import {Button,Tooltip, Typography} from "antd";
import {FullscreenOutlined, MinusOutlined} from "@ant-design/icons";
import React from "react";
import "./AiSideBarItem.less"


const AiSideBarItem = ({
                         title,
                         icon,
                         onMinimize=()=>{},
                         onExpand=()=>{},
                         children,
                     }) => {
    return <div>
        <div className={"ai-sidebar-item-header"}>
            <div className={"ai-sidebar-item-title"}>
                {icon}
                <Typography.Title level={3}>
                    {title}
                </Typography.Title>
            </div>
            <div className={"control-panel"}>
                <Tooltip title="Minimize">
                    <Button
                        type="text"
                        size="large"
                        icon={<MinusOutlined/>}
                        onClick={onMinimize}
                    />
                </Tooltip>
                <Tooltip title="Expand">
                    <Button
                        type="text"
                        size="large"
                        icon={<FullscreenOutlined/>}
                        onClick={onExpand}
                    />
                </Tooltip>
            </div>
        </div>
        {children}
    </div>
}


export default AiSideBarItem;
