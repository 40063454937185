import React, {useContext, useEffect, useState} from 'react';
import {Spin} from 'antd';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import StressTable from "@components/stress/StressTable";

const ClientStress = () => {
    const {client, calculated_values, proposed_calculated_values} = useContext(SpasClientContext);

    const [loading, setLoading] = useState(false);
    const [portfolios, setPortfolios] = useState(client['current_portfolios']);

    const [stressItems, setStressItems] = useState([]);

    useEffect(() => {
        let stress_results = calculated_values?.risk?.stress_test?.filter(s => !s.auxiliary)
        let proposed_stress_results = null
        if (proposed_calculated_values && proposed_calculated_values?.risk?.stress_test) {
            proposed_stress_results = proposed_calculated_values?.risk?.stress_test?.filter(s => !s.auxiliary)
        }


        let market_value = calculated_values['market_value']


        if (!stress_results) {
            return
        }

        let proposed_stress_map = {}

        if (proposed_stress_results) {
            proposed_stress_map = Object.fromEntries(
                proposed_stress_results.map(item => [item.period_label, item])
            )
        }

        let si = []

        for (let client_result of stress_results) {
            const label = client_result['period_label']
            let proposed_data = null
            if (proposed_stress_map.hasOwnProperty(label)) {
                proposed_data = proposed_stress_map[label]
            }

            let stress_item = {
                period_label: label,
                date: client_result.start + ' — ' + client_result.end,
                title: label,
                benchmarkInfo: {
                    title: client_result.benchmark_label,
                    portfolioReturn: client_result.benchmark_return / 100,
                    dollarPortfolioReturn: (client_result.benchmark_return / 100) * market_value,
                }, portfolioInfo: {
                    title: 'Current Portfolio',
                    portfolioReturn: client_result.portfolio_return / 100,
                    dollarPortfolioReturn: (client_result.portfolio_return / 100) * market_value,
                }
            }

            if (proposed_data) {
                stress_item['proposalInfo'] = {
                    title: 'Proposed Portfolio',
                    portfolioReturn: proposed_data.portfolio_return / 100,
                    dollarPortfolioReturn: proposed_data.portfolio_return / 100 * market_value,
                }
            } else {
                stress_item['proposalInfo'] = null
            }
            console.log("----------")
            console.log(stress_item)
            si.push(stress_item)
        }


        setStressItems(si)


    }, [client])

    const fetchData = () => {
        setLoading(true)
        let pIds = portfolios.map(p => p._id)
        //stressAPI.getStressPrognosis(id, pIds, stressDataHandler)
    }


    return (
        loading ? <div style={{height: 300}} className="loadingBox"><Spin size="large"></Spin></div> :
            <div>
                <div id="stress-card" style={{paddingTop: "5px"}}>
                    <StressTable stressItems={stressItems}/>
                </div>
            </div>
    )
}

export default ClientStress;
