import React, {createContext, useContext, useState} from "react";

const socketContext = createContext();

const SocketWrapper = ({children}) => {
    const socketController = useSocketCreation();
    return <socketContext.Provider value={socketController}>{children}</socketContext.Provider>
};

const useWebSocket = () => {
    return useContext(socketContext);
};

const useSocketCreation = () => {
    const [sockets, setSockets] = useState({});

    const createSocket = ({
                              url,
                              tag,
                              onmessageCallback,
                              onopenCallback = null,
                              onerrorCallback = null,
                              oncloseCallback = null
                          }) => {
        // let existingSocket = sockets[tag]
        // if (existingSocket && socket.readyState === socket.OPEN) {
        //     console.warn('socket ' + tag + ' already exists and is open, returning existing instance')
        //     return existingSocket
        // }
        let socket = new WebSocket(url);
        socket.onmessage = (event) => {
            console.log(`[${tag}] :: Recieved message`);
            onmessageCallback(event, socket);
        }
        // socket.binaryType = "arraybuffer";


        socket.onopen = (event) => {
            console.log(`[${tag}] :: Web socket to ${url} was opened`);
            if (onopenCallback) {
                onopenCallback(event, socket);
            }
        }

        socket.onerror = (error) => {
            console.error(`[${tag}] :: Web socket server raised error`);
            console.error(`[${tag}] :: ${error}`);
            if (onerrorCallback) {
                onerrorCallback(error);
            }
            socket.close(1000);
        }

        socket.onclose = (event) => {
            console.log(`[${tag}] :: Web socket was closed by ${url}`);
            if (oncloseCallback) {
                oncloseCallback(event);
            }
        }

        setSockets({...sockets, [tag]: socket});
        return socket
    }

    const deleteSocket = tag => {
        let _sockets = Object.assign({}, sockets);
        delete _sockets[tag];
        setSockets(_sockets);
    }

    //closes before removing
    const destroySocket = tag => {
        console.debug(sockets)
        const socket = sockets[tag];
        if (socket) {
            console.debug(socket)
            if (socket.readyState === socket.OPEN) {
                socket.close(1000);
            }
            deleteSocket(tag);
        }
    }

    const getActualSockets = () => {
        return Object.assign({}, sockets)
    }

    const socketOpen = (tag) => {
        let socket = sockets[tag];
        if (socket && socket.readyState === socket.OPEN) {
            return true
        }
        return false
    }
    
    const sendMessage = (tag, messageJson) => {
        if (socketOpen(tag)) {
            sockets[tag].send(JSON.stringify(messageJson), {type: "application/json"})    
        } else {
            console.error("Can not send message on not ready socket, " + socket.readyState)
            throw new Error("Bad socket state!")
        }
    }
    
    return {
        sockets,
        createSocket,
        deleteSocket,
        destroySocket,
        getActualSockets,
        sendMessage,
        socketOpen
    }
}

export {SocketWrapper, useWebSocket}
