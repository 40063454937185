/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import {Button, Collapse, Input, message, Modal, Typography} from "antd";
import '@root/components/portfolio/Portfolio.less';
import {savePlayroundPreset} from "@API/playground";
import {saveAllocationsPreset} from "@API/allocation";

const {Panel} = Collapse;
const {Paragraph, Text, Title} = Typography;
const AllocationsPresetCreator = ({preset, presetSaved = null}) => {
    const [presetName, setPresetName] = useState([])
    const [showSavePreset, setShowSavePreset] = useState(false)

    useEffect(() => {

    }, [])
    const handleInputChange = e => {
        const {name, value} = e.target;
        setPresetName(value);
    }

    const savePreset = () => {
        let presetToSave = {
            name: presetName,
            type: 'global',//for now
        }

        saveAllocationsPreset(presetToSave, (result, error) => {
            if (error) {
                message.error('Error saving the preset: ' + err)
            } else {
                message.success(presetName + ' was successfully created')
            }
            setShowSavePreset(false)
        })
    }

    return (<><Button
            style={{marginRight: 12}}
            onClick={() => setShowSavePreset(true)}>Save configuration</Button>
        <Modal
            title="Preset save"
            open={showSavePreset}
            width={750}
            closable={false}
            maskClosable={true}
            onCancel={v => setShowSavePreset(false)}
            onOk={() => savePreset()}
        >
            <Input onChange={handleInputChange} name={'preset_name'} value={presetName}></Input>
        </Modal></>);
}

export default AllocationsPresetCreator;
