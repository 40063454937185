import axiosInstance from "./SchedulerClient";
import axios from "axios";

let cancel;
const getDashboardData = async (start_date, end_date) => {
    let url = `/server_state?start_date=${start_date}&end_date=${end_date}`
    return await axiosInstance.get(url)
}
const getSchedulerObjects = (entity, task_ids, search, handler, abortSignal) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/`;

    let params = {search_string: search};
    if (entity === "task" && task_ids) {
        params.ids = task_ids.join(",");
    }

    axiosInstance.get(url,
        {
            params: params,
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancel = c;
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getSchedulerObject = (entity, id, handler, abortSignal) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/${id}`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancel = c;
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const createSchedulerObject = (entity, obj, handler) => { //createTask
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/`;

    axiosInstance.post(url, obj)
        .then(({data}) => {
            handler(data);
        })
        .catch(({response}) => {
            handler(null, true);
        })
};

const updateSchedulerObject = (entity, id, obj, handler) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/${id}`;

    axiosInstance.put(url, obj)
        .then(({data}) => {
            handler(data);
        })
        .catch(({response}) => {
            handler(null, true);
        })
};

const runSchedulerObject = (entity, id, handler) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/run/${id}`;

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response}) => {
            handler(null, true);
        })
};

const deleteSchedulerObject = (entity, id, handler) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/${entity}/${id}`;

    axiosInstance.delete(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response}) => {
            handler(response.data.message, true);
        })
};

const getJobs = (handler, abortSignal) => {
    let url = `/job/`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancel = c;
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getJob = (jobId, handler, abortSignal) => {
    let url = `/job/${jobId}`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancel = c;
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const scheduleEvent = (id, entity, name, cronString, handler, abortSignal) => {
    let url = `/job/cron`;

    axiosInstance.post(url, {
        _id: id,
        entity: entity,
        name: name,
        cron_string: cronString
    }, {signal: abortSignal})
        .then(({data}) => {
            handler(data);
        })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const createTaskJob = (taskId, jobData, handler, abortSignal) => {
    let url = `/job/task/${taskId}`;

    axiosInstance.post(url, jobData, {signal: abortSignal})
        .then(({data}) => {
            handler(data);
        })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const createQueueJob = (queueId, jobData, handler, abortSignal) => {
    let url = `/job/queue/${queueId}`;

    axiosInstance.post(url, jobData, {signal: abortSignal})
        .then(({data}) => {
            handler(data);
        })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const updateJob = (jobId, jobData, handler, abortSignal) => {
    let url = `/job/${jobId}`;

    axiosInstance.put(url, jobData, {signal: abortSignal})
        .then(({data}) => {
            handler(data);
        })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const deleteJob = (jobId, handler, abortSignal) => {
    let url = `/job/${jobId}`;

    axiosInstance.delete(url, {signal: abortSignal})
        .then(({data}) => {
            handler(data);
        })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

export {
    getSchedulerObjects,
    getSchedulerObject,
    runSchedulerObject,
    deleteSchedulerObject,
    createSchedulerObject,
    updateSchedulerObject,
    getJobs,
    getJob,
    createQueueJob,
    createTaskJob,
    updateJob,
    deleteJob,
    getDashboardData,
    scheduleEvent,
    cancel
}
