import React from "react";
import {Button, Drawer, Flex, theme, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";

const {useToken} = theme;


const MobileDrawer = ({
                          open,
                          onClose,
                          closable = true,
                          title,
                          width = 900,
                          maskClosable = true,
                          backButtonText = "Back",
                          style = {},
                          rootClassName = null,
                          children
                      }) => {
    const {token} = useToken();

    return <Drawer
        onClose={onClose}
        open={open}
        width={width}
        closable={false}
        maskClosable={maskClosable}
        title={null}
        style={{background: token.colorBgGreyDark, ...style}}
        styles={{body: {paddingTop: token.paddingXS}}}
        className={"holistico retail"}
        rootClassName={rootClassName}
    >
        {closable
            &&
            <Button
                type={"link"}
                onClick={onClose}
                size={"large"}
                icon={<LeftOutlined/>}
                style={{padding: 0, marginBottom: token.padding}}
            >
                {backButtonText}
            </Button>
        }
        {title &&
            <Typography.Title level={4} style={{margin: "0 0 12px 0"}}>
                Risk ability questionnaire
            </Typography.Title>
        }
        {children}
    </Drawer>
}


export default MobileDrawer;
