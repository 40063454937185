import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;
const getReportBasics = (reportClass, templatesHandler) => {
    let url = `/new-report/`

    axiosInstance.get(url,
        {
            params: {report_class: reportClass},
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        templatesHandler(data);
    })
        .catch(({response}) => {
            console.log(response);
        })
};

const saveReportPreset = (userTemplate, creationCallback) => {
    let url = `/new-report/presets`

    axiosInstance.post(url, userTemplate)
        .then(() => {
            creationCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};

const deleteReportPreset = (_id, deletionCallback) => {
    let url = `/new-report/presets/${_id}`

    axiosInstance.delete(url)
        .then(() => {
            deletionCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};

const createReport = (reportData, callback) => {
    let url = `/new-report/generate`

    axiosInstance.post(url, reportData, {responseType: "arraybuffer"})
        .then((response) => {
            callback(response);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

export {
    cancel,
    createReport,
    getReportBasics,
    saveReportPreset,
    deleteReportPreset
}
