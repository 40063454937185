import {
    Button,
    Divider,
    Form,
    Input,
    Typography
} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {QuestionnaireForm} from "@holistico/proposal/new/form/GeneralInfoForm";
import ActionInfo from "@holistico/aux/ActionInfo";
import RiskStatisticPanel, {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/RiskStatisticPanel";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import ModelsCarousel
    from "@holistico/proposal/view/client/ModelsCarousel";
import {ShowAiButton} from "@holistico/proposal/view/ai/AiSideBar";
import {NavLink} from "react-router-dom";


export const getRiskScoreFromQuestionnaire = (values) => {
    const answers = values?.questionnaire
    if (!answers) {
        return null
    }
    let score = 50
    score += {short: -5, intermediate: 0, long: +10}[answers.investment_time_horizon]
    score += {below_spending: -5, above_spending: +10}[answers.non_investment_income]
    score += {not_working: -5, retired: -5, working: 5}[answers.wstatus]
    score += {married: -5, single: +5, divorced: 0}[answers.mstatus]
    const age = moment().year() - answers.birth_year
    score += Math.round((40 - age) / 2)
    score += Number(answers.hstatus) - 5
    return score
}


const ProposalQuestionnaireForm = () => {
    const [form] = Form.useForm();
    const {proposal, updateClientInfo} = useHolisticoProposalContext()
    const [saving, setSaving] = useState(false)

    const submitForm = (values) => {
        setSaving(true)
        const riskScore = getRiskScoreFromQuestionnaire(values)
        if (riskScore) {
            values["questionnaire"]["risk_profile"] = {"ability": riskScore}
        }
        updateClientInfo(values, true, () => {
            setSaving(false)
        })
    };

    useEffect(() => {
        form.setFieldsValue(proposal);
    }, [proposal])

    return <Form form={form}
                 layout={'vertical'}
                 onFinish={submitForm}
                 style={{marginTop: "24px"}}
                 requiredMark={true}
    >
        <Form.Item
            name="full_name"
            label="Name"
        >
            <Input placeholder={"Name"}/>
        </Form.Item>
        <QuestionnaireForm withRules={true}/>
        <div className={"hint"}>
            <div style={{
                display: "inline-block",
                marginRight: "4px",
                color: "#f5222d",
                fontSize: "16px",
                lineHeight: 1,
            }}>*
            </div>
            fields required for the risk ability estimation
        </div>
        <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{width: "200px", marginTop: "20px"}}
            loading={saving}
        >
            Save
        </Button>
    </Form>
}


const Questionnaire = () => {
    const {proposal, loading} = useHolisticoProposalContext()
    const abilityScore = proposal?.questionnaire?.risk_profile?.ability
    const abilityLevel = getToleranceLevelByRiskScore(abilityScore)

    return <>
        {proposal &&
            <>
                <div className={'proposal-item-header'}>
                    <Typography.Title level={3}>
                        Questionnaire
                    </Typography.Title>
                    <ShowAiButton/>
                </div>
                <div className={"page-content"} style={{padding: 0}}>
                    <RiskStatisticPanel active="ability"/>
                    <div style={{margin: "32px 28px 72px 28px"}}>
                        <ActionInfo>
                            The questionnaire below estimates client's
                            ability to take risk
                        </ActionInfo>
                        <div className={"hint"} style={{marginLeft: "32px", marginTop: "8px", whiteSpace: "pre-line"}}>
                            {"Alternatively, you can fill the "}
                            <NavLink
                                to={`../client/behavioral-quiz`}>
                                Behavioral quiz
                            </NavLink>
                            {" to estimate behavioral tolerance to risk.\n"}
                            {"To estimate client's willingness to take risk, use "}
                            <NavLink
                                to={`../client/risk-tolerance`}>
                                Risk tolerance
                            </NavLink>
                            {" section."}
                        </div>
                        <ProposalQuestionnaireForm/>
                        <Divider/>
                        <RiskResult
                            title={abilityScore
                                ?
                                `Risk ability score: ${abilityScore}`
                                : "Risk ability score not set"
                            }
                            description={abilityScore
                                ?
                                `${abilityLevel} risk ability`
                                :
                                "Fill the questionnaire to get risk ability score"
                            }
                            success={!!abilityScore}
                            style={{margin: "48px 0 32px 0"}}
                        />
                    </div>
                    {!loading
                        && <ModelsCarousel
                            riskScore={abilityScore}
                            active={!!abilityScore}
                            inactiveMessage={"Models will become available after filling the questionnaire"}
                        />
                    }
                </div>
            </>
        }
    </>
}

export default Questionnaire;
