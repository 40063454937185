import {
    Typography,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Rate,
    Row,
    Space,
    Tag,
    Tooltip, Divider, Button
} from "antd";
import React, {useState} from "react";
import moment from "moment";
import {AuditOutlined, FrownOutlined, HeartOutlined, MehOutlined, SmileOutlined} from '@ant-design/icons';
import {showAi} from "@holistico/proposal/view/ai/AiSideBar";

const {CheckableTag} = Tag;
const radioButtonStyle = {
    width: "100%",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}

const customIcons = {
    1: <FrownOutlined/>,
    2: <FrownOutlined/>,
    3: <MehOutlined/>,
    4: <SmileOutlined/>,
    5: <SmileOutlined/>,
};


const itemColSpan = () => {
    if (showAi()) {
        return {xl: 12, lg: 24, md: 24, sm: 24, xs: 24}
    } else {
        return {xl: 12, lg: 12, md: 24, sm: 24, xs: 24}
    }
}

const checkboxItemColSpan = () => {
    return {xl: 8, lg: 8, md: 12, sm: 12, xs: 24}
}

export const QuestionnaireForm = ({withRules = false}) => {
    // const [retirementYear, setRetirementYear] = useState();
    // const defaultPickerYear = moment().year() - 20;
    // const form = Form.useFormInstance();
    const requiredItemProps = withRules ? {
        rules: [{
            required: true,
            warningOnly: true,
            message: "Required for the risk ability estimation"
        }],
        required: true
    } : {}

    return <>
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item label="Phone" name="phone">
                    <Input placeholder={"+1"}/>
                </Form.Item>

            </Col>
            <Col {...itemColSpan()}>
                <Form.Item label="Email" name="email">
                    <Input placeholder={"@"}/>
                </Form.Item>
            </Col>
        </Row>
        <Divider/>
        <Form.Item name={["questionnaire", "goals"]} label={"Goals"}>
            <Checkbox.Group style={{width: "100%", marginTop: "4px"}}>
                <Row gutter={[48, 4]}>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="Retirement">
                            Save for retirement
                        </Checkbox>
                    </Col>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="College Savings">
                            Save for college
                        </Checkbox>

                    </Col>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="Income">
                            Generate income
                        </Checkbox>
                    </Col>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="Debt">
                            Pay off debt
                        </Checkbox>
                    </Col>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="Wealth Accumulation">
                            Accumulate wealth
                        </Checkbox>
                    </Col>
                    <Col {...checkboxItemColSpan()}>
                        <Checkbox value="Other">
                            Other
                        </Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </Form.Item>
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Investment horizon"
                    name={["questionnaire", "investment_time_horizon"]}
                    {...requiredItemProps}
                >
                    <Radio.Group style={{width: "100%"}}>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="long">
                                    10 years or more
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="intermediate">
                                    {"6 - 9 years"}
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="short">
                                    {"5 years or less"}
                                </Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Non-investment income"
                    name={["questionnaire", "non_investment_income"]}
                    {...requiredItemProps}
                >
                    <Radio.Group style={{width: "100%"}}>
                        <Row>
                            <Col span={12}>
                                <Radio.Button style={radioButtonStyle} value="above_spending">
                                    Above spending
                                </Radio.Button>
                            </Col>
                            <Col span={12}>
                                <Radio.Button style={radioButtonStyle} value="below_spending">
                                    Below spending
                                </Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Working status"
                    name={["questionnaire", "wstatus"]}
                    {...requiredItemProps}
                >
                    <Radio.Group style={{width: "100%"}}>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="working">
                                    Working
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="not_working">
                                    Sabbatical
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="retired">
                                    Retired
                                </Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Marital status"
                    name={["questionnaire", "mstatus"]}
                    {...requiredItemProps}
                >
                    <Radio.Group style={{width: "100%"}}>
                        <Row>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="married">
                                    Married
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="single">
                                    Single
                                </Radio.Button>
                            </Col>
                            <Col span={8}>
                                <Radio.Button style={radioButtonStyle} value="divorced">
                                    Divorced
                                </Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Birth year"
                    name={["questionnaire", "birth_year"]}
                    required={withRules}
                    rules={!withRules ? [] : [
                        {
                            // Without custom validator it works weird
                            warningOnly: true,
                            validator: (_, value) =>
                                value
                                    ?
                                    Promise.resolve()
                                    :
                                    Promise.reject(new Error("Required for the risk ability estimation")),
                        },
                    ]}
                    // getValueFromEvent={(onChange) => moment(onChange).year()}
                    // getValueProps={(intYear) => {
                    //     let mom = moment().set('years', intYear)
                    //     console.log(mom)
                    //     console.log(intYear)
                    //     return mom
                    // }}
                    // defaultPickerValue={defaultPickerYear}
                >
                    <InputNumber
                        placeholder={"Year"}
                        style={{width: "100%"}}
                    />
                    {/*<DatePicker*/}
                    {/*    style={{width: "100%"}}*/}
                    {/*    onChange={date => {*/}
                    {/*        const birthYear = date.year();*/}
                    {/*        let retirementYear = birthYear + 65;*/}
                    {/*        if (!form.getFieldValue(["questionnaire", "retirement_year"])) {*/}
                    {/*            form.setFieldValue(["questionnaire", "retirement_year"], retirementYear);*/}
                    {/*        }*/}
                    {/*        //form.setFieldValue(["questionnaire", "birth_year"], birthYear);*/}
                    {/*    }}*/}
                    {/*    picker="year"*/}
                    {/*/>*/}
                </Form.Item>
            </Col>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Retirement year"
                    name={["questionnaire", "retirement_year"]}
                >
                    <InputNumber
                        placeholder={"Year"}
                        style={{width: "100%"}}
                        // value={retirementYear}
                        // onChange={setRetirementYear}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Health status"
                    name={["questionnaire", "hstatus"]}
                    required={withRules}
                    rules={!withRules ? [] : [
                        {
                            // Without custom validator it works weird
                            warningOnly: true,
                            validator: (_, value) =>
                                value
                                    ?
                                    Promise.resolve()
                                    :
                                    Promise.reject(new Error("Required for the risk ability estimation")),
                        },
                    ]}
                >
                    <Rate style={{fontSize: 24, color: '#F86767FF'}}
                          character={<HeartOutlined/>}/>
                </Form.Item>
            </Col>
            <Col {...itemColSpan()}>
                <Form.Item label="Life expectancy" name={["questionnaire", "life_expectancy"]}>
                    <InputNumber placeholder={"95"} style={{width: "100%"}}/>
                </Form.Item>
            </Col>
        </Row>
        {/*<Divider/>*/}
        <Row gutter={48}>
            <Col {...itemColSpan()}>
                <Form.Item
                    label="Current fee, %"
                    name={["questionnaire", "current_fee"]}
                >
                    <InputNumber step={0.1} placeholder={"1.0"}
                                 style={{width: "100%"}}/>
                </Form.Item>
            </Col>
        </Row>
        {/*<Form.Item*/}
        {/*    label="Notes"*/}
        {/*    name={["questionnaire", "notes"]}*/}
        {/*>*/}
        {/*    <Input.TextArea*/}
        {/*        placeholder={"Additional info about employment, major expenses, etc."}*/}
        {/*        style={{minHeight: 120}}*/}
        {/*    />*/}
        {/*</Form.Item>*/}
    </>
}


const GeneralInfoForm = ({saveData}) => {
    return <>
        <Form.Item label={'Name'}>
            <Form.Item
                name="full_name"
                noStyle
            >
                <Input placeholder={"Name"}/>
            </Form.Item>
        </Form.Item>
        <Collapse>
            <Collapse.Panel
                key={"1"}
                showArrow={false}
                header={
                    <Row>
                        <Col span={24} align={"right"}
                             style={{marginTop: -13}}>
                            <span><AuditOutlined/> Questionnaire</span>
                        </Col>
                    </Row>
                }
            >
                <QuestionnaireForm/>

                <Button type="primary" onClick={() => {
                    console.log(saveData)
                    console.log(typeof saveData)
                    saveData()
                }}>Save</Button>
            </Collapse.Panel>
        </Collapse>
    </>
}

export default GeneralInfoForm
