/* eslint-disable react/prop-types */
import React from 'react';
import {DeleteOutlined, EllipsisOutlined, FileExcelOutlined, RetweetOutlined, SnippetsOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';

import '../Portfolio.less';


const PortfolioRowMenu = ({
    editTitle,
    editMarketValue,
    exportPortfolio,
    deletePortfolio,
    refreshPortfolio,
    portfolioExcelDownload,
    setUpdatingModal,
    type,
    orionId = null
}) => {

    const items = [];
    if (orionId) {
        items.push({
            key: "refresh",
            label: (<div
                onClick={e => {
                    e.stopPropagation();
                    refreshPortfolio();
                }}
                style={{color: '#db844b'}}
            >
                <RetweetOutlined style={{marginRight: 12}}/>
                Refresh from Orion
            </div>),
        })
    }
    items.push({
        key: "download",
        label: (<div
            onClick={e => {
                e.stopPropagation();
                portfolioExcelDownload();
            }}
            style={{color: '#4e9c50'}}
        >
            <FileExcelOutlined style={{marginRight: 12}}/>
            Download as Excel
        </div>)
    })
    items.push({
        key: "file",
        label: (<div
           onClick={e => {
                e.stopPropagation();
                setUpdatingModal(true);
            }}
            style={{color: '#096dd9'}}
        >
            <SnippetsOutlined style={{marginRight: 12}}/>
            Update from file
        </div>)
    })
    items.push({
        key: "delete",
        label: (<div
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                deletePortfolio();
            }}
            style={{color: '#ad2102'}}
        >
            <DeleteOutlined style={{marginRight: 12}}/>
            Remove
        </div>)
    })

    return <Dropdown trigger={['click']} menu={{items: items}}>
        <div onClick={e => e.stopPropagation()}>
            <EllipsisOutlined/>
        </div>
    </Dropdown>

}

export default PortfolioRowMenu;
