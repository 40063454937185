import React, {useEffect, useRef, useState} from "react";
import {message, Select} from "antd";
import {getRepresentatives} from "@API/supervision";

const RepresentativeSearch = ({selectedRepresentative, setSelectedRepresentative}) => {
    const [representativesLoading, setRepresentativesLoading] = useState(false);
    const [representativesList, setRepresentativesList] = useState([]);
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        setRepresentativesLoading(true);
        getRepresentatives((data, error) => {
            setRepresentativesLoading(false);
            if (error) {
                message.error("Error while retrieving representatives list");
                return
            }
            setRepresentativesList(data);
            console.log(data)
        }, abortControllerRef.current.signal)

        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    return (
        <Select
            style={{width: "100%"}}
            showSearch
            loading={representativesLoading}
            allowClear={selectedRepresentative != null}
            placeholder="Select representative"
            value={selectedRepresentative}
            onChange={(value) => {
                setSelectedRepresentative(value);
            }}
            onClear={() => {
                setSelectedRepresentative(null);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {/* <Select.Option key={-1} value={null}>All representatives</Select.Option> */}
            {representativesList && representativesList.map((representative, inx) => {
                return <Select.Option key={inx} value={representative}>{representative}</Select.Option>
            })}
        </Select>
    )
}

export default RepresentativeSearch
