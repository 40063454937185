import React, {useMemo} from "react";
import {Tooltip, Typography} from "antd";
import RedFlagInfo from "./info_components/RedFlagInfo";
import ClientRedFlagInfo from "./info_components/ClientRedFlagInfo";
import ClientRedFlagContainer from "../client/report_components/ClientRedFlagContainer";
import RedFlagContainer from "../red_flag/report_components/RedFlagContainer";
import ExpandableContainer from "./ExpandableContainer";
import "../Supervision.less";
import {IssuesCloseOutlined, WarningFilled} from "@ant-design/icons";

const getGlobalRootData = (branch, args) => {
    switch (branch.level) {
        case "red_flag":
            return {
                text: branch.red_flag_name,
                infoComponent: <RedFlagInfo
                    clients_count={branch.problem_counters.clients}
                    representatives_count={branch.problem_counters.representatives}
                    all_clients={branch.problem_counters.clients}
                    all_representatives={branch.problem_counters.representatives}
                />,
                expandableComponent: <RedFlagContainer flag={branch}/>
            }
    }
}

//XXX: should this be put on a level higher? but it's the first place we have the data
const getClientMark = (client) => {
    let markElement = null

    if (!client?.mute.muted && client?.limit.client) {
        let markText = "The flag limit was changed"
        if (client?.limit.type == 'global') {
            markText += ', however, it was ignored by report settings.'
            markElement = <Tooltip title={markText}>
                <sup style={{color: '#ccc'}}><IssuesCloseOutlined /></sup>
            </Tooltip>
        } else {
            let color = 'green'
            let icon = <IssuesCloseOutlined/>
            if (client.red_flag_raised) {
                color = 'gold'
                icon = <WarningFilled />//<WarningOutlined />
            }
            markText += ' and used during the report data aggregation.'
            markElement = <Tooltip title={markText}>
                <sup style={{color: color, opacity: 0.5}}>{icon}</sup>
            </Tooltip>
        }
    }
    return markElement
}
const getClientRootData = (branch, clientInfo) => {
    
    let markElement = getClientMark(branch)
    return {
        text: branch.red_flag_name,
        infoComponent: <ClientRedFlagInfo
            raised={branch.red_flag_raised}
            muted={branch?.mute?.muted}
            limitSettings={branch?.limit}
        />,
        mark: markElement,
        expandableComponent: <ClientRedFlagContainer flag={branch} clientInfo={clientInfo}/>
    }
}

const ReportConstructor = ({data, type = "flag", additionalClientInfo = null, description = ''}) => {
    const report = useMemo(() => {
        if (data) {
            const getRootData = type === "flag" ? getGlobalRootData : getClientRootData;
            return <div style={{marginTop: "15px", fontSize: "0.9em"}}>
                {data.map((branch, inx) => {
                    return <ExpandableContainer key={inx} {...getRootData(branch, additionalClientInfo)} />
                })}
            </div>
        }
        return null
    }, [data, type])

    return (
        <div className={'report-constructor'}>
            <Typography.Title level={4} className={'spas'}>
                Checks
            </Typography.Title>
            {description.split('\n').map((text, index) => {
                return <Typography.Paragraph key={index} className={'text'}>
                    {text}
                </Typography.Paragraph>
            })}
            {report}
        </div>
    )
}

export default ReportConstructor
