import {Col, Empty, Row} from 'antd'
import AssetClassPie from "@holistico/chart/AssetClassPie";
import React from "react";
import ScoreMarkersRadar from "@holistico/chart/ScoreMarkersRadar";

const ScoreMarkersCompareRow = ({original_values, recommended_values}) => {
    if (!original_values || Object.keys(original_values).length === 0) return <Empty style={{margin: "15px 0"}}
                                                                                     description="No asset class information"/>;

    return <Row gutter={[24, 24]} style={{width: '100%'}}>
        <Col span={12}>
            <ScoreMarkersRadar
                showLegend={true}
                // score_markers={original_values?.score_markers?.filter(marker => marker.label != 'sscore_plus')}
                score_markers={original_values?.score_markers}
            />
        </Col>
        <Col span={12}>
            <ScoreMarkersRadar
                showLegend={true}
                // score_markers={recommended_values?.score_markers?.filter(marker => marker.label != 'sscore_plus')}
                score_markers={recommended_values?.score_markers}
            />
        </Col>
        <Col span={12}>

        </Col>
    </Row>
}

export default ScoreMarkersCompareRow
