/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {Card, Col, Input, Row, Select, Slider, Space, Typography} from 'antd';
import '@components/stress/StressInfo.less';
import ValueFomatter from "@global/ValueFormatter";

const {TextArea} = Input;
const {Paragraph, Text, Title} = Typography;
const {Meta} = Card;
const {Option} = Select;

const getPerformanceData = (dollarReturn, percentReturn) => {
    let formattedDollars = ValueFomatter.compactCurrency(Math.abs(dollarReturn))
    let formattedPercent = ValueFomatter.percent(percentReturn, 0)

    let colorClass = 'stress-positive'
    if (percentReturn > 0) {
        formattedPercent = '+' + formattedPercent
    } else if (percentReturn < 0) {
        colorClass = 'stress-negative'
    }

    return <Typography.Title level={4}
                             className={colorClass}> {formattedPercent} / {formattedDollars}</Typography.Title>
}

const QStepRisk = ({financialParams = {}, financialParamsChanged}) => {
    const {id} = useParams();

    const onChange = value => {
        if (isNaN(value)) {
            return;
        }

        financialParamsChanged('risk', value)
    };

    useEffect(() => {
        onChange(typeof financialParams?.risk === 'number' ? financialParams.risk : 50)
    }, []);

    return (
        (<div>
            <Title level={3}>Six months risk comfort zone</Title>
            <Paragraph style={{fontSize: 18, marginBottom: 48, color: '#000000d9'}}>Let’s define your risk comfort zone. How much loss within 6 months
                can you
                tolerate?</Paragraph>
            <Row gutter={[8, 8]} justify={"center"} align={"bottom"}>
                <Col span={20} style={{textAlign: 'right'}}>
                    <Slider
                        className={"user-slider"}
                        included={true}
                        min={1}
                        max={100}
                        marks={{
                            7: {style: {color: '#3196B2', marginTop: 12}, label: 'Cash'},
                            22: {
                                style: {color: '#3196B2', marginTop: -48},
                                label: 'Hedge Tracker ETF'
                            },
                            35: {
                                style: {color: '#3196B2', marginTop: 12},
                                label: 'Investment Grade Corporate Bond'
                            },
                            60: {style: {color: '#3196B2', marginTop: -48}, label: 'S&P 500 ETF Trust'},
                            92: {style: {color: '#3196B2', marginTop: 0}, label: 'FANG'},

                        }}
                        onChange={onChange}
                        defaultValue={50}
                        value={financialParams.risk}
                        vertical={false}
                        style={{
                            fontSize: '13px',
                            color: '#000000'
                        }}
                        tooltip={{
                            formatter: (risk) => {
                                if (risk < 8) {
                                    return 'No reason to invest'
                                } else if (risk < 40) {
                                    return 'Conservative'
                                } else if (risk < 50) {
                                    return 'Balanced'
                                } else if (risk < 65) {
                                    return 'Moderate'
                                } else if (risk < 80) {
                                    return 'Strategic'
                                } else if (risk < 100) {
                                    return 'High risk'
                                }
                            },

                            placement: "top"
                        }} />
                </Col>
                <Col span={4} className={'stress'}>
                    <Space direction={"vertical"}>
                        {getPerformanceData(financialParams['positive_performance_plan_dollar'], financialParams['positive_performance_plan'])}
                        {getPerformanceData(financialParams['negative_performance_plan_dollar'], financialParams['negative_performance_plan'])}
                    </Space>
                </Col>

            </Row>
        </div>)
    );
}

export default QStepRisk;
