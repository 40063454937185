import {Progress, Space, Tooltip, Typography} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import {UserSwitchOutlined} from "@ant-design/icons";
import moment from "moment";

const activeBadgeProps = {status: "processing", color: "green"};
const inactiveBadgeProps = {status: "default", color: "gray"};


const SupervisionClientProposals = ({proposals = []}) => {
    
    const Proposal = ({info}) => {
        let curMom = moment(info['created_at'])
        let formattedValue = curMom.format("MMM DD, YYYY")
        let url = `proposal/${info.proposal_id}/overview`
        return <Space direction={'horizontal'} size={4} align={"center"}>
            <UserSwitchOutlined style={{color: '#E0AD60'}}/>
            <Link to={url}>{info.proposal_name}</Link>
            {formattedValue}
            <Tooltip title={'Questionnaire filling rate.'}>
                <Progress percent={info.questionnaire_filling_rate} steps={20} size="small" strokeColor={'green'} showInfo={false}/>
            </Tooltip>
        </Space>
    }
    
    if (proposals.length == 0) {
        return null
    } 
    return <div style={{marginBottom: 12}}>
        <Typography.Text>The following proposals were created for this client:</Typography.Text>
        <br/>
        {proposals.map(p => <Proposal info={p}/> )}
    </div>
}

export default SupervisionClientProposals
