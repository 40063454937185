import {Button, Form, Image, Result} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import ComparativePortfolioPreview from "@holistico/proposal/new/preview/ComparativePortfolioPreview";
import {HolisticoProposalContextProvider, useHolisticoProposalContext} from "@hooks/ProposalContext";

const PortfolioPreview = ({id}) => {
    return <div>
        {!id ? <Result
            status="403"
            title="Submit the client first"
            subTitle="Client information not submitted yet. Please complete previous steps."
        /> : <ComparativePortfolioPreview/>}

    </div>
}

export default PortfolioPreview;
