/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Flex,} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {CARD_TAB_OPTIONS} from "@components/screener/settings";
import TagsPanel from "@components/screener/params/TagsPanel";
import SearchFilter from "@components/screener/params/SearchFilter";
import SortSelect from "@components/screener/params/SortSelect";
import CardTabSelect from "@components/screener/params/CardTabSelect";
import ValueFormatter from "@global/ValueFormatter";


const CounterPanel = () => {
    const {
        totalAssetsCount,
        entityGroupLabelSg,
        entityGroupLabelPl,
        loadingAssets
    } = useScreenerContext()

    return <div style={{height: 36}}>
        {!loadingAssets
            &&
            <div style={{fontSize: 16, color: "#e0ad60"}}>
                <span style={{fontSize: 24, letterSpacing: 3, marginRight: 4}}>
                    {totalAssetsCount ? ValueFormatter.int_number(totalAssetsCount) : "0"}
                </span>
                {totalAssetsCount === 1
                    ? `${entityGroupLabelSg} found`
                    : `${entityGroupLabelPl} found`
                }
            </div>
        }
        {loadingAssets
            &&
            <LoadingOutlined style={{fontSize: 24, color: "#e0ad60"}} spin/>
        }
    </div>
}


const TopPanel = ({}) => {
    const {
        entityGroup,
        fundActiveTab,
        setFundActiveTab,
        portfolioActiveTab,
        setPortfolioActiveTab
    } = useScreenerContext()

    return <Card style={{borderColor: "#e3e3e3", marginBottom: 16}}>
        <CounterPanel/>
        <TagsPanel/>
        <Flex
            justify={"space-between"}
            align={'flex-start'}
            wrap={"wrap"}
            style={{flexDirection: "row-reverse"}}
        >
            <div style={{marginTop: 12}}>
                <SearchFilter/>
            </div>
            <div style={{marginTop: 12}}>
                <SortSelect/>
            </div>
        </Flex>
        {entityGroup === 'fund'
            && <CardTabSelect
                options={CARD_TAB_OPTIONS['fund']}
                activeTab={fundActiveTab}
                setActiveTab={setFundActiveTab}
            />
        }
        {entityGroup === 'portfolio'
            && <CardTabSelect
                options={CARD_TAB_OPTIONS['portfolio']}
                activeTab={portfolioActiveTab}
                setActiveTab={setPortfolioActiveTab}
            />
        }
    </Card>
}


export default TopPanel;
