import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const getWeeklyTypes = (typesHandler) => {
    let url = `/weekly/types`

    axiosInstance.get(url,
    {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    }).then(({data}) => {
        typesHandler(data);
    })
    .catch(({response}) => {
        console.log(response);
    })
};

export {
    getWeeklyTypes,
    cancel
}