import React, {useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import {RightOutlined} from "@ant-design/icons";

import "@components/portfolio/Portfolio.less";


const ExpandableContainer = ({text, infoComponent, expandableComponent, mark = null}) => {
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        setExpand(false);
    }, [expandableComponent, setExpand])

    const clickRow = () => {
        setExpand(!expand);
    }
    
    return (
        <>
            <Row className="portfolio-row-item supervision-row-header" onClick={clickRow}>
                <Col span={1} style={{textAlign: 'center'}}>
                    <RightOutlined rotate={expand ? 90 : 0} style={{color: 'red'}}/>
                </Col>
                <Col span={8}>
                    <Typography.Text key="name" className="portfolio-title-name">
                        {text} {mark}
                    </Typography.Text>
                </Col>
                <Col style={{marginLeft: "auto"}}>
                    <div>{infoComponent}</div>
                </Col>
            </Row>
            {expand && <div className="supervision-row-content">{expandableComponent}</div>}
        </>
    )
}

export default ExpandableContainer
