import React, {useEffect, useMemo, useState} from 'react';
import StockInfo from "@components/asset/StockInfo";
import MFETFInfo from "@components/asset/MFETFInfo";
import IndexInfo from "@components/asset/IndexInfo";
import {useSearchParams} from "react-router-dom";
import {describeWithAi, getDetails} from "@API/asset";
import {Alert, Card, Empty, message} from "antd";
import PortfolioInfo from "@components/asset/PortfolioInfo";
import AssetSearch from "@components/portfolio/position/new/AssetSearch";

const SEPARATOR = '__'
const KEYS_TO_PARAMS = ['ticker', 'gvkey', 'gvkeyx', 'iid', 'secid', 'security_type', 'definition_id']

const createParams = (asset, index) => {
    let paramObject = Object.keys(asset)
        .filter(key => KEYS_TO_PARAMS.includes(key) && asset[key] != null)
        .reduce((a, v) => ({
            ...a,
            [v + SEPARATOR + index]: asset[v]
        }), {})
    console.debug('paramObject is ' + JSON.stringify(paramObject))
    return paramObject
}

const mergeParams = (oldParams, newParams, index) => {
    let merged = Object.fromEntries(oldParams)

    //clearing prev params for that index
    for (let key in Object.keys(oldParams)) {
        if (key.includes(`${SEPARATOR}${index}`)) {
            delete merged[key]
        }
    }
    //merging new
    return {...merged, ...newParams}
}

const AssetLoader = ({index, asset}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [fullAsset, setFullAsset] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (asset == null) {
            return
        }
        setLoading(true)
        console.log('loading asset details for ' + JSON.stringify(asset))
        //describeWithAi
        getDetails(asset, (data, error) => {
            if (error) {
                setLoading(false);
                message.error("Error while retrieving asset data");
                return
            }
            setFullAsset(data);
            setLoading(false);
        });

    }, [asset])

    const neighbourChosen = (neighbour) => {
        let neighbourIndex = (index > 0) ? 0 : 1
        let params = createParams(neighbour, neighbourIndex)
        let newSearchParams = mergeParams(searchParams, params, neighbourIndex)
        setSearchParams(newSearchParams)
        window.scrollTo(0, 0);
    }

    const loadOpenApiData = (callback) => {
        describeWithAi(asset, (text, error) => {
            if (error) {
                setLoading(false);
                message.error("Error while retrieving AI data");
                return
            }
            callback(text)
        });
    }

    const AssetDataContainer = useMemo(() => {
        let container = <></>
        if (fullAsset == null) {
            return container
        }
        console.info(`updating asset container at index ${index} for ${JSON.stringify(asset)}`)
        console.debug('current full asset ticker is ' + JSON.stringify(fullAsset?.ticker))
        if (['Stock', 'ADR'].includes(fullAsset?.security_type)) {
            container = <StockInfo fullAsset={fullAsset} otherSelected={neighbourChosen} loadOpenApiData={loadOpenApiData}/>
        } else if (['ETF', "MF", 'MM'].includes(fullAsset?.security_type)) {
            container = <MFETFInfo fullAsset={fullAsset} otherSelected={neighbourChosen} loadOpenApiData={loadOpenApiData}/>
        } else if (['Index'].includes(fullAsset?.security_type)) {
            container = <IndexInfo fullAsset={fullAsset} loadOpenApiData={loadOpenApiData}/>
        } else if (fullAsset?.calculated_values) {
            container = <PortfolioInfo portfolio={fullAsset} loadOpenApiData={loadOpenApiData}/>
        }
        return <Alert.ErrorBoundary>{container}</Alert.ErrorBoundary>
    }, [fullAsset])

    const assetChosen = (asset) => {
        let params = createParams(asset, index)
        let newSearchParams = mergeParams(searchParams, params, index)
        setSearchParams(newSearchParams)
    }


    return (
        <div>
             <AssetSearch
                 onSelect={assetChosen}
                 allowCreate={false}
                 tableView={false}
                 byTickerOnly={false}
                 placeholder={"Search asset to show..."}
                 size={"large"}
             />
            <br/>
            {loading ? <Card loading={true}><Empty description={'No asset selected'}/></Card> : AssetDataContainer}
        </div>
    )

}

export default AssetLoader;
