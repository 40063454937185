const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const moneyCompactFormatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'currency',
    currency: 'USD',
});

const moneyCompactLongFormatter = new Intl.NumberFormat('en-US', {
    notation: "compact",
    compactDisplay: "long",
    style: 'currency',
    currencyDisplay: 'name',
    currency: 'USD',
});

const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});
const veryLowPriceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 5,
    maximumFractionDigits: 5
});
const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const softPercentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const oneDigitPercentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
});

const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const intNumberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    weekday: 'short'
};

const dateLongOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
};

const dateLongMMMOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
};


const numericDateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
}

const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
const dateLongFormatter = new Intl.DateTimeFormat('en-US', dateLongOptions);
const dateLongMMMFormatter = new Intl.DateTimeFormat('en-US', dateLongMMMOptions);
const numericDateFormatter = new Intl.DateTimeFormat('en-CA', numericDateOptions);
const shortNumberic = new Intl.NumberFormat('en', {notation: "compact", compactDisplay: "short"})

export const is_null = (value) => {
    return !(value || value === 0);
}


export const coalesce = (a, b) => {
    return is_null(a) ? b : a;
}


export default class ValueFormatter {

    static currency(value) {
        // return moneyFormatter.format(value);
        return value && isFinite(value) ? moneyFormatter.format(value) : '$0';
    }

    static currencyCustom(value, nanPlaceholer='$0') {
        // return moneyFormatter.format(value);
        return value && isFinite(value) ? moneyFormatter.format(value) : nanPlaceholer;
    }

    static compactCurrency(value) {
        return value && isFinite(value) ? moneyCompactFormatter.format(value) : '$0';
    }

    static compactLongCurrency(value) {
        return value && isFinite(value) ? moneyCompactLongFormatter.format(value) : '$0';
    }

    static price(value) {
        if (value && isFinite(value)) {
            return (value < 0.01 && value > 0) ? veryLowPriceFormatter.format(value) : priceFormatter.format(value)
        }
        return '$0'
    }

    static score(value) {
        return this.round(value, 0)
    }

    //takes weight as input
    static percent(value, defaultValue) {
        let fValue = percentFormatter.format(value);
        if (fValue.includes('NaN')) {
            if (isFinite(defaultValue)) {
                fValue = this.percent(defaultValue);
            } else {
                return ''
            }
        }

        return fValue
    }

    //takes int percents as input
    static int_percent(value, defaultValue) {
        return this.percent(value / 100)
    }

    static floorPercent(value, defaultValue) {
        if (typeof value === "number") {
            let fValue = Math.floor((value + Number.EPSILON) * 10000) / 10000;
            fValue = percentFormatter.format(fValue);
            return fValue
        }
        let fValue = percentFormatter.format(fValue);
        if (fValue.includes('NaN')) {
            if (isFinite(defaultValue)) {
                fValue = this.percent(defaultValue);
            } else {
                return ''
            }
        }

        return fValue
    }

    static softPercent(value, defaultValue) {
        let fValue = softPercentFormatter.format(value);
        if (fValue.includes('NaN')) {
            if (isFinite(defaultValue)) {
                fValue = this.softPercent(defaultValue)
            }
        }
        return fValue;
    }
    static oneDigitPercent(value, defaultValue) {
        let fValue = oneDigitPercentFormatter.format(value);
        if (fValue.includes('NaN')) {
            if (isFinite(defaultValue)) {
                fValue = this.oneDigitPercentFormatter(defaultValue)
            }
        }
        return fValue;
    }

    static percentParser(value) {
        let prValue = (value.replace('%', '').replace(',', ''))
        prValue = prValue / 100
        return prValue
    }

    static inputPercent(value) {
        if (value * 100 % 1 * 10 > 0.0000001) {
            return value.toFixed(2) + '%'
        }
        return value + '%'
    }

    static number(value) {
        // return numberFormatter.format(value);
        return (value != null && isFinite(value)) ? numberFormatter.format(value) : '0';
    }

    static int_number(value) {
        // return numberFormatter.format(value);
        return (value != null && isFinite(value)) ? intNumberFormatter.format(value) : '0';
    }

    static durationSeconds(seconds) {
        return seconds//durationFormatter.format({seconds: seconds})
    }

    static date(value, numeric = false) {
        if (numeric) {
            console.log(value, numericDateFormatter.format(value))
            return numericDateFormatter.format(value)
        } else {
            return dateFormatter.format(value);
        }
    }

    static dateLong(value) {
        return dateLongFormatter.format(value);
    }

    static dateLongMMM(value) {
        return dateLongMMMFormatter.format(value);
    }

    static pythonDateTimeShort(value, toLocal = true, defaultValue = "?") {
        try {
            let jsDate = Date.parse(value)
            let options = {
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false
            }

            if (toLocal) {
                options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            let formatter = new Intl.DateTimeFormat('en-US', options)
            return formatter.format(jsDate)
        } catch (e) {
            return defaultValue
        }
    }

    static pythonDateFancy(value, toLocal = true) {
        try {
            let jsDate = Date.parse(value)
            let options = {
                weekday: "long",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZoneName: 'short',
                hour12: false
            }

            if (toLocal) {
                options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            let formatter = new Intl.DateTimeFormat('en-US', options)
            return formatter.format(jsDate)
        } catch (e) {
            return '?'
        }
    }

    static pythonDate(value, toLocal = false, defaultValue = "?") {
        try {
            let jsDate = Date.parse(value)
            let options = {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short'
            }

            if (toLocal) {
                options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            let formatter = new Intl.DateTimeFormat('en-US', options)
            return formatter.format(jsDate)
        } catch (e) {
            return defaultValue
        }
    }

    static stringDate(value) {
        if (value && value.includes('T')) {
            return value.split('T')[0];
        } else {
            return '-';
        }
    }

    static spaceDateTime(value) {
        if (value && value.includes(' ')) {
            return value.split(' ')[0];
        } else {
            return '-';
        }
    }

    /**
     * @argument x число
     * @argument n количество знаков после запятой
     */
    static round(x, n, nonan = false, defaultValue="") {
        // if (isNaN(x) || isNaN(n)) return false;
        const m = 10 ** n;
        const result = Math.round(x * m) / m;
        if (nonan) {
            if (isNaN(result)) {
                return defaultValue
            }
        }
        return result
    }

    static decodeCurrency(value) {
        let regexp = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
        if (value.includes('$0')) {
            return parseInt(value.replace('$0', ''), 10);
        } else {
            return parseInt(value.replace(regexp, ''), 10);
        }
    }

    static decodePrice(value) {
        let regexp = /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi;
        if (value.includes('$0')) {
            return parseFloat(value.replace('$', ''), 10);
        } else {
            return parseFloat(value.replace(regexp, ''), 10);
        }
    }

    static decodePercent(value) {
        const validate = /^[0-9]+(\.[0-9]{1,2})?[%]$/;
        if (validate.test(value)) {
            let regexp = /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi;
            return parseFloat(value.replace(regexp, ''), 10);
        }
    }

    static dateToAge(date) {
        let d1 = new Date();
        let d2 = new Date(date);

        return Math.floor((d1 - d2) / 31536000000)
    }

    static extractTextInParentheses(title) {

        const regex = /\(([^)]+)\)/;
        const match = title.match(regex);

        if (match) {
            return match[1];
        }

        return title;
    }

    static entityNumber(number, entity) {
        if (number < 1) {
            return `0 ${entity}s`
        } else if (number === 1) {
            return `1 ${entity}`
        }
        return `${number} ${entity}s`
    }
}
