import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    message,
    Modal,
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {saveReportPreset} from "@API/report";


const CreateTemplateModal = ({
                               open,
                               onCancel,
                               onSave,
                               reportClass,
                               sections,
                               name = null,
                               description = null
                           }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        setLoading(true)
        const newKey = values["name"].toLowerCase().replaceAll(" ", "_") + "_" + uuidv4()
        saveReportPreset({
            title: values["name"],
            description: values["description"],
            key: newKey,
            sections: sections,
            type: "user",
            report_class: reportClass
        }, () => {
            message.success("Template was saved successfully!")
            onSave(newKey);
            setLoading(false)
        })
    }

    useEffect(() => {
        form.setFieldsValue({name: name, description: description})
    }, [name, description])


    return <Modal
        open={open}
        title={null}
        closable={false}
        onOk={form.submit}
        onCancel={onCancel}
        okText="Save"
        okButtonProps={{loading: loading}}
    >
        <Form form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
        >
            <Form.Item
                name="name"
                label="Template name"
                requiredMark={false}
                rules={[{
                    required: true,
                    message: 'Fill in template name',
                }]}
            >
                <Input placeholder="Name" value={name}/>
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                required={false}
            >
                <Input placeholder="Description" value={description}/>
            </Form.Item>
        </Form>
    </Modal>


}

export default CreateTemplateModal;
