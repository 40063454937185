import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {Col, Divider, Form, InputNumber, Rate, Row, Select} from "antd";
import {AimOutlined, CaretRightOutlined} from "@ant-design/icons";
import React from "react";
import {roles} from "@components/holistico/proposal/view/ai/role_models";

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
}

const roleModels = [{
    label: 'eyashin',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {}
}]

const prepareKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
}
const ConfigurationForm = ({aiSettings, modifyAiSettings}) => {
    const [form] = Form.useForm();
    if (aiSettings == null) {
        return null
    }
    return <Form
        form={form}
        layout={'vertical'}
        initialValues={aiSettings}
        requiredMark={false}
        size={"small"}
        onValuesChange={(changes, allValues) => {
            if (changes.role_model) {
                //role_model change is being processed directly by the component
                return
            }
            modifyAiSettings(allValues)
        }}
        onFinish={(values) => {
            let formValues = {...values}
            modifyAiSettings(formValues)
        }}
    >
        <Row>
            <Col span={24} style={{height: 80, width: '100%'}}>
                <Form.Item extra={'select the AI role model'} name={'role_model'}>
                    <Select bordered={true} size={"large"} style={{width: '100%'}}
                            onChange={(value) => {
                                let roleModel = roles.find(role => role.label === value)
                                for (let key of Object.keys(roleModel.character_params)) {
                                    form.setFieldValue(
                                        ['character_params', key], roleModel.character_params[key]
                                    )
                                    console.log(`setting ${key} to ${roleModel.character_params[key]}`)
                                    form.submit()
                                }

                            }}>
                        {roles.map(roleModel => {
                            return <Select.Option key={roleModel.label}
                                                  value={roleModel.label}>{roleModel.name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
            <Divider style={{marginTop: 4, marginBottom: 4}}/>
            <Col span={24} style={{marginTop: 10}}>

                <Form.Item hidden={true} name={"model"} label={"Ai Model"}>
                    <Select options={[{
                        value: 'gtp-4',
                        label: 'GPT',
                    }, {
                        value: 'claude',
                        label: 'Claude AI'
                    }]}/>
                </Form.Item>
                <Form.Item hidden={true} name={"max_tokens"} label={"Answer size"}>
                    <InputNumber/>
                </Form.Item>
                {Object.keys(aiSettings?.character_params)?.map(key => {
                    return <Form.Item
                        key={key}
                        label={prepareKey(key)}
                        name={['character_params', key]}
                    >
                        <Rate count={10} character={<CaretRightOutlined />} style={{fontSize: 24, color: '#93C2F0'}}/>
                    </Form.Item>
                })}

            </Col>
        </Row>
    </Form>
}

export default ConfigurationForm;
