import React, {useContext, useEffect, useState} from 'react';


import {Col, Collapse, Layout, message, Modal, Row, Spin, Tooltip, Typography} from 'antd';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import {getModels} from "@API/portfolio";
import PortfolioSelector from "@components/portfolio/selector/PortfolioSelector";
import LStorage from "@global/Storage";
import {PlayCircleTwoTone} from "@ant-design/icons";
import {clientPortfolioClone} from "@API/clientPortfolio";

const {Content, Header} = Layout;

const {Panel} = Collapse;
const {Paragraph, Text, Title} = Typography;
const {confirm} = Modal;
const ClientPortfolioSelector = ({setActual, setBenchmark, onlyActual = false, currentTitle = 'Current'}) => {
    const [modal] = Modal.useModal();
    const {client, loadData, calculated_values, proposed_calculated_values} = useContext(SpasClientContext);
    const [loading, setLoading] = useState(false)
    const [actuals, setActuals] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [benchmarks, setBenchmarks] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState({
        name: 'Unknown', calculated_values: {}
    })
    const [selectedBenchmark, setSelectedBenchmark] = useState({
        name: 'Unknown', calculated_values: {}
    })

    const AddAsProposal = ({}) => {
        const setClientProposal = () => {
            const instance = confirm({
                title: 'Add as client proposed portfolio',
                content: `Selected benchmark will be added to client proposals and can be managed later in "Proposal" tab. The market value for this portfolio will be set to client's market value.`,
                onOk() {
                    return new Promise((resolve, reject) => {
                        clientPortfolioClone(client._id, selectedBenchmark._id, 'prop', false, calculated_values.market_value, (result, error) => {
                            if (!error) {
                                loadData()
                                message.success('Successfully set the proposal!');
                                resolve()
                            } else {
                                message.error(result.data.message);
                                reject(result.data.message)
                            }
                        })
                    }).catch(() => console.log('Oops errors!'));
                },
            });
        }
        return <Tooltip title={"Set as proposal"} color={'#E0AD60'}>
            <PlayCircleTwoTone style={{paddingLeft: 8, cursor: 'pointer'}} twoToneColor="#3196B2"
                               onClick={setClientProposal}/>
        </Tooltip>
    }

    const fetchModelData = () => {
        setLoading(true)
        getModels(true, null, (data, err) => {
            if (!err) {
                setBenchmarks(data)

                if (data.length == 0) {
                    setLoading(false)
                    return
                }

                if (client.proposed_portfolios.length == 0) {
                    let benchmark_to_use = data[0]
                    if (calculated_values?.risk?.risk_score) {
                        let client_risk = calculated_values?.risk?.risk_score

                        let best_diff = 100
                        for (let benchmark of data) {
                            let bench_risk = benchmark?.calculated_values?.risk?.risk_score
                            let diff = Math.abs(client_risk - bench_risk)
                            if (diff < best_diff) {
                                best_diff = diff
                                benchmark_to_use = benchmark
                            }
                        }
                    }
                    console.log('just set selected benchmark to ' + benchmark_to_use.name)
                    benchmarkSelected(benchmark_to_use)
                }

            } else {
                message.error('Failed to load models!')
            }
            setLoading(false)
        });
    }

    const benchmarkSelected = (benchmarkModel) => {
        setSelectedBenchmark(benchmarkModel)
        setBenchmark(benchmarkModel)

        LStorage.setDefaultFactorBenchmark(benchmarkModel._id)
    }

    const actualSelected = (portfolio) => {
        setSelectedPortfolio(portfolio)
        setActual(portfolio)
    }

    useEffect(() => {
            !onlyActual && fetchModelData();
            if (!isNaN(proposed_calculated_values?.risk?.risk_score)) {
                let proposals_p = []
                if (client.proposed_portfolios.length > 1) {
                    let ids = client.proposed_portfolios.map(p => p._id)
                    proposals_p.push({
                        name: 'Full Proposal',
                        calculated_values: {...proposed_calculated_values},
                        _id: ids.join()
                    })
                }
                proposals_p.push(...client.proposed_portfolios)
                !onlyActual && benchmarkSelected(proposals_p[0])
                setProposals(proposals_p)
            }

            if (!isNaN(calculated_values?.risk?.risk_score)) {
                let actuals_p = []
                if (client.current_portfolios.length > 1) {
                    let ids = client.current_portfolios.map(p => p._id)
                    actuals_p.push({
                        name: client.full_name + ', summary',
                        calculated_values: {...calculated_values},
                        _id: ids.join()
                    })
                }
                actuals_p.push(...client.current_portfolios)
                actualSelected(actuals_p[0])
                setActuals(actuals_p)
            }

        },
        [client])

    return (
        <Spin style={{height: 150}} spinning={loading} tip="Loading..." size={"large"}>
            <Row gutter={12}>
                <Col span={onlyActual ? 24 : 12}>
                    {currentTitle && <Title level={5}>{currentTitle}</Title>}
                    <PortfolioSelector selected={selectedPortfolio} portfolios={actuals} proposedPortfolios={proposals}
                                       setSelectedPortfolio={actualSelected} showEmpty={false}/>
                </Col>
                {!onlyActual &&
                    <Col span={12}>
                        <Title level={5}>
                            Benchmark
                            {['model', 'benchmark'].includes(selectedBenchmark.type) && <AddAsProposal/>}
                        </Title>
                        <PortfolioSelector selected={selectedBenchmark} portfolios={proposals}
                                           proposedPortfolios={benchmarks}
                                           setSelectedPortfolio={benchmarkSelected} showEmpty={false}
                                           showMarketValue={false}/>
                    </Col>
                }
            </Row>
        </Spin>
    )
}

export default ClientPortfolioSelector;
