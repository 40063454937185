/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {Col, Row, Typography, Empty} from "antd";
import '@root/components/portfolio/Portfolio.less';
import CreditRatingChart from "@components/chart/CreditRatingChart";
import MaturityDateBar from "@components/chart/MaturityDateBar";
import FISectorPie from "@components/chart/FISectorPie";

const {Title} = Typography;
const PlaygroundFixedIncomeAnalysis = ({selectedPortfolio, selectedBenchmark, loading}) => {

    const CreditRating = ({portfolio, bar = false}) => {
        const fi_credit_rating_allocation = portfolio?.calculated_values?.fi_credit_rating
        return <CreditRatingChart allocation={fi_credit_rating_allocation} loading={loading}/>
        // if (fi_credit_rating_allocation && Object.keys(fi_credit_rating_allocation).length > 0) {
        //     if (!bar) {
        //         return <CreditRatingChart allocation={fi_credit_rating_allocation} loading={loading}/>
        //     } else {
        //         return <CreditRatingChart allocation={fi_credit_rating_allocation} loading={loading}/>
        //     }

        // } else {
        //     return null
        // }
    }

    const ratingsAvailable = (portfolio) => {
        const fi_credit_rating_allocation = portfolio?.calculated_values?.fi_credit_rating

        if (fi_credit_rating_allocation && Object.keys(fi_credit_rating_allocation).length > 0) {
            return true
        } else {
            return false
        }
    }


    return (<>
        <Title level={3}>Fixed Income</Title>
        {ratingsAvailable(selectedPortfolio) || ratingsAvailable(selectedBenchmark) ?
            <div>
                <Row gutter={12}>
                    <Col span={24}>
                        <Title level={4} className={"spas"}>Credit rating</Title>
                    </Col>
                    {/*<Col span={24}>
                        <SectorBar loading={false} allocation={selectedPortfolio.calculated_values['fi_credit_rating']}
                                   proposalAllocation={selectedBenchmark?.calculated_values?.fi_credit_rating}/>
                    </Col>*/}
                    <Col span={12}>
                        <CreditRating portfolio={selectedPortfolio}/>
                    </Col>
                    <Col span={12}>
                        <CreditRating portfolio={selectedBenchmark}/>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <br/>
                        <Title level={4} className={"spas"}>Sector</Title>
                    </Col>
                    <Col span={12}>
                        <FISectorPie loading={false}
                                     allocation={selectedPortfolio?.calculated_values?.fi_super_sector}/>
                    </Col>
                    <Col span={12}>
                        <FISectorPie loading={false}
                                     allocation={selectedBenchmark?.calculated_values?.fi_super_sector}/>
                    </Col>
                    {/*<Col span={24}>
                        <SectorBar loading={false} allocation={selectedPortfolio.calculated_values['fi_super_sector']}
                                   proposalAllocation={selectedBenchmark?.calculated_values?.fi_super_sector}/>
                    </Col>*/}
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <br/>
                        <Title level={4} className={"spas"}>Maturity date</Title>
                    </Col>
                    <Col span={24}>
                        <MaturityDateBar loading={loading}
                                         allocation={selectedPortfolio?.calculated_values?.fi_effective_maturity}
                                         proposalAllocation={selectedBenchmark?.calculated_values?.fi_effective_maturity}/>
                    </Col>
                    {/*<Col span={12}>
                        <MaturityTimeline loading={false}
                                          allocation={selectedPortfolio?.calculated_values?.fi_effective_maturity}/>
                    </Col>
                    <Col span={12}>
                        <MaturityTimeline loading={false}
                                          allocation={selectedBenchmark?.calculated_values?.fi_effective_maturity}/>
                    </Col>*/}
                </Row>
            </div> : <Empty style={{margin: "15px 0"}} description="Fixed income analysis is not available"/>
            }
    </>)
}

export default PlaygroundFixedIncomeAnalysis;
