import {Alert, Button, Col, Divider, Row, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import React, {useEffect, useState} from "react";
import ActionInfo from "@holistico/aux/ActionInfo";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import RiskStatisticPanel, {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/RiskStatisticPanel";
import ModelsCarousel
    from "@holistico/proposal/view/client/ModelsCarousel";
import RiskToleranceSlider
    from "@holistico/proposal/view/client/risk_tolerance/RiskToleranceSlider";
import PerformanceStatistics
    from "@holistico/proposal/view/client/risk_tolerance/PerformanceStatistics";
import {NavLink} from "react-router-dom";
import {showAi, ShowAiButton} from "@holistico/proposal/view/ai/AiSideBar";


const RiskTolerance = () => {
    const {proposal, loading, updateQuestionnaire} = useHolisticoProposalContext()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [willingnessScore, setWillingnessScore] = useState(60)
    const willingnessLevel = getToleranceLevelByRiskScore(willingnessScore)
    const current = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score
    const ability = proposal?.questionnaire?.risk_profile?.ability
    const behavioral = proposal?.questionnaire?.risk_profile?.behavioral
    const willingnessScoreInitial = proposal?.questionnaire?.risk_profile?.willingness
    const sliderDefaultValue = willingnessScoreInitial ?? behavioral ?? ability ?? current ?? 60
    const _showAi = showAi()

    const onChange = (value) => {
        setWillingnessScore(value)
    };

    const onSave = () => {
        setButtonLoading(true)
        if (willingnessScore) {
            updateQuestionnaire({risk_profile: {willingness: willingnessScore}}, true, () => {
                setButtonLoading(false)
            })
        }
    }

    useEffect(() => {
        const current = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score
        const ability = proposal?.questionnaire?.risk_profile?.ability
        const behavioral = proposal?.questionnaire?.risk_profile?.behavioral
        const willingnessScoreInitial = proposal?.questionnaire?.risk_profile?.willingness
        setWillingnessScore(willingnessScoreInitial ?? behavioral ?? ability ?? current ?? 60)
    }, [proposal])

    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Risk tolerance
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            {proposal && (ability || behavioral)
                && <>
                    <RiskStatisticPanel active="willingness"/>
                    <div style={{padding: "32px 28px 72px 28px"}}>
                        <ActionInfo>
                            Choose the risk that the client agrees to take
                        </ActionInfo>
                        <Row gutter={[24, 24]}>
                            <Col xl={10} lg={_showAi ? 24 : 10} md={24} sm={24} xs={24}>
                                <RiskToleranceSlider
                                    defaultValue={sliderDefaultValue}
                                    onChange={onChange}
                                />
                            </Col>
                            <Col xl={14} lg={_showAi ? 24 : 14} md={24} sm={24} xs={24}
                                 style={{
                                     display: "flex",
                                     flexDirection: "column",
                                 }}
                            >
                                <Row style={{paddingTop: "32px"}}>
                                    <Col span={24}>
                                        <PerformanceStatistics riskScore={willingnessScore}/>
                                    </Col>
                                </Row>
                                <Divider/>
                                <RiskResult
                                    title={`Risk willingness score: ${willingnessScore}`}
                                    description={`${willingnessLevel} risk willingness`}
                                    success={willingnessScoreInitial === willingnessScore}
                                    tooltip={willingnessScoreInitial === willingnessScore ? null : "Save to apply changes"}
                                >
                                     <Button
                                         type="primary"
                                         size="large"
                                         style={{width: "200px"}}
                                         onClick={onSave}
                                         loading={buttonLoading}
                                         disabled={willingnessScoreInitial === willingnessScore}
                                     >
                                         Save
                                     </Button>
                                </RiskResult>
                            </Col>
                        </Row>
                    </div>
                    {!loading
                        && <ModelsCarousel
                            riskScore={willingnessScore}
                            active={!!willingnessScoreInitial}
                            inactiveMessage={"Models will become available after confirming risk willingness score"}
                        />
                    }
                </>
            }
            {proposal && !ability && !behavioral
                && <>
                    <RiskStatisticPanel active="willingness"/>
                    <div style={{margin: "16px"}}>
                    <Alert
                        message="Questionnaire not filled"
                        description={
                            <div>
                                {"This section will become available after filling the "}
                                <NavLink
                                    to={`../client/general-info`}>
                                    Questionnaire
                                </NavLink>
                                {" or passing the "}
                                <NavLink
                                    to={`../client/behavioral-quiz`}>
                                    Behavioral quiz
                                </NavLink>
                                .
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                </div>
                </>
            }
        </div>
    </>
}

export default RiskTolerance;
