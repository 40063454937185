import React, {useEffect, useState} from "react";
import {Menu, Typography} from "antd";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import RedFlagReport from "@components/supervision/red_flag/RedFlagReport";
import RepresentativesReport from "@components/supervision/representative/RepresentativesReport";
import ClientReport from "@components/supervision/client/ClientReport";
import GlobalConfigs from "./global_configs/GlobalConfigs";
import ClientLimitsReport from "@components/supervision/client_limits/ClientLimitsReport";
import {useAuth} from "@hooks/AuthHook";
import SummaryDashboard from "@components/supervision/summary/SummaryDashboard";
import Tutorial from "@components/supervision/tutorial/Tutorial";
import {getHiddenHref} from "@global/Utils";

const {Title} = Typography;

const getMenuItems = (auth) => {
    let menuItems = [{
        key: "summary",
        label: "Summary",
    }, {
        key: "representatives",
        label: "Representatives",
    }, {
        key: "flags",
        label: "Checks",
    },
        {
            key: "client",
            label: "Clients"
        }, {
            key: "limits",
            label: "Custom limits"
        }]

    if (auth.isSupervisor()) {
        menuItems.push({
            key: "configs",
            label: "Configurations"
        })
    }

    menuItems.push({
        key: "tutorial",
        label: "Tutorial"
    })

    for (let item of menuItems) {
        item.label = getHiddenHref(item.label, item.key, "/wealthmonitor/")
    }

    return menuItems
}
const SupervisionRoutes = () => {
    const auth = useAuth();
    const [selectedKeys, setSelectedKeys] = useState([""]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let path = location.pathname.split("/").at(-1);
        setSelectedKeys([path]);
    }, [location.pathname])

    const onClick = (e) => {
        console.log('client menu click ', e);
        navigate(e.key)
    };

    return (
        <div>
            <Title level={3}>Compliance Master</Title>
            {/*<Typography.Paragraph underline>Last run: 10/25/2022</Typography.Paragraph>*/}
            <Menu
                style={{lineHeight: '30px'}}
                className="secondary-menu"
                mode="horizontal"
                items={getMenuItems(auth)}
                onClick={onClick}
                theme="dark"
                selectedKeys={selectedKeys}
            />
            <div style={{paddingTop: '30px'}}>
                <Routes>
                    <Route exact path="" element={<Navigate to="summary" replace/>}/>
                    <Route exact path="summary" element={<SummaryDashboard/>}/>
                    <Route path="flags" element={<RedFlagReport/>}/>
                    <Route path="representatives" element={<RepresentativesReport/>}/>
                    <Route path="client" element={<ClientReport/>}>
                        <Route path=":clientId" element={<ClientReport/>}/>
                    </Route>
                    <Route path="configs" element={<GlobalConfigs/>}/>
                    <Route path="limits" element={<ClientLimitsReport/>}/>
                    <Route path="tutorial" element={<Tutorial/>}/>
                </Routes>
            </div>
        </div>
    )
}

export default SupervisionRoutes
