import React from "react";
import {Checkbox, Flex, Form, Space} from 'antd';


const SectorInput = ({allSectors, hidden=false}) => {
    return <Form.Item
        name={"sector"}
        label={<b>Sector</b>}
        hidden={hidden}
    >
        <Checkbox.Group>
            <Flex vertical>
                {allSectors.map(sector => {
                    return <Checkbox key={sector} value={sector}>
                        {sector}
                    </Checkbox>
                })}
            </Flex>
        </Checkbox.Group>
    </Form.Item>
}

export default SectorInput
