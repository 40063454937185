import React, {useEffect} from "react";
import {Anchor, Row, Col, Card, Typography} from "antd";
import {items} from "@holistico/tutorial/items";
import { useLocation } from "react-router-dom";
import "./Tutorial.less"
import {debounce} from "@global/Utils";

const {Link} = Anchor;


const ListOfContents = ({items}) => {
    return <Anchor affix={true} className={"tutorial-list-of-contents"}>
            {items.map(item => {
                return <div key={item.key}>
                    {item.type && item.type === "divider"
                        ?
                        <span className={"tutorial-list-of-contents-divider"}>
                            {item.title}
                        </span>
                        :
                        <Link
                            href={"#" + item.key}
                            title={item.title}
                        >
                            {item.children
                                &&
                                item.children.map(child => {
                                    return <Link
                                        key={child.key}
                                        href={"#" + child.key}
                                        title={child.title}
                                    />
                                })
                            }
                        </Link>
                    }
                </div>
            })}
    </Anchor>
}


const Content = ({items}) => {
    return items.map(item => {
        return <div key={item.key}>
            {!item.type
                &&
                <Card hoverable className={"tutorial-page"}>
                    <Typography.Title level={4} id={item.key}>
                        {item.path && item.path + " / "}{item.title}
                    </Typography.Title>
                    {item.content
                        &&
                        <div className={"tutorial-level-1-content"}>
                            {item.content}
                        </div>
                    }
                    {item.children
                        &&
                        item.children.map(child => {
                            return <div key={child.key}
                                        className={"tutorial-level-1-child"}>
                                <Typography.Title level={5} id={child.key}>
                                    {child.title}
                                </Typography.Title>
                                {child.content
                                    &&
                                    <div className={"tutorial-level-2-content"}>
                                        {child.content}
                                    </div>
                                }
                            </div>
                        })
                    }
                </Card>
            }
        </div>
    })
}


const Tutorial = () => {
    const { hash } = useLocation();

    useEffect(() => {
        // necessary for external links
        if (hash) {
            const section = document.querySelector(hash);
            if (section) {
                section.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        }
    }, [])

    return <div>
        <Typography.Title level={3} className={"page-title"}>
            Tutorial
        </Typography.Title>
        <Row gutter={[24, 24]}>
            <Col span={6}>
                <ListOfContents items={items}/>
            </Col>
            <Col span={18}>
                <Content items={items}/>
            </Col>
        </Row>
    </div>
}

export default Tutorial;
