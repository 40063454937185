import React from "react";
import {Tag, Tooltip} from "antd";

import "@components/supervision/Supervision.less";

const ClientRedFlagInfo = ({raised, muted, limitSettings}) => {
    let status = muted ? "MUTED" : (raised ? "FAILED" : "PASSED")
    let limitType = limitSettings.type
    let hasClientLimit = limitSettings.client != null ? true : false

    let customStyle = {marginRight: "10px", width: "80px", fontSize: "0.8em", textAlign: "center"}
    let color = 'red'
    let help = "The check failed. "
    if (status == "MUTED") {
        if (raised) {
            help = "The check failed, but it is not active for this client and can be ignored."
        } else {
            help = "Everything is all right, however this check is not active for this client anyway."
        }
    } else {
        if (raised) {
            
        } else {
            help = "Everything is on track! "
            color = 'green'
        }
        if (hasClientLimit) {
            let limitText = limitType == 'global' ? 'Custom client settings ignored.' : 'Custom client settings applied.'
            //customStyle['border-color'] = 'gold'
            help+=limitText
        }
    }

    return (
        <Tooltip title={help}>
            <Tag color={color}
                 style={customStyle}>
                {status}
            </Tag>
        </Tooltip>
    )
}

export default ClientRedFlagInfo
