/* eslint-disable react/prop-types */
import {Col, InputNumber, Row, Select, Typography, Tooltip} from 'antd';
import * as React from "react";
import {useEffect, useState} from "react";
import './RetirementOptions.less';
import ValueFormatter from "@global/ValueFormatter";
import {QuestionCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

// eslint-disable-next-line react/prop-types
const RetirementOptions = ({title = "Retirement Details", retirementConfiguration, handleChange}) => {
    const [localOptions, setLocalOptions] = useState({});

    // const percentFields = ['inflation_rate', 'savings_growth', 'management_fee']

    useEffect(() => {
        const locOptProjection = {
            ...retirementConfiguration,
        }

        if (!isFinite(locOptProjection.management_fee)) {
            locOptProjection.management_fee = 0.5
        }

        locOptProjection.ignore_extreme = (locOptProjection.ignore_extreme == true)
        locOptProjection.nominal_values = (locOptProjection.nominal_values == true)

        setLocalOptions(locOptProjection)
    }, [retirementConfiguration])

    const fieldChanged = (name, value) => {
        setLocalOptions({...localOptions, [name]: value})
        console.log(name, value)
        handleChange(name, value)
    }


    const retirementOptionsHeader = <Row responsive={"true"}>
        <Typography.Title level={4} style={{marginBottom: 0}} className={'spas'}>{title}</Typography.Title>
    </Row>

    return (
        <>
            <Typography.Title level={4} style={{marginBottom: 0}} className={'spas'}>{title}</Typography.Title>
            <Row gutter={[16, 16]} className={'retirement-options'}>
                {/*<Col md={8} lg={8} sm={24} xs={24}>
                    <label>Retirement goal</label>
                    <InputNumber
                        placeholder="Desired money at retirement" formatter={ValueFormatter.currency}
                        name={"wanted_at_retirement"}
                        step={1000}
                        value={localOptions['wanted_at_retirement']}
                        onChange={value => fieldChanged('wanted_at_retirement', value)}
                    />
                </Col>*/}
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Investment amount</label>
                    <InputNumber
                        placeholder="Investment amount" formatter={ValueFormatter.currency}
                        name={"investment_amount"}
                        step={1000}
                        value={localOptions['investment_amount']}
                        onChange={value => fieldChanged('investment_amount', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Monthly Savings</label>
                    <InputNumber
                        step={1000}
                        placeholder="Monthly savings" formatter={ValueFormatter.currency} name={"monthly_savings"}
                        value={localOptions['monthly_savings']}
                        onChange={value => fieldChanged('monthly_savings', value)}
                    />

                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Monthly Withdrawals</label>
                    <InputNumber
                        step={1000}
                        placeholder="Monthly withdrawals" name={"monthly_withdrawals"}
                        formatter={ValueFormatter.currency}
                        value={localOptions['monthly_withdrawals']}
                        onChange={value => fieldChanged('monthly_withdrawals', value)}
                    />
                </Col>

            </Row>
            <Row gutter={[16, 16]} className={'retirement-options'}>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Year of birth</label>
                    <InputNumber
                        placeholder="Birth year"
                        name={"birth_year"}
                        defaultValue={1950}
                        value={localOptions['birth_year']} onChange={value => fieldChanged('birth_year', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Retirement year</label>
                    <InputNumber
                        placeholder="Retirement year" name={"retirement_year"}
                        value={localOptions['retirement_year']}
                        onChange={value => fieldChanged('retirement_year', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Life Expectancy</label>
                    <InputNumber
                        placeholder="Life expectancy" name={"life_expectancy"}
                        value={localOptions['life_expectancy']}
                        onChange={value => fieldChanged('life_expectancy', value)}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className={'retirement-options'}>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Ann. savings increase, %</label>
                    <InputNumber
                        step={0.1}
                        placeholder="Annual savings increase" name={"savings_growth"}
                        value={localOptions['savings_growth']}
                        onChange={value => fieldChanged('savings_growth', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Inflation rate, %</label>
                    <InputNumber
                        step={0.1}
                        placeholder="Inflation rate" name={"inflation_rate"}
                        value={localOptions['inflation_rate']}
                        onChange={value => fieldChanged('inflation_rate', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>
                        Management fee, %  <Tooltip title="This value will be used in report as a proposal fee">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </label>
                    <InputNumber
                        step={0.1}
                        placeholder="Management fee" name={"management_fee"}
                        value={localOptions['management_fee']}
                        onChange={value => fieldChanged('management_fee', value)}
                    />
                </Col>
            </Row>
        </>
    )

}

export default RetirementOptions
