import {Badge, Tag, Tooltip} from "antd";
import {gold} from "@ant-design/colors";
import React from "react";
import {colorDetector} from "@components/supervision/report_constructor/tree_utils/utils";

const RepresentativeConfiguredClients = ({flagTitle, configuredClientsCount, allClientsCount, dependentColor = false}) => {
    if (configuredClientsCount == 0) {
        return <Badge status={'success'} style={{cursor: 'pointer', opacity: 0.5}}
                      onClick={() => {
                          console.info("table badge clicked")
                      }}/>
    }
    let clients = configuredClientsCount == 1 ? 'client' : 'clients'
    let have = configuredClientsCount == 1 ? 'has' : 'have'
    let cCount = configuredClientsCount

    let color = 'gold'
    if (dependentColor) {
        color = colorDetector(configuredClientsCount, allClientsCount, 0.08)
    }
    
    let ttl = `${configuredClientsCount} of ${allClientsCount} ${clients} ${have} "${flagTitle}" mutes or limits set.`
    return <Tooltip title={ttl} color={gold[5]}>
        <Tag color={color}>{configuredClientsCount}</Tag>
    </Tooltip>
}

export default RepresentativeConfiguredClients
