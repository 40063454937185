import React, {useContext, useEffect, useState} from 'react';
import {List, Modal, Progress, Spin, Typography} from 'antd';
import {getModels} from "@API/portfolio";
import ValueFormatter from "@global/ValueFormatter";
import PortfolioListItem from "@components/portfolio/list/PortfolioListItem";
import MainSearch from "@components/clients/MainSearch";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {
    PortfolioListContext
} from "@components/portfolio/contexts/PortfolioListContext";


// eslint-disable-next-line react/prop-types
const CloneModelModal = ({onFinish, open=false}) => {
    const {addFromOther} = useContext(PortfolioListContext);
    const [loading, setLoading] = useState(false);
    const [portfolios, setPortfolios] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [loadPercent, setLoadPercent] = useState([]);
    const [searchString, setSearchString] = useState(null);

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: !inProgress,
        title: null,
        footer: null,
        onCancel: v => onFinish(),
        style: {background: "none"}
    }

    useEffect(() => {
        setLoading(true);
        const controller = new AbortController()
        getModels(false, searchString, (result, error) => {
            if (!error) {
                setPortfolios(result);
            }
            setLoading(false);
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }, [searchString]);


    const clone = (index, portfolio) => {
        setInProgress(true)
        setLoadPercent(0)
        let progressInterval = progressIntervalCaller();

        addFromOther(portfolio._id, false, 100.0, () => {
             setInProgress(false);
             onFinish();
             clearInterval(progressInterval);
        });
    }

    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    return <>
        <Modal {...modalProps} open={open} className={"holistico"}>
            {inProgress
                ?
                <>
                    <Typography.Text>Cloning model portfolio...</Typography.Text>
                    <Progress percent={loadPercent} status="active" strokeColor='#E0AD60'/>
                </>
                :
                <>
                    <MainSearch
                        fetchData={(value) => {setSearchString(value)}}
                        placeholder={"Type in the model name to search"}
                    />
                    <Spin spinning={loading} tip="Searching model portfolios..." size={"large"}>
                        {portfolios && portfolios.length !== 0
                            ?
                            <List
                                dataSource={portfolios}
                                renderItem={(item, index) => {
                                    return <PortfolioListItem
                                        key={index}
                                        index={index}
                                        portfolio={item}
                                        multi={false}
                                        select={clone}
                                        selected={false}
                                        showMarketValue={false}
                                    />
                                }}
                                style={{maxHeight: "70vh", overflow: "scroll"}}
                            />
                            :
                            <NoDataPlaceholder description="No models found..."/>
                        }
                    </Spin>
                </>
            }
        </Modal>
    </>
}

export default CloneModelModal;
