const downloadFile = (blob_data, file_name, file_type, callback) => {
    const blob = new Blob([blob_data], { type: file_type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file_name;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    callback();
}

export { downloadFile }