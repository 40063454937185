import React from "react";
import SupervisionRoutes from "@components/supervision/SupervisionRoutes";

const Supervision = () => {
    return (
        <SupervisionRoutes/>
    )
}

export default Supervision
