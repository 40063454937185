import React, {useState} from "react";
import {
    Card,
    Flex, List, Spin,
    theme,
    Typography
} from 'antd';
import Icon from "@ant-design/icons";
import {useRequest, useUpdateEffect} from "ahooks";
import {cancel, getSectorPrognosisAsync} from "@API/ahooks/dashboard";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {ReactComponent as ExtraBad} from './sector/icons/thunderstorms-night-rain.svg'
import {ReactComponent as SuperBad} from './sector/icons/semi-bad.svg'
import {ReactComponent as SemiBad} from './sector/icons/rain.svg'
import {ReactComponent as Norm} from './sector/icons/partly-cloudy-day.svg'
import {ReactComponent as Okay} from './sector/icons/horizon.svg'
import {ReactComponent as Good} from './sector/icons/clear-day.svg'


const {useToken} = theme;


const WeatherIcon = ({score_value}) => {
    let component;
    if (score_value < 20) {
        component = ExtraBad;
    } else if (score_value < 30) {
        component = SuperBad;
    } else if (score_value < 40) {
        component = SemiBad;
    } else if (score_value < 60) {
        component = Norm;
    } else if (score_value < 70) {
        component = Okay;
    } else {
        component = Good;
    }

    return <Icon component={component} style={{fontSize: '28px'}}/>
}


const SectorCard = ({}) => {
    const [data, setData] = useState([]);
    const {token} = useToken();

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getSectorPrognosisAsync(cancel));

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('unknown error')
        }
    }, [error])

    useUpdateEffect(() => {
        setData(response.data);
        console.debug("Sector forecast loaded", response.data);
    }, [response])

    return <Card hoverable style={{
        height: "100%",
        cursor: "default",
    }}>
        <Typography.Title level={4} style={{marginTop: 0}}>
            Sector forecast
        </Typography.Title>
        <List
            loading={loading}
            dataSource={data}
            size={"small"}
            renderItem={(item, index) => {
                return <Flex
                    key={index}
                    gap={"middle"}
                    align={"center"}
                    style={{fontSize: token.fontSizeSM}}
                    className={"selection-bg-on-hover"}
                >
                    <Flex align={"center"} justify={"space-between"} style={{minWidth: 64}}>
                        <WeatherIcon score_value={item['Consensus'] * 100}/>
                        <span style={{fontWeight: token.fontWeightStrong}}>
                            {is_null(item.Consensus)
                                ? "-"
                                : ValueFormatter.round(100*item.Consensus, 0)
                            }
                        </span>
                    </Flex>
                    {item.Sector}
                </Flex>
            }}
            grid={{
                xs: 1, sm: 2, md: 2, lg: 2, xl: 1, xxl: 1
            }}
        />
    </Card>

}

export default SectorCard;
