import React from "react";
import TutorialVideo from "@holistico/tutorial/TutorialVideo";
import {Anchor, Typography} from "antd"
import {
    assetAllocationDescription
} from "@holistico/proposal/view/fundamentals/AssetAllocation";
import {
    ratingsDescription, sectorsDescription, sizeDescription, styleDescription
} from "@holistico/proposal/view/fundamentals/EquitiesPlain";
import {
    creditRatingDescription, fiSectorDescription, maturityDateDescription
} from "@holistico/proposal/view/fundamentals/FixedIncomePlain";
import {
    retirementMapDescription
} from "@holistico/proposal/view/retirement_map/RetirementMap";
import StressTests, {
    stressTestsDescription
} from "@holistico/proposal/view/stress_tests/StressTests";

const {Text} = Typography


export const items = [
    {
        key: 'video-tutorials',
        title: 'Video tutorials',
        children: [
            {
                key: 'video-tutorials-quick-report-for-a-new-client',
                title: 'Quick report for a new client',
                content: <TutorialVideo
                    title={"Quick report for a new client"}
                    src={"https://www.youtube.com/embed/mQn-gtXsn6E"}
                />
            },
            {
                key: 'video-tutorials-client-proposal',
                title: 'Client proposal',
                content: <TutorialVideo
                    title={"Client proposal"}
                    src={"https://www.youtube.com/embed/GMzrofyHNOU"}
                />
            }
        ],
    },
    {
        key: "glossary-section-divider",
        title: 'Glossary',
        type: "divider"
    },
    {
        key: 'risk-scores',
        title: 'Risk scores',
        children: [
            {
                key: 'risk-scores-portfolio-risk-score',
                title: 'Portfolio risk score',
                content: <>
                    <Typography.Paragraph>
                        Portfolio risk score quantifies the portfolio risk.
                        It is based on the downside risk measured by the 5-th
                        percentile of the portfolio projected return. For
                        convenience, risk score is scaled from 0 to 99,
                        where 0 means zero downside risk, and 99 means
                        extremely high downside risk.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        For cash, risk score is 0, while for S&P 500, it is
                        about 60.
                        One may use the following reference points for
                        the risk score:
                        <ul>
                            <li>
                                <Text code>risk score &le; 27 </Text>
                                : low risk
                            </li>
                            <li>
                                <Text code>28 &le; risk score &le; 41 </Text>
                                : below-average risk
                            </li>
                            <li>
                                <Text code>42 &le; risk score &le; 55 </Text>
                                : moderate risk
                            </li>
                            <li>
                                <Text code>56 &le; risk score &le; 60 </Text>
                                : above-average risk
                            </li>
                            <li>
                                <Text code>risk score &ge; 61 </Text>
                                : high risk
                            </li>
                        </ul>
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'risk-scores-risk-ability-score',
                title: 'Risk ability score',
                content: <>
                    <Typography.Paragraph>
                        Risk ability score measures client's financial ability
                        to withstand declines in portfolio values. We calculate
                        this score using client's questionnaire data. The score
                        is scaled from 0 to 99, where high values means that the
                        client take a high portfolio risk.
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'risk-scores-behavioral-risk-score',
                title: 'Behavioral risk score',
                content: <>
                    <Typography.Paragraph>
                        Behavioral risk score measures client's subconsciousness
                        tolerance to risk. We calculate this score using the
                        results of the behavioral quiz. The score is scaled from
                        0 to 99, where high values means high tolerance to risk.
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'risk-scores-risk-willingness-score',
                title: 'Risk willingness score',
                content: <>
                    <Typography.Paragraph>
                        Risk willingness score measures client's willingness to
                        take risk (or tolerance to risk). The score is based on
                        the level of possible losses that the client agrees to
                        take. The score is scaled from 0 to 99, where high
                        values means high tolerance to risk.
                    </Typography.Paragraph>
                </>
            },
        ],
    },
    {
        key: "models-section-divider",
        title: 'Models',
        type: "divider"
    },
    {
        key: 'models',
        title: 'Models & benchmarks',
        content: <>
            <Typography.Paragraph>
                On the "Models" page, you can find the list of model portfolios
                and benchmarks.
            </Typography.Paragraph>
            <Typography.Paragraph>
                On this page you can add new model from file, other model,
                or load it from Orion. Notice that if a model is loaded from
                Orion, its composition is updated every day. If a model
                is uploaded from file, its composition remains the same
                until you update it manually.
            </Typography.Paragraph>
        </>
    },
    {
        key: "assets-section-divider",
        title: 'Assets',
        type: "divider"
    },
    {
        key: 'assets',
        title: 'Assets comparison',
        content: <>
            <Typography.Paragraph>
                On the "Assets" page, one may search, analyze and compare
                different assets such as stocks, ETFs, mutual funds and
                model portfolios.
            </Typography.Paragraph>
            <Typography.Paragraph>
                For each asset, it provides
                <ul>
                    <li>
                        General info such as ticker,
                        market capitalization, fund size, expense ratio, etc.
                    </li>
                    <li>
                        Fundamental ratings if applicable
                    </li>
                    <li>
                        Peers list with the most similar assets
                    </li>
                    <li>
                        AI overall impression
                    </li>
                </ul>
                For funds and portfolios, it also provides various
                charts with asset allocation, sector distribution, etc.
            </Typography.Paragraph>
        </>
    },
    {
        key: "proposal-section-divider",
        title: 'Proposal',
        type: "divider"
    },
    {
        key: 'proposal-ai-chat',
        title: 'AI chat',
        path: "Proposal",
        content: <>
            <Typography.Paragraph>
                On every page of the proposal, you can use chat to ask
                AI questions on the client's portfolio. For example, you
                can get the overall impression by asking
                "What's your overall client impression?"
                or talking points by asking
                "Give me 5 points to discuss with the client".
            </Typography.Paragraph>
            <Typography.Paragraph>
                To get more AI options, you can go to the
                {" "}
                <a href={"#proposal-sections-other-ai-conversation"}>
                    AI conversation
                </a>
                {" "}
                page.
            </Typography.Paragraph>
            <Typography.Paragraph>
                You can copy AI answers, paste them to the notes and edit
                if necessary. These notes are available as separate section
                that you can include into the
                {" "}
                <a href={"#proposal-report"}>
                    PDF report
                </a>
                {" "}
                .
            </Typography.Paragraph>
        </>
    },
    {
        key: 'proposal-report',
        title: 'PDF report',
        path: "Proposal",
        content: <>
            <Typography.Paragraph>
                You can download client proposal PDF report
                by clicking "Get PDF report" button. In this case, the report
                with base template will be generated.
                You can also click "..." and choose another template or
                click "More options" to configure report on-the-fly.
            </Typography.Paragraph>
            <Typography.Paragraph>
                On the "More options" page, you can choose necessary
                sections. You can also change the order of sections
                by dragging them on the right place.
                "Proj. return and volatility..." switch controls whether
                to include projected return and portfolios volatility
                info into report or not.
                If you are going to use this template in the future,
                you can save it by clicking "Save as new template" button.
            </Typography.Paragraph>
        </>
    },
    {
        key: 'proposal-sections-client',
        title: 'Client',
        path: "Proposal",
        children: [
            {
                key: 'proposal-sections-client-questionnaire',
                title: 'Questionnaire',
                content: <>
                    <Typography.Paragraph>
                        Questionnaire is designed to estimate client's
                        risk ability, i.e. the ability
                        to withstand declines in portfolio values.
                        Risk ability score is based on client's
                        investment horizon, non-investment income, age, etc.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        After filling and saving the questionnaire you will
                        see client's risk ability score and model portfolios,
                        which best fit this score. Here you can add the most
                        suitable models to the proposed portfolio.
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'proposal-sections-client-behavioral-quiz',
                title: 'Behavioral quiz',
                content: <>
                    <Typography.Paragraph>
                        Behavioral quiz* is designed to estimate client's
                        subconsciousness tolerance to risk.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        After answering 13 questions, you will
                        see client's behavioral risk score and model portfolios,
                        which best fit this score. Here you can add the most
                        suitable models to the proposed portfolio.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        * Quiz source: Grable, J. E., & Lytton, R. H. (1999).
                        Financial risk tolerance revisited: The development of a
                        risk assessment instrument.
                        <i>Financial Services Review</i>, 8, 163-181.
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'proposal-sections-client-risk-tolerance',
                title: 'Risk tolerance',
                content: <>
                    <Typography.Paragraph>
                        On this page, you can estimate client's willingness to
                        take risk (or tolerance to risk) by choosing the level
                        of possible losses that the client agrees to take.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        After confirming client's risk willingness score,
                        you will see model portfolios,
                        which best fit this score. Here you can add the most
                        suitable models to the proposed portfolio.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Notice that this section is available only after
                        filling the questionnaire or passing the behavioral
                        quiz. We apply this restriction as we consider
                        the client's self-estimate risk tolerance to be the
                        least objective measure of the client's portfolio risk.
                        We encourage you to use the questionnaire and/or
                        behavioral quiz to get more objective risk measures.
                    </Typography.Paragraph>
                </>
            },
        ]
    },
    {
        key: 'proposal-sections-portfolios',
        title: 'Portfolios',
        path: "Proposal",
        children: [
            {
                key: 'proposal-sections-portfolios-client-portfolio',
                title: 'Client portfolio',
                content: <>
                    <Typography.Paragraph>
                        On this page, you can investigate and modify client's
                        current portfolio.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top, you can see current portfolio main
                        characteristics such as Risk score, Market value,
                        Expense ratio, etc.
                        Below main characteristics, the list of client's
                        accounts is presented. You can remove accounts
                        or add a new one from the file, model portfolio,
                        or create an empty account and fill it by hand.
                        You can also modify existing account market value
                        and its holdings.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If some accounts came from Orion, you can refresh them
                        by clicking "Refresh Orion portfolios" button.
                    </Typography.Paragraph>
                </>
            },
            {
                key: 'proposal-sections-portfolios-proposed-portfolio',
                title: 'Proposed portfolio',
                content: <>
                    <Typography.Paragraph>
                        On this page, you can investigate and modify the proposed
                        portfolio.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top, you can see current portfolio main
                        characteristics such as Risk score, Market value,
                        Expense ratio, etc. Below main characteristics, the list of portfolio
                        accounts is presented. You can remove accounts
                        or add a new one from the file, model portfolio,
                        or create an empty account and fill it by hand.
                        You can also modify existing account market value
                        and its holdings.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can click "Reallocate capital" to change account
                        weights in the portfolio.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If some accounts came from Orion, you can refresh them
                        by clicking "Refresh Orion portfolios" button.
                    </Typography.Paragraph>
                </>
            },
        ]
    },
    {
        key: 'proposal-sections-fundamentals',
        title: 'Fundamentals',
        path: "Proposal",
        children: [
            {
                key: 'proposal-sections-fundamentals-asset-allocation',
                title: 'Asset allocation',
                content: <>
                    {assetAllocationDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-equities-ratings',
                title: 'Equities / Ratings',
                content: <>
                    {ratingsDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-equities-sector',
                title: 'Equities / Sector',
                content: <>
                    {sectorsDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-equities-size',
                title: 'Equities / Size',
                content: <>
                    {sizeDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-equities-size-and-style',
                title: 'Equities / Style',
                content: <>
                    {styleDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-fixed-income-credit-rating',
                title: 'Fixed-income / Credit rating',
                content: <>
                    {creditRatingDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-fixed-income-sector',
                title: 'Fixed-income / Sector',
                content: <>
                    {fiSectorDescription}
                </>
            },
            {
                key: 'proposal-sections-fundamentals-fixed-income-maturity-date',
                title: 'Fixed-income / Maturity date',
                content: <>
                    {maturityDateDescription}
                </>
            },
        ],
    },
    {
        key: 'proposal-sections-other',
        title: 'Other tools',
        path: "Proposal",
        children: [
            {
                key: 'proposal-sections-other-retirement-map',
                title: 'Retirement map',
                content: <>
                    {retirementMapDescription}
                </>
            },
            {
                key: 'proposal-sections-other-stress-tests',
                title: 'Stress tests',
                content: <>
                    {stressTestsDescription}
                </>
            },
            {
                key: 'proposal-sections-other-ai-conversation',
                title: 'AI Conversation',
                content: <>
                    <Typography.Paragraph>
                        Although you can access AI Chat on every page of
                        the proposal, sometimes it is convenient to go to
                        AI Conversation page.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        AI Conversation stores the full chat history and
                        provides
                        additional functionality. For example, you can change
                        AI answers style by choosing its charm, intelligence,
                        humor level, etc. You can also choose one of the
                        AI role models to use predefined values of these
                        parameters.
                    </Typography.Paragraph>
                </>
            },
        ],
    },
]
