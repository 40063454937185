import {Empty} from "antd";
import React from "react";
import "./NoDataPlaceholder.less"


const NoDataPlaceholder = ({loading, ...props}) => {
     let className = props?.className ?? ""
     if (loading) {
          className += " loading"
     }

     return <Empty
         image={Empty.PRESENTED_IMAGE_SIMPLE}
         {...props}
         className={className}
     />
}

export default NoDataPlaceholder
