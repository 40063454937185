import React, {useState} from "react";
import {Button, Card, Flex, Spin, theme, Typography} from "antd";
import {is_null} from "@global/ValueFormatter";


const {useToken} = theme;


export const StatsCardDescription = ({children}) => {
    const {token} = useToken();

    return <Typography.Paragraph style={{
        color: token.colorTextDescription,
        fontSize: token.fontSizeXS,
        marginTop: token.padding
    }}>
        {children}
    </Typography.Paragraph>
}


export const StatsCardButton = ({onClick=null, icon=null, children}) => {
    const {token} = useToken();

    return <Button
        onClick={onClick}
        type={"link"}
        icon={icon}
        style={{fontSize: token.fontSizeSM, padding: 0}}
    >
        {children}
    </Button>
}


const StatsCard = ({title, value, loading=false, children, style={}}) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Card
        type={"inner"}
        style={{background: hover ? token.colorBgHoverBlue : token.colorBgGrey, ...style}}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Spin spinning={loading}>
            <Flex vertical align={"flex-start"}>
                <span style={{
                    fontSize: token.fontSizeHeading2,
                    lineHeight: 1,
                    color: token.colorStats,
                }}>
                    {is_null(value) ? "-" : value}
                </span>
                <span style={{
                    marginTop: token.paddingSM,
                    fontSize: token.fontSizeXS,
                    textTransform: "uppercase",
                    lineHeight: 1.2,
                    letterSpacing: 1,
                }}>
                    {title}
                </span>
                {children}
            </Flex>
        </Spin>
    </Card>
}


export default StatsCard;
