import React, {useEffect, useState, useCallback} from "react";
import {
    Button,
    message,
    Typography,
    Card,
    Checkbox,
    theme,
    Flex
} from "antd";
import {
    CloudDownloadOutlined,
} from "@ant-design/icons";
import {downloadAggregationExcel} from "@API/backtest";
import {downloadFile} from "@global/FileDownloader";
import Localizator from "@global/Localizator";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import BacktestAggregationTable
    from "@components/backtest/detailed/BacktestAggregationTable";


const {useToken} = theme;


const groupingsOrder = {
    "date": 1,
    "year": 1,
    "month": 1,
    "size": 2,
    "vbg_style": 3,
    "sector": 4
}


const sortGroupings = (groupings) => {
    return [...groupings].sort(function (a, b) {
        return (groupingsOrder[a] - groupingsOrder[b]) || a.localeCompare(b);
    });
}


const BacktestAggregator = ({config, rows = [], run}) => {
    const [groupBy, setGroupBy] = useState(["year"]);
    const [excelLoading, setExcelLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const {token} = useToken();

    let availableGroupings = [
        "year", "month",
        ...sortGroupings(config['groupings'].filter(x => x !== 'date'))
    ];
    let groupingHint = null;
    if (config.portfolio_config.type !== 'companies' || config.benchmark_config.type !== 'companies') {
        availableGroupings = ["year", "month"];
        groupingHint = "Grouping by other factors is not available " +
            "if portfolio (or benchmark) is not a companies sample";
    }


    useEffect(() => {
        console.log('Run aggregation');
        setTableLoading(true);
        const aggConfig = {
            groupings: groupBy
                .filter(x => !(x === "year" && groupBy.includes("month")))
                .filter(v => availableGroupings.includes(v)),
        }
        run(aggConfig);
    }, [groupBy, config])

    useEffect(() => {
        setTableLoading(false)
    }, [rows])

    const download = useCallback(() => {
        setExcelLoading(true);
        downloadAggregationExcel({
            agg: rows,
            config_name: config.name,
            config: config
        }, (response, error) => {
            if (error) {
                message.error("Error while downloading the aggregation excel");
                setExcelLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = "Performance history.xlsx";
            downloadFile(response.data, file_name, file_type, () => {
                setExcelLoading(false);
            })
        })
    }, [rows, config, setExcelLoading])

    return <Card hoverable style={{marginTop: token.padding}}>
        <Flex gap={"middle"} justify={"space-between"} align={"baseline"} wrap={"wrap"}>
            <Typography.Title level={4} style={{margin: 0}}>
                Performance history
            </Typography.Title>
            <Button
                onClick={download}
                loading={excelLoading}
                icon={<CloudDownloadOutlined/>}
            >
                Download table
            </Button>
        </Flex>
        <Flex vertical style={{marginTop: token.padding, marginBottom: token.padding}}>
            <Flex gap={"middle"}>
                <b>
                    Group by:
                </b>
                <Flex gap={"small"}>
                    <Checkbox.Group
                        options={availableGroupings.map(value => {
                            return {
                                label: Localizator.t(`factor.${value}`),
                                value: value
                            };
                        })}
                        value={groupBy}
                        onChange={value => setGroupBy(value)}
                    />
                </Flex>
            </Flex>
            {groupingHint
                &&
                <div style={{
                    color: token.colorTextDescription,
                    fontSize: token.fontSizeSM
                }}>
                    {groupingHint}
                </div>
            }
        </Flex>
        {(rows && rows.length > 0)
            ?
            <BacktestAggregationTable
                group_by={groupBy.filter(x => !(x === "year" && groupBy.includes("month")))}
                rows={rows}
                loading={tableLoading}
            />
            :
            <NoDataPlaceholder description={"No data to show"}/>
        }
    </Card>
}

export default BacktestAggregator
