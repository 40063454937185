import React, {useState, useEffect, useRef} from "react";
import {Form, Input, message, Modal, Spin, Select} from "antd";
import { createSchedulerObject, getSchedulerObject, updateSchedulerObject, getSchedulerObjects, cancel } from "@API/scheduler";
import Editor from "@monaco-editor/react";

const defaultTaskCode = "# write your code here";

const TaskQueueEditor = ({schedulerEntity, addModalOption, setAddModal, afterFinish, fullSchedulerObject=null}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState(null);
    const [taskCode, setTaskCode] = useState(defaultTaskCode);
    const abortControllerRef = useRef(new AbortController());

    const handleCancel = () => {
        form.resetFields();
        setTaskCode(defaultTaskCode);
        setAddModal(false);
        setTasks(null);
    }

    const onFinish = values => {
        setLoading(true);
        if (schedulerEntity === "task") {
            values.formula = taskCode;
        }
        if (typeof addModalOption === "string") {
            updateSchedulerObject(schedulerEntity, addModalOption, values, (resp, error) => {
                setLoading(false);
                if (error) {
                    message.error(`Error while updating ${schedulerEntity}`);
                    return
                }
                message.success(`Scheduler ${schedulerEntity} was updated successfully`);
                handleCancel();
                afterFinish();
            })
        } else {
            createSchedulerObject(schedulerEntity, values, (resp, error) => {
                setLoading(false);
                if (error) {
                    message.error(`Error while creating ${schedulerEntity}`);
                    return
                }
                message.success(`New ${schedulerEntity} was created successfully`);
                handleCancel();
                afterFinish();
            })
        }
    }

    useEffect(() => {
        if (!addModalOption) {
            return
        }
        console.log("useEffect")
        if (schedulerEntity === "queue") {
            console.log("loading tasks")
            getSchedulerObjects("task", null, "", (data, error) => {
                if (error) {
                    message.error(`Error while retrieving scheduler tasks`)
                    return 
                }
                console.log("get tasks", data)
                setTasks(data);
            }, abortControllerRef.current.signal)
        }
        if (fullSchedulerObject) {
            let fields = {
                title: fullSchedulerObject.title,
                description: fullSchedulerObject.description
            }
            if (schedulerEntity === "task") {
                setTaskCode(fullSchedulerObject.formula);
            } else if (schedulerEntity === "queue") {
                fields.tasks = fullSchedulerObject.tasks;
            }
            form.setFieldsValue(fields);
        } else if (typeof addModalOption === "string") {
            setLoading(true);
            getSchedulerObject(schedulerEntity, addModalOption, (data, error) => {
                setLoading(false);
                if (error) {
                    message.error(`Error while retrieve ${schedulerEntity}`);
                    return
                }
                console.log(data)
                let fields = {
                    title: data.title,
                    description: data.description
                }
                if (schedulerEntity === "task") {
                    setTaskCode(data.formula);
                } else if (schedulerEntity === "queue") {
                    fields.tasks = data.tasks;
                }
                form.setFieldsValue(fields);
            }, abortControllerRef.current.signal)
        }
    }, [addModalOption, fullSchedulerObject, schedulerEntity])

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
            cancel && cancel();
        }
    }, [])

    return (
        (<Modal
            width={800}
            open={addModalOption !== false}
            title={addModalOption === true ? `Add ${schedulerEntity}` : `Update ${schedulerEntity}`}
            onOk={form.submit}
            onCancel={handleCancel}
            okText={addModalOption === true ? "Add" : "Update"}
        >
            <Spin size={"large"} spinning={loading} tip="Waiting...">
                <Form form={form} name="scheduler_obj_editor" onFinish={onFinish} autoComplete="off" 
                    scrollToFirstError layout="vertical"
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                        {
                            required: true,
                            message: 'Missing title',
                        },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                        {
                            required: true,
                            message: 'Missing description',
                        },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    {schedulerEntity === "task" && <Form.Item
                        label="Code"
                    >
                        <Editor
                            height="400px"
                            defaultLanguage="python"
                            value={taskCode}
                            onChange={setTaskCode}
                            theme="vs-light"
                        />
                    </Form.Item>}
                    {tasks && <Form.Item
                        name="tasks"
                        label="Select tasks"
                        extra="Order of tasks will follow the order of your choice"
                        rules={[
                        {
                            required: true,
                            message: "You need to select at least one task",
                            type: 'array',
                        },
                        ]}
                    >
                        <Select mode="multiple" placeholder="Select tasks to include">
                            {tasks.map((task, inx) => <Select.Option key={inx} value={task._id}>{task.title}</Select.Option>)}
                        </Select>
                    </Form.Item>}
                </Form>
            </Spin>
        </Modal>)
    );
}

export default TaskQueueEditor