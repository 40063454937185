import React, {useEffect, useRef, useState} from "react";
import {DatePicker, Form, Input, message, Modal, Row, Spin, Typography} from "antd";
import moment from 'moment';
import {setMute} from "@API/supervision";

const MuteModal = ({modalOpen, setModalOpen, clientId, clientName, mute, flagKey, flagName, finishAction}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const abortControllerRef = useRef(new AbortController());

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    const handleCancel = () => {
        form.resetFields();
        setModalOpen(false);
    }

    const save = (values = null) => {
        setLoading(true);
        let muteData = {
            client_id: clientId,
            flag_key: flagKey,
            mute: mute
        }
        if (values) {
            muteData.until = values.until.toISOString();
            muteData.comment = values.comment;
        }
        console.log(muteData)
        setMute(muteData, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while mute was set");
                return
            }
            message.success(`${flagName} check was ${mute ? "muted" : "unmuted"} successfully`);
            handleCancel();
            finishAction();
        }, abortControllerRef.current.signal)
    }

    return (
        (<Modal title={mute ? "Mute " + flagName : "Unmute " + flagName}
               open={modalOpen}
               onCancel={handleCancel}
               onOk={mute ? form.submit : () => save(null)}
               okText={mute ? "Mute" : "Unmute"}
               className={'form-modal'}
        >
            <Spin size={"large"} spinning={loading} tip="Waiting...">
                {mute ? <>
                        <Typography.Paragraph>Muted flags are skipped when processing given client, unless you ignore custom client configurations intentionally.</Typography.Paragraph>
                        <Form form={form} name="mute_flag" onFinish={save}
                              labelCol={{span: 5}}
                              wrapperCol={{span: 19}}
                              autoComplete="off"
                              scrollToFirstError
                              layout="horizontal"
                              validateTrigger={'onSubmit'}
                              requiredMark={false}
                    >
                        <Form.Item
                            name="until"
                            tooltip="Till what date should the check be disabled."
                            label="Until"
                            initialValue={moment().add(1, 'month')}
                            rules={[{
                                required: true,
                                type: 'object',
                                message: 'Missing until date',
                            }]
                            }>
                            <DatePicker disabledDate={disabledDate}/>
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Comment"
                            requiredMark={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Missing comment',
                                },
                            ]}
                        >
                            <Input.TextArea maxLength={300}/>
                        </Form.Item>
                    </Form></>
                    :
                    <Row>{flagName} check will be unmuted and will start taking part in all reports.</Row>
                }
            </Spin>
        </Modal>)
    );
}

export default MuteModal
