const proposalLocalizations = {
    "Client portfolios list": "Current portfolios list",
    "Proposal portfolios list": "Proposed portfolios list",
    "Summary, single": "Summary, current",
    "Summary, vs benchmark": "Summary, current vs proposed",
    "Summary, vs benchmark with fee": "Summary with fee, current vs proposed",
    "Sector, single": "Equity sector, current",
    "Sector, vs benchmark": "Equity sector, current vs proposed",
    "Size, single": "Equity size, current",
    "Size, vs benchmark": "Equity size, current vs proposed",
    "Fixed income credit rating, single": "Fixed-inc. credit rating, current",
    "Fixed income credit rating, vs benchmark": "Fixed-inc. credit rating, current vs proposed",
    "Fixed income effective maturity, single": "Fixed-inc. maturity, current",
    "Fixed income effective maturity, vs benchmark": "Fixed-inc. maturity, current vs proposed",
    "Fixed income sector, single": "Fixed-inc. sector, current",
    "Fixed income sector, vs benchmark": "Fixed-inc. sector, current vs proposed",
    "Style, single": "Equity style, current",
    "Style, vs benchmark": "Equity style, current vs proposed",
    "Score allocation, single": "Equity ratings, current",
    "Stocks factors, portfolio": "Stock factors, current",
    "Equity funds factors, portfolio": "Equity funds factors, current",
    "Fixed income factors, portfolio": "Fixed-inc. factors, current",
    "Stocks factors, benchmark": "Stocks factors, proposed",
    "Equity funds factors, benchmark": "Equity funds factors, proposed",
    "Fixed income factors, benchmark": "Fixed-inc. factors, proposed",
    "Positions summary, portfolio": "Positions summary, current",
    "Positions, portfolio": "Positions, current",
    "Positions summary, benchmark": "Positions summary, proposed",
    "Positions, benchmark": "Positions, proposed",
    "Stress testing, portfolio": "Stress testing, current",
    "Stress testing, vs benchmark": "Stress testing, current vs proposed",
    "Retirement map, single": "Retirement map, current",
}


const enrollmentLocalizations = {
    "Client portfolios list": "Current portfolios list",
    "Proposal portfolios list": "Benchmark portfolios list",
    "Summary, single": "Summary, current",
    "Summary, vs benchmark": "Summary, current vs benchmark",
    "Summary, vs benchmark with fee": "Summary with fee, current vs benchmark",
    "Sector, single": "Equity sector, current",
    "Sector, vs benchmark": "Equity sector, current vs benchmark",
    "Size, single": "Equity size, current",
    "Size, vs benchmark": "Equity size, current vs benchmark",
    "Fixed income credit rating, single": "Fixed-inc. credit rating, current",
    "Fixed income credit rating, vs benchmark": "Fixed-inc. credit rating, current vs benchmark",
    "Fixed income effective maturity, single": "Fixed-inc. maturity, current",
    "Fixed income effective maturity, vs benchmark": "Fixed-inc. maturity, current vs benchmark",
    "Fixed income sector, single": "Fixed-inc. sector, current",
    "Fixed income sector, vs benchmark": "Fixed-inc. sector, current vs benchmark",
    "Style, single": "Equity style, current",
    "Style, vs benchmark": "Equity style, current vs benchmark",
    "Score allocation, single": "Equity ratings, current",
    "Stocks factors, portfolio": "Stock factors, current",
    "Equity funds factors, portfolio": "Equity funds factors, current",
    "Fixed income factors, portfolio": "Fixed-inc. factors, current",
    "Stocks factors, benchmark": "Stocks factors, benchmark",
    "Equity funds factors, benchmark": "Equity funds factors, benchmark",
    "Fixed income factors, benchmark": "Fixed-inc. factors, benchmark",
    "Positions summary, portfolio": "Positions summary, current",
    "Positions, portfolio": "Positions, current",
    "Positions summary, benchmark": "Positions summary, benchmark",
    "Positions, benchmark": "Positions, benchmark",
    "Stress testing, portfolio": "Stress testing, current",
    "Stress testing, vs benchmark": "Stress testing, current vs benchmark",
    "Retirement map, single": "Retirement map, current",
}

export default class SectionLocalizator {
    static t(label, reportClass) {
        if (reportClass === "client" && proposalLocalizations.hasOwnProperty(label)) {
            return proposalLocalizations[label]
        }
        if (reportClass === "enrollment" && proposalLocalizations.hasOwnProperty(label)) {
            return enrollmentLocalizations[label]
        }
        return '' + label
    }
}
