/* eslint-disable react/prop-types */
import React, {useEffect, useState, useMemo} from 'react';
import '@app/AppSpas.less';
import {getGroupedFactors, cancel} from "@API/factor";
import {Table, Tabs, Typography, Spin, Badge} from "antd";
import ChartStyles from "@global/ChartStyles";

const {Title} = Typography;

const Factors = () => {
    // const [showAddFactor, setShowAddFactor] = useState(false);
    // const [factors, setFactors] = useState([]);
    const [loadingFactors, setLoadingFactors] = useState(true);
    //grouped ones
    const [factorsMap, setFactorsMap] = useState({});

    const columns = useMemo(() => {
        return [
        {
            title: 'Column',
            dataIndex: 'column_name',
            width: 150,
            render: text => <Typography.Text copyable>{text}</Typography.Text>,
        },
        {
            title: 'Name',
            width: 250,
            dataIndex: 'full_name',

        },
        {
            title: 'Source',
            dataIndex: 'source',
            sorter: (a, b) => a.source.localeCompare(b.source),
        }, {
            title: 'Style',
            dataIndex: 'style',
            sorter: (a, b) => a.style.localeCompare(b.style),
            filters: [
                {text: 'Composite', value: 'Composite'},
                {text: 'Value', value: 'Value'},
                {text: 'Growth', value: 'Growth'},
                {text: 'Profitability', value: 'Profitability'},
                {text: 'Safety', value: 'Safety'},
                {text: 'Yield', value: 'Yield'},
                {text: 'Momentum', value: 'Momentum'},
                {text: 'Quality', value: 'Quality'},
                {text: 'Liquidity', value: 'Liquidity'},
                {text: 'Size', value: 'Size'},
                {text: 'Fixed Income', value: 'Fixed Income'}
            ],
            onFilter: (value, record) => record.style == value,
        },
        {
            title: 'Entity',
            dataIndex: 'entity',
            sorter: (a, b) => a.entity.localeCompare(b.entity),
            filters: [
                {text: 'Company', value: 'company'},
                {text: 'ETF', value: 'etf'},
                {text: 'Macro', value: 'macro'},
                {text: 'Fund', value: 'fund'},
                {text: 'Index', value: 'index'},
            ],
            onFilter: (value, record) => record.entity == value,
        }, {
            title: 'Order',
            dataIndex: 'processing_order',
            sorter: (a, b) => a.processing_order - b.processing_order,
        }, {
            title: 'Value Type',
            dataIndex: 'value_type'
        },/*,
        {
            title: 'Destination',
            dataIndex: 'destination',
            render: destionations => {
                return destionations.join(', ')
            },
        }*/]
    }, [])

    useEffect(() => {
        getGroupedFactors("", groupedFactors => {
            setFactorsMap(groupedFactors);
            setLoadingFactors(false);
        })
        return () => {
            cancel && cancel();
        }
    }, [])

    // const FactorTabs = ({factors, selected}) => {
    //     return <Tabs defaultActiveKey="0" tabPosition={"top"} type="card">
    //         {Object.entries(factors).map((entry, index) => {
    //             let [repr, factorArray] = entry
    //             return <Tabs.TabPane tab={repr} key={index} size={"small"}>
    //                 <Table loading={loadingFactors} size="small" columns={columns} dataSource={factorArray}
    //                        rowKey={'_id'}
    //                        pagination={false}
    //                        expandable={{
    //                            expandedRowRender: record => <pre style={{margin: 0}}>{record.formula}</pre>
    //                        }}/>
    //             </Tabs.TabPane>
    //         })}
    //     </Tabs>
    // }

    const MemoFactorTabs = useMemo(() => {
        if (loadingFactors) {
            return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "600px"}}>
                <Spin size="large" tip="Loading all factors info..."></Spin>
                </div>
        } else {
            return <Tabs defaultActiveKey="0" tabPosition={"top"} type="card">
                {Object.entries(factorsMap).map((entry, index) => {
                    let [repr, factorArray] = entry
                    let tabHead = <Badge color={ChartStyles.getRepresentationColor(repr)} text={repr} />
                    return <Tabs.TabPane tab={repr} key={index} size={"small"}>
                        <Table loading={loadingFactors} size="small" columns={columns} dataSource={factorArray}
                            rowKey={'_id'}
                            pagination={false}
                            expandable={{
                                expandedRowRender: record => <pre style={{margin: 0}}>{record.formula}</pre>
                            }}/>
                    </Tabs.TabPane>
                })}
            </Tabs>
        }
    }, [factorsMap, loadingFactors])
    return (<div>
            <Title level={3}>Factors</Title>
            {MemoFactorTabs}
        </div>
    );
}

export default Factors;
