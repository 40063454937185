import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Carousel,
    Radio,
    Form,
    Space,
    Typography,
    Divider, Tooltip, Row, Col, Tag
} from "antd";
import questions
    from "@holistico/proposal/view/client/behavioral_quiz/Questions";
import "./BehavioralQuiz.less"
import {CloseOutlined} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";


const BehavioralQuizForm = ({slideIndex = 0, onFinish, closable=true}) => {
    const {proposal} = useHolisticoProposalContext();
    const [form] = Form.useForm();
    const [slide, setSlide] = useState(slideIndex)
    const [nextDisabled, setNextDisabled] = useState(true)
    const carouselRef = useRef()

    useEffect(() => {
        form.setFieldsValue(proposal?.questionnaire?.behavioral_quiz);
    }, [proposal])

    const updateNextButtonState = () => {
        setNextDisabled(!form.getFieldValue(["answers", `${slide + 1}`]))
    }

    const onSlide = (index) => {
        setSlide(index)
    }

    useEffect(() => {
        updateNextButtonState()
    }, [slide])


    return <>
        <Form form={form}
              layout={'vertical'}
              initialValues={proposal?.questionnaire?.behavioral_quiz}
              onValuesChange={() => {
                  updateNextButtonState()
              }}
              onFinish={onFinish}
        >
            <Row>
                <Col span={23}>
                    <Carousel
                        dots={false}
                        swipe={false}
                        draggable={false}
                        infinite={false}
                        ref={carouselRef}
                        afterChange={onSlide}
                        initialSlide={slideIndex}
                    >
                        {questions.map((question) => {
                            return <div key={question.key}
                                        className={"quiz-card"}>
                                {/*<Typography.Text>*/}
                                {/*    {question.key} / 13*/}
                                {/*</Typography.Text>*/}
                                <Tag color="default">{question.key} / 13</Tag>
                                <Typography.Title
                                    level={5}
                                    style={{
                                        margin: "8px 0 16px 0"
                                    }}
                                >
                                    {question["title"]}
                                </Typography.Title>
                                <Form.Item
                                    name={["answers", `${question.key}`]}
                                >
                                    <Radio.Group size="large">
                                        <Space direction="vertical" size={16}>
                                            {question["options"].map((option) => {
                                                return <Radio
                                                    value={option["value"]}
                                                    key={option["value"]}>
                                                    {option["label"]}
                                                </Radio>
                                            })}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        })}
                    </Carousel>
                </Col>
                {closable
                    &&
                    <Col span={1} style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Tooltip title="Close quiz">
                            <Button type="text" htmlType="submit">
                                <CloseOutlined style={{fontSize: "20px"}}/>
                            </Button>

                        </Tooltip>
                    </Col>
                }
            </Row>
            {/*<Divider style={{margin: "0 0 12px 0"}}/>*/}
            <Divider/>
            <Space>
                <Button
                    // type="secondary"
                    onClick={() => {
                        carouselRef.current.prev()
                    }}
                    // size="large"
                    disabled={slide === 0}
                    style={{width: "120px"}}
                >
                    Back
                </Button>
                {slide === questions.length - 1
                    ?
                    <Button
                        type="primary"
                        // size="large"
                        disabled={nextDisabled}
                        style={{width: "180px"}}
                        htmlType="submit"
                    >
                        Get results
                    </Button>
                    :
                    <Button
                        type="primary"
                        onClick={() => {
                            carouselRef.current.next()
                        }}
                        // size="large"
                        disabled={nextDisabled}
                        style={{width: "180px"}}
                    >
                        Next
                    </Button>
                }
            </Space>
        </Form>
    </>
}

export default BehavioralQuizForm;
