import React from 'react';
import {Empty, Table} from 'antd';
import ValueFormatter from "@global/ValueFormatter";

import "./Asset.less";


const factorsUnits = {
    ticker: {
        title: "Ticker",
        render: (v) => {
            return v
        }
    },
    name: {
        title: "Company name",
        render: (v) => {
            return v
        }
    },
    fund_size: {
        title: "Fund size",
        render: (v) => {
            return ValueFormatter.compactCurrency(v)
        }
    },
    stocks_weight: {
        title: "Stocks weight",
        render: (v) => {
            return ValueFormatter.percent(v)
        }
    },
    bonds_weight: {
        title: "Bonds weight",
        render: (v) => {
            return ValueFormatter.percent(v)
        }
    },
    sector: {
        title: "Sector",
        render: (v) => {
            return v
        }
    },
    size: {
        title: "Size",
        render: (v) => {
            return v
        }
    },
    vbg_style: {
        title: "Style",
        render: (v) => {
            return v
        }
    },
    similarity: {
        title: "Similarity",
        render: (v) => {
            return ValueFormatter.floorPercent(v)
        },
        sorter: (a, b) => parseFloat(a.similarity) - parseFloat(b.similarity)
    }
}

const Neighbours = ({neighbours, neighborClicked}) => {
    const columns = neighbours ? Object.keys(neighbours[0]).map(column => {
        let factorUnit = factorsUnits[column];
        if (factorUnit) {
            return {
                title: factorUnit.title,
                dataIndex: column,
                render: factorUnit.render,
                sorter: factorUnit.sorter || null
            }
        }
    }).filter(v => v !== undefined) : null;

    return (
        <div>
            {/* <Title className="spas" level={4}>Quantitative Peers</Title> */}
            {neighbours ?
                <Table
                    rowKey='ticker' className="neighbours-table" dataSource={neighbours} columns={columns}
                    pagination={false} size={'small'}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => neighborClicked(record)
                        }
                    }}
                />
                :
                <Empty description="Security neighnours is not available"/>
            }
        </div>
    )
}

export default Neighbours;
