/* eslint-disable react/prop-types */
import React from 'react';
import ChartStyles from "@global/ChartStyles";
import BarChart from "@components/screener/chart/BarChart";


const maturityDateSortOrder = {
    "0-1 yrs": 1,
    "1-3 yrs": 2,
    "3-5 yrs": 3,
    "5-7 yrs": 4,
    "7-10 yrs": 5,
    "10-20 yrs": 6,
    ">20 yrs": 7,
}

const MaturityDate = ({allocation}) => {
    let skip = false
    if (allocation) {
        const nonZeroKeys = Object.keys(allocation).filter(key => allocation[key])
        skip = nonZeroKeys.length === 0
    }


    return <BarChart
        allocation={skip ? null : allocation}
        color={ChartStyles.colors[3]}
        sortOrder={maturityDateSortOrder}
        noDataText={"No maturity date information"}
    />
}

export default MaturityDate;
