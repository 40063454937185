export default class StaticInfo {

    static riskReturns =
        [
            {'Risk Score': 0, 'Lower Bound': 0, 'Upper Bound': 0},
            {'Risk Score': 1, 'Lower Bound': -0.0002, 'Upper Bound': 0.0005},
            {'Risk Score': 2, 'Lower Bound': -0.0005, 'Upper Bound': 0.0016},
            {'Risk Score': 3, 'Lower Bound': -0.0012, 'Upper Bound': 0.0037},
            {'Risk Score': 4, 'Lower Bound': -0.0018, 'Upper Bound': 0.0058},
            {'Risk Score': 5, 'Lower Bound': -0.0028, 'Upper Bound': 0.0089},
            {'Risk Score': 6, 'Lower Bound': -0.0037, 'Upper Bound': 0.012},
            {'Risk Score': 7, 'Lower Bound': -0.0046, 'Upper Bound': 0.0151},
            {'Risk Score': 8, 'Lower Bound': -0.0057, 'Upper Bound': 0.0192},
            {'Risk Score': 9, 'Lower Bound': -0.0069, 'Upper Bound': 0.0238},
            {'Risk Score': 10, 'Lower Bound': -0.0081, 'Upper Bound': 0.0289},
            {'Risk Score': 11, 'Lower Bound': -0.0094, 'Upper Bound': 0.0345},
            {'Risk Score': 12, 'Lower Bound': -0.0107, 'Upper Bound': 0.0401},
            {'Risk Score': 13, 'Lower Bound': -0.0122, 'Upper Bound': 0.0473},
            {'Risk Score': 14, 'Lower Bound': -0.0137, 'Upper Bound': 0.0546},
            {'Risk Score': 15, 'Lower Bound': -0.0152, 'Upper Bound': 0.0625},
            {'Risk Score': 16, 'Lower Bound': -0.0168, 'Upper Bound': 0.0705},
            {'Risk Score': 17, 'Lower Bound': -0.0185, 'Upper Bound': 0.0792},
            {'Risk Score': 18, 'Lower Bound': -0.0201, 'Upper Bound': 0.0876},
            {'Risk Score': 19, 'Lower Bound': -0.0219, 'Upper Bound': 0.0962},
            {'Risk Score': 20, 'Lower Bound': -0.0236, 'Upper Bound': 0.1045},
            {'Risk Score': 21, 'Lower Bound': -0.0255, 'Upper Bound': 0.1113},
            {'Risk Score': 22, 'Lower Bound': -0.0274, 'Upper Bound': 0.1155},
            {'Risk Score': 23, 'Lower Bound': -0.0293, 'Upper Bound': 0.1191},
            {'Risk Score': 24, 'Lower Bound': -0.0313, 'Upper Bound': 0.1226},
            {'Risk Score': 25, 'Lower Bound': -0.0333, 'Upper Bound': 0.126},
            {'Risk Score': 26, 'Lower Bound': -0.0353, 'Upper Bound': 0.1294},
            {'Risk Score': 27, 'Lower Bound': -0.0375, 'Upper Bound': 0.1327},
            {'Risk Score': 28, 'Lower Bound': -0.0396, 'Upper Bound': 0.1361},
            {'Risk Score': 29, 'Lower Bound': -0.0417, 'Upper Bound': 0.1393},
            {'Risk Score': 30, 'Lower Bound': -0.044, 'Upper Bound': 0.1427},
            {'Risk Score': 31, 'Lower Bound': -0.0462, 'Upper Bound': 0.1461},
            {'Risk Score': 32, 'Lower Bound': -0.0485, 'Upper Bound': 0.1494},
            {'Risk Score': 33, 'Lower Bound': -0.0509, 'Upper Bound': 0.153},
            {'Risk Score': 34, 'Lower Bound': -0.0532, 'Upper Bound': 0.1564},
            {'Risk Score': 35, 'Lower Bound': -0.0556, 'Upper Bound': 0.1599},
            {'Risk Score': 36, 'Lower Bound': -0.0581, 'Upper Bound': 0.1634},
            {'Risk Score': 37, 'Lower Bound': -0.0606, 'Upper Bound': 0.167},
            {'Risk Score': 38, 'Lower Bound': -0.0631, 'Upper Bound': 0.1706},
            {'Risk Score': 39, 'Lower Bound': -0.0655, 'Upper Bound': 0.174},
            {'Risk Score': 40, 'Lower Bound': -0.0681, 'Upper Bound': 0.1776},
            {'Risk Score': 41, 'Lower Bound': -0.0707, 'Upper Bound': 0.1813},
            {'Risk Score': 42, 'Lower Bound': -0.0733, 'Upper Bound': 0.1851},
            {'Risk Score': 43, 'Lower Bound': -0.076, 'Upper Bound': 0.1888},
            {'Risk Score': 44, 'Lower Bound': -0.0787, 'Upper Bound': 0.1926},
            {'Risk Score': 45, 'Lower Bound': -0.0814, 'Upper Bound': 0.1964},
            {'Risk Score': 46, 'Lower Bound': -0.0841, 'Upper Bound': 0.2002},
            {'Risk Score': 47, 'Lower Bound': -0.087, 'Upper Bound': 0.2042},
            {'Risk Score': 48, 'Lower Bound': -0.0898, 'Upper Bound': 0.2081},
            {'Risk Score': 49, 'Lower Bound': -0.0926, 'Upper Bound': 0.2119},
            {'Risk Score': 50, 'Lower Bound': -0.0956, 'Upper Bound': 0.216},
            {'Risk Score': 51, 'Lower Bound': -0.0984, 'Upper Bound': 0.2199},
            {'Risk Score': 52, 'Lower Bound': -0.1014, 'Upper Bound': 0.2241},
            {'Risk Score': 53, 'Lower Bound': -0.1044, 'Upper Bound': 0.2282},
            {'Risk Score': 54, 'Lower Bound': -0.1074, 'Upper Bound': 0.2324},
            {'Risk Score': 55, 'Lower Bound': -0.1104, 'Upper Bound': 0.2365},
            {'Risk Score': 56, 'Lower Bound': -0.1135, 'Upper Bound': 0.2407},
            {'Risk Score': 57, 'Lower Bound': -0.1165, 'Upper Bound': 0.2449},
            {'Risk Score': 58, 'Lower Bound': -0.1196, 'Upper Bound': 0.2491},
            {'Risk Score': 59, 'Lower Bound': -0.1227, 'Upper Bound': 0.2533},
            {'Risk Score': 60, 'Lower Bound': -0.1259, 'Upper Bound': 0.2578},
            {'Risk Score': 61, 'Lower Bound': -0.129, 'Upper Bound': 0.262},
            {'Risk Score': 62, 'Lower Bound': -0.1323, 'Upper Bound': 0.2665},
            {'Risk Score': 63, 'Lower Bound': -0.1356, 'Upper Bound': 0.2709},
            {'Risk Score': 64, 'Lower Bound': -0.1389, 'Upper Bound': 0.2754},
            {'Risk Score': 65, 'Lower Bound': -0.1421, 'Upper Bound': 0.2799},
            {'Risk Score': 66, 'Lower Bound': -0.1454, 'Upper Bound': 0.2844},
            {'Risk Score': 67, 'Lower Bound': -0.1488, 'Upper Bound': 0.2889},
            {'Risk Score': 68, 'Lower Bound': -0.1521, 'Upper Bound': 0.2934},
            {'Risk Score': 69, 'Lower Bound': -0.1556, 'Upper Bound': 0.2982},
            {'Risk Score': 70, 'Lower Bound': -0.1589, 'Upper Bound': 0.3027},
            {'Risk Score': 71, 'Lower Bound': -0.1624, 'Upper Bound': 0.3074},
            {'Risk Score': 72, 'Lower Bound': -0.1659, 'Upper Bound': 0.3122},
            {'Risk Score': 73, 'Lower Bound': -0.1692, 'Upper Bound': 0.3167},
            {'Risk Score': 74, 'Lower Bound': -0.1727, 'Upper Bound': 0.3215},
            {'Risk Score': 75, 'Lower Bound': -0.1764, 'Upper Bound': 0.3265},
            {'Risk Score': 76, 'Lower Bound': -0.1799, 'Upper Bound': 0.3313},
            {'Risk Score': 77, 'Lower Bound': -0.1835, 'Upper Bound': 0.3361},
            {'Risk Score': 78, 'Lower Bound': -0.1872, 'Upper Bound': 0.3411},
            {'Risk Score': 79, 'Lower Bound': -0.1907, 'Upper Bound': 0.3459},
            {'Risk Score': 80, 'Lower Bound': -0.1944, 'Upper Bound': 0.3509},
            {'Risk Score': 81, 'Lower Bound': -0.1981, 'Upper Bound': 0.3559},
            {'Risk Score': 82, 'Lower Bound': -0.2017, 'Upper Bound': 0.3607},
            {'Risk Score': 83, 'Lower Bound': -0.2054, 'Upper Bound': 0.3658},
            {'Risk Score': 84, 'Lower Bound': -0.2093, 'Upper Bound': 0.3711},
            {'Risk Score': 85, 'Lower Bound': -0.213, 'Upper Bound': 0.3761},
            {'Risk Score': 86, 'Lower Bound': -0.2168, 'Upper Bound': 0.3811},
            {'Risk Score': 87, 'Lower Bound': -0.2207, 'Upper Bound': 0.3864},
            {'Risk Score': 88, 'Lower Bound': -0.2244, 'Upper Bound': 0.3915},
            {'Risk Score': 89, 'Lower Bound': -0.2283, 'Upper Bound': 0.3968},
            {'Risk Score': 90, 'Lower Bound': -0.2322, 'Upper Bound': 0.4021},
            {'Risk Score': 91, 'Lower Bound': -0.2361, 'Upper Bound': 0.4074},
            {'Risk Score': 92, 'Lower Bound': -0.2401, 'Upper Bound': 0.4127},
            {'Risk Score': 93, 'Lower Bound': -0.244, 'Upper Bound': 0.418},
            {'Risk Score': 94, 'Lower Bound': -0.2479, 'Upper Bound': 0.4233},
            {'Risk Score': 95, 'Lower Bound': -0.2518, 'Upper Bound': 0.4286},
            {'Risk Score': 96, 'Lower Bound': -0.2559, 'Upper Bound': 0.4341},
            {'Risk Score': 97, 'Lower Bound': -0.2599, 'Upper Bound': 0.4394},
            {'Risk Score': 98, 'Lower Bound': -0.264, 'Upper Bound': 0.4449},
            {'Risk Score': 99, 'Lower Bound': -0.2681, 'Upper Bound': 0.4505},
            {'Risk Score': 99, 'Lower Bound': -0.2722, 'Upper Bound': 0.456},
        ]

    static riskReturns1Y =
        [
            {'Risk Score': 0, 'Lower Bound': 0.0, 'Upper Bound': 0.002},
            {'Risk Score': 1, 'Lower Bound': 0.0, 'Upper Bound': 0.003},
            {'Risk Score': 2, 'Lower Bound': -0.0005, 'Upper Bound': 0.004},
            {'Risk Score': 3, 'Lower Bound': -0.0009, 'Upper Bound': 0.0050},
            {'Risk Score': 4, 'Lower Bound': -0.0014, 'Upper Bound': 0.0100},
            {'Risk Score': 5, 'Lower Bound': -0.0018, 'Upper Bound': 0.0539},
            {'Risk Score': 6, 'Lower Bound': -0.0020, 'Upper Bound': 0.0549},
            {'Risk Score': 7, 'Lower Bound': -0.0022, 'Upper Bound': 0.0567},
            {'Risk Score': 8, 'Lower Bound': -0.0023, 'Upper Bound': 0.0585},
            {'Risk Score': 9, 'Lower Bound': -0.0026, 'Upper Bound': 0.0602},
            {'Risk Score': 10, 'Lower Bound': -0.0028, 'Upper Bound': 0.0626},
            {'Risk Score': 11, 'Lower Bound': -0.0046, 'Upper Bound': 0.0648},
            {'Risk Score': 12, 'Lower Bound': -0.0063, 'Upper Bound': 0.067},
            {'Risk Score': 13, 'Lower Bound': -0.0084, 'Upper Bound': 0.0697},
            {'Risk Score': 14, 'Lower Bound': -0.0104, 'Upper Bound': 0.0723},
            {'Risk Score': 15, 'Lower Bound': -0.0122, 'Upper Bound': 0.0747},
            {'Risk Score': 16, 'Lower Bound': -0.0144, 'Upper Bound': 0.0777},
            {'Risk Score': 17, 'Lower Bound': -0.0165, 'Upper Bound': 0.0804},
            {'Risk Score': 18, 'Lower Bound': -0.0187, 'Upper Bound': 0.0836},
            {'Risk Score': 19, 'Lower Bound': -0.0212, 'Upper Bound': 0.0872},
            {'Risk Score': 20, 'Lower Bound': -0.0236, 'Upper Bound': 0.0906},
            {'Risk Score': 21, 'Lower Bound': -0.0261, 'Upper Bound': 0.0943},
            {'Risk Score': 22, 'Lower Bound': -0.0284, 'Upper Bound': 0.0979},
            {'Risk Score': 23, 'Lower Bound': -0.0309, 'Upper Bound': 0.1017},
            {'Risk Score': 24, 'Lower Bound': -0.0335, 'Upper Bound': 0.1053},
            {'Risk Score': 25, 'Lower Bound': -0.0362, 'Upper Bound': 0.1088},
            {'Risk Score': 26, 'Lower Bound': -0.0395, 'Upper Bound': 0.113},
            {'Risk Score': 27, 'Lower Bound': -0.0419, 'Upper Bound': 0.1162},
            {'Risk Score': 28, 'Lower Bound': -0.0448, 'Upper Bound': 0.1201},
            {'Risk Score': 29, 'Lower Bound': -0.0476, 'Upper Bound': 0.1238},
            {'Risk Score': 30, 'Lower Bound': -0.0508, 'Upper Bound': 0.1282},
            {'Risk Score': 31, 'Lower Bound': -0.0539, 'Upper Bound': 0.1323},
            {'Risk Score': 32, 'Lower Bound': -0.0568, 'Upper Bound': 0.1364},
            {'Risk Score': 33, 'Lower Bound': -0.06, 'Upper Bound': 0.141},
            {'Risk Score': 34, 'Lower Bound': -0.0631, 'Upper Bound': 0.1454},
            {'Risk Score': 35, 'Lower Bound': -0.0661, 'Upper Bound': 0.1497},
            {'Risk Score': 36, 'Lower Bound': -0.0693, 'Upper Bound': 0.1544},
            {'Risk Score': 37, 'Lower Bound': -0.0728, 'Upper Bound': 0.1596},
            {'Risk Score': 38, 'Lower Bound': -0.0761, 'Upper Bound': 0.1646},
            {'Risk Score': 39, 'Lower Bound': -0.0793, 'Upper Bound': 0.1695},
            {'Risk Score': 40, 'Lower Bound': -0.0826, 'Upper Bound': 0.1748},
            {'Risk Score': 41, 'Lower Bound': -0.0858, 'Upper Bound': 0.1804},
            {'Risk Score': 42, 'Lower Bound': -0.0892, 'Upper Bound': 0.1868},
            {'Risk Score': 43, 'Lower Bound': -0.0923, 'Upper Bound': 0.193},
            {'Risk Score': 44, 'Lower Bound': -0.0957, 'Upper Bound': 0.1997},
            {'Risk Score': 45, 'Lower Bound': -0.099, 'Upper Bound': 0.2065},
            {'Risk Score': 46, 'Lower Bound': -0.1023, 'Upper Bound': 0.2135},
            {'Risk Score': 47, 'Lower Bound': -0.1057, 'Upper Bound': 0.221},
            {'Risk Score': 48, 'Lower Bound': -0.1091, 'Upper Bound': 0.2285},
            {'Risk Score': 49, 'Lower Bound': -0.1124, 'Upper Bound': 0.2362},
            {'Risk Score': 50, 'Lower Bound': -0.1156, 'Upper Bound': 0.244},
            {'Risk Score': 51, 'Lower Bound': -0.1191, 'Upper Bound': 0.2525},
            {'Risk Score': 52, 'Lower Bound': -0.1224, 'Upper Bound': 0.261},
            {'Risk Score': 53, 'Lower Bound': -0.1258, 'Upper Bound': 0.2699},
            {'Risk Score': 54, 'Lower Bound': -0.129, 'Upper Bound': 0.2789},
            {'Risk Score': 55, 'Lower Bound': -0.1323, 'Upper Bound': 0.2884},
            {'Risk Score': 56, 'Lower Bound': -0.1357, 'Upper Bound': 0.2983},
            {'Risk Score': 57, 'Lower Bound': -0.1389, 'Upper Bound': 0.3084},
            {'Risk Score': 58, 'Lower Bound': -0.1422, 'Upper Bound': 0.3191},
            {'Risk Score': 59, 'Lower Bound': -0.1454, 'Upper Bound': 0.3301},
            {'Risk Score': 60, 'Lower Bound': -0.1494, 'Upper Bound': 0.3401},
            {'Risk Score': 61, 'Lower Bound': -0.1534, 'Upper Bound': 0.3459},
            {'Risk Score': 62, 'Lower Bound': -0.1586, 'Upper Bound': 0.3534},
            {'Risk Score': 63, 'Lower Bound': -0.1624, 'Upper Bound': 0.359},
            {'Risk Score': 64, 'Lower Bound': -0.166, 'Upper Bound': 0.3644},
            {'Risk Score': 65, 'Lower Bound': -0.1708, 'Upper Bound': 0.3716},
            {'Risk Score': 66, 'Lower Bound': -0.1755, 'Upper Bound': 0.3786},
            {'Risk Score': 67, 'Lower Bound': -0.18, 'Upper Bound': 0.3855},
            {'Risk Score': 68, 'Lower Bound': -0.1833, 'Upper Bound': 0.3906},
            {'Risk Score': 69, 'Lower Bound': -0.1887, 'Upper Bound': 0.3989},
            {'Risk Score': 70, 'Lower Bound': -0.1929, 'Upper Bound': 0.4054},
            {'Risk Score': 71, 'Lower Bound': -0.197, 'Upper Bound': 0.4119},
            {'Risk Score': 72, 'Lower Bound': -0.202, 'Upper Bound': 0.4198},
            {'Risk Score': 73, 'Lower Bound': -0.2059, 'Upper Bound': 0.426},
            {'Risk Score': 74, 'Lower Bound': -0.2107, 'Upper Bound': 0.4337},
            {'Risk Score': 75, 'Lower Bound': -0.2153, 'Upper Bound': 0.4412},
            {'Risk Score': 76, 'Lower Bound': -0.2198, 'Upper Bound': 0.4486},
            {'Risk Score': 77, 'Lower Bound': -0.2242, 'Upper Bound': 0.4559},
            {'Risk Score': 78, 'Lower Bound': -0.2285, 'Upper Bound': 0.4631},
            {'Risk Score': 79, 'Lower Bound': -0.2335, 'Upper Bound': 0.4716},
            {'Risk Score': 80, 'Lower Bound': -0.2384, 'Upper Bound': 0.4799},
            {'Risk Score': 81, 'Lower Bound': -0.2432, 'Upper Bound': 0.4881},
            {'Risk Score': 82, 'Lower Bound': -0.2479, 'Upper Bound': 0.4962},
            {'Risk Score': 83, 'Lower Bound': -0.2524, 'Upper Bound': 0.5042},
            {'Risk Score': 84, 'Lower Bound': -0.2568, 'Upper Bound': 0.512},
            {'Risk Score': 85, 'Lower Bound': -0.2618, 'Upper Bound': 0.521},
            {'Risk Score': 86, 'Lower Bound': -0.266, 'Upper Bound': 0.5286},
            {'Risk Score': 87, 'Lower Bound': -0.2708, 'Upper Bound': 0.5374},
            {'Risk Score': 88, 'Lower Bound': -0.2762, 'Upper Bound': 0.5472},
            {'Risk Score': 89, 'Lower Bound': -0.2807, 'Upper Bound': 0.5554},
            {'Risk Score': 90, 'Lower Bound': -0.2854, 'Upper Bound': 0.5637},
            {'Risk Score': 91, 'Lower Bound': -0.2912, 'Upper Bound': 0.574},
            {'Risk Score': 92, 'Lower Bound': -0.2957, 'Upper Bound': 0.582},
            {'Risk Score': 93, 'Lower Bound': -0.3, 'Upper Bound': 0.59},
            {'Risk Score': 94, 'Lower Bound': -0.3054, 'Upper Bound': 0.5998},
            {'Risk Score': 95, 'Lower Bound': -0.3106, 'Upper Bound': 0.6094},
            {'Risk Score': 96, 'Lower Bound': -0.3157, 'Upper Bound': 0.619},
            {'Risk Score': 97, 'Lower Bound': -0.3206, 'Upper Bound': 0.6283},
            {'Risk Score': 98, 'Lower Bound': -0.3255, 'Upper Bound': 0.6376},
            {'Risk Score': 99, 'Lower Bound': -0.3302, 'Upper Bound': 0.6467},
        ]

    static load = () => {
        //loading static data!
    }
}
