/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import MainSearch from "./MainSearch";
import ClientsList from "./ClientsList";
import {cancel, getClients} from '@API/clients';


const AllClients = () => {
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [secureMode, setStateSecureMode] = useState(localStorage.getItem("advSecureMode") !== null ? localStorage.getItem("advSecureMode") === "true" : false);

    const setSecureMode = state => {
        setStateSecureMode(state);
        localStorage.setItem("advSecureMode", state);
    }

    useEffect(() => {
        fetchData(searchString)
    }, []);


    const fetchData = (searchText, secure = null) => {
        if (cancel) {
            cancel();
        }
        setSearchString(searchText);
        setLoading(true);
        getClients(searchText, skip, limit, clientsHandler(secure));
    }

    const loadMore = () => {
        const newCount = limit + limit;
        setLimit(newCount);
        //FIXME: concat, not redraw
        fetchData(searchString);
    }

    const clientsHandler = (secure) => {
        if (secure === null) {
            return (clients, error) => {
                setLoading(false);
                setSearchResults(clients);
            }
        } else {
            return (clients, error) => {
                setLoading(false);
                setSearchResults(clients);
                setSecureMode(secure);
            }
        }
    }

    return (
        <>
            <MainSearch fetchData={fetchData} secureMode={secureMode} setSecureMode={setSecureMode}
                        placeholder={"Type in the client name to start searching"}/>
            <ClientsList dataArray={searchResults} loading={loading} loadMore={loadMore}
                         secureMode={secureMode} setSecureMode={setSecureMode}/>
        </>
    );
}

export default AllClients;
