import {Flex, Typography, theme, Row, Col} from "antd";
import React, {useState} from "react";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ReportDownloadButton
    from "@holistico/proposal/view/report/ReportDownloadButton";
import {EditOutlined} from "@ant-design/icons";
import {useLatest} from "ahooks";

const {useToken} = theme;

const ProposalHeader = () => {
    const {proposal, updateBaseClientInfo} = useHolisticoProposalContext()
    const {token} = useToken();
    const [input, setInput] = useState('');
    const latestCountRef = useLatest(input);

    const inputChange = (new_text) => {
        setInput(new_text)
        console.log('just set input to ' + new_text)
    }

    const saveNewName = () => {
        function foo() {
            updateBaseClientInfo({full_name: latestCountRef.current})
        }
        setTimeout(foo, 0);
    }

    return <Row>
        <Col span={8}>
            <Typography.Title level={1} style={{
                color: token.colorWarning,
                fontVariant: "small-caps",
                fontSize: 48,
                lineHeight: 1.19,
                marginTop: 24,
                marginBottom: 24
            }} editable={{
                //enterIcon: null,
                icon: <EditOutlined style={{fontSize: '30%'}}/>,
                onStart: () => {
                    console.log('on start call ' + proposal.full_name)
                    //setEditableFullName(proposal.full_name)
                },
                onChange: (t) => inputChange(t),
                onEnd: () => saveNewName(),
            }}>{proposal?.full_name}</Typography.Title>

        </Col>
        <Col span={4}></Col>
        <Col span={6}></Col>
        <Col span={6}>
            <Typography.Title level={1}>
                <ReportDownloadButton
                    reportClass="client"
                    strictPresets={['full_with_fixed_income', 'single']}
                    size={"large"}
                />
            </Typography.Title>

        </Col>
    </Row>

}


export default ProposalHeader
