import {Modal, Progress, Typography} from "antd";
import React, {useEffect, useState} from "react";
import ValueFormatter from "@global/ValueFormatter";


const SimpleProgressModal = ({description, open}) => {
    const [loadPercent, setLoadPercent] = useState(0);

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: false,
        title: null,
        footer: null,
        style: {background: "none"}
    }

    useEffect(() => {
            if (open) {
                setLoadPercent(0)
                let progressInterval = progressIntervalCaller();
                return () => {
                    clearInterval(progressInterval);
                }
            }
        },
        [open]
    )

    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    return <Modal {...modalProps} open={open} className={"holistico"}>
        <>
            <Typography.Text>{description}</Typography.Text>
            <Progress percent={loadPercent} status="active" strokeColor='#E0AD60'/>
        </>
    </Modal>
}

export default SimpleProgressModal;