import React from "react";


const TutorialVideo = ({title, src}) => {
    return <iframe
        width="560"
        height="315"
        src={src}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{border: "none"}}
    />
}


export default TutorialVideo
