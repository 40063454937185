import React from 'react';
import {App, Col, ConfigProvider, Flex, Row, Slider, Space, Typography} from "antd";
import "./RiskToleranceSlider.less"


const RiskToleranceSlider = ({defaultValue, onChange}) => {
    const textStyle = {
        textTransform: 'uppercase',
        letterSpacing: '2px',
        color: '#475a6b',
        fontWeight: 350
    }
    return <Row style={{height: 400}}>
        <Col span={8}>
            <Flex vertical={true} justify={"space-around"} align={"center"} style={{height: 400}}>
                <Typography.Text style={textStyle}>High risk</Typography.Text>
                <Typography.Text style={textStyle}>Moderate</Typography.Text>
                <Typography.Text style={textStyle}>Low risk</Typography.Text>
            </Flex>
        </Col>
        <Col span={16}>
            <ConfigProvider
                theme={{
                    "components": {
                        "Slider": {
                            handleColor: "#e0ad60",
                            handleActiveColor: "#e0ad60",
                            trackBg: "#e0ad60",
                            trackHoverBg: "#e0ad60",
                            handleSize: 24,
                            handleSizeHover: 24,
                            railSize: 8,
                            dotSize: 16,
                            handleLineWidth: 2
                        }
                    }
                }}>
                <Slider
                    style={{whiteSpace: 'nowrap'}}
                    vertical={true}
                    defaultValue={defaultValue}
                    min={0}
                    max={99}
                    included={true}
                    marks={{
                        0: {label: 'Cash'},
                        25: {label: 'Treasuries'},
                        40: {label: 'Corporate Bonds'},
                        60: {label: 'S&P 500'},
                        88: {label: 'FAANG'},
                    }}
                    onChange={onChange}
                    tooltip={{placement: "right"}}
                />
            </ConfigProvider>
        </Col>
    </Row>
    return <div className={"risk-tolerance-slider"}>
        <div className={"risk-level-hint"}>High risk</div>
        <div className={"risk-level-hint"}>Moderate</div>
        <div className={"risk-level-hint"}>Low risk</div>
        <div className={"slider-container"}>
            <Slider
                vertical={true}
                defaultValue={defaultValue}
                min={0}
                max={99}
                included={true}
                marks={{
                    0: {label: 'Cash'},
                    25: {label: 'Treasuries'},
                    40: {label: 'Corporate Bonds'},
                    60: {label: 'S&P 500'},
                    88: {label: 'FAANG'},
                }}
                onChange={onChange}
                tooltip={{placement: "right"}}
            />
        </div>
    </div>
}

export default RiskToleranceSlider;
