/* eslint-disable react/prop-types */
import React, {useContext, useState} from 'react';
import {Button, message, Spin, Steps} from 'antd';
import './Questionnaire.less'
import QStepOne from "./steps/QStepOne";
import QStepTwo from "./steps/QStepTwo";
import QStepRisk from "./steps/QStepRisk";
import QStepRiskImprove from "./steps/QStepRiskImprove";
import QStepRiskResult from "./steps/QStepRiskResult";
import StaticInfo from "@global/StaticInfo";
import {saveQuestionnaire} from "@API/clients";
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";


const {Step} = Steps;

const Questionnaire = ({questionnaire}) => {
    const [questionnaireProxy, setQuestionnaireProxy] = useState(questionnaire);
    const {client, loadData} = useContext(SpasClientContext);


    const [step, setStep] = useState(questionnaireProxy?.step ? questionnaireProxy?.step : 0);
    const [loading, setLoading] = useState(false);

    const financialParamsChanged = (name, value) => {
        let setterObject = {...questionnaireProxy, [name]: value}

        if (name === 'risk' || name === 'risk-') {
            let percentData = StaticInfo.riskReturns[value]

            console.log(value, percentData)
            setterObject['negative_performance_plan'] = percentData['Lower Bound']
            setterObject['positive_performance_plan'] = percentData['Upper Bound']

            setterObject['negative_performance_plan_dollar'] = setterObject['negative_performance_plan'] * questionnaireProxy.investment_amount
            setterObject['positive_performance_plan_dollar'] = setterObject['positive_performance_plan'] * questionnaireProxy.investment_amount

            setterObject['risk'] = value

        }

        setQuestionnaireProxy(setterObject)
        if (name === 'risk-') {
            next()
        }
    }

    const setGoals = (newGoals) => {
        if (!isFinite(questionnaireProxy.investment_amount)) {
            const current_calculations = client?.p_bucket_cur?.calculated_values
            if (current_calculations) {
                let inv_amount = current_calculations['market_value']
                setQuestionnaireProxy({
                    ...questionnaireProxy,
                    'goals': newGoals,
                    'investment_amount': inv_amount
                })
            }
        } else {
            setQuestionnaireProxy({...questionnaireProxy, 'goals': newGoals, 'objectives': newGoals.join('; ')})
        }

    }

    const apply = (finilize = false, increaseStep = false) => {
        setLoading(true);

        saveQuestionnaire(client._id, {...questionnaireProxy, step: step}, (response, error) => {
            setLoading(false);
            if (!error) {
                if (finilize == true) {
                    loadData(true);
                }
                if (increaseStep === true) {
                    if (checkStep(step)) {
                        setStep(step + 1);
                    }
                }
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }

        });
    }

    const checkStep = () => {
        if (step == 0) {
            if (!questionnaireProxy.goals) {
                message.error('Please select one or more goals');
                return false
            }
        }

        if (step == 1) {
            if (!questionnaireProxy.investment_amount || questionnaireProxy.investment_amount < 1) {
                message.error('Investment amount must be greater than $1!');
                return false
            }
        }

        return true
    }

    const first = () => {
        setStep(0)
    }


    const next = () => {
        apply(false, true);
        // const newStep = step + 1

        // if (checkStep(step)) {
        //     setStep(newStep)
        // }
    }

    const prev = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    }

    const steps = [
        {
            title: <a href={'#'} style={{color: 'black'}} onClick={first}>Specify goals</a>,
            content: <QStepOne goals={questionnaireProxy?.goals} setGoals={setGoals}/>,
        },
        {
            title: 'Define financial status',
            content: <QStepTwo financialParams={questionnaireProxy} financialParamsChanged={financialParamsChanged}/>
        },
        {
            title: 'Evaluate risk tolerance',
            content: <QStepRisk financialParams={questionnaireProxy} financialParamsChanged={financialParamsChanged}/>
        },
        {
            title: 'Adjust risk preferences',
            content: <QStepRiskImprove financialParams={questionnaireProxy}
                                       financialParamsChanged={financialParamsChanged}/>
        }, {
            title: 'Get risk score',
            content: <QStepRiskResult financialParams={questionnaireProxy}/>
        }
    ];

    return (
        loading ? <div style={{height: 300}} className="loadingBox"><Spin size="large"></Spin></div> :
            <div className={"questionnaire"}>
                <Steps current={step} size="small">
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div className="steps-content">{steps[step].content}</div>
                <div className="steps-action">
                    {step > 0 && step != 3 && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Back
                        </Button>
                    )}
                    {(step < steps.length - 1 && step != 3) && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {(step == 3) && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Back to tolerance
                        </Button>
                    )}
                    {(step == 3) && (
                        <Button type="primary" onClick={() => {
                            next()
                        }}>
                            Feels like me
                        </Button>
                    )}
                    {step === steps.length - 1 && (
                        <>
                            <Button type="primary" onClick={() => apply(true)}>
                                Apply risk score
                            </Button>
                        </>
                    )}
                </div>
            </div>
    )
}

export default Questionnaire;
