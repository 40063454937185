import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

/* all securities with weights and performances by model */
const getAllocationsSummary = (definitionIds, callback) => {
    let url = `/portfolio/allocations/summary`
    const params = {
        definition_ids: definitionIds,
        name: ""
    }
    axiosInstance.post(url, params)
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
}

const getAllocationsPresets = (templatesHandler) => {
    let url = `/portfolio/allocations/presets/`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        templatesHandler(data);
    })
        .catch(({response}) => {
            console.log(response);
        })
};

const saveAllocationsPreset = (userPreset, creationCallback) => {
    let url = `/portfolio/allocations/presets/`
    
    if (userPreset._id) {
        axiosInstance.put(url, userPreset)
            .then((result) => {
                creationCallback(result, null);
            })
            .catch(({response}) => {
                console.log(response);
                creationCallback(null, response);
            })
    } else {
        axiosInstance.post(url, userPreset)
            .then((result) => {
                creationCallback(result, null);
            })
            .catch(({response}) => {
                console.log(response);
                creationCallback(null, response);
            })    
    }

    
};

const deleteAllocationsPreset = (_id, deletionCallback) => {
    let url = `/portfolio/allocations/presets/${_id}`

    axiosInstance.delete(url)
        .then(() => {
            deletionCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};

const getPerformanceExcel = (handler) => {
    let url = `/portfolio/allocations/performance_excel`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            responseType: "arraybuffer"
        }).then((response) => {
            handler(response, false);
    })
        .catch((response) => {
            console.log(response);
            handler(null, true);
        })
};

const getAllocationsExcel = (data, handler) => {
    let url = `/portfolio/allocations/allocations_excel`

    axiosInstance.post(url, data,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            responseType: "arraybuffer"
        }).then((response) => {
            handler(response, false);
    })
        .catch((response) => {
            console.log(response);
            handler(null, true);
        })
};

export {
    getAllocationsSummary,
    getAllocationsPresets,
    saveAllocationsPreset,
    deleteAllocationsPreset,
    getPerformanceExcel,
    getAllocationsExcel,
    cancel
}
