import React from "react";
import {Empty} from "antd";
import ScoreMarkersStats from "@components/chart/ScoreMarkersStats";

const ScoreAttribution = ({fullAsset}) => {
    if (fullAsset) {
        let marker_values = [];
        for (let col of Object.keys(fullAsset)) {
            if (col.includes('sscore_plus') && fullAsset[col] !== null) {
                marker_values.push({
                    label: col,
                    value: fullAsset[col]
                });
            }
        }
        return (
            <div>
                {marker_values.length > 0 ?
                    <ScoreMarkersStats score_markers={marker_values} title={"Rating markers"} loading={false}/>
                    :
                    <Empty description="Rating attribution is not available"/>
                }
            </div>
        )
    }
    return <></>
}

export default ScoreAttribution