import axiosInstance from "@API/APIClient";
import axios from "axios";

let cancel;

const getFactors = (callback) => {
    let factorUrl = `/factors`;
    axiosInstance.get(factorUrl,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true)
        })
}

const getSimpleFactors = (search_string, callback) => {
    let factorUrl = `/factors/simple`;
    axiosInstance.get(factorUrl, {
        params: {search_string: search_string},
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true)
        })
}

const getGroupedFactors = (search_string, callback) => {
    let factorUrl = `/factors/grouped`;
    axiosInstance.get(factorUrl, {
        params: {search_string: search_string},
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true)
        })
}

const getPortfolioFactors = (callback) => {
    let factorUrl = `/p_factors`;
    axiosInstance.get(factorUrl,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch((response) => {
            callback(response, true)
        })
}

export
{
    getFactors, getPortfolioFactors, getGroupedFactors, getSimpleFactors, cancel
}
