import {Button, Card, Col, Form, Row, Typography} from "antd";
import React, {useState} from "react";
import PreliminaryAnalysis from "@holistico/proposal/new/preview/PreliminaryAnalysis";
import PortfolioLabel from "@holistico/proposal/view/fundamentals/PortfolioLabel";
import ModelSelectInput from './ModelSelectInput';
import CustomSelectInput from "@holistico/proposal/new/preview/CustomSelectInput";
import AssetClassCompareRow from "@holistico/proposal/new/preview/AssetClassCompareRow";
import ScoreMarkersCompareRow from "@holistico/proposal/new/preview/ScoreMarkersCompareRow";
import StyleSizeCompareRow from "@holistico/proposal/new/preview/StyleSizeCompareRow";
import SectorBar from "@holistico/chart/SectorBar";
import StatsList from "@holistico/proposal/new/preview/StatsList";
import ValueFormatter from "@global/ValueFormatter";
import FISectorCompareRow from "@holistico/proposal/new/preview/FISectorCompareRow";
import MaturityDateBar from "@holistico/chart/MaturityDateBar";
import CreditRatingCompareRow from "@holistico/proposal/new/preview/CreditRatingCompareRow";
import AiPreview from "@holistico/proposal/new/preview/AiPreview";
import {useNavigate} from "react-router-dom";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import SectionDescriptionEllipsis from "@holistico/aux/SectionDescription";
import {
    assetAllocationDescription
} from "@holistico/proposal/view/fundamentals/AssetAllocation";
import {
    ratingsDescription, sectorsDescription, sizeDescription, styleDescription
} from "@holistico/proposal/view/fundamentals/EquitiesPlain";
import SizeCompareRow from "@holistico/proposal/new/preview/SizeCompareRow";
import {
    creditRatingDescription, fiSectorDescription, maturityDateDescription
} from "@holistico/proposal/view/fundamentals/FixedIncomePlain";


const ComparativePortfolioPreview = () => {
    const navigate = useNavigate();
    const {id, proposal: client, setBenchmark} = useHolisticoProposalContext()


    const [benchmarkCalculatedValues, setBenchmarkCalculatedValues] = useState({})

    if (!client?.p_bucket_cur?.calculated_values) {
        return null
    }

    if (!client?.p_bucket_cur?.calculated_values?.market_value) {
        return null
    }

    const benchmarkSelected = (portfolio) => {
        console.dir(portfolio.calculated_values)
        setBenchmarkCalculatedValues(portfolio.calculated_values);
        setBenchmark(portfolio)
    }
    return <div>
        <Row gutter={[24, 24]}>

            <Col span={12}>
                <CustomSelectInput items={[{
                    label: `Current Portfolio, ${ValueFormatter.currency(client?.p_bucket_cur?.calculated_values?.market_value)}`,
                    value: 'Current Portfolio'
                }]}/>
                <StatsList
                    calculated_values={client?.p_bucket_cur?.calculated_values}/>
            </Col>
            <Col span={12}>
                <ModelSelectInput onChange={benchmarkSelected}
                                  risk_score={client?.p_bucket_cur?.calculated_values?.risk?.risk_score}/>
                <StatsList calculated_values={benchmarkCalculatedValues}/>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Asset class
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {assetAllocationDescription}
                </SectionDescriptionEllipsis>
                <AssetClassCompareRow
                    original_values={client?.p_bucket_cur?.calculated_values}
                    recommended_values={benchmarkCalculatedValues}/>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Holistic view
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {ratingsDescription}
                </SectionDescriptionEllipsis>
                <ScoreMarkersCompareRow
                    original_values={client?.p_bucket_cur?.calculated_values}
                    recommended_values={benchmarkCalculatedValues}/>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Size, equities
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {sizeDescription}
                </SectionDescriptionEllipsis>
                <SizeCompareRow
                    original_values={client?.p_bucket_cur?.calculated_values}
                    recommended_values={benchmarkCalculatedValues}/>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Size & style, equities
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {styleDescription}
                </SectionDescriptionEllipsis>
                <StyleSizeCompareRow
                    original_values={client?.p_bucket_cur?.calculated_values}
                    recommended_values={benchmarkCalculatedValues}/>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Sector, equities
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {sectorsDescription}
                </SectionDescriptionEllipsis>
                <div style={{width: '100%'}}>
                    <SectorBar
                        allocation={client?.p_bucket_cur?.calculated_values?.sector}
                        proposalAllocation={benchmarkCalculatedValues?.sector}
                        proposalLabel={"Benchmark"}/>
                </div>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Credit rating, fixed-income
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {creditRatingDescription}
                </SectionDescriptionEllipsis>
                <div style={{width: '100%'}}>
                    <CreditRatingCompareRow
                        original_values={client?.p_bucket_cur?.calculated_values}
                        recommended_values={benchmarkCalculatedValues}/>
                </div>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Sector, fixed-income
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {fiSectorDescription}
                </SectionDescriptionEllipsis>
                <div style={{width: '100%'}}>
                    <FISectorCompareRow
                        original_values={client?.p_bucket_cur?.calculated_values}
                        recommended_values={benchmarkCalculatedValues}/>
                </div>
            </Col>

            <Col span={24}>
                <Typography.Title level={4} className={"section-header"}
                                  style={{marginTop: 20}}>
                    Maturity date, fixed-income
                </Typography.Title>
                <SectionDescriptionEllipsis>
                    {maturityDateDescription}
                </SectionDescriptionEllipsis>
                <div style={{width: '100%'}}>
                    <MaturityDateBar
                        allocation={client?.p_bucket_cur?.calculated_values?.fi_effective_maturity}
                        proposalAllocation={benchmarkCalculatedValues?.fi_effective_maturity}
                        proposalLabel={"Benchmark"}/>
                </div>
            </Col>

            <Col span={24}>
                {/*<AiPreview client_id={client._id}/>*/}
            </Col>
        </Row>
    </div>
}


export default ComparativePortfolioPreview
