import React from "react";
import {Route, Routes} from "react-router-dom";
import AdminMain from "@components/admin_panel/AdminMain";
import UserRouter from "@components/admin_panel/users/UserRouter";
import { useAuth } from "@hooks/AuthHook";
import SchedulerRouter from "@components/admin_panel/scheduler/SchedulerRouter";

const AdminRoutes = () => {
    const auth = useAuth();
    
    return (
        <Routes>
            {auth?.isAdmin() && <Route exact path="" element={<AdminMain/>}/>}
            {auth?.isAdmin() && <Route path="users/*" element={<UserRouter/>} />}
            {auth?.isAdmin() && <Route path="scheduler/*" element={<SchedulerRouter/>} />}
        </Routes>
    )
}

export default AdminRoutes
