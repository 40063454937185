/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import {
    AutoComplete,
    Dropdown,
    Button,
    Col,
    Input,
    Collapse,
    Empty,
    Modal,
    Row,
    Space,
    Typography,
    message,
    Menu, Card, Divider
} from "antd";
import '@root/components/portfolio/Portfolio.less';
import '@root/components/portfolio/list/PortfolioList.less';
import {DeleteOutlined, DownOutlined} from '@ant-design/icons';
import {getAllocationsPresets, deleteAllocationsPreset, saveAllocationsPreset} from "@API/allocation";
import {Form} from "@ant-design/compatible";

const {Panel} = Collapse;
const {Paragraph, Text, Title} = Typography;
const AllocationsPresetManager = ({presetSelected = null, definitionIds = []}) => {
    const [allOptions, setAllOptions] = useState([])
    const [foundOptions, setFoundOptions] = useState([])
    const [currentPreset, setCurrentPreset] = useState({name: "", definition_ids: []})
    const [showSelectPreset, setShowSelectPreset] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadPresets()
    }, [showSelectPreset])

    const loadPresets = () => {
        setLoading(true)
        getAllocationsPresets((presets) => {
            setLoading(false)
            let opts = presets.map(p => {
                return {
                    ...p,
                    value: p.name,
                    label: <span>
                        {p.name}
                        <a
                            style={{
                                float: 'right',
                            }}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                console.log('aaaaa')
                            }}
                        >
                      x
                    </a>
                  </span>,
                    key: p._id
                }
            })
            setAllOptions(opts)
        })
    }

    const savePreset = (updateExisting) => {
        setLoading(true)
        let presetToSave = {
            name: currentPreset.name,
            definition_ids: definitionIds
        }

        if (updateExisting) {
            presetToSave._id = currentPreset._id
        }

        saveAllocationsPreset(presetToSave, (result, error) => {
            if (error) {
                message.error('Error saving the preset: ' + err)
            } else {
                message.success(currentPreset.name + ' was successfully created')
            }
            loadPresets()
        })

    }

    const deletePreset = (event, preset) => {
        event.stopPropagation()
        event.preventDefault()
        console.log('now the preset will be deleted!' + JSON.stringify(preset))
        deleteAllocationsPreset(preset._id, () => {
            loadPresets()
        })
    }

    const onSelect = (name, selectedPreset) => {
        console.log(selectedPreset)
        setCurrentPreset(selectedPreset)
        presetSelected(selectedPreset)
    }

    const onChange = (newValue) => {
        console.log("onchange coming " + newValue)
        setCurrentPreset({...currentPreset, name: newValue})
    }

    return (
        <Space direction={"horizontal"}>
            <Form.Item>
                <AutoComplete
                    loading={loading}
                    size={'middle'}
                    allowClear={true}
                    style={{
                        width: 310,
                    }}
                    options={allOptions}
                    onSelect={onSelect}
                    onChange={onChange}
                    filterOption={(inputValue, option) => {
                        if (!inputValue) inputValue = ""
                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }}
                >
                    <Input size="middle" placeholder="Click to load or type a new name to save"/>
                </AutoComplete>
            </Form.Item>
            <Form.Item>
                <Dropdown.Button
                    type="primary"
                    icon={<DownOutlined/>}
                    trigger={['click']}
                    loading={loading}
                    style={{marginLeft: 40}}
                    onClick={() => savePreset(true)}
                    overlay={
                        <Menu
                            items={[
                                {
                                    label: 'Save as',
                                    key: 'new',
                                    onClick: () => savePreset(false)
                                },
                            ]}
                        />}>
                    Save
                </Dropdown.Button>
            </Form.Item>
        </Space>)
}

export default AllocationsPresetManager;
