/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Modal, Spin} from 'antd';
import {getGroupedFactors} from "@API/factor";
import FactorSearch from "@components/portfolio/selector/FactorSearch";
import {FileExcelOutlined} from '@ant-design/icons';

import './FactorSelector.less';


const fixed_income_columns = ['fi_credit_rating', 'fi_ytw', 'fi_effective_maturity']
const expanded_eq_columns = ['sector_alloc', 'div_yield', 'sscore_plus']
const performance_columns = ['total_return_1m','total_return_1q','total_return_ytd', 'total_return_1yr','total_return_1yr_relative_to_benchmark']
const past_performance_columns = ['total_return_1yr', 'total_return_3yr', 'total_return_5yr', 'total_return_10yr']

const FactorSelector = ({selectedColumnNames = [], done, ligthVersion = false, downloadFactors = null}) => {
    const [factorDialogVisible, setFactorDialogVisible] = useState(false);
    const [selectionProxy, setSelectionProxy] = useState([]);
    const [loading, setLoading] = useState(false);
    //grouped ones
    const [factorsMap, setFactorsMap] = useState({});

    const updateSelection = (columns = null) => {
        setFactorDialogVisible(false);
        if (!columns) {
            columns = selectionProxy;
        } else {
            setSelectionProxy(columns);
        }
        let selectedFactors = [];
        for (let group of Object.entries(factorsMap)) {
            let [key, factors] = group;
            let found = factors.filter(factor => columns.includes(factor.column_name))
            if (found.length) {
                selectedFactors.push(...found)
            }
            if (selectedFactors.length == columns.length) {
                break;
            }
        }
        selectedFactors = selectedFactors.sort((a, b) => columns.indexOf(a.column_name) - columns.indexOf(b.column_name))
        done(selectedFactors);
    }

    const resetSelection = () => {
        setSelectionProxy([]);
        done([]);
    }

    const setFi = () => {
        updateSelection(fixed_income_columns);
    }

    const setEq = () => {
        updateSelection(expanded_eq_columns);
    }

    const setPerformance = () => {
        updateSelection(performance_columns);
    }
    
    const setExtPerformance = () => {
        updateSelection(past_performance_columns);
    }

    useEffect(() => {
        setLoading(true);
        getGroupedFactors("", groupedFactors => {
            console.log(groupedFactors)
            setLoading(false);
            setFactorsMap(groupedFactors);
        })
    }, [])

    useEffect(() => {
        setSelectionProxy(selectedColumnNames);
    }, [selectedColumnNames, factorDialogVisible])

    const memoFactorSearch = useMemo(() => {
        return (
            <FactorSearch selectedColumnNames={selectionProxy} availableFactors={factorsMap}
            updateSelection={(selected_columns) => {
                setSelectionProxy(selected_columns)
            }}/>
        )
    }, [selectionProxy, factorsMap, setSelectionProxy])

    const controledDownloadFactors = () => {
        if (downloadFactors !== null) {
            setLoading(true);
            downloadFactors(selectionProxy, () => {
                setLoading(false);
            });
        }
    }

    return (
        (<Spin spinning={loading}>
            <div style={{display: "flex", flexWrap: "wrap", columnGap: "20px", rowGap: "10px", marginBottom: "10px"}}>
                <Button type={'primary'} onClick={() => setFactorDialogVisible(true)}>
                    Manage columns
                </Button>
                <Button type="dashed" onClick={() => resetSelection(true)}>
                    Reset columns
                </Button>
                {downloadFactors !== null && <Button type="dashed" onClick={controledDownloadFactors}>
                    <FileExcelOutlined style={{color: "green"}}/> Download excel
                </Button>}
                {!ligthVersion && 
                <>
                    <Button type="link" onClick={() => setEq()}>Equity</Button>
                    <Button type="link" onClick={() => setFi()}>Fixed Income</Button>
                    <Button type="link" onClick={() => setPerformance()}>Performance</Button>
                    <Button type="link" onClick={() => setExtPerformance()}>Ext. Performance</Button>
                    
                </>
                }

                <Modal
                    title="Select factors"
                    open={factorDialogVisible}
                    zIndex={1005}
                    closable={false}
                    onCancel={() => setFactorDialogVisible(false)}
                    onOk={() => updateSelection()}
                    width={"80%"}
                >
                    {memoFactorSearch}
                </Modal>
            </div>
        </Spin>)
    );
}

export default FactorSelector;
