import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Typography,
    message,
    Card,
    theme,
    Flex,
    Tooltip,
    List,
    Popconfirm,
} from 'antd';
import {
    CaretRightFilled,
    CloudDownloadOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {
    deleteBacktestConfig, downloadBacktestWeekly,
    getBacktestConfigs,
    saveBacktestConfig
} from "@API/backtest";
import Localizator from "@global/Localizator";
import {prepareForServer} from "@components/backtest/BacktestTransformator";
import WeeklyConfigModal from "@components/backtest/WeeklyConfigModal";
import {downloadFile} from "@global/FileDownloader";


const {useToken} = theme;


const ConfigurationSelectModal = ({configs, open, onSelect, onCancel}) => {
    const {token} = useToken();

    return <Modal
        title={null}
        open={open}
        width={750}
        closable={false}
        maskClosable={true}
        onCancel={onCancel}
        footer={null}
    >
        <List
            dataSource={configs}
            renderItem={(config, index) => {
                const freq = Localizator.t(`backtest_v2.${config.return_factor}`);
                return <List.Item
                    key={index}
                    className={"selection-bg-on-hover"}
                    style={{
                        paddingLeft: token.paddingSM,
                        paddingRight: token.paddingSM,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        cursor: "pointer"
                    }}
                    onClick={() => onSelect(config.id)}
                >
                    <Typography.Text style={{
                        fontSize: token.fontSizeLG
                    }}>
                        {config.name}
                    </Typography.Text>
                    {config.return_factor &&
                        <Typography.Text style={{color: "#a2a2a2", fontSize: token.fontSizeSM}}>
                            Rebalance {freq.toLowerCase()}
                        </Typography.Text>
                    }
                    {!config.id &&
                        <Typography.Text style={{color: "#a2a2a2", fontSize: token.fontSizeSM}}>
                            Default configuration
                        </Typography.Text>
                    }
                </List.Item>
            }}
        />
    </Modal>
}


const SaveAsModal = ({config, open, onCancel, onSubmit}) => {
    const [form] = Form.useForm();
    useEffect(
        () => {
            form.setFieldsValue({name: config.name + " (Copy)"})
        },
        [config]
    )

    return <Modal
        title={null}
        open={open}
        width={500}
        closable={false}
        maskClosable={true}
        onOk={form.submit}
        okText={"Save"}
        onCancel={onCancel}
        cancelText={"Cancel"}
    >
        <Form
            form={form}
            layout={"vertical"}
            onFinish={(values) => {
                onSubmit(values.name)
            }}
        >
            <Form.Item name={'name'} label={"New name"}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>
}


const BacktestHeader = ({config, loadConfig}) => {
    const form = Form.useFormInstance();
    const [configs, setConfigs] = useState([]);
    const [openSelectModal, setOpenSelectModal] = useState(false);
    const [openSaveAsModal, setOpenSaveAsModal] = useState(false);
    const [loadingPortfolio, setLoadingPortfoliol] = useState(false);
    const portfolioType = Form.useWatch(["portfolio_config", "type"], form);
    const {token} = useToken();

    useEffect(() => {
        updateConfigsList();
    }, [])

    const updateConfigsList = () => {
        getBacktestConfigs((configs) => {
            console.debug("Configs list loaded", configs);
            const defaultItem = [{id: null, name: "Default"}];
            setConfigs(defaultItem.concat(configs));
        })
    }

    const deleteConfig = () => {
        console.log('Configuration to be deleted:' + JSON.stringify(config))
        deleteBacktestConfig(config.id, () => {
            message.success("Backtest was successfully removed!")
            loadConfig(null);
            updateConfigsList();
        })
    }

    const saveConfig = (name = null) => {
        let _config = prepareForServer(form.getFieldsValue());
        if (name) {
            // Name appears when "save as" button clicked
            _config.name = name;
            delete _config.id;
        }
        saveBacktestConfig(_config, (error, result) => {
            if (error) {
                message.error('Error while saving configuration: ' + error);
            } else {
                message.success(_config.name + ' was successfully saved!');
                if (name) {
                    // Name appears when "save as" button clicked
                    loadConfig(result.data);
                }
            }
            updateConfigsList();
        })
    }

    const downloadPortfolio = useCallback(() => {
        setLoadingPortfoliol(true);
        let server_config = form.getFieldsValue();
        downloadBacktestWeekly(null, server_config, (response, error) => {
            if (error) {
                message.error("Error while downloading the portfolio");
                setLoadingPortfoliol(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = "Current portfolio composition.xlsx";
            downloadFile(response.data, file_name, file_type, () => {
                setLoadingPortfoliol(false);
            })
            message.success("Portfolio file was successfully created!");
        })
    }, [config])

    return <div style={{minHeight: 106}}>
        {configs.length > 0
            && <Flex
                gap={"large"}
                justify={"space-between"}
                align={"flex-end"}
                wrap={"wrap"}
            >
                <Flex vertical gap={"small"} style={{
                    flexGrow: 2,
                }}>
                    <Tooltip title={"Choose backtest configuration"}>
                        <Flex
                            gap={"middle"}
                            onClick={() => setOpenSelectModal(true)}
                            style={{
                                padding: token.paddingXS,
                                borderRadius: token.borderRadius,
                                fontSize: 36,
                                // fontVariant: "small-caps",
                                lineHeight: 1.1,
                                alignSelf: "flex-start",
                                alignItems: "center",
                                fontWeight: token.fontWeightStrong
                            }}
                            className={"grey-bg-on-hover"}
                        >
                            {config.name}
                            <DownOutlined style={{
                                color: "#a2a2a2",
                                fontSize: 24,
                                marginTop: 10
                            }}/>
                        </Flex>
                    </Tooltip>
                    <Flex gap={"small"}>
                        <Tooltip title={"Save configuration"} mouseEnterDelay={0.2}>
                            <Button
                                onClick={() => saveConfig()}
                                type="text"
                                icon={<SaveOutlined/>}
                                disabled={!config.id}
                            >
                                Save
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Save configuration under new name"} mouseEnterDelay={0.5}>
                            <Button
                                onClick={() => setOpenSaveAsModal(true)}
                                type="text"
                                icon={<CopyOutlined/>}
                            >
                                Save As
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Delete configuration"} mouseEnterDelay={0.2}>
                            <Popconfirm
                                title={`Are you sure to delete ${config.name || "this"} configuration?`}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={deleteConfig}
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined/>}
                                    disabled={!config.id}
                                >
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </Flex>
                </Flex>
                <Flex gap={"small"} justify={"flex-end"} style={{
                    flexGrow: 1,
                    flexShrink: 1
                }}>
                    <Tooltip
                        title={"Download portfolio composition that currently meets the selected constraints"}
                        mouseEnterDelay={0.2}
                    >
                        <Button
                            size={'large'}
                            icon={<CloudDownloadOutlined/>}
                            disabled={portfolioType !== "companies"}
                            onClick={() => downloadPortfolio()}
                            loading={loadingPortfolio}
                        >
                            Download portfolio
                        </Button>
                    </Tooltip>
                    <Button
                        size={'large'}
                        type="primary"
                        htmlType="submit"
                        icon={<CaretRightFilled/>}
                    >
                        Start testing
                    </Button>
                </Flex>
            </Flex>
        }
        {configs.length > 0
            && <>
                <ConfigurationSelectModal
                    configs={configs}
                    open={openSelectModal}
                    onSelect={(id) => {
                        setOpenSelectModal(false);
                        console.log("BacktestHeader selected", id);
                        loadConfig(id);
                    }}
                    onCancel={() => setOpenSelectModal(false)}
                />
                <SaveAsModal
                    config={config}
                    open={openSaveAsModal}
                    onCancel={() => {
                        setOpenSaveAsModal(false);
                    }}
                    onSubmit={(name) => {
                        saveConfig(name);
                        setOpenSaveAsModal(false);
                    }}
                />
            </>
        }
    </div>
}


export default BacktestHeader;
