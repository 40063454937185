import {Avatar, List} from "antd";
import React, {useRef} from "react";
import {useUpdateEffect} from "ahooks";
import {useAuth} from "@hooks/AuthHook";
import ChatMessage from "@holistico/ai/chat/AiChatMessage";
import AiChatInput from "@holistico/ai/chat/AiChatInput";
import AiSideBarItem from "@holistico/ai/sidebar/AiSideBarItem";
import {useAiChatContext} from "@hooks/AiChatContext";
import "./AiChat.less"


const AiChat = ({
                    height = "45vh",
                    setAiMinimized=(val)=>{},
                    setAiExpand=(val)=>{}
                }) => {
    const divRef = useRef(null);
    const auth = useAuth();
    const {messageList, newMsgTrigger} = useAiChatContext()

    useUpdateEffect(() => {
        setTimeout(() => {
            if (divRef?.current) {
                divRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                })
            }
        }, 500)
    }, [newMsgTrigger])

    return <AiSideBarItem
        title={"AI Chat"}
        icon={<Avatar size="small" src="/img/cyber-master-of-coin.jpg"/>}
        onMinimize={() => setAiMinimized(true)}
        onExpand={() => setAiExpand(true)}
    >
        <div className={"ai-chat"}>
            <List
                style={{
                    height: height,
                    overflowY: 'scroll',
                    width: '100%'
                }}
                gutter={24}
                dataSource={[...messageList]}
                size={'small'}
                renderItem={(item, index) => {
                    return <ChatMessage
                        index={index}
                        content={item.content}
                        role={item.role}
                        status={item.status}
                        user={auth.user}
                    />
                }}
                className={"msg-container"}
            >
                <div style={{float: "left", clear: "both"}} ref={divRef}>
                    <br/>
                </div>
            </List>
            <AiChatInput/>
        </div>
    </AiSideBarItem>
}


export default AiChat;
