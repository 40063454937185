import {Link} from "react-router-dom";
import {mailtoSupervision} from "@global/Mail";
import {MailOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import React from "react";

const ActionNotifyRepresentative = ({client, representativeInfo, metricFlagData}) => {
    return <Tooltip title={"Notify representative"}>
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailtoSupervision(client, representativeInfo, metricFlagData);
                e.preventDefault();
            }}
        >
            <MailOutlined/>
        </Link>
    </Tooltip>
}

export default ActionNotifyRepresentative
