/* eslint-disable react/prop-types */
import React from 'react';
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {Flex, List, Progress} from "antd";
import {blue, green, red} from "@ant-design/colors";
import ChartStyles from "@global/ChartStyles";
import Localizator from "@global/Localizator";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import ScoreMarkersRadar from "@holistico/chart/ScoreMarkersRadar";


const getRatingColor = (value) => {
    return value > 60 ? green[3] : (value <= 40 ? red[3] : blue[3])
}

export const ScoreStatsCompact = ({title, value, separate=false}) => {
    return <Flex
        align={"center"}
        style={{marginBottom: separate ? 12 : 0}}
    >
            <Progress
                percent={value ? value + 10 : 0}
                steps={5}
                showInfo={false}
                strokeColor={getRatingColor(value)}
                size={[3, 12]}
            />
            <span style={{
                fontSize: 16,
                fontWeight: 500,
                marginLeft: 8,
                width: 32,
                minWidth: 32,
            }}>
                {is_null(value) ? "-" : value}
            </span>
            <span style={{
                fontSize: 14,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: separate ? 500 : 350
            }}>
                {title}
            </span>
        </Flex>
}

const AssetClassPie = ({asset}) => {
    const score_markers = [
        {"label": "sscore_plus", "value": asset?.sscore_plus},
        {"label": "sscore_plus_growth", "value": asset?.sscore_plus_growth},
        {"label": "sscore_plus_momentum", "value": asset?.sscore_plus_momentum},
        {"label": "sscore_plus_safety", "value": asset?.sscore_plus_safety},
        {"label": "sscore_plus_profitability", "value": asset?.sscore_plus_profitability},
        {"label": "sscore_plus_value", "value": asset?.sscore_plus_value},
        {"label": "sscore_plus_yield", "value": asset?.sscore_plus_yield},
    ]

    let translated = score_markers.map((marker) => {
        let value = ValueFormatter.round(marker['value'], 0)

        let status = ChartStyles.get_status(value)
        return {
            label: Localizator.t(`factor.${marker['label']}`),
            value: value,
            status: status
        }
    })

    return <div className={"fade-in-500"}>
        {!is_null(asset?.sscore_plus_growth)
            ?
            <Flex justify={"space-between"} align={"center"} style={{minWidth: "100%"}}>
                <List
                    dataSource={translated}
                    renderItem={(item, idx) => (
                        <ScoreStatsCompact
                            title={item.label}
                            value={item.value}
                            separate={idx === 0}
                        />
                    )}
                    bordered={false}
                />
                <div style={{maxWidth: 250}}>
                    <ScoreMarkersRadar
                        score_markers={score_markers}
                        showLegend={false}
                        height={250}
                        alias={"Fund score"}
                    />
                </div>
            </Flex>
            :
            <NoDataPlaceholder description={"No equity ratings information"}/>
        }
    </div>
}


export default AssetClassPie;
