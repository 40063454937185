import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Badge, Button, Col, Dropdown, Input, Menu, message, Row, Table, Typography} from "antd";
import {cancel, deleteSchedulerObject, getSchedulerObjects, runSchedulerObject} from "@API/scheduler";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, PlayCircleOutlined, SearchOutlined} from '@ant-design/icons';
import TaskQueueEditor from "./TaskQueueEditor";
import {Link} from "react-router-dom";
import {debounce} from "@global/Utils";
import {MMMDDHHmm} from "@global/DateFormatter";


const TasksOrQueuesTable = ({schedulerEntity, mode = "full", task_ids = null}) => {
    const [loading, setLoading] = useState(false);
    const [schedulerObjects, setSchedulerObjects] = useState(null);
    const [schedulerObjectsEditorValue, setSschedulerObjectsEditorValue] = useState(false); // string ObjectId | true | false
    const abortControllerRef = useRef(new AbortController());

    const getSchedulerObjectsProxy = useCallback((searchString = "") => {
        setLoading(true);
        getSchedulerObjects(schedulerEntity, task_ids, searchString, (data, error) => {
            setLoading(false);
            if (error) {
                message.error(`Error while retrieving scheduler ${schedulerEntity}s`)
                return
            }
            setSchedulerObjects(data);
        }, abortControllerRef.current.signal)
    }, [setLoading, setSchedulerObjects, schedulerEntity, task_ids])

    const runSchedulerObjectProxy = useCallback((id) => {
        setLoading(true);
        runSchedulerObject(schedulerEntity, id, (_, error) => {
            if (error) {
                setLoading(false);
                message.error(`Error while attempt to run ${schedulerEntity}`)
                return
            }
            setTimeout(() => {
                setLoading(false);
                getSchedulerObjectsProxy();
                message.success(`Scheduler ${schedulerEntity} was ran`);
            }, [700])
        })
    }, [getSchedulerObjectsProxy, setLoading, schedulerEntity])

    const deleteSchedulerObjectProxy = useCallback((id, isRunning) => {
        if (isRunning) {
            message.warning(`You can not delete running ${schedulerEntity}`);
            return
        }
        setLoading(true);
        deleteSchedulerObject(schedulerEntity, id, (resp_message, error) => {
            setLoading(false);
            if (error) {
                message.error(resp_message);
                return
            }
            message.success(`Scheduler ${schedulerEntity} was deleted successfully`);
            getSchedulerObjectsProxy();
        })
    }, [getSchedulerObjectsProxy, setLoading, schedulerEntity])

    const generateDropdownList = useCallback((id, isRunning) => {
        return <Menu items={[
            {
                key: '1',
                label: (
                    <div onClick={(e) => {
                        // clearEvent(e);
                        console.log(id, isRunning)
                        if (isRunning) {
                            message.warning(`Can not re-run: already running ${schedulerEntity}`);
                        } else {
                            runSchedulerObjectProxy(id);
                        }
                    }}><PlayCircleOutlined/> Run {schedulerEntity}</div>
                ),
            },
            {
                key: '2',
                label: (
                    <div onClick={(e) => {
                        // clearEvent(e);
                        setSschedulerObjectsEditorValue(id);
                    }}><EditOutlined/> Update {schedulerEntity}</div>
                ),
            },
            {
                key: '3',
                label: (
                    <div onClick={(e) => {
                        // clearEvent(e);
                        deleteSchedulerObjectProxy(id, isRunning);
                    }}><DeleteOutlined/> Delete {schedulerEntity}</div>
                ),
                danger: true,
            },
        ]}/>
    }, [runSchedulerObjectProxy, setSschedulerObjectsEditorValue, deleteSchedulerObjectProxy, schedulerEntity])

    const columns = useMemo(() => {
        let _columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: (a, b) => a.title.localeCompare(b.title),
                render: (_, {_id, title}) => (
                    <Link to={_id}>{title}</Link>
                )
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Status',
                key: 'status',
                width: 200,
                render: (_, {state, job}) => {
                    if (state.is_running) {
                        return <Typography.Text type={'success'}>Running</Typography.Text>
                    } else if (job) {
                        return <Typography.Text mark>Planned {MMMDDHHmm(job.next_run_time)}</Typography.Text>
                    }
                    return null
                },
                sorter: (a, b) => Boolean(a?.state?.is_running) - Boolean(b?.state?.is_running),
            },
            {
                title: 'Last run',
                key: 'last_run',
                width: 150,
                render: (_, {state}) => {
                    let dateValue = MMMDDHHmm(state?.start, null)
                    if (state.result) {
                        return <Badge status={state.result} text={dateValue}/>
                    } else {
                        return dateValue
                    }
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => ('' + a?.state?.start).localeCompare(b?.state?.start)
            },
        ]
        if (mode === "full") {
            _columns.push({
                title: '',
                key: 'action',
                render: (_, {_id, state}) => {
                    const menu = generateDropdownList(_id, state ? state.is_running : false);
                    return <Dropdown trigger={['click']} overlay={menu}>
                        <EllipsisOutlined onClick={e => {
                            // e.preventDefault()
                            e.stopPropagation()
                        }}/>
                    </Dropdown>
                },
            })
        } else {
            _columns.splice(0, 0, {
                title: 'Order',
                key: 'order',
                dataIndex: "order",
                sorter: (a, b) => a.order - b.order
            })
        }
        return _columns
    }, [generateDropdownList, schedulerEntity])

    const directSearch = value => {
        setLoading(true);
        getSchedulerObjectsProxy(value);
    }

    const searchSchedulerObject = debounce(directSearch, 300);

    useEffect(() => {
        console.log(schedulerEntity)
        getSchedulerObjectsProxy();
    }, [schedulerEntity])

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
            cancel && cancel();
        }
    }, [])

    return (
        <>
            {mode === "full" && <Row style={{marginTop: "10px"}} justify="space-between">
                <Col span={20}>
                    <Input onChange={e => searchSchedulerObject(e.target.value)} size="middle"
                           placeholder="Type in the title to start searching"
                           suffix={<SearchOutlined className='certain-category-icon'/>}
                           onPressEnter={e => directSearch(e.target.value)}
                    />
                </Col>
                <Col>
                    <Button type="primary"
                            onClick={() => setSschedulerObjectsEditorValue(true)}
                    >
                        Add {schedulerEntity}
                    </Button>
                </Col>
            </Row>}
            <Typography.Paragraph type={'secondary'} style={{paddingTop: 24}}>Table timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</Typography.Paragraph>
            <Table columns={columns} dataSource={schedulerObjects} loading={loading} pagination={{pageSize: 30}}
                   style={{marginTop: "10px"}}
            />
            {mode === "full" &&
                <TaskQueueEditor schedulerEntity={schedulerEntity} addModalOption={schedulerObjectsEditorValue}
                                 setAddModal={setSschedulerObjectsEditorValue}
                                 afterFinish={() => {
                                     getSchedulerObjectsProxy();
                                 }}
                />}
        </>
    )
}

export default TasksOrQueuesTable