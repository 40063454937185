/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Select} from "antd";

import './FactorSelector.less';


const {Option, OptGroup} = Select;

const FactorSearch = ({selectedColumnNames = [], availableFactors, updateSelection}) => {
    const [selectedProxy, setSelectedProxy] = useState([]);
    const [options, setOptions] = useState([]);

    // const resetSelection = () => {
    //     done([])
    // }

    useEffect(() => {
        if (!availableFactors) {
            return
        }
        let newOptions = []
        for (let group of Object.entries(availableFactors)) {
            let [key, factors] = group;

            let opts = factors.map(factor => {
                return <Option value={factor['column_name']} key={factor['column_name']}>{factor['full_name']}</Option>
            })
            newOptions.push(<OptGroup label={key} key={key}>{opts}</OptGroup>);
        }
        setOptions(newOptions);
        setSelectedProxy(selectedColumnNames);
    }, [selectedColumnNames])

    const onChange = (data) => {
        updateSelection(data);
    };

    return (
        <Select
            style={{width: '100%'}}
            value={selectedProxy}
            showSearch
            mode="multiple"
            // mode="tags"
            defaultActiveFirstOption={false}
            showArrow={false}
            // filterOption={true}
            onChange={onChange}
            notFoundContent={null}
            filterOption={(inputValue, option) => {
                if (option.hasOwnProperty("options")) {
                    return false
                }
                return option.children.toLowerCase().includes(inputValue.toLowerCase())
            }}
        >
            {options}
        </Select>
    )
}

export default FactorSearch;
