import React from "react";
import {
    Divider,
    Flex,
    Form,
    Input,
    theme,
    Tooltip
} from "antd";
import {useWatch} from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import {ClearOutlined, CloseCircleFilled} from "@ant-design/icons";
import AssetSearch from "@components/portfolio/position/new/AssetSearch";
const {useToken} = theme;


const AnyAssetInput = ({field}) => {
    const form = useFormInstance();
    const asset = useWatch([field, "asset"], form);
    const {token} = useToken();

    const onSelect = (_asset) => {
        console.log("AnyAssetInput, set asset", _asset);
        form.setFieldValue([field, "asset"], _asset);
    }

    return <>
        {asset
            ?
            <Flex
                gap={"middle"}
                align={"center"}
                style={{
                    fontSize: token.fontSizeLG,
                    alignSelf: "flex-start",
                    background: token.colorPrimary,
                    color: "white",
                    padding: `${token.paddingXXS}px ${token.paddingXS}px ${token.paddingXXS}px ${token.padding}px`,
                    borderRadius: 2 * token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                }}
            >
                {asset.ticker ?? "-"}
                <Divider
                    type={"vertical"}
                    style={{margin: 0, borderColor: "rgba(255, 255, 255, 0.5)"}}
                />
                {asset.name}
                <Divider
                    type={"vertical"}
                    style={{margin: 0, borderColor: "rgba(255, 255, 255, 0.5)"}}
                />
                {asset.security_type}
                <Tooltip title={"Remove benchmark asset"}>
                    <CloseCircleFilled
                        onClick={() => (onSelect(null))}
                    />
                </Tooltip>
            </Flex>
            :
            <div style={{width: "100%", maxWidth: 500}}>
                <AssetSearch
                    onSelect={onSelect}
                    allowCreate={false}
                    tableView={false}
                    byTickerOnly={false}
                    placeholder={"Search asset/portfolio..."}
                />
            </div>
        }
        <Form.Item
            name={[field, "asset"]}
            dependencies={[field, "type"]}
            rules={[
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (value || getFieldValue([field, "type"]) !== 'any') {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error(
                                "Please add an asset or select another "
                                + (field ==="portfolio_config" ? "portfolio" : "benchmark")
                                + " option!"
                            )
                        );
                    },
                }),
            ]}
            // hidden={true}
            className={"hidden-form-item"}
        >
            <Input/>
        </Form.Item>
    </>
}


export default AnyAssetInput
