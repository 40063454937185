import {Col, Divider, List, message, Row, Spin, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useAuth} from "@hooks/AuthHook";
import AdvisorIndex from "@holistico/welcome/AdvisorIndex";


const AdvisorIndexContents = ({}) => {
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    useEffect(() => {
            setLoading(true);
            setLoading(false);
        },
        []
    )

    return <Spin spinning={false}>
        <AdvisorIndex/>
    </Spin>
}

export default AdvisorIndexContents
