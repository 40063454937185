import React from "react";
import {Anchor} from "antd";
import {items} from './items.js'
import "../Supervision.less";
import SPASTutorial from "@components/tutorial/SPASTutorial";

const {Link} = Anchor;

const Tutorial = () => {
    return <div>
        <SPASTutorial items={items} title={'Compliance Master Tutorial'} />
    </div>
}

export default Tutorial;
