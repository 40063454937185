import {createContext} from 'react';

/**
 * reloadData: (boolean) => {some reload actions}
 * @type {React.Context<{sectors: *[], sizes: *[], groupings: *[], vbg_styles: *[], algos: *[], returns: *[]}>}
 */
export const BacktestContext = createContext({
        "sizes": [],
        "sectors": [],
        "vbg_styles": [],
        "groupings": [],
        "returns": [],
        "algos": [],
        "factors": [],
        "weight_options": [],
    }
);
