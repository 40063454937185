import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, message, Popconfirm, Popover, Row, Spin} from 'antd';
import {deleteExportConfig, downloadWeekly, getExportConfigs} from "@API/data_export";
import {DeleteOutlined, DownloadOutlined, FormatPainterOutlined, SettingOutlined, ExpandOutlined} from "@ant-design/icons";
import {downloadFile} from "@global/FileDownloader";
import EditExportConfig from "@components/data_export/EditExportConfig";


const ExportConfigsList = () => {
    const [configs, setConfigs] = useState([])
    const [fromEmpty, setFromEmpty] = useState(true)
    const [editedConfig, setEditedConfig] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadConfigs();
    }, []);

    const loadConfigs = () => {
        setEditedConfig(null)
        setLoading(true);
        getExportConfigs((data, error) => {
            console.debug(data)
            setLoading(false);
            if (error) {
                message.error("Error while loading configurations");
                return
            }
            if (data) {
                setConfigs(data);
            }
        });
    }

    const editConfig = (cfg) => {
        setEditedConfig(cfg)
    }

    const createConfig = (empty) => {
        let emptyConfig = {name: 'New config', type: 'user', config: {}};
        setFromEmpty(empty);
        setEditedConfig(emptyConfig);
    }

    const stopEdit = (reload) => {
        setEditedConfig(null)
        if (reload) {
            loadConfigs()
        }
    }

    const deleteConfig = useCallback((id) => {
        deleteExportConfig(id, (response, error) => {
            if (error) {
                message.error("Error while delete config");
                return
            }
            loadConfigs();
        })
    }, []);

    const download = useCallback((selectedConfig) => {
        setLoading(true);
        downloadWeekly(selectedConfig, (response, error) => {
            if (error) {
                message.error("Error while creating and downloading Weekly");
                setLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Weekly stocks data ${new Date().toLocaleString()}.xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                setLoading(false);
            })
        })
    }, []);

    const clearEvent = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }


    return (
        <Spin spinning={loading} tip={"Work in progress..."}>
            <div className={"export-configs-list"}>
                <Row gutter={16}>
                    {configs.map(cfg => {
                        return <Col span={6} key={cfg.id}>
                            <Card
                                style={{marginBottom: 16}}
                                hoverable={true}
                                onClick={() => download(cfg.id)}
                                actions={[
                                    <DownloadOutlined key={0} onClick={(e) => {
                                        clearEvent(e)
                                        download(cfg.id)
                                    }}/>,
                                    <SettingOutlined key={1} onClick={(e) => {
                                        clearEvent(e)
                                        editConfig(cfg)
                                    }}/>,
                                    <Popconfirm
                                        key={2}
                                        title="Sure to remove the config?"
                                        onConfirm={(e) => {
                                            clearEvent(e)
                                            deleteConfig(cfg.id)
                                        }}
                                        onCancel={e => {
                                            clearEvent(e)
                                        }}
                                    >
                                        <DeleteOutlined onClick={(e) => {
                                            clearEvent(e)
                                        }}/>
                                    </Popconfirm>

                                ]}
                                >
                                <Card.Meta
                                    title={<b>{cfg.name}</b>}
                                />
                            </Card>
                        </Col>
                    })}
                </Row>
                <Row style={{marginBottom: 16}}>
                    
                    <Popover content={"Create a configuration with default columns set"} trigger="hover">
                        <Button onClick={() => createConfig(false)}><FormatPainterOutlined/>Create
                            configuration</Button>
                    </Popover>
                    <Popover content={"Create a configuration with no default columns set"} trigger="hover">
                        <Button onClick={() => createConfig(true)} style={{marginLeft: "10px"}}>
                            <ExpandOutlined />Create empty configuration
                        </Button>
                    </Popover>
                    <EditExportConfig config={editedConfig} close={stopEdit} fromEmpty={fromEmpty}/>
                </Row>
            </div>
        </Spin>
    )
}

export default ExportConfigsList
