import {Col, ConfigProvider, Menu, Row, theme, Typography} from "antd";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getHiddenHref} from "@global/Utils";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ValueFormatter from "@global/ValueFormatter";

const {useToken} = theme;
const getMenuColumns = (proposal) => {
    const proposalId = proposal?._id

    let qItem = {
        "label": "Questionnaire",
        "key": "client/general-info"
    }

    let bItem = {
        "label": "Behavioral quiz",
        "key": "client/behavioral-quiz"
    }

    let wItem = {
        "label": "Risk tolerance",
        "key": "client/risk-tolerance"
    }

    if (proposal?.questionnaire?.risk_profile?.ability) {
        qItem['label'] = "Risk ability: " + proposal?.questionnaire?.risk_profile?.ability
    }

    if (proposal?.questionnaire?.risk_profile?.behavioral) {
        bItem['label'] = "Behavioral risk: " + proposal?.questionnaire?.risk_profile?.behavioral
    }

    if (proposal?.questionnaire?.risk_profile?.willingness) {
        wItem['label'] = "Risk willingness: " + proposal?.questionnaire?.risk_profile?.willingness
    }

    let cPort = {
        label: "No client portfolio",
        key: "portfolios/client-portfolio"
    }

    let individualViewLink = null

    if (proposal?.p_bucket_cur?.calculated_values?.market_value) {
        cPort['label'] = "Market value: " + ValueFormatter.compactCurrency(proposal?.p_bucket_cur?.calculated_values?.market_value)
        individualViewLink = {
            label: "Individual view",
            key: `/view/${proposalId}`
        }
    }

    let pPort = {
        label: "No proposal made",
        key: "portfolios/proposed-portfolios"
    }

    if (proposal?.p_bucket_prop?.calculated_values?.market_value) {
        pPort['label'] = "Proposed portfolio"
    }

    let portfolioElements = [cPort, pPort]
    if (individualViewLink) {
        portfolioElements.push(individualViewLink)
    }

    let menuColumns = [
        {
            title: "Client",
            key: "Client",
            items: [qItem, bItem, wItem]
        },
        {
            title: "Portfolio",
            key: "portfolio",
            items: portfolioElements
        },
        {
            title: "Fundamentals",
            key: "fundamentals",
            items: [{
                label: "Asset allocation",
                key: "fundamentals/asset-allocation"
            }, {
                label: "Equities",
                key: "fundamentals/equities"
            }, {
                label: "Fixed-income",
                key: "fundamentals/fixed-income"
            }]
        },
        {
            title: "Other",
            key: "other",
            items: [{
                label: "Retirement map",
                key: "other/retirement-map"
            }, {
                label: "Stress tests",
                key: "other/stress-tests"
            }, {
                label: "AI Conversation",
                key: "ai/assistant"
            }]
        },
    ]


    for (let menuColumn of menuColumns) {
        for (let item of menuColumn["items"]) {
            if (item.key.startsWith("/")) {
                item.label = getHiddenHref(item.label, item.key, ``, true)
            } else {
                item.label = getHiddenHref(item.label, item.key, ``, false)
            }
        }
    }

    return menuColumns
}


const getSelectedKeyFromPath = (path) => {
    let menuColumns = getMenuColumns()
    for (let menuColumn of menuColumns) {
        for (let item of menuColumn["items"]) {
            if (path.includes(item['key'])) {
                return item['key']
            }
        }
    }

    return "fundamentals/asset-allocation"
}

const ProposalMegaMenu = ({proposalId}) => {
    const {token} = useToken();
    const {proposal} = useHolisticoProposalContext()
    const location = useLocation()
    const selectedKey = getSelectedKeyFromPath(location.pathname)
    const navigate = useNavigate()

    const handleClick = (e) => {
        if (!e.key.startsWith("/")) {
            console.log("navigate to", e.key)
            navigate({pathname: `${e.key}`, search: location.search})
        }

    }

    return <ConfigProvider
        theme={{
            "components": {
                "Menu": {
                    itemSelectedBg: "#e0ad60",
                    itemSelectedColor: "#ffffff"
                }
            }
        }}>
        <Row style={{
            background: token.colorInfoBg,
            borderRadius: token.borderRadius
        }}>
            {getMenuColumns(proposal).map((menuColumn) => {
                return <Col span={6} key={menuColumn["key"]}>
                    <Typography.Title level={5}
                                      style={{
                                          paddingLeft: token.paddingMD,
                                          marginTop: token.marginSM,
                                          marginBottom: token.marginXXS,
                                          textTransform: "uppercase",
                                          letterSpacing: 2
                                      }}>
                        {menuColumn["title"]}
                    </Typography.Title>
                    <Menu
                        size={'small'}
                        style={{background: "none", border: "none", borderTop: "1px solid #e8e8e8"}}
                        items={menuColumn["items"]}
                        onClick={handleClick}
                        selectedKeys={[selectedKey]}
                    />
                </Col>
            })}
        </Row>
    </ConfigProvider>
}

export default ProposalMegaMenu
