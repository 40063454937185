import {Form, InputNumber, Spin} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import React from "react";
import PortfolioFilesForm from "@holistico/proposal/new/form/PortfolioFilesForm";
import "./InvestmentsInfoForm.less"


const InvestmentsInfoForm = ({label="Portfolio files", style=null}) => {
    return <Form.Item label={label} className={'portfolio-option-card'} style={style}>
        <PortfolioFilesForm/>
    </Form.Item>
}

export default InvestmentsInfoForm;
