/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {DatabaseOutlined} from '@ant-design/icons';
import {Button, message, Space, Spin} from 'antd';

import PortfolioCreator from '@root/components/portfolio/new/PortfolioCreator';
import {
    cancel,
    clone,
    createEmptyPortfolio,
    createFromFile,
    deleteModelOrTemp,
    fromOrion,
    getModels
} from "@API/portfolio";
import ExpandablePortfolioRow from '@root/components/portfolio/table/ExpandablePortfolioRow';
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import MainSearch from "@components/clients/MainSearch";
import ModelGraphButton from "@components/models/graph/ModelGraphButton";

const type = 'model';

const ModelPortfolios = () => {
    const [showAddPortfolio, setShowAddPortfolio] = useState(false);
    const [portfolios, setPortfolios] = useState([]);
    const [loadingPortfolios, setLoadingPortfolios] = useState(true);
    const [searchString, setSearchString] = useState(null)

    const hideAddPortfolio = () => {
        setShowAddPortfolio(false);
    }
    useEffect(() => {
        fetchModelData(searchString);

        return () => {
            cancel && cancel()
        }
    }, [searchString]);

    const portfolioHandler = (portfolios, error) => {
        if (!error) {
            setPortfolios(portfolios);
        } else {
            message.error("Error while loading models")
        }
        setTimeout(() => setLoadingPortfolios(false), 1000);
    }

    //TODO: doReeload
    const fetchModelData = (search) => {
        if (cancel) {
            cancel();
        }
        setLoadingPortfolios(true)
        setShowAddPortfolio(false)
        getModels(true, search, portfolioHandler);
    }

    const addFromFile = (formData, callback) => {
        createFromFile(formData, (result, error) => {
            if (!error) {
                fetchModelData()
                callback()
            } else {
                setLoadingPortfolios(false)
                callback(error)
                message.error('Error loading portfolio from file!');
            }
        })
    }

    const addEmpty = (postData) => {
        createEmptyPortfolio(postData, (result, error) => {
            if (!error) {
                fetchModelData()
            } else {
                setLoadingPortfolios(false)
                message.error('Failed to add the portfolio!');
            }
        })
    }


    const addFromOrion = (orion_id) => {
        hideAddPortfolio()
        setLoadingPortfolios(true)
        fromOrion(orion_id, 'model', (result, error) => {
            if (!error) {
                fetchModelData()
            } else {
                setLoadingPortfolios(false)
                message.error('Loading from Orion failed!');
            }
        })
    }

    //we do nothing with weight here
    const addFromOther = (id, expand_portfolio_positions = false, weight, callback = () => {
    }) => {
        hideAddPortfolio()
        setLoadingPortfolios(true)
        clone(id, 'model', {}, expand_portfolio_positions, (result, error) => {
            if (!error) {
                fetchModelData()
            } else {
                setLoadingPortfolios(false)
                message.error('' + error);
            }
            if (callback) {
                callback()
            }
        })
    }

    const deleteExisting = (id) => {
        deleteModelOrTemp(id, (result, error) => {
            if (!error) {
                message.success("Portfolio was deleted successfully");
                fetchModelData()
            } else {
                message.error('Error removing the model!');
            }
        })
    }


    return (
        <Spin style={{height: 800}} spinning={loadingPortfolios} tip="Loading models..." size={"large"}>
            <PortfolioListContext.Provider
                value={{
                    addFromOther: addFromOther,
                    addFromOrion: addFromOrion,
                    reloadContext: () => fetchModelData(),
                    addEmpty: addEmpty,
                    addFromFile: addFromFile,
                    removeOne: deleteExisting
                }}>
                <MainSearch fetchData={fetchModelData} placeholder={"Type in the model name to search"}/>
                <Space>
                    {showAddPortfolio ? <PortfolioCreator hideForm={hideAddPortfolio} type={type}/>
                        :
                        <Button
                            type="primary"
                            onClick={() => setShowAddPortfolio(true)}>Add model</Button>
                    }
                    <ModelGraphButton/>
                </Space>
                <div>
                    {loadingPortfolios ?
                        null
                        : portfolios.length !== 0 ?
                            <div className="portfolio-container">
                                {portfolios.map(portfolio => {
                                    return (<ExpandablePortfolioRow key={portfolio._id} portfolioInfo={portfolio}
                                                                    type={portfolio.type}/>)
                                })}
                            </div>
                            : <div className="noDataBox">
                                <DatabaseOutlined style={{fontSize: '2em'}}/>
                                <div>No portfolios added</div>
                            </div>
                    }
                </div>
            </PortfolioListContext.Provider>
        </Spin>
    );
}

export default ModelPortfolios;
