import React, {useState} from "react";
import {
    Button,
    Card, Spin,
    theme,
    Typography
} from 'antd';
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useRequest, useUpdateEffect} from "ahooks";
import {
    getMacroHistorySeparate,
    getMarketMonitorComment
} from "@API/ahooks/dashboard";
import dayjs from "dayjs";


const {useToken} = theme;



const MacroOverview = ({}) => {
    const [text, setText] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const {token} = useToken();

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getMarketMonitorComment());

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('Error while loading macroeconomic data')
        }
    }, [error])

    useUpdateEffect(() => {
        console.debug("MacroOverview data loaded", response.data);
        setText(response.data.comment ?? "");
    }, [response])

    return <Card hoverable style={{
        height: "100%",
        cursor: "default",
    }}>
        <Typography.Title level={4} style={{margin: 0}}>
             Market monitor
        </Typography.Title>
        <Typography.Text type={"secondary"} style={{
            display: "block",
            marginBottom: token.padding
        }}>
            By AI
        </Typography.Text>
        <Spin spinning={loading}>
            {text.split("\n")
                .filter((_, i) => i < 2)
                .map((par, index) => {
                    return <Typography.Paragraph key={index}>
                        {par}
                    </Typography.Paragraph>
                })}
        </Spin>
        <Button
            type={"link"}
            onClick={() => setOpenDrawer(true)}
            style={{padding: 0}}
        >
            Read more...
        </Button>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={600}
        >
            <Card>
                <Typography.Title level={4} style={{margin: 0}}>
                    Market monitor
                </Typography.Title>
                <Typography.Text type={"secondary"} style={{
                    display: "block",
                    marginBottom: token.padding
                }}>
                    By AI
                </Typography.Text>
                {text.split("\n").map((par, index) => {
                    return <Typography.Paragraph key={index}>
                        {par}
                    </Typography.Paragraph>
                })}
            </Card>
        </MobileDrawer>
    </Card>

}

export default MacroOverview;
