/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Heatmap} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const SizeStyleHeat = ({allocation}) => {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        if (!allocation || Object.keys(allocation).length === 0) {
            setConfig(null )
            return
        }

        const defaults = ChartStyles.size_style_defaults();
        let allocProxy = {...defaults, ...allocation}

        let _data = Object.keys(allocProxy).map(key => {
            let size = key.split(" ")[0]
            let style = key.split(" ")[1]
            return {
                size: size,
                style: style,
                label: key,
                value: allocProxy[key]
            }
        })

        const _config = {
            data: _data,
            xField: 'style',
            yField: 'size',
            render: 'svg',
            colorField: 'value',
            height: 250,
            color: [
                "#eaf5f7",
                "#d6eaf0",
                "#c1e0e8",
                "#add5e0",
                "#98cbd9",
                "#83c0d1",
                "#6fb6c9",
                "#5aabc1",
                "#46a1ba",
                "#3196B2"
            ],
            meta: {
                'style': {
                    values: ['Value', 'Blend', 'Growth']
                },
                'size': {
                    values: ['Small', 'Medium', 'Large']
                },
            },
            xAxis: {
                position: 'top',
                label: {
                    style: {
                        fontSize: 14,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 400,
                        fill: "#4B4B4B",
                    }
                },
            },
            yAxis: {
                label: {
                    style: {
                        fontSize: 14,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 400,
                        fill: "#4B4B4B",
                    }
                },
            },
            // padding: [55, 12, 55, 55],
            label: {
                style: {
                    fill: '#000',
                    fontSize: 14,
                    fontFamily: "Red Hat Display W",
                    fontWeight: 400,
                },
                content: (valueObeject, b, index) => {
                    return `${ValueFormatter.oneDigitPercent(valueObeject.value)}`
                }
            },
            tooltip: {
                showMarkers: false,
                formatter: (datum) => {
                    return {name: datum.size + " " + datum.style, value: ValueFormatter.oneDigitPercent(datum.value)};
                },
            }
        }

        setConfig(_config)

    }, [allocation])


    return <div className={"fade-in-500"}>
        {config
            ?
            <div style={{maxWidth: 350}}>
                <Heatmap {...config}/>
            </div>
            :
            <NoDataPlaceholder description={"No size-style information"}/>
        }
    </div>
}

export default SizeStyleHeat;
