/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate, useSearchParams
} from "react-router-dom";
import {Menu} from "antd";
import {getHiddenHref} from "@global/Utils";
import {ScreenerContextProvider} from "@hooks/ScreenerContext";
import ParamsForm from "@components/screener/ParamsForm";
import AssetsList from "@components/screener/AssetsList";


const getMenuItems = (pathStart) => {
    const items = [
        {
            key: `company`,
            label: "Companies"
        }, {
            key: `fund`,
            label: "Funds"
        }, {
            key: `portfolio`,
            label: "Models marketplace"
        }
    ]

    return items.map(item => {
        return {key: item.key, label: getHiddenHref(item.label, item.key)}
    })
}


const getEntityGroupFromPath = (path) => {
    if (path.includes("screener/company")) {
        return  'company'
    } else if (path.includes("/screener/fund")) {
        return 'fund'
    } else if (path.includes("/screener/portfolio")) {
        return 'portfolio'
    } else {
        return null
    }
}


const CompanyScreener= ({}) => {
    return <ScreenerContextProvider entityGroup={'company'}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const FundScreener= ({}) => {
    return <ScreenerContextProvider entityGroup={'fund'}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const PortfolioScreener= ({}) => {
    return <ScreenerContextProvider entityGroup={'portfolio'}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const AssetScreener = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [path, setPath] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [paramsStore, setParamsStore] = useState( {company: null, fund: null});
    const [selectedKey, setSelectedKey] = useState(['company'])
    const holistico = location.pathname.startsWith('/ria')
    const pathStart = holistico ? '/ria' : ""

    useEffect(() => {
        let _entityGroup = getEntityGroupFromPath(location.pathname)
        if (location.pathname === path && _entityGroup) {
            // path didn't change, store params for this entity group
            setParamsStore(prev => {
                prev[_entityGroup] = searchParams
                return prev
            })

        } else {
            // path changed, restore params for this entity group
            if(paramsStore[_entityGroup]) {
                setSearchParams(paramsStore[_entityGroup])
            }
        }
        setPath(location.pathname)
        setSelectedKey(`${_entityGroup}`)
    }, [location.pathname, searchParams])

    return <>
        <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
                navigate(e.key)
            }}
            items={getMenuItems(pathStart)}
            style={{
                background: "none",
                border: "none",
                marginBottom: 16,
                marginLeft: -16,
                marginTop: -16,
            }}
        />
        <Routes>
            <Route path="" element={<Navigate to="company" replace/>}/>
            <Route path="company" element={<CompanyScreener/>}/>
            <Route path="fund" element={<FundScreener/>}/>
            <Route path="portfolio" element={<PortfolioScreener/>}/>
        </Routes>
    </>
}

export {AssetScreener, PortfolioScreener};
