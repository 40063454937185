import {Select, Form, Space, Typography, Rate, Row, Col} from "antd";
import {useRequest} from "ahooks";
import {getBenchmarkPortfoliosData} from "@API/portfolio";
import React, {useEffect, useState} from "react";
import {formChartData} from "@components/admin_panel/scheduler/TaskTimeLine";
import {HeartOutlined, ThunderboltOutlined} from "@ant-design/icons";
import StatusProgress from "@holistico/chart/StatusProgress";

const chooseModel = (risk_score, models) => {
    if (!models.length) {
        return null
    }
    let selectedPortfolio = models[0]

    let best_diff = 100
    for (let iteration_portfolio of models) {
        let iteration_risk = iteration_portfolio?.calculated_values?.risk?.risk_score
        let diff = Math.abs(risk_score - iteration_risk)
        if (diff < best_diff) {
            best_diff = diff
            selectedPortfolio = iteration_portfolio
        }
    }
    return selectedPortfolio
}

const genPortfolioLabel = (portfolio) => {
    let risk = portfolio?.calculated_values?.risk?.risk_score
    if (!risk) {
        risk = 0
    }
    let rate = parseInt()
    return <Row>
        <Col span={20}>
            {portfolio.name}
        </Col>
        <Col span={4}>
            <StatusProgress value={risk} status={null}/>
        </Col>
    </Row>
    return <Space direction={'horizontal'}>

    </Space>
}

const ModelSelectInput = ({risk_score = 50, onChange}) => {
    const {
        data,
        run,
        loading,
    } = useRequest(() => getBenchmarkPortfoliosData())

    const [options, setOptions] = useState([])
    const [models, setModels] = useState([])
    const [selectedModel, setSelectedModel] = useState(null)

    useEffect(() => {
        if (!data) {
            return
        }
        let responseData = data.data
        //debugger
        let selectedPortfolio = chooseModel(risk_score, responseData)
        console.log('setting selected portfolio ' + selectedPortfolio?.name)
        console.log('setting models to ' + responseData)
        setModels(responseData)
        setOptions(responseData.map(p => {
            return {"label": genPortfolioLabel(p), value: p._id}
        }))
        onChange(selectedPortfolio)
        setSelectedModel(selectedPortfolio._id)
    }, [data]);

    return (
        <Select bordered={false}
                value={selectedModel}
                size={"large"}
                showArrow={false}
                className={'portfolio-select'}
                loading={loading}
                onChange={(_id) => {
                    console.log(`searching for ${_id}`)
                    let selectedPortfolio = models.find(p => p._id === _id)
                    onChange(selectedPortfolio)
                    setSelectedModel(selectedPortfolio._id)
                }}
                options={options}/>
    )

}

export default ModelSelectInput
