/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Row, Select, Typography} from 'antd';
import FineTuneOption from "@components/questionnaire/steps/FineTuneOption";

const {TextArea} = Input;
const {Paragraph, Text, Title} = Typography;
const {Meta} = Card;
const {Option} = Select;

const OneRiskImproveStep = ({financialParams = {}, step, itemSelected}) => {
    const [riskChange, setRiskChange] = useState(6 - step)
    const [plusRisk, setPlusRisk] = useState(6 - step)
    const [minusRisk, setMinusRisk] = useState(6 - step)

    const onChange = value => {

    };

    const getText = (type) => {
        if (type == 0) {
            if (minusRisk == 1) {
                return 'Minimum risk'
            }
            if (step == 1) {
                return 'Less risk'
            } else {
                return 'Less risk, less return'
            }
        }

        if (type == 1) {
            if (step == 1) {
                return 'Your initial choice'
            } else {
                return 'Your current choice'
            }
        }

        if (type == 2) {
            if (plusRisk == 100) {
                return 'Maximum risk'
            }
            if (step == 1) {
                return 'More return'
            } else {
                return 'More return, more risk'
            }
        }
    }

    useEffect(() => {
        setRiskChange(7 - step)
        console.log(step)
        console.log(riskChange)

        let moreRisk = financialParams.risk + riskChange
        if (moreRisk > 100) {
            moreRisk = 100
        }

        let lessRisk = financialParams.risk - riskChange
        if (lessRisk < 1) {
            lessRisk = 1
        }
        setPlusRisk(moreRisk)
        setMinusRisk(lessRisk)
    }, [step]);

    const selected = (riskScore) => {
        itemSelected(riskScore)
    }

    return (
        <div>
            <Title type={'secondary'} level={4}>Risk fine tune</Title>
            <Paragraph>Let’s fine-tune your risk tolerance. You can choose either less risk or more return.</Paragraph>
            <Row gutter={8} justify="center" align="middle">
                <Col md={8} lg={8} sm={24} xs={24} justify="center" align="middle" className={"risk-improve-column"}>
                    <FineTuneOption selected={selected} additionalClass={'less-risk'}
                                    title={getText(0)}
                                    additionalClass={'less-risk'}
                                    investment_amount={financialParams?.investment_amount}
                                    riskScore={minusRisk}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24} className={"risk-improve-column"}>
                    <FineTuneOption selected={selected}
                                    title={getText(1)}
                                    additionalClass={'default current'}
                                    investment_amount={financialParams?.investment_amount}
                                    riskScore={financialParams.risk}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24} className={"risk-improve-column"}>
                    <FineTuneOption selected={selected}
                                    title={getText(2)}
                                    additionalClass={'more-profit'}
                                    investment_amount={financialParams?.investment_amount}
                                    riskScore={plusRisk}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default OneRiskImproveStep;
