import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Empty} from "antd";
import './PortfolioList.less';
import PortfolioListItem from "./PortfolioListItem";

/**
 *
 * @param portfolios the list of selectable portfolios
 * @param itemSelected function sending selected item to parent
 * @param multi if multiselectable
 * @returns {JSX.Element} simple selectable portfolio list
 * @constructor
 */
const PortfolioList = ({portfolios, itemSelected, multi = false, showEmpty = true, portfolioType = "client", showMarketValue = true}) => {

    const [localPool, setLocalPool] = useState([]);


    const pushPortfolioHandler = (e, portfolio) => {
        if (e.target.checked) {
            itemSelected(portfolio, true);
        } else {
            itemSelected(portfolio, false);
        }
    }

    const onClickHandler = (index, portfolio) => {
        if (!multi) {
            itemSelected(portfolio);
        } else {
            if (localPool.filter(ind => ind === index).length !== 0) {
                itemSelected(portfolio, false);
                setLocalPool(localPool.filter(ind => ind !== index));
            } else {
                itemSelected(portfolio, true);
                setLocalPool([...localPool, index]);
            }
        }
    }

    const checkedHandler = (currIndex) => {
        if (localPool.filter(index => index === currIndex).length !== 0) {
            return true
        } else {
            return false
        }
    }

    return (
        portfolios.length > 0 ? <div>
            {portfolios.map((portfolio, index) => {
                if (portfolio.calculated_values.risk !== null || showEmpty) {
                    return <PortfolioListItem key={index} index={index} portfolio={portfolio} multi={multi}
                                            select={onClickHandler} selected={checkedHandler(index)} showMarketValue={showMarketValue}/>
                }
            })}
        </div> : <Empty style={{margin: "15px 0"}} description={`No ${portfolioType} portfolios`}/>
    )
}
PortfolioList.propTypes = {
    portfolios: PropTypes.array,
    itemSelected: PropTypes.func,
    multi: PropTypes.bool
}
export default PortfolioList;
