import React, {useEffect, useState} from "react";
import {Row, Table, Avatar, message, Input, Button, Tag, Typography} from "antd";
import {useNavigate} from 'react-router-dom';
import {searchUsers, cancel} from "@API/users";
import {UserOutlined, SearchOutlined} from '@ant-design/icons';
import {debounce} from '@global/Utils';
import AddUser from "./AddUser";
import ChartStyles from "@global/ChartStyles";
import {redirect} from "react-router-dom";
import ModalInviteOrgUser from "@components/admin_panel/users/ModalInviteOrgUser";

const columnsFormat = [
    {
        title: "",
        dataIndex: ["avatars", "avatar_50"],
        render: img => <Avatar size="large" src={img}><UserOutlined/></Avatar>,
        width: "5%"
    },
    {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: "Name",
        dataIndex: ["meta", "full_name"],
        sorter: (a, b) => a.meta.full_name.localeCompare(b.meta.full_name),
    },
    {
        title: 'Roles',
        key: 'roles',
        dataIndex: 'roles',
        render: (_, {roles}) => (
            <>
                {roles.sort((a, b) => a.localeCompare(b)).map((role) => {
                    let color = ChartStyles.get_role_color(role)
                    return (
                        <Tag color={color} key={role}>
                            {role}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: "Last activity",
        dataIndex: "last_activity",
        sorter: (a, b) => new Date(a.last_activity) - new Date(b.last_activity),
        render: (last_activity) => last_activity && new Date(last_activity + "Z").toLocaleString("en-US"),
        defaultSortOrder: 'descend',
    },
]

const UsersList = ({holistico = false}) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const navigate = useNavigate()

    const axiosSearchHandler = (data, error) => {
        setLoading(false);
        if (error) {
            message.error("Can not load users");
            return
        }
        setUsers(data.map((user, inx) => {
            return {...user, key: inx}
        }));
    }

    const loadAllUsers = () => {
        setLoading(true);
        searchUsers(null, axiosSearchHandler);
    }

    useEffect(() => {
        loadAllUsers();
        return () => {
            cancel && cancel();
        }
    }, [])

    const directSearch = value => {
        setLoading(true);
        searchUsers(value, axiosSearchHandler);
    }

    const handleChange = debounce(directSearch, 400)

    return (
        <>
            <Typography.Title level={4} className={'spas'}>{"Users"}</Typography.Title>
            <Row style={{marginTop: "20px"}} align="space-between">
                <Input style={{width: "85%"}} onChange={e => handleChange(e.target.value)} size="large"
                       placeholder="Type in the username to start searching"
                       suffix={<SearchOutlined className='certain-category-icon'/>}
                       onPressEnter={e => directSearch(e.target.value)}
                />
                <Button style={{width: "10%"}} size="large" type="primary" onClick={() => setAddModal(true)}>Add
                    user</Button>
            </Row>
            <Row style={{marginTop: "20px"}}>
                <Table
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                if (holistico) {
                                    let url = `/ria/profile/${record.id}`
                                    console.log(url)
                                    navigate(url)
                                } else {
                                    console.log(record.id + "!!!!")
                                    navigate(`${record.id}`)
                                }

                            },
                            style: {cursor: "pointer"}
                        };
                    }}

                    loading={loading} style={{width: "100%"}} columns={columnsFormat} dataSource={users}
                    pagination={{pageSize: 15}}/>
            </Row>

            {holistico ? <ModalInviteOrgUser isOpen={addModal} closeModal={() => {
                    setAddModal(false)
                }}/> :
                <AddUser addModal={addModal} setAddModal={setAddModal} reloadUsers={loadAllUsers}/>}
        </>
    )
}

export default UsersList
