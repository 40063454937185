import {Typography} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import React from "react";


const ActionInfo = ({children, ...props}) => {
    let style = props?.style ?? {}
    style = {
        ...style,
        fontSize: "24px",
        color: "#93C2F0",
        display: "flex",
        alignItems: "flex-start",
        lineHeight: 1.2
    }

    return <div style={style}>
        <InfoCircleFilled style={{marginRight: "8px", lineHeight: 1.2, fontSize: "24px"}}/>
        <div>
            {children}
        </div>
    </div>
}

export default ActionInfo;
