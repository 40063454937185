/* eslint-disable react/prop-types */
import React from 'react';
import ChartStyles from "@global/ChartStyles";
import BarChart from "@components/screener/chart/BarChart";


const fiSectorSortOrder = {
    "Government": 1,
    "Municipal": 2,
    "Corporate": 3,
    "Securitized": 4,
    "Cash & Equivalents": 5,
    "Derivative": 6
}

const FISectorBar = ({allocation}) => {
    let skip = false
    if (allocation) {
        const nonZeroKeys = Object.keys(allocation).filter(key => allocation[key])
        skip = nonZeroKeys.length === 1 && nonZeroKeys.includes("Cash & Equivalents")
    }

    return <BarChart
        allocation={skip ? null : allocation}
        color={ChartStyles.colors[2]}
        sortOrder={fiSectorSortOrder}
        noDataText={"No fixed-income sector information"}
    />
}

export default FISectorBar;
