/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Bar} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const BarChart = ({allocation, sortOrder=null, color=null, noDataText=null}) => {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        if (!allocation || Object.keys(allocation).length === 0) {
            setConfig(null)
            return
        }

        let _data = Object.keys(allocation).map(key => {
            return {
                label: key,
                value: allocation[key],
            }
        })

        if (sortOrder) {
            _data.sort(function (a, b) {
                return (sortOrder[a.label] - sortOrder[b.label])
                    || a.label.localeCompare(b.label);
            });
        } else {
            _data.sort((a, b) => {
                return a?.label.localeCompare(b?.label)
            })
        }

        let _config = {
            data: _data,
            height: 250,
            color: color ?? ChartStyles.colors[0],
            maxBarWidth: 16,
            xField: 'value',
            yField: 'label',
            legend: false,
            appendPadding: [0, 32],
            label: {
                position: (item) => {
                    return item.value < 0 ? "middle" : "right";
                },
                offsetX: -5,
                style: {
                    fontSize: 14,
                    fontFamily: "Red Hat Display W",
                    fontWeight: 400,
                }
            },
            yAxis: {
                label: {
                    style: {
                        fontSize: 14,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 400,
                        fill: "#4B4B4B",
                    },
                },
            },
            xAxis: {
              grid: {
                line: {
                  style: {
                    stroke: "rgba(0, 0, 0, 0.2)",
                    lineWidth: 1,
                    lineDash: [4, 5],
                    strokeOpacity: 0.7,
                  }
                }
              }
            },
            marginRatio: 0,
            meta: {
                value: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.oneDigitPercent(v)
                    }
                },
                style: {
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: "weight",
                        value: ValueFormatter.oneDigitPercent(datum.value)
                    };
                },
            },
        }

        setConfig(_config)
    }, [allocation])

    return <div className={"fade-in-500"}>
        {config
            ?
            <Bar {...config}/>
            :
            <NoDataPlaceholder description={noDataText ?? "No data"}/>
        }
    </div>
}

export default BarChart;
