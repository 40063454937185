import {CheckOutlined} from "@ant-design/icons";
import {Tag, Tooltip} from "antd";
import {red} from "@ant-design/colors";
import React from "react";
import {colorDetector} from "@components/supervision/report_constructor/tree_utils/utils";

const RepresentativeProblemClients = ({flagTitle, badClientsCount, allClientsCount, dependentColor = false}) => {
    if (badClientsCount == 0) {
        return <CheckOutlined style={{color: 'green', opacity: 0.7}}/>
    }
    let clients = badClientsCount == 1 ? 'client' : 'clients'
    let have = badClientsCount == 1 ? 'has' : 'have'
    let cCount = badClientsCount

    let color = 'red'
    if (dependentColor) {
        color = colorDetector(badClientsCount, allClientsCount, 0.08)
    }
    
    let ttl = `${badClientsCount} of ${allClientsCount} ${clients} ${have} "${flagTitle}" warnings raised`
    return <Tooltip title={ttl} color={red[5]}>
        <Tag color={'red'}>{badClientsCount}</Tag>
    </Tooltip>
}

export default RepresentativeProblemClients
