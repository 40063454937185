import React from 'react';
import {Progress} from 'antd';

const BuySign = ({value}) => {
    return (
        <Progress percent={value} steps={10} size="small" strokeColor="#52c41a"
                  showInfo={true} status="success" format={(percent) => {
            return "Buy"
        }}/>
    );
}

export default BuySign;
