import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, message, Row, Spin, Typography, Alert} from 'antd';

import RetirementGraph from "@root/components/retirement/RetirementGraph";
import RetirementOptions from "@root/components/retirement/RetirementOptions";
import ClientPortfolioSelector from "@components/client/ClientPortfolioSelector";
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import {calculateRetirement} from "@API/retirement";
import {saveQuestionnaire} from "@API/clients";

import '@root/components/portfolio/Portfolio.less';

const lifetimeChecker = (clientDetails) => {
    let currentYear = new Date().getFullYear();
    return -(currentYear - clientDetails.birth_year - clientDetails.life_expectancy)
}

const ClientRetirement = () => {
    const {client, loadData} = useContext(SpasClientContext);
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [retirementData, setRetirementData] = useState(null);
    const [warningMessage, setWarningMessage] = useState(false);
    const [clientDetails, setClientDetails] = useState({
        monthly_savings: client.questionnaire?.monthly_savings ? client.questionnaire.monthly_savings : 0,
        retirement_year: client.questionnaire?.retirement_year ? client.questionnaire.retirement_year : 2030,
        monthly_withdrawals: client.questionnaire?.monthly_withdrawals ? client.questionnaire.monthly_withdrawals : 0,
        birth_year: client.questionnaire?.birth_year ? client.questionnaire.birth_year : 1960,
        life_expectancy: client.questionnaire?.life_expectancy ? client.questionnaire.life_expectancy : 90,
        savings_growth: client.questionnaire?.savings_growth ? client.questionnaire.savings_growth : 0,
        inflation_rate: client.questionnaire?.inflation_rate ? client.questionnaire?.inflation_rate : 2,
        management_fee: client.questionnaire?.management_fee ? client.questionnaire?.management_fee : 0.5,
        nominal_values: true,
        ignore_extreme: true,
        wanted_at_retirement: client.questionnaire?.wanted_at_retirement ? client.questionnaire.wanted_at_retirement : 0,
        investment_amount: 0,
    });
    console.log(clientDetails)
    useEffect(() => {
        if (selectedPortfolio) {
            let newClientDetails = {
                ...clientDetails,
                wanted_at_retirement: clientDetails.wanted_at_retirement !== 0 ? clientDetails.wanted_at_retirement : selectedPortfolio.calculated_values.market_value,
                investment_amount: selectedPortfolio.calculated_values.market_value
            };
            console.log(newClientDetails)
            setClientDetails(newClientDetails);
            let preparedData = {
                projected_return_1m: selectedPortfolio.calculated_values.risk.projected_return_1m,
                variance_1m: selectedPortfolio.calculated_values.risk.variance_1m,
                investment_amount: newClientDetails.investment_amount,
                expense_ratio: selectedPortfolio.calculated_values.expense_ratio,
                client_full_name: client.full_name,
                retirement_details: newClientDetails
            };
            requestRetirementCalculation(preparedData);
        }
    }, [selectedPortfolio])

    const requestRetirementCalculation = (data = null) => {
        if (!data) {
            data = {
                projected_return_1m: selectedPortfolio.calculated_values.risk.projected_return_1m,
                variance_1m: selectedPortfolio.calculated_values.risk.variance_1m,
                investment_amount: clientDetails.investment_amount,
                expense_ratio: selectedPortfolio.calculated_values.expense_ratio,
                client_full_name: client.full_name,
                retirement_details: clientDetails
            };
        }
        if (lifetimeChecker(clientDetails) < 0) {
            setWarningMessage(true);
            retirementData !== null && setRetirementData(null);
            return
        } else if (warningMessage) {
            setWarningMessage(false);
        }
        setLoading(true);
        calculateRetirement(data,
            ret_data => {
                ret_data.wanted_at_retirement = clientDetails.wanted_at_retirement;
                setRetirementData(ret_data);
                setLoading(false);
            },
            error => {
                console.log(error)
                message.error("Something is wrong with retirement processing");
                setLoading(false);
            }
        );
    }

    const handleDetailsChange = (name, value) => {
        setClientDetails({...clientDetails, [name]: value});
    }

    const enterPressRecalculation = (e) => {
        if (e.key === 'Enter') {
            // updateRetirementDetails();
            reloadRetirement();
        }
    }

    const reloadRetirement = () => {
        if (clientDetails.investment_amount === selectedPortfolio.calculated_values.market_value) {
            updateRetirementDetails(true);
        } else {
            updateRetirementDetails(false);
            requestRetirementCalculation();
        }
    }

    const updateRetirementDetails = (reloadData = true) => {
        saveQuestionnaire(client._id, clientDetails, (response, error) => {
            if (!error) {
                reloadData && loadData(false);
                // requestRetirementCalculation();
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }

        });
    }

    return (
        <div style={{width: '100%', background: '#fff', padding: 16}}>
            <Typography.Title level={3}>Retirement map</Typography.Title>
            <Row>
                <Col span={24}>
                    <ClientPortfolioSelector currentTitle={null} setActual={setSelectedPortfolio}
                                             setBenchmark={null}
                                             onlyActual={true}/>
                </Col>
            </Row>
            {warningMessage &&
            <Row>
                <Alert
                    style={{width: "100%", margin: "10px 0"}}
                    message="Wrong client's lifetime"
                    description={
                        <div>
                        <Typography.Text>{
                        `Seems like your client is probably out of date ${new Date().toLocaleString()}.
                        Client's date of birth is ${clientDetails.birth_year} and his life expectancy is ${clientDetails.life_expectancy}.`
                        }</Typography.Text>
                        <br/>
                        <Typography.Text>System can not calculate retirement for this parameters.</Typography.Text>
                        <br/>
                        <Typography.Text>{`${(clientDetails.birth_year + clientDetails.life_expectancy)} < ${new Date().getFullYear()}`}</Typography.Text>
                        </div>
                    }
                    type="error"
                    showIcon
                />
            </Row>}
            <Row>
                <div onKeyDown={enterPressRecalculation}>
                    <RetirementOptions handleChange={handleDetailsChange} retirementConfiguration={clientDetails}
                                       title={"Details"}/>
                </div>
            </Row>
            <Row style={{textAlign: 'right', marginTop: 13, marginRight: 13}}>
                <Button type={'primary'} onClick={() => {
                    reloadRetirement();
                }}>Calculate</Button>
            </Row>
            <Row>
                {loading ? <div style={{height: 300}} className="loadingBox"><Spin size="large">Loading</Spin></div> :
                    <RetirementGraph retirementData={retirementData} title={"Results"}
                                     retirementConfiguration={clientDetails}/>}
            </Row>

        </div>
    )
}

export default ClientRetirement;
