import React, { useState } from 'react';
import {Input, Spin} from 'antd';

const {Search} = Input;

// eslint-disable-next-line react/prop-types
const OrionForm = ({addFromOrion}) => {
    const [loading, setLoading] = useState(false);
    const addPortfolio = (value) => {
        setLoading(true);
        addFromOrion(value, () => {
            setLoading(false);
        });
    }


    return (
        !loading ?
            <Search onSearch={addPortfolio} placeholder="type in Orion account (not client) id" enterButton="Load"/>
            :
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spin size="large"></Spin>
            </div>
    );
}

export default OrionForm;
