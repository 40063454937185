import React, {useContext, useEffect, useState} from 'react';
import {
    Checkbox,
    Col,
    message,
    Row,
    Spin,
    Typography,
    Alert,
} from 'antd';
const { Title, Paragraph, Text } = Typography;

import RetirementOptions from "@root/components/holistico/proposal/view/retirement_map/RetirementOptions";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {calculateRetirement} from "@API/retirement";

import "./RetirementMap.less"
import RetirementGraph from "@components/holistico/proposal/view/retirement_map/RetirementGraph";
import PortfolioLabel
    from "@holistico/proposal/view/fundamentals/PortfolioLabel";
import {showAi, ShowAiButton} from "@holistico/proposal/view/ai/AiSideBar";
import SectionDescriptionEllipsis from "@holistico/aux/SectionDescription";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useUpdateEffect} from "ahooks";


export const prepareDataForRequest = (proposal, proposed=false, useDefault=true) => {
    const questionnaire = proposal?.questionnaire;
    const cv = proposed ? proposal?.p_bucket_prop?.calculated_values : proposal?.p_bucket_cur?.calculated_values;
    const fee = proposed ? questionnaire?.proposed_fee : questionnaire?.current_fee;

    if (!cv.market_value) {
        return null
    }

    if (!useDefault) {
        if (!questionnaire) {
            return null;
        }
        const required = [
            'birth_year',
            'retirement_year',
            'life_expectancy',
            'monthly_savings',
            'monthly_withdrawals',
            'inflation_rate',
        ];
        if (proposed) {
            required.push('proposed_fee');
        } else {
            required.push('current_fee');
        }
        if (required.some(field => is_null(questionnaire[field]))) {
            return null;
        }
    }

    return {
        projected_return_1m: cv?.risk?.projected_return_1m,
        variance_1m: cv?.risk?.variance_1m,
        investment_amount: cv?.market_value,
        expense_ratio: cv?.expense_ratio,
        client_full_name: proposal?.full_name,
        retirement_details: {
            birth_year: questionnaire?.birth_year ?? 1960,
            retirement_year: questionnaire?.retirement_year ?? 2035,
            life_expectancy: questionnaire?.life_expectancy ?? 90,
            monthly_savings: questionnaire?.monthly_savings ?? 0,
            monthly_withdrawals: questionnaire?.monthly_withdrawals ?? 0,
            savings_growth: questionnaire?.inflation_rate ?? 0,
            inflation_rate: questionnaire?.inflation_rate ?? 0,
            management_fee: fee ?? 0.5,
            nominal_values: true,
            ignore_extreme: true,
            wanted_at_retirement: cv?.market_value ?? 0,
            investment_amount: cv?.market_value ?? 0,
        }
    }
}


export const retirementMapDescription = <>
    <Paragraph>
        Retirement map is designed to examine how the current portfolio meets
        the client's long-term goals.
    </Paragraph>
    <Paragraph>
        The center line on the charts shows the projected portfolio
        dynamics. The probability range bounds the most probable
        investment dynamics scenarios. However, at each moment
        of time, there is a 10% probability of getting out the range
        down and a 10% probability of getting out the range up.
    </Paragraph>
    <Paragraph>
        As reference points, we present the projected value of wealth at
        retirement and
        the probability of not running out of money
        during the whole considered period.
    </Paragraph>
    <Paragraph>
        The retirement map calculation is based on Monte Carlo approach and is
        closely related to the portfolio projected return and risk characteristics.
    </Paragraph>
</>


const columnSpan = () => {
    if(showAi()) {
        return {xl: 12, lg: 24, md: 24, sm: 24, xs: 24}
    } else {
        return {xl: 12, lg: 12, md: 24, sm: 24, xs: 24}
    }
}


const RetirementMap = () => {
    const {proposal} = useHolisticoProposalContext()
    const [loading, setLoading] = useState(false);
    const [loadingProposed, setLoadingProposed] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    const [retirementData, setRetirementData] = useState(null);
    const [retirementDataProposed, setRetirementDataProposed] = useState(null);
    const [charToShow, setCharToShow] = useState(['original', 'proposed']);
    const mkval = proposal?.p_bucket_cur?.calculated_values?.market_value
    const mkval_prop = proposal?.p_bucket_prop?.calculated_values?.market_value
    const mkval_diff = (mkval && mkval_prop) ? Math.abs(mkval - mkval_prop) : 0

    let originalColProps= columnSpan()
    if (charToShow.includes("original") && charToShow.length === 1) {
        originalColProps = {span: 24}
    }
    if (!charToShow.includes("original")) {
        originalColProps = {span: 0}
    }
    let proposedColProps= columnSpan()
    if (charToShow.includes("proposed") && charToShow.length === 1) {
        proposedColProps = {span: 24}
    }
    if (!charToShow.includes("proposed")) {
        proposedColProps = {span: 0}
    }

    useUpdateEffect(() => {
        console.log('ahhahahah')
        const questionnaire = proposal?.questionnaire
        if (!questionnaire?.birth_year || !questionnaire?.life_expectancy) {
            return;
        }
        const currentYear = new Date().getFullYear();
        if (questionnaire.birth_year + questionnaire.life_expectancy < currentYear) {
            setWarningMessage(true);
            setRetirementData(null);
        } else {
            setWarningMessage(false);
        }
    }, [proposal])

    useEffect(() => {
        if (!proposal) {
            return;
        }

        setLoading(true)
        const data = prepareDataForRequest(proposal, false)
        if (data) {
            calculateRetirement(data,
                ret_data => {
                    setRetirementData(ret_data);
                    setLoading(false);
                },
                error => {
                    console.log(error)
                    message.error("Something is wrong with retirement processing");
                    setLoading(false);
                }
            );
        } else {
            setRetirementData(null);
            setLoading(false);
        }

        setLoadingProposed(true)
        const proposedData = prepareDataForRequest(proposal, true)
        if (proposedData) {
            calculateRetirement(proposedData,
                ret_data => {
                    setRetirementDataProposed(ret_data);
                    setLoadingProposed(false);
                },
                error => {
                    console.log(error)
                    message.error("Something is wrong with retirement processing");
                    setLoadingProposed(false);
                }
            );
        } else {
            setRetirementDataProposed(null);
            setLoadingProposed(false);
        }

    }, [proposal])

    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Retirement map
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            <SectionDescriptionEllipsis>
                {retirementMapDescription}
            </SectionDescriptionEllipsis>
            <div className={"settings-container"}
                 style={{
                     marginTop: 0,
                     borderTop: "1px  #F0F0F0 solid"
                }}
            >
                <RetirementOptions setCharToShow={setCharToShow}/>
            </div>
            {warningMessage &&
                <Alert
                    style={{width: "calc(100% - 24px)", margin: "12px"}}
                    message="Wrong life expectancy"
                    description={"Life expectancy must exceed the current year!"}
                    type="error"
                    showIcon
                />
            }
            {mkval_diff > 10
                && <Alert
                    style={{width: "calc(100% - 24px)", margin: "12px"}}
                    message="Warning"
                    description={<>
                        Client's current portfolio market value
                        ({ValueFormatter.currency(mkval)})
                        does not match
                        proposed portfolio market value
                        ({ValueFormatter.currency(mkval_prop)}).
                        This may affect the difference in the charts below.
                    </>}
                    type="warning"
                    showIcon
                />
            }
            <Row gutter={24}>
                <Col {...originalColProps}>
                    <PortfolioLabel type={"original"}>Current</PortfolioLabel>
                    <Spin size="large" spinning={loading}>
                        <RetirementGraph
                            retirementData={retirementData}
                            lifeExpectancy={proposal?.questionnaire?.life_expectancy}
                            investmentsAmount={proposal?.p_bucket_cur?.calculated_values?.market_value}
                            divId={"retirementGraphContainer"}
                        />
                    </Spin>
                </Col>
                <Col {...proposedColProps}>
                    <Spin size="large" spinning={loadingProposed}>
                        <PortfolioLabel
                            type={"proposed"}>Proposed</PortfolioLabel>
                        <RetirementGraph
                            retirementData={retirementDataProposed}
                            lifeExpectancy={proposal?.questionnaire?.life_expectancy}
                            investmentsAmount={proposal?.p_bucket_prop?.calculated_values?.market_value}
                            divId={"retirementGraphProposedContainer"}
                        />
                    </Spin>
                </Col>
            </Row>
        </div>
    </>
}

export default RetirementMap;
