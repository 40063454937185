import React, {useContext, useState} from 'react';
import {
    Button, Col,
    Form,
    Input,
    Modal,
    Progress, Row,
    Typography
} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import {
    PortfolioListContext
} from "@components/portfolio/contexts/PortfolioListContext";


// eslint-disable-next-line react/prop-types
const EmptyPortfolioModal = ({onFinish, open=false}) => {
    const {addEmpty} = useContext(PortfolioListContext);
    const [form] = Form.useForm();
    const [inProgress, setInProgress] = useState(false);
    const [loadPercent, setLoadPercent] = useState([]);

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: !inProgress,
        title: null,
        // title: "Adding empty portfolio",
        footer: null,
        onCancel: v => onFinish(),
        style: {background: "none"}
    }

    const addPortfolio = (value) => {
        console.log("addPortfolio", value)
        setInProgress(true)
        setLoadPercent(0)
        let progressInterval = progressIntervalCaller();

        addEmpty(value, () => {
            setInProgress(false);
            onFinish();
            clearInterval(progressInterval);
        });
    }

    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    return <>
        <Modal {...modalProps} open={open} className={"holistico"}>
            {inProgress
                ?
                <>
                    <Typography.Text>
                        Creating empty portfolio...
                    </Typography.Text>
                    <Progress percent={loadPercent} status="active" strokeColor='#E0AD60'/>
                </>
                :
                <>
                    <Form form={form}
                          layout={'vertical'}
                          onFinish={(values) => {
                              addPortfolio(values.name)
                          }}
                          initialValues={{name: "New portfolio"}}
                          requiredMark={false}
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={20}>
                                <Form.Item
                                    label={<Typography.Text
                                        className={"hint"}
                                        style={{fontSize: "18px"}}
                                    >
                                        Portfolio name
                                    </Typography.Text>}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Portfolio name cannot be empty!'
                                        }
                                    ]}
                                >
                                    <Input size="large"
                                           placeholder={"Enter portfolio name"}
                                           // allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Button
                                   type="primary"
                                   size="large"
                                   htmlType="submit"
                                   style={{width: "100%", marginTop: "28px"}}
                                >
                                    Ok
                                </Button>
                            </Col>
                        </Row>
                     </Form>
                </>
            }
        </Modal>
    </>
}

export default EmptyPortfolioModal;
