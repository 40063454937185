/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import {Col, List, Progress, Row, Spin, Empty} from "antd";

const CreditRatingChart = ({allocation, loading = false}) => {
    const [data, setData] = useState([]);
    // const [config, setConfig] = useState({
    //     angleField: 'value',
    //     data: [],
    //     colorField: 'label',
    // });

    useEffect(() => {
        if (!allocation) {
            return
        }
        let chartData = Object.keys(allocation).map(key => {
            return {
                label: key,
                value: allocation[key]
            }
        })
        if (chartData == data) {
            return
        }
        chartData.sort(function (a, b) {

            if (a.label == 'Below B') {
                return 1
            }
            if (b.label == 'Below B') {
                return -1
            }

            if (a.label.includes(b.label)) {
                return -1
            }

            if (b.label.includes(a.label)) {
                return 1
            }

            return a.label.localeCompare(b.label);
        });
        setData(chartData)
        // const values = Object.values(allocation)
        // const labels = Object.keys(allocation)

        // const max_value = Math.max(...values)
        // const max_label = labels[values.indexOf(max_value)]

        // const statistics = {
        //     title: {
        //         formatter: () => ValueFormatter.percent(max_value)
        //     },
        //     content: {
        //         formatter: () => max_label,
        //     }
        // }

        // const chartConfig = {
        //     title: 'Rating Distribution',
        //     angleField: 'value',
        //     data: chartData,
        //     colorField: 'label',
        //     color: (labelObj) => {
        //         console.log(labelObj.label)
        //         let color = ChartStyles.sizeColor(labelObj.label)
        //         return color;
        //     },
        //     forceFit: true,
        //     label: {
        //         style: {
        //             fontFamily: 'Red Hat Display',
        //             fontSize: 13
        //         },
        //         content: (valueObeject, b, index) => {
        //             return `${valueObeject.label}, ${ValueFormatter.percent(valueObeject.value)}`
        //         }
        //     },
        //     legend: false,
        //     responsive: true,
        //     radius: 0.8,
        //     innerRadius: 0.7,
        //     statistic: statistics,
        //     meta: {
        //         percent: {
        //             formatter: (v, s, d) => {
        //                 return ValueFormatter.percent(v)
        //             }
        //         }
        //     },
        //     annotation: [
        //         {
        //             type: 'centralText',
        //             onActive: false,
        //         },
        //     ]
        // }

        // setConfig(chartConfig)


    }, [allocation])

    return <Spin size="large" spinning={loading}>
        {data.length > 0
        ? 
        <List
            size="small"
            grid={{
                gutter: 4,
                column: 1
            }}
            dataSource={data}
            renderItem={item => <List.Item>
                <Row>
                    <Col span={3}>{item['label']}</Col>
                    <Col span={19}>
                        <Progress
                            style={{fontSize: "15px"}}
                            size="small"
                            percent={item['value'] * 100}
                            strokeColor={ChartStyles.get_rating_color(item['label'])}
                            format={percent => {
                                return ValueFormatter.round(percent, 2) + '%'
                            }
                            }/>
                    </Col>
                </Row>
            </List.Item>}
        />
        : 
        <Empty style={{margin: "15px 0"}} description="No credit rating information"/>
        }
    </Spin>
}

export default CreditRatingChart;
