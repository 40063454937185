import React, {useContext, useEffect, useState} from 'react';
import {List, Modal, Progress, Spin, Typography} from 'antd';
import {searchOrionAccounts} from "@API/clients";
import ValueFormatter from "@global/ValueFormatter";
import MainSearch from "@components/clients/MainSearch";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {
    PortfolioListContext
} from "@components/portfolio/contexts/PortfolioListContext";


const OrionAccountItem = ({account, onClick}) => {
    return <div
        onClick={() => onClick(account.id)}
        className="accounts-list-item"
    >
        <Typography.Text className="account-id">
            {account.id}
        </Typography.Text>
        <Typography.Text className="account-name">
            {account.name}
        </Typography.Text>
    </div>
}


const OrionClientItem = ({client, onClick}) => {
    return <div className="accounts-list">
        <Typography.Text className="accounts-list-title">
            {client.name}
        </Typography.Text>
        {client.accounts.map((account) => {
            return <OrionAccountItem account={account}
                                     onClick={onClick}
                                     key={account.id}/>
        })}
    </div>
}


// eslint-disable-next-line react/prop-types
const FromOrionModal = ({onFinish, open=false}) => {
    const {addFromOrion} = useContext(PortfolioListContext);
    const [loading, setLoading] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [loadPercent, setLoadPercent] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: !inProgress,
        title: null,
        // title: "Adding portfolio from Orion",
        footer: null,
        onCancel: v => onFinish(),
        style: {background: "none"}
    }

    const addPortfolio = (value) => {
        console.log("addPortfolio", value)
        setInProgress(true)
        setLoadPercent(0)
        let progressInterval = progressIntervalCaller();

        addFromOrion(value, () => {
            setInProgress(false);
            onFinish();
            clearInterval(progressInterval);
        });
    }

    useEffect(() => {
        const controller = new AbortController()
        if (searchString === "") {
            setSearchResult([]);
        } else {
            setLoading(true);
            searchOrionAccounts(searchString, (result, error) => {
                if (!error) {
                    setSearchResult(result);
                }
                setLoading(false);
            }, controller.signal);
        }
        return () => {
            controller.abort()
        }
    }, [searchString]);


    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    return <>
        <Modal {...modalProps} open={open} className={"holistico"}>
            {inProgress
                ?
                <>
                    <Typography.Text>Loading portfolio from Orion...</Typography.Text>
                    <Progress percent={loadPercent} status="active" strokeColor='#E0AD60'/>
                </>
                :
                <>
                    <MainSearch
                        fetchData={(value) => {setSearchString(value)}}
                        placeholder={"Type in the client name or account ID to start searching"}
                        // bordered={true}
                        autoFocus={true}
                    />
                    <Spin spinning={loading} tip="Searching portfolios..." size={"large"}>
                        {searchResult && searchResult.length !== 0
                            ?
                            <List
                                dataSource={searchResult}
                                renderItem={(client) => {
                                    return <OrionClientItem
                                        client={client}
                                        onClick={addPortfolio}
                                        key={client.id}
                                    />
                                }}
                                style={{maxHeight: "70vh", overflow: "scroll"}}
                            />
                            :
                            <NoDataPlaceholder
                                loading={loading}
                                description={
                                    searchString === "" || loading
                                        ?
                                        "Search result will be here..."
                                        :
                                        "No portfolios found..."
                                }
                            />
                        }
                    </Spin>
                </>
            }
        </Modal>
    </>
}

export default FromOrionModal;
