/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Empty, Row, Spin, Tabs, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import GeneralInfo from './GeneralInfo';
import ScoreAttribution from './ScoreAttribution';
import AssetClassPie from "@components/chart/AssetClassPie";
import FixedIncomeDistribution from "@components/asset/FixedIncomeDistribution";
import EquityDistribution from "@components/asset/EquityDistribution";
import parse from 'html-react-parser';


const {TabPane} = Tabs;
const generalInfo = {
    "ticker": {
        title: "Ticker"
    },
    "security_type": {
        title: "Security type"
    },
    "global_category": {
        title: "Global category"
    },
    "sscore_plus": {
        title: "SScore+",
        formatter: (v) => ValueFormatter.round(v, 0)
    },
    "expense_ratio": {
        title: "Exspense ratio",
        formatter: (v) => ValueFormatter.percent(v / 100, 0)
    },
    "div_yield": {
        title: "Dividend yield",
        formatter: (v) => ValueFormatter.percent(v / 100, 0)
    },
    "projected_return_1m": {
        title: "Proj. return 1mo.",
        formatter: (v) => ValueFormatter.percent(v / 100, 0)
    },
    "projected_return_6m": {
        title: "Proj. return 6mo.",
        formatter: (v) => ValueFormatter.percent(v / 100, 0)
    },
    "projected_return_1yr": {
        title: "Proj. return 1yr.",
        formatter: (v) => ValueFormatter.percent(v / 100, 0)
    },
}

const PortfolioInfo = ({portfolio, loadOpenApiData}) => {
    const [portfolioAsset, setPortfolioAsset] = useState(null)
    const [aiText, setAiText] = useState("");
    const [loading, setLoading] = useState(false)
    const run = () => {
        setLoading(true)
        loadOpenApiData((text) => {
            setAiText(text)
            setLoading(false)
        })
    }

    useEffect(() => {
        setAiText("")
    }, [portfolioAsset])

    useEffect(() => {
        let ticker = portfolio?.ticker

        if (!ticker) {
            ticker = 'Portfolio'
        }

        let scoreMarkers = {}

        for (let marker of portfolio?.calculated_values['score_markers']) {
            scoreMarkers[marker['label']] = marker['value']
        }

        let sscore_plus= portfolio?.calculated_values['score_markers'][0]['value']
        let pa = {
            risk_score: portfolio?.calculated_values['risk']['risk_score'],
            ticker: ticker,
            security_type: 'Portfolio',
            global_category: 'Signet Portfolio',
            name: portfolio.name,
            market_value: portfolio?.calculated_values['market_value'],
            div_yield: portfolio?.calculated_values['annual_dividend'],
            expense_ratio: portfolio?.calculated_values['expense_ratio'],
            sscore_plus: sscore_plus,
            projected_return_1m: portfolio?.calculated_values['risk']['projected_return_1m'],
            projected_return_1yr: portfolio?.calculated_values['risk']['projected_return_1yr'],
            projected_return_6m: portfolio?.calculated_values['risk']['projected_return_6m'],
            asset_class_alloc_dist: portfolio?.calculated_values['asset_class'],
            size_alloc_dist: portfolio?.calculated_values['size'],
            sector_alloc_dist: portfolio?.calculated_values['sector'],
            size_style_alloc_dist: portfolio?.calculated_values['size_style'],
            fi_credit_rating_alloc_dist: portfolio?.calculated_values['fi_credit_rating'],
            fi_effective_maturity_alloc_dist: portfolio?.calculated_values['fi_effective_maturity'],
            fi_super_sector_alloc_dist: portfolio?.calculated_values['fi_super_sector'],
            scoreMarkers: scoreMarkers
        }
        console.info(pa)
        setPortfolioAsset(pa)
    }, [portfolio])

    const memoGeneralInfo = useMemo(() => {
        return <GeneralInfo fullAsset={portfolioAsset} generalInfo={generalInfo}/>
    }, [portfolioAsset])

    const memoScoreAttribution = useMemo(() => {
        return <ScoreAttribution fullAsset={portfolioAsset?.scoreMarkers}/>
    }, [portfolioAsset])

    const memoFixedIncomeDistribution = useMemo(() => {
        return <FixedIncomeDistribution fullAsset={portfolioAsset}/>
    }, [portfolioAsset])

    const memoEquityDistribution = useMemo(() => {
        return <EquityDistribution fullAsset={portfolioAsset}/>
    }, [portfolioAsset])

    if (portfolioAsset) {
        return (
            <Card>
                {memoGeneralInfo}
                {Object.keys(portfolioAsset.asset_class_alloc_dist).length > 0 &&
                    <>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Typography.Title level={4}>Asset class</Typography.Title>
                                <AssetClassPie allocation={portfolioAsset.asset_class_alloc_dist} loading={false}/>
                            </Col>
                        </Row>
                    </>
                }
                <br/>
                <div style={{marginTop: "10px"}}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Ratings" key="1">
                            {memoScoreAttribution}
                        </TabPane>
                        <TabPane tab="Equity dist." key="2">
                            {memoEquityDistribution}
                        </TabPane>
                        <TabPane tab="Fixed income dist." key="3">
                            {memoFixedIncomeDistribution}
                        </TabPane>
                        <TabPane tab={<>&nbsp;AI<sup style={{color: 'red'}}>*</sup></>} key={"5"}>
                            <Button onClick={run} disabled={loading}>Get AI impression</Button>
                            <Spin spinning={loading} tip={"Talking to AI"}>
                                <Typography.Paragraph>
                                    {aiText.split("\n\n").map(par => {
                                        return <Typography.Paragraph>
                                            {parse(par)}
                                        </Typography.Paragraph>
                                    })
                                    }
                                </Typography.Paragraph>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
        )
    } else {
        return (
            <Empty description={'No asset selected'}/>
        )
    }
}

export default PortfolioInfo;
