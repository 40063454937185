import React, {useEffect, useState} from 'react';
import {Col, Divider, Input, message, Row, Select, Spin, Typography, Upload} from 'antd';
import {cancel, getExampleExcel} from "@API/portfolio";
import {FileExcelOutlined, FileImageOutlined, FilePdfOutlined, FileTextOutlined} from "@ant-design/icons";
import PositionProxy from "@components/portfolio/position/PositionProxy";
import {downloadFile} from "@global/FileDownloader";

const {Paragraph} = Typography;
const {Dragger} = Upload;
const {Option} = Select;

// eslint-disable-next-line react/prop-types
const FileForm = ({uploadFile, portfolioType}) => {
    const [proxy, setProxy] = useState(null);
    const [portfolioOptions, setPortfolioOptions] = useState({
        name: ""
    });
    const [loading, setLoading] = useState(false);
    const [uploadingError, setUploadingError] = useState(null);

    useEffect(() => {
        return () => {
            cancel && cancel();
        }
    }, [])

    const doUpload = (options) => {
        let fileName = options?.file?.name
        let autoName = fileName.substr(0, fileName.lastIndexOf('.')) || fileName

        let uploadOptions = {...portfolioOptions}
        if (uploadOptions.name === "") {
            uploadOptions.name = autoName
        }

        const {file} = options;
        let uploadData = new FormData();
        uploadData.append("file_name", fileName);
        uploadData.append("file_content", file);
        uploadData.append("portfolio_name", uploadOptions.name);
        uploadData.append("portfolio_type", portfolioType);

        if (proxy !== null) {
            const strProxy = JSON.stringify(proxy.proxy);
            uploadData.append("proxy", strProxy);
        }

        setLoading(true);
        return uploadFile(uploadData, (error) => {
            setLoading(false);
            setPortfolioOptions({name: ""})
            if (error) {
                //message.error(error)
            }
        });
    }

    const exampleExcelDownload = () => {
        console.log("DOWNLOAD")
        getExampleExcel((response, error) => {
            if (error) {
                message.error("Error while downloading example");
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `SPAS Example Excel.xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
            })
        })
    }

    const draggerProps = {
        multiple: false,
        //accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        showUploadList: false,
        customRequest: (options => doUpload(options)),
        onError: (err) => {
            setLoading(false);
            console.log(err);
        }
    };

    return (

        <div style={{display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%"}}>
            {!loading ? <>
                    <Paragraph>
                        Upload an excel, csv, image or pdf file, containing
                        portfolio positions info, for excel/csv
                        files please use <a href="#" onClick={() => exampleExcelDownload()}>
                            the example
                        </a> for clarification, each tab is a whole
                        portfolio file example.
                    </Paragraph>
                    {uploadingError &&
                        <Paragraph
                            style={{color: "#bd3030"}}>{uploadingError}</Paragraph>}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: "20px 0"
                    }}
                         className={'ant-upload ant-upload-drag'}>

                        <Dragger {...draggerProps} style={{border: 0}}>

                            <p className="ant-upload-drag-icon">
                                <FilePdfOutlined style={{color: "indianred"}}/>
                                <FileExcelOutlined style={{color: "green"}}/>
                                <FileTextOutlined style={{color: '#3196B2'}}/>
                                <FileImageOutlined style={{color: '#E0AD60'}}/>

                            </p>
                            <p className="ant-upload-text">Click or drag to
                                upload</p>
                            <p className="ant-upload-hint">
                                Upload any file with positions table
                            </p>
                        </Dragger>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <label>Portfolio Name</label>
                            <Input placeholder="will be set to filename by default"
                                   value={portfolioOptions.name}
                                   onChange={e => setPortfolioOptions({
                                       ...portfolioOptions,
                                       name: e.target.value
                                   })}/>
                        </Col>
                    </Row>
                    <Divider plain>Default Values for Unknown Assets</Divider>
                    <label>Proxy</label>
                    <PositionProxy onChange={setProxy} value={proxy}/>
                </>
                :
                <Spin size="large" style={{marginTop: "40px"}}></Spin>
            }
        </div>
    );
}

export default FileForm;
