import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import {Modal, Form, Input, InputNumber, Select, Spin, Space, Badge, Divider, Tooltip, Row, Col} from "antd";
import { 
    getGlobalConfig, 
    createGlobalConfig, 
    updateGlobalConfig,
    getGlobalConfigsDetails
} from "@API/supervision";
import {QuestionCircleOutlined} from '@ant-design/icons';

import "./GlobalConfigs.less";

const ConfigCreator = ({configId, updateList, modalOpen, closeModal, copy}) => {
    const [configsDetails, setConfigsDetails] = useState(null);
    const [form] = Form.useForm();
    const [retrieveLoading, setRetrieveLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        getGlobalConfigsDetails((data, error) => {
            if (error) {
                message.error("Error while retrieving configs details")
                return
            }
            console.log(data)
            setConfigsDetails(data)
        }, abortControllerRef.current.signal);

        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    useEffect(() => {
        if (configId !== null) {
            setRetrieveLoading(true);
            getGlobalConfig(configId, (data, error) => {
                setRetrieveLoading(false);
                if (error) {
                    message.error("Error while retrieving config")
                    return
                }
                console.log(data)
                // const _configValues = {...data.limits, use_client_config: data.use_client_config};
                const _configValues = {...data.limits};
                if (!copy) {
                    _configValues.name = data.name
                }
                form.setFieldsValue(_configValues);
                setFormValues(_configValues);
            }, abortControllerRef.current.signal);
        }
    }, [configId, copy])

    const handleCancel = () => {
        form.resetFields();
        closeModal();
        setFormValues({});
    }

    const updateConfig = useCallback((values) => {
        setRetrieveLoading(true);
        let configValues = {};
        configValues.name = values.name;
        // configValues.use_client_config = values.use_client_config;
        configValues.type = "global";
        delete values.name;
        // delete values.use_client_config;
        configValues.limits = values;
        updateGlobalConfig(configId, configValues, (data, error) => {
            setRetrieveLoading(false);
            if (error) {
                message.error("Error while updating config")
                return
            }
            handleCancel();
            updateList();
        }, abortControllerRef.current.signal);
    }, [configId, setRetrieveLoading, abortControllerRef])

    const createConfig = useCallback((values) => {
        setRetrieveLoading(true);
        let configValues = {};
        configValues.name = values.name;
        // configValues.use_client_config = values.use_client_config;
        configValues.type = "global";
        delete values.name;
        // delete values.use_client_config;
        configValues.limits = values;
        createGlobalConfig(configValues, (data, error) => {
            setRetrieveLoading(false);
            if (error) {
                message.error("Error while creating config")
                return
            }
            handleCancel();
            updateList();
        }, abortControllerRef.current.signal);
    }, [setRetrieveLoading, abortControllerRef])

    const memoizedCreatorContainer = useMemo(() => {
        if (!configsDetails) {
            return null
        }
        return <>
            <Form form={form} 
                name="config_creator"
                autoComplete="off"
                scrollToFirstError
                requiredMark={false}
                onFinish={(values) => {
                    console.log(values)
                    if (configId && !copy) {
                        updateConfig(values);
                    } else {
                        createConfig(values);
                    }
                }}
            >
                <Space direction="vertical" style={{width: "100%"}}>
                    <Form.Item
                        style={{width: "100%"}}
                        name="name"
                        label="Title"
                        labelCol={{span: 9}}
                        rules={[{
                            required: true,
                            type: 'string',
                            message: 'Missing title',
                        }]}>
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        name="use_client_config"
                        label="Use clients' limits"
                        labelCol={{span: 9}}
                        valuePropName="checked"
                        initialValue={false}
                        rules={[{
                            required: true,
                        }]}>
                        <Switch />
                    </Form.Item> */}
                </Space>
                <Divider orientation="left" plain style={{marginTop: 0}}>
                    Limits
                </Divider>
                <Space direction="vertical" style={{width: "100%"}}>
                    {Object.keys(configsDetails).map((flagKey, inx) => {
                        const flag = configsDetails[flagKey];
                        let flagLabel;
                        if (configId && !Object.keys(formValues).includes(flagKey)) {
                            flagLabel = <Tooltip title="Default value is set here, but you need to update it or just save config">
                                <Badge status="error" text={flag.name} />
                            </Tooltip>
                        } else {
                            flagLabel = flag.name;
                        }
                        // we can't avoid code duplication due to antd Form is able to watch fields in inline code only
                        if (flag.limit.type === "numeric") {
                            return <Form.Item
                                key={inx}
                                name={flagKey}
                                labelCol={{span: 9}}
                                tooltip={flag.description}
                                extra={<>
                                    <Row>{flag.limit.description}</Row>
                                    <Row>Bounds of limit value: from {flag.limit.lower_limit} to {flag.limit.upper_limit}</Row>
                                </>}
                                label={flagLabel}
                                initialValue={flag.limit.default_value}
                                rules={[{
                                    required: true,
                                    message: 'Missing limit',
                                }, {
                                    min: flag.limit.lower_limit,
                                    max: flag.limit.upper_limit,
                                    type: 'number',
                                    message: `Bounds of limit value: from ${flag.limit.lower_limit} to ${flag.limit.upper_limit}`
                                }]
                                }>
                                    <InputNumber
                                        formatter={flag.limit.units === "%" ? 
                                            value => (value + "%") :
                                            value => value
                                        }
                                        min={flag.limit.lower_limit} 
                                        max={flag.limit.upper_limit}
                                        step={flag.limit.step}
                                    />
                            </Form.Item>
                        } else {
                            return <Form.Item
                                key={inx}
                                name={flagKey}
                                label={flagLabel}
                                labelCol={{span: 9}}
                                tooltip={flag.description}
                                extra={<>
                                    <Row>{flag.limit.description}</Row>
                                    <Row>Choose one of presets</Row>
                                </>}
                                initialValue={flag.limit.default_value}
                                rules={[{
                                    required: true,
                                    message: 'Missing limit',
                                }]
                                }>
                                    <Select>
                                        {flag.limit.options.map((option, inx) => {
                                            return <Select.Option key={inx} value={option.key}>{option.name}</Select.Option>
                                        })}
                                    </Select>
                            </Form.Item>
                        }
                    })}
                </Space>
            </Form>
        </>
    }, [configsDetails, form, updateConfig, createConfig, configId, formValues])

    return (
        (<Modal title={configId && !copy ? "Update config" : "Create config"}
               width={800}
               open={modalOpen}
               onCancel={handleCancel}
               onOk={form.submit}
               okText={configId && !copy ? "Update" : "Create"}
        >
            <Spin size={"large"} spinning={retrieveLoading} tip="Waiting...">
                {memoizedCreatorContainer}
            </Spin>
        </Modal>)
    );
}

export default ConfigCreator