import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    ConfigProvider,
    Flex,
    List,
    Tag,
    theme,
    Tooltip,
    Typography,
} from 'antd';
import {useRequest, useUpdateEffect} from "ahooks";
import ValueFormatter from "@global/ValueFormatter";
import {ProposalCardRibbon, ProposalStatusBadge} from "@global/ProposalHelper";
import {getProposalsAsync, cancel} from "@API/ahooks/dashboard";
import {
    ShareAltOutlined,
    UserOutlined,
    WalletOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import {useAuth} from "@hooks/AuthHook";
import RecentProposalsListItemMenu
    from "@holistico/proposal/recent/RecentProposalsListItemMenu";
import Constants from "@global/Constants";


const {useToken} = theme;


const ProposalTag = ({
                         tooltip = null,
                         icon = null,
                         color = null,
                         style = {},
                         children
                     }) => {
    return <Tooltip title={tooltip} mouseEnterDelay={0.5}>
        <Tag
            bordered={false}
            icon={icon}
            color={color}
            style={{margin: 0, ...style}}
        >
            {children}
        </Tag>
    </Tooltip>
}


const ProposalItem = ({proposal}) => {
    const [background, setBackground] = useState("rgba(0, 0, 0, 0.03)");
    const auth = useAuth();
    const {token} = useToken();

    const url = `/${Constants.RIA_URL}/proposals/${proposal._id}`;

    return <ProposalCardRibbon proposal={proposal}>
        <NavLink to={url}>
            <List.Item
                onMouseEnter={() => setBackground(token.colorBgGreyDark)}
                onMouseLeave={() => setBackground(token.colorBgGrey)}
                style={{
                    paddingLeft: token.paddingXS,
                    paddingRight: token.paddingXS,
                    marginBottom: token.paddingXS,
                    borderRadius: token.borderRadius,
                    background: background
                }}
            >
                <Flex gap={"small"} align={"center"} style={{width: "100%"}}>
                    <ProposalStatusBadge proposal={proposal} offset={[-15, 12]}>
                        <Flex justify={"center"} align={"center"} style={{
                            background: "white",
                            borderRadius: 100,
                            padding: token.padding,
                            fontSize: token.fontSizeLG
                        }}>

                            <UserOutlined/>

                        </Flex>
                    </ProposalStatusBadge>
                    <Flex vertical gap={4} style={{flexGrow: 1}}>
                            <Typography.Text
                                style={{fontWeight: token.fontWeightStrong}}>
                                {proposal.full_name && proposal.full_name !== ""
                                    ? proposal.full_name
                                    : "Unknown"
                                }
                            </Typography.Text>
                        <ConfigProvider theme={{
                            token: {
                                fontSize: 14,
                            }
                        }}>
                            <Flex gap={"small"} wrap={"wrap"}>
                                <ProposalTag
                                    tooltip={"Total investments"}
                                    color={"white"}
                                    style={{color: token.colorTextSecondary}}
                                >
                                    {ValueFormatter.compactCurrency(proposal.p_bucket_cur?.calculated_values?.market_value ?? 0)}
                                </ProposalTag>
                                <ProposalTag
                                    tooltip={"Last update"}
                                    color={"white"}
                                    style={{color: token.colorTextSecondary}}
                                >
                                    {ValueFormatter.dateLongMMM(dayjs(proposal.updatedAt))}
                                </ProposalTag>
                            </Flex>
                        </ConfigProvider>
                    </Flex>
                    <Flex style={{marginRight: token.padding}}>
                        <RecentProposalsListItemMenu
                            id={proposal._id}
                            onEdit={() =>  auth.setProposalListChange(prev => prev + 1)}
                        />
                    </Flex>
                </Flex>
            </List.Item>
        </NavLink>
    </ProposalCardRibbon>
}


const ProposalsList = () => {
    const [proposals, setProposals] = useState([]);
    const auth = useAuth();
    const {token} = useToken();

    const {
        data: proposalsResponse,
        loading,
        error,
        refresh
    } = useRequest(() => getProposalsAsync(
        cancel,
        true,
        false,
        false,
        true,
        "updatedAt",
        5,

    ), {
        refreshDeps: [auth.proposalListChange]
    });

    useUpdateEffect(() => {
        console.error(error);
    }, [error]);

    useUpdateEffect(() => {
        console.debug("Recent proposals loaded", proposalsResponse.data);
        setProposals(proposalsResponse.data);
    }, [proposalsResponse]);

    return <List
        loading={loading}
        dataSource={proposals}
        renderItem={(item) => {
            return <ProposalItem key={item._id} proposal={item}/>
        }}
        style={{marginBottom: -token.paddingXS}}
    />
}


const ProposalsCard = () => {
    const navigate = useNavigate();
    const {token} = useToken();

    return <Card hoverable style={{
        height: "100%",
        cursor: "default"
    }}>
        <Typography.Title level={4} style={{marginTop: 0}}>
            Proposals
        </Typography.Title>
        <Flex gap={"middle"} align={"baseline"} wrap={"wrap"}
              style={{marginBottom: token.paddingLG}}>
            <Button
                type="primary"
                onClick={() => {
                    navigate({pathname: `/${Constants.RIA_URL}/proposals/new`})
                }}
            >
                New client
            </Button>
            <a href={`proposals`}>
                View all clients
            </a>
        </Flex>
        <ProposalsList/>
    </Card>
}

export default ProposalsCard;
