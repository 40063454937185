import React from "react";
import {
    Col,
    Radio,
    Row,
    Tooltip
} from "antd";


const colSpan = { xs: 12,  sm: 8,  md: 6, lg:4, xl: 3 }


const ReportPresetList = ({presets, selected, onSelect}) => {
    return <Radio.Group
        onChange={(e) => onSelect(e.target.value)}
        value={selected.key}
        style={{width: "calc(100% - 60px)"}}
    >
        <Row gutter={[24, 24]}>
            {presets.map(preset => {
                return <Col {...colSpan} key={preset.key}>
                    <Tooltip title={preset.description}>
                        <Radio value={preset.key} className={"preset-card"}>
                            {preset.title}
                            {preset.type !== "user" && <span className={"hint"}>global</span>}
                        </Radio>
                    </Tooltip>
                </Col>
            })}
        </Row>
    </Radio.Group>

}

export default ReportPresetList;
