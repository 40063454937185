import React from "react";
import {Checkbox, Flex, Form} from 'antd';


const SizeInput = ({allSizes, hidden=false}) => {
    return <Form.Item
        name={"size"}
        label={<b>Size</b>}
        hidden={hidden}
    >
        <Checkbox.Group>
            <Flex vertical>
                {allSizes.map(size => {
                    return <Checkbox key={size} value={size}>
                        {size}
                    </Checkbox>
                })}
            </Flex>
        </Checkbox.Group>
    </Form.Item>
}

export default SizeInput
