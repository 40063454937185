import React, {useState} from "react";
import {message, Modal, Progress, Result, Typography} from 'antd';
import {createFromOrion} from "@API/clients";
import Localizator from "@global/Localizator";
import {Link} from "react-router-dom";

const {Paragraph} = Typography;

const OrionProposalCreatorModal = ({orionId, clientClick, cancelAction}) => {
    const [loadedPercent, setLoadedPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [createdClient, setCreatedClient] = useState(null);

    const progressIntervalCaller = () => {
        return setInterval(() => {
            setLoadedPercent((current) => current <= 100 ? current + 1 : current);
        }, 700)
    }

    const OrionLoader = () => {
        if (!loading) {
            return <Paragraph>{Localizator.t('confirm.orion.load')}</Paragraph>
        } else {
            return <>
                <Paragraph>Waiting for proposal creation to finish.</Paragraph>
                <Progress percent={loadedPercent} status="active" strokeColor='#E0AD60'/>
            </>
        }
    }

    const loadFromOrion = () => {
        setLoading(true);
        let progressInterval = progressIntervalCaller();
        createFromOrion(orionId, (newClient, error) => {
            setLoading(false);
            clearInterval(progressInterval);
            if (error) {
                message.error('Failed to load the client from Orion');
                handleCancel();

            } else {
                console.log(newClient)
                message.success('Client proposal was created successfully');
                if (clientClick) {
                    cancelAction();
                    clientClick(newClient);
                } else {
                    setCreatedClient(newClient);
                }
            }
        })
    };

    const handleCancel = () => {
        setCreatedClient(null);
        cancelAction();
        setLoading(false);
        setLoadedPercent(0);
    };

    return (
        (<Modal
            title="Client synchronization"
            open={orionId !== null}
            closable={!loading}
            onOk={createdClient ? handleCancel : loadFromOrion}
            confirmLoading={loading}
            onCancel={handleCancel}
            cancelButtonProps={loading && {disabled: true}}
        >
            {createdClient ?
            <Result
                status="success"
                title={`Client proposal for client ${createdClient.full_name} was created successfully`}
                subTitle={<Link to={`proposal/${createdClient._id}/overview`} target="_blank">Show proposal</Link>}
            /> :
            <OrionLoader/>}
        </Modal>)
    );
}

export default OrionProposalCreatorModal
