import React, {useState} from "react";
import {Radio, Space, Spin, Tooltip, Typography} from 'antd';

import {deleteReportPreset} from "@API/report";


const {Title} = Typography;

const ReportPresetList = ({presets, selected, selectPreset, reloadPresets}) => {
    const [loadingId, setLoadingId] = useState(null);
    const handleDeleteTemplate = (_id) => {
        setLoadingId(_id);
        deleteReportPreset(_id, () => {
            reloadPresets();
            setLoadingId(null);
        })
    }

    const PresetRadio = ({preset}) => {
        let label = null;
        if (preset.type === 'user') {
            label = <sup><a onClick={() => handleDeleteTemplate(preset._id)}>delete</a></sup>
        }
        return (
            loadingId === preset._id ?
            
                <Spin key={preset.key} size="small"/>
                :
                <Radio key={preset.key} value={preset.key}>
                    <Tooltip placement="right" title={preset.description}>
                            {preset.title} {label}
                    </Tooltip>
                </Radio>
                
        )       
    }

    return (
        <div>
            <Title level={4}>Preset</Title>
            <Radio.Group onChange={(e) => selectPreset(e.target.value)} value={selected.key}>
                <Space direction="vertical">
                    {presets.map((preset, inx) => <PresetRadio preset={preset} key={inx}/>)}

                </Space>
            </Radio.Group>
        </div>
    )
}

export default ReportPresetList
