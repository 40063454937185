/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {Typography} from "antd";
import AssetComparator from "@components/asset/AssetComparator";

const KEYS_TO_PARAMS = ['ticker', 'gvkey', 'gvkeyx', 'iid', 'secid', 'security_type']


const Assets = () => {

    return (
        <>
            <Typography.Title level={3}>Asset analysis</Typography.Title>
            <AssetComparator/>
        </>
    );
}

export default Assets;
