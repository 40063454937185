import {Tooltip} from "antd";
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import React from "react";


const RiskResult = ({title, description, tooltip, success, children, ...props}) => {
    const content = <div style={{display: "inline-flex"}}>
        <div style={{marginRight: "32px"}}>
            {success
                ?
                <CheckCircleFilled style={{
                    fontSize: "72px",
                    color: "#86bd91"
                }}/>
                :
                <ExclamationCircleFilled
                    style={{
                        fontSize: "72px",
                        color: "#e3e3e3"
                }}/>
            }
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                fontSize: "24px",
                display: "flex",
                alignItems: "baseline"
            }}>
                {title}
            </div>
            <div style={{
                color: "#a2a2a2",
                marginBottom: "24px"
            }}>
                {description}
            </div>
            {children}
        </div>
    </div>

    return <div {...props}>
        {tooltip
            ?
            <Tooltip title={tooltip}>
                {content}
            </Tooltip>
            :
            content
        }
    </div>
}

export default RiskResult;
