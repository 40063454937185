import {is_null} from "@global/ValueFormatter";
import {DEFAULT_ORDER_BY} from "@components/screener/settings";


export const getFilterType = (name, settings) => {
    if (name in settings.filters) {
        return settings.filters[name].type;
    }
    if (name.includes(".") && name.split(".").length === 2) {
        const key = name.split(".")[0];
        return settings.filters[key]?.type;
    }
    if (name.includes(".") && name.split(".").length === 3) {
        const key = name.split(".")[0];
        return settings.filters[key]?.type + '-item';
    }
    return undefined;
}


export const isValidParamName = (name, settings) => {
    if (['search', 'search-pinned', 'order_by'].includes(name)) {
        return true;
    }
    if (name in settings.filters) {
        return true;
    }
    if (name.includes(".") && name.split(".").length === 2) {
        const [p_name, sign] = name.split(".");
        return settings.filters[p_name]?.type === 'numeric' && ['from', 'to'].includes(sign);
    }
    if (name.includes(".") && name.split(".").length === 3) {
        const [p_name, idx, sign] = name.split(".");
        if (settings.filters[p_name]?.type !== 'allocation') {
            return false;
        }
        if (!settings.filters[p_name].items || !settings.filters[p_name].items[idx]) {
            return false;
        }
        return ['from', 'to'].includes(sign);
    }
    return false;
}


const getAllocationParamsFix = (params, settings) => {
    const fix = {};
    // If there is allocation-item filter,
    // switch to 'custom' allocation set
    for (let name in params) {
        if (getFilterType(name, settings) === 'allocation-item') {
            const p_name = name.split(".")[0];
            if (settings.filters[p_name]?.tips) {
                 fix[p_name] = 'custom';
            }
        }
    }
    // Unpack allocation values
    for (let name in params) {
        if (getFilterType(name, settings) === 'allocation' && settings.filters[name]?.tips) {
            if (params[name] === 'custom' || fix[name] === 'custom') {
                continue;
            }
            let tipValues = null;
            // Look for necessary tip
            for (let tip of settings.filters[name].tips) {
                if (tip.name === params[name]) {
                    tipValues = tip.values;
                    break;
                }
            }
            // Unpack tip values
            if (tipValues) {
                settings.filters[name].items.map((item, index) => {
                    fix[`${name}.${index}.from`] = tipValues[item.value]?.from;
                    fix[`${name}.${index}.to`] = tipValues[item.value]?.to;
                })
            }
        }
    }

    return fix;
}


export const parseSearchParams = (searchParams, entityGroup, settings) => {
    const params = {};

    searchParams.forEach((value, name) => {
        if (name in params || !isValidParamName(name, settings)){
            return;
        }
        const type = getFilterType(name, settings)
        if (type === 'choice') {
            params[name] = searchParams.getAll(name);
        } else {
            params[name] = value
        }
    });
    if (!('order_by' in params)) {
        params['order_by'] = DEFAULT_ORDER_BY[entityGroup]
    }

    const fix = getAllocationParamsFix(params, settings)

    console.log('parseSearchParams', {...params, ...fix})
    return {...params, ...fix}
}


const fillSearchParamsWithAllocItemsFromForm = (prev, form, name, ignore=null) => {
    const values = form.getFieldsValue();
    for (let key in values) {
        if (key.startsWith(name + ".") && key !== ignore) {
            if (!is_null(values[key])) {
                prev.set(key, values[key]);
            } else if (prev.has(key)) {
                prev.delete(key);
            }
        }
    }
}


export const updateSearchParams = (prev, params, settings, form) => {
    for (let name in params) {
        const value = params[name];
        const type = getFilterType(name, settings);

        if (type === 'allocation' && value !== 'custom') {
            // Remove all sub-items
            const toDrop = []
            prev.forEach((_, key) => {
                if(key.startsWith(name + ".")){
                    toDrop.push(key)
                }
            })
            toDrop.map(key => prev.delete(key));
            console.log('updateSearchParams', name, value, toDrop)
            prev.set(name, value);
            continue;
        }

        if (type === 'allocation' && value === 'custom') {
            // Set all existing sub-items
            // Take them from form as they are not in params yet
            fillSearchParamsWithAllocItemsFromForm(prev, form, name)
            if (prev.has(name)) {
                prev.delete(name)
            }
            continue;
        }

         if (type === 'allocation-item') {
             const [p_name, idx, sign] = name.split(".")
             if (settings.filters[p_name]?.tips && prev.has(p_name) && prev.get(p_name) !== 'custom') {
                 // Switch to custom allocation
                 // Set all existing sub-items
                 // Take them from form as they are not in params yet
                 fillSearchParamsWithAllocItemsFromForm(prev, form, p_name, name)
             }
             if (!is_null(value)) {
                 prev.set(name, value)
             } else if (prev.has(name)) {
                 prev.delete(name)
             }
             // Delete allocation name from params
             if (prev.has(p_name)) {
                 prev.delete(p_name)
             }
             continue;
         }

         if (type === 'choice' && !is_null(value)) {
             const values = Array.isArray(value) ? value : [value]
             const prevValues = prev.getAll(name)
             prevValues.map(pv => {
                 if (!values.includes(pv)) {
                     prev.delete(name, pv)
                 }
             })
             values.map(v => {
                 if (!prevValues.includes(v)) {
                     prev.append(name, v)
                 }
             })
             continue;
         }

         if (type !== 'choice' && !is_null(value)) {
             if (name === 'search' && is_null(form.getFieldValue(['search']))) {
                // Too fast search pin - do nothing
                continue;
            }
             prev.set(name, value);
             continue;
         }

         if (is_null(value) && prev.has(name)) {
             prev.delete(name);
         }
    }

    return prev;
}