/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import Localizator from "@global/Localizator";
import {Col, List, Row, Spin, Empty} from "antd";
import ChartStyles from "@global/ChartStyles";
import ValueFormatter from "@global/ValueFormatter";
import StatusProgress from "@components/chart/StatusProgress";
import ScoreMarkersRadar from "@components/chart/ScoreMarkersRadar";

// const {Paragraph, Text, Title} = Typography;
const ScoreMarkersStats = ({score_markers, loading, title}) => {
    // const [config, setConfig] = useState({
    //     data: [],
    //     xField: 'label',
    //     yField: 'value'
    // });
    const [data, setData] = useState([])

    useEffect(() => {
        if (!score_markers) {
            return
        }
        let translated = score_markers.map((marker) => {
            let value = ValueFormatter.round(marker['value'], 0)

            let status = ChartStyles.get_status(value)
            return {
                label: Localizator.t(`factor.${marker['label']}`),
                value: value,
                status: status
            }
        })
        setData(translated)
    }, [score_markers])

    return <Spin size="large" spinning={loading}>
        {score_markers && score_markers.length > 0?
            <Row>
                <Col span={7}>
                    <List
                        dataSource={data}
                        renderItem={item => (
                            <List.Item title={item.label}>
                                <StatusProgress value={item.value}
                                                status={item.status}/><b>{item.value}</b> {item.label}


                            </List.Item>)}/>
                </Col>
                <Col span={17}>
                    <ScoreMarkersRadar score_markers={data}
                                       title={"selectedBenchmark.name"}/>
                </Col>
            </Row> :
            <Empty style={{margin: "15px 0"}} description="No SScores information"/>
        }
    </Spin>;
}

export default ScoreMarkersStats;
