import React, {useState} from "react";
import {Form, Input, message, Modal, Select, Spin, Typography} from "antd";
import {createUser, inviteUserToOrg} from "@API/users";
import {useNavigate} from "react-router-dom";


const ModalInviteOrgUser = ({isOpen, closeModal}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        form.resetFields();
        closeModal()
    }

    const onFinish = values => {
        setLoading(true);
        inviteUserToOrg(values.email, (resp, error) => {
            setLoading(false);
            if (error) {
                console.error(resp);
                return
            }
            message.success("If the user exists in the system, an invitation will be sent.");
            form.resetFields();
            closeModal()
        })
    }

    return (
        <Modal
            open={isOpen}
            title="User creation"
            onOk={form.submit}
            onCancel={handleCancel}
            okText="Add"
        >
            <Spin size={"large"} spinning={loading} tip="Invitation in progress...">
                <Typography.Paragraph>
                    Type in user's email to invite him/her to your organization.
                </Typography.Paragraph>
                <Form form={form} name="user_creation" onFinish={onFinish} autoComplete="off" scrollToFirstError
                      layout="horizontal">
                    <Form.Item
                        name="email"
                        label="Email"
                        requiredMark={false}
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid email',
                            },
                            {
                                required: true,
                                message: 'Missing email',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ModalInviteOrgUser
