/* eslint-disable react/prop-types */
import React, {useContext, useEffect, useState} from 'react';
import {message, Spin} from 'antd';
import PortfolioHeaderRow from './PortfolioHeaderRow';
import PortfolioPositionsTable from '@root/components/portfolio/position/PortfolioPositionsTable';
import {PortfolioEntryContext} from '@root/components/portfolio/contexts/PortfolioEntryContext';
import {downloadFile} from "@global/FileDownloader";

import '../Portfolio.less';
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import {
    addNewPosition,
    deletePosition,
    getPortfolioExcel,
    orionRefresh,
    setMarketValue,
    setName,
    updateExistingPosition,
    updatePortfolioFromFile,
    addHistoricalCompositionFromFile,
    cancel
} from "@API/portfolio";


export const groupPositions = (positions) => {
    let sum_w = 0;
    let sum_mv = 0;
    return positions.reduce(function (acc, position) {
        let id = position.ticker
        if (acc.map.hasOwnProperty(id)) {
            acc.map[id].market_value += position.market_value
            acc.map[id].weight += position.weight
            acc.map[id].quantity += position.quantity
            acc.map[id]['position_indexes'].push(position['position_index'])
            acc.map[id]['lots'].push({...position})
            acc.map[id]['multilot'] = true
            sum_w += position.weight
            sum_mv += position.market_value
        } else {
            let newObj = Object.assign({}, position);
            acc.map[id] = newObj;
            newObj['position_indexes'] = [newObj['position_index']]
            newObj['lots'] = [{...position}]
            newObj['multilot'] = false
            sum_w += newObj.weight
            sum_mv += newObj.market_value
            acc.data.push(newObj);
        }
        return acc;
    }, {data: [], map: {}}).data;
}


const ExpandablePortfolioRow = ({portfolioInfo, type, showRisk=true}) => {
    const {removeOne, reloadContext} = useContext(PortfolioListContext);
    const [rowPortfolio, setRowPortfolio] = useState();

    const [positionsLoading, setPositionsLoading] = useState(false);
    const [headerLoading, setHeaderLoading] = useState(false);
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        return () => {
            cancel && cancel();
        }
    }, [])

    useEffect(() => {
        let portfolioProxy = {...portfolioInfo};
        let groupedPositions = groupPositions(portfolioProxy.positions)
        groupedPositions.sort((p1, p2) => {
            return p1.ticker.localeCompare(p2.ticker)
        })
        portfolioProxy.positions = groupedPositions
        //console.log(groupedPositions)
        setRowPortfolio(portfolioProxy)
    }, [portfolioInfo]);

    const addPosition = (position) => {
        setPositionsLoading(true)
        addNewPosition(rowPortfolio._id, position, (response, error) => {
            setPositionsLoading(false)
            if (!error) {
                console.log(`position ${position.position_index} was removed`)
                reloadContext();
            } else {
                response && message.error("Problem updating the position!")
            }
        });
    }

    const updatePosition = (position) => {
        setPositionsLoading(true)
        updateExistingPosition(rowPortfolio._id, position, (response, error) => {
            setPositionsLoading(false)
            if (!error) {
                console.log(`position ${position.position_index} was updated`)
                reloadContext();
            } else {
                response && message.error("Problem updating the position!")
            }
        });
    }

    const removePosition = (position) => {
        setPositionsLoading(true)
        let indexes = position['position_indexes'].join()

        if (position.multilot) {
            message.warning("Completely removing a multilot position!");
        }

        deletePosition(rowPortfolio._id, indexes, (response, error) => {
            setPositionsLoading(false)
            if (!error) {
                console.log(`position ${position.position_index} was removed`)
                reloadContext();
            } else {
                response && message.error("Problem removing the position!")
            }
        });
    }

    const changeMarketValue = (newMarketValue) => {
        setHeaderLoading(true)
        setMarketValue(rowPortfolio._id, newMarketValue, (response, error) => {
            setHeaderLoading(false);
            console.log("changeMarketValue " + response)
            if (error) {
                //response && message.error("Problem changing portfolio market value!")
                message.error("Error changing portfolio market value")

            }
            reloadContext();
        });
    }

    const changeTitle = (newTitle) => {
        setHeaderLoading(true)
        setName(rowPortfolio._id, newTitle, (response, error) => {
            setHeaderLoading(false);
            if (error) {
                message.error("Problem changing portfolio title!")
            }
            reloadContext();
        });
    }

    const orionLoad = () => {
        setHeaderLoading(true);
        orionRefresh(rowPortfolio._id, rowPortfolio.external_id, (response, error) => {
            setHeaderLoading(false);
            if (error) {
                response && message.error(response.data.message);
            }
            reloadContext();

        })
    }

    const portfolioExcelDownload = () => {
        setHeaderLoading(true);
        getPortfolioExcel(rowPortfolio._id, (response, error) => {
            if (error) {
                message.error("Error while creating excel file!");
                setHeaderLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `${rowPortfolio.name} (${new Date().toLocaleString()}).xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                setHeaderLoading(false);
            })
        })
    }


    const destroy = () => {
        setHeaderLoading(true);
        removeOne(rowPortfolio._id, () => {
            setHeaderLoading(false);
        })
    }

    const changePortfolioFromFile = (file) => {
        setPositionsLoading(true);
        setHeaderLoading(true);
        let uploadData = new FormData();
        uploadData.append("excel_file", file);
        uploadData.append("file_type", "portfolio_classic");
        updatePortfolioFromFile(rowPortfolio._id, uploadData, (reponse, error) => {
            setPositionsLoading(false);
            setHeaderLoading(false);
            if (error) {
                console.log(reponse)
                message.error(reponse.message);
                return
            }
            reloadContext();
        })
    }

    const addHistoricalComposition = (file, date) => {
        setPositionsLoading(true);
        setHeaderLoading(true);
        let uploadData = new FormData();
        uploadData.append("excel_file", file);
        uploadData.append("file_type", "portfolio_classic");
        uploadData.append("date", date)
        addHistoricalCompositionFromFile(rowPortfolio._id, uploadData, (reponse, error) => {
            setPositionsLoading(false);
            setHeaderLoading(false);
            if (error) {
                console.log(reponse)
                message.error(reponse.message);
                return
            }
            message.success(`Composition was added for ${rowPortfolio.name}`);
        })
    }

    if (rowPortfolio && isFinite(rowPortfolio?.calculated_values?.market_value)) {
        return (
            <PortfolioEntryContext.Provider value={{
                rowPortfolio: rowPortfolio,
                positions: rowPortfolio.positions,
                marketValue: rowPortfolio.calculated_values.market_value,
                calculated_values: rowPortfolio.calculated_values,
                destroy: () => destroy(),
                changeTitle: (title) => changeTitle(title),
                changeMarketValue: (nvm) => changeMarketValue(nvm),
                refreshFromOrion: () => orionLoad(),
                addPosition: (newP) => addPosition(newP),
                removePosition: (ptr) => removePosition(ptr),
                updatePosition: (up) => updatePosition(up),
                portfolioExcelDownload: () => portfolioExcelDownload(),
                updatePortfolioFromFile: changePortfolioFromFile,
                addHistoricalComposition: addHistoricalComposition
            }}>
                <div id={rowPortfolio._id} className="portfolio-table">
                    <PortfolioHeaderRow
                        expandProps={{expand, setExpand}}
                        type={type}
                        loading={headerLoading}
                        showRisk={showRisk}
                    />
                    {positionsLoading ?
                        <Spin size="large" className="positions-loading"/>
                        :
                        <div style={{padding: 6, paddingTop: 0}}>
                            <PortfolioPositionsTable
                                expand={expand}
                                positions={rowPortfolio.positions}
                                hideAdd={false}/>
                        </div>
                    }

                </div>
            </PortfolioEntryContext.Provider>
        )
    }
    return <></>
}

export default ExpandablePortfolioRow;
