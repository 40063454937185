import React from "react";
import {Col, Row, Statistic, Typography} from "antd";
import ChartStyles from "@global/ChartStyles";
import RiskIndicator from '@components/portfolio/RiskIndicator';

const {Title} = Typography;

const GeneralInfo = ({fullAsset, generalInfo}) => {
    return (
        <>
            <Row>
                {fullAsset.risk_score !== null &&
                <Col span={4}>
                    <RiskIndicator riskValue={fullAsset.risk_score} width={65}/>
                </Col>
                }
                <Col span={16}>
                    <Title level={4} style={{marginTop: 0}}>
                        {fullAsset.name} <br/>{ChartStyles.get_recommendation(fullAsset.sscore_plus, 19, 71)}
                    </Title>
                </Col>
            </Row>
            <br/>
            <Row gutter={[2, 10]}>
                {Object.keys(generalInfo).map((factor, inx) => {
                    if (factor in fullAsset && fullAsset[factor] !== null) {
                        let value = fullAsset[factor];
                        let factorInfo = generalInfo[factor];
                        if (factorInfo.formatter) {
                            value = factorInfo.formatter(fullAsset[factor]);
                        }
                        return (
                            <Col key={inx} span={8}>
                                <Statistic title={factorInfo.title} value={value}/>
                            </Col>
                        )
                    }
                })}
                {fullAsset.industry &&
                <Col span={24}>
                    <Statistic title={"Industry"} value={fullAsset.industry}/>
                </Col>
                }
            </Row>
        </>
    )
}

export default GeneralInfo
