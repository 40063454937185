import React from "react";
import {Card, Empty, Row} from "antd";
import {Link} from "react-router-dom";

const {Meta} = Card;

const TaskQueueSingleContainer = ({schedulerEntity, schedulerObjects, bodyStyle={}}) => {
    return (
        <Card
            title={`Running ${schedulerEntity}s`}
            bodyStyle={bodyStyle}
        >
            {schedulerObjects && schedulerObjects.length > 0 ?
                schedulerObjects.map((schedulerObj, inx) => {
                    return <Card
                        key={inx}
                        bordered={false}
                        bodyStyle={{padding: "12px"}}
                    >
                        <Meta
                            className="online_state-card-meta"
                            title={
                                <Link to={`administration/scheduler/${schedulerEntity}s/${schedulerObj._id}`}>
                                    <Row style={{fontSize: "0.8em"}}>{schedulerObj._id}</Row>
                                    <Row>
                                        <div style={{wordBreak:"break-word", width: "100%", 
                                            whiteSpace: "normal", lineHeight: "1.1"}}
                                        >
                                            {schedulerObj.title.replaceAll("_", " ")}
                                        </div>
                                    </Row>
                                </Link>
                            }
                            description={<span style={{color: "#249136"}}>running</span>}
                        />
                    </Card>
                })
                : <Empty description={`No running ${schedulerEntity}s`}></Empty>
            }
        </Card>
    )
}

export default TaskQueueSingleContainer