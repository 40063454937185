import React, {useCallback, useEffect, useRef, useState} from "react";
import {Checkbox, message, Space, Spin} from "antd";
import {getFlagReport} from "@API/supervision";
import ReportConstructor from "../report_constructor/ReportConstructor";
import RepresentativeSearch from "./RepresentativeSearch";
import "../Supervision.less";
import Localizator from "@global/Localizator";
import {SupervisionContext} from "../contexts/SupervisionContext";
import ConfigSelector from "../global_configs/ConfigSelector";
import {useAuth} from "@hooks/AuthHook";

// const configId = "634c0dbb2dbf10e5efa46bf1";

const RedFlagReport = () => {
    const auth = useAuth();
    const [globalRedFlags, setGlobalRedFlags] = useState(null);
    const [config, setConfig] = useState(null);
    const [reportLoading, setReportLoading] = useState(false);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    const [useClientConfig, setUseClientConfig] = useState(true);
    const abortControllerRef = useRef(new AbortController());

    const getGlobalReportWithParams = useCallback((configId, _useClientConfig, representative) => {
        setReportLoading(true);

        getFlagReport(configId, _useClientConfig, representative, (data, error) => {
            setReportLoading(false);
            if (error) {
                message.error("Error while retrieving global supervision config");
                return
            }
            setGlobalRedFlags(data.children);
        }, abortControllerRef.current.signal)
    }, [setGlobalRedFlags])

    const contextGetGlobalReportWithParams = useCallback(() => {
        if (config) {
            getGlobalReportWithParams(config.id, useClientConfig, selectedRepresentative);
        }
    }, [getGlobalReportWithParams, selectedRepresentative, config, useClientConfig])

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    // useEffect(() => {
    //     if (config) {
    //         setUseClientConfig(config.use_client_config);
    //     }
    // }, [config])

    useEffect(() => {
        if (config !== null && useClientConfig !== null) {
            getGlobalReportWithParams(config.id, useClientConfig, selectedRepresentative);
        }
    }, [selectedRepresentative, config, useClientConfig])

    return (
        <div className="supervision-container">
            <Space direction={'horizontal'} style={{marginBottom: 24}} size={15}>
                {auth.isSupervisor() && <div style={{width: 300}}>
                    <RepresentativeSearch selectedRepresentative={selectedRepresentative}

                                          setSelectedRepresentative={setSelectedRepresentative}
                    />
                </div>}
                <div style={{width: 300}}>
                    <ConfigSelector setSelectedConfig={setConfig}/>
                </div>
                <Checkbox checked={useClientConfig} onChange={e => setUseClientConfig(e.target.checked)}>
                    Use clients' limits
                </Checkbox>
            </Space>
            
            <Spin size="large" style={globalRedFlags ? {} : {height: "300px"}}
                  spinning={reportLoading} tip="Loading the report..."
            >
                {globalRedFlags && <SupervisionContext.Provider value={{
                    updateData: contextGetGlobalReportWithParams,
                    configState: {configId: config.id, useClientConfig: useClientConfig}
                }}>
                    <ReportConstructor data={globalRedFlags} type={'flag'}
                                       description={Localizator.t('supervision.description.summary')}/>
                </SupervisionContext.Provider>}
            </Spin>
        </div>
    )
}

export default RedFlagReport;
