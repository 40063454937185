import {Col, Divider, Layout, Form, Row, Select, Typography} from "antd";
import ClientChat from "@holistico/proposal/view/ai/ClientChat";
import AiConfiguration from "@holistico/proposal/view/ai/AiConfiguration";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ConfigurationForm from "@holistico/proposal/view/ai/ConfigurationForm";
import React, {useState} from "react";
import Sider from "antd/es/layout/Sider";

const ConfiguredChat = ({}) => {
    const {id, aiSettings, modifyAiSettings} = useHolisticoProposalContext()
    const [siderCollapsed, setSiderCollapsed] = useState(true);
    return <Layout style={{minHeight: '100vh'}}>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Ai Assistant
            </Typography.Title>
        </div>
        <Row gutter={[16, 16]}>
            <Col span={16}>
                <ClientChat/>
            </Col>
            <Col span={8}>
                <div className={'page-content'}>
                    <ConfigurationForm modifyAiSettings={modifyAiSettings} aiSettings={aiSettings}/>
                </div>
            </Col>
        </Row>
    </Layout>
}

export default ConfiguredChat;
