import React from "react";
import {Typography} from 'antd';
import DataImport from "@components/data_import/DataImport";
import AssetDataExport from "@components/data_export/AssetDataExport";

const AssetData = () => {
    return (
        <div>
            <Typography.Title level={3}>Asset data</Typography.Title>
            <div style={{margin: "25px 0"}}>
                <AssetDataExport/>
            </div>
            <div>
                <DataImport/>
            </div>
        </div>

    )
}

export default AssetData
