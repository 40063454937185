/* eslint-disable react/prop-types */
import React from 'react';
import PortfolioComposition
    from "@holistico/proposal/view/portfolios/PortfolioComposition";
import {Typography} from "antd";
import {
    HideAiButton,
    ShowAiButton
} from "@holistico/proposal/view/ai/AiSideBar";


const ClientPortfolioComposition = () => {
    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Client portfolio
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            <PortfolioComposition bucketType={"cur"}/>
        </div>
    </>
}

export default ClientPortfolioComposition;
