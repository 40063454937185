/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import {Col, Row} from "antd";
import AssetLoader from "@components/asset/AssetLoader";
import {useSearchParams} from "react-router-dom";

const KEYS_TO_PARAMS = ['ticker', 'gvkey', 'gvkeyx', 'iid', 'secid', 'security_type', 'definition_id']
const SEPARATOR = "__"
const assetFromQuery = (index, params) => {
    let asset = null
    let thisIndexKeys = Object.keys(params).filter(key => key.includes(SEPARATOR+index))
    if (thisIndexKeys.length > 0) {
        asset = {}
        for (let key of thisIndexKeys) {
            asset[key.split(SEPARATOR + index)[0]] = params[key]
        }
    }
    console.debug(JSON.stringify(params) + '[' + index + '] -> ' + JSON.stringify(asset))
    return asset
}

const AssetComparator = () => {
    const [assets, setAssets] = useState([null, null])
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        let params = Object.fromEntries(searchParams)
        let newAssets = [...assets]
        for (let index = 0; index < assets.length; index++) {
            let qAsset = assetFromQuery(index, params)
            if (!qAsset) {
                continue
            }
            for (let param of KEYS_TO_PARAMS) {
                if (assets[index] == null || qAsset[param] != assets[index][param]) {
                    newAssets[index] = qAsset
                    break
                }
            }
        }
        setAssets(newAssets)
    }, [searchParams])

    const getColumnWidth = () => {
        return (100 / assets.length + '%')
    }

    const addAssetColumn = () => {
        let newAssets = [...assets]
        newAssets.push(null)
        setAssets(newAssets)
    }


    return (
        <>
            <Row gutter={[24, 24]}>
                {assets.map((asset, index) => {
                    return <Col style={{width: getColumnWidth()}} key={index}>
                        <AssetLoader asset={asset} index={index}/>
                    </Col>
                })}
            </Row>
        </>
    );
}

export default AssetComparator;
