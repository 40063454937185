/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import {cancel} from "@API/portfolio";
import {Table, Typography} from "antd";
import {getPortfolioFactors} from "@API/factor";

const PortfolioFactors = () => {
    const [showAddFactor, setShowAddFactor] = useState(false);
    const [factors, setFactors] = useState([]);
    const [loadingFactors, setLoadingFactors] = useState(true);
    const {Title} = Typography;

    const columns = [
        {
            title: 'Column',
            dataIndex: 'column_name',
            render: text => <a>{text}</a>,
            sorter: (a, b) => a.column_name.localeCompare(b.column_name),
        },
        {
            title: 'Name',
            dataIndex: 'full_name'
        }, {
            title: 'Order',
            dataIndex: 'processing_order',
            sorter: (a, b) => a.processing_order - b.processing_order,
        },
        {
            title: 'Value Type',
            dataIndex: 'value_type'
        }]

    useEffect(() => {
        fetchFactorData();

        return () => {
            cancel && cancel()
        }
    }, []);

    const factorsHandler = (factors) => {
        setFactors(factors);
        setTimeout(() => setLoadingFactors(false), 1000);
    }

    const fetchFactorData = () => {
        setLoadingFactors(true)
        setShowAddFactor(false)
        getPortfolioFactors(factorsHandler);
    }
    return (<div>
            <Title level={3}>Portfolio factors</Title>
            <Table loading={loadingFactors} size="small" columns={columns} dataSource={factors} rowKey={'_id'}
                   pagination={false}
                   expandable={{
                       expandedRowRender: record => <pre style={{margin: 0}}>{record.formula}</pre>
                   }}/>
        </div>
    );
}

export default PortfolioFactors;
