/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined} from '@ant-design/icons';
import {Dropdown, Menu, message, Spin} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {cancel as deletionCancel, deleteClient} from '@API/clients';

import './ClientsList.less';

const ClientRowMenu = ({edit, id}) => {
    const [headerLoading, setHeaderLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            deletionCancel && deletionCancel();
        }
    }, [])

    const clearEvent = (e) => {
        e.domEvent.stopPropagation()
        e.domEvent.preventDefault()
    }

    const deleteClientCallback = (response_message, error) => {
        if (error) {
            message.error(response_message);
            setHeaderLoading(false);
        } else {
            message.success(response_message);
            setHeaderLoading(false);
            navigate("/");
        }
    }

    const deleteClientOnClick = () => {
        setHeaderLoading(true);
        deleteClient(id, deleteClientCallback)
    }

    const menu = (
        <Menu>
            <Menu.Item key="report" onClick={(e) => {
                clearEvent(e)
            }}>
                <Link to={"proposal/" + id + "/report"}>
                    <DownloadOutlined/>
                    &nbsp;PDF Report
                </Link>
            </Menu.Item>
            <Menu.Item key="edit" disabled={false} onClick={(e) => {
                clearEvent(e)
                edit()
            }}>
                <EditOutlined/>
                &nbsp;Edit
            </Menu.Item>
            <Menu.Item key="delete" style={{color: '#ad2102'}}
                       onClick={(e) => {
                           clearEvent(e)
                           deleteClientOnClick()
                       }}>
                <DeleteOutlined/>
                &nbsp;Remove
            </Menu.Item>
        </Menu>
    );

    if (!id) {
        return null
    }

    return (
        headerLoading ? <Spin /> 
        : <div className={"portfolio-title-menu"}>
            <Dropdown trigger={['click']} overlay={menu}>
                <EllipsisOutlined onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}/>
            </Dropdown>
        </div>
    );
}

export default ClientRowMenu;
