/* eslint-disable react/prop-types */
import React from 'react';
import {Flex, Radio} from "antd";


const CardTabSelect = ({options, activeTab, setActiveTab}) => {
    return <Flex align={"center"} style={{
        margin: "16px -24px -24px -24px",
        padding: "16px 24px 24px 24px",
        borderTop: "1px #e3e3e3 solid",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        background: "#fafafa",
    }}>
        <span style={{
            fontSize: 18,
            fontWeight: 500,
            marginRight: 16
        }}>
            Display:
        </span>
        <Radio.Group
            options={options}
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
        />
    </Flex>
}

export default CardTabSelect;
