import React, {useEffect, useState} from 'react';
import {Col, Flex, message, Row, Select} from 'antd';
import {listProxies} from "@API/asset";


const {Option, OptGroup} = Select;


const PositionProxy = ({value, onChange, thisRef, nextRef}) => {
    const [defaultProxyOptions, setDefaultProxyOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        listProxies((proxies, error) => {
            if (!error) {
                console.log(proxies)
                setDefaultProxyOptions(proxies);
                setLoading(false);
            } else {
                message.error("Failed to load default proxies!");
                setLoading(false);
            }
        })
    }, []);

    const onChangeProxy = proxyTicker => {
        console.log('now proxy is ' + proxyTicker)
        let newProxy = defaultProxyOptions.filter(proxy => proxyTicker === proxy.ticker)[0];
        onChange(newProxy);
        nextRef?.current?.focus();
    }

    return <>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Select
                    value={value?.ticker}
                    placeholder="Proxy"
                    onChange={onChangeProxy}
                    style={{width: '100%'}}
                    showAction={['focus', 'click']}
                    ref={thisRef}
                    loading={loading}
                >
                    <OptGroup label="Default proxies">
                        {defaultProxyOptions.map(proxy => (
                            <Option key={proxy.ticker}>
                                <Flex>
                                    <b style={{minWidth: 60}}>
                                        {proxy.ticker}
                                    </b>
                                    {` ${proxy.security_type} (${proxy.label})`}
                                </Flex>
                            </Option>
                        ))}
                    </OptGroup>
                    <OptGroup label="No proxy">
                        <Option key={null}>None</Option>
                    </OptGroup>
                </Select>
            </Col>
        </Row>
    </>
}

export default PositionProxy;
