import React, {useContext, useState} from 'react';
import {Spin, Tabs} from 'antd';

import Questionnaire from "@root/components/questionnaire/Questionnaire";
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import ClientQuestionnaireView from "@components/questionnaire/ClientQuestionnaireView";
import {EditOutlined, FolderViewOutlined} from "@ant-design/icons";

const ClientQuestionnaire = () => {
    const {client} = useContext(SpasClientContext);
    const [questionnaire, setQuestionnaire] = useState(client['questionnaire']);
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const next = () => {
        const newStep = step + 1
        setStep(step + 1);
    }

    const prev = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    }

    return (
        loading ? <div style={{height: 300}} className="loadingBox"><Spin size="large"></Spin></div> :
            <div>
                {questionnaire.risk ? <Tabs  type="card">
                    <Tabs.TabPane tab={<span><FolderViewOutlined />View</span>} key="view">
                        <ClientQuestionnaireView questionnaire={questionnaire}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span><EditOutlined />Edit</span>} key="edit">
                        <Questionnaire questionnaire={questionnaire}/>
                    </Tabs.TabPane>
                </Tabs> : <Questionnaire questionnaire={questionnaire}/>}
            </div>
    )
}

export default ClientQuestionnaire;
