import moment from "moment/moment";

const WEEKDAY_FORMAT = 'ddd, MMM DD, HH:mm';
const SHORT_FORMAT = 'MMM DD, HH:mm';

const dddMMMDDHHmm = (utcDateString, defaultValue = "-") => {
    if (utcDateString == null) {
        return null
    }
    try {
        let localResult = moment.utc(utcDateString).local().format(WEEKDAY_FORMAT)
        return localResult
    } catch (e) {
        console.error(e)
        return defaultValue
    }
}

const MMMDDHHmm = (utcDateString, defaultValue = "-") => {
    if (!utcDateString) {
        return defaultValue
    }
    try {
        let localResult = moment.utc(utcDateString).local().format(SHORT_FORMAT)
        return localResult
    } catch (e) {
        console.error(e)
        return defaultValue
    }
}

export {dddMMMDDHHmm,MMMDDHHmm}