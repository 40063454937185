/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {List, Progress} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const creditRatingSortOrder = {
    "AAA": 1,
    "AA": 2,
    "A": 3,
    "BBB": 4,
    "BB": 5,
    "B": 6,
    "Below B": 7
}


const CreditRatingBar = ({allocation}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!allocation) {
            setData([])
            return
        }
        const nonZeroKeys = Object.keys(allocation).filter(key => allocation[key])
        const skip = nonZeroKeys.length === 0
        if (skip) {
            setData([])
            return
        }
        let _data = Object.keys(allocation).map(key => {
            return {
                label: key,
                value: allocation[key]
            }
        })
        if (_data === data) {
            return
        }
        _data.sort(function (a, b) {
            return (creditRatingSortOrder[a.label] - creditRatingSortOrder[b.label])
                || a.label.localeCompare(b.label);
        });
        setData(_data)
    }, [allocation])


    return <div style={{minWidth: 350}} className={"fade-in-500"}>
        {data.length > 0
            ?
            <List
                size="small"
                dataSource={data}
                renderItem={item =>
                    <List.Item
                        className={"custom-legend-item"}
                        style={{height: 32}}
                    >
                        <div style={{
                            minWidth: "60px",
                            fontSize: 14
                        }}>
                            {item['label']}
                        </div>
                        <Progress
                            size="small"
                            percent={item["value"] * 100}
                            strokeColor={ChartStyles.get_rating_color(item["label"])}
                            format={percent => {
                                return ValueFormatter.oneDigitPercent(percent / 100)
                            }}
                            showInfo={false}
                        />
                        <div className={"value"} style={{
                            minWidth: "50px",
                            marginLeft: "8px",
                            fontSize: 14
                        }}>
                            {ValueFormatter.oneDigitPercent(item["value"])}
                        </div>
                    </List.Item>
                }
            />
            :
            <NoDataPlaceholder description={"No credit rating information"}/>
        }
    </div>
}

export default CreditRatingBar;