import React from "react";
import {Route, Routes} from "react-router-dom";
import UserMenu from "./UserMenu";
import UsersList from "./UsersList";
import AdminNavigation from "../AdminNavigation";
// import {Typography} from "antd";
// import AdminMain from "@components/admin_panel/AdminMain";

// const {Title} = Typography;

const UserRouter = () => {
    return (
        <>
            <AdminNavigation route={location.pathname}/>
            <Routes>
                <Route exact path="" element={<UsersList/>}/>
                <Route path=":id/*" element={<UserMenu/>} />
            </Routes>
        </>
    )
}

export default UserRouter
