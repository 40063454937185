import React, {useEffect, useState} from 'react';
import {Typography} from 'antd';
const { Title, Paragraph, Text } = Typography;
import StressTable from "@components/holistico/proposal/view/stress_tests/StressTable";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    HideAiButton,
    ShowAiButton
} from "@holistico/proposal/view/ai/AiSideBar";
import SectionDescriptionEllipsis from "@holistico/aux/SectionDescription";


export const stressTestsDescription = <>
    <Paragraph>
        Portfolio stress tests are of paramount importance for the following
        reasons:
    </Paragraph>
    <Paragraph>
        1. Risk Assessment: Stress tests allow investors to evaluate their
        portfolios under extreme but plausible market conditions. They provide a
        measure of how much value a portfolio could lose in such scenarios. This
        helps investors understand the level of risk they are assuming.
    </Paragraph>
    <Paragraph>
        2. Portfolio Resilience: By simulating various adverse market
        conditions, stress tests assess the resilience of a portfolio. They help
        determine whether the portfolio can withstand market turbulence and
        maintain its value.
    </Paragraph>
    <Paragraph>
        3. Asset Allocation: Stress testing can guide asset allocation decisions
        by indicating which assets could potentially lead to significant losses
        in a downturn. This can help investors adjust their portfolio to
        mitigate these risks.
    </Paragraph>
    <Paragraph>
        4. Regulatory Compliance: For institutional investors, stress tests are
        often a regulatory requirement. These tests ensure that the institution
        can endure market shocks without posing a risk to the financial system.
    </Paragraph>
    <Paragraph>
        In essence, stress tests are a potent tool for understanding and
        managing portfolio risk. They provide critical insights into how a
        portfolio could perform under severe market conditions, informing
        strategies to enhance portfolio resilience.
    </Paragraph>
</>



const StressTests = () => {
    const {proposal} = useHolisticoProposalContext()
    const [stressItems, setStressItems] = useState([]);

    useEffect(() => {
        let stress_results = proposal?.p_bucket_cur?.calculated_values?.risk?.stress_test?.filter(s => !s.auxiliary)
        let proposed_stress_results = proposal?.p_bucket_prop?.calculated_values?.risk?.stress_test?.filter(s => !s.auxiliary)
        let market_value = proposal?.p_bucket_cur?.calculated_values['market_value']

        if (!stress_results) {
            return
        }

        let proposed_stress_map = {}

        if (proposed_stress_results) {
            proposed_stress_map = Object.fromEntries(
                proposed_stress_results.map(item => [item.period_label, item])
            )
        }

        let si = []

        for (let client_result of stress_results) {
            const label = client_result['period_label']
            let proposed_data = null
            if (proposed_stress_map.hasOwnProperty(label)) {
                proposed_data = proposed_stress_map[label]
            }

            let stress_item = {
                period_label: label,
                date: client_result.start + ' — ' + client_result.end,
                title: label,
                benchmarkInfo: {
                    title: client_result.benchmark_label,
                    portfolioReturn: client_result.benchmark_return / 100,
                    dollarPortfolioReturn: (client_result.benchmark_return / 100) * market_value,
                }, portfolioInfo: {
                    title: 'Current Portfolio',
                    portfolioReturn: client_result.portfolio_return / 100,
                    dollarPortfolioReturn: (client_result.portfolio_return / 100) * market_value,
                }
            }

            if (proposed_data) {
                stress_item['proposalInfo'] = {
                    title: 'Proposed Portfolio',
                    portfolioReturn: proposed_data.portfolio_return / 100,
                    dollarPortfolioReturn: proposed_data.portfolio_return / 100 * market_value,
                }
            } else {
                stress_item['proposalInfo'] = null
            }
            console.log("----------")
            console.log(stress_item)
            si.push(stress_item)
        }


        setStressItems(si)


    }, [proposal])



    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Stress tests
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            <SectionDescriptionEllipsis>
                {stressTestsDescription}
            </SectionDescriptionEllipsis>
            <StressTable stressItems={stressItems}/>
        </div>
    </>
}

export default StressTests;
