/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Card, Checkbox, Col, Input, Row, Select, Typography} from 'antd';

const {TextArea} = Input;
const {Paragraph, Text, Title} = Typography;
const {Meta} = Card;
const {Option} = Select;

const QStepOne = ({goals = [], setGoals}) => {
    const {id} = useParams();

    const [questionnaire, setQuestionnaire] = useState({step: 0});
    const [loading, setLoading] = useState(false);

    const options = [
        {label: 'Retirement', value: 'Retirement'},
        {label: 'College Savings', value: 'College'},
        {label: 'Income', value: 'Income'},
        {label: 'Debt', value: 'Debt'},
        {label: 'Wealth Accumulation', value: 'Wealth'},
        {label: 'Other', value: 'Other'},
    ];

    function handleChange(value) {
        setGoals(value)
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Title level={3}>What are your financial goals?</Title>
            <Checkbox.Group defaultValue={goals} style={{width: '100%'}} onChange={handleChange}>
                <Row>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="Retirement">Save for Retirement</Checkbox>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="College Savings">Save for college</Checkbox>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="Income">Generate income</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="Debt">Pay off debt</Checkbox>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="Wealth Accumulation">Accumulate wealth</Checkbox>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Checkbox value="Other">Other</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group></>

    )
}

export default QStepOne;
