import React, {useCallback, useEffect, useState} from "react";
import {cancel, deleteUser, editUser} from "@API/users";
import {useNavigate} from 'react-router-dom';
import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, KeyOutlined} from '@ant-design/icons';

import "./Users.less";
import ChangePasswordModal from "@components/admin_panel/users/ChangePasswordModal";
import {getAuthConfiguration} from "@API/configuration";


const {Option} = Select;
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 12},
};

const UserDetails = ({user, getUser}) => {
    const [configuration, setConfiguration] = useState({available_roles: [], available_associates: []})
    const [loading, setLoading] = useState(false);

    const [passwordModal, setPasswordModal] = useState(false);

    const [userForm] = Form.useForm();
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        loadConfiguration();
        return () => {
            cancel && cancel();
        }
    }, [])

    const removeUser = useCallback(() => {
        deleteUser(user.id, (resp, error) => {
            if (error) {
                message.error(resp);
                return
            }
            message.success(`${user.email} was deleted successfully`);
            navigate("../../users");
        })
    }, [user])

    const loadConfiguration = useCallback(() => {
        getAuthConfiguration((configuration, error) => {
            if (error) {
                message.error("Error while retrieving user data" + error);
                return
            }
            setConfiguration(configuration);
            setLoading(false);
        })
    }, [])

    const retrieveUserDetails = useCallback(() => {
        getUser();
        setTimeout(() => setLoading(false), 500)
    }, [getUser])

    const userFormSave = useCallback((values) => {
        console.log(values)
        setLoading(true);
        let editedUser = Object.assign(user, values);
        editUser(editedUser, (resp, error) => {
            if (error) {
                setLoading(false);
                message.error(resp);
                return
            }
            message.success(`${user.email} was updated successfully`);
            retrieveUserDetails();
        })
    }, [user])

    const StaticDetails = () => {
        return <Descriptions layout={"horizontal"} column={1}>
            <Descriptions.Item
                label="Last seen"
                key={"last_activity"}>{new Date(user.last_activity).toLocaleString("en-US")}</Descriptions.Item>
            <Descriptions.Item
                label="Last modified"
                key={"last_modification"}>{new Date(user.last_modification).toLocaleString("en-US")}</Descriptions.Item>
        </Descriptions>
    }

    return (
        <Spin size="large" spinning={loading}>
            <Typography.Title level={4} className={'spas'}>{"User Details"}</Typography.Title>
            {user ?
                <div style={{width: '100%', marginTop: "24px"}}>
                    <Card loading={loading}>
                        <Row>
                            <Col span={4}>
                                <Avatar size={160} src={user.avatar}
                                        style={{backgroundColor: '#E0AD60', fontSize: 50}}>U</Avatar>
                                <div
                                    style={{width: 160, height: 160, borderRadius: "50%"}} className="ant-image-mask"
                                    onClick={() => console.log("kek")}
                                >Change avatar
                                </div>
                            </Col>
                            <Col span={12}>
                                <span
                                    style={{fontSize: "60px", fontWeight: "bolder"}}>{user.email}</span>
                                <StaticDetails/>
                            </Col>
                        </Row>
                        <Form {...layout}
                              initialValues={user}
                              form={userForm}
                              name="user-details"
                              onFinish={userFormSave}
                              labelAlign={"left"}
                              size={"large"}
                              requiredMark={false}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item name={['details', 'meta', 'full_name']} label="Full Name"
                                               rules={[{required: true}]}>
                                        <Input/>

                                    </Form.Item>
                                    <Form.Item
                                        name={['details', 'roles']}
                                        label={`Roles`}
                                        rules={[{required: true}]}
                                    >
                                        <Select mode="tags" maxTagCount={'responsive'}>
                                            {configuration.available_roles.map(role => {
                                                return <Option value={role} key={role}/>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name={'associates'} label={"Associates"}
                                               tooltip={"This users' clients will be seen while searching on the client page. To view not owned clients the 'head' role is needed."}>
                                        <Select mode="tags" maxTagCount={'responsive'}>
                                            {configuration.available_associates.map(user => {
                                                return <Option value={user} key={user}/>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                    <Row style={{marginTop: "20px"}}>
                        <Alert
                            message="Change password"
                            style={{width: "100%", fontSize: "1.2em"}}
                            showIcon
                            icon={<KeyOutlined/>}
                            type="error"
                            action={
                                <Button danger onClick={() => setPasswordModal(true)} style={{width: 100}}>
                                    Change
                                </Button>
                            }
                        />
                        <Alert
                            message="Delete user"
                            style={{width: "100%", fontSize: "1.2em", marginTop: "20px"}}
                            showIcon
                            icon={<DeleteOutlined/>}
                            type="error"
                            action={
                                <Popconfirm
                                    title="Sure to remove the user?"
                                    onConfirm={removeUser}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger style={{width: 100}}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            }
                        />
                    </Row>
                    <ChangePasswordModal user={user} hide={() => setPasswordModal(false)} visible={passwordModal}/>
                </div>
                :
                <div style={{height: "400px"}}></div>
            }
        </Spin>
    )
}

export default UserDetails
