/* eslint-disable react/prop-types */
import React from 'react';
import {DatabaseOutlined} from '@ant-design/icons';
import {Card, Col, Row, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import {NavLink} from "react-router-dom";
import PrognosisSlider from "./PrognosisSlider";
import RiskIndicator from "./RiskIndicator";
import './Portfolio.less';
import AssetClassPie from "@charts/AssetClassPie";

const {Paragraph, Text, Title} = Typography;

const {Meta} = Card;
/**
 *
 * @param rId id for the chart
 * @param link route to when clicked
 * @param calculated_values riskPlannedClient.assetDetails or riskPlannedClient.proposedAssetDetails
 * @param title
 * @param loading if is loading currently
 * @returns {JSX.Element} Card containing basic portfolio summary info
 * @constructor
 */
const PortfolioCard = ({link, calculated_values = {}, title, loading}) => {
    return (
        <NavLink to={link} relative={"path"}>
            <Card hoverable loading={loading}>
                <Meta
                    avatar={
                        <RiskIndicator riskValue={calculated_values?.risk?.risk_score} width={70}/>
                    }
                    title={title}
                    description={ValueFormatter.currency(calculated_values['market_value'])}
                />
                {(calculated_values?.market_value) ?
                    <div style={{marginTop: "15px"}}>
                        <Row responsive="true">
                            <Col span={24}>
                                <Row>
                                    <Col span={18}>
                                        <Paragraph className={"portfolio-title-secondary"}>Potential Annual
                                            Return</Paragraph></Col>
                                    <Col span={6} style={{textAlign: 'right'}}>
                                        {ValueFormatter.percent(calculated_values?.risk?.projected_return_1yr / 100)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={18}><Paragraph className={"portfolio-title-secondary"}>Annual
                                        Dividend</Paragraph></Col>
                                    <Col span={6} style={{textAlign: 'right'}}>
                                        {ValueFormatter.percent(calculated_values.annual_dividend / 100)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={18}><Paragraph className={"portfolio-title-secondary"}>Expense
                                        Ratio</Paragraph></Col>
                                    <Col span={6} style={{textAlign: 'right'}}>
                                        {ValueFormatter.percent(calculated_values.expense_ratio / 100)}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <div style={{
                                    lineHeight: 1.57,
                                    fontVariant: 'tabular-nums',
                                    color: '#5b8c00',
                                    fontSize: 16
                                }}>Prospective Volatility, 6 months
                                </div>
                                <PrognosisSlider vertical={false} currentValue={calculated_values.market_value}
                                                 risk_calculations={calculated_values['risk']}
                                                 title={"95% probability"}/>
                            </Col>
                        </Row>
                        <AssetClassPie loading={loading} allocation={calculated_values['asset_class']}/>
                    </div> : <div className="noDataBox">
                        <DatabaseOutlined style={{fontSize: '2em'}}/>
                        <div>{`No portfolios yet.`}</div>
                    </div>}

            </Card>
        </NavLink>
    );
}

export default PortfolioCard;
