import {Descriptions} from "antd";
import ValueFormatter from "@global/ValueFormatter";

const getQValue = (questionnaire, key) => {
    let value = questionnaire[key]
    if (value) {
        if (typeof value == 'string') {
            return value.toLowerCase()
        } else {
            return value
        }
    } else {
        return 'N/A'
    }
}
const ClientQuestionnaireView = ({questionnaire}) => {

    const BucketData = ({bucket}) => {
        if (bucket?.calculated_values?.market_value > 0){

        } else {
            return null
        }
    }
    const QuestionnaireData = () => {
        let goalsString = questionnaire.goals.join(', ').toLowerCase()
        return <Descriptions title="Questionnaire"
                             layout={'horizontal'}
                             size={'small'}
                             column={1}
                             labelStyle={{width: '30%'}}
                             bordered
        >
            <Descriptions.Item label="Portfolio goals">{goalsString}</Descriptions.Item>
            <Descriptions.Item label="Birth year">{getQValue(questionnaire, 'birth_year')}</Descriptions.Item>
            <Descriptions.Item label="Health status">{getQValue(questionnaire, 'hstatus')}</Descriptions.Item>
            <Descriptions.Item label="Martial status">{getQValue(questionnaire, 'mstatus')}</Descriptions.Item>
            <Descriptions.Item label="Working status">{getQValue(questionnaire, 'wstatus')}</Descriptions.Item>
            <Descriptions.Item label="Retirement year">{getQValue(questionnaire, 'retirement_year')}</Descriptions.Item>
            <Descriptions.Item label="Life expectancy">{getQValue(questionnaire, 'life_expectancy')}</Descriptions.Item>
            <Descriptions.Item label="Desired risk"><b>{getQValue(questionnaire, 'risk')}</b></Descriptions.Item>
            <Descriptions.Item label="Current fee">{getQValue(questionnaire, 'current_fee')}%</Descriptions.Item>
            <Descriptions.Item
                label="Primary Investment amount">{ValueFormatter.currency(getQValue(questionnaire, 'investment_amount'))}</Descriptions.Item>
            <Descriptions.Item label="Additional info">{getQValue(questionnaire, 'additional')}</Descriptions.Item>
        </Descriptions>
    }
    return <div>
        <QuestionnaireData/>
    </div>
}

export default ClientQuestionnaireView
