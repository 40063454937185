import {List, Space, Spin, Typography} from "antd";
import React from "react";


const ChatMessageContent = ({index, role, content, status}) => {
    if (typeof content === 'string' && role === 'user') {
        return <Typography.Text className={"chat-msg chat-msg-usr"}>
            {content}
        </Typography.Text>
    } else if (typeof content === 'string' && role === 'assistant') {
        return <Typography.Text copyable={status==="success"} className={"chat-msg chat-msg-ai"}>
            {content}
        </Typography.Text>
    } else if (typeof content === 'string' && role === 'assistant-thinking') {
        return <Space className={"chat-msg chat-msg-ai chat-msg-ai-thinking"}>
            <Spin size="small" className={"white-spinner"} />
            <Typography.Text>
                {content}
            </Typography.Text>
        </Space>
    } else {
        return content ?? ""
    }
}


const ChatMessage = ({index, role, content, status}) => {
    return <>
        {
            !!content && content !== ""
            &&
            <List.Item style={{border: "none"}}>
                <ChatMessageContent
                    content={content}
                    role={role}
                    index={index}
                    status={status}
                />
            </List.Item>
        }
    </>
}


export default ChatMessage;
