/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import {Card, Image, Table, Typography} from "antd";
import ValueFomatter from "@global/ValueFormatter";
import './StressInfo.less';
import Localizator from "@global/Localizator";

const {Meta} = Card;

const getPerformanceData = (dollarReturn, percentReturn, benchmark_label) => {
    let formattedDollars = ValueFomatter.compactCurrency(Math.abs(dollarReturn))
    let formattedPercent = ValueFomatter.percent(percentReturn, 0)

    let colorClass = 'stress-positive'
    if (percentReturn > 0) {
        formattedPercent = '+' + formattedPercent
    } else if (percentReturn < 0) {
        colorClass = 'stress-negative'
    }

    if (benchmark_label) {
        let shortLabel = benchmark_label.split(' ').pop()
        return <><Typography.Title level={4}
                                   className={colorClass}>{formattedPercent} / {formattedDollars} {shortLabel} </Typography.Title></>
    } else {
        return <Typography.Title level={4}
                                 className={colorClass}> {formattedPercent} / {formattedDollars}</Typography.Title>
    }


}
const StressTable = ({
                         stressItems
                     }) => {

    const columns = [
        {
            title: '',
            dataIndex: 'icon',
            width: 64,
            render: (nothing, record) => {
                let scenText = record['period_label']
                let imgPath = '/img/bull.png'
                let color = 'rgb(134, 189, 145)'
                if (scenText.includes('bear')) {
                    imgPath = '/img/bear.png'
                } else if (scenText.includes('crisis')) {
                    color = '#f5222d;'
                    imgPath = '/img/crisis.png'
                } else if (scenText.includes('interest')) {
                    imgPath = '/img/interest.png'
                } else if (scenText.includes('crash')) {
                    color = '#f5222d;'
                    imgPath = '/img/virus_crash.png'
                }
                return <Image
                    height={60}
                    width={60}
                    style={{opacity: 0.85}}
                    preview={false}
                    src={imgPath}
                />
            }
        },
        {
            title: 'Scenario',
            dataIndex: 'period_label',
            render: (text) => {
                const formattedTitle = Localizator.t('stress.' + text)
                return <Typography.Title level={4}
                                         style={{
                                             fontSize: 24,
                                             marginBottom: 0
                                         }}>{formattedTitle}</Typography.Title>
            }
        },
        {
            title: 'Index ETF',
            dataIndex: 'benchmark',
            render: (nothing, record) => {
                let info = record['benchmarkInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'], info['title'])
            }
        },
        {
            title: 'Portfolio',
            dataIndex: 'portfolio',
            render: (nothing, record) => {
                let info = record['portfolioInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'])
            }
        },
    ];

    if (stressItems.length && stressItems[0].proposalInfo) {
        columns.push({
            title: 'Proposal',
            dataIndex: 'proposal',
            render: (nothing, record) => {
                let info = record['proposalInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'])
            }
        })
    }


    return <div style={{width: '100%', background: '#fff', padding: 16}}>
        <Typography.Title level={3} className={'spas'}>Stress Tests</Typography.Title>
        <Table
            title={() => 'The table below demonstrates how your portfolio would behave in different situations. This analysis helps you understand potential impacts to your portfolio during selected market events. The results of each scenario include an estimated portfolio yield, this is represented by a 12 month trailing yield.'}
            rowKey='period_label'
            pagination={false}
            columns={columns}
            className={'stress'}
            bordered={false}
            dataSource={stressItems}/>
    </div>
}

export default StressTable
