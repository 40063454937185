import React, {useState} from "react";
import {Col, Flex, Row, Grid} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import EnrollmentCard from "@components/retail/portfolio/EnrollmentCard";
import AccountsCard from "@components/retail/portfolio/AccountsCard";
import RiskProfileCard from "@components/retail/portfolio/RiskProfileCard";
import OverviewCard from "@components/retail/portfolio/OverviewCard";
import AnalyticsCard from "@components/retail/portfolio/AnalyticsCard";
import StressTestCard from "@components/retail/portfolio/StressTestCard";
import RetirementMapCard from "@components/retail/portfolio/RetirementMapCard";
import AiChatPanel from "@components/retail/portfolio/AiChatPanel";
import PositionsCard from "@components/retail/portfolio/PositionsCard";
import {useUpdateEffect} from "ahooks";
import {useParams} from "react-router-dom";


const {useBreakpoint} = Grid;


const MyPortfolio = ({}) => {
    const {externalId} = useParams();
    const {proposal, updateAiAnalyticalComments, aiAnalyticalComments} = useHolisticoProposalContext();
    const [aiExpand, setAiExpand] = useState(false);
    const screens = useBreakpoint();
    const portfolios = proposal?.current_portfolios;

    useUpdateEffect(() => {
        if (externalId && proposal) {
            if (!aiAnalyticalComments) {
                updateAiAnalyticalComments()
            }
        }
    }, [proposal])


    return <>{proposal &&
        (portfolios && portfolios.length > 0
                ?
                <>
                    {screens.xl
                        ?
                        <Row gutter={[24, 24]}>
                            <Col span={14}>
                                <Flex vertical gap={24}>
                                    <AccountsCard/>
                                    <AnalyticsCard setAiExpand={setAiExpand}/>
                                    <PositionsCard/>
                                </Flex>
                            </Col>
                            <Col span={10}>
                                <Flex vertical gap={24}>
                                    <RiskProfileCard/>
                                    <OverviewCard/>
                                    <StressTestCard/>
                                    <RetirementMapCard/>
                                </Flex>
                            </Col>
                        </Row>
                        :
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <AccountsCard/>
                            </Col>
                            <Col span={24}>
                                <RiskProfileCard/>
                            </Col>
                            <Col span={24}>
                                <OverviewCard/>
                            </Col>
                            <Col span={24}>
                                <AnalyticsCard setAiExpand={setAiExpand}/>
                            </Col>
                            <Col span={24}>
                                <StressTestCard/>
                            </Col>
                            <Col span={24}>
                                <RetirementMapCard/>
                            </Col>
                            <Col span={24}>
                                <PositionsCard/>
                            </Col>
                        </Row>
                    }
                    <AiChatPanel aiExpand={aiExpand} setAiExpand={setAiExpand}/>
                </>
                :
                <EnrollmentCard/>
        )
    }</>
}


export default MyPortfolio;
