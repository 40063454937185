import {Progress, Typography} from "antd";
import React from "react";
import ChartStyles from "@global/ChartStyles";

const RedFlagDetails = ({data}) => {
    let status = ChartStyles.get_status(data?.value, 60, 90)
    let color = ChartStyles.get_status_color(status)

    const getStatusText = (data, status) => {
        let clientText = <Typography.Text>
            <Typography.Text type="danger">{data?.clientsProblem}</Typography.Text> of {data?.clientsTotal} clients have
            warnings and should be optimized.
        </Typography.Text>
        let flagText = data?.flagsProblem ? <Typography.Text><Typography.Text type={"warning"}> {data?.flagsProblem}</Typography.Text> checks failed in total.</Typography.Text> : null
        return <Typography.Paragraph>{clientText}{flagText}</Typography.Paragraph>
    }
    return <div>
        <Typography.Title level={5}>
            {data?.label}
        </Typography.Title>
        <Progress percent={data?.value} steps={20} size="small" strokeColor={color}/>
        {getStatusText(data, status)}
        <Typography.Paragraph>{data?.description}</Typography.Paragraph>
    </div>
}

export default RedFlagDetails