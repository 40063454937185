import React, {useState} from "react";
import {Button, Checkbox, Input, Modal, Space, Typography} from 'antd';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {saveReportPreset} from "@API/report";

const {Text, Paragraph, Title} = Typography;

const defaultModalState = {visible: false, loading: false};
const defaultInputTemplate = {title: "", description: ""}

const ReportSections = ({reportClass, sections, updateSections, refreshData}) => {
    const [modalConfig, setModalConfig] = useState(defaultModalState);
    const [newTemplateAttributes, setNewTemplateAttributes] = useState(defaultInputTemplate);
    const [inputError, setInputError] = useState(null);

    const handleNewTemplateInput = e => {
        const {name, value} = e.target;
        setNewTemplateAttributes({...newTemplateAttributes, [name]: value});
    }

    const sectionsCheckChange = (selectedSections) => {
        updateSections(sections.map(section => {
            let checked = selectedSections.includes(section.key);
            return {...section, checked: checked}
        }))
    }

    const sectionsOrderChange = (result) => {
        if (!result.destination) return;

        const items = Array.from(sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateSections(items);
    }

    const handleCreateTemplate = () => {
        if (newTemplateAttributes.title === "") {
            setInputError("Please, input template's title")
            return
        }
        if (newTemplateAttributes.description === "") {
            setInputError("Please, input template's description")
            return
        }
        setModalConfig({...modalConfig, loading: true});

        saveReportPreset({
            title: newTemplateAttributes.title,
            description: newTemplateAttributes.description,
            key: newTemplateAttributes.title.toLowerCase().replaceAll(" ", "_"),
            sections: sections.filter(section => section.checked).map(section => section.key),
            type: "user",
            report_class: reportClass
        }, () => {
            setModalConfig(defaultModalState);
            setNewTemplateAttributes(defaultInputTemplate);
            refreshData();
        })
    }

    const onModalClose = () => {
        setModalConfig(defaultModalState);
        setInputError(null);
    }

    return (
        (<div>
            <Title level={4}>Sections</Title>
            <Space direction={"vertical"}>
                <DragDropContext onDragEnd={sectionsOrderChange}>
                    <Droppable droppableId="sections">
                        {(provided) => (
                            <Checkbox.Group onChange={sectionsCheckChange} value={sections.map(section => {
                                if (section.checked) {
                                    return section.key
                                }
                            })} {...provided.droppableProps} ref={provided.innerRef}>
                                <Space direction="vertical">
                                    {sections.map((section, inx) => {
                                        return (
                                            <Draggable key={section.key} draggableId={section.key} index={inx}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Checkbox value={section.key}
                                                                  disabled={section.disabled}>{section.title}</Checkbox>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                </Space>
                                {provided.placeholder}
                            </Checkbox.Group>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button onClick={() => setModalConfig({...modalConfig, visible: true})}>Save as new preset</Button>
            </Space>
            <Modal title="Custom preset creation"
                   className="modal-template-creator" open={modalConfig.visible} footer={[
                <Button key="cancel" onClick={onModalClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={modalConfig.loading} onClick={handleCreateTemplate}>
                    Save preset
                </Button>
            ]} onCancel={onModalClose}>
                <Input
                    placeholder="Name the template" name="title" value={newTemplateAttributes.title}
                    onChange={handleNewTemplateInput}
                />
                <Input
                    placeholder="Describe the template" name="description"
                    value={newTemplateAttributes.description} onChange={handleNewTemplateInput}
                />
                {inputError && <Text className="input-error">{inputError}</Text>}
            </Modal>
        </div>)
    );
}

export default ReportSections
