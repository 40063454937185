import React, {useCallback, useState} from "react";
import {Badge, Form, Mentions, Popover, theme, Tooltip, Typography} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {getSimpleFactors} from "@API/factor";
import {cancel} from "@API/factor";
import ChartStyles from "@global/ChartStyles";
import {debounce} from "@global/Utils";


const {useToken} = theme;


const CodeInput = ({field}) => {
    const [foundFactors, setFoundFactors] = useState([])
    const [loading, setLoading] = useState(false)
    const {token} = useToken();

    const loadFactors = (search) => {
        getSimpleFactors(search, (result, err) => {
            if (!err) {
                setFoundFactors(result);
            }
            setLoading(false);
        })
    }

    const debounceLoadFactors = useCallback(debounce(loadFactors, 300), [])

    const onSearch = search => {
        if (cancel) {
            cancel();
        }
        setLoading(true);
        setFoundFactors([]);
        debounceLoadFactors(search);
        
    };

    return <div style={{position: "relative"}}>
        <Popover
            title={<b>Selection criteria</b>}
            content={
                <div style={{whiteSpace: "pre-line"}}>
                    <Typography.Paragraph>
                        Defines what companies are included in
                        the {field === "portfolio_config" ? "portfolio" : "benchmark"}.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Example:
                        <pre>
                        @sscore_plus > 50
                            {"\n"}and (
                            {"\n    "}@macro_score_1q > 40
                            {"\n    "}or @macro_score_1yr > 40
                            {"\n"})
                    </pre>
                    </Typography.Paragraph>
                </div>
            }
        >
            <QuestionCircleOutlined style={{
                color: "rgba(0, 0, 0, 0.45)",
                position: "absolute",
                right: -token.padding,
                top: token.paddingSM,
                zIndex: 1,
            }}/>
        </Popover>
        <Form.Item name={[field, 'sql_condition']}>
            <Mentions
                loading={loading}
                onSearch={onSearch}
                className={"mentions-factor "}
                autoSize={{minRows: 3}}
                prefix={"@"}
                placement={"bottom"}
                placeholder={"Type in selection criteria. Use @ to search factors..."}
                variant={"borderless"}
                style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: 500,
                    paddingRight: 50,
                    margin: `0 -${token.paddingLG}px -${token.paddingLG}px -${token.paddingLG}px`,
                    width: `calc(100% + ${token.paddingLG}px + ${token.paddingLG}px)`,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderTop: `1px solid ${token.colorBorder}`,
                    padding: `${token.paddingXS}px ${token.padding}px ${token.paddingLG}px`
                }}
            >
                {foundFactors.map(f => {
                    return <Mentions.Option
                        value={f['column_name']}
                        key={f['_id']}
                    >
                        <Badge
                            color={ChartStyles.getRepresentationColor(f['representation'])}
                            text={f['full_name']}
                        />
                    </Mentions.Option>
                })}
            </Mentions>
        </Form.Item>
    </div>
}

export default CodeInput
