import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Menu, Typography} from "antd";
import AdminNavigation from "../AdminNavigation";
import TasksOrQueuesTable from "./task_queue/TasksOrQueuesTable";
import TaskQueueFullInfo from "./task_queue/TaskQueueFullInfo";
import JobsList from "./job/JobsList";
import {getHiddenHref} from "@global/Utils";
import SchedulerDashboard from "@components/admin_panel/scheduler/SchedulerDashboard";

const {Title} = Typography;

const menuItems = () => {
    let menuItms = [{
        key: "dashboard",
        label: "Dashboard",
    }, {
        key: "tasks",
        label: "Tasks",
    }, {
        key: "queues",
        label: "Queues",
    }, {
        key: "jobs",
        label: "Jobs"
    }];

    for (let item of menuItms) {
        item.label = getHiddenHref(item.label, item.key, "/admin/scheduler/")
    }
    return menuItms
}

const SchedulerRouter = () => {
    const [selectedKeys, setSelectedKeys] = useState([""]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let path = location.pathname.split("/").at(-1);
        setSelectedKeys([path]);
    }, [location.pathname])

    const onClick = (e) => {
        console.log('client menu click ', e);
        navigate(e.key)
    };

    return (
        <div>
            <AdminNavigation route={location.pathname}/>
            <Title level={3}>Scheduler</Title>
            <Menu
                style={{lineHeight: '30px'}}
                className="secondary-menu"
                mode="horizontal"
                items={menuItems()}
                onClick={onClick}
                theme="dark"
                selectedKeys={selectedKeys}
            />
            <div style={{paddingTop: '30px'}}>
                <Routes>
                    <Route exact path="" element={<Navigate to="dashboard" replace/>}/>
                    <Route exact path="dashboard" element={<SchedulerDashboard/>}/>
                    <Route exact path="tasks" element={<TasksOrQueuesTable schedulerEntity="task"/>}/>
                    <Route path="tasks/:schedulerObjectId" element={<TaskQueueFullInfo schedulerEntity="task"/>}/>
                    <Route exact path="queues" element={<TasksOrQueuesTable schedulerEntity="queue"/>}/>
                    <Route path="queues/:schedulerObjectId" element={<TaskQueueFullInfo schedulerEntity="queue"/>}/>
                    <Route exact path="jobs" element={<JobsList/>}/>
                </Routes>
            </div>
        </div>
    )
}

export default SchedulerRouter
