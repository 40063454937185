/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {DeleteOutlined, EllipsisOutlined} from '@ant-design/icons';
import {Button, Dropdown, Menu, message, Spin} from 'antd';
import {deleteClient} from '@API/clients';


const menuItems = [
    {
        label: 'Remove',
        key: 'delete',
        icon: <DeleteOutlined/>,
        style: {color: '#ad2102'},
    }
]

const RecentProposalsListItemMenu = ({id, onEdit}) => {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const deleteClientCallback = (response_message, error) => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: response_message,
            })
            setLoading(false);
        } else {
            messageApi.open({
                type: 'success',
                content: response_message,
            })
            setLoading(false);
            onEdit();
        }
    }

    const handleMenuClick = (e) => {
        console.log(e.key)
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
        if (e.key === "delete") {
            setLoading(true);
            deleteClient(id, deleteClientCallback)
        }
    }

    if (loading) {
        return <Spin/>
    } else {
        return <Dropdown
            trigger={['click']}
            menu={{
                items: menuItems,
                onClick: handleMenuClick
            }}
            arrow={true}
            placement={"bottom"}
        >
            <Button
                type={"text"}
                icon={<EllipsisOutlined />}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation(); // stop propagation main button
                }}
            />
        </Dropdown>
    }
}

export default RecentProposalsListItemMenu;
