import {Form, Input, message, Modal} from "antd";
import React, {useCallback} from "react";
import UserDetails from "@components/admin_panel/users/UserDetails";
import {changePassword} from "@API/users";

const ChangePasswordModal = ({user, visible, hide}) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        form.resetFields();
        hide()
    }

    const changeUserPassword = useCallback((values) => {
        changePassword(user.id, {new_password: values.password, user_email: user.email}, (resp, error) => {
            if (error) {
                message.error(resp);
                return
            }
            message.success(`The new password for ${user.email} was set successfully`);
            handleCancel();
        })
    }, [user])

    return (
        <Modal
            open={visible}
            title="Change password"
            onOk={form.submit}
            onCancel={handleCancel}
            okText="Change"
        >
            <Form form={form} name="password_updating" onFinish={changeUserPassword} autoComplete="off"
                  scrollToFirstError layout="vertical" requiredMark={false}>
                <Form.Item
                    name="password"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: 'Missing password',
                        },
                        {
                            min: 7,
                            message: 'Password requires 7 or more symbols',
                        },
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9-*^]*$/),
                            message: 'Password can include only Latin characters, numbers and symbols ^ * -',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirmed_password"
                    label="Confirm new password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangePasswordModal
