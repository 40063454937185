import React, {useCallback, useEffect, useState} from "react";
import {Col, Row, Skeleton, Spin, Typography} from "antd";

import "../Supervision.less";
import {green} from '@ant-design/colors';
import RedFlagHealthRadar from "@components/supervision/chart/RedFlagHealthRadar";
import RedFlagDetails from "@components/supervision/summary/RedFlagDetails";

const green_color = green[6]


const RadarChart = React.memo(({data, updater}) => {
    return <RedFlagHealthRadar flags_healths={data} updateDetails={updater}/>
})

const SmartRadar = ({radarData, defaultDetails, loading}) => {
    const [redFlagDetails, setRedFlagDetails] = useState(null)

    const updateDetails = useCallback((newDetails) => {
        console.debug(newDetails)
        if (newDetails == null) {
            setRedFlagDetails(defaultDetails)
        } else {
            if (newDetails.key != redFlagDetails?.key) {
                setRedFlagDetails(newDetails)
            }
        }

    }, [defaultDetails, radarData]);

    useEffect(() => {
        setRedFlagDetails(defaultDetails)
    }, [defaultDetails])

    return (
        <div className="supervision-container">
            <Typography.Title level={4} className="spas">
                Wealth Health
            </Typography.Title>
            <Typography.Paragraph>The graph on the right shows how good is your company clients' wealth managed and organized in terms of different points of probable weakness. Hover on the chart to view the details for each check.</Typography.Paragraph>
            <Row>
                <Col span={10}>
                    <Skeleton active={loading} loading={loading}>
                        <RedFlagDetails data={redFlagDetails}/>
                    </Skeleton>
                </Col>
                <Col span={14}>
                    <Spin spinning={loading} style={{height: 300}}>
                        <RadarChart data={radarData} updater={updateDetails}/>
                    </Spin>
                </Col>
            </Row>
        </div>
    )
}

export default SmartRadar;
