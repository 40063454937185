/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';

import './PortfolioSelector.less'
import StaticTypifiedPortfolioList from "@components/portfolio/list/StaticTypifiedPortfolioList";
import PortfolioList from "@components/portfolio/list/PortfolioList";

const PortfolioSelector = ({
                               selected,
                               portfolios,
                               proposedPortfolios,
                               setSelectedPortfolio,
                               showEmpty = true,
                               showMarketValue = true
                           }) => {

    const [modalWindowShow, setModalWindowShow] = useState(false);

    const updateSelection = (newPortfolio) => {
        setSelectedPortfolio(newPortfolio)
        setModalWindowShow(false)
    }

    useEffect(() => {

    }, [portfolios])


    return (
        (<div>
            <PortfolioList portfolios={[selected]}
                           itemSelected={() => setModalWindowShow(true)}
                           showEmpty={showEmpty}
                           showMarketValue={showMarketValue}
            />
            <Modal
                title="Portfolios To Use"
                className={'portfolio-select-modal'}
                open={modalWindowShow}
                width={850}
                closable={false}
                maskClosable={true}
                onCancel={v => setModalWindowShow(false)}
                footer={[]}
            >{proposedPortfolios && proposedPortfolios.length ?
                <StaticTypifiedPortfolioList setSelection={newValue => updateSelection(newValue)}
                                             proposedPortfolios={proposedPortfolios}
                                             clientPortfolios={portfolios}
                                             showEmpty={showEmpty}
                                             showMarketValue={showMarketValue}
                /> :
                <PortfolioList portfolios={portfolios} proposedPortfolios={proposedPortfolios}
                               itemSelected={newValue => updateSelection(newValue)} showEmpty={showEmpty}
                               showMarketValue={showMarketValue}/>
            }

            </Modal>
        </div>)
    );
}

export default PortfolioSelector;
