/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Input, Tooltip, Typography} from 'antd';

import '../Portfolio.less';
import {CloudSyncOutlined, DatabaseOutlined} from "@ant-design/icons";
import ValueFormatter from "@global/ValueFormatter";

const {Paragraph, Text, Title} = Typography;

const PortfolioTitleInput = ({
                                 viewTitleInputProps,
                                 applyNewValue,
                                 portfolioTitle,
                                 ticker = null,
                                 external_id = null,
                                 auxiliary_data = null,
                                 log_data = null,
                                 type = 'client'
                             }) => {

    const {viewTitleInput, setViewTitleInput} = viewTitleInputProps;
    const [input, setInput] = useState(portfolioTitle);

    const toolpitInfo = "Hit Enter to accept the name change";

    useEffect(() => {
        setInput(portfolioTitle)
    }, [portfolioTitle])

    const handleKeyDown = (event) => {
        console.log(event.keyCode)
        if (event.keyCode === 27) {
            onBlur()
        } else if (event.keyCode === 13) {
            applyNewValue(input)
        }
    }

    const onBlur = () => {
        setInput(portfolioTitle)
        setViewTitleInput(false)
    }

    const handleDbl = (e) => {
        e.preventDefault();
        setViewTitleInput(true)
    }

    const getTitleText = () => {

        let texts = []

        texts.push(<Text key="name" onDoubleClick={(e) => handleDbl(e)}
                         className="portfolio-title-name">
            {input}</Text>)
        if (ticker) {
            texts.push(<Text key={"ticker"}> ({ticker.replace("#", "")}) </Text>)
        }

        if (auxiliary_data?.weights_update_method) {
            if (auxiliary_data?.weights_update_method.includes('orion') && external_id) {
                let icon = <Tooltip title={"Follows the composition from Orion"}><CloudSyncOutlined
                    style={{fontSize: '16px', color: '#E0AD60'}}/></Tooltip>
                texts.push(<sup key={"sup"}>
                    {icon}</sup>)
            } else if (external_id) {
                let icon = <Tooltip title={"Loaded from Orion and stored in the DB"}><DatabaseOutlined
                    style={{fontSize: '16px', color: '#71A7B6FF', opacity: 0.5}}/></Tooltip>
                texts.push(<sup key={"sup"}>
                    {icon}</sup>)
            } else {

            }
        }

        return texts
    }

    const formatLog = (log_data) => {
        let date = ValueFormatter.pythonDate(log_data['timestamp'])
        return date + ', ' + log_data['application']
    }

    const getHiddenText = () => {
        let texts = []

        if (type == 'client') {
            return texts
        }
        if (log_data) {
            texts.push(<Text
                className="portfolio-title-name small-additional-info"
                key={'log'}> {formatLog(log_data)}</Text>)
        }

        if (auxiliary_data?.weights_update_method) {
            /*if (!auxiliary_data?.weights_update_method.includes('orion')) {
                texts.push(<Text key="weights_update_method"
                                 className="portfolio-title-name additional-info"> {auxiliary_data?.weights_update_method} |</Text>)
            }
            if (auxiliary_data?.rebalance_method?.length) {
                texts.push(<Text key="rebalance_method"
                                 className="portfolio-title-name additional-info"> rebalance: {auxiliary_data?.rebalance_method}</Text>)
            }*/
        }


        return texts
    }

    return (
        (viewTitleInput) ?
            <Input value={input} autoFocus={true}
                   onChange={e => setInput(e.target.value)}
                   onKeyDown={event => handleKeyDown(event)}
                   onBlur={event => onBlur()}
            />
            :
            <Typography.Text
                ellipsis={true}
                style={{marginBottom: 0, whiteSpace: "nowrap"}}
            >
                {getTitleText()}
                {getHiddenText()}
            </Typography.Text>
    )
}

export default PortfolioTitleInput;
