import React, {useState} from "react";
import {message} from "antd";
import {createFromPositions} from "@API/clientPortfolio";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import PortfolioConstructor
    from "@components/retail/portfolio/constructor/PortfolioConstructor";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";


const EnrollmentCard = ({}) => {
    const {proposal, fetchProposal, updateAiAnalyticalComments} = useHolisticoProposalContext();
    const [openProgress, setOpenProgress] = useState(false);

    const submit = (positions) => {
        const postData = {
            positions: positions,
            portfolio_name: "Main account",
            portfolio_type: "cur"
        }
        setOpenProgress(true);
        createFromPositions(proposal._id, postData, (result, error) => {
            if (!error) {
                console.log("submit success", result);
                fetchProposal(true, updateAiAnalyticalComments);
            } else {
                // TODO: updateAiAnalyticalComments?
                message.error("Something went wrong while preparing analytics!");
                console.error(result);
            }
            setOpenProgress(false);
        })
    }

    return <>
        <PortfolioConstructor
            name={"My portfolio"}
            tip={
                "Fill portfolio positions to get the analysis " +
                "and AI comments. " +
                "You can either upload file with positions or " +
                "use asset search to fill them manually."
            }
            onSubmit={submit}
            style={{
                maxWidth: 800,
                marginLeft: "auto",
                marginRight: "auto",
            }}
        />
        <FakeProgress
            tip={"Preparing portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </>

}


export default EnrollmentCard;
