import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;
const BACKTEST_URL = "/backtest"
const BACKTEST_CONFIG_URL = BACKTEST_URL + "/config"

const getDefaults = (callback) => {
    let url = `${BACKTEST_URL}/setup`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(null, response)
            } else {
                callback(null, 'Unknown error!')
            }
        })
}

const getDefaultsAsync = async () => {
    let url = `${BACKTEST_URL}/setup`
    return await axiosInstance.get(url)
}

const getBacktestConfigs = (templatesHandler) => {
    let url = `${BACKTEST_CONFIG_URL}`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        templatesHandler(data);
    })
        .catch(({response}) => {
            console.log(response);
        })
};

const saveBacktestConfig = (userTemplate, saveCallback) => {
    let url = `${BACKTEST_URL}/config`

    if (userTemplate.id) {
        axiosInstance.put(`${url}/${userTemplate.id}`, userTemplate)
            .then((result) => {
                saveCallback(null, result);
            })
            .catch(({response}) => {
                console.log(response);
                saveCallback(response, null);
            })
    } else {
        axiosInstance.post(url, userTemplate)
            .then((result) => {
                saveCallback(null, result);
            })
            .catch(({response}) => {
                console.log(response);
                saveCallback(response, null);
            })
    }


};

const deleteBacktestConfig = (_id, deletionCallback) => {
    let url = `${BACKTEST_CONFIG_URL}/${_id}`

    axiosInstance.delete(url)
        .then(() => {
            deletionCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};

const getConfig = (id, handler) => {
    let url = `${BACKTEST_CONFIG_URL}/${id}`

    if (!id) {
        url = `${BACKTEST_URL}/default_config`
    }
    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            handler(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                handler(null, response)
            } else {
                handler(null, 'Unknown error!')
            }
        })
}

const downloadBacktestWeekly = (configId, backtestConfig, callback) => {
    let url = `${BACKTEST_URL}/download_weekly`;

    axiosInstance.post(url,
        {weekly_config_id: configId, backtest_config: backtestConfig},
        {
            responseType: "arraybuffer",
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then((response) => {
            callback(response, false);
        })
        .catch(({response}) => {
            console.log(response)
            callback(response, true);
        })
}

const downloadStatisticsExcel = (data, callback) => {
    let url = `${BACKTEST_URL}/download_statistics`;

    axiosInstance.post(url, data,
        {
            responseType: "arraybuffer",
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then((response) => {
            callback(response, false);
        })
        .catch(({response}) => {
            console.log(response)
            callback(response, true);
        })
}

const downloadAggregationExcel = (data, callback) => {
    let url = `${BACKTEST_URL}/download_aggregation`;

    axiosInstance.post(url, data,
        {
            responseType: "arraybuffer",
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then((response) => {
            callback(response, false);
        })
        .catch(({response}) => {
            console.log(response)
            callback(response, true);
        })
}

export {
    getConfig,
    saveBacktestConfig,
    deleteBacktestConfig,
    getBacktestConfigs,
    getDefaults,
    getDefaultsAsync,
    downloadBacktestWeekly,
    downloadStatisticsExcel,
    downloadAggregationExcel,
    cancel
}
