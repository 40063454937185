import {Col, Empty, Row} from 'antd'
import React from "react";
import CreditRatingChart from "@holistico/chart/CreditRatingChart";

const CreditRatingCompareRow = ({original_values, recommended_values}) => {
    if (!original_values || Object.keys(original_values).length === 0) return <Empty style={{margin: "15px 0"}}
                                                                                     description="No asset class information"/>;

    return <Row gutter={[24, 24]} style={{width: '100%'}}>
        <Col span={12}>
            <CreditRatingChart allocation={original_values?.fi_credit_rating} showChart={true} showLegend={false}/>
        </Col>
        <Col span={12}>
            <CreditRatingChart allocation={recommended_values?.fi_credit_rating} showChart={true} showLegend={false}/>
        </Col>
    </Row>
}

export default CreditRatingCompareRow
