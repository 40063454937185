import {Form, Spin, Upload} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";
import React from "react";
import {createFromFile} from "@API/portfolio";
import HSpinner from "@holistico/HSpinner";

const PortfoliosFilesForm = () => {
    const form = Form.useFormInstance();
    const loading = Form.useWatch('loading', form);

    const backToForm = (fileEvent) => {
        console.debug('backToForm called')
        console.debug(fileEvent)
        if (Array.isArray(fileEvent)) {
            return fileEvent;
        }

        return fileEvent.fileList
    };


    const props = {
        showUploadList: true,
        multiple: true,
        maxCount: 10,
        listType: "picture",
        customRequest: async ({file, onProgress, onError, onSuccess}) => {

            console.debug('Custom request called')
            let progressInterval = progressIntervalCaller(onProgress);
            let uploadData = new FormData();
            uploadData.append("file_content", file);
            uploadData.append("file_name", file.name);
            uploadData.append("file_type", file.type);
            uploadData.append("portfolio_name", file.name);
            uploadData.append("portfolio_type", 'temporal');

            createFromFile(uploadData, (result, error) => {
                if (error) {
                    console.error(result)
                    onError()
                } else {
                    form.setFieldValue('loading', true)
                    file.portfolio_id = result['id'];
                    onSuccess()
                }
                clearInterval(progressInterval);
            })
        },
        onChange(props) {
            console.debug('files form onChange request called')
            const {status} = props.file;
            if (status === 'done') {
                //message.success(`Successfully processed ${props.file.name}!`);
            } else if (status === 'error') {
                console.error(`${props.file.name} file upload failed!`);
            }
        },
    };


    const progressIntervalCaller = (onProgress) => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            onProgress({percent: progress});
        }, 700)
    }

    return <HSpinner loading={loading}>
        <Form.Item name={["initial_investments", "uploaded_files"]}
                   valuePropName="fileList"
                   getValueFromEvent={backToForm}
                   noStyle>
            <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined/>
                </p>
                <p className="ant-upload-text">
                    Upload xls, client pdf report or image files containing portfolio details.
                </p>
                <p className="ant-upload-hint">
                    Click to find on your storage or Drag to upload
                </p>
            </Upload.Dragger>
        </Form.Item>
    </HSpinner>
}

export default PortfoliosFilesForm
