import React, {useRef} from "react";
// import {Row, Tooltip, Tree, Alert} from "antd";
import {Row, Tree} from "antd";
import {createTreeData} from "@components/supervision/report_constructor/tree_utils/TreeDataCreator";
import {DownOutlined} from "@ant-design/icons";

import "../RedFlagReport.less";

const RedFlagContainer = ({flag}) => {
    const treeData = useRef(createTreeData(flag.children, {
        metric_units: flag.metric_units, 
        metric_decimals_precision: flag.metric_decimals_precision,
        red_flag_name: flag.red_flag_name,
        red_flag_key: flag.red_flag_key
    }));

    return (
        <div className="flag-row-content-inner">
            <Row style={{fontSize: "1.1em"}}>{flag.red_flag_description}</Row>
            <Tree
                className="supervision-report-tree"
                style={{marginTop: 12}}
                showLine={{showLeafIcon: false}}
                switcherIcon={<DownOutlined style={{color: "#1890ff", fontSize: "0.9em"}}/>}
                showIcon={false}
                selectable={false}
                // autoExpandParent={true}
                treeData={treeData.current}
            />
            
        </div>
    )
}

export default RedFlagContainer
