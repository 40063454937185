/* eslint-disable react/prop-types */
import React from 'react';

import AllClients from "@components/clients/AllClients";
import {Typography} from "antd";

const Clients = () => {
    return (<>
            <Typography.Title level={3}>Client Proposals</Typography.Title>
            <AllClients/></>
    )

}

export default Clients;
