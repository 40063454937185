/* eslint-disable react/prop-types */
import React from 'react';
import ModelPortfolios from '@components/models/ModelPortfolios';
import '@app/AppSpas.less';
import {Typography} from "antd";

const Models = () => {
    return (<><Typography.Title level={3}>Models and benchmarks</Typography.Title>
            <ModelPortfolios/></>
    );
}

export default Models;
