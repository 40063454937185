import React from "react";
import {Card, Flex, theme} from "antd";
import {is_null} from "@global/ValueFormatter";


const {useToken} = theme;


const StatsNonBordered = ({title, value}) => {
    const {token} = useToken();

    return <Card type={"inner"} style={{
        background: "rgba(255,255,255,0)",
        borderColor: "rgb(200, 200, 200)",
        border: "none"
    }}>
        <Flex vertical align={"flex-start"}>
            <span style={{
                fontSize: token.fontSizeHeading4,
                lineHeight: 1,
                textTransform: "uppercase",
                color: token.colorStats
            }}>
                {is_null(value) ? "-" : value}
            </span>
            <span style={{
                marginTop: token.paddingSM,
                fontSize: 12,
                textTransform: "uppercase",
                lineHeight: 1.2,
                letterSpacing: 1,
            }}>
                {title}
            </span>
        </Flex>
    </Card>
}


export default StatsNonBordered;
