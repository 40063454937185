/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {
    Card,
    Col,
    Descriptions,
    Divider,
    Flex,
    Progress,
    Row, Space,
    Spin
} from 'antd';
import {blue, green, grey, red} from '@ant-design/colors';
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import CardHeader from "@components/screener/card/CardHeader";
import StatsCard from "@components/screener/card/StatsCard";
import DescriptiveInfo from "@components/screener/card/DescriptiveInfo";
import Localizator from "@global/Localizator";


export const getRatingColor = (value) => {
    return value > 60 ? green[3] : (value <= 20 ? red[3] : blue[3])
}


const ScoreStatsCompact = ({title, value, rank = false}) => {
    let rating_value = value
    if (rank && value) {
        rating_value = (6 - value) * 20 - 10
    }

    rating_value = ValueFormatter.score(rating_value)

    return <Flex align={"center"}>
        <Progress
            percent={rating_value ? rating_value + 10 : 0}
            steps={5}
            showInfo={false}
            strokeColor={getRatingColor(rating_value)}
            size={[3, 12]}
        />
        <span style={{
            fontSize: 16,
            fontWeight: 500,
            marginLeft: 8,
            width: 32,
            minWidth: 32,
        }}>
                {is_null(value) ? "-" : value}
            </span>
        <span style={{
            fontSize: 14,
            color: grey[4],
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }}>
                {title}
            </span>
    </Flex>
}


const MainPanel = ({asset}) => {
    return <Row gutter={[16, 16]} style={{marginBottom: 20, minHeight: 90}}>
        <Col span={6}>
            <StatsCard
                title={Localizator.t("factor.sscore_plus")}
                value={asset.sscore_plus}
                progress={asset.sscore_plus ? asset.sscore_plus + 10 : 0}
                progressColor={getRatingColor(asset.sscore_plus)}
                style={{minHeight: 90}}
                wrapTitle={true}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={"Dividend Yield"}
                value={asset.div_yield ? ValueFormatter.int_percent(asset.div_yield) : '-'}
                style={{minHeight: 90}}
                wrapTitle={true}
            />
        </Col>
        <Col span={12}>
            <DescriptiveInfo
                title1={
                    (asset.size ?? "")
                    + (asset.vbg_style ? " " + asset.vbg_style : "")
                    + (asset.country ? ", " + asset.country : "")
                }
                title2={asset.sector ?? "-"}
                title3={asset.industry ?? "-"}
            />
        </Col>
    </Row>
}


const RatingsPanel = ({asset}) => {
    return <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col span={12}>
            <ScoreStatsCompact
                title={"1Y Macro Score"}
                value={asset.macro_score_1yr}
            />
            <ScoreStatsCompact
                title={"1Q Macro Score"}
                value={asset.macro_score_1q}
            />
            <ScoreStatsCompact
                title={"ZScore"}
                value={asset.z_score}
            />
            <div style={{marginTop: 12}}/>
            <ScoreStatsCompact
                title={"Comp. Rating, IBD"}
                value={asset.comp_rating_ibd}
            />
            <ScoreStatsCompact
                title={"CM Rank, ER"}
                value={asset.cm_rank_er}
                rank
            />
            <ScoreStatsCompact
                title={"Timeliness Rank, VL"}
                value={asset.timeliness_rank_vl}
                rank
            />
            <ScoreStatsCompact
                title={"Zacks Rank"}
                value={asset.zacks_rank_zks}
                rank
            />
        </Col>
        <Col span={12}>
            <ScoreStatsCompact
                title={"Growth"}
                value={asset.sscore_plus_growth}
            />
            <ScoreStatsCompact
                title={"Momentum"}
                value={asset.sscore_plus_momentum}
            />
            <ScoreStatsCompact
                title={"Profitability"}
                value={asset.sscore_plus_profitability}
            />
            <ScoreStatsCompact
                title={"Safety"}
                value={asset.sscore_plus_safety}
            />
            <ScoreStatsCompact
                title={"Value"}
                value={asset.sscore_plus_value}
            />
            <ScoreStatsCompact
                title={"Yield"}
                value={asset.sscore_plus_yield}
            />
        </Col>
    </Row>
}


const MetricsPanel = ({asset}) => {
    return <Descriptions
        bordered
        items={[
            {
                key: 'mkval',
                label: 'Market Cap.',
                children: is_null(asset.mkval)
                    ? "-" : ValueFormatter.compactCurrency(1000000 * asset.mkval),
                span: 2
            },
            {
                key: 'price_to_earnings',
                label: 'P/E',
                children: is_null(asset.price_to_earnings)
                    ? "-" : ValueFormatter.round(asset.price_to_earnings, 1)
            },
            {
                key: 'sales_growth_1yr',
                label: 'Sales Growth 1Y',
                children: is_null(asset.sales_growth_1yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.sales_growth_1yr / 100)
            },
            {
                key: 'price_to_book',
                label: 'P/B',
                children: is_null(asset.price_to_book)
                    ? "-" : ValueFormatter.round(asset.price_to_book, 1)
            },
            {
                key: 'sales_growth_5yr',
                label: 'Sales Growth 5Y',
                children: is_null(asset.sales_growth_5yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.sales_growth_5yr / 100)
            },
            {
                key: 'roa',
                label: 'ROA',
                children: is_null(asset.roa)
                    ? "-" : ValueFormatter.round(asset.roa, 1)
            },
            {
                key: 'idiosyncratic_risk_mse',
                label: 'Idiosyncratic Risk (MSE)',
                children: is_null(asset.idiosyncratic_risk_mse)
                    ? "-" : ValueFormatter.round(asset.idiosyncratic_risk_mse, 1)
            },
            {
                key: 'roe',
                label: 'ROE',
                children: is_null(asset.roe)
                    ? "-" : ValueFormatter.round(asset.roe, 1)
            },
            {
                key: 'idiosyncratic_risk_r2',
                label: 'Idiosyncratic Risk (R2)',
                children: is_null(asset.idiosyncratic_risk_r2)
                    ? "-" : ValueFormatter.round(asset.idiosyncratic_risk_r2, 1)
            },
            {
                key: 'epe_growth',
                label: 'ePE / Growth',
                children: is_null(asset.epe_growth)
                    ? "-" : ValueFormatter.round(asset.epe_growth, 2)
            },
            {
                key: 'total_return_1yr',
                label: '1Y Return',
                children: is_null(asset.total_return_1yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1yr / 100)
            },
            {
                key: 'total_return_ytd',
                label: 'YTD Return',
                children: is_null(asset.total_return_ytd)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_ytd / 100)
            },
            {
                key: 'total_return_1m',
                label: '1M Return',
                children: is_null(asset.total_return_1m)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1m / 100)
            },
        ]}
        size={"small"}
        column={2}
        contentStyle={{fontSize: 14, padding: "4px 8px"}}
        labelStyle={{fontSize: 14, padding: "4px 8px"}}
    />
}

const CompanyCardTooltip = ({asset}) => {
    const [loading, setLoading] = useState(false)

    return <Card style={{borderColor: grey[4]}}>
        <Spin spinning={loading}>
            <Space direction={"vertical"}>
                <CardHeader asset={asset} loading={loading} setLoading={setLoading}/>
                <MainPanel asset={asset}/>
                <RatingsPanel asset={asset}/>
            </Space>
        </Spin>
    </Card>
}


const CompanyCard = ({asset}) => {
    const [loading, setLoading] = useState(false)

    return <Card hoverable style={{borderColor: "#e3e3e3"}}>
        <Spin spinning={loading}>
            <CardHeader asset={asset} loading={loading} setLoading={setLoading}/>
            <Divider style={{margin: "8px 0 16px 0"}}/>
            <MainPanel asset={asset}/>
            <RatingsPanel asset={asset}/>
            <MetricsPanel asset={asset}/>
        </Spin>
    </Card>
}

export {CompanyCard, CompanyCardTooltip, MetricsPanel, MainPanel, RatingsPanel};
