import React, {useEffect, useRef, useState, useCallback} from "react";
import {Row, Col, Button, message, Spin, Popconfirm, Card, Badge} from "antd";
import {DeleteOutlined, SettingOutlined, CopyOutlined} from "@ant-design/icons";
import { getGlobalConfigs, deleteGlobalConfig } from "@API/supervision";
import ConfigCreator from "./ConfigCreator";

export const defaultConfigKey = "defaultSupervisionConfig";

const ConfigStatus = ({children, status, defaultConfig}) => (
    status === true ?
    <Badge.Ribbon text="Out of date" color="red">
        {children}
    </Badge.Ribbon>
    :
    defaultConfig ? 
    <Badge.Ribbon text="Default" color="blue">
        {children}
    </Badge.Ribbon>
    :
    children
)

const GlobalConfigs = () => {
    const abortControllerRef = useRef(new AbortController());
    const [configsLoading, setConfigsLoading] = useState(false);
    const [configs, setConfigs] = useState(null);
    const [defaultConfigId, setDefaultConfigId] = useState(localStorage.getItem(defaultConfigKey))
    const [modalOpen, setModalOpen] = useState(false);
    const [updatingConfigId, setUpdatingConfigId] = useState(null);
    const [deletionLoading, setDeletionLoading] = useState(null);
    const [copy, setCopy] = useState(false);

    const updateConfigsList = useCallback(() => {
        setConfigsLoading(true);
        getGlobalConfigs((data, error) => {
            setConfigsLoading(false);
            if (error) {
                message.error("Error while retrieving configs");
                return
            }
            console.log(data)
            setConfigs(data)
        }, abortControllerRef.current.signal)
    }, [setConfigsLoading, setConfigs])

    useEffect(() => {
        updateConfigsList();

        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    const setDefaultConfig = useCallback((config) => {
        if (config.id !== defaultConfigId) {
            if (!config.out_of_date) {
                localStorage.setItem(defaultConfigKey, config.id);
                setDefaultConfigId(config.id);
                message.success("Default config was changed to " + config.name);
            } else {
                message.error(config.name + " config is unavailable due to it is out of date");
            }
        }
    }, [defaultConfigId])

    const deleteConfig = useCallback((id) => {
        if (configs.length > 1) {
            setDeletionLoading(id);
            deleteGlobalConfig(id, (_, error) => {
                setDeletionLoading(null);
                if (error) {
                    message.error("Error while deletion config");
                    return
                }
                if (id === defaultConfigId) {
                    localStorage.removeItem(id);
                    setDefaultConfigId(null);
                }
                updateConfigsList();
            }, abortControllerRef.current.signal)
        } else {
            message.error("You can not delete the only config");
        }
    }, [setDeletionLoading, setDefaultConfigId, updateConfigsList, configs])

    const clearEvent = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const updateConfig = (id) => {
        setModalOpen(true);
        setUpdatingConfigId(id);
    }

    const copyConfig = (id) => {
        setModalOpen(true);
        setUpdatingConfigId(id);
        setCopy(true);
    }

    const closeModal = () => {
        setUpdatingConfigId(null);
        setModalOpen(false);
        setCopy(false);
    }

    return (
        <div>
            <Row style={{marginBottom: "10px"}}>
                <Button type="primary" onClick={() => setModalOpen(true)}>Create</Button>
            </Row>
            <Spin spinning={configsLoading} size="large" tip="Loading configs...">
                {configs && <Row>
                    {configs.map((config, inx) => {
                        return <Col key={inx} className="supervision-config-item">
                            <ConfigStatus status={config.out_of_date} defaultConfig={defaultConfigId === config.id}>
                                <Card
                                    hoverable={true}
                                    onClick={() => setDefaultConfig(config)}
                                    actions={[
                                        <SettingOutlined key={0} onClick={(e) => {
                                            clearEvent(e);
                                            updateConfig(config.id);
                                        }}/>,
                                        <CopyOutlined key={1} onClick={(e) => {
                                            clearEvent(e);
                                            copyConfig(config.id);
                                        }}/>,
                                        <Popconfirm
                                            key={2}
                                            title="Sure to remove the config?"
                                            onConfirm={(e) => {
                                                clearEvent(e);
                                                deleteConfig(config.id);
                                            }}
                                            onCancel={(e) => {
                                                clearEvent(e);
                                            }}
                                        >
                                            <DeleteOutlined onClick={(e) => clearEvent(e)}/>
                                        </Popconfirm>

                                    ]}
                                    >
                                    {deletionLoading === config.id ?
                                        <Spin spinning={true} size="large" tip="Deleting..."/> :
                                        <Card.Meta
                                            title={<b>{config.name}</b>}
                                            description={config.owner}
                                        />
                                    }
                                </Card>
                            </ConfigStatus>
                        </Col>
                    })}
                </Row>}
            </Spin>
        <ConfigCreator configId={updatingConfigId} updateList={updateConfigsList} 
            modalOpen={modalOpen} closeModal={closeModal} copy={copy}/>
        </div>
    )
}

export default GlobalConfigs