import React, {useState} from "react";


const SectionDescriptionEllipsis = ({children}) => {
    const [ellipsis, setEllipsis] = useState(true)
    return <div style={{maxWidth: "50em"}}>
         <div className={ellipsis ? "three-line-ellipsis" : undefined}>
             {children}
         </div>
        <a onClick={() => setEllipsis(!ellipsis)}>
            {ellipsis ? "Read more..." : "Hide description"}
        </a>
    </div>

}

export default SectionDescriptionEllipsis
