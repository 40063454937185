import {useAuth} from "@hooks/AuthHook";
import {reactToNotification} from "@API/ahooks/notifications";
import {Button, message, Modal, notification, Space} from "antd";
import React, {useEffect} from "react";
import {useRequest} from "ahooks";
import {useNavigate} from "react-router-dom";
import Constants from "@global/Constants";
import {ApiTwoTone} from "@ant-design/icons";

const Notifications = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const {
        data: reactionResponse,
        loading,
        runAsync: doReact,
        error
    } = useRequest((id, agree) => reactToNotification(id, agree), {
        manual: true
    })

    const replyToMessage = (server_notification, agree) => {
        doReact(server_notification.id, agree).then(({data}) => {
            if (data) {
                console.log(server_notification.id)
                notification.destroy(server_notification.id)
                server_notification['_archived'] = true
                if (agree) {
                    auth.setProposalListChange(prev => prev + 1);
                    message.success(data, 13).then(r => console.log('notification accepted'))
                    //auth.refreshAuth()
                    if (server_notification.type === 'invite_associate') {
                        let retail_name = server_notification?.props?.initiator_full_name
                        let proposalId = server_notification?.props?.proposal_id

                        if (!retail_name) {
                            retail_name = 'client'
                        }
                        if (proposalId) {
                            Modal.confirm({
                                title: 'New prospect',
                                cancelText: 'View later',
                                icon: <ApiTwoTone/>,
                                content: `Now you can work on the ${retail_name} individual accounts! How would you like to proceed?`,
                                footer: (_, {OkBtn, CancelBtn}) => (
                                    <>
                                        <Button type={'primary'} onClick={() => {
                                            navigate("/" + Constants.RIA_URL + "/proposals/" + proposalId, {replace: true})
                                            Modal.destroyAll();
                                            //ria/proposals/667c78bf4e1d7a89a538f115/client/general-info
                                        }}>Work on proposal</Button>
                                        <CancelBtn/>
                                    </>
                                ),
                            });
                        }
                    }
                } else {
                    message.warning(data, 13).then(r => console.log('notification declined'))
                }

            }
        }).catch((error) => {
            console.error(error);
            message.error("Failed to reply!")
        }).finally(() => {

        })
    }

    useEffect(() => {
        console.table(auth.notifications)
        for (let n of auth.notifications) {
            if (n['_archived']) {
                continue
            }

            const buttons = (
                <Space direction={"horizontal"}>
                    <Button size="small" type="primary"
                            onClick={() => {
                                replyToMessage(n, true);
                            }}>
                        Accept
                    </Button>
                    <Button size="small" danger type="ghost"
                            onClick={() => replyToMessage(n, false)}>
                        Decline
                    </Button>
                </Space>
            );
            notification['info']({
                message: n.message,
                description: n.description,
                duration: 103,
                key: n.id,
                btn: buttons
            });
        }
    }, [auth.user])

    return null
}

export default Notifications