import {Row, Col, List, Affix, Input, Button, Avatar, Typography, message, Divider} from 'antd';
import React, {useEffect, useRef, useState} from "react";
import {useUpdateEffect, useWebSocket} from "ahooks";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {useAuth} from "@hooks/AuthHook";
import {AudioOutlined, LoadingOutlined, SendOutlined} from "@ant-design/icons";
import {getTextByKey, highlight} from "@holistico/proposal/view/ai/textWorkers";
import {sendChatMessage} from "@API/ai";

function swapArrayElements(arr) {
    for (let i = 0; i < arr.length; i += 2) {
        if (i + 1 < arr.length) {
            // Swap pairs of elements
            [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]];
        }
    }
    return arr;
}

const ChatMessageContent = ({content, role}) => {
    if (typeof content == 'string') {
        if (role == 'user') {
            return <Typography.Text mark={true}>{content}</Typography.Text>
        }

        console.debug(`chat message: ${content}`)
        return <Typography.Text> {content.split("\n").map((par, index) => {
            return <Typography.Paragraph key={index}>
                {highlight(par)}
            </Typography.Paragraph>
        })}
        </Typography.Text>
    } else {
        return content
    }

}

const ChatMessage = ({role, content, user}) => {
    let avatarSrc = (role == 'assistant') ? '/img/cyber-master-of-coin.jpg' : user?.picture
    return (
        <List.Item style={{border: 1, borderColor: '#cccccc'}}>
            <List.Item.Meta
                avatar={<Avatar
                    src={avatarSrc}
                />}
                title={role == 'assistant' ? 'Master of coin' : user.meta.full_name}
                description={<ChatMessageContent content={content} role={role}/>}
            />

        </List.Item>
    )
}

const sortMessages = (a, b) => {
    if (a._id < b._id) {
        return 1;
    }
    if (a._id > b._id) {
        return -1;
    }

    // ids are equal, sort by role
    if (a.role === 'user' && b.role !== 'user') {
        return -1;
    }
    if (a.role !== 'user' && b.role === 'user') {
        return 1;
    }

    return 0;
}

const ClientChat = () => {
    // Ref for div
    const divRef = useRef(null);
    const searchInputRef = useRef(null);

    const auth = useAuth();
    const {id, conversation} = useHolisticoProposalContext()
    //useMemo to be used here
    const [messageList, setMessageList] = useState(conversation);
    const [currentMessage, setCurrentMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useUpdateEffect(() => {
        setMessageList(conversation)
    }, [conversation])

    const handleSend = (text) => {
        setLoading(true)

        let newMessages = [...messageList, {role: 'user', content: text}];
        newMessages = [...newMessages, {role: 'assistant', content: <LoadingOutlined/>}];
        setMessageList(newMessages);
        setCurrentMessage('')
        sendChatMessage(id, text, 'user', (data, err) => {
            setLoading(false)
            if (err) {
                console.error(e)
                message.error("ai communication error")
            } else {
                let mess = [...newMessages]
                mess[mess.length - 1] = data;
                console.log(data)
                setMessageList(mess);
            }
        })

    }

    const getMessagesToShow = (messages) => {
        if (messages.length == 0) {
            return []
        }
        let updatedMessages = [...messages].reverse();
        if (updatedMessages[updatedMessages.length - 1].role == 'user') {
            console.log('before ' + updatedMessages)
            updatedMessages = swapArrayElements(updatedMessages)
            console.log('after ' + updatedMessages)
        }
        return updatedMessages
    }

    const loadPreset = (key) => {
        let text = getTextByKey(key)
        setCurrentMessage(text)
        searchInputRef.current.focus();
    }

    return (
        <div className={'page-content'}>
            <Row>
                <Col span={24} style={{height: 80, width: '100%'}}>
                    <Input.Search
                        bordered={true}
                        placeholder={'type yr message here'}
                        autoFocus={true}
                        ref={searchInputRef}
                        size={'large'}
                        loading={loading}
                        disabled={loading}
                        value={currentMessage}
                        enterButton={<Button icon={<SendOutlined/>} shape="round" type="outlined" size="middle"
                                             loading={loading}/>}
                        onSearch={handleSend}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                    />
                    <Button type={"link"} size={"small"} onClick={() => loadPreset('points')}>talking
                        points</Button>
                    <Button type={"link"} size={"small"} onClick={() => loadPreset('prosandcons')}>pros and
                        cons</Button>
                    <Button type={"link"} size={"small"} onClick={() => loadPreset('impression')}>overall
                        impression</Button>
                    <Button type={"link"} size={"small"}
                            onClick={() => loadPreset('suggestions')}>suggestions</Button>
                    <Button type={"link"} size={"small"}
                            onClick={() => loadPreset('vs_macro')}>in market context</Button>
                </Col>
                <Divider dashed={true} style={{marginTop: 4, marginBottom: 4}}/>
                <Col span={24}>
                    <div ref={divRef}>
                        <List
                            gutter={12}
                            dataSource={getMessagesToShow(messageList)}
                            size={'small'}
                            renderItem={(item) => {
                                return <ChatMessage content={item.content} role={item.role}
                                                    user={auth.user}/>
                            }}

                        >
                            {!messageList.length &&
                                <ChatMessage content={'Hi human, lets talk about your client portfolio'}
                                             role={'assistant'}
                                             user={auth.user}/>}
                        </List>
                    </div>
                </Col>

            </Row>
        </div>

    )
}

export default ClientChat;
