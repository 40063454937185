/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {Area, G2} from '@antv/g2plot';

import ValueFormatter from "@global/ValueFormatter";
import {Typography} from "antd";
import {DatabaseOutlined} from "@ant-design/icons";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";

export const retirementAmountSuccessProbability = prob => {
    if (prob < 5) {
        return "Low "
    } else if (prob >= 5 && prob <= 95) {
        return ValueFormatter.softPercent(prob / 100)
    } else if (prob > 95) {
        return "High "
    } else {
        return "-"
    }
}

const RetirementGraph = ({
                             retirementData,
                             lifeExpectancy,
                             investmentsAmount,
                             divId = "retirementGraphContainer",
                             height = 400,
                             showTips = true,
                             noDataText="No data"
                         }) => {
    useEffect(() => {
        if (!retirementData) {
            return
        }
        let meanPoint = false;
        G2.registerShape('point', 'retirement-point', {
            draw(cfg, container) {
                const data = cfg.data;
                const point = {x: cfg.x, y: cfg.y};

                if (data.date === retirementData.retirement_date && meanPoint) {
                    const group = container.addGroup();
                    group.addShape({
                        type: 'circle',
                        attrs: {
                            x: point.x,
                            y: point.y[1],
                            r: 3,
                            fill: cfg.color,
                        },
                    });
                    const animatedCircle = group.addShape({
                        type: 'circle',
                        attrs: {
                            x: point.x,
                            y: point.y[1],
                            r: 5,
                            fill: cfg.color,
                            opacity: 0.7,
                        },
                    });
                    animatedCircle.animate(
                        {
                            r: 10,
                            opacity: 0.1,
                        },
                        {
                            duration: 1400,
                            easing: 'easeLinear',
                            repeat: true,
                        }
                    );
                    group.addShape({
                        type: 'text',
                        attrs: {
                            text: `Retirement`,
                            fill: "#6e6e6e",
                            x: point.x,
                            y: point.y[1] - 15.,
                            textAlign: 'center',
                            fontSize: "13px",
                            fontWeight: "bolder"
                        },
                    });

                    meanPoint = false;
                } else if (data.date === retirementData.retirement_date && !meanPoint) {
                    meanPoint = true;
                }
            },
        });

        let fullData = [];
        let forTooltip = {};
        let firstChunk = true;
        let mod = retirementData.chart_data.length > 300 ? 6 : 3;
        for (let chunk of retirementData.chart_data) {
            if ((new Date(chunk.date).getMonth() + 1) % mod !== 0 &&
                !firstChunk &&
                chunk.date !== retirementData.retirement_date) {
                continue
            }
            firstChunk = false;

            const max = parseInt(chunk.upper_bound);
            const av = parseInt(chunk.median);
            const min = parseInt(chunk.lower_bound);

            fullData.push({
                type: 'Best Case',
                date: chunk.date,
                value: max - av
            })
            fullData.push({
                type: 'Most Probable',
                date: chunk.date,
                value: av - min
            })

            fullData.push({
                type: 'Worst Case',
                date: chunk.date,
                value: min
            })

            forTooltip[chunk.date] = {
                'Best Case': max,
                'Most Probable': av,
                'Worst Case': min
            }
        }

        // MUST CLEAN UP BEFORE RENDER
        let graphContainer = document.getElementById(divId);
        while (graphContainer.hasChildNodes()) {
            graphContainer.removeChild(graphContainer.lastChild);
        }


        const areaPlot = new Area(divId, {
            data: fullData,
            isStack: true,
            xField: 'date',
            yField: 'value',
            seriesField: 'type',
            xAxis: {
                // tickCount: 8
                tickInterval: 12,
                min: -4,
                label: {
                    formatter: (v) => v.split("-")[0]
                }
            },
            yAxis: {
                tickCount: 6,
                label: {
                    formatter: (v) => ValueFormatter.compactCurrency(v)
                }
            },
            autoFit: true,
            renderer: 'svg',
            animation: {
                appear: {
                    animation: 'path-in',
                    duration: 5000,
                },
            },
            legend: false,
            color: ["#5ad8a6", "#499871", "#ffffff"],
            tooltip: {
                shared: true,
                formatter: (tooltipOptions) => {
                    let fvalue = ValueFormatter.currency(forTooltip[tooltipOptions.date][tooltipOptions.type])
                    let newOptions = {
                        ...tooltipOptions,
                        value: fvalue,
                        name: tooltipOptions.type
                    }
                    return newOptions
                }
            },
            description: {
                visible: true
            },
            point: {
                shape: 'retirement-point',
            },
            areaStyle: {
                lineWidth: 0.5,
                lineDash: [4, 5],
                strokeOpacity: 0.7,
                cursor: 'pointer'
            },
        });
        areaPlot.render();
    }, [retirementData]);

    return <div style={{margin: "0 12px 24px 12px"}}>
        {retirementData
            ?
            <>
                {/*{investmentsAmount*/}
                {/*    &&*/}
                {/*     <Typography.Paragraph style={{margin: 0}}>*/}
                {/*         {"Initial investments: "}*/}
                {/*         {ValueFormatter.currency(investmentsAmount)}*/}
                {/*    </Typography.Paragraph>*/}
                {/*}*/}
                {retirementData?.projected_at_retirement && showTips
                    &&
                    <Typography.Paragraph style={{margin: 0}}>
                        {"Projected wealth at retirement: "}
                        {ValueFormatter.currency(retirementData.projected_at_retirement)}
                    </Typography.Paragraph>
                }
                {showTips
                    &&
                    <Typography.Paragraph>
                        {retirementAmountSuccessProbability(100 - retirementData?.survival_probability)}
                        {" probability of running out of money when you turn "}
                        {lifeExpectancy}
                    </Typography.Paragraph>
                }
                <div id={divId}
                     style={{
                         height: height,
                         width: '100%',
                         marginTop: showTips ? 35 : 0
                     }}/>
            </>
            :
            <NoDataPlaceholder description={noDataText}/>
            // <div className="noDataBox">
            //     <DatabaseOutlined style={{fontSize: '2em'}}/>
            //     <div>No data for the retirement chart</div>
            // </div>
        }
    </div>
}

export default RetirementGraph;
