import {Col, Row, Space} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import React from "react";
import ValueFormatter from "@global/ValueFormatter";
import StaticInfo from "@global/StaticInfo";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
} from "@ant-design/icons";
import HStatistic from "@holistico/aux/HStatistic";


const PerformanceStatistics = ({riskScore}) => {
    const {proposal} = useHolisticoProposalContext()

    const riskReturns = StaticInfo.riskReturns1Y[riskScore]
    const mkval = proposal?.p_bucket_cur?.calculated_values?.market_value
    const lowerBoundPercent = riskReturns["Lower Bound"] < 0 ? -riskReturns["Lower Bound"] : 0
    const lowerBoundAmount = lowerBoundPercent * mkval
    const upperBoundPercent = riskReturns["Upper Bound"]
    const upperBoundAmount = upperBoundPercent * mkval

    return <>{proposal
        && <Row gutter={[24, 24]} style={{marginBottom: "48px"}}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <HStatistic
                    title="Possible loss"
                    info={{
                        content: "Annual performance can fall below this level with 5% probability"
                    }}
                >
                    <Space style={{
                        color: '#f5222d',
                        fontSize: "60px",
                        fontWeight: 600,
                        lineHeight: 1,
                        margin: "16px 0",
                        fontFeatureSettings: '"tnum", "tnum"'
                    }}>
                        <ArrowDownOutlined/>
                        {ValueFormatter.softPercent(lowerBoundPercent)}
                    </Space>
                    <div style={{
                        color: "rgba(245, 34, 45, 0.6)",
                        fontSize: "36px",
                        marginLeft: "70px",
                        lineHeight: 1,
                        fontFeatureSettings: 'tnum, tnum'
                    }}>
                        {ValueFormatter.compactCurrency(lowerBoundAmount)}
                    </div>
                </HStatistic>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <HStatistic
                    title="Possible gain"
                    info={{
                        content: "Annual performance can rise above this level with 5% probability"
                    }}
                >
                    <Space style={{
                        color: '#49aa19',
                        fontSize: "60px",
                        fontWeight: 600,
                        lineHeight: 1,
                        margin: "16px 0",
                        fontFeatureSettings: '"tnum", "tnum"'
                    }}>
                        <ArrowUpOutlined/>
                        {ValueFormatter.softPercent(upperBoundPercent)}
                    </Space>
                    <div style={{
                        color: "rgba(134, 189, 145, 0.7)",
                        fontSize: "36px",
                        marginLeft: "70px",
                        lineHeight: 1,
                        fontFeatureSettings: 'tnum, tnum'
                    }}>
                        {ValueFormatter.compactCurrency(upperBoundAmount)}
                    </div>
                </HStatistic>
            </Col>
        </Row>
    }</>
}

export default PerformanceStatistics;
