import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;
const CONFIGURATION_URL = "/configuration"

const getDefaultGlobals = (globalsHandler) => {
    let url = `${CONFIGURATION_URL}/default_factor_values`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            globalsHandler(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                globalsHandler(null, response)
            } else {
                globalsHandler(null, 'Unknown error!')
            }
        })
}

const getAuthConfigurationAsync = async () => {
    let url = `${CONFIGURATION_URL}/auth`
    return await axiosInstance.get(url)
}
const getAuthConfiguration = (handler) => {
    let url = `${CONFIGURATION_URL}/auth`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            handler(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                handler(null, response)
            }
        })
};
const getMarketAssumptions = (assumptionsHandler) => {
    let url = `${CONFIGURATION_URL}/assumptions`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            assumptionsHandler(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                assumptionsHandler(null, response)
            }
        })
};

const getOnline = async () => {
    let url = `${CONFIGURATION_URL}/online`
    return await axiosInstance.get(url)
}
export {
    getMarketAssumptions,
    getDefaultGlobals,
    getAuthConfiguration,
    getAuthConfigurationAsync,
    getOnline,
    cancel
}
