import React, {useEffect, useState} from "react";
import {Modal, Progress} from "antd";


const FakeProgress = ({tip, open, time=70000}) => {
    const [percent, setPercent] = useState(0);
    const timerRef = React.useRef();

    useEffect(() => {
        if(open) {
            setPercent(0);
            timerRef.current = progressIntervalCaller();
        } else {
            clearInterval(timerRef.current);
        }
        return () => clearInterval(timerRef.current);
    }, [open]);

    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setPercent(Math.round(progress));
        }, time / 100)
    }

    return <Modal
        title={null}
        footer={null}
        closable={false}
        maskClosable={false}
        open={open}
    >
        {tip}
        <Progress percent={percent} status="active"/>
    </Modal>
}


export default FakeProgress;
