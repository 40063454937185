import React from "react";
import {Checkbox, Flex, Form} from 'antd';


const StyleInput = ({allStyles, type, hidden = false}) => {
    return <Form.Item
        name={"vbg_style"}
        label={<b>Style</b>}
        hidden={hidden}
    >
        <Checkbox.Group>
            <Flex  vertical>
                {allStyles.map(style => {
                    return <Checkbox key={style} value={style}>
                        {style}
                    </Checkbox>
                })}
            </Flex>
        </Checkbox.Group>
    </Form.Item>
}

export default StyleInput
