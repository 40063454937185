import {Button, Divider, message, Modal, Transfer, Typography} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {SelectOutlined} from "@ant-design/icons";
import {getModels} from "@API/portfolio";
import AllocationsPresetManager from "@components/allocations/AllocationsPresetManager";

const SelectPortfoliosModal = ({selection, updateSelection}) => {
    const [visible, setVisible] = useState(false)
    //right side
    const [targetKeys, setTargetKeys] = useState([]);
    const [available, setAvailable] = useState([])

    useEffect(() => {
        console.log('selection change ' + selection, selection.length)
        setTargetKeys(selection)
    }, [selection])

    const loadAvailable = useCallback(() => {
        getModels(false, null, (data, error) => {
            if (error) {
                message.error("Error retrieving models data!")
                return

            }
            let all = data.map(p => {
                return {
                    key: p._id,
                    title: p.name,
                    description: p.name,
                }
            })
            setAvailable(all)
            setVisible(true)
        });
    }, []);

    useEffect(() => {
        loadAvailable()
    }, [])

    const hide = () => {
        setVisible(false)
    }
    
    const loadPreset = (preset) => {
        console.info(`${preset.name} selected`)
        console.info(preset)
        setTargetKeys(preset['definition_ids'])
    }

    const submit = () => {
        updateSelection(targetKeys)
        hide()
    }

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    return <><Button
        type="primary"
        icon={<SelectOutlined/>}
        onClick={() => setVisible(true)}>
        Select Portfolios
    </Button>
        <Modal
            open={visible}
            width={700}
            title="Configuration"
            onOk={() => submit()}
            onCancel={() => hide()}
            okText="Ok"
        >
            <AllocationsPresetManager presetSelected={(preset) => loadPreset(preset)} definitionIds={targetKeys}/>
            <Divider/>
            <Typography.Paragraph>Select portfolios on the left and move them to the right using the button in the middle. Push OK to load the allocation table for selected portfolios.</Typography.Paragraph>
            <Transfer
                className={'select-portfolios-transfer'}
                dataSource={available}
                oneWay={true}
                titles={['Available', 'Selected']}
                targetKeys={targetKeys}
                onChange={onChange}
                render={(item) => item.title}
            />
        </Modal></>;
}

export default SelectPortfoliosModal
