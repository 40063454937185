import React, {useEffect, useState} from "react";
import {Table} from 'antd';
import Localizator from "@global/Localizator";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import dayjs from "dayjs";


const PERCENTAGES = ['alpha', 'return_portfolio', 'return_benchmark'];
const INTS = ['n_obs_portfolio', 'n_obs_benchmark'];


const formatMonth = (value) => {
    let curMom = dayjs("2024-" + value + "-01");
    return curMom.format("MMMM");
}

const cellRenderer = (value, record, key) => {
    if (isNaN(value) && (PERCENTAGES.includes(key) || INTS.includes(key))) {
        return ''
    }

    let formattedValue = value;
    if (PERCENTAGES.includes(key)) {
        formattedValue = ValueFormatter.int_percent(value, '-');
    } else if (INTS.includes(key)) {
        formattedValue = parseInt(value);
    } else if (key === 'month') {
        formattedValue = formatMonth(value);
    }

    return formattedValue;
}


const onCell = (record, rowIndex, column) => {
    let value = record[column]
    let props = {}

    if (isNaN(value)) {
        return {};
    }

    if (PERCENTAGES.includes(column)) {
        let color = ChartStyles.intPerformance2color(value, column === "alpha");
        if (color) {
            props = {style: {background: color}};
        }
    }

    return props;
}


const tableColumns = [
    {
        title: 'Alpha',
        dataIndex: 'alpha',
        key: 'alpha',
        width: 120,
        align: "right",
        render: (value, record) => cellRenderer(value, record, 'alpha'),
        onCell: (record, rowIndex) => onCell(record, rowIndex, 'alpha'),
    }, {
        title: 'Return p.',
        dataIndex: 'return_portfolio',
        key: 'return_portfolio',
        width: 120,
        align: "right",
        render: (value, record) => cellRenderer(value, record, 'return_portfolio'),
        onCell: (record, rowIndex) => onCell(record, rowIndex, 'return_portfolio'),

    }, {
        title: '# obs. p.',
        dataIndex: 'n_obs_portfolio',
        key: 'n_obs_portfolio',
        width: 120,
        align: "right",
        render: (value, record) => cellRenderer(value, record, 'n_obs_portfolio'),
        onCell: (record, rowIndex) => onCell(record, rowIndex, 'n_obs_portfolio'),
    },
    {
        title: 'Return b.',
        dataIndex: 'return_benchmark',
        key: 'return_benchmark',
        width: 120,
        align: "right",
        render: (value, record) => cellRenderer(value, record, 'return_benchmark'),
        onCell: (record, rowIndex) => onCell(record, rowIndex, 'return_benchmark'),
    }, {
        title: '# obs. b.',
        dataIndex: 'n_obs_benchmark',
        key: 'n_obs_benchmark',
        width: 120,
        align: "right",
        render: (value, record) => cellRenderer(value, record, 'n_obs_benchmark'),
        onCell: (record, rowIndex) => onCell(record, rowIndex, 'n_obs_benchmark'),
    }
];


const tableOptions = tableColumns.map(col => {
    return {label: col.title, value: col.key};
})


const getFilters = (column, records) => {
    let filters = [];

    let track = new Set();
    let uniqueRecords = [...records].filter((record) => (
        track.has(record[column]) ? false : track.add(record[column])));

    if (column === "month") {
        uniqueRecords.forEach(function (record) {
            filters.push({
                text: formatMonth(record[column]),
                value: record[column]
            })
        });
        filters = filters.sort(function (a, b) {
            return a["value"] - b["value"];
        });
    } else {
        uniqueRecords.forEach(function (record) {
            filters.push({text: "" + record[column], value: record[column]})
        });
        filters = filters.sort(function (a, b) {
            return a["text"].localeCompare(b["text"]);
        })
    }
    return filters;
}


const BacktestAggregationTable = ({
                                      rows,
                                      group_by,
                                      loading = false
                                  }) => {
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [columnSelectorValues, setColumnSelectorValues] = useState(tableColumns.map(c => c.key));

    useEffect(() => {
        updateColumns()
    }, [rows, columnSelectorValues])

    const updateColumns = () => {
        if (group_by) {
            let columns = [];
            let group_by_ext = group_by.includes("month") ? ["year", ...group_by] : group_by;
            for (let factor of group_by_ext) {
                let group_column = {
                    title: Localizator.t(`factor.${factor}`),
                    dataIndex: factor,
                    key: factor,
                    filters: getFilters(factor, rows),
                    render: (value, record) => cellRenderer(value, record, factor),
                    onCell: (record, rowIndex) => onCell(record, rowIndex, factor),
                    onFilter: (value, record) => record[factor] === value,
                }
                columns.push(group_column)
            }
            let selectedColumns = tableColumns.filter(col => columnSelectorValues.includes(col.key))
            columns.push(...selectedColumns);
            setDynamicColumns(columns);
        }
    }

    const onColumnsChange = (newValues) => {
        setColumnSelectorValues(newValues)
    }

    return <div style={{minHeight: 500}}>
        {/*<Checkbox.Group*/}
        {/*    style={{width: '100%', textAlign: 'right', padding: 8}}*/}
        {/*    options={tableOptions}*/}
        {/*    value={columnSelectorValues}*/}
        {/*    onChange={onColumnsChange}*/}
        {/*/>*/}
        <Table
            rowKey={'ui_key'}
            size="small"
            loading={loading}
            dataSource={rows}
            columns={dynamicColumns}
            pagination={{
                defaultPageSize: 300,
                hideOnSinglePage: true,
                pageSize: 300,
                position: 'topRight'
            }}
            // className={'heatmap-table'}
        />
    </div>
}

export default BacktestAggregationTable
