const STORAGE = window.sessionStorage
export default class LStorage {

    /**
     *
     * @param value benchmark id
     */
    static setDefaultFactorBenchmark(value) {
        STORAGE.setItem('defaultFactorBenchmark', value)
    }

    /**
     *
     * @returns {string} deafult benchmark ID
     */
    static getDefaultFactorBenchmark() {
        return STORAGE.getItem('defaultFactorBenchmark')
    }

    static setShowPerformanceInReport(value) {
        STORAGE.setItem('showPerformanceInReport', value)
    }

    static setShowExpRatioInReportPositionTable(value) {
        STORAGE.setItem('showExpRatioInReportPositionTable', value)
    }

    static setShowDividendsInReportPositionTable(value) {
        STORAGE.setItem('showDividendsInReportPositionTable', value)
    }

    static showExpRatioInReportPositionTable() {
        let show = STORAGE.getItem('showExpRatioInReportPositionTable')

        let toReturn = (show == 'true')
        return toReturn
    }

    static showDividendsInReportPositionTable() {
        let show = STORAGE.getItem('showDividendsInReportPositionTable')

        let toReturn = (show == 'true')
        return toReturn
    }

    static showPerformanceInReport() {
        let show = STORAGE.getItem('showPerformanceInReport')

        let toReturn = (show == 'true')
        return toReturn
    }

    static get(label) {

    }
}
