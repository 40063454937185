/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Col, InputNumber, List, Modal, Row, Typography} from 'antd';

import './PortfolioWeighter.less'
import ValueFormatter from "@global/ValueFormatter";

const PortfolioWeighter = ({
                               visible = false,
                               existingPortfolios = [],
                               clientTotal = 100000,
                               doneCallback
                           }) => {
    const [pWeights, setPWeights] = useState({})
    const [changed, setChanged] = useState(false)

    const done = (save) => {
        console.log('done')
        //converting to market values
        let pValues = {}
        for (let p_id of Object.keys(pWeights)) {
            pValues[p_id] = ValueFormatter.round(pWeights[p_id]/100 * clientTotal, 1)
        }
        doneCallback((save && changed), pValues)
    }

    useEffect(() => {
        console.log(existingPortfolios)
        let wProxy = {}
        for (let ep of existingPortfolios) {
            wProxy[ep._id] = ValueFormatter.round(ep.weight*100, 1)
        }
        setPWeights(wProxy)
    }, [existingPortfolios, visible])

    const fieldChanged = (field_type, portfolio_id, value) => {
        setPWeights({...pWeights, [portfolio_id]: value})
        setChanged(true)
    }

    return (
        <Modal
            title="Reallocating capital"
            open={visible}
            closable={false}
            maskClosable={true}
            onCancel={() => done(false)}
            onOk={() => done(true)}
            className="holistico"
            width={750}
            style={{background: "none"}}
        >
            <Typography.Text
                className={"hint"}
                style={{display: "block", marginBottom: "16px", fontSize: "18px"}}
            >
                Please reallocate client's
                {" " + ValueFormatter.compactCurrency(clientTotal) + " "}
                between your proposals
            </Typography.Text>
            <List
                dataSource={existingPortfolios}
                renderItem={(p) => {
                    return <Row key={p._id} gutter={[24, 24]}
                                className={"selection-bg-on-hover"}>
                        <Col span={15}
                             style={{display: "flex", alignItems: "center"}}>
                            <Typography.Text style={{fontSize: "20px"}}>
                                {p.name}
                            </Typography.Text>
                        </Col>
                        <Col span={5}
                             style={{display: "flex", alignItems: "center"}}>
                            <InputNumber
                                step={0.1}
                                min={0}
                                placeholder="weight"
                                name={p._id}
                                value={pWeights[p._id]}
                                addonAfter={"%"}
                                onChange={value => fieldChanged('weight', p._id, value)}
                                style={{margin: "4px 0"}}
                            />
                        </Col>
                        <Col span={4}
                             style={{display: "flex", alignItems: "center"}}>
                            <Typography.Text className={"hint"}
                                             style={{fontSize: "20px"}}>
                                {ValueFormatter.compactCurrency(clientTotal * (pWeights[p._id] / 100))}
                            </Typography.Text>
                        </Col>
                    </Row>
                }}
            />
        </Modal>
    )
}

export default PortfolioWeighter;
