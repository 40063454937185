import React, {useEffect, useState, useCallback} from "react";
import {getUser, cancel} from "@API/users";
import {useParams, Route, Routes, useNavigate, Navigate} from 'react-router-dom';
import {Spin, message, Tabs} from "antd";
import UserDetails from "./UserDetails";
import UserActivity from "./UserActivity";

const UserMenu = ({}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        retrieveUserDetails(true);
        return () => {
            cancel && cancel();
        }
    }, [])

    const retrieveUserDetails = useCallback((redirect = false) => {
        getUser(id, (data, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while retrieving user data" + error);
                return
            }
            setUser(data);
            if (redirect) {
                navigate(`details`);
            }
        })
    }, [id, setUser, setLoading, loading])

    const onTabChange = (key) => {
        navigate(`./${key}`)
    }

    return (
        <Spin size="large" spinning={loading}>
            {user ?
                <div style={{width: '100%'}}>
                    <Tabs defaultActiveKey="details" onChange={onTabChange}>
                        <Tabs.TabPane tab="Details" key="details">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Activity" key="activity">
                        </Tabs.TabPane>
                    </Tabs>
                    <Routes>
                        <Route path="" element={<Navigate to="details" replace={true}/>}/>
                        <Route path="activity" element={<UserActivity email={user.email}/>}/>
                        <Route path="details" element={<UserDetails user={user} getUser={retrieveUserDetails}/>}/>
                    </Routes>
                </div>
                :
                <div style={{height: "400px"}}></div>
            }
        </Spin>
    )
}

export default UserMenu
