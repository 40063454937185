import React from "react";
import {Flex, Form, Radio, Segmented, Select, Space, Tooltip} from 'antd';
import {QuestionCircleOutlined} from "@ant-design/icons";


const AggTypeInput = ({field}) => {
    const tooltipText =
        'Defines how to weight companies inside groups. ' +
        'If "Median return" is chosen, then median return ' +
        'for each group will be used.';

    return <Flex gap={"small"} align={"center"}>
        <Form.Item name={[field, "agg_type"]}>
            {/*<Select*/}
            {/*    options={[*/}
            {/*        {label: "Market cap weighted", value: "wmean"},*/}
            {/*        {label: "Equally weighted", value: "mean"},*/}
            {/*        {label: "Median return", value: "median"},*/}
            {/*    ]}*/}
            {/*    style={{*/}
            {/*        width: 180*/}
            {/*    }}*/}
            {/*/>*/}
            {/*<Segmented*/}
            {/*    options={[*/}
            {/*        {label: "Market cap weighted", value: "wmean"},*/}
            {/*        {label: "Equally weighted", value: "mean"},*/}
            {/*        {label: "Median return", value: "median"},*/}
            {/*    ]}*/}
            {/*/>*/}
            <Radio.Group
                    options={[
                    {label: "Market cap weighted", value: "wmean"},
                    {label: "Equally weighted", value: "mean"},
                    {label: "Median return", value: "median"},
                ]}
            />
            </Form.Item>
        <Tooltip title={tooltipText}>
            <QuestionCircleOutlined style={{color: "rgba(0, 0, 0, 0.45)"}}/>
        </Tooltip>
    </Flex>
}

export default AggTypeInput
