import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Dropdown, Menu, message, Table, Typography} from "antd";
import {DeleteOutlined, EditOutlined, EllipsisOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {deleteJob, getJobs} from "@API/scheduler";
import JobEditor from "./JobEditor";
import {toCronString} from "@global/DateUtils";
import {dddMMMDDHHmm} from "@global/DateFormatter";

const DATE_TIME_FORMAT = 'MMM DD, HH:mm';

const JobsList = () => {
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState(null);
    const [jobEditor, setJobEditor] = useState(false); // string | true | false
    const [editingJob, setEditingJob] = useState(null);
    const [tqUpdateIndicator, setTqUpdateIndicator] = useState(null);
    const abortControllerRef = useRef(new AbortController());

    const getJobsProxy = useCallback(() => {
        setLoading(true);
        getJobs((data, error) => {
            setLoading(false);
            if (error) {
                message.error(`Error while retrieving scheduler jobs`);
                return
            }
            console.log(data)
            setJobs(data);
        }, abortControllerRef.current.signal)

    }, [setJobs, setLoading])

    const deleteJobProxy = useCallback((id) => {
        setLoading(true);
        deleteJob(id, (_, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while deleting job");
                return
            }
            message.success("Scheduler job was deleted successfully");
            getJobsProxy();
            setTqUpdateIndicator(v => !v);
        }, abortControllerRef.current.signal)
    }, [getJobsProxy, setLoading, setTqUpdateIndicator])

    const generateDropdownList = useCallback((fullJob) => {
        return <Menu items={[
            {
                key: '1',
                label: (
                    <div onClick={() => {
                        setJobEditor(true);
                        setEditingJob(fullJob);
                    }}><EditOutlined/> Update job</div>
                ),
            },
            {
                key: '2',
                label: (
                    <div onClick={() => {
                        deleteJobProxy(fullJob.id);
                    }}><DeleteOutlined/> Delete job</div>
                ),
                danger: true,
            },
        ]}/>
    }, [setJobEditor, setEditingJob, deleteJobProxy])

    const columns = useMemo(() => {
        return [
            {
                title: 'Title',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (_, {kwargs, name}) => {
                    let entity = kwargs.hasOwnProperty("task_id") ? "task" : "queue";
                    let id = kwargs[`${entity}_id`];
                    return <Link to={id}>{name}</Link>
                }
            },
            {
                title: 'Entity',
                key: 'entity',
                filters: [
                    {
                        text: 'Queue',
                        value: 'queue',
                    },
                    {
                        text: 'Task',
                        value: 'task',
                    }
                ],
                onFilter: (value, record) => record.kwargs.hasOwnProperty(`${value}_id`),
                render: (_, {kwargs}) => (
                    kwargs.hasOwnProperty("task_id") ? "Task" : "Queue"
                )
            },
            {
                title: 'Next run',
                key: 'next_run',
                render: (_, {next_run_time}) => {
                    //return ValueFormatter.pythonDateFancy(next_run_time, false)
                    return dddMMMDDHHmm(next_run_time)
                },
                defaultSortOrder: 'descend',
                sorter: (a, b) => ('' + a['next_run']).localeCompare(b['next_run'])
            },
            {
                title: 'Cron',
                key: 'cron',
                render: (_, fullJob) => {
                    return  "(UTC) " + toCronString(fullJob) + ""
                },
            },
            {
                title: '',
                key: 'action',
                render: (_, fullJob) => {
                    const menu = generateDropdownList(fullJob);
                    return <Dropdown trigger={['click']} overlay={menu}>
                        <EllipsisOutlined onClick={e => {
                            // e.preventDefault()
                            e.stopPropagation()
                        }}/>
                    </Dropdown>
                },
            }
        ]
    }, [generateDropdownList])

    useEffect(() => {
        getJobsProxy();
        return () => abortControllerRef.current.abort()
    }, [])

    return (
        <>
            <Button type="primary" style={{marginTop: "10px"}}
                    onClick={() => setJobEditor(true)}
            >
                Create job
            </Button>
            <Typography.Paragraph type={'secondary'} style={{paddingTop: 24}}>Table timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</Typography.Paragraph>
            <Table columns={columns} dataSource={jobs} loading={loading} pagination={{pageSize: 30}}
                   style={{marginTop: "10px"}}/>
            <JobEditor addModalOption={jobEditor}
                       setAddModal={() => {
                           setEditingJob(null);
                           setJobEditor(false);
                       }}
                       afterFinish={() => {
                           setEditingJob(null);
                           getJobsProxy();
                       }}
                       schedulerObjectBase={editingJob ? {
                           name: editingJob.name,
                           _id: editingJob.kwargs.hasOwnProperty("task_id") ? editingJob.kwargs.task_id : editingJob.kwargs.queue_id,
                           entity: editingJob.kwargs.hasOwnProperty("task_id") ? "task" : "queue"
                       } : null}
                       jobSettings={editingJob}
                       tqUpdateIndicator={tqUpdateIndicator}
            />
        </>
    )
}

export default JobsList