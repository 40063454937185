import {Col, Empty, Row} from 'antd'
import AssetClassPie from "@holistico/chart/AssetClassPie";
import React from "react";

const AssetClassCompareRow = ({original_values, recommended_values}) => {
    if (!original_values || Object.keys(original_values).length === 0) return <Empty style={{margin: "15px 0"}}
                                                                 description="No asset class information"/>;

    return <Row style={{width: '100%'}} gutter={[24,24]}>
        <Col span={12}>
            <AssetClassPie allocation={original_values?.asset_class} showChart={true} showLegend={false}/>
        </Col>
        <Col span={12}>
            <AssetClassPie allocation={recommended_values?.asset_class} showChart={true} showLegend={false}/>
        </Col>
    </Row>
}

export default AssetClassCompareRow
