import Localizator from "@global/Localizator";
import ValueFormatter from "@global/ValueFormatter";
import BuySign from "@components/portfolio/position/BuySign";
import HoldSign from "@components/portfolio/position/HoldSign";
import SellSign from "@components/portfolio/position/SellSign";
import Rainbow from 'rainbowvis.js'

export default class ChartStyles {
    static colors = [
        "rgb(85, 157, 180)",
        "rgb(217, 174, 108)",
        "rgb(39, 60, 87)",
        "rgb(175, 99, 65)",
        "rgb(232, 242, 245)",
        "rgb(134, 189, 145)",
        "rgb(149, 206, 223)",
        "rgb(254, 136, 177)",
        "rgb(201, 219, 116)",
        "rgb(139, 224, 164)",
        "rgb(180, 151, 231)",
        "rgb(179, 179, 179)",
        "rgb(128,96,52)",
        "rgb(197,238,221)",
        "rgb(93,102,110)",
        "rgb(148,55,55)",
        "rgb(93,81,81)",
    ]

    //TODO: we should get all this stuff from server, including the colors
    static sizes = ['Large', 'Medium', 'Small', 'Micro']
    static recommendations = ['Buy', 'Sell', 'Hold', 'N/A']
    static fi_sectors = ['Cash & Equivalents', 'Corporate', 'Derivative', 'Government', 'Municipal', 'Securitized']
    static size_styles = ['Large Growth', 'Large Value', 'Large Blend', 'Medium Growth',
        'Medium Value', 'Medium Blend', 'Small Growth', 'Small Value', 'Small Blend']
    static securityTypes = ['US Equity', 'Non-US Equity', 'US Bond', 'Non-US Bond', 'Other', 'Cash', 'Mixed']
    static assetClasses = ['Stock', 'ADR', 'Bond', 'ETF', 'MF', 'MM', 'Index', 'Cash', 'Other']
    static sectors = ['Information Technology', 'Health Care', 'Communication Services', 'Consumer Discretionary',
        'Consumer Staples', 'Energy', 'Financials', 'Industrials', 'Materials', 'Real Estate']
    static representations = [
        'Classifications',
        'Financial Reports Data',
        'General Info',
        'Indicators',
        'Original Market Data',
        'Original Report Data',
        'Performance',
        'Prices',
        'Rate Spreads',
        'Rates and Yields',
        'Ratios and Other Calculated Factors',
        'Return Spreads',
        'Returns',
        'Scores, Ratings, and Ranks'
    ]


    static assetClassColor(label, no_blured = false) {

        let color_index = this.assetClasses.indexOf(label)
        if (color_index > -1) {
            return this.colors[color_index]
        } else {
            return this.colors[13]
        }
    }

    static securityTypeColor(label, no_blured = false) {
        if (label === 'Other') {
            return this.colors[10]
        }

        let color_index = this.securityTypes.indexOf(label)
        if (color_index > -1) {
            return this.colors[color_index]
        } else {
            return this.colors[13]
        }
    }

    static sectorColor(label) {
        let color_index = this.sectors.indexOf(label)
        if (color_index > -1) {
            return this.colors[color_index]
        } else {
            return this.colors[13]
        }
    }

    static fiSectorColor(label) {
        let color_index = this.fi_sectors.indexOf(label)
        if (color_index > -1) {
            return this.colors[color_index]
        } else {
            return this.colors[13]
        }
    }

    static sizeColor(label) {
        let color_index = this.sizes.indexOf(label)
        let fixed_label = Localizator.t('stock.' + label)
        if (color_index > -1) {
            return this.colors[color_index]
        } else {
            return this.colors[13]
        }
    }

    static size_style_defaults() {
        return this.size_styles.reduce((alloc, ss) => {
            alloc[ss] = 0
            return alloc
        }, {})
    }

    static get_recommendation(score, sell = 40, buy = 55) {
        if (!score) {
            return null
        }
        let formatted = ValueFormatter.round(score, 1, true)

        if (score <= sell) return <SellSign value={formatted}/>
        if (score >= buy) return <BuySign value={formatted}/>
        return <HoldSign value={formatted}/>
    }

    static get_rating_color(rating) {
        if (rating.includes('A')) {
            return this.colors[5]
        } else if (rating == 'Below B') {
            return this.colors[3]
        } else if (rating.includes('B')) {
            return this.colors[0]
        } else {
            return this.colors[3]
        }
    }

    static get_role_color(role) {
        switch (role) {
            case 'admin': return '#E0AD60'
            case 'head': return '#3196B2'
            case 'sales': return '#87d068'
            case 'supervisor': return 'rgb(175, 99, 65)'
            default: return 'black'
        }
    }

    static get_action_op_color(action) {
        switch (action) {
            case 'update from orion': return '#E0AD60'
            case 'create from excel': return '#3196B2'
            case 'create from model': return 'rgb(175, 99, 65)'
            case 'get': return '#87d068'
            case 'delete': return 'rgb(93,102,110)'
            case 'get assets': return '#3196B2'
            case 'get aggregation': return '#3196B2'
            case 'create': return 'rgb(175, 99, 65)'
            case 'update': return '#E0AD60'
            default: {
                if (action.includes('get')) {
                    return '#87d068'
                } else if (action.includes('update')) {
                    return '#E0AD60'
                } else if (action.includes('delete')) {
                    return 'rgb(93,102,110)'
                } else if (action.includes('report')) {
                    return '#3196B2'
                }
                return 'rgb(201, 219, 116)'
            }
        }
    }

    static get_status(score, sell = 19, buy = 71) {
        if (!score) {
            return null
        }
        // let formatted = ValueFormatter.round(score, 1, true)

        if (score <= sell) return 'exception'
        if (score >= buy) return 'success'
        return 'normal'
    }

    static get_status_color(status) {
        if (status == 'exception') return '#f5222d'
        if (status == 'success') return '#52c41a'
        if (status == 'normal') return this.colors[0]
    }

    static toColor(value, min, max, colorMin='#f5222d', colorNeutral='#dec944', colorMax='#8eea9a') {
        let rainbow = new Rainbow();

        rainbow.setSpectrum(colorMin, colorNeutral, colorMax);
        if (isNaN(value)) {
            return 'transparent'
        }
        rainbow.setNumberRange(min, max)
        return '#' + rainbow.colorAt(value)
    }

    static intPerformance2color(value, alpha=false) {
        let rainbow = new Rainbow();
        let min = -15
        let max = 15
        if (alpha) {
            min = -5
            max = 5
        }

        rainbow.setSpectrum('#f5222d', '#FFFFFF', '#8eea9a')
        if (isNaN(value)) {
            return 'transparent'
        }
        rainbow.setNumberRange(min, max)
        return '#' + rainbow.colorAt(value)
    }

    static stats2color(value, fieldName){
        let rainbow = new Rainbow();
        let min = -15
        let max = 15
        rainbow.setSpectrum('#f5222d', '#FFFFFF', '#8eea9a')


        if (isNaN(value)) {
            return 'transparent'
        }


        if (fieldName == 'std') {
            min = 0
            max = 20
            rainbow.setSpectrum('#8eea9a', '#FFFFFF', '#f5222d')
        } else if (fieldName == 'sharpe_ratio') {
            min = -5
            max = 10
        } else if (!['mean', 'median', 'max', 'min'].includes(fieldName)) {
            return null
        }

        rainbow.setNumberRange(min, max)
        return '#' + rainbow.colorAt(value)
    }

    static stats2fontcolor(value, fieldName){
        let adj = 1.0
        let rainbow = new Rainbow();
        let min = -15
        let max = 15
        rainbow.setSpectrum('#f5222d', '#4B4B4B', '#86bd91')


        if (isNaN(value)) {
            return 'transparent'
        }


        if (fieldName === 'std' || ieldName === 'mean_semideviation') {
            min = -20
            max = 0
            rainbow.setSpectrum('#86bd91', '#4B4B4B', '#f5222d')
            adj = -1.0
        } else if (fieldName === 'sharpe_ratio') {
            min = -5
            max = 10
        } else if (!['mean', 'median', 'max', 'min'].includes(fieldName)) {
            return null
        }

        rainbow.setNumberRange(min, max)
        return '#' + rainbow.colorAt(adj * value)
    }

    static getRepresentationColor(source) {
        let index = this.representations.indexOf(source)
        if (index > -1) {
            return this.colors[index]
        } else {
            return 'transparent'
        }
    }
}

