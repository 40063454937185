import axiosInstance from "@API/APIClient";

const getAssets = (entityGroup, filterString, callback, abortSignal, offset=null, limit=20) => {
    let url = `screener/${entityGroup}/assets?`
    if (offset) {
         url += `&offset=${offset}`
    }
     if (limit) {
         url += `&limit=${limit}`
    }
    if (filterString) {
        url += '&' + filterString
    }
    axiosInstance.get(url, {signal: abortSignal})
        .then(({data}) => {
            console.debug('screener/getAssets', data)
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(response, true);
            }
        })
};

const getSettings = (entityGroup, callback, abortSignal) => {
    let url = `screener/${entityGroup}/settings`
    axiosInstance.get(url, {signal: abortSignal})
        .then(({data}) => {
            console.debug('screener/getSettings', data)
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(response, true);
            }
        })
};

const getCollectionOptions = (entityGroup, callback, abortSignal) => {
    let url = `screener/${entityGroup}/collection-options`
    axiosInstance.get(url, {signal: abortSignal})
        .then(({data}) => {
            console.debug('screener/getCollectionOptions', data)
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(response, true);
            }
        })
};

const getAssetCollections = (entityGroup, asset, callback, abortSignal) => {
    let url = `screener/${entityGroup}/asset-collections`
    axiosInstance.post(url, asset, {signal: abortSignal})
        .then(({data}) => {
            console.debug('screener/getAssetCollections', data)
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(response, true);
            }
        })
};

const createCollection = (entityGroup, name, callback) => {
    let url = `screener/${entityGroup}/collections/create`
    axiosInstance.post(url, name)
        .then(({data}) => {
            console.debug('screener/createCollection', data)
            callback(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
                callback(response, true);
            }
        })
};

const addToCollection = (entityGroup, collection_id, asset, callback) => {
    let url = `screener/${entityGroup}/collections/${collection_id}/add-item`
    axiosInstance.post(url, asset)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const removeFromCollection = (entityGroup, collection_id, asset, callback) => {
    let url = `screener/${entityGroup}/collections/${collection_id}/remove-item`
    axiosInstance.post(url, asset)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const renameCollection = (entityGroup, collection_id, new_name, callback) => {
    let url = `screener/${entityGroup}/collections/${collection_id}/name`
    axiosInstance.put(url, new_name)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const deleteCollection = (entityGroup, collection_id, callback) => {
    let url = `screener/${entityGroup}/collections/${collection_id}`
    axiosInstance.delete(url)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

export
{
    getSettings,
    getCollectionOptions,
    getAssets,
    getAssetCollections,
    createCollection,
    addToCollection,
    removeFromCollection,
    renameCollection,
    deleteCollection
}
