import {Col, Divider, Menu, Row, Typography} from "antd";
const { Title, Paragraph, Text } = Typography;

import React from "react";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import SizePie from "@components/holistico/chart/SizePie";
import SectorBar from "@components/holistico/chart/SectorBar";
import SizeStyleHeat from "@components/holistico/chart/SizeStyleHeat";
import PortfolioLabel
    from "@holistico/proposal/view/fundamentals/PortfolioLabel";
import ScoreMarkersRadar from "@components/holistico/chart/ScoreMarkersRadar";
import {showAi, ShowAiButton} from "@holistico/proposal/view/ai/AiSideBar";
import SectionDescriptionEllipsis from "@holistico/aux/SectionDescription";


const columnSpan = () => {
    if(showAi()) {
        return {xl: 12, lg: 12, md: 24, sm: 24, xs: 24}
    } else {
        return {xl: 12, lg: 12, md: 12, sm: 24, xs: 24}
    }
}


export const ratingsDescription = <>
    <Paragraph>
        Ratings show how healthy a portfolio is in terms of fundamental factors
        exposure. Ratings are an essential tool in the arsenal of every
        investor. They serve as a compass, guiding us through the labyrinthine
        world of stocks and equities. These ratings are derived from a
        meticulous analysis of a company's financial health, market position,
        and overall economic conditions.
    </Paragraph>
    <Paragraph>
        For convenience, we provide a <b>Composite rating</b> and
        its 6 components, which reflect different aspects of the company's
        investment attractiveness:
        <ul>
            <li>
                <b>Growth rating</b> measures company growth potential
                and includes such factors as Projected EPS growth,
                Sales growth, etc.
            </li>
            <li>
                <b>Momentum rating</b> measures stock price strength
                and includes various indicators of the price trend.
            </li>
            <li>
                <b>Profitability rating</b> measures company profitability
                and earnings quality and includes such factors as ROA,
                FCF Margin, etc.
            </li>
            <li>
                <b>Safety rating</b> measures stock safety and includes such
                factors as Beta, Earnings stability, etc.
            </li>
            <li>
                <b>Yield rating</b> measures company yield and includes such
                factors as Dividend yield, Earnings yield, etc.
            </li>
            <li>
                <b>Value rating</b> shows how undervalued a company is by
                the market and includes such factor as Price/Book ratio,
                Projected P/E, etc.
            </li>
        </ul>
    </Paragraph>
    <Paragraph>
        In total, these ratings aggregate more than 35 fundamental factors and
        3rd party ratings and projections. All the ratings a scaled from 1 to
        100, where low values are bad and high values are good. Notice that
        ratings attribution relates only to the equity part of a portfolio.
    </Paragraph>
    <Paragraph>
        Fundamental ratings are an essential tool in the arsenal of every
        seasoned investor. They serve as a compass, guiding us through the
        labyrinthine world of stocks and equities. These ratings are derived
        from a meticulous analysis of a company's financial health, market
        position, and overall economic conditions.
    </Paragraph>
    <Paragraph>
        In essence, fundamental ratings are not mere numbers. They are a
        distillation of vast amounts of financial data into a digestible format,
        a beacon that illuminates the path to profitable investing. Without
        them, we'd be navigating the stock market in the dark.
    </Paragraph>
</>


export const sectorsDescription = <>
    <Paragraph>
        Sector allocation is a significant aspect of an effective investment
        strategy. Here's why:
    </Paragraph>
    <Paragraph>
        1. Reduction of Risk: Different sectors of the economy perform
        differently at various stages of the business cycle. By being invested
        in a variety of sectors, you reduce the risk of your entire portfolio
        suffering should one sector underperform.
    </Paragraph>
    <Paragraph>
        2. Exploitation of Opportunities: Diversification across sectors allows
        an investor to take advantage of growth in different areas of the
        economy. For instance, if the technology sector is performing well,
        being invested in this sector can provide significant benefits.
    </Paragraph>
    <Paragraph>
        3. Protection Against Volatility: Certain sectors are less susceptible
        to market volatility than others. For instance, the consumer staples
        sector, which includes companies that produce essential items like food
        and household goods, tends to be less volatile. Being invested in such
        sectors can help cushion your portfolio against market fluctuations.
    </Paragraph>
    <Paragraph>
        In essence, sector diversification is like not putting all your eggs in
        one basket.
    </Paragraph>
</>


export const sizeDescription = <>
    <Paragraph>
        Size allocation plays an integral role in a well-balanced investment
        portfolio for several reasons:
    </Paragraph>
    <Paragraph>
        1. Risk and Return Tradeoff: Generally, small-cap stocks are considered
        riskier but potentially offer higher returns, while large-cap stocks are
        more stable with moderate returns. Mid-cap stocks fall somewhere in
        between. By diversifying across different size allocations, investors
        can balance their risk and return potential.
    </Paragraph>
    <Paragraph>
        2. Market Cycle: Different size categories may perform better at
        different stages of the market cycle. For instance, large-cap stocks
        often fare better during economic downturns due to their stability,
        while small-cap stocks may outperform during a market recovery.
    </Paragraph>
    <Paragraph>
        3. Diversification: Diversification is not merely about spreading
        investments across different sectors but also about diversifying within
        sectors. Investing in companies of various sizes within the same sector
        allows investors to benefit from the sector's performance while
        mitigating the risk associated with any single company size.
    </Paragraph>
    <Paragraph>
        4. Influence of Economic Conditions: Large-, mid-, and small-cap stocks
        react differently to changes in economic conditions. Large-cap companies
        often have more substantial international exposure, making them more
        sensitive to global economic factors. In contrast, small-cap companies
        are usually more influenced by domestic economic conditions.
    </Paragraph>
    <Paragraph>
        In essence, size allocation is an additional layer of diversification
        that aims to optimize the risk-return tradeoff based on an investor's
        risk tolerance and financial goals. As such, it is an essential
        consideration in the formulation of an effective investment strategy.
    </Paragraph>
</>


export const styleDescription = <>
    <Paragraph>
        Style allocation is an integral part of portfolio construction, and its
        importance stems from several factors:
    </Paragraph>
    <Paragraph>
        1. Performance Variation: Different investment styles (value, growth,
        blend) perform differently under varying market conditions. For
        instance, value stocks may outperform in certain economic environments,
        while growth stocks may lead in others. By having a diverse style
        allocation, you can potentially benefit from the performance of
        different styles at different times.
    </Paragraph>
    <Paragraph>
        2. Risk Mitigation: Similar to sector and size diversification, style
        diversification also aids in spreading risk. Each style comes with its
        own set of risks and return potential. Balancing your portfolio across
        different styles helps to mitigate the risk associated with any single
        style.
    </Paragraph>
    <Paragraph>
        3. Exposure to Different Investing Approaches: Value investing focuses
        on stocks that are believed to be undervalued by the market, growth
        investing seeks companies that are expected to grow at an above-average
        rate, and blend investing is a mix of value and growth. Diversifying
        across these styles gives investors exposure to different investing
        methodologies, further enhancing diversification.
    </Paragraph>
    <Paragraph>
        4. Aligning with Investment Goals and Risk Tolerance: The choice of
        style allocation can be aligned with an investor's financial goals and
        risk tolerance. Those seeking higher growth may lean more towards growth
        stocks, while conservative investors may prefer value stocks. A blend
        approach could be suitable for moderate investors.
    </Paragraph>
    <Paragraph>
        In summary, style allocation provides an additional level of
        diversification, enabling investors to navigate different market
        conditions effectively while aligning with their individual investment
        objectives.
    </Paragraph>
</>


const EquitiesPlain = () => {
    const {proposal} = useHolisticoProposalContext()

    return <>
        <div className={'proposal-item-header'}>
            <Title level={3}>
                Equities
            </Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            <Title level={4} className={"section-header"}>
                Ratings
            </Title>
            <SectionDescriptionEllipsis>
                {ratingsDescription}
            </SectionDescriptionEllipsis>
            <Row gutter={[24, 24]}>
                <Col {...columnSpan()}>
                    <PortfolioLabel type={"original"}>Current</PortfolioLabel>
                    <ScoreMarkersRadar
                        score_markers={proposal?.p_bucket_cur?.calculated_values?.score_markers}/>
                </Col>
                <Col  {...columnSpan()}>
                    <PortfolioLabel type={"proposed"}>Proposed</PortfolioLabel>
                    <ScoreMarkersRadar
                        score_markers={proposal?.p_bucket_prop?.calculated_values?.score_markers}/>
                </Col>
            </Row>
            <Title level={4} className={"section-header"}>
                Sector
            </Title>
            <SectionDescriptionEllipsis>
                {sectorsDescription}
            </SectionDescriptionEllipsis>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <SectorBar
                        allocation={proposal?.p_bucket_cur?.calculated_values?.sector}
                        proposalAllocation={proposal?.p_bucket_prop?.calculated_values?.sector}/>
                </Col>
            </Row>
            <Title level={4} className={"section-header"}>
                Size
            </Title>
            <SectionDescriptionEllipsis>
                {sizeDescription}
            </SectionDescriptionEllipsis>
            <Row gutter={[24, 24]}>
                <Col  {...columnSpan()}
                      className={"portfolio-chart-card original"}>
                    <PortfolioLabel type={"original"}>Current</PortfolioLabel>
                    <SizePie
                        allocation={proposal?.p_bucket_cur?.calculated_values?.size}/>
                </Col>
                <Col  {...columnSpan()}
                      className={"portfolio-chart-card proposed"}>
                    <PortfolioLabel type={"proposed"}>Proposed</PortfolioLabel>
                    <SizePie
                        allocation={proposal?.p_bucket_prop?.calculated_values?.size}/>
                </Col>
            </Row>
            <Title level={4} className={"section-header"}>
                Size & style
            </Title>
            <SectionDescriptionEllipsis>
                {styleDescription}
            </SectionDescriptionEllipsis>
            <Row gutter={[24, 24]}>
                <Col  {...columnSpan()}>
                    <PortfolioLabel type={"original"}>
                        Current
                    </PortfolioLabel>
                    <SizeStyleHeat loading={false}
                                   allocation={proposal?.p_bucket_cur?.calculated_values?.size_style}/>
                </Col>
                <Col  {...columnSpan()}>
                    <PortfolioLabel type={"proposed"}>
                        Proposed
                    </PortfolioLabel>
                    <SizeStyleHeat loading={false}
                                   allocation={proposal?.p_bucket_prop?.calculated_values?.size_style}/>
                </Col>
            </Row>
        </div>
    </>
}


export default EquitiesPlain
