import {Select, Form} from "antd";
import {useRequest} from "ahooks";
import {getBenchmarkPortfoliosData} from "@API/portfolio";
import {useEffect} from "react";
import {formChartData} from "@components/admin_panel/scheduler/TaskTimeLine";

const CustomSelectInput = ({items}) => {
    return (
        <Select bordered={false}
                size={"large"}
                suffixIcon={null}
                className={'portfolio-select'}
                defaultActiveFirstOption={true}
                defaultValue={items[0].value}
                loading={false}
                options={items}
        />
    )

}

export default CustomSelectInput
