/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import ValueFormatter from "@global/ValueFormatter";
import {Table} from "antd";
import FactorSelector from "@components/portfolio/selector/FactorSelector";
import Localizator from "@global/Localizator";
import ChartStyles from "@global/ChartStyles";

const frozenColumns = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        render: (ticker) => <b>{ticker}</b>,
        sorter: (a, b) => ('' + a.ticker).localeCompare(b.ticker),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
            return name
        },
        sorter: (a, b) => ('' + a.name).localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'Mkt.Value',
        dataIndex: 'market_value',
        key: 'market_value',
        align: 'right',
        render: (marketValue) => ValueFormatter.currency(marketValue),
        sorter: (a, b) => a.market_value - b.market_value,
    }, {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        align: 'right',
        render: (weight) => <b>{ValueFormatter.percent(weight)}</b>,
        sorter: (a, b) => a.weight - b.weight,
    },
    {
        title: 'Type',
        dataIndex: 'security_type',
        key: 'security_type',
        sorter: (a, b) => (a.security_type ? '' : a.security_type).localeCompare(b.security_type),
        sortDirections: ['descend', 'ascend'],
        filters: [
            {text: 'Stock', value: 'Stock'},
            {text: 'ETF', value: 'ETF'},
            {text: 'Fund', value: 'MF'},
        ],
        render: (val, record, smthElse) => {
            if (val == 'Other' && record['ticker']?.length > 4 && record['name']?.includes('%') && record['name']?.includes('/20')) {
                return <p>Bond<sup style={{color: 'red'}}>*</sup></p>
            } else {
                return val
            }
        }
    },
    {
        title: Localizator.t('factor.global_category'),
        dataIndex: 'global_category',
        key: 'global_category',
        sorter: (a, b) => {
            let avalue = a['global_category'];
            let bvalue = b['global_category'];
            if (!avalue) {
                avalue = '';
            }

            if (!bvalue) {
                bvalue = '';
            }
            return avalue.localeCompare(bvalue)
        },
        sortDirections: ['descend', 'ascend']
    },
]

const frozen_column_names = frozenColumns.map(uc => uc.dataIndex)
const defaultColumns = [...frozenColumns,
    {
        title: Localizator.t('factor.div_yield'),
        dataIndex: 'div_yield',
        key: 'div_yield',
        render: val => {
            return ValueFormatter.percent(val / 100)
        },
        sorter: (a, b) => a.div_yield - b.div_yield,
    }, {
        title: Localizator.t('factor.expense_ratio'),
        dataIndex: 'expense_ratio',
        key: 'expense_ratio',
        render: val => {
            return ValueFormatter.percent(val / 100)
        },
        sorter: (a, b) => a.expense_ratio - b.expense_ratio,
    },]
/**
 *
 * Represents position table shown in analysis
 *
 * @param type the type of the portfolios
 * @returns {*}
 * @constructor
 */
const FactorAnalysisTable = ({
    positions = [], column_names = [], loading = false, reloadPositions = () => {}, downloadFactors = null
}) => {
    const [tablePositions, setTablePositions] = useState(positions);
    const [columns, setColumns] = useState(defaultColumns);

    useEffect(() => {
        setTablePositions(positions);
    }, [positions]);


    const handleTableChange = (pagination, filters, sorter) => {
        console.log(filters)
        if (filters.security_type) {
            let allowed = filters.security_type;
            let fps = positions.filter(pos => allowed.includes(pos.security_type));
            setTablePositions(fps);
        } else {
            setTablePositions(positions);
        }
    };

    const updateColumns = (selectedFactors) => {
        if (selectedFactors?.length < 1) {
            let colsToSet = defaultColumns

            let column_names = colsToSet.map(c => c.dataIndex)
            reloadPositions(column_names)
            setColumns(colsToSet)
            return
        }
        let newColumns = selectedFactors.filter(f => !(frozen_column_names.includes(f['column_name']))).map(f => {
            let col = f['column_name'];
            console.info('adding column: ' + col)
            let align = 'left';
            let numeric = (['integer', 'numeric', 'double precision'].includes(f['value_type']));
            let isScore = f['column_name'].includes('score');
            let isReturn = f['column_name'].includes('return');
            let isPercent = (f['units'] == '%');

            if (numeric) {
                align = 'right';
            }

            let sorter = (a, b) => {
                let avalue = a[col];
                let bvalue = b[col];
                if (numeric) {
                    return (avalue - bvalue)
                } else {
                    if (!avalue) {
                        return -1
                    }

                    if (!bvalue) {
                        return 1
                    }
                    return avalue.localeCompare(bvalue)
                }

            }

            let renderer = (val) => {
                let rounding = isScore ? 0 : 2;
                if (val == null || val == undefined) {
                    return ''
                }
                if (isPercent) {
                    if (isReturn) {
                        if (val > 0) {
                            return <b className={'positive'}>{ValueFormatter.percent(val / 100)}</b>
                        } else if (val < 0) {
                            return <b className={'negative'}>{ValueFormatter.percent(val / 100)}</b>
                        }
                    }
                    return <b>{ValueFormatter.percent(val / 100, 0)}</b>
                }

                if (isScore) {
                    return ChartStyles.get_recommendation(val, 19, 71)
                }

                return numeric ? <b>{ValueFormatter.round(val, rounding, false)}</b> : val
            }


            return {
                title: f.full_name,
                dataIndex: col,
                key: col,
                align: align,
                render: renderer,
                sorter: sorter,
                //sortDirections: ['descend', 'ascend'],
            }
        })
        let colsToSet = frozenColumns.concat(newColumns);

        let column_names = colsToSet.map(c => c.dataIndex);
        reloadPositions(column_names);
        setColumns(colsToSet);
    }

    return (
        <div>
            {
            (tablePositions.length > 0) ?
            <FactorSelector 
                done={updateColumns}
                selectedColumnNames={
                    columns.map(c => c.dataIndex).filter(column_name => !frozen_column_names.includes(column_name))
                }
                downloadFactors={downloadFactors}
            />
            : null
            }
            <Table onChange={handleTableChange}
                loading={loading}
                style={{width: '100%', fontSize: 18}}
                className={'font-18'}
                pagination={false}
                bordered={false}
                size="small"
                dataSource={tablePositions}
                rowKey='ticker'
                columns={columns}
                scroll={{x: true}}
            />
                
        </div>
    )
}

export default FactorAnalysisTable;
