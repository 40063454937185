/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {message, Space, Spin, Typography} from 'antd';

import {
    clone,
    createEmptyPortfolio,
    createFromFile,
    deleteModelOrTemp,
    fromOrion,
    getModels
} from "@API/portfolio";
import ExpandablePortfolioRow from '@root/components/portfolio/table/ExpandablePortfolioRow';
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import MainSearch from "@components/clients/MainSearch";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import AddPortfolioButton from "@holistico/portfolio/new/AddPortfolioButton";
import {PlusOutlined} from "@ant-design/icons";
import ModelGraphButton from "@components/models/graph/ModelGraphButton";


const ModelPortfolios = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [loadingPortfolios, setLoadingPortfolios] = useState(true);
    const [searchString, setSearchString] = useState(null)
    const [lockActions, setLockActions] = useState(false);
    const models = portfolios.filter(item => (item.type === "model"))
    const benchmarks = portfolios.filter(item => (item.type === "benchmark"))

    useEffect(() => {
        const controller = new AbortController()
        fetchModelData(controller.signal);
        return () => {
            controller.abort();
        }
    }, [searchString]);

    const fetchModelData = (signal) => {
        setLoadingPortfolios(true)
        getModels(true, searchString, (result, error) => {
            if (error) {
                message.error('Error while loading the models!');
            } else {
                setPortfolios(result);
            }
            setLoadingPortfolios(false)
        }, signal ?? new AbortController().signal);
    }

    const addFromFile = (formData, callback) => {
        setLockActions(true);
        createFromFile(formData, (result, error) => {
            if (!error) {
                message.success('Model portfolio was added successfully!');
                fetchModelData()
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback()
            }
            setLockActions(false);
        })
    }

    const addEmpty = (portfolioName, callback) => {
        setLockActions(true);
        let postData = {name: portfolioName, portfolio_type: "model"}
        createEmptyPortfolio(postData, (result, error) => {
            if (!error) {
                message.success('Model portfolio was added successfully!');
                fetchModelData()
            } else {
                result && message.error('Model creation failed!');
            }
            if (callback) {
                callback()
            }
            setLockActions(false);
        })
    }

    const addFromOrion = (orion_id, callback) => {
        setLockActions(true);
        fromOrion(orion_id, 'model', (result, error) => {
            if (!error) {
                message.success('Model portfolio was added successfully!');
                fetchModelData()
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback()
            }
            setLockActions(false);
        })
    }

    //we do nothing with weight here
    const addFromOther = (id, expand_portfolio_positions = false, weight, callback) => {
        setLockActions(true);
        clone(id, 'model', {}, expand_portfolio_positions, (result, error) => {
            if (!error) {
                message.success('Model portfolio was added successfully!');
                fetchModelData()
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback()
            }
            setLockActions(false);
        })
    }

    const deleteExisting = (id, callback) => {
        setLockActions(true);
        deleteModelOrTemp(id, (result, error) => {
            if (!error) {
                message.success("Model portfolio was deleted successfully");
                fetchModelData()
            } else {
                message.error('Error removing the model portfolio!');
            }
            if (callback) {
                callback()
            }
            setLockActions(false);
        })
    }

    return <>
        <Typography.Title level={3} className={"page-title"}>
            Models & benchmarks
        </Typography.Title>
        <div className={"page-content"}>
            <PortfolioListContext.Provider
                value={{
                    addFromOther: addFromOther,
                    addFromOrion: addFromOrion,
                    reloadContext: () => fetchModelData(),
                    addEmpty: addEmpty,
                    addFromFile: addFromFile,
                    removeOne: deleteExisting,
                    lockActions: lockActions
                }}>
                <div style={{padding: "16px 12px"}}>
                    <MainSearch
                        fetchData={(value) => {
                            setSearchString(value)
                        }}
                        placeholder={"Type in the model name to search"}
                        bordered={true}
                    />
                    <Space>
                        <AddPortfolioButton
                            bucketType="model"
                            disabled={lockActions}
                            style={{width: 200}}
                        >
                            <PlusOutlined/>
                            Add model
                        </AddPortfolioButton>
                        <ModelGraphButton/>
                    </Space>
                </div>
                <Spin style={{height: 800}}
                      spinning={loadingPortfolios}
                      tip="Loading models..."
                      size={"large"}
                >
                    {!loadingPortfolios && models?.length > 0
                        && <div className="portfolio-container">
                            {models.map(portfolio => {
                                return <ExpandablePortfolioRow
                                    key={portfolio._id}
                                    portfolioInfo={portfolio}
                                    type={portfolio.type}
                                />
                            })}
                        </div>
                    }
                    {!loadingPortfolios && benchmarks?.length > 0
                        && <div className="portfolio-container">
                            <Typography.Title
                                level={4}
                                style={{
                                    margin: "24px 0 -8px 0",
                                    color: "#a2a2a2"
                                }}
                            >
                                Benchmarks
                            </Typography.Title>
                            {benchmarks.map(portfolio => {
                                return <ExpandablePortfolioRow
                                    key={portfolio._id}
                                    portfolioInfo={portfolio}
                                    type={portfolio.type}
                                />
                            })}
                        </div>
                    }
                    {!loadingPortfolios && portfolios?.length === 0
                        && <NoDataPlaceholder description={"No models found"}/>
                    }
                </Spin>
            </PortfolioListContext.Provider>
        </div>
    </>
}

export default ModelPortfolios;
