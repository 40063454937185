import React, {createContext, useContext, useReducer} from "react";

const storageContext = createContext();

const ACTION_TYPE_UPDATE = "update";
const ACTION_TYPE_DELETE = "delete";
const ACTION_TYPE_INNER_UPDATE = "inner_update";

const StorageWrapper = ({children}) => {
    const storage = initStorage();
    return <storageContext.Provider value={{
        storage: storage.state,
        dispatch: storage.dispatch,
        ACTION_TYPE_UPDATE: ACTION_TYPE_UPDATE,
        ACTION_TYPE_DELETE: ACTION_TYPE_DELETE,
        ACTION_TYPE_INNER_UPDATE: ACTION_TYPE_INNER_UPDATE
    }}>{children}</storageContext.Provider>
}

const useStorage = () => {
    return useContext(storageContext)
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE_UPDATE:
            return {...state, [action.tag]: action.value}
        case ACTION_TYPE_DELETE:
            let storage = Object.assign({}, state);
            delete storage[action.tag];
            return storage
        case ACTION_TYPE_INNER_UPDATE:
            if (state[action.tag] instanceof Array) {
                let innerArray = state[action.tag].slice();
                innerArray[action.index] = {...innerArray[action.index], ...action.value}
                return {...state, [action.tag]: innerArray}
            } else if (state[action.tag] instanceof Object) {
                return {...state, [action.tag]: {...state[action.tag], ...action.value}}
            }
            
        default:
            return state;
    }
}

const initStorage = () => {
    const [state, dispatch] = useReducer(reducer, {});
    
    return {
        state,
        dispatch
    }
}

export {StorageWrapper, useStorage}
