import {Col, Row} from "antd";
import ActionNotifyRepresentative from "@components/supervision/actions/client/ActionNotifyRepresentative";
import ActionMuteFlag from "@components/supervision/actions/client/ActionMuteFlag";
import ActionMuteClient from "@components/supervision/actions/client/ActionMuteClient";
import ActionToPlayground from "@components/supervision/actions/client/ActionToPlayground";
import ActionAddProposal from "@components/supervision/actions/client/ActionAddProposal";
import "./SupervisionActions.less";
import React from "react";

const ClientActions = ({actions, client, metricFlagData, representativeInfo, size = 'small', showTitle=false}) => {
    
    const ActionComponent = ({action}) => {
        switch (action) {
            case 'notify':
                if (!metricFlagData || !representativeInfo || !client) {
                    throw Error("Not enough data!")
                }
                return <ActionNotifyRepresentative client={client} metricFlagData={metricFlagData}
                                                   representativeInfo={representativeInfo}/>
            case 'mute_flag':
                if (!metricFlagData || !client) {
                    throw Error("Not enough data!")
                }
                return <ActionMuteFlag metricFlagData={metricFlagData} client={client}/>
            case 'mute_client':
                if (!client) {
                    throw Error("Not enough data!")
                }
                return <ActionMuteClient client={client}/>
            case 'mute_client_warn':
                if (!client) {
                    throw Error("Not enough data!")
                }
                return <ActionMuteClient client={client} noticeDefaultVisible={true}/>
            case 'analyze':
                if (!client) {
                    throw Error("Not enough data!")
                }
                return <ActionToPlayground client={client}/>
            case 'proposal':
                if (!client) {
                    throw Error("Not enough data!")
                }
                return <ActionAddProposal client={client}/>
        }
    }
    return <div className={`client-action`}>
        <Row className={size}>
            {showTitle ? <Col className={size} style={{cursor: "default"}}>
                Actions:
            </Col> : null}
            {actions.map(action_string => {
                return <Col key={action_string}>
                    <ActionComponent action={action_string}/>
                </Col>
            })}
        </Row>
    </div>
}

export default ClientActions
