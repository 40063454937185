/* eslint-disable react/prop-types */
import React, {useContext, useEffect, useState} from 'react';
import {Col, message, Row, Typography, Empty} from 'antd';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import {getSnapshot, getPortfolioExcelWithFactors, cancel} from "@API/portfolio";
import LStorage from "@global/Storage";
import SizePie from "@components/chart/SizePie";
import AssetClassPie from "@components/chart/AssetClassPie";
import SizeStyleHeat from "@components/chart/SizeStyleHeat";
import FactorAnalysisTable from "@components/portfolio/factor/FactorAnalysisTable";
import ClientPortfolioSelector from "@components/client/ClientPortfolioSelector";
import ScoreMarkersStats from "@components/chart/ScoreMarkersStats";
import PlaygroundFixedIncomeAnalysis from "@components/playground/PlaygroundFixedIncomeAnalysis";
import SectorBar from "@components/chart/SectorBar";
import { downloadFile } from '@global/FileDownloader';


const {Paragraph, Title} = Typography;

/**
 *
 * Represents Portfolios tab in menu
 *
 * @param type the type of the portfolios
 * @returns {*}
 * @constructor
 */
const ClientAnalysis = () => {
    const {client, calculated_values, proposed_calculated_values} = useContext(SpasClientContext);

    if (!client) {
        return null
    }

    const [loading, setLoading] = useState(false)
    const [columnNames, setColumnNames] = useState(["global_category", "asset_class_alloc", "div_yield", "expense_ratio"])
    const [selectedPortfolio, setSelectedPortfolio] = useState({
        name: 'Unknown', calculated_values: {}
    })
    const [selectedBenchmark, setSelectedBenchmark] = useState({
        name: 'Unknown', calculated_values: {}
    })
    const [portfolioPositions, setPortfolioPositions] = useState([])

    const fetchPositionData = () => {
        setLoading(true)
        if (selectedPortfolio['name'] == 'Unknown') {
            setLoading(false)
            return
        }
        let definitions = []
        definitions = selectedPortfolio._id.split(',')

        getSnapshot(definitions, columnNames, (result, error) => {
            if (!error) {
                setPortfolioPositions(result.positions)
            } else {
                message.error('Error loading portfolio data!');
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchPositionData()
    }, [selectedPortfolio, columnNames]);

    useEffect(() => {
        return () => {
            cancel && cancel();
        }
    }, [])

    const updateColumns = (newColumns) => {
        console.log('updating table colimns to ' + newColumns)
        setColumnNames(newColumns)
    }

    const benchmarkSelected = (benchmarkModel) => {
        setSelectedBenchmark(benchmarkModel)
        LStorage.setDefaultFactorBenchmark(benchmarkModel.id)
    }

    const actualSelected = (portfolio) => {
        setSelectedPortfolio(portfolio)
    }

    const downloadFactorsExcel = (additionalColumns, callback) => {
        console.log(additionalColumns)
        getPortfolioExcelWithFactors(selectedPortfolio._id.split(','), additionalColumns, (response, error) => {
            if (error) {
                message.error("Error while downloading excel");
                callback();
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Analyzed ${selectedPortfolio.name} (${new Date().toLocaleString()}).xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                message.success("Factors excel was downloaded successfully");
                callback();
            })
        })
    }

    return <div className={'portfolio-analysis'}>
        <ClientPortfolioSelector setActual={actualSelected} setBenchmark={benchmarkSelected}/>

        <Row>
            <Col span={24}>
                <Title level={3}>Asset class</Title>
                <Paragraph>The extent to which you choose to employ asset allocation as a means of diversification is
                    going to be an individual decision that is guided by your personal investment goals and your risk
                    tolerance. If you're very risk-averse, then you may want to invest only in relatively safe asset
                    classes. You may aim to diversify within an asset class. Stock investors commonly diversify by
                    holding a selection of large-cap, mid-cap, and small-cap stocks. Alternately, they may seek
                    diversification through investing in unrelated market sectors.</Paragraph>
            </Col>
            <Col span={12}>
                <AssetClassPie loading={loading} allocation={selectedPortfolio.calculated_values['asset_class']}/>
            </Col>
            <Col span={12}>
                <AssetClassPie loading={loading} allocation={selectedBenchmark?.calculated_values['asset_class']}/>
            </Col>
        </Row>
        <Title level={3}>Equities</Title>
        {selectedPortfolio?.calculated_values?.score_markers ?
            <Row>
                <Col span={24}>
                    <Title level={4} className={"spas"}>Rating attribution</Title>
                    <Paragraph>Score ratings attribution shows how healthy a 
                        portfolio is in terms of fundamental factors exposure. 
                        To simplify the analysis, we present portfolio scores, each of which 
                        aggregates multiple factors. For example, SScore+ takes into account more than 35 
                        fundamental factors and ratings such as Sales Growth, Momentum, Zacks Rank, etc. 
                        Score ratings are normalized to take values from 1 to 100. For each individual stock, 
                        the interpretation of score values is following: 70 and above — "Buy", 20 and below — "Sell", 
                        between 20 and 70 — "Hold". Portfolio scores are calculated as a weighted average of its holdings 
                        scores. You should strive to achieve portfolio scores above 50. Notice that score attribution 
                        relates only to the equity part of a portfolio.</Paragraph>
                </Col>
                <Col span={12}>
                    <ScoreMarkersStats score_markers={selectedPortfolio?.calculated_values['score_markers']}
                        loading={loading}
                        title={selectedPortfolio.name}/>
                </Col>
                <Col span={12}>
                    <ScoreMarkersStats score_markers={selectedBenchmark?.calculated_values['score_markers']}
                        loading={loading}
                        title={selectedBenchmark.name}/>
                </Col>
            </Row> 
            : 
            <Empty style={{margin: "15px 0"}} description="No SScores information"/>
            }
        <Row>
            <Col span={24}>
                <Title level={4} className={"spas"}>Size</Title>
                <Paragraph>Historically, over the long run, Small-Caps have out- performed Large-Cap stocks. However,
                    periodically Large Cap lead the way for periods of 3-6 years. The rotation from Small to Large and
                    back to Small dominance regimes can be swift. A well balanced portfolio will have representation
                    from large, Medium and Small companies in order to provide a smoother long-term ride.</Paragraph>
            </Col>
            <Col span={12}>
                <SizePie loading={loading} allocation={selectedPortfolio.calculated_values['size']}/>
            </Col>
            <Col span={12}>
                <SizePie loading={loading} allocation={selectedBenchmark?.calculated_values?.size}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Title level={4} className={"spas"}>Sector</Title>
                <Paragraph>To provide our investors with adequate diversification, we include most major economic
                    sectors in our portfolios. During the distinct stages of an economic cycle sectors behave
                    differently. During the recovery and expansion cyclical sectors like Consumer Discretionary,
                    Financials, Industrials, Information Technology and Real Estate tend to do well. During the slowdown
                    Consumer Staples, Energy and Materials dominate. Going into recession defensive sectors like
                    Consumer Staples, Healthcare, Telecom and Utilities tend to outperform. It is very difficult to
                    predict the exact timing of a regime change and shift from one cycle stage to another. That is why
                    it is important to have a diversified portfolio where major sectors are represented.</Paragraph>
            </Col>
            {/*<Col span={12}>
                <SectorPie loading={false} allocation={selectedPortfolio.calculated_values['sector']}/>
            </Col>
            <Col span={12}>
                <SectorPie loading={false} allocation={selectedBenchmark?.calculated_values?.sector}/>
            </Col>*/}
            <Col span={24}>
                <SectorBar loading={false} allocation={selectedPortfolio.calculated_values['sector']}
                           proposalAllocation={selectedBenchmark?.calculated_values?.sector}/>
            </Col>
            <Col style={{marginTop: "20px"}} span={24}>
                <Title level={4} className={"spas"}>Size Style</Title>
                <Paragraph>To provide our investors with adequate diversification, we include most major economic
                    sectors in our portfolios. During the distinct stages of an economic cycle sectors behave
                    differently. During the recovery and expansion cyclical sectors like Consumer Discretionary,
                    Financials, Industrials, Information Technology and Real Estate tend to do well. During the slowdown
                    Consumer Staples, Energy and Materials dominate. Going into recession defensive sectors like
                    Consumer Staples, Healthcare, Telecom and Utilities tend to outperform. It is very difficult to
                    predict the exact timing of a regime change and shift from one cycle stage to another. That is why
                    it is important to have a diversified portfolio where major sectors are represented.</Paragraph>
            </Col>
            <Col span={12}>
                <SizeStyleHeat loading={false} allocation={selectedPortfolio.calculated_values['size_style']}/>
            </Col>
            <Col span={12}>
                <SizeStyleHeat loading={false} allocation={selectedBenchmark.calculated_values['size_style']}/>
            </Col>
        </Row>
        <PlaygroundFixedIncomeAnalysis selectedBenchmark={selectedBenchmark}
                                       selectedPortfolio={selectedPortfolio} loading={loading}/>
        <Row style={{marginTop: "35px"}}>
            <Col span={24}>
                <Title level={3}>{selectedPortfolio.name} positions</Title>
                <FactorAnalysisTable positions={portfolioPositions} loading={loading}
                                     reloadPositions={updateColumns} downloadFactors={downloadFactorsExcel}/>
            </Col>

        </Row>

    </div>
}

export default ClientAnalysis;
