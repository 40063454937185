/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Checkbox, Col, Row, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import './PortfolioList.less';
import RiskIndicator from "@root/components/portfolio/RiskIndicator";

const {Paragraph, Text, Title} = Typography;


const {Meta} = Card;

const PortfolioListItem = ({portfolio, select, multi, selected, index, showMarketValue = true}) => {
    const getClass = (p_type) => {
        let className = 'portfolio-row-item'
        if (p_type === 'benchmark') {
            className += ' benchmark'
        }
        return className
    }

    return (
        <Row className={getClass(portfolio?.type)}
             style={{marginBottom: 13, marginLeft: 0, marginRight: 0, marginTop: 0}}
             type="flex" justify="start" align="middle" key={index} gutter={[8, 16]}
             onClick={() => select(index, portfolio)}>
            <Col md={3} lg={3} sm={4} xs={4} style={{width: 50}}>
                <RiskIndicator width={50} riskValue={portfolio?.calculated_values?.risk?.risk_score}/>
            </Col>
            <Col span={15} style={{textAlign: 'left'}}>
                <Text className="portfolio-title-name">{portfolio.name}</Text>
            </Col>
            <Col md={6} lg={6} sm={0} xs={0} style={{textAlign: 'right'}}>
                {showMarketValue && ValueFormatter.currency(portfolio?.calculated_values?.market_value)}{multi && <Checkbox style={
                {paddingLeft: 5}
            } checked={selected} onChange={e => select(index, portfolio)}/>}
            </Col>
        </Row>
    )
}

export default PortfolioListItem;
