import React, {useState} from 'react';
import {Input, Spin, message} from 'antd';

const {Search} = Input;

// eslint-disable-next-line react/prop-types
const EmptyForm = ({createEmpty, portfolioType}) => {
    const [loading, setLoading] = useState(false);

    const onSearch = title => {
        if (title === "") {
            message.error("You must specify portfolio name")
            return
        }
        setLoading(true);
        if (portfolioType === "model") {
            createEmpty({name: title, portfolio_type: portfolioType}, () => {
                setLoading(false);
            });
        } else {
            createEmpty({name: title, type: portfolioType}, () => {
                setLoading(false);
            });
        }
    }

    return (
        loading
        ?   <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spin size="large"></Spin>
            </div> 
        : <Search onSearch={onSearch} placeholder="enter new portfolio title" enterButton="Create"/>
    );
}

export default EmptyForm;
