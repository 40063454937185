import {createContext} from 'react';

/**
 * reloadData: (boolean) => {some reload actions}
 * @type {React.Context<{market_assumptions: {}, proposed_calculated_values: {}, client: null, loadData: loadData, calculated_values: {}}>}
 */
export const SpasClientContext = createContext({
    client: null, loadData: () => {
    },
    calculated_values: {},
    proposed_calculated_values: {},
    market_assumptions: {}
});
