/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useUpdateEffect} from "ahooks";
import {Form, Row, Col, Space} from "antd";
import {debounce} from "@global/Utils";
import {useScreenerContext} from "@hooks/ScreenerContext";
import FiltersPanel from "@components/screener/FiltersPanel";
import TopPanel from "@components/screener/TopPanel";
import {
    parseSearchParams,
    updateSearchParams
} from "@components/screener/params/utils";
import "./AssetScreener.less"


const ParamsForm = ({children}) => {
    const {
        entityGroup,
        settings,
        searchParams,
        setSearchParams
    } = useScreenerContext()
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState(null)

    useEffect(() => {
        console.log('ParamsForm', 'useEffect[settings]')
        if (!settings) {
            return
        }
        const params = parseSearchParams(searchParams, entityGroup, settings)
        setInitialValues(params);
    }, [settings])

    // useUpdateEffect below is necessary
    // for back/forward browser buttons to work correctly
    useUpdateEffect(() => {
        if (!settings) {
            return
        }
        console.log('ParamsForm', 'useUpdateEffect[searchParams]')
        const unset = {}
        Object.keys(form.getFieldsValue()).map(key => unset[key] = null)
        const params = parseSearchParams(searchParams, entityGroup, settings)
        form.setFieldsValue({...unset, ...params});
    }, [searchParams, settings])


    const onFieldsChange = (changedFields) => {
        setSearchParams(prev => {
            const params = {}
            changedFields.forEach(item => params[item.name[0]] = item.value)
            return updateSearchParams(prev, params, settings, form);
        })
    }

    const onFieldsChangeDebounced = debounce(onFieldsChange, 500);

    return <>{settings && initialValues
        &&
        <Form
            form={form}
            layout="vertical"
            onFieldsChange={onFieldsChangeDebounced}
            initialValues={initialValues}
            className={"asset-filters-form"}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={10} lg={10} xl={6} xxl={6}>
                    <FiltersPanel/>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={18} xxl={18}>
                    <TopPanel/>
                    {children}
                </Col>
            </Row>
        </Form>
    }</>
}

export default ParamsForm;
