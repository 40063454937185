const formatterMapper = (type) => {
    switch (type) {
        case "":
            return (value, precision) => value !== null ? value.toFixed(precision) : "none";
        case "%":
            return (value, precision) => value !== null ? `${value.toFixed(precision)}%` : "none";
        case "day(s)":
            return (value, precision) => value !== null ? value : "none";
        case "days per quarter":
            return (value, precision) => value !== null ? value : "none";
        default:
            return (value, precision) => value !== null ? value.toFixed(precision) : "none";

    }
}

const colorDetector = (number) => {
    if (number === 0) {
        return "green"
    } 
    return "red"
    // if (number < step * maximum) {
    //     return "gold"
    // } 
    // if (number < (step * 2) * maximum) {
    //     return "orange"
    // }
    // if (number < (step * 3) * maximum) {
    //     return "volcano"
    // }
    // if (number >= (step * 3) * maximum) {
    //     return "red"
    // }
}

export {formatterMapper, colorDetector}