import React from "react";
import {Tag} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import { colorDetector } from "../tree_utils/utils";

const RedFlagInfo = ({clients_count, representatives_count, all_clients, all_representatives}) => {
    return (
        <>
            <Tag color={colorDetector(clients_count, all_clients, 0.08)}
                style={{marginRight: "10px", width: "85px", fontSize: "0.8em", textAlign: "center"}}
            >
                {ValueFormatter.entityNumber(clients_count, "client")}
            </Tag>
            <Tag color={colorDetector(representatives_count, all_representatives, 0.15)} 
                style={{marginRight: "10px", width: "140px", fontSize: "0.8em", textAlign: "center"}}
            >
                {ValueFormatter.entityNumber(representatives_count, "representative")}
            </Tag>
        </>
    )
}

export default RedFlagInfo