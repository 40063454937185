/* eslint-disable react/prop-types */
import React from 'react';
import {Col, Row, Typography} from 'antd';
import RiskIndicator from "../../portfolio/RiskIndicator";
import PrognosisSlider from "../../portfolio/PrognosisSlider";
import StressInfo from "../../stress/StressInfo";


const {Title} = Typography;

const QStepRiskResult = ({financialParams = {}, financialParamsChanged}) => {
    return (
        <div style={{textAlign: 'center'}}>
            <div style={{margin: 'auto', width: '100%'}}>
                <Row>
                    <Col span={7}>
                        <StressInfo title={'Potential loss'} returnPercent={financialParams.negative_performance_plan}
                                    dollarPortfolioReturn={financialParams.negative_performance_plan_dollar}/>
                    </Col>
                    <Col md={10} lg={10} sm={0} xs={0} style={{textAlign: 'center'}}>
                        <div style={{margin: 'auto', width: 202}}>
                            <Title type={'secondary'} level={4}>Target risk score</Title>
                            <RiskIndicator width={200}
                                           riskValue={financialParams?.risk}/>
                        </div>
                    </Col>
                    {/* <Col md={0} lg={0} sm={24} xs={24} style={{textAlign: 'center'}}>
                        <div style={{margin: 'auto', width: 202}}>
                            <Title type={'secondary'} level={4}>Target risk score</Title>
                            <RiskIndicator width={200}
                                           riskValue={financialParams?.risk}/>
                        </div>
                    </Col> */}
                    <Col span={7}>
                        <StressInfo title={'Potential gain'} returnPercent={financialParams.positive_performance_plan}
                                    dollarPortfolioReturn={financialParams.positive_performance_plan_dollar}/>
                    </Col>
                </Row>
                <PrognosisSlider vertical={false} currentValue={financialParams.investment_amount}
                                 risk_calculations={{
                                     lower_bound_6m: financialParams.negative_performance_plan * 100,
                                     upper_bound_6m: financialParams.positive_performance_plan * 100
                                 }}
                                 title={"95% probability"}/>
            </div>
        </div>

    )
}

export default QStepRiskResult;
