/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import {Button, Col, Collapse, Empty, Modal, Row, Typography} from "antd";
import '@root/components/portfolio/Portfolio.less';
import '@root/components/portfolio/list/PortfolioList.less';
import {deletePlaygroundPreset, getPlaygroundPresets} from "@API/playground";
import {DeleteOutlined} from '@ant-design/icons';

const {Panel} = Collapse;
const {Paragraph, Text, Title} = Typography;
const PlaygroundPresetSelector = ({presetSelected = null}) => {
    const [presets, setPresets] = useState([])
    const [showSelectPreset, setShowSelectPreset] = useState(false)

    useEffect(() => {
        getPlaygroundPresets((presets) => {
            setPresets(presets)
        })
    }, [showSelectPreset])

    const selected = (newPreset) => {
        presetSelected(newPreset)
        setShowSelectPreset(false)
    }

    const deletePreset = (event, preset) => {
        event.stopPropagation()
        event.preventDefault()
        console.log('now new preset will be deleted!' + JSON.stringify(preset))
        deletePlaygroundPreset(preset._id, preset.name, () => {
            getPlaygroundPresets((presets) => {
                setPresets(presets)
            })
        })
    }


    return (<><Button
            style={{marginRight: 12}}
            onClick={() => setShowSelectPreset(true)}>Load configuration</Button>
        <Modal
            title="Preset loading"
            open={showSelectPreset}
            width={750}
            closable={false}
            maskClosable={true}
            onCancel={v => setShowSelectPreset(false)}
            footer={[]}

        >
            {presets.length ? presets.map((preset, index) => {
                return <Row className={'portfolio-row-item'}
                            style={{marginBottom: 13, marginLeft: 0, marginRight: 0, marginTop: 0}}
                            type="flex" justify="start" align="middle" key={index} gutter={[8, 16]}
                            onClick={() => selected(preset)}>
                    <Col span={22} style={{textAlign: 'left'}}>
                        <Title level={4} className="portfolio-title-name">{preset.name}</Title>
                        <Text>{preset.owner}</Text>
                    </Col>
                    <Col span={2} style={{textAlign: 'left'}}>
                        <DeleteOutlined onClick={(event) => {
                            deletePreset(event, preset)
                        }
                        }/>
                    </Col>
                </Row>
            }) : <Empty description={false}/>}
        </Modal></>);
}

export default PlaygroundPresetSelector;
