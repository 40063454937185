import ClientTreeTitle from "@components/supervision/red_flag/report_components/ClientTreeTitle";
import RepresentativeTreeTitle from "@components/supervision/red_flag/report_components/RepresentativeTreeTitle";

var actualRepresentative;

const titleCreator = (data, metricFlagData) => {
    let prepData = Object.assign({}, data);
    if (prepData.leaf === false) {
        delete prepData.children;
    }
    switch (data.level) {
        case "representative":
            actualRepresentative = prepData;
            return <RepresentativeTreeTitle representative={prepData} flagTitle={metricFlagData.red_flag_name}/>

        case "client":
            return <ClientTreeTitle client={prepData}
                                    metricFlagData={metricFlagData}
                                    representativeInfo={actualRepresentative}
            />
    }
}

const createTreeData = (data, metricFlagData, key = "0-") => {
    const branchData = data.map((branch, inx) => {
        let antdBranch = {
            title: titleCreator(branch, metricFlagData),
            isLeaf: true,
            key: `${key}${inx}`,
            selectable: false,
        }
        if (branch.level !== "client") {
            antdBranch.isLeaf = false;
            antdBranch.children = createTreeData(branch.children, metricFlagData, key + `${inx}-`);
        }
        return antdBranch
    })

    return branchData
}

export {createTreeData}
