import {Button, List, Modal, Row, Switch, Tooltip} from 'antd';
import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ClientListItem from "./ClientListItem";
import AddClient from "./AddClient";
import OrionProposalCreatorModal from './OrionProposalCreatorModal';

import './ClientsList.less';
import {FormatPainterOutlined} from "@ant-design/icons";

// eslint-disable-next-line react/prop-types
const ClientsList = ({dataArray, loadMore, loading, secureMode, setSecureMode}) => {
    const [orionId, setOrionId] = useState(null);
    const [modalCreation, setModalCreation] = useState(false);
    const [clientId, setClientId] = useState(null);

    const navigate = useNavigate();

    const editClient = (id) => {
        setClientId(id);
        setModalCreation(true);
    }

    const clientClick = (client) => {
        console.log(client)
        if (client._id) {
            navigate(`../proposal/${client._id}/overview`)
        } else {
            setOrionId(client.external_id)
        }
    }
    const closeCreationModal = () => {
        setClientId(null);
        setModalCreation(false);
    }

    const memoizedAddCLient = useMemo(() => {
        return <AddClient clientId={clientId} closeCreationModal={closeCreationModal}/>
    }, [clientId])

    return (
        (<div>
            <Row>
                <Button type="primary" onClick={() => setModalCreation(true)}>Add client proposal</Button>
                <Tooltip title={'Show/hide client details.'}>
                    <Switch style={{marginLeft: "auto"}}
                            checkedChildren={<FormatPainterOutlined/>}
                            unCheckedChildren={<FormatPainterOutlined/>}
                            checked={secureMode} onChange={checked => setSecureMode(checked)}/>
                </Tooltip>
            </Row>
            <List
                loading={loading ? {size: 'large', tip: "Loading existing proposals..."} : false}
                itemLayout="horizontal"
                dataSource={dataArray}
                className="portfolio-table"
                renderItem={item => (
                    <ClientListItem client={item} select={client => clientClick(client)}
                                    edit={editClient} secureMode={secureMode}/>
                )}
            />
            <OrionProposalCreatorModal
                orionId={orionId}
                clientClick={clientClick}
                cancelAction={() => setOrionId(null)}
            />
            <Modal
                title={`Client ${clientId ? "Editing" : "Creation"}`}
                open={modalCreation}
                // onOk={loadFromOrion}
                // confirmLoading={confirmLoading}
                onCancel={closeCreationModal}
                footer={null}
            >
                {memoizedAddCLient}
            </Modal>
        </div>)
    );
}

export default ClientsList
