/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
    FileExcelOutlined
} from '@ant-design/icons';
import {Alert, Button, Form, Row, Space, Table, Tooltip, Typography, message} from 'antd';

const Title = {Typography}
import {
    getAllocationsSummary,
    getAllocationsExcel,
    cancel
} from "@API/allocation";
import ChartStyles from "@global/ChartStyles";
import ValueFormatter from "@global/ValueFormatter";
import './Allocations.less'
import SelectPortfoliosModal from "@components/allocations/SelectPortfoliosModal";
import AllocationsPresetManager from "@components/allocations/AllocationsPresetManager";
import AllocationsPresetCreator from "@components/allocations/AllocationsPresetCreator";
import {downloadFile} from "@global/FileDownloader";

const {ErrorBoundary} = Alert;

const START_COLUMNS = [
    /*{
        title: '#',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
            if (name) {
                return <b>{name}</b>
            } else {
                return <b>{record['asset_class_alloc']}</b>
            }
        },
    },*/
    {
        title: 'ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        width: 100,
        fixed: 'left',
        render: (ticker, record) => {
            if (ticker) {
                return <Tooltip title={record['name']}>
                    <b>{ticker}</b>
                </Tooltip>
            } else {
                return <b>{record['asset_class_alloc']}</b>
            }
        },

    },
]
const PERFORMANCE_COLUMNS = [
    {
        title: '3 mo.',
        width: 100,
        fixed: 'left',
        dataIndex: 'total_return_1q',
        key: 'total_return_1q',
        align: 'right',
        render: (value, record) => {
            return renderPerformance(value, record)
        },
        onCell: (rec, rowIndex) => stylePerformance(rec, rowIndex, 'total_return_1q'),
    }, {
        title: '6 mo.',
        width: 100,
        fixed: 'left',
        dataIndex: 'total_return_2q',
        key: 'total_return_2q',
        align: 'right',
        render: (value, record) => {
            return renderPerformance(value, record)
        },
        onCell: (rec, rowIndex) => stylePerformance(rec, rowIndex, 'total_return_2q'),
    }, , {
        title: 'YTD',
        width: 100,
        fixed: 'left',
        dataIndex: 'total_return_ytd',
        key: 'total_return_ytd',
        align: 'right',
        render: (value, record) => {
            return renderPerformance(value, record)
        },
        onCell: (rec, rowIndex) => stylePerformance(rec, rowIndex, 'total_return_ytd'),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: '12 mo.',
        width: 100,
        fixed: 'left',
        dataIndex: 'total_return_1yr',
        key: 'total_return_1yr',
        align: 'right',
        render: (value, record) => {
            return renderPerformance(value, record)
        },
        onCell: (rec, rowIndex) => stylePerformance(rec, rowIndex, 'total_return_1yr'),
    }]

const renderPerformance = (performance, record) => {
    if (!record['ticker']) {
        return null
    }
    let formattedValue = ValueFormatter.int_percent(performance, '-')
    return <div>{formattedValue}</div>
}

const renderWeight = (weight, name) => {
    let text = ValueFormatter.percent(weight, 0)
    if (weight > 0) {
        return <Tooltip title={name}>{text}</Tooltip>
    } else {
        return text
    }

}

const styleWeight = (record, definitionId) => {
    const weight = record[definitionId]
    let props = {}
    if (!weight && record['ticker']) {
        props['style'] = {
            color: 'gray'
        }
    }
    return props
}
const stylePerformance = (record, rowIndex, propName) => {
    let props = {}
    let value = record[propName]

    if (!record['ticker']) {
        return props
    }

    if (value == null || isNaN(value)) {
        return props
    }

    let color = ChartStyles.intPerformance2color(value)
    if (color) {
        props = {style: {background: color}}
    }

    return props
}

const AllocationAssets = () => {
    const [tableRows, setTableRows] = useState([]);
    const [definitionNameMap, setDefinitionNameMap] = useState({});
    //current ones
    const [selectedDefinitionIds, setSelectedDefinitionIds] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [searchString, setSearchString] = useState(null)

    useEffect(() => {
        if (selectedDefinitionIds.length > 0) {
            fetchData(searchString, selectedDefinitionIds)
        }

        return () => {
            cancel && cancel();
        }
    }, [selectedDefinitionIds]);

    const fetchData = (search, definitionIds) => {
        setLoading(true)
        getAllocationsSummary(definitionIds, (data, error) => {
            portfolioHandler(data, error);
        })
    }

    const portfolioHandler = allocationDict => {
        let definition_name_map = allocationDict['definition_name_map'];
        setDefinitionNameMap(definition_name_map);
        let definitionColumns = Object.keys(definition_name_map).map((definitionId) => {
            let name = definition_name_map[definitionId]
            return {
                title: name,
                render: (value, record) => {
                    return renderWeight(value, name)
                },
                width: 100,
                onCell: (rec, rowIndex) => styleWeight(rec, definitionId),
                dataIndex: definitionId,
                key: definitionId,
                align: 'right',
            }
        });
        let distributionColumns = START_COLUMNS.concat(PERFORMANCE_COLUMNS)
        if (selectedDefinitionIds.length > 0) {
            distributionColumns = distributionColumns.concat(definitionColumns)
        }
        setColumns(distributionColumns)
        setTableRows(allocationDict['records_with_totals']);
        setLoading(false)
    }

    

    const selectModels = (definitionIds) => {
        console.info("new model selection " + definitionIds)
        setSelectedDefinitionIds(definitionIds)
        //fetchData(null, definitionIds)
    }

    const download = () => {
        setExcelLoading(true);
        getAllocationsExcel({allocations: tableRows, definition_name_map: definitionNameMap}, (response, error) => {
            if (error) {
                message.error("Error while downloading allocations excel");
                setExcelLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `SPAS Allocations (${new Date().toLocaleString()}).xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                setExcelLoading(false);
            })
        })
    }

    return (
        <ErrorBoundary>
            <Typography.Title level={4} className={'spas'}>Asset allocation</Typography.Title>
            <Space direction={'horizontal'}>
                <Form.Item>
                    <SelectPortfoliosModal selection={selectedDefinitionIds}
                                           updateSelection={(p_ids) => selectModels(p_ids)}/>
                </Form.Item>
                <Form.Item>
                    <Button type={'dashed'} onClick={() => download()}
                        icon={<FileExcelOutlined style={{color: "green"}}/>}
                        disabled={tableRows.length === 0} loading={excelLoading}
                    >
                        Download
                    </Button>
                </Form.Item>
            </Space>
            <Table
                columns={columns}
                className={'allocation-summary-performances'}
                dataSource={tableRows}
                loading={loading}
                rowKey={record => (record.ticker + "_" + record.asset_class_alloc)}
                size={"small"}
                pagination={{defaultPageSize: 300, hideOnSinglePage: true, pageSize: 300, position: 'topRight'}}
                rowClassName={(record) => record.ticker ? 'data-row' : 'category-row'}
                scroll={{
                    y: 1600,
                    x: 1200
                }}
            />
        </ErrorBoundary>
    );
}

export default AllocationAssets;
