import React, {useEffect, useState} from "react";
import {Avatar, Card, Col, Empty, Row, Typography} from "antd";
import {
    FileSearchOutlined,
    HistoryOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";
import TaskQueueSingleContainer from "./scheduler/containers/TaskQueueSingleContainer";
import {useRequest, useUpdateEffect} from "ahooks";
import {getOnline} from "@API/configuration";

const {Title} = Typography;
const {Meta} = Card;

const AdminMain = () => {
    const {data: onlineState} = useRequest(getOnline, {
        pollingInterval: 13000,
        pollingErrorRetryCount: 2
    });

    const [onlineUsers, setOnlineUsers] = useState([])
    const [onlineTasks, setOnlineTasks] = useState([])

    useUpdateEffect(() => {
        if (onlineState && onlineState.data) {
            setOnlineUsers(onlineState.data.users_online)
            setOnlineTasks(onlineState.data.tasks_online)
        }
    }, [onlineState])
    return (
        <>
            <Title level={3}>Administration</Title>
            <Row justify="space-between">
                <Col span={18}>
                    <Row justify="space-around" gutter={[0, 32]}>
                        <Col span={10}>
                            <Link to="users">
                                <Card
                                    hoverable
                                    style={{width: "100%", minHeight: "130px"}}
                                >
                                    <Meta
                                        title={<span style={{fontSize: "20px"}}>Users</span>}
                                        description="User management"
                                        avatar={<UsergroupAddOutlined style={{fontSize: "57px", color: "#3196B2"}}/>}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col span={10}>
                            <Card
                                hoverable
                                style={{width: "100%", minHeight: "130px"}}
                            >
                                <Meta
                                    title={<span style={{fontSize: "20px"}}>Settings</span>}
                                    description="Global SPAS settings"
                                    avatar={<SettingOutlined style={{fontSize: "57px", color: "#3196B2"}}/>}
                                />
                            </Card>
                        </Col>
                        <Col span={10}>
                            <Link to="scheduler">
                                <Card
                                    hoverable
                                    style={{width: "100%", minHeight: "130px"}}
                                >
                                    <Meta
                                        title={<span style={{fontSize: "20px"}}>Scheduler</span>}
                                        description="Scheduler tasks/queues/jobs management"
                                        avatar={<HistoryOutlined style={{fontSize: "57px", color: "#3196B2"}}/>}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col span={10}>
                            <Card
                                hoverable
                                style={{width: "100%", minHeight: "130px"}}
                            >
                                <Meta
                                    title={<span style={{fontSize: "20px"}}>Logs</span>}
                                    description="System and user logs"
                                    avatar={<FileSearchOutlined style={{fontSize: "57px", color: "#3196B2"}}/>}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Row justify="end" gutter={[0, 32]}>
                        <Col style={{width: "90%"}}>
                            <Card
                                title="Online users"
                                bodyStyle={{maxHeight: "270px", overflowY: "auto"}}
                            >
                                {onlineUsers && onlineUsers.length > 0 ?
                                    onlineUsers.map((user, inx) => {
                                        return <Card
                                            key={inx}
                                            bordered={false}
                                            bodyStyle={{padding: "12px"}}
                                        >
                                            <Meta
                                                className="online_state-card-meta"
                                                title={user.meta.full_name}
                                                description={<span style={{color: "#249136"}}>online</span>}
                                                avatar={<Avatar src={user.avatars.avatar_50}><UserOutlined/></Avatar>}
                                            />
                                        </Card>
                                    })
                                    : <Empty description="No users online"></Empty>

                                }
                            </Card>
                        </Col>
                        <Col style={{width: "90%"}}>
                            <TaskQueueSingleContainer schedulerEntity="task"
                                                      schedulerObjects={onlineTasks}
                                                      bodyStyle={{maxHeight: "270px", overflowY: "auto"}}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default AdminMain
