import React from "react";
import {Button, Flex, message, theme, Upload} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";
import {downloadFile} from "@global/FileDownloader";
import {
    getExampleExcelRetail,
    getPositionsFromFile
} from "@API/portfolio";


const {useToken} = theme;


const FileUpload = ({addPositions, setUploading}) => {
    const {token} = useToken();

    const exampleExcelDownload = () => {
        console.log("DOWNLOAD")
        getExampleExcelRetail((response, error) => {
            if (error) {
                message.error("Something went wrong while downloading example file");
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Portfolio example.xlsx`;
            downloadFile(response.data, file_name, file_type, () => {})
        })
    }

    const props = {
        showUploadList: false,
        multiple: true,
        maxCount: 10,
        customRequest: async ({file, onError}) => {
            console.debug('Custom request called');
            setUploading(true);
            let uploadData = new FormData();
            uploadData.append("file_content", file);
            uploadData.append("file_name", file.name);
            uploadData.append("file_type", file.type);
            uploadData.append("portfolio_name", file.name);
            uploadData.append("portfolio_type", 'temporal');

            getPositionsFromFile(uploadData, (result, error) => {
                if (error) {
                    console.error(result);
                    onError();
                } else {
                    addPositions(result);
                }
                setUploading(false);
            })
        },
    };

    return <Upload.Dragger
        {...props}
        className={"ignore-holistico"}
        style={{
            display: "flex",
            maxWidth: 360,
            background: "rgba(255, 255, 255, 0)",
            borderWidth: 2
        }}
    >
        <Flex
            align={"flex-start"}
            justify={"flex-start"}
            gap={"middle"}
            style={{height: "100%"}}
        >
            <CloudUploadOutlined style={{fontSize: 20}}/>
            <Flex vertical gap={"middle"} align={"flex-start"}>
                    <span style={{
                        color: token.colorTextDescription,
                        textAlign: "left"
                    }}>
                        Upload PDF, Excel or image file
                        with portfolio positions to add
                    </span>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        exampleExcelDownload();
                    }}
                    size={"small"}
                    type={"link"}
                    style={{
                        fontSize: token.fontSizeSM,
                        padding: 0
                    }}
                >
                    File example
                </Button>
            </Flex>

        </Flex>
    </Upload.Dragger>
}


export default FileUpload;
