/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Empty, Row, Spin, Tabs, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import Neighbours from "@components/asset/Neighbours";
import AssetClassPie from "@components/chart/AssetClassPie";
import GeneralInfo from './GeneralInfo';
import ScoreAttribution from './ScoreAttribution';
import EquityDistribution from './EquityDistribution';
import FixedIncomeDistribution from './FixedIncomeDistribution';
import parse from 'html-react-parser';


const {Title} = Typography;
const {TabPane} = Tabs;
const generalInfo = {
    "ticker": {
        title: "Ticker"
    }, "security_type": {
        title: "Security type"
    }, "global_category": {
        title: "Global category"
    }, "fi_credit_rating_alloc": {
        title: "Credit rating"
    }, "fi_super_sector_alloc": {
        title: "Fixed income sector"
    }, "fi_effective_maturity_alloc": {
        title: "Effective maturity"
    }, "sscore_plus": {
        title: "SScore+", formatter: (v) => ValueFormatter.round(v, 0)
    }, "expense_ratio": {
        title: "Exspense ratio", formatter: (v) => ValueFormatter.percent(v / 100, 0)
    }, "div_yield": {
        title: "Distribution rate", formatter: (v) => ValueFormatter.percent(v / 100, 0)
    }, "stocks_weight": {
        title: "Stocks weight", formatter: (v) => ValueFormatter.percent(v, 0)
    }, "bonds_weight": {
        title: "Bonds weight", formatter: (v) => ValueFormatter.percent(v, 0)
    }, "fund_size": {
        title: "Fund size", formatter: (v) => ValueFormatter.compactCurrency(v)
    },
}

const MFETFInfo = ({fullAsset, otherSelected, loadOpenApiData}) => {
    const [aiText, setAiText] = useState("");
    const [loading, setLoading] = useState(false)
    const run = () => {
        setLoading(true)
        loadOpenApiData((text) => {
            setAiText(text)
            setLoading(false)
        })
    }

    useEffect(() => {
        setAiText("")
    }, [fullAsset])

    const memoScoreAttribution = useMemo(() => {
        return <ScoreAttribution fullAsset={fullAsset}/>
    }, [fullAsset])

    const memoGeneralInfo = useMemo(() => {
        return <GeneralInfo fullAsset={fullAsset} generalInfo={generalInfo}/>
    }, [fullAsset])

    const memoFixedIncomeDistribution = useMemo(() => {
        return <FixedIncomeDistribution fullAsset={fullAsset}/>
    }, [fullAsset])

    const memoEquityDistribution = useMemo(() => {
        return <EquityDistribution fullAsset={fullAsset} generalInfo={generalInfo}/>
    }, [fullAsset])

    if (fullAsset) {
        return (<Card>
                {memoGeneralInfo}
                {Object.keys(fullAsset.asset_class_alloc_dist).length > 0 && <>
                    <br/>
                    <Row>
                        <Col span={24}>
                            <Title level={4}>Asset class</Title>
                            <AssetClassPie allocation={fullAsset.asset_class_alloc_dist} loading={false}/>
                        </Col>
                    </Row>
                </>}
                <br/>
                <div>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Ratings" key="1">
                            {memoScoreAttribution}
                        </TabPane>
                        <TabPane tab="Equity dist." key="2">
                            {memoEquityDistribution}
                        </TabPane>
                        <TabPane tab="Fixed income dist." key="3">
                            {memoFixedIncomeDistribution}
                        </TabPane>
                        <TabPane tab="Peers" key="4">
                            <Neighbours
                                neighbours={fullAsset.k_neighbours}
                                neighborClicked={otherSelected}
                            />
                        </TabPane>
                        <TabPane tab={<>&nbsp;AI<sup style={{color: 'red'}}>*</sup></>} key={"5"}>
                            <Button onClick={run} disabled={loading}>Get AI impression</Button>
                            <Spin spinning={loading} tip={"Talking to AI"}>
                                <Typography.Paragraph>
                                    {aiText.split("\n\n").map((par, index) => {
                                        return <Typography.Paragraph key={index}>
                                            {parse(par)}
                                        </Typography.Paragraph>
                                    })}
                                </Typography.Paragraph>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </Card>)
    } else {
        return (<Empty description={'No asset selected'}/>)
    }
}

export default MFETFInfo;
