/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {Tabs, Typography} from "antd";
import AllocationPerformances from "@components/allocations/AllocationPerformances";
import AllocationAssets from "@components/allocations/AllocationAssets";

const Allocations = () => {

    return (
        <div>
            <Typography.Title level={3}>Allocations</Typography.Title>
            <Tabs defaultActiveKey="performance">
                <Tabs.TabPane tab="Performance" key="performance">
                    <AllocationPerformances/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Asset allocation" key="asset">
                    <AllocationAssets/>
                </Tabs.TabPane>
            </Tabs>


        </div>
    );
}

export default Allocations;
