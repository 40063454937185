/* eslint-disable react/prop-types */
import React from 'react';
import {Flex, Tag, Typography, Button} from "antd";
import {blue, gold} from '@ant-design/colors';
import {CloseCircleFilled, PushpinOutlined,} from "@ant-design/icons";
import {useScreenerContext} from "@hooks/ScreenerContext";
import Localizator from "@global/Localizator";
import {
    getFilterType,
    isValidParamName,
} from "@components/screener/params/utils";


const getValueLabelForNumeric = (from, to) => {
    let label = "";
    if (from === to) {
        label += from;
    } else if (from && !to) {
        label += `${from} or above`;
    } else if (!from && to) {
        label += `${to} or below`;
    } else {
        label += `from ${from} to ${to}`;
    }
    return label;
}


const TagItem = ({item}) => {
    const {setSearchParams} = useScreenerContext()

    const onClose = (e) => {
        e.preventDefault();
        if (["choice", "search-pinned"].includes(item.type)) {
            setSearchParams(prev => {
                prev.delete(item.name, item.value)
                return prev;
            });
        }
        if (["allocation", "search"].includes(item.type)) {
            setSearchParams(prev => {
                prev.delete(item.name)
                return prev;
            });
        }
        if (["numeric", "allocation-item"].includes(item.type)) {
            setSearchParams(prev => {
                prev.delete(`${item.name}.from`)
                prev.delete(`${item.name}.to`)
                return prev;
            });
        }
    }

    return <Tag
        className={'filter-tag'}
        color={blue[4]}
        closeIcon={<CloseCircleFilled/>}
        bordered
        closable
        onClose={onClose}
    >
        {item.type === "search-pinned"
            &&
            <PushpinOutlined style={{
                color: gold[2],
                margin: "0 8px 0 0",
                fontSize: 20
            }}/>
        }
        {item.label}
    </Tag>
}


const TagsPanel = ({}) => {
    const {
        entityGroup,
        settings,
        searchParams,
        setSearchParams
    } = useScreenerContext()

    const items = []
    const toSkip = []
    searchParams.forEach((value, name) => {
        if (!isValidParamName(name, settings)){
            return;
        }

        const type = getFilterType(name, settings)

        if (type === 'choice') {
            const label = Localizator.t(`screener.${entityGroup}.${name}`)
            let valueLabel = value
            for (let option of settings.filters[name].options) {
                if (option.value === value) {
                    valueLabel = option.label;
                    break;
                }
            }
            items.push({
                name: name,
                value: value,
                type: type,
                label: `${label}: ${valueLabel}`
            })
            return;
        }

        if (type === 'allocation') {
            if (value !== 'custom') {
                let hasItemsInParams = false;
                searchParams.forEach((_value, _name) => {
                    if (_name.startsWith(name + ".")) {
                        hasItemsInParams = true;
                    }
                })
                if (hasItemsInParams) {
                    return;
                }
            }
            const label = Localizator.t(`screener.${entityGroup}.${name}`)
            let valueLabel = value
            for (let option of settings.filters[name].tips) {
                if (option.name === value) {
                    valueLabel = option.label;
                    break;
                }
            }
            items.push({
                name: name,
                value: value,
                type: type,
                label: `${label}: ${valueLabel}`
            })
        }

        if (type === 'numeric') {
            const p_name = name.split(".")[0]
            if (toSkip.includes(p_name)) {
                return;
            }
            const label = Localizator.t(`screener.${entityGroup}.${p_name}`)
            const _from = searchParams.get(`${p_name}.from`)
            const _to = searchParams.get(`${p_name}.to`)
            items.push({
                name: p_name,
                value: value,
                type: type,
                label: `${label}: ${getValueLabelForNumeric(_from, _to)}`
            })
            toSkip.push(p_name)
        }

        if (type === 'allocation-item') {
            const [p_name, idx, sign] = name.split(".")
            const i_name = `${p_name}.${idx}`
            if (toSkip.includes(i_name)) {
                return;
            }
            let label = Localizator.t(`screener.${entityGroup}.${p_name}`)
            label += ` / ${settings.filters[p_name].items[idx].label}`
            const _from = searchParams.get(`${i_name}.from`)
            const _to = searchParams.get(`${i_name}.to`)

            items.push({
                name: i_name,
                value: value,
                type: type,
                label: `${label}: ${getValueLabelForNumeric(_from, _to)}`
            })
            toSkip.push(i_name)
        }

        if (name === 'search') {
            items.push({
                name: name,
                value: value,
                type: 'search',
                label: `Ticker/name contains: ${value}`
            })
        }

        if (name === 'search-pinned') {
            items.push({
                name: name,
                value: value,
                type: 'search-pinned',
                label: `Ticker/name contains: ${value}`
            })
        }
    })

    const clearAll = () => {
        setSearchParams(prev => {
            items.map(item => {
                if (['numeric', 'allocation-item'].includes(item.type)) {
                    prev.delete(item.name + ".from")
                    prev.delete(item.name + ".to")
                } else {
                    prev.delete(item.name)
                }
            })
            return prev;
        })
    }

    return <Flex
        wrap={"wrap"}
        align={"center"}
        style={{
            minHeight: "40px",
            marginBottom: 12
    }}
    >
        {items.length === 0
            &&
            <Typography.Text type="secondary">
                No filters applied
            </Typography.Text>
        }
        {items.map((item, idx) => {
            return <TagItem key={idx + "." + item.name} item={item}/>
        })}
        {items.length > 1
            &&
            <Button
                onClick={clearAll}
                type={"link"}
                style={{padding: "3px 8px"}}
            >
                Clear all filters
            </Button>
        }
    </Flex>
}


export default TagsPanel;
