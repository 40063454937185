import {Popover} from "antd";
import React from "react";
import {InfoCircleOutlined} from "@ant-design/icons";

const HStatistic = ({title, children, info=null, ...props}) => {
    let style = props?.style ?? {}
    style["display"] = "flex"
    style["flexDirection"] = "column"

    return <div {...props} style={style}>
        <span className={"hint"} style={{display: "block", marginBottom: "4px"}}>
            {title}
            {info &&
                <Popover {...info}
                         overlayClassName={"holistico-dark-popover"}
                         style={{whiteSpace: "pre-wrap"}}
                >
                    <InfoCircleOutlined style={{marginLeft: "8px"}}/>
                </Popover>
            }
        </span>
        {children}
    </div>
}

export default HStatistic;