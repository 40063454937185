import React, {createContext, useContext, useEffect, useState} from "react";

const componentsContext = createContext();

const ComponentsWrapper = ({children}) => {
    const {components, push, pop, getIndex} = useExpand(children);
    console.debug(components)
    return <componentsContext.Provider value={{push, pop, getIndex}}>{components}</componentsContext.Provider>
};

const useComponentsHandler = () => {
    return useContext(componentsContext)
};

const useExpand = children => {
    const [components, setComponents] = useState([]);
    console.debug(components)
    useEffect(() => {
        if (children instanceof Array) {
            let updatedChildren = children.map(child => {
                return React.cloneElement(child, {key: getIndex()});
            })
            setComponents([...updatedChildren])
        } else {
            let propedComponent = React.cloneElement(children, {key: getIndex()});
            setComponents([propedComponent])
        }

    }, [children])

    const push = component => {
        console.debug(component)
        let propedComponent = React.cloneElement(component, {key: getIndex(), popIndex: getIndex()});
        console.log(propedComponent)
        setComponents([...components, propedComponent]);
    }

    const pop = index => {
        console.warn("POPPPPEPEPEPEPEPE", index)
        let _components = components.slice();
        _components.splice(index, 1);
        setComponents(_components);
    }

    const getIndex = () => {
        return components.length
    }

    return {
        components,
        push,
        pop,
        getIndex
    }
}

export {ComponentsWrapper, useComponentsHandler}
