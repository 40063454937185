import {Typography} from "antd";
import React from "react";


const PortfolioLabel = ({type, children, colored=false, compact=false}) => {
    return <div className={`portfolio-chart-label ${type}  ${colored ? "colored" : ""} ${compact ? "compact" : ""}`}>
        <Typography.Title level={4}>
            {children}
        </Typography.Title>
    </div>
}

export default PortfolioLabel
