import React from "react";
import {Flex, Form, theme, Typography} from 'antd';
import CodeInput from "@components/backtest/inputs/portfolio/CodeInput";
import PortfolioTypeInput
    from "@components/backtest/inputs/portfolio/PortfolioTypeInput";
import AggTypeInput
    from "@components/backtest/inputs/portfolio/AggTypeInput";
import AnyAssetInput from "@components/backtest/inputs/portfolio/AnyAssetInput";


const {useToken} = theme;


const PortfolioInput = ({field = 'portfolio_config'}) => {
    const form = Form.useFormInstance();
    const portfolioType = Form.useWatch([field, "type"], form);
    const {token} = useToken();

    return <Flex vertical>
        <Typography.Text style={{
            fontSize: token.fontSizeXL,
            fontWeight: 500,
        }}>
            {field ==="portfolio_config" ? "Portfolio" : "Benchmark"}
        </Typography.Text>
        <Flex vertical gap={"middle"}>
            <PortfolioTypeInput field={field}/>
            {(portfolioType === "companies" || !portfolioType)
                && <>
                    <AggTypeInput field={field}/>
                    <CodeInput field={field}/>
                </>
            }
            {portfolioType === "any"
                && <AnyAssetInput field={field}/>
            }
        </Flex>
    </Flex>
}

export default PortfolioInput
