import {useCountDown} from "ahooks";
import {Progress} from "antd";
import ChartStyles from "@global/ChartStyles";
import React from "react";


const TimedLoader = ({
                         secondsToWait,
                         stepsCount = 100,
                         status = 'success',
                         size = "small",
                         strokeColor=ChartStyles.get_status_color(status)
                     }) => {
    const [lifetime, setLifetime] = React.useState(secondsToWait * 1000);
    const [countdown] = useCountDown({leftTime: lifetime});

    React.useEffect(() => {
        console.log(secondsToWait)
        setLifetime(secondsToWait * 1000)
    }, [secondsToWait])

    return <Progress
        percent={100 - (countdown / lifetime) * 100}
        steps={stepsCount}
        size={size}
        strokeColor={strokeColor}
        showInfo={false}
    />
}

export default TimedLoader
