import {Col, Empty, Row} from 'antd'
import AssetClassPie from "@holistico/chart/AssetClassPie";
import React from "react";
import SizeStyleHeat from "@holistico/chart/SizeStyleHeat";

const StyleSizeCompareRow = ({original_values, recommended_values}) => {
    if (!original_values || Object.keys(original_values).length === 0) return <Empty style={{margin: "15px 0"}}
                                                                 description="No style information"/>;

    return <Row gutter={[24, 24]} style={{width: '100%'}}>
        <Col span={12}>
            <SizeStyleHeat loading={false}
                           allocation={original_values?.size_style}/>
        </Col>
        <Col span={12}>
            <SizeStyleHeat loading={false}
                           allocation={recommended_values?.size_style}/>
        </Col>
    </Row>
}

export default StyleSizeCompareRow
