/* eslint-disable react/prop-types */
import React from 'react';
import {Button, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import {CloudFilled} from "@ant-design/icons";


const OrionClientListItem = ({client, blur, onClick}) => {
    return <div className={"proposals-list-item"}
                style={{alignItems: "center"}}>
        <div className={"risk-indicator-low"} style={{height: 50, width: 50}}>
            <CloudFilled style={{fontSize: '16px', color: '#08c'}}/>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginLeft: "16px",
        }}>
            <Typography.Text
                className={blur ? "blured-4" : ""}
                style={{
                    fontSize: "20px",
                    lineHeight: 1.4,
                }}
            >
                {!!client.full_name ? client.full_name : "Anonymous"}
            </Typography.Text>
        </div>
        <Typography.Text style={{
            padding: "0 24px",
            borderRight: "1px #F0F0F0 solid",
            fontSize: "20px",
            whiteSpace: "nowrap",
        }}>
            {ValueFormatter.compactCurrency(client?.p_bucket_cur?.calculated_values?.market_value) ?? ""}
        </Typography.Text>
        <Button onClick={() => onClick(client.external_id)}
                type="link"
                size={"large"}
                style={{margin: "0 24px"}}
        >
            Create proposal
        </Button>
    </div>
}

export default OrionClientListItem;
