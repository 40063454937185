import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const getExportConfigs = (handler) => {
    let url = `/weekly/config`

    axiosInstance.get(url,
    {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
    .then(({data}) => {
        handler(data, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const getExportConfig = (id, handler) => {
    let url = `/weekly/config/${id}`

    axiosInstance.get(url,
    {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
    .then(({data}) => {
        handler(data, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const createExportConfig = (config, handler) => {
    let url = `/weekly/config`

    axiosInstance.post(url, config)
    .then(() => {
        handler(null, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const updateExportConfig = (id, config, handler) => {
    let url = `/weekly/config/${id}`

    axiosInstance.put(url, config)
    .then(() => {
        handler(null, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const deleteExportConfig = (id, handler) => {
    let url = `/weekly/config/${id}`

    axiosInstance.delete(url)
    .then(() => {
        handler(null, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const getFactorsRules = (handler) => {
    let url = `/weekly/get_factors_rules`

    axiosInstance.get(url,
    {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
    .then(({data}) => {
        handler(data, false);
    })
    .catch(({response}) => {
        console.log(response);
        handler(response, true);
    })
};

const downloadWeekly = (configId, callback) => {
    let url;
    if (configId) {
        url = `/weekly/download?config_id=${configId}`;
    } else {
        url = `/weekly/download`;
    }

    axiosInstance.get(url, {
        responseType: "arraybuffer",
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
    .then((response) => {
        callback(response, false);
    })
    .catch(({response}) => {
        console.log(response)
        callback(response, true);
    })
}

export {
    getExportConfigs,
    getExportConfig,
    createExportConfig,
    deleteExportConfig,
    updateExportConfig,
    getFactorsRules,
    downloadWeekly,
    cancel
}
