/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Modal, Typography} from 'antd';
import OneRiskImproveStep from "@components/questionnaire/steps/OneRiskImproveStep";

const {Paragraph} = Typography;

const QStepRiskImprove = ({financialParams = {}, financialParamsChanged}) => {
    const [tuneStep, setTuneStep] = useState(1)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedScore, setSelectedScore] = useState(financialParams['risk'])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = (riskScore) => {
        setIsModalVisible(false);
        console.debug('risk improve done!')
        financialParamsChanged('risk-', selectedScore)
    };

    const handleCancel = () => {
        setTuneStep(1)
        setIsModalVisible(false);
    };

    useEffect(() => {

    }, []);

    const itemSelected = (riskScore) => {
        setSelectedScore(riskScore)
        console.debug('risk improve item selected!, ' + tuneStep)
        if (riskScore == financialParams.risk || tuneStep == 4) {
            //we are done
            showModal()
        } else {
            setTuneStep(tuneStep + 1)
            financialParamsChanged('risk', riskScore)
        }
    }

    return (
        (<div>
            <OneRiskImproveStep financialParams={financialParams} step={tuneStep} itemSelected={itemSelected}/>
            <Modal title="Apply choice" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Paragraph>Proceed to finalizing your risk preferences? Please confirm your are fine with applying the
                    current selection.</Paragraph>
                <Paragraph>Press Cancel to continue fine tuning.</Paragraph>
            </Modal>
        </div>)
    );
}

export default QStepRiskImprove;
