import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    Typography,
    Row,
    Col,
    Table,
    Button,
    message,
    Card,
    theme, ConfigProvider, Flex, Statistic
} from 'antd';
import {downloadStatisticsExcel} from "@API/backtest";
import {
    AreaChartOutlined,
    CloudDownloadOutlined
} from "@ant-design/icons";
import {downloadFile} from "@global/FileDownloader";
import Localizator from "@global/Localizator";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {animateScroll as scroll} from 'react-scroll'
import {debounce} from '@global/Utils';
import PerformanceChart from "@components/backtest/summary/PerformanceChart";


const {useToken} = theme;


const STATS_TABLE_FIELDS = ['mean', 'std', 'sharpe_ratio', 'min', 'median', 'max', 'mean_semideviation', 'n_obs'];
const PERCENTAGES = ['mean', 'median', 'min', 'max', 'std', 'mean_semideviation'];
const INTEGERS = ['n', 'n_obs'];
const ALPHA_SKIP = ['sharpe_ratio', 'n_obs'];


const cellRenderer = (value, record) => {
    let field = record['key'];
    if (isNaN(value)) {
        return '-';
    }
    let formattedValue = value;
    if (PERCENTAGES.includes(field)) {
        formattedValue = ValueFormatter.int_percent(value, '-');
    } else if (INTEGERS.includes(field)) {
        formattedValue = ValueFormatter.round(value, 0, true, '-');
    } else {
        formattedValue = ValueFormatter.number(value);
    }
    return formattedValue;
}


const tableColumns = [
    {
        title: '',
        dataIndex: 'fieldName',
        key: 'name',
        width: 100
    },
    {
        title: 'Alpha',
        dataIndex: 'alpha',
        key: 'alpha',
        render: cellRenderer,
        width: 50,
        align: "right"
    },
    {
        title: 'Portfolio return',
        dataIndex: 'portfolio',
        key: 'portfolio',
        render: cellRenderer,
        width: 50,
        align: "right"
    }, {
        title: 'Benchmark return',
        dataIndex: 'benchmark',
        key: 'benchmark',
        render: cellRenderer,
        width: 50,
        align: "right"
    }
];


const prepareRows = (statistics) => {
    let rows = [];
    let {alpha, benchmark, portfolio} = statistics;
    for (let field of STATS_TABLE_FIELDS) {
        let row = {
            'benchmark': benchmark[field],
            'portfolio': portfolio[field],
            'fieldName': Localizator.t('statistics.' + field),
            'key': field
        }
        if (!ALPHA_SKIP.includes(field)) {
            row["alpha"] = alpha[field];
        }
        rows.push(row);
    }
    return rows;
}


const prepareMainStatistics = (statistics) => {
    let stats = {};
    stats['alpha'] = statistics.alpha?.mean;
    stats['jensens_alpha'] = statistics.regression?.jensens_alpha;
    stats['beta'] = statistics.regression?.beta;
    stats['tracking_error'] = statistics.regression?.tracking_error;
    return stats;
}


const BacktestStatistics = ({statistics, config}) => {
    const [mainStatistics, setMainStatistics] = useState({});
    const [rows, setRows] = useState([]);
    const [excelLoading, setExcelLoading] = useState(false);
    const titleRef = useRef(null);
    const chartRef = useRef(null);
    const {token} = useToken();

    useEffect(() => {
        if (statistics) {
            setMainStatistics(prepareMainStatistics(statistics));
            setRows(prepareRows(statistics));
            if (rows && rows.length > 0) {
                debounce(titleRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                }), 300);
            } else {
                debounce(scroll.scrollToBottom({duration: 1500}), 300);
            }
        } else {
            setMainStatistics({});
            setRows([]);
        }
    }, [statistics])

    const download = useCallback(() => {
        setExcelLoading(true);
        downloadStatisticsExcel({
            stats: statistics,
            config_name: config.name,
            config: config
        }, (response, error) => {
            if (error) {
                message.error("Error while downloading statistics");
                setExcelLoading(false);
            } else {
                let file_type = response.headers['content-type'];
                let file_name = `Backtest statistics.xlsx`;
                downloadFile(response.data, file_name, file_type, () => {
                    setExcelLoading(false);
                })
            }
        })
    }, [statistics, config])

    return <Card hoverable ref={titleRef} style={{marginTop: token.padding}}>
        <Flex gap={"middle"} justify={"space-between"} align={"baseline"} wrap={"wrap"}>
            <Typography.Title
                level={4}
                style={{margin: 0}}
            >
                Summary statistics
            </Typography.Title>
            <Flex gap={"small"}>
                <Button
                    onClick={download}
                    loading={excelLoading}
                    icon={<CloudDownloadOutlined/>}
                >
                    Download table
                </Button>
                <Button
                    onClick={() => chartRef.current.downloadImage("Backtested performance.png")}
                    loading={excelLoading}
                    icon={<AreaChartOutlined/>}
                >
                    Download chart
                </Button>
            </Flex>
        </Flex>
        <Row gutter={[token.padding, token.padding]} style={{marginTop: token.padding}}>
            {Object.keys(mainStatistics).map((key, index) => {
                return <Col key={key} xs={12} sm={6} md={6} lg={3} xl={3}>
                    <Statistic
                        title={Localizator.t('statistics.' + key)}
                        value={is_null(mainStatistics[key]) ? "-" : ValueFormatter.number(mainStatistics[key], 2)}
                    />
                </Col>
            })}
        </Row>
        <Row gutter={[token.paddingLG, token.paddingLG]} style={{marginTop: token.paddingLG}}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <ConfigProvider theme={{
                    token: {
                        fontSize: 14,
                        colorBgBase: token.colorBgGrey,
                    },
                    components: {
                        Table: {headerBg: token.colorBgGrey},
                    }
                }}>
                    <Flex vertical gap={"middle"} style={{
                        background: token.colorBgGrey,
                        padding: token.padding,
                        borderRadius: token.borderRadius
                    }}>
                        <Table
                            size="small"
                            dataSource={rows}
                            columns={tableColumns}
                            pagination={false}
                        />
                        <Typography.Text type="secondary">
                            <span style={{color: "red"}}>* </span>
                            Alpha, returns, and other statistics
                            are annual
                        </Typography.Text>
                    </Flex>
                </ConfigProvider>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <PerformanceChart statistics={statistics} chartRef={chartRef}/>
            </Col>
        </Row>
    </Card>

}

export default BacktestStatistics
