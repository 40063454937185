import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    Flex,
    List, Spin,
    theme,
    Typography,
} from 'antd';
import {RightOutlined} from "@ant-design/icons";
import Constants from "@global/Constants";
import ValueFormatter, {coalesce, is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import OverviewCard, {getChecks} from "@components/retail/portfolio/OverviewCard";
import StressTestCard, {prepareScenarios} from "@components/retail/portfolio/StressTestCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import RiskProfileCard from "@components/retail/portfolio/RiskProfileCard";
import {
    retirementAmountSuccessProbability
} from "@holistico/proposal/view/retirement_map/RetirementGraph";
import RetirementMapCard from "@components/retail/portfolio/RetirementMapCard";


const {useToken} = theme;


const StatsList = ({
                       data,
                       title = null,
                       showDetailsLink = false,
                       onDetailsLinkClick = null,
                       loading = false
                   }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Flex
        vertical
        align={"stretch"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            padding: token.paddingSM,
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            borderRadius: token.borderRadiusLG,
            width: "100%"
        }}
    >
        <Spin spinning={loading}>
            {(title || showDetailsLink)
                && <Flex
                    gap={"large"}
                    align={"flex-end"}
                    style={{marginBottom: token.paddingXS}}
                >
                    {title
                        && <Typography.Title level={5} style={{
                            margin: 0,
                            fontSize: token.fontSize,
                        }}>
                            {title}
                        </Typography.Title>
                    }
                    {showDetailsLink
                        && <Flex
                            justify={"flex-end"}
                            flex={1}
                        >
                            {/*<Button*/}
                            {/*    type={"link"}*/}
                            {/*    size={"small"}*/}
                            {/*    onClick={onDetailsLinkClick}*/}
                            {/*    icon={<RightOutlined*/}
                            {/*        style={{fontSize: token.fontSizeSM}}/>}*/}
                            {/*    style={{*/}
                            {/*        padding: 0,*/}
                            {/*        background: "white",*/}
                            {/*        display: "flex",*/}
                            {/*        justifyContent: "center",*/}
                            {/*        alignItems: "center",*/}
                            {/*        height: 30,*/}
                            {/*        width: 30,*/}
                            {/*        borderRadius: 30,*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Button*/}
                            {/*    type={"text"}*/}
                            {/*    size={"small"}*/}
                            {/*    onClick={onDetailsLinkClick}*/}
                            {/*    style={{*/}
                            {/*        fontSize: token.fontSizeSM,*/}
                            {/*        color: token.colorTextDescription,*/}
                            {/*        marginRight: -token.paddingXXS*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Details*/}
                            {/*   <RightOutlined style={{fontSize: token.fontSizeSM}}/>*/}
                            {/*</Button>*/}
                        </Flex>
                    }
                </Flex>
            }
            <List
                dataSource={data}
                style={{width: "100%"}}
                renderItem={(item, index) => {
                    return <List.Item key={index} style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        border: "none",
                        padding: "2px 0",
                    }}>
                        <Flex gap={token.paddingXS} align={"baseline"}>
                            <Badge dot color={token.colorWarning}/>
                            <Typography.Text style={{lineHeight: 1.1}}>
                                {item.title}
                            </Typography.Text>
                        </Flex>
                        <Typography.Text style={{
                            fontWeight: token.fontWeightStrong,
                            flexShrink: 0,
                            textAlign: "right",
                            minWidth: 70,
                            marginLeft: token.padding,
                            marginRight: token.paddingXXS
                        }}>
                            {item.value}
                        </Typography.Text>
                    </List.Item>
                }}
            />
            {showDetailsLink &&
                <Button
                    type={"link"}
                    size={"small"}
                    onClick={onDetailsLinkClick}
                    style={{
                        fontSize: token.fontSizeSM,
                        padding: 0,
                        marginTop: token.paddingXXS,
                    }}
                >
                    Details
                    <RightOutlined style={{fontSize: token.fontSizeSM}}/>
                </Button>
            }
        </Spin>
    </Flex>
}


const PortfolioStats = ({}) => {
    const {proposal} = useHolisticoProposalContext();

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const data = [
        {
            title: "Market value",
            value: is_null(cv?.market_value) ? "-" : ValueFormatter.currency(cv.market_value)
        },
        {
            title: "Positions",
            value: is_null(cv?.unique_pos_count) ? "-" : ValueFormatter.int_number(cv.unique_pos_count)
        },
        {
            title: "Distribution rate",
            value: is_null(cv?.annual_dividend) ? "-" : ValueFormatter.int_percent(cv.annual_dividend)
        },
        {
            title: "Expense ratio",
            value: is_null(cv?.expense_ratio) ? "-" : ValueFormatter.int_percent(cv.expense_ratio)
        },
    ];

    return <StatsList data={data}/>
}


const RiskProfileStats = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const data = [
        {
            title: "Portfolio risk score",
            value: coalesce(cv?.risk?.risk_score, "-"),
        },
        {
            title: "Risk ability score",
            value: coalesce(proposal?.questionnaire?.risk_profile?.ability, "-"),
        },
        {
            title: "Risk tolerance score",
            value: coalesce(proposal?.questionnaire?.risk_profile?.behavioral, "-"),
        },
    ];

    return <>
        <StatsList
            data={data}
            title={"Risk profile"}
            showDetailsLink={true}
            onDetailsLinkClick={() => setOpenDrawer(true)}
        />
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setOpenDrawer(false);
            }}
        >
            <RiskProfileCard/>
        </MobileDrawer>
    </>
}


const ReviewStats = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);

    const checks = getChecks(proposal);
    const nWarning = checks.filter(check => !check.passed).length;
    const nSuccess = checks.filter(check => check.passed).length;
    const data = [
        {
            title: "Review warnings",
            value: nWarning ? `${nWarning} / ${nWarning + nSuccess}` : "-",
        },
    ];

    return <>
        <StatsList
            data={data}
            title={"Review"}
            showDetailsLink={true}
            onDetailsLinkClick={() => setOpenDrawer(true)}
        />
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setOpenDrawer(false);
            }}
        >
            <OverviewCard collapsible={false}/>
        </MobileDrawer>
    </>
}


const StressTestStats = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const scenarios = prepareScenarios(cv?.risk?.stress_test);
    const score = scenarios?.reduce((s, a) => s + a.portfolio_score, 0);
    const scoreMax = scenarios?.length * 5;

    const data = [
        {
            title: "Stress tests points",
            value: score ? `${score} / ${scoreMax}` : "-",
        },
    ];

    return <>
        <StatsList
            data={data}
            title={"Stress tests"}
            showDetailsLink={true}
            onDetailsLinkClick={() => setOpenDrawer(true)}
        />
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setOpenDrawer(false);
            }}
        >
            <StressTestCard collapsible={false}/>
        </MobileDrawer>
    </>
}


const RetirementMapStats = ({}) => {
    const {proposal, retirement, loadingRetirement} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);

    const data = [
        {
            title: "Projected wealth at retirement",
            value: is_null(retirement?.projected_at_retirement)
                ? "-"
                : ValueFormatter.currency(retirement.projected_at_retirement)

        },
        {
            title: "Probability of running out of money",
            value: retirementAmountSuccessProbability(100 - retirement?.survival_probability)
        },
    ];

    return <>
        <StatsList
            data={data}
            title={"Retirement map"}
            showDetailsLink={true}
            onDetailsLinkClick={() => setOpenDrawer(true)}
            loading={loadingRetirement}
        />
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setOpenDrawer(false);
            }}
        >
            <RetirementMapCard/>
        </MobileDrawer>
    </>
}


const MyPortfolioCard = () => {
    const {proposal} = useHolisticoProposalContext();
    const navigate = useNavigate();
    const {token} = useToken();

    const portfolioExists = proposal?.current_portfolios && proposal?.current_portfolios.length > 0;

    return <Card hoverable style={{
        height: "100%",
        cursor: "default",
    }}>
        <Flex vertical gap={"small"} align={"flex-start"}>
            <Typography.Title level={4} style={{margin: 0, lineHeight: 1.2}}>
                My portfolio
            </Typography.Title>
            {portfolioExists
                ? <>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            navigate({pathname: `/${Constants.RETAIL_URL}/portfolio`});
                        }}
                    >
                        Open my portfolio
                    </Button>
                    <PortfolioStats/>
                    <RiskProfileStats/>
                    <ReviewStats/>
                    <StressTestStats/>
                    <RetirementMapStats/>
                </>
                : <>
                    <Typography.Text>
                        Upload the portfolio to get the analysis
                        and AI comments.
                    </Typography.Text>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            navigate({pathname: `/${Constants.RETAIL_URL}/portfolio`});
                        }}
                        style={{marginBottom: token.padding}}
                    >
                        Upload my portfolio
                    </Button>
                    <RiskProfileStats/>
                </>
            }
        </Flex>
    </Card>
}

export default MyPortfolioCard;
