import React from 'react';
import {Progress} from 'antd';

const SellSign = ({value}) => {
    return <Progress percent={value} steps={10} size="small" strokeColor="#f5222d"
                     showInfo={true} status="exception" format={(percent) => {
        return "Sell"
    }}/>
}

export default SellSign;
