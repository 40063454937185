import React, {useContext} from "react";
import {Form, Select} from 'antd';
import {BacktestContext} from "@components/backtest/BacktestContext";


const WeightingSchemaInput = ({}) => {
    const {weight_options} = useContext(BacktestContext);
    const options = weight_options.map(item => ({value: item['key'], label: item['label']}))

    return <Form.Item
        name={"weight_model"}
        label={<b>Group weights</b>}
        tooltip={
            'Group weights (e.g. size groups) ' +
            ' in the portfolio and in the benchmark' +
            ' will be set equal to the group weights of a chosen index/ETF. ' +
            ' If "As in benchmark" is chosen, group weights ' +
            ' in the portfolio will be set equal to the group weights ' +
            ' of the benchmark'
        }
        dependencies={[["portfolio_config", "type"], ["benchmark_config", "type"]]}
        validateTrigger="onSubmit"
        rules={[
            ({getFieldValue}) => ({
                validator(_, value) {
                    if (value !== 1 || getFieldValue(["benchmark_config", "type"]) === 'companies') {
                        return Promise.resolve();
                    }
                    if (getFieldValue(["portfolio_config", "type"]) !== 'companies') {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(
                            'This option works only with the "Companies" benchmark!'
                            + " Please choose another option or change the benchmark..."
                        )
                    );
                },
            }),
        ]}
    >
        <Select
            options={options}
            style={{width: '100%'}}
        />

    </Form.Item>
}

export default WeightingSchemaInput;
