import React, {createContext, useContext, useEffect, useState} from "react";
import {
    loadFullClient as loadFullProposal,
    saveQuestionnaire, updateAiSettings, updateClientInfo as _updateClientInfo,
} from "@API/clients";
import {message, Spin} from "antd";
import {useRequest, useUpdateEffect} from "ahooks";
import {getAiThoughts} from "@API/ahooks/proposal";
import {
    prepareDataForRequest
} from "@holistico/proposal/view/retirement_map/RetirementMap";
import {calculateRetirement} from "@API/retirement";

const DEFAULT_AI_SETTINGS = {
    'model': 'gpt-4',
    'max_tokens': 800,
    character_params: {
        charm: 5,
        humor: 5,
        mood: 5,
        intelligence: 5,
        assertiveness: 5,
    }
}
const ProposalContext = createContext(null);


const HolisticoProposalContextProvider = ({
                                              proposal_id,
                                              startLoading = true,
                                              loadIteration = 0,
                                              autoUpdateRetirement = false,
                                              children
                                          }) => {
    const [id, setId] = useState(proposal_id);
    const [proposal, setProposal] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [notes, setNotes] = useState(null);
    const [benchmark, setBenchmark] = useState(null);
    const [loading, setLoading] = useState(startLoading);
    const [notesInReport, setNotesInReport] = useState(true);
    const [resultData, setResultData] = useState({
        message: '',
        status: 'info',
        title: ''
    })
    const [aiSettings, setAiSettings] = useState(null);
    const [aiAnalyticalComments, setAnalyticalComments] = useState(null);
    const {
        runAsync: getAiAnalyticalComments,
        loading: loadingAiAnalyticalComments
    } = useRequest(getAiThoughts, {manual: true});
    const [loadingRetirement, setLoadingRetirement] = useState(false);
    const [retirement, setRetirement] = useState(null);

    useEffect(() => {
        setId(proposal_id)
    }, [proposal_id]);

    useEffect(() => {
        console.log(`loading proposal ${id}`)
        fetchProposal();
    }, [id])

    useEffect(() => {
        if (autoUpdateRetirement) {
            console.log("fetching retirement data")
            fetchRetirementData();
        }
    }, [proposal]);

    useUpdateEffect(() => {
        console.log(`outer proposal ${id} update: ${loadIteration}`)
        fetchProposal();
    }, [loadIteration])

    const plugAiSettings = (fullProposal) => {
        if (fullProposal?.related_info?.ai_settings) {
            setAiSettings(fullProposal.related_info.ai_settings)
        } else {
            setAiSettings(DEFAULT_AI_SETTINGS)
        }
    }

    const plugConversation = (fullProposal) => {
        if (fullProposal?.related_info?.conversation.length > 0) {
            console.debug('updating the conversation')
            setConversation(fullProposal?.related_info?.conversation)
        } else {
            setConversation([])
        }
    }

    const plugNotes = (fullProposal) => {
        if (fullProposal?.related_info?.notes) {
            console.debug('updating the notes')
            setNotes(fullProposal?.related_info?.notes)
        } else {
            setNotes('')
        }
    }

    const setTempProposalPortfolio = (proposal) => {

    }

    const fetchProposal = (with_loading = true, callback=null) => {
        if (id == null) {
            return
        }
        if (with_loading) {
            console.debug(`loading set!`)
            setLoading(true);
        }
        loadFullProposal(id, (result, error) => {
            setLoading(false);
            if (error) {
                console.log(result)
                setResultData({
                    message: result['message'],
                    status: 'error',
                    title: 'Access denied!'
                })
            } else {
                setProposal(result);
                setAnalyticalComments(result?.related_info?.structured_comments);
                plugAiSettings(result);
                plugConversation(result);
                plugNotes(result);
            }
            if (callback) {
                callback();
            }
        })
    }

    const fetchRetirementData = () => {
        if (!proposal) {
            setLoadingRetirement(false);
            setRetirement(null);
            return;
        }
        setLoadingRetirement(true);
        const data = prepareDataForRequest(proposal, false, false);
        if (!data) {
            setLoadingRetirement(false);
            setRetirement(null);
            return;
        }
        calculateRetirement(data,
            ret_data => {
                setRetirement(ret_data);
                setLoadingRetirement(false);
            },
            error => {
                console.log(error);
                message.error("Something went wrong while processing retirement map data!");
                setLoadingRetirement(false);
            }
        );
    }

    const updateBaseClientInfo = (values, callback) => {
        setLoading(true)
        _updateClientInfo(proposal._id, values, (response, error) => {
            if (!error) {
                fetchProposal(false)
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }
            setLoading(false)
            if (callback) {
                callback()
            }
        })
    }

    const updateClientInfo = (values, with_loading = false, callback = null) => {
        _updateClientInfo(proposal._id, values, (response, error) => {
            if (!error) {
                updateQuestionnaire(values?.questionnaire, with_loading)
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }
            if (callback) {
                callback()
            }
        });
    }

    const updateQuestionnaire = (values, with_loading = false, callback = null) => {
        saveQuestionnaire(proposal._id, values, (response, error) => {
            if (!error) {
                fetchProposal(with_loading);
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }
            if (callback) {
                callback()
            }
        });
    }

    const updateAiAnalyticalComments = () => {
        getAiAnalyticalComments(proposal._id, "json").then((result) => {
            if (result) {
                console.log("updateAiAnalyticalComments", result.data);
                setAnalyticalComments(result.data);
            }
        }).catch((error) => {
            console.error(error);
            message.error("Failed to load AI analytical comments!");
        }).finally()
    }

    const modifyAiSettings = (aiSettings) => {
        updateAiSettings(id, aiSettings, (response, error) => {
            if (!error) {
                setAiSettings(aiSettings)
            } else {
                message.error("Failed to update ai settings!")
            }
        })
    }

    return <ProposalContext.Provider
        value={{
            id,
            setId,
            proposal,
            setProposal,
            benchmark,
            setBenchmark,
            conversation,
            notes,
            setNotes,
            notesInReport,
            setNotesInReport,
            loading,
            setLoading,
            resultData,
            fetchProposal,
            updateClientInfo,
            updateBaseClientInfo,
            updateQuestionnaire,
            aiSettings,
            modifyAiSettings,
            updateAiAnalyticalComments,
            loadingAiAnalyticalComments,
            aiAnalyticalComments,
            loadingRetirement,
            setLoadingRetirement,
            retirement
        }}
    >
        <Spin style={{height: 800}} spinning={loading} tip="Loading..."
              size={"large"}>
            {children}
        </Spin>
    </ProposalContext.Provider>

}

const useHolisticoProposalContext = () => {
    const context = useContext(ProposalContext)
    if (!context) {
        throw new Error(
            "useHolisticoProposalContext must be used within HolisticoProposalContextProvider"
        )
    }
    return context;
}

export {HolisticoProposalContextProvider, useHolisticoProposalContext}
