import React from "react";
import ImportFileRow from "./ImportFileRow";

const EditableFilesTable = ({files, changeFiles, types}) => {
    const changeFile = (index, newFileDetails) => {
        let newFilesArray = files.slice();
        newFilesArray[index] = newFileDetails;
        changeFiles(newFilesArray);
    }

    const deleteFile = index => {
        let newFilesArray = files.slice();
        newFilesArray.splice(index, 1);
        changeFiles(newFilesArray);
    }

    return (
        <div>
            {files.map((file, inx) => {
                return (
                    <ImportFileRow key={inx} index={inx}
                                   file={file} changeFile={changeFile}
                                   deleteFile={deleteFile} types={types}
                    />
                )
            })}
        </div>
    )
}

export default EditableFilesTable
