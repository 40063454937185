/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import {Image, Table, Typography} from "antd";
import ValueFomatter from "@global/ValueFormatter";
import './StressInfo.less';
import Localizator from "@global/Localizator";


const getPerformanceData = (dollarReturn, percentReturn, benchmark_label) => {
    let formattedDollars = ValueFomatter.compactCurrency(Math.abs(dollarReturn))
    let formattedPercent = ValueFomatter.oneDigitPercent(percentReturn, 0)
    if (percentReturn > 0) {
        formattedPercent = '+' + formattedPercent
    }
    let colorClass = percentReturn < 0 ? 'stress-negative' : 'stress-positive'

    if (benchmark_label) {
        let shortLabel = benchmark_label.split(' ').pop().replace("(", "").replace(")", "")
        return <div className={"stress-performance-cell " + colorClass}>
            <span className={'label'}>{shortLabel}</span>
            <span className={'percent-value'}>{formattedPercent}</span>
            <span className={'dollar-value'}>{formattedDollars}</span>
        </div>
    } else {
       return <div className={"stress-performance-cell " + colorClass}>
            <span className={'percent-value'}>{formattedPercent}</span>
            <span className={'dollar-value'}>{formattedDollars}</span>
        </div>
    }
}


const StressTable = ({stressItems}) => {

    const columns = [
        {
            title: '',
            dataIndex: 'icon',
            width: 64,
            render: (nothing, record) => {
                let scenText = record['period_label']
                let imgPath = '/img/bull.png'
                let color = 'rgb(134, 189, 145)'
                if (scenText.includes('bear')) {
                    imgPath = '/img/bear.png'
                } else if (scenText.includes('crisis')) {
                    color = '#f5222d;'
                    imgPath = '/img/crisis.png'
                } else if (scenText.includes('interest')) {
                    imgPath = '/img/interest.png'
                } else if (scenText.includes('crash')) {
                    color = '#f5222d;'
                    imgPath = '/img/virus_crash.png'
                }
                return <Image
                    height={60}
                    width={60}
                    style={{opacity: 0.85}}
                    preview={false}
                    src={imgPath}
                />
            }
        },
        {
            title: 'Scenario',
            dataIndex: 'period_label',
            render: (text) => {
                const formattedTitle = Localizator.t('stress.' + text)
                return <span className={"stress-scenario-cell"}>{formattedTitle}</span>
            }
        },
        {
            title: 'Benchmark ETF',
            dataIndex: 'benchmark',
            render: (nothing, record) => {
                let info = record['benchmarkInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'], info['title'])
            }
        },
        {
            title: 'Current',
            dataIndex: 'portfolio',
            render: (nothing, record) => {
                let info = record['portfolioInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'])
            }
        },
    ];

    if (stressItems.length && stressItems[0].proposalInfo) {
        columns.push({
            title: 'Proposed',
            dataIndex: 'proposal',
            render: (nothing, record) => {
                let info = record['proposalInfo']
                return getPerformanceData(info['dollarPortfolioReturn'], info['portfolioReturn'])
            }
        })
    }

    return <Table
        rowKey='period_label'
        pagination={false}
        columns={columns}
        className={'stress-table'}
        bordered={false}
        dataSource={stressItems}/>
}

export default StressTable
