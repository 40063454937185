import React, {useContext, useRef, useState} from "react";
import {Alert, Button, message, Space, Typography} from "antd";
import MuteModal from "@components/supervision/actions/form/MuteModal";
import LimitModal from "@components/supervision/actions/form/LimitModal";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";

import ClientMetricsResult from "./ClientMetricsResult";
import {setLimit} from "@API/supervision";
import MetricsResultDetails from "./MetricsResultDetails";
import ValueFormatter from "@global/ValueFormatter";


const ClientRedFlagContainer = ({flag, clientInfo}) => {
    const {updateData, configsDetails} = useContext(SupervisionContext);
    const [muteModal, setMuteModal] = useState(false);
    const [limitModal, setLimitModal] = useState(false);
    const abortControllerRef = useRef(new AbortController());

    const MutedContent = () => {
        let comment = <Typography.Text italic>{flag.mute.comment}</Typography.Text>
        let tmstp = ValueFormatter.stringDate(flag.mute.timestamp)
        let description = <Typography.Text>The reason for deactivation was stated as: {comment} by {flag.mute.user} on {tmstp}.</Typography.Text>
        return <>
            <Alert
                message={`This check is muted for ${clientInfo.clientName} until ${flag.mute.until}. It will not take part in any reports, warnings raised will be ignored.`}
                type="warning" action={
                <Button size="small" className={'small-button'} type="link" danger={false}
                        onClick={() => setMuteModal(true)}>
                    Activate
                </Button>
            } description={description}/>
        </>
    }

    const ActiveContent = () => {
        return <Alert className={'silent-notice'}
                      message={`Current check is active for ${clientInfo.clientName}. It will take part in all wealth monitor reports.`}
                      action={
                          <Button size="small" className={'small-button'} type="link" danger={false}
                                  onClick={() => setMuteModal(true)}>
                              Mute flag
                          </Button>
                      }/>
    }

    const saveClientLimit = (values) => {
        let limitData = {
            client_id: clientInfo.clientId,
            flag_key: flag.red_flag_key,
        }
        if (values) {
            limitData.is_set = true
            limitData.comment = values.comment;
            limitData.value = values.limit_value;
        } else {
            limitData.is_set = false
        }
        setLimit(limitData, (resp, error) => {
            if (error) {
                message.error("Error while setting the limit!");
                return
            }
            message.success(`${flag.red_flag_name} check limit was updated successfully`);
            setLimitModal(false)
            updateData();
        }, abortControllerRef.current.signal)
    }

    const changeClientLimit = (reset = false) => {
        if (reset) {
            saveClientLimit(null)
        } else {
            setLimitModal(true)
        }
    }

    return (
        <Space direction={'vertical'} size={16} style={{width: '100%'}}>
            <Typography.Text className={'text'}>{flag.red_flag_description}</Typography.Text>
            {flag.mute.muted ?
                <MutedContent/> : <ActiveContent/>}
            <ClientMetricsResult flag={flag} updateLimit={() => changeClientLimit(false)}
                                 resetLimit={() => changeClientLimit(true)}/>
            <MetricsResultDetails details={flag.details} tips={flag.tips}/>
            <LimitModal
                modalOpen={limitModal}
                setModalOpen={setLimitModal}
                clientName={clientInfo.clientName}
                limitValue={flag.limit.client}
                flagName={flag.red_flag_name}
                precision={flag.metric.decimals_precision}
                limitDetails={configsDetails[flag.red_flag_key]}
                save={(values) => {
                    saveClientLimit(values)
                }}
            />

            <MuteModal
                modalOpen={muteModal}
                setModalOpen={setMuteModal}
                clientId={clientInfo.clientId}
                clientName={clientInfo.clientMame}
                mute={!flag.mute.muted}
                flagKey={flag.red_flag_key}
                flagName={flag.red_flag_name}
                finishAction={() => {
                    updateData();
                }}
            />
        </Space>
    )
}

export default ClientRedFlagContainer
