import React from "react";
import {Breadcrumb} from "antd";
import {Link, useLocation} from "react-router-dom";

const AdminNavigation = () => {
    const location = useLocation();
    // /spas/administration
    const baseRoute = location.pathname.split("/").splice(1);
    const adminBase = `/${baseRoute[0]}/${baseRoute[1]}`

    const returnCrumbs = () => {
        let url = adminBase
        let crumbs = []
        for (let routePart of baseRoute.slice(2)) {
            crumbs.push(<Breadcrumb.Item key={routePart}>
                <Link to={`${url}/${routePart}`}>
                    {routePart.charAt(0).toUpperCase() + routePart.slice(1)}
                </Link>
            </Breadcrumb.Item>)
            url += `/${routePart}`
        }
        return crumbs
    }

    return (
        <Breadcrumb style={{marginBottom: 20}}>
            <Breadcrumb.Item>
                <Link to={adminBase}>Administration</Link>
            </Breadcrumb.Item>
            {returnCrumbs()}
        </Breadcrumb>
    )
}

export default AdminNavigation
