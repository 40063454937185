import axiosInstance from "./APIClient";

let cancel;

const clientPortfolioFromOrion = (client_id, orion_id, type, callback) => {
    const url = `/client/${client_id}/portfolio/orion/${orion_id}`

    const data = {
        type: type
    }

    axiosInstance.post(url, data)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const clientPortfolioClone = (client_id, definition_id, type, expand_portfolio_positions = false, market_value = null, callback) => {
    let url = `/client/${client_id}/portfolio/clone/${definition_id}`

    //no proxy for now
    let data = {
        type: type,
        expand_portfolio_positions: expand_portfolio_positions
    }
    console.log(market_value)
    if (market_value) {
        data['market_value'] = market_value
    }
    axiosInstance.post(url, data)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

//changing several portfolios market values
const clientProposalsReallocate = (client_id, new_market_values = {}, callback) => {
    let url = `/client/${client_id}/portfolio/reallocate`

    //no proxy for now
    let data = {
        p_mvs: new_market_values
    }
    axiosInstance.post(url, data)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            console.error(response)
            callback(response, true);
        })
}

const deletePortfolio = (client_id, type, definition_id, callback) => {
    let url = `/client/${client_id}/portfolio/${type}/${definition_id}`;
    axiosInstance.delete(url)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createFromFile = (client_id, formData, callback) => {
    let url = `/client/${client_id}/portfolio/from_file`

    axiosInstance.post(url, formData, {
        headers: {
            'accept': 'application/json',
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createEmptyPortfolio = (client_id, postData, callback) => {
    let url = `/client/${client_id}/portfolio/empty`

    axiosInstance.post(url, postData)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createFromPositions = (client_id, postData, callback) => {
    let url = `/client/${client_id}/portfolio/from_positions`

    axiosInstance.post(url, postData)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

export {
    clientPortfolioClone,
    clientProposalsReallocate,
    clientPortfolioFromOrion,
    deletePortfolio,
    createFromFile,
    createEmptyPortfolio,
    createFromPositions,
    cancel
}
