import React, {useEffect, useState} from "react";
import {Badge, List, Space, Typography} from "antd";
import {WarningOutlined} from "@ant-design/icons";

import "./SupervisionClientReport.less";

const getTipString = (tip) => {
    let type = tip['type']
    let content = tip['content']
    if (type == 'text') {
        return content
    }
    console.log(tip)

    let recommentationText = content['text']

    let details = ""
    if (content['positions']) {
        details = " "
        for (let position of content['positions']) {
            details += position['ticker'] + ", "
        }
        details = details.slice(0, -2)
    }

    if (content['models']) {
        details = " "
        for (let model of content['models']) {
            details += model['name'] + ", "
        }
        details = details.slice(0, -2)
    }

    if (content['accounts']) {
        details = " "
        for (let account of content['accounts']) {
            details += account['account_name'] + ", "
        }
        details = details.slice(0, -2)
    }
    return recommentationText + details

}
const EmptyWarning = () => {
    return <Space direction={'horizontal'}>
        <WarningOutlined/> Not set
    </Space>
}

const ClientRecommendations = ({client}) => {
    const [tips, setTips] = useState([])
    useEffect(() => {
        let premTips = []
        for (let red_flag of client['red_flags']) {
            if (red_flag['tips'] != null) {
                for (let tip of red_flag['tips']) {
                    premTips.push(getTipString(tip))
                }
            }
        }
        console.log(premTips)
        setTips(premTips)
    }, [client])
    return <div style={{marginBottom: 12}}>
        <List
            header={<Typography.Title level={4} className={'spas'}>
                Recommendations
            </Typography.Title>}
            dataSource={tips}
            renderItem={(item => {
                return <List.Item>
                    <Badge color={'red'} text={item}/>
                </List.Item>
            })}/>
    </div>

}

export {ClientRecommendations, getTipString}
