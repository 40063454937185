/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Col, Input, InputNumber, Modal, Row, Typography} from 'antd';

import './PortfolioWeighter.less'
import StaticTypifiedPortfolioList from "@components/portfolio/list/StaticTypifiedPortfolioList";
import PortfolioList from "@components/portfolio/list/PortfolioList";
import ValueFormatter from "@global/ValueFormatter";

const PortfolioWeighter = ({
                               visible = false,
                               newPortfolio = null,
                               existingPortfolios = [],
                               availablePortfolios = [],
                               clientTotal = 100000,
                               doneCallback
                           }) => {

    const [pWeights, setPWeights] = useState({})
    const [pValues, setPValues] = useState({})
    const [changed, setChanged] = useState(false)

    const done = (save) => {
        console.log('done')
        //converting to market values
        let pValues = {}
        for (let p_id of Object.keys(pWeights)) {
            pValues[p_id] = ValueFormatter.round(pWeights[p_id]/100 * clientTotal, 1)
        }
        doneCallback((save && changed), pValues)
    }

    useEffect(() => {
        console.log(existingPortfolios)
        let wProxy = {}
        for (let ep of existingPortfolios) {
            wProxy[ep._id] = ValueFormatter.round(ep.weight*100, 1)
        }
        setPWeights(wProxy)
    }, [existingPortfolios, visible])

    const fieldChanged = (field_type, portfolio_id, value) => {
        setPWeights({...pWeights, [portfolio_id]: value})
        setChanged(true)
    }

    return (
        <Modal

            title={<Typography.Title style={{marginBottom: 0, paddingBottom: 0}} className={'spas'} level={3}>
                Value allocation
            </Typography.Title>}
            open={visible}
            width={850}
            closable={false}
            maskClosable={true}
            onCancel={() => done(false)}
            onOk={() => done(true)}
        >
            <div>
                <Typography.Title level={4}>Please distribute client's {ValueFormatter.compactLongCurrency(clientTotal)} between your proposals</Typography.Title>
                {existingPortfolios.map(p => {
                    return <Row key={p._id}>
                        <Col span={16}>
                            <Typography.Title level={4} className={'spas'} style={{marginTop: 0}}>{p.name}</Typography.Title>
                        </Col>
                        <Col span={4}>
                            <InputNumber
                                step={0.1}
                                min={1}
                                placeholder="weight" name={p._id}
                                value={pWeights[p._id]}
                                addonAfter={"%"}
                                onChange={value => fieldChanged('weight', p._id, value)}
                        />
                        </Col>
                        <Col span={4} style={{textAlign: 'right'}}>
                            <Typography.Title level={4} style={{marginTop: 0}}>{ValueFormatter.compactCurrency(clientTotal * (pWeights[p._id]/100))}</Typography.Title>
                        </Col>
                    </Row>
                })}
            </div>
        </Modal>
    )
}

export default PortfolioWeighter;
