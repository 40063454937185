import React, {useEffect, useRef, useState} from "react";
import {message, Row, Skeleton, Spin, Table, Tag, Tooltip, Typography} from "antd";
import {getUserLogs, getUserStatistics} from "@API/logs";
import ChartStyles from "@global/ChartStyles";


const logsColumns = [
    {
        title: "Log time",
        dataIndex: "timestamp",
        render: timestamp => new Date(timestamp).toLocaleString("en-US"),
    },
    {
        title: "Message",
        dataIndex: "message"
    }
]

const actionStatsColumns = [
    {
        title: "Target",
        dataIndex: "type",
    }, {
        title: "Count",
        dataIndex: "value"
    }, {
        title: "Actions",
        dataIndex: "operations",
        render: (_, {operations}) => (
            <>
                {operations.sort((a, b) => a['title'].localeCompare(b['title'])).map((op) => {
                    let color = ChartStyles.get_action_op_color(op['title'])
                    return (
                        <Tooltip title={op['value']} key={op['title']}>
                            <Tag color={color} key={op['title']}>
                                {op['title']}
                            </Tag>
                        </Tooltip>
                    );
                })}
            </>
        ),
    },
]

const UserActivity = ({email}) => {
    const [loading, setLoading] = useState(false);
    const [userStatistics, setUserStatistics] = useState([]);
    const [actionStatistics, setActionStatistics] = useState([]);
    const [userLogs, setUserLogs] = useState(null);
    const abortControllerRef = useRef(new AbortController());

    const retrieveLogs = (skip) => {
        setLoading(true);
        getUserLogs(email, skip, (data, error) => {
            if (error) {
                message.error("Error while retrieving user logs");
                return
            }
            setUserLogs(data);
            setLoading(false);
        }, abortControllerRef.current.signal)
    }

    useEffect(() => {
        retrieveLogs(0);
        getUserStatistics(email, (data, error) => {
            if (error) {
                // message.error("Error when retrieve user statistics");
                return
            }
            setUserStatistics(data);
            let statsRows = []
            for (let statsType of Object.keys(data.grouped_actions)) {
                let total = 0
                let ops = []
                for (let op of Object.keys(data.grouped_actions[statsType])) {
                    total += data.grouped_actions[statsType][op]
                    ops.push({title: op, value: data.grouped_actions[statsType][op]})
                }
                statsRows.push({
                    type: statsType,
                    value: total,
                    operations: ops
                })
            }
            statsRows.sort((r1, r2) => {
                return r1['type'].localeCompare(r2['type'])
            })
            setActionStatistics(statsRows)
            console.log(data);
        }, abortControllerRef.current.signal)

        return () => abortControllerRef.current.abort()
    }, [])

    const loadMoreLogs = (page, pageSize) => {
        retrieveLogs((page - 1) * pageSize);
    }

    return (
        <div>
            <Typography.Title level={3}>{email} activity</Typography.Title>
            <Row style={{marginTop: "20px"}}>
                <Table
                    loading={loading} style={{width: "100%"}} columns={actionStatsColumns}
                    dataSource={actionStatistics} pagination={{
                    pageSize: 20
                }}
                />
            </Row>
            <Row style={{marginTop: "20px"}}>
                <Table
                    loading={loading} style={{width: "100%"}} columns={logsColumns} dataSource={userLogs}
                    pagination={{
                        pageSize: 10,
                        total: userStatistics.logs_cnt,
                        onChange: loadMoreLogs,
                        showSizeChanger: false
                    }}
                />
            </Row>
        </div>
    )
}

export default UserActivity
