/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Dropdown, Menu} from 'antd';
import {useAuth} from "@hooks/AuthHook";
import CloneModelModal from "@holistico/portfolio/new/CloneModelModal";
import FromFileModal from "@holistico/portfolio/new/FromFileModal";
import FromOrionModal from "@holistico/portfolio/new/FromOrionModal";
import EmptyPortfolioModal from "@holistico/portfolio/new/EmptyPortfolioModal";

const getMenuItems = () => {
    const auth = useAuth()
    let items = [
        {
            label: 'Clone model',
            key: 'clone-model',
        },
        {
            label: 'From file',
            key: 'from-file',
        },
    ]

    if (auth.orionEnabled()) {
        items.push({
            label: 'From Orion',
            key: 'from-orion'
        })
    }

    items.push({
            label: 'Add empty portfolio',
            key: 'create-empty'
    })

    return items
}


const AddPortfolioButton = ({children, bucketType, disabled, ...props}) => {
    const [openModal, setOpenModal] = useState(null)

    const onMenuClick = (e) => {
        setOpenModal(e.key)
    }

    const onModalFinish = () => {
        setOpenModal(null)
    }

    return <>
        <Dropdown
            overlay={<Menu onClick={onMenuClick} items={getMenuItems()}/>}
            disabled={disabled}
            type={"primary"}
        >
            <Button {...props} type={"primary"} >
                 {children
                     ??
                     <>
                         <PlusOutlined/>
                         Add portfolio
                     </>
                 }
            </Button>
        </Dropdown>
        {openModal === "clone-model"
            && <CloneModelModal open={openModal === "clone-model"}
                                onFinish={onModalFinish}/>
        }
        {openModal === "from-file"
            && <FromFileModal bucketType={bucketType}
                              open={openModal === "from-file"}
                              onFinish={onModalFinish}/>
        }
        {openModal === "from-orion"
            && <FromOrionModal open={openModal === "from-orion"}
                               onFinish={onModalFinish}/>
        }
        {openModal === "create-empty"
            && <EmptyPortfolioModal open={openModal === "create-empty"}
                                    onFinish={onModalFinish}/>
        }
    </>
}

export default AddPortfolioButton;
