import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "@hooks/AuthHook";
import {Avatar, Button, Col, Layout, Row, Spin, Typography} from "antd";
import "./HolisticoFork.less";
import {useRequest, useUpdateEffect} from "ahooks";
import {getCustomMacro, recognition} from "@API/ahooks/dashboard";
import Constants from "@global/Constants";

const HolisticoFork = ({}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const auth = useAuth();

    const [role, setRole] = useState(null);

    const {
        data,
        loading,
        run
    } = useRequest(() => recognition(role), {
        manual: true
    })

    useEffect(() => {
        window.location.href = 'https://holisticoinvest.com'
    }, [])

    useUpdateEffect(() => {
        run()
    }, [role])

    useUpdateEffect(() => {
        if (role === 'advisor') {
            navigate("/" + Constants.RIA_URL)
        } else {
            navigate("/" + Constants.RETAIL_URL)
        }
    }, [data])


    return (
        <Spin spinning={true} fullscreen={true} size="large">
            <Layout style={{width: '100%', background: "#475a6b", minHeight: "100vh",}}>
                <div style={{textAlign: "center", background: "#ffffff", height: "40vh"}}>
                    <Typography.Title level={2} style={{textTransform: "uppercase", fontFamily: 'Red Hat Display W'}}>How
                        would you like to proceed?</Typography.Title>
                    <img
                        src={"/img/spin/spinner-atom.svg"}
                        style={{width: '200px', height: '200px'}}
                    />
                </div>
                <Row style={{width: '100%', height: "70vh"}}>
                    <Col span={12} className={"h-fork-dark"} onClick={() => {
                        setRole('retail')
                    }}>
                        <a href={"#"}>
                            <Typography.Title level={2} style={{color: "#ffffff"}}>Individual
                                Investor</Typography.Title>
                            <Typography.Text type="secondary" style={{color: "#ffffff"}}>personal
                                investments</Typography.Text>
                        </a>
                    </Col>
                    <Col span={12} className={"h-fork-white"} onClick={() => {
                        setRole('advisor')
                    }}>
                        <Typography.Title level={2}>Financial Advisor</Typography.Title>
                        <Typography.Text type="secondary">clients' accounts</Typography.Text>
                    </Col>
                </Row>
            </Layout>
        </Spin>
    );
}

export default HolisticoFork
