import React from "react";
import {Alert, Typography, Modal} from 'antd';


const BacktestLoadingModal = ({messages, clearMessages}) => {
    return (
        (<Modal
            title={
                <Typography.Title level={4} style={{marginTop: 0, marginBottom: 16}}>
                    Running calculations
                 </Typography.Title>
            }
            open={messages.length > 0}
            onCancel={clearMessages}
            confirmLoading={true}
            afterClose={clearMessages}
            width={550}
            closable={true}
            maskClosable={false}
            footer={null}
        >
            {messages.map(mes => {
                return <Alert
                    key={mes.key}
                    message={mes.text}
                    type={mes.status}
                    style={{marginBottom: 8}}
                />
            })}
        </Modal>)
    );
}

export default BacktestLoadingModal
