import React, {useContext, useState} from 'react';
import {message, Modal, Progress, Space, Typography, Upload} from 'antd';
import {CloudUploadOutlined, DownloadOutlined,} from "@ant-design/icons";
import {getExampleExcel} from "@API/portfolio";
import {downloadFile} from "@global/FileDownloader";
import ValueFormatter from "@global/ValueFormatter";
import {
    PortfolioListContext
} from "@components/portfolio/contexts/PortfolioListContext";


// eslint-disable-next-line react/prop-types
const FromFileModal = ({bucketType, onFinish, open=false}) => {
    const {addFromFile} = useContext(PortfolioListContext);
    const [inProgress, setInProgress] = useState(false);
    const [loadPercent, setLoadPercent] = useState([]);

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: !inProgress,
        title: null,
        // title: "Adding portfolio from file",
        footer: null,
        onCancel: v => onFinish(),
        style: {background: "none"},
    }

    const uploadProps = {
        showUploadList: false,
        multiple: false,
        disabled: inProgress,
        customRequest: async ({file, onProgress, onError, onSuccess}) => {
            console.debug('Custom request called')
            let progressInterval = progressIntervalCaller(onProgress);
            setLoadPercent(0)
            setInProgress(true);

            let uploadData = new FormData();
            uploadData.append("file_content", file);
            uploadData.append("file_name", file.name);
            uploadData.append("file_type", file.type);
            uploadData.append("portfolio_name", file.name);
            uploadData.append("portfolio_type", bucketType);

            addFromFile(uploadData, (result, error) => {
                if (error) {
                    console.error(result)
                    onError()
                } else {
                    onSuccess()
                }
                setInProgress(false);
                onFinish();
                clearInterval(progressInterval);
            })
        },
    };

    const progressIntervalCaller = (onProgress) => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            onProgress({percent: progress});
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    const exampleExcelDownload = () => {
        console.log("Download example excel")
        getExampleExcel((response, error) => {
            if (error) {
                message.error("Error while downloading example");
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Portfolio details example.xlsx`;
            downloadFile(response.data, file_name, file_type, () => {})
        })
    }

    return <>
        <Modal {...modalProps} open={open} className={"holistico"}>
            {inProgress
                ?
                <>
                    <Typography.Text>Adding portfolio from file...</Typography.Text>
                    <Progress percent={loadPercent} status="active" strokeColor='#E0AD60'/>
                </>
                :
                <Space direction="vertical" style={{width: "100%"}}>
                    <a href="#"
                       onClick={() => exampleExcelDownload()}
                       className="secondary-link"
                    >
                        <DownloadOutlined/> File example
                    </a>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <CloudUploadOutlined/>
                        </p>
                        <p className="ant-upload-text">
                            Upload xls, client pdf report or image files containing portfolio details.
                        </p>
                        <p className="ant-upload-hint">
                            Click to find on your storage or Drag to upload
                        </p>
                    </Upload.Dragger>
                </Space>
            }
        </Modal>
    </>
}

export default FromFileModal;
