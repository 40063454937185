import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const searchUsers = (search, handler) => {
    let url = `/users`

    axiosInstance.get(url,
        {
            params: {search_query: search},
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response}) => {
            handler(null, true);
        })
};

const inviteUserToOrg = (inviteeEmail, handler) => {
    let url = `/users/organization/invite`

    axiosInstance.post(url, {email: inviteeEmail}).then(({data}) => {
        console.info(data)
        handler(data);
    })
        .catch(({response}) => {
            console.log(response)
            handler(response, true);
        })
}

const inviteAssociateAdvisor = (inviteeEmail, handler) => {
    let url = `/users/organization/invite_associate`

    axiosInstance.post(url, {email: inviteeEmail}).then(({data}) => {
        console.info(data)
        handler(data);
    })
        .catch(({response}) => {
            console.log(response)
            handler(response, true);
        })
}

const createUser = (newUser, handler) => {
    let url = `/users`

    axiosInstance.post(url, newUser).then(({data}) => {
        console.info(data)
        handler(data);
    })
        .catch(({response}) => {
            console.log(response)
            handler(response.message, true);
        })
};

const getUser = (id, handler) => {
    let url = `/users/${id}`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response}) => {
            handler(null, true);
        })
};

const getUserAsync = async (id) => {
    let url = `/users/${id}`
    return await axiosInstance.get(url)
}

const getOrgAsync = async (id) => {
    let url = `/users/organization/${id}`
    return await axiosInstance.get(url)
}

const editUser = (editedUser, handler) => {
    let url = `/users`

    axiosInstance.put(url, editedUser).then((resp) => {
        handler();
    })
        .catch((error) => {
            console.log(error)
            handler(error?.response?.message, true);
        })
};


const editOrganization = (id, orgData, handler) => {
    let url = `/users/organization/${id}`
    axiosInstance.put(url, orgData).then((resp) => {
        handler();
    })
        .catch((error) => {
            console.log(error)
            handler(error?.response?.message, true);
        })
};

const changePassword = (id, newUserPassword, handler) => {
    let url = `/users/password/${id}`

    axiosInstance.put(url, newUserPassword).then(({data}) => {
        handler(data);
    })
        .catch(({response}) => {
            console.log(response)
            handler(response?.message, true);
        })
};

const deleteUser = (id, handler) => {
    let url = `/users/${id}`

    axiosInstance.delete(url).then(() => {
        handler();
    })
        .catch(({response}) => {
            console.log(response)
            handler(response?.message, true);
        })
};

export {
    searchUsers,
    createUser,
    getUser,
    inviteUserToOrg,
    inviteAssociateAdvisor,
    getUserAsync,
    getOrgAsync,
    editUser,
    editOrganization,
    changePassword,
    deleteUser,
    cancel
}
