import axiosInstance from "@API/APIClient";

const reactToNotification = async (id, agree, handler) => {
    let url = `/users/notification/${id}`
    return await axiosInstance.put(url, {agree: agree})
}

export {
    reactToNotification
}
