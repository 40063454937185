import {Button, Form, Input, message, Modal, Spin, Typography} from "antd";
import React, {useState} from "react";
import {useToggle} from "ahooks";
import {inviteAssociateAdvisor} from "@API/users";
import {ShareAltOutlined} from "@ant-design/icons";


const SendToAdvisor = ({}) => {
    const [form] = Form.useForm();
    const [state, {toggle, setLeft, setRight}] = useToggle();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);
        inviteAssociateAdvisor(values.email, (resp, error) => {
            setLoading(false);
            if (error) {
                console.error(resp);
                return
            }
            message.success("If the user exists in the system, an invitation will be sent.");
            form.resetFields();
            toggle()
        })
    }

    return (
        <>
            <Button type={"link"} onClick={toggle} icon={<ShareAltOutlined/>} style={{padding: 0}}>
                Share with advisor
            </Button>
            <Modal
                open={state}
                title="Share with advisor"
                onOk={form.submit}
                onCancel={() => {
                    form.resetFields();
                    toggle()
                }}
                okText="Share"
            >
                <Spin size={"large"} spinning={loading} tip="Invitation in progress...">
                    <Typography.Paragraph>
                        The financial advisor will be able to view your accounts,
                        risk profile and all portfolio analytics.
                        Based on this, the advisor can give you expert advice
                        and help you reach your financial goals.
                    </Typography.Paragraph>
                    <Form form={form} name="send_to_advisor" onFinish={onFinish} autoComplete="off" scrollToFirstError
                          layout="horizontal" initialValues={{'email': 'info@signetfm.com'}}>
                        <Form.Item
                            name="email"
                            label="Advisor Email"
                            requiredMark={false}
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email',
                                },
                                {
                                    required: true,
                                    message: 'Missing email',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default SendToAdvisor