import React, {useRef, useState, useEffect} from "react";
import {useSize} from "ahooks";
import {
    Card,
    Flex,
    Typography,
    theme,
    message,
    List,
    Divider,
    Button,
    Tooltip
} from "antd";
import {
    DownOutlined,
    ExclamationCircleFilled,
    ProfileFilled,
    StarFilled,
    UpOutlined
} from "@ant-design/icons";
import {getPositions} from "@API/portfolio";
import ValueFomatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import CardHeader from "@components/retail/cards/CardHeader";
import NestedCard from "@components/retail/cards/NestedCard";
import StatsInline from "@components/retail/cards/StatsInline";


const {useToken} = theme;


const RatingTag = ({value, sellLimit = 19, buyLimit = 71}) => {
    const {token} = useToken();

    let status = "hold"
    if (value <= sellLimit) {
        status = "sell";
    }
    if (value >= buyLimit) {
        status = "buy";
    }
    const color = {
        hold: token.colorLinkHover,
        buy: token.colorSuccess,
        sell: token.colorError
    }[status];
    const tooltip = {
        hold: "Moderate equity rating",
        buy: "High equity rating",
        sell: "Low equity rating",
    }[status]

    return <Tooltip title={tooltip}>
        <Flex
            gap={4}
            align={"center"}
            style={{
                textTransform: "capitalize",
                color: "white",
                background: color,
                borderRadius: token.borderRadius,
                padding: "0 8px 0 4px",
                fontSize: token.fontSizeSM - 2,
                height: (token.fontSizeSM - 2) * 1.5
            }}
        >
            <StarFilled color={"white"}/>
            {status}
        </Flex>
    </Tooltip>
}


const PositionsListItem = ({
                               asset,
                               containerSize,
                               background,
                               weightUpperLimit,
                               expenseRatioLimit
                           }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const vertical = containerSize?.width < 500;

    const descriptions = [asset.security_type];
    if (!is_null(asset.div_yield) && ["Stock", "ADR"].includes(asset.security_type)) {
        descriptions.push("Dividend yield: " + ValueFomatter.percent(asset.div_yield / 100));
    }
    if (!is_null(asset.div_yield) && !["Stock", "ADR"].includes(asset.security_type)) {
        descriptions.push("Distribution rate: " + ValueFomatter.percent(asset.div_yield / 100));
    }
    if (!is_null(asset.expense_ratio)) {
        descriptions.push("Expense ratio: " + ValueFomatter.percent(asset.expense_ratio / 100));
    }

    const alerts = [];
    if (asset.weight * 100 > weightUpperLimit && ["ADR", "Stock", "Bond"].includes(asset.security_type)) {
        alerts.push("Too large position");
    }
    if (asset.weight < 0) {
        alerts.push("Short position");
    }
    if (asset.short_fund) {
        alerts.push("Short/inverse fund");
    }
    if (asset.leveraged_fund) {
        alerts.push("Leveraged fund");
    }
    if (asset.expense_ratio > expenseRatioLimit) {
        alerts.push("High expense ratio");
    }

    return <Flex
        vertical={vertical}
        style={{
            padding: 16,
            borderBottom: "1px solid rgb(230, 230, 230)",
            background: hover ? token.colorBgHoverBlue : background ?? "none",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Flex gap={"large"} align={"baseline"} style={{minWidth: 80}}>
            <Typography.Text strong>
                {asset.ticker ?? asset.cusip ?? "-"}
            </Typography.Text>
            {vertical && asset.sscore_plus
                && <RatingTag
                    value={asset.sscore_plus}
                    sellLimit={["Stock", "ADR"].includes(asset.security_type) ? 19 : 39}
                    buyLimit={["Stock", "ADR"].includes(asset.security_type) ? 71 : 56}
                />
            }
        </Flex>
        <Flex gap={"large"} style={{flexGrow: 1, overflow: "hidden",}}>
            <Flex vertical gap={4} style={{
                overflow: "hidden",
                flexGrow: 1
            }}>
                <Flex gap={"large"} align={"center"}>
                    <Typography.Text ellipsis={true}>
                        {asset.name}
                    </Typography.Text>
                </Flex>
                <Flex
                    align={"center"}
                    wrap={"wrap"}
                    style={{color: token.colorTextDescription, fontSize: token.fontSizeSM, lineHeight: 1.2}}
                >
                    {descriptions.map((item, index) => {
                        return <span key={index}>
                            <span>
                                {item}
                            </span>
                            {index < descriptions.length - 1
                                && <Divider type={"vertical"} style={{margin: "0 16px"}}/>
                            }
                        </span>
                    })}
                </Flex>
                <Flex wrap={"wrap"} style={{lineHeight: 1.2, marginTop: 4}}>
                    {alerts.map((alert, index) => {
                        return <Flex
                            key={index}
                            gap={"small"}
                            style={{color: token.colorError, fontSize: token.fontSizeSM, marginLeft: index > 0 ? 32 : 0}}
                        >
                            <ExclamationCircleFilled/>
                            {alert}
                        </Flex>
                    })}
                </Flex>
            </Flex>
            <Flex vertical gap={4} align={"flex-end"}>
                <Flex gap={50} align={"center"}>
                    <Typography.Text strong style={{whiteSpace: "nowrap"}}>
                        {ValueFomatter.percent(asset.weight)}
                    </Typography.Text>
                </Flex>
                <Flex
                    align={"center"}
                    style={{color: token.colorTextDescription, fontSize: token.fontSizeSM, whiteSpace: "nowrap", lineHeight: 1.2}}
                >
                    {ValueFomatter.currency(asset.market_value)}
                </Flex>
            </Flex>
            {!vertical
                && <Flex justify={"flex-end"} style={{minWidth: 80, paddingTop: 2}}>
                    {asset.sscore_plus
                        ? <RatingTag
                            value={asset.sscore_plus}
                            sellLimit={["Stock", "ADR"].includes(asset.security_type) ? 19 : 39}
                            buyLimit={["Stock", "ADR"].includes(asset.security_type) ? 71 : 56}
                        />
                        : " "
                    }
                </Flex>
            }
        </Flex>
    </Flex>
}


const PositionsCard = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [positions, setPositions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const cp = proposal?.p_bucket_cur?.calculated_values?.check_points;
    const weightUpperLimit = cp?.large_positions?.metric?.limit;
    const expenseRatioLimit = cp?.large_positions?.metric?.limit;
    const nStocks = (positions ?? []).filter(a => ["Stock", "ADR"].includes(a.security_type)).length;
    const nBonds = (positions ?? []).filter(a => ["Bond"].includes(a.security_type)).length;
    const nFunds = (positions ?? []).filter(a => ["ETF", "MF", "MM"].includes(a.security_type)).length;

    useEffect(() => {
        const ids = Object.keys(proposal?.p_bucket_cur?.portfolios);
        if (ids) {
            setLoading(true);
            const columns = ["global_category", "asset_class_alloc", "sscore_plus", "div_yield", "expense_ratio", "leveraged_fund", "short_fund"];
            getPositions(ids, columns, (result, error) => {
                if (!error) {
                    const resultSorted = (result ?? []).sort(function (a, b) {
                        return  (b.security_type === "Cash") - (a.security_type === "Cash") || b.weight - a.weight;
                    });
                    setPositions(resultSorted);
                } else {
                    message.error('Something went wrong while loading positions data!');
                }
                setLoading(false);
            })
        }
    }, [proposal])

    return <Card hoverable ref={ref} style={{
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)",
        cursor: "default"
    }}>
        <NestedCard background={"white"}>
            <CardHeader title="Positions" icon={<ProfileFilled/>}/>
            <Flex gap={"large"}>
                {nStocks ? <StatsInline title={nStocks === 1 ? "stock" : "stocks"} value={nStocks}/> : null}
                {nBonds ? <StatsInline title={nBonds === 1 ? "bond" : "bonds"} value={nBonds}/> : null}
                {nFunds ? <StatsInline title={nFunds === 1 ? "fund" : "funds"} value={nFunds}/> : null}
            </Flex>
        </NestedCard>
        <List
            loading={loading}
            dataSource={expanded ? (positions ?? []) : (positions ?? []).filter((_, i) => i < 5)}
            renderItem={(asset, index) => {
                return <PositionsListItem
                    key={index}
                    asset={asset}
                    background={index % 2 === 1 ? "white" : token.colorBgGrey}
                    containerSize={size}
                    weightUpperLimit={weightUpperLimit}
                    expenseRatioLimit={expenseRatioLimit}
                />
            }}
        />
        {positions && positions.length > 5
            &&
            <Button
                onClick={() => setExpanded(prev => !prev)}
                type={"link"}
                size={"small"}
                icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                style={{paddingLeft: 0, marginTop: token.paddingLG}}
            >
                {expanded ? "Show less" : "Show all"}
            </Button>
        }
    </Card>

}


export default PositionsCard;
