import {Typography} from "antd";
import {MailOutlined, AudioMutedOutlined, ExperimentOutlined, UserAddOutlined,
    SettingOutlined, CopyOutlined, DeleteOutlined} from "@ant-design/icons";
import React from "react";


const items = [
    {
        key: '1',
        href: '#tutorial-general-description',
        title: 'General description',
        text: <>
            <Typography.Paragraph>
                The Compliance Master app helps advisors maintain client
                portfolios to meet company and industry standards.
                In particular, it helps to reveal potential problems
                such as high risk, poor diversification, risky positions,
                etc. and deal with them.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Every day a number of checks are carried out for each
                client to identify problems. If a client does not pass
                a check, a warning is raised. You can find all
                the warnings on the “Advisors” or “Checks” tabs. If you
                want to see the warnings for a particular client, you
                can go to the “Client” tab.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Each check is based on the corresponding metric.
                For example, “High risk” check is based on the portfolio
                risk score. A warning is raised if metric value goes
                beyond the allowed limit (e.g., risk score is too high).
            </Typography.Paragraph>
            <Typography.Paragraph>
                The limits are configurable. We have 3 configurations:
                Soft, Moderate, and Strict. You can choose one of them
                when running reports. Besides that, you can set custom
                limits for a particular client. This may be useful when
                the limit suggested by the app does not fit the client
                (e.g., the client agrees to a high risk portfolio to get
                high potential return).
            </Typography.Paragraph>
            <Typography.Paragraph>
                You can set custom limits on the “Client” tab. You can
                also mute certain checks for a client or the whole
                client. In this case, warnings for this client will be
                hidden in the reports. You can find a summary on the
                custom limits and mutes on the “Custom limits” tab.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Notice that custom limits and mutes will be taken into
                account in the reports only if the “Use clients' limits"
                option is selected.
            </Typography.Paragraph>
        </>
    },
    {
        key: '2',
        href: '#tutorial-tabs',
        title: 'Tabs',
        children: [
            {
                key: '2-1',
                href: '#tutorial-tabs-summary',
                title: 'Summary',
                text: <>
                    <Typography.Paragraph>
                        This tab contains aggregated information on the
                        warnings and some useful information on the clients’
                        wealth distribution.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '2-2',
                href: '#tutorial-tabs-representatives',
                title: 'Representatives',
                text: <>
                    <Typography.Paragraph>
                        This tab contains a table with warnings count
                        aggregated by advisors.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top of the page, you can:
                        <ul>
                            <li>
                                choose limits configuration
                                (Soft, Moderate, or Strict)
                            </li>
                            <li>
                                hover <Typography.Text type="warning"> # </Typography.Text>
                                in the configuration dropdown to see
                                configuration details
                            </li>
                            <li>
                                click "Use clients' limits" to apply/ignore
                                client specific limits and mutes
                            </li>
                            <li>
                                click "Download report" to download the table
                                as an Excel file
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the report table, for each advisor, you can find:
                        <ul>
                            <li>
                                the advisor "wealth health" calculated as
                                the percentage of clients without warnings
                            </li>
                            <li>
                                the number of clients with warnings for each check
                            </li>
                        </ul>
                        The last row of the table contains the total number
                        of warnings for each check.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can expand an advisor row to see the clients.
                        Warnings are marked with red dots.
                        For each client, you can:
                        <ul>
                            <li>
                                click on the client to open client report
                                in another tab
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <AudioMutedOutlined/> </Typography.Text>
                                to fully mute the client
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <ExperimentOutlined/> </Typography.Text>
                                to open client portfolio in SPAS playground
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <UserAddOutlined/> </Typography.Text>
                                to create client proposal
                            </li>
                        </ul>
                    </Typography.Paragraph>
                </>
            },
            {
                key: '2-3',
                href: '#tutorial-tabs-checks',
                title: 'Checks',
                text: <>
                    <Typography.Paragraph>
                        This tab contains the report on the warnings count
                        grouped by checks.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top of the page, you can:
                        <ul>
                            <li>
                                choose an advisor (to see all available
                                advisors, leave this field empty)
                            </li>
                            <li>
                                choose limits configuration
                                (Soft, Moderate, or Strict)
                            </li>
                            <li>
                                hover <Typography.Text type="warning"> # </Typography.Text>
                                in the configuration dropdown to see
                                configuration details
                            </li>
                            <li>
                                click "Use clients' limits" to apply/ignore
                                client specific limits and mutes
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the report, for each check, the number of problem
                        clients and advisors are presented. In the check
                        description, you can find the condition when the
                        warning is raised and what metric is used for that.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Expand a check to see the advisors with warnings.
                        For each advisor, the number of problem clients is
                        presented.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Expand an advisor to see the clients. For each client,
                        you can find:
                        <ul>
                            <li>
                                total investments
                            </li>
                            <li>
                                age
                            </li>
                            <li>
                                metric value vs allowed limit
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can also:
                        <ul>
                            <li>
                                click on the client to open client report
                                in another tab
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <MailOutlined/> </Typography.Text>
                                to open a pre-filled email and send it to the
                                client's representative
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <AudioMutedOutlined/> </Typography.Text>
                                to mute the check for the client
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <ExperimentOutlined/> </Typography.Text>
                                to open client portfolio in SPAS playground
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <UserAddOutlined/> </Typography.Text>
                                to create client proposal
                            </li>
                        </ul>
                    </Typography.Paragraph>
                </>
            },
            {
                key: '2-4',
                href: '#tutorial-tabs-clients',
                title: 'Clients',
                text: <>
                    <Typography.Paragraph>
                        This tab contains a client warnings report.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top of the page, you can:
                        <ul>
                            <li>
                                search a client
                            </li>
                            <li>
                                choose limits configuration
                                (Soft, Moderate, or Strict)
                            </li>
                            <li>
                                hover <Typography.Text type="warning"> # </Typography.Text>
                                in the configuration dropdown to see
                                configuration details
                            </li>
                            <li>
                                click "Use clients' limits" to apply/ignore
                                client specific limits and mutes
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the client section, general information such as age,
                        representative, investments market value, etc. is
                        presented. Available proposals are at the bottom of the
                        section.
                        You can:
                        <ul>
                            <li>
                                click <Typography.Text type="warning"> <MailOutlined/> </Typography.Text>
                                to open a pre-filled email and send it to the
                                client's representative
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <AudioMutedOutlined/> </Typography.Text>
                                to mute the check for the client
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <ExperimentOutlined/> </Typography.Text>
                                to open client portfolio in SPAS playground
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <UserAddOutlined/> </Typography.Text>
                                to create client proposal
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the checks section, you can see what warnings were
                        raised. To see the details, expand a check.
                        The following information is presented in details:
                        <ul>
                            <li>
                                check description, where you can find the
                                condition when the warning is raised and
                                what metric is used for that
                            </li>
                            <li>
                                check metric value vs allowed limit
                            </li>
                            <li>
                                whether the check is muted or not and
                                the reason why it was muted
                            </li>
                            <li>
                                custom limit if available and the reason
                                why it was set
                            </li>
                            <li>
                                warning details
                                (portfolio positions, accounts, etc.)
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can:
                        <ul>
                            <li>
                                mute/unmute (activate) check
                            </li>
                            <li>
                                set/update/delete custom limit
                            </li>
                        </ul>
                    </Typography.Paragraph>
                </>
            },
            {
                key: '2-5',
                href: '#tutorial-tabs-custom-limits',
                title: 'Custom limits',
                text: <>
                    <Typography.Paragraph>
                        This tab contains the report on the clients for which
                        custom limits were set, or which were muted.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        At the top of the page, you can:
                        <ul>
                            <li>
                                choose limits configuration
                                (Soft, Moderate, or Strict)
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the report table, for each advisor, you can find:
                        <ul>
                            <li>
                                total number of checks muted
                            </li>
                            <li>
                                total number of checks with custom limits
                            </li>
                            <li>
                                number of clients with mutes or custom limits
                                for each check
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can expand an advisor row to see the clients.
                        Muted checks and checks with custom limits are marked
                        with corresponding icons. For each client, you can:
                        <ul>
                            <li>
                                click on the client to open client report
                                in another tab
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <AudioMutedOutlined/> </Typography.Text>
                                to mute the check for the client
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <ExperimentOutlined/> </Typography.Text>
                                to open client portfolio in SPAS playground
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <UserAddOutlined/> </Typography.Text>
                                to create client proposal
                            </li>
                        </ul>
                    </Typography.Paragraph>
                </>
            },
            {
                key: '2-6',
                href: '#tutorial-tabs-configurations',
                title: 'Configurations',
                text: <>
                    <Typography.Paragraph>
                        This tab contains all available limit configurations.
                        Default configuration is marked with the “Default” tag.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can:
                        <ul>
                            <li>
                                click “Create” to create new configuration
                            </li>
                            <li>
                                click on a configuration to use it by default
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <SettingOutlined/> </Typography.Text>
                                to update configuration
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <CopyOutlined/> </Typography.Text>
                                to make a copy of configuration
                            </li>
                            <li>
                                click <Typography.Text type="warning"> <DeleteOutlined/> </Typography.Text>
                                to delete configuration
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        In the “Update config” window, you can change
                        the configuration name and set the limits
                        for each check.
                    </Typography.Paragraph>
                </>
            },
        ],
    },
    {
        key: '3',
        href: '#tutorial-checks-description',
        title: 'Checks description',
        children: [
            {
                key: '3-1',
                href: '#tutorial-checks-description-high-portfolio-risk',
                title: 'High portfolio risk',
                text: <>
                    <Typography.Paragraph>
                        High portfolio risk may indicate that the portfolio
                        does not meet the client's goals. This warning is
                        raised when the portfolio risk score exceeds the
                        allowed limit. By default, the limit is calculated
                        solely on the client's age. If you need to adjust
                        the limit for specific investment goals, you may do
                        this on the client tab.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-2',
                href: '#tutorial-checks-description-large-positions',
                title: 'Large positions',
                text: <>
                    <Typography.Paragraph>
                        Large positions may indicate that the portfolio
                        is poorly diversified and its performance highly
                        depends on one (or few) assets. This warning is
                        raised when the weight of at least one individual
                        stock or bond exceeds the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-3',
                href: '#tutorial-checks-description-small-positions',
                title: 'Small positions',
                text: <>
                    <Typography.Paragraph>
                        Small positions may indicate that the portfolio has not
                        been rebalanced for a long time or there are some
                        unwanted positions after corporate actions. This
                        warning is raised when the weight of at least one
                        non-cash position falls below the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-4',
                href: '#tutorial-checks-description-high-equity-weight',
                title: 'High equity weight',
                text: <>
                    <Typography.Paragraph>
                        High equity weight makes portfolio more risky which may
                        not be desirable for aged clients. Equity weight above
                        100% may indicate leveraged positions. This warning is
                        raised when the portfolio equity weight exceeds the
                        allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-5',
                href: '#tutorial-checks-description-high-cash-weight',
                title: 'High cash weight',
                text: <>
                    <Typography.Paragraph>
                        High cash weight may indicate short positions,
                        inefficient portfolio composition, and may lead to the
                        loss of the risk-free income. This warning is raised
                        when the portfolio cash weight exceeds the allowed
                        limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-6',
                href: '#tutorial-checks-description-negative-cash-weight',
                title: 'Negative cash weight',
                text: <>
                    <Typography.Paragraph>
                        Negative cash may indicate leveraged positions. This
                        warning is raised when the portfolio cash weight falls
                        below the allowed limit (e.g., zero).
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-7',
                href: '#tutorial-checks-description-short-positions',
                title: 'Short positions',
                text: <>
                    <Typography.Paragraph>
                        Short positions increase portfolio risk. In the case
                        when the price of a short position rises, losses are
                        not bound. This warning is raised when the total
                        absolute weight of short positions exceeds the allowed
                        limit. Notice that short/inverse funds with positive
                        weight do not count (see "Short/inverse funds" to
                        detect them). Alternative assets, money market funds,
                        and cash with negative weight do not count as well.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-8',
                href: '#tutorial-checks-description-short-inverse-funds',
                title: 'Short/inverse funds',
                text: <>
                    <Typography.Paragraph>
                        Short/inverse funds may increase portfolio risk.
                        In the case when the price of a short position rises,
                        losses are not bound. This warning is raised when the
                        total absolute weight of short/inverse funds exceeds
                        the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-9',
                href: '#tutorial-checks-description-leveraged-funds',
                title: 'Leveraged funds',
                text: <>
                    <Typography.Paragraph>
                        Leveraged funds may increase portfolio risk.
                        Furthermore, leveraged funds are frequently rebalanced,
                        which comes with additional costs. This warning is
                        raised when the total absolute weight of leveraged
                        funds exceeds the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-10',
                href: '#tutorial-checks-description-high-expense-ratio-funds',
                title: 'High expense ratio funds',
                text: <>
                    <Typography.Paragraph>
                        High expense ratio funds are associated with additional
                        costs for the client. This warning is raised when
                        the expense ratio at least for one fund exceeds
                        the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-11',
                href: '#tutorial-checks-description-forgotten-client',
                title: 'Forgotten client',
                text: <>
                    <Typography.Paragraph>
                        Forgotten client warning indicates that the client's
                        portfolio has not been rebalanced for a long time.
                        This warning is raised when the number of
                        no-transaction days exceeds the allowed limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-12',
                href: '#tutorial-checks-description-high-trading-frequency',
                title: 'High trading frequency',
                text: <>
                    <Typography.Paragraph>
                        High trading frequency may indicate that the client
                        (or advisor) does not adhere to the long-term
                        investment framework or is subject to behavioral
                        biases. This warning is raised when the number of
                        trading days during the last quarter exceeds the
                        allowed limit. Notice that options transactions and
                        dividends reinvestment are ignored when calculating
                        trading days.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-13',
                href: '#tutorial-checks-description-billing-on-not-managed-accounts',
                title: 'Billing on not managed accounts',
                text: <>
                    <Typography.Paragraph>
                        Billing on not managed accounts indicates that there
                        are accounts with non-zero fee and without model
                        portfolio set. Usually, if the model portfolio is not
                        set, it means that the account is not actively managed
                        and its fee should be zero. This warning is raised when
                        the number of accounts with non-zero fee and without
                        model portfolio set exceeds the allowed limit
                        (e.g., zero).
                    </Typography.Paragraph>
                </>
            },
            {
                key: '3-14',
                href: '#tutorial-checks-description-bad-sector-diversification',
                title: 'Bad sector diversification',
                text: <>
                    <Typography.Paragraph>
                        Bad sector diversification indicate that
                        the portfolio equity part is poorly diversified and
                        its performance highly depends on one (or few) equity sectors.
                        This warning is raised when the deviation from the benchmark
                        sector weight (Russell 3000), at least for one sector,
                        exceeds the allowed limit. Notice that the warning
                        is not raised if equity weight is low (less than 30%).
                    </Typography.Paragraph>
                </>
            },
        ],
    },
    {
        key: '4',
        href: '#tutorial-faq',
        title: 'FAQ',
        children: [
            {
                key: '4-1',
                href: '#tutorial-faq-1',
                title: 'What "Use clients\' limits" stands for?',
                text: <>
                    <Typography.Paragraph>
                        Advisors can set custom limits for particular clients
                        or mute them. To account for these custom limits and
                        mutes, “Use clients’ limits” option should be selected.
                        Otherwise custom limits and mutes will be ignored.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-2',
                href: '#tutorial-faq-2',
                title: 'How is the limit for "High portfolio risk" calculated?',
                text: <>
                    <Typography.Paragraph>
                        The limit for “High portfolio risk” depends on the
                        client’s age and chosen configuration. The dependence
                        is following:
                        <ul>
                            <li>
                                Soft configuration: limit =  100 - 0.3 * age
                            </li>
                            <li>
                                Moderate configuration: limit =  90 - 0.3 * age
                            </li>
                            <li>
                                Strict configuration: limit =  85 - 0.3 * age
                            </li>
                        </ul>
                        If the client's age is not available, the default
                        value of 40 is used for age.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If the limit suggested by the app does not fit a
                        client, you can set a custom limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-3',
                href: '#tutorial-faq-3',
                title: 'How is the limit for "High equity weight" calculated?',
                text: <>
                    <Typography.Paragraph>
                        The limit for “High equity weight” depends on the
                        client’s age and chosen configuration. The dependence
                        is following:
                        <ul>
                            <li>
                                Soft configuration
                                <ul>
                                    <li>younger 60: limit = 150%</li>
                                    <li>aged 60 to 79: limit = 110%</li>
                                    <li>older 79: limit = 90%</li>
                                </ul>
                            </li>
                            <li>
                                Moderate configuration
                                <ul>
                                    <li>younger 60: limit = 110%</li>
                                    <li>aged 60 to 79: limit = 90%</li>
                                    <li>older 79: limit = 70%</li>
                                </ul>
                            </li>
                            <li>
                                Strict configuration
                                <ul>
                                    <li>younger 60: limit = 90%</li>
                                    <li>aged 60 to 79: limit = 70%</li>
                                    <li>older 79: limit = 50%</li>
                                </ul>
                            </li>
                        </ul>
                        If the client's age is not available, the default
                        value of 40 is used for age.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If the limit suggested by the app does not fit a
                        client, you can set a custom limit.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-4',
                href: '#tutorial-faq-4',
                title: 'How short/inverse funds are identified?',
                text: <>
                    <Typography.Paragraph>
                        A fund is considered as “short/inverse” if it is marked
                        as  inverse in the Morningstar Institutional Category.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-5',
                href: '#tutorial-faq-5',
                title: 'How leveraged funds are identified?',
                text: <>
                    <Typography.Paragraph>
                        A fund is considered as “leveraged” if it is marked as
                        leveraged in the Morningstar Institutional Category.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-6',
                href: '#tutorial-faq-6',
                title: 'How is cash weight calculated?',
                text: <>
                    <Typography.Paragraph>
                        Cash weight is calculated for the whole client
                        portfolio. Notice that cash in funds is also counted.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-7',
                href: '#tutorial-faq-7',
                title: 'How is equity weight calculated?',
                text: <>
                    <Typography.Paragraph>
                        Cash weight is calculated for the whole client
                        portfolio. Notice that equities share in funds is
                        also counted.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-8',
                href: '#tutorial-faq-8',
                title: 'How to view client portfolio positions?',
                text: <>
                    <Typography.Paragraph>
                        If you want to look at the client portfolio positions,
                        you can open the portfolio in SPAS playground
                        (click <Typography.Text type="warning"> <ExperimentOutlined/> </Typography.Text>
                        where available). Portfolio positions table will be at
                        the bottom of the page.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-9',
                href: '#tutorial-faq-9',
                title: 'How to view problem positions?',
                text: <>
                    <Typography.Paragraph>
                        For some checks, you can look at problem positions that
                        caused the warning. To do so, go to the client report
                        and expand the necessary check.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-10',
                href: '#tutorial-faq-10',
                title: 'How to view problem accounts?',
                text: <>
                    <Typography.Paragraph>
                        For some checks, you can look at problem accounts that
                        caused the warning. To do so, go to the client report
                        and expand the necessary check.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-11',
                href: '#tutorial-faq-11',
                title: 'How to see the limits used for reports?',
                text: <>
                    <Typography.Paragraph>
                        At the top of the page, hover <Typography.Text type="warning"> # </Typography.Text>
                        in the configuration dropdown to see the limits used.
                        Alternatively, if you have access to the
                        “Configurations” tab, you can go there and click
                        <Typography.Text type="warning"> <SettingOutlined/> </Typography.Text>
                        on the necessary configuration to see the limits.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-12',
                href: '#tutorial-faq-12',
                title: 'Why can\'t I see the “Configurations” tab?',
                text: <>
                    <Typography.Paragraph>
                        All the configurations are tuned to meet company and
                        industry standards. For safety, only users with admin
                        rights can view the “Configurations” tab and edit
                        configurations.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-13',
                href: '#tutorial-faq-13',
                title: 'Why can\'t I see other advisors?',
                text: <>
                    <Typography.Paragraph>
                        Only users with admin rights can see reports for all
                        advisors.
                    </Typography.Paragraph>
                </>
            },
            {
                key: '4-14',
                href: '#tutorial-faq-14',
                title: 'Can’t find the necessary information',
                text: <>
                    <Typography.Paragraph>
                        If you have questions not covered in this tutorial,
                        please contact the dev team at it-support@signetfm.com.
                    </Typography.Paragraph>
                </>
            },
        ],
    },
]

export
{
    items
}
