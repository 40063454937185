import React from "react";
import {Button, Drawer, FloatButton, theme, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import AiChat from "@holistico/ai/chat/AiChat";
import {LeftOutlined} from "@ant-design/icons";


const {useToken} = theme;


const AiChatPanel = ({aiExpand, setAiExpand}) => {
    const {proposal} = useHolisticoProposalContext();
    const portfolios = proposal?.current_portfolios;
    const {token} = useToken();

    return <>{portfolios && portfolios.length > 0
        &&
        <>
            <FloatButton
                onClick={() => setAiExpand(true)}
                icon={<div style={{fontWeight: 500, fontSize: 16}}>AI</div>}
                type={"primary"}
                tooltip={"Open AI chat"}
                badge={{dot: true}}
            />
            <Drawer
                title={null}
                open={aiExpand}
                onClose={() => setAiExpand(false)}
                closable={false}
                width={640}
                rootClassName={"holistico ai-sidebar retail"}
                style={{background: token.colorBgGreyDark}}
            >
                <Button
                    type={"link"}
                    onClick={() => setAiExpand(false)}
                    size={"large"}
                    icon={<LeftOutlined/>}
                    style={{
                        padding: 0,
                        marginBottom: token.padding,
                        marginLeft: 24
                    }}
                >
                    Back
                </Button>
                <Typography.Title level={4} style={{margin: "0 0 12px 24px"}}>
                    AI Chat
                </Typography.Title>
                <AiChat
                    autoMessage={false}
                    height={"calc(100vh - 215px)"}
                />
            </Drawer>
        </>
    }</>
}


export default AiChatPanel;
