/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Menu} from "antd";
import './ClientInfo.less';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import ClientOverview from "@components/client/ClientOverview";
import ClientQuestionnaire from "@components/client/ClientQuestionnaire";
import ClientStress from "@components/client/ClientStress";
import ClientRetirement from "@components/client/ClientRetirement";
import ClientPortfolios from "@components/client/ClientPortfolios";
import ClientAnalysis from "@components/client/ClientAnalysis";
import ClientReport from "@components/client/ClientReport";
import Questionnaire from "@components/questionnaire/Questionnaire";
import {getHiddenHref} from "@global/Utils";
import ClientAI from "@components/client/ClientAI";

const getMenuItems = (client) => {
    const Questionnaire = () => {
        if (client?.questionnaire?.risk) {
            return <span style={{color: 'green'}}>Questionnaire</span>
        } else {
            return <span style={{color: 'red'}}>Questionnaire</span>
        }
    }
    let items = [{
        key: "overview",
        label: "Overview",
    }, {
        key: "questionnaire",
        label: <Questionnaire/>
    }, {
        key: "current",
        label: "Portfolio"
    }, {
        key: "factor",
        label: "Analyzer"
    }, {
        key: "stress",
        label: "Stress"
    }, {
        key: "retirement",
        label: "Retirement"
    }, {
        key: "proposed",
        label: "Proposal"
    }, {
        key: "report",
        label: "Report"
    }, {
        key: "ai",
        label: "AI"
    }]

    for (let item of items) {
        item.label = getHiddenHref(item.label, item.key)
    }

    return items
}
const ClientRoutes = ({client}) => {
    const [selectedKeys, setSelectedKeys] = useState(['overview'])
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        let path = location.pathname.split("/").pop()
        setSelectedKeys([path])
    }, [location.pathname])

    const onClick = (e) => {
        console.log('client menu click ', e);
        navigate(e.key)
    };


    return (
        <div>
            <Menu
                style={{lineHeight: '30px'}}
                mode="horizontal"
                items={getMenuItems(client)}
                onClick={onClick}
                theme="dark"
                selectedKeys={selectedKeys}
                defaultSelectedKeys={['overview']}
            />
            <div style={{paddingTop: '30px'}}>
                <Routes>
                    <Route
                        path=""
                        element={<Navigate to="overview" replace/>}
                    />
                    <Route path={"overview"} element={<ClientOverview/>}/>
                    <Route path={"ai"} element={<ClientAI/>}/>
                    <Route path={"questionnaire"} element={<ClientQuestionnaire/>}/>
                    <Route path={"current"} element={<ClientPortfolios type="cur"/>}/>
                    <Route path={"factor"} element={<ClientAnalysis/>}/>
                    <Route path={"stress"} element={<ClientStress/>}/>
                    <Route path={"retirement"} element={<ClientRetirement/>}/>
                    <Route path={"proposed"} element={<ClientPortfolios type="prop"/>}/>
                    <Route path={"report"} element={<ClientReport/>}/>
                </Routes>
            </div>
        </div>
    )

}

export default ClientRoutes;
