import React from "react";
import {DatePicker, Form} from 'antd';


const DateInput = ({size='large', hidden=false}) => {
    return <Form.Item
        name={`dates_range`}
        rules={[{required: true}]}
        hidden={hidden}
    >
        <DatePicker.RangePicker
            picker="month"
            size={size}
            format={(value) => {
                return value.format("MMM YYYY")
            }}
            // style={{width: "100%"}}
        />
    </Form.Item>
}

export default DateInput
