import {Button, Divider, List, message, Spin, Typography} from "antd";
import {FormatPainterOutlined, PlusOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
// import {getProposals} from "@API/hproposal";
import {
    cancel as cancelGetProposals,
    createFromOrion,
    getClients as getProposals
} from "@API/clients";
import RecentProposalsListItem from "@holistico/proposal/recent/RecentProposalsListItem";
import OrionClientListItem
    from "@holistico/proposal/recent/OrionClientListItem";
import MainSearch from "@components/clients/MainSearch";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import "./RecentProposals.less"
import SimpleProgressModal from "@holistico/aux/SimpleProgressModal";
import RecentProposalCard from "@holistico/proposal/recent/RecentProposalCard";
import {useAuth} from "@hooks/AuthHook";


const RecentProposals = ({}) => {
    const [searchString, setSearchString] = useState("")
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [data, setData] = useState([]);
    const [blur, setBlur] = useState(
        localStorage.getItem("proposalsBlur") !== null
            ? localStorage.getItem("proposalsBlur") === "true"
            : false
    );
    const auth = useAuth();
    const location = useLocation()
    const navigate = useNavigate()

    const proposalsData = data?.filter(item => (!!item._id))
    const orionData = data?.filter(item => (!item._id))

    useEffect(() => {
            setLoading(true);
            getProposals(searchString, 0, 0, (result, error) => {
                    if (!error) {
                        setData(result);
                    }
                    setLoading(false);
                }
            );

            return () => {
                if (cancelGetProposals) {
                    cancelGetProposals();
                }
            }
        },
        [searchString, auth.proposalListChange]
    )

    const onCreateFromOrion = (external_id) => {
        setCreating(true);
        createFromOrion(external_id, (newClient, error) => {
            if (error) {
                message.error('Failed to load the client from Orion');
                setCreating(false);
            } else {
                console.log(newClient)
                message.success('Proposal was created successfully');
                setCreating(false);
                navigate({pathname: newClient._id, search: location.search})
            }
        })
    }

    return <>
        <SimpleProgressModal
            description={"Creating proposal..."}
            open={creating}
        />
        <div className={"recent-proposals"} style={{padding: "16px 28px"}}>
            <Typography.Title level={3}>
                Recent proposals
            </Typography.Title>

            <MainSearch
                fetchData={(value) => {
                    setSearchString(value)
                }}
                secureMode={blur}
                bordered={true}
                placeholder={"Type in the client name to start searching"}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        navigate({pathname: "new", search: location.search})
                    }}
                    size={"large"}
                >
                    New investor
                </Button>
                <Button
                    type={"text"}
                    icon={<FormatPainterOutlined/>}
                    onClick={() => {
                        localStorage.setItem("proposalsBlur", !blur);
                        setBlur(!blur);
                    }}
                    size={"large"}
                >
                    {blur ? "Remove blur" : "Blur titles"}
                </Button>
            </div>
            <Spin spinning={loading}>
                {proposalsData && proposalsData.length > 0
                    ?
                    <List
                        grid={{gutter: 12   , column: 4}}
                        dataSource={proposalsData}
                        renderItem={(item) => (
                            <List.Item>
                                <RecentProposalCard
                                    proposal={item}
                                    blur={blur}
                                    onEdit={() => {
                                        auth.setProposalListChange(prev => (prev + 1));
                                    }}
                                />
                            </List.Item>
                        )}
                        style={{marginTop: "32px"}}
                    />
                    :
                    <NoDataPlaceholder description={"No proposals found..."}/>
                }
                {orionData && orionData.length > 0
                    ?
                    <>
                        <Typography.Title
                            level={4}
                            style={{
                                margin: "48px 0 0 0",
                                fontWeight: 350,
                                color: "#a2a2a2"
                            }}
                        >
                            Found in Orion
                        </Typography.Title>
                        <List
                            dataSource={orionData}
                            renderItem={(item) => (
                                <OrionClientListItem
                                    client={item}
                                    blur={blur}
                                    onClick={onCreateFromOrion}
                                />
                            )}
                        />
                    </>

                    :
                    (searchString
                        && <>
                            <Divider/>
                            <NoDataPlaceholder description={"No Orion clients found..."}/>
                        </>
                    )
                }
            </Spin>
        </div>
    </>
}

export default RecentProposals
