import React, {useEffect, useRef, useState} from "react";
import {Form, message, Modal, Select, Spin, Typography} from "antd";
import {getSchedulerObjects, scheduleEvent, updateJob} from "@API/scheduler";
import {Cron} from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import {fromCronString, toCronString} from "@global/DateUtils";


const JobEditor = ({
                       addModalOption,
                       setAddModal,
                       afterFinish,
                       schedulerObjectBase = null,
                       jobSettings = null,
                       tqUpdateIndicator = null
                   }) => {
    const [form] = Form.useForm();
    const [cronValue, setCronValue] = useState('* * * * *')
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState(null);
    const [queues, setQueues] = useState(null);
    const abortControllerRef = useRef(new AbortController());

    const handleCancel = () => {
        form.resetFields();
        setAddModal(false);
    }

    const onFinishCreate = values => {
        setLoading(true);

        scheduleEvent(values._id, values.entity, values.name, cronValue, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error(`Error while creating job`);
                return
            }
            message.success(`New job was created successfully`);
            handleCancel();
            afterFinish();
        }, abortControllerRef.current.signal)
    }

    const onFinishUpdate = values => {
        setLoading(true);

        let jobData = fromCronString(cronValue)
        jobData['name'] = values.name

        console.log("jobData", jobData)
        updateJob(jobSettings.id, jobData, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error(`Error while updating the job`);
                return
            }
            message.success(`The job was updated successfully`);
            handleCancel();
            afterFinish();
        }, abortControllerRef.current.signal)
    }

    const getTasksAndQueues = () => {
        setLoading(true);
        Promise.all([
            new Promise((resolve) => getSchedulerObjects("task", null, "", (data, error) => {
                resolve();
                if (error) {
                    message.error(`Error while retrieving scheduler tasks`)
                    return
                }
                console.log("get tasks", data)
                setTasks(data);
            }, abortControllerRef.current.signal)),
            new Promise((resolve) => getSchedulerObjects("queue", null, "", (data, error) => {
                resolve();
                if (error) {
                    message.error(`Error while retrieving scheduler queues`)
                    return
                }
                console.log("get queues", data)
                setQueues(data);
            }, abortControllerRef.current.signal))
        ]).then(() => setLoading(false))
    }

    useEffect(() => {
        getTasksAndQueues();
    }, [tqUpdateIndicator])

    useEffect(() => {
        if (schedulerObjectBase) {
            form.setFieldsValue({
                name: schedulerObjectBase.name,
                _id: schedulerObjectBase._id,
                entity: schedulerObjectBase.entity
            });
        }
        if (jobSettings) {
            let crons = toCronString(jobSettings)
            setCronValue(crons)
        }


    }, [addModalOption, schedulerObjectBase, jobSettings, tasks, queues])

    useEffect(() => {
        return () => abortControllerRef.current.abort()
    }, [])

    const getObjectsByEntityType = (type) => {
        switch (type) {
            case "task":
                return tasks
            case "queue":
                return queues
            default:
                return []
        }
    }

    return (
        (<Modal
            width={700}
            open={addModalOption !== false}
            title={`${jobSettings ? "Update" : "Create"} job`}
            onOk={form.submit}
            onCancel={handleCancel}
            okText={jobSettings ? "Update" : "Create"}
        >
            <Cron value={cronValue} setValue={setCronValue}/>
            <Typography.Paragraph type={'secondary'} mark>Cron: {cronValue} (UTC)</Typography.Paragraph>
            <Typography.Paragraph type={'secondary'}>UTC time: {new Date().toUTCString()}</Typography.Paragraph>
            <Spin size={"large"} spinning={loading} tip="Waiting...">
                {tasks && queues && <Form form={form} name="scheduler_job_creator"
                                          onFinish={jobSettings ? onFinishUpdate : onFinishCreate}
                                          scrollToFirstError layout="vertical"
                >
                    <Form.Item
                        name="entity"
                        label="Task / Queue"
                        rules={[
                            {
                                required: true,
                                message: "You need to choose scheduler object type"
                            },
                        ]}
                    >
                        <Select placeholder="Select entity type"
                                onChange={() => form.setFieldsValue({name: null, _id: null})}
                                disabled={schedulerObjectBase ? true : false}
                        >
                            <Select.Option value="task">Task</Select.Option>
                            <Select.Option value="queue">Queue</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        hidden
                        name="name"
                    ></Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.entity !== curValues.entity
                        }
                    >
                        {() => form.getFieldValue("entity") ? (
                            <Form.Item
                                name="_id"
                                label={`Select ${form.getFieldValue("entity")} to schedule`}
                                rules={[
                                    {
                                        required: true,
                                        message: `You need to select ${form.getFieldValue("entity")}`
                                    },
                                ]}
                            >
                                <Select placeholder={`Select ${form.getFieldValue("entity")}`}
                                        onChange={(_, option) => form.setFieldsValue({name: option.children})}
                                        disabled={schedulerObjectBase ? true : false}
                                >
                                    {getObjectsByEntityType(form.getFieldValue("entity"))
                                        .filter(q => schedulerObjectBase ? true : !q.hasOwnProperty("job"))
                                        .map((obj, inx) => <Select.Option key={inx}
                                                                          value={obj._id}>{obj.title}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        ) : null}
                    </Form.Item>
                </Form>}
            </Spin>
        </Modal>)
    );
}

export default JobEditor