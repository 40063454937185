import {Button, Image, Space, Typography, Tooltip, message} from "antd";
import React, {useRef} from "react";
import {useNavigate} from 'react-router-dom';
import NewInvestorForm from "@holistico/proposal/new/form/NewInvestorForm";
import {HolisticoProposalContextProvider} from "@hooks/ProposalContext";
import ReportDownloadButton from "@holistico/proposal/view/report/ReportDownloadButton";
import AiSidebarContainer from "@holistico/ai/sidebar/AiSidebarContainer";
import PortfolioPreview from "@holistico/proposal/new/form/PortfolioPreview";
import "./Enrollment.less"
import {useCounter} from "ahooks";
import {submitExtendedClient} from "@API/clients";


const Enrollment = () => {
    const [clientId, setClientId] = React.useState(null)
    const [refreshCounter, {inc, dec, set, reset}] = useCounter(0)

    const clientUpdated = (newClientId) => {
        if (newClientId != clientId) {
            setClientId(newClientId)
        } else {
            inc()
        }
    }
    return <>
        <HolisticoProposalContextProvider
            proposal_id={clientId}
            startLoading={false}
            loadIteration={refreshCounter}
        >
            <AiSidebarContainer
                proposalId={clientId}
                chatType={"enrollment"}
                marginTop={18}
            >
                <Space align={'end'} style={{height: 50}}>
                    <Image
                        height={50}
                        width={102}
                        src={"https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*xOYlR4e8ihIAAAAAAAAAAABkARQnAQ"}
                        preview={false}
                    />
                    <Typography.Title
                        level={3}
                        className={"page-title primary"}
                        style={{marginLeft: 0}}
                    >
                        New investor
                    </Typography.Title>
                </Space>
                <div className={"page-content"}>
                    <NewInvestorForm setClientId={clientUpdated}/>
                    <PortfolioPreview id={clientId}/>
                </div>
            </AiSidebarContainer>
        </HolisticoProposalContextProvider>
    </>
}

export default Enrollment
