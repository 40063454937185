import {Col, Empty, Row} from 'antd'
import React from "react";
import SizePie from "@components/holistico/chart/SizePie";

const SizeCompareRow = ({original_values, recommended_values}) => {
    if (!original_values || Object.keys(original_values).length === 0) return <Empty
        style={{margin: "15px 0"}}
        description="No size information"/>;

    return <Row gutter={[24, 24]} style={{width: '100%'}}>
        <Col span={12}>
            <SizePie loading={false}
                           allocation={original_values?.size}/>
        </Col>
        <Col span={12}>
            <SizePie loading={false}
                           allocation={recommended_values?.size}/>
        </Col>
    </Row>
}

export default SizeCompareRow
