import React, {useCallback, useEffect, useRef, useState} from "react";
import {Bar} from "@ant-design/charts";
import moment from "moment";
import {Result, Space, Typography} from "antd";
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";

const DATE_TIME_FORMAT = 'MMM DD, HH:mm';
const DEFAULT_START = moment().subtract(1, 'days').startOf('day')
const DEFAULT_END = moment().add(1, 'days')

const getColor = (statusObj) => {
    if (statusObj.status == 'planned') {
        return '#cccccf';
    } else if (statusObj.status == 'error') {
        return 'red';
    } else if (statusObj.status == 'running') {
        return 'green'
    } else {
        return '#3196B2'
    }
}

const getResultConf = (status) => {
    if (status == 'running') {
        return {icon: <PlayCircleOutlined/>, status: 'warning'}
    } else if (status == 'success') {
        return {icon: <CheckCircleOutlined/>, status: 'success'}
    } else if (status == 'planned') {
        return {icon: <ClockCircleOutlined/>, status: 'info'}
    } else if (status == 'error') {
        return {icon: <CloseCircleOutlined/>, status: 'error'}
    }
}
const TaskTimeLine = ({
                          task_data = [],
                          interval_dates = [
                              DEFAULT_START,
                              DEFAULT_END
                          ],
                          task_names = [],
                          queue_names = [],
                          ordered_titles = [],
                      }) => {
    const [config, setConfig] = useState(null)
    const barChartRef = useRef(null);

    const unixFormatter = useCallback(
        (v) => {
            let momentValue = moment.unix(parseInt(v)).format(DATE_TIME_FORMAT)
            return momentValue
        },
        []
    );

    const downloadImage = () => {
        barChartRef.current?.downloadImage();
    };

    // get base64 data
    const toDataURL = () => {
        console.log(barChartRef.current?.toDataURL());
    };

    useEffect(() => {
        const height = ordered_titles.length * 39
        if (height == 0) return

        const cfg = {
            height: height,
            autoFit: false,
            xField: 'interval_numeric',
            yField: 'title',
            isRange: true,
            label: false,
            theme: {
                styleSheet: {
                    fontFamily: 'Red Hat Display'
                }
            },
            meta: {
                result: {
                    values: ['success', 'error', 'running', 'planned']
                },
                type: {
                    values: ['task', 'queue']
                },
                title: {
                    values: ordered_titles
                },
            },
            barStyle: (itemInfo) => {
                let color = getColor(itemInfo.interval_numeric[2])
                return {
                    //fill: color,
                    cursor: 'pointer',
                }
            },
            seriesField: 'interval_numeric',
            color: (itemInfo) => {
                return getColor(itemInfo.interval_numeric[2])
            },
            xAxis: {
                min: interval_dates[0].unix(),
                max: interval_dates[1].unix(),
                position: 'left',
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [4, 5],
                        strokeOpacity: 0.7
                    }
                },
                label: {
                    type: 'date',
                    position: 'left',
                    formatter: unixFormatter,
                    style: {
                        fontWeight: '700'
                    },
                    layout: [
                        {type: 'interval-hide-overlap'},
                    ],
                },
                grid: {
                    line: {
                        style: {
                            stroke: '#cccccc',
                            lineWidth: 0.5,
                        }
                    }
                },
            },
            yAxis: {
                label: {
                    style: (label, index, all_labels) => {
                        let defaultStyle = {
                            fontSize: 14,
                            width: 100
                        }

                        let queueStyle = {
                            fontWeight: 'bold',
                            fontSize: 16,
                            shadowColor: '#E0AD60',
                            fill: '#E0AD60'
                        }

                        if (queue_names.includes(label)) {
                            defaultStyle = {...defaultStyle, ...queueStyle}
                        }
                        return defaultStyle
                    },
                    formatter: (label, index, all_labels) => {
                        return label
                    },
                },

                grid: {
                    line: {
                        style: {
                            stroke: '#cccccc',
                            lineWidth: 1,
                        }
                    }
                }
            },
            tooltip: {
                shared: false,
                fields: ["start", "finish", "task", "status", "total_time_seconds", "error_message"],
                customContent: (title, data) => {
                    try {
                        if (!data.length) {
                            return null
                        }

                        const tooltipData = data[0].data;
                        const duration = moment.duration(tooltipData.total_time_seconds * 1000);

                        let sbttl = <Space direction={'vertical'}>
                            <Typography.Text>
                                {unixFormatter(tooltipData.start)} &mdash; {unixFormatter(tooltipData.finish)}
                            </Typography.Text>
                            <Typography.Text
                                mark={true}>{tooltipData.type} run
                                time: {duration.humanize()}</Typography.Text>
                        </Space>

                        let resultStatus = tooltipData.result
                        let {icon, status} = getResultConf(resultStatus)
                        return <Result
                            icon={icon}
                            status={status}
                            title={tooltipData.title}
                            subTitle={sbttl}
                            style={{maxWidth: 400}}
                        >
                            {tooltipData.error_message ?
                                <div>
                                    <Typography.Text type={'danger'} aling={'left'}
                                                     style={{textAlign: 'left'}}>
                                        {tooltipData.error_message}
                                    </Typography.Text>
                                </div> : null}
                        </Result>
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            shape: 'hollow-rect',
            legend: false,
        };
        setConfig(cfg)
    }, [interval_dates])


    return config &&
        <Bar {...config}
             data={task_data}
             chartRef={barChartRef}
             onReady={(plot) => {
                 plot.on('plot:click', (evt) => {
                     const {x, y} = evt;
                     const {xField} = plot.options;
                     const tooltipData = plot.chart.getTooltipItems({x, y});
                     console.log(tooltipData);
                 });

                 barChartRef.current = plot
             }}/>
        ;
}

const formChartData = (event_list) => {
    let events = [...event_list]
    for (let event of events) {
        let start = moment.utc(event.start).unix()
        let finish = moment.utc(event.finish).unix()

        let interval_numeric = [start, finish, {type: event.type, status: event.result}]
        event['interval_numeric'] = interval_numeric
        event['start'] = start
        event['finish'] = finish
    }

    events.sort((a, b) => a.start - b.start);
    return events
}

export {
    TaskTimeLine, formChartData
}