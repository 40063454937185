import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {Avatar, Card, Col, Divider, Form, InputNumber, Rate, Row, Select, Slider} from "antd";
import {Radar} from '@ant-design/charts';
import {
    AimOutlined,
    CalculatorOutlined,
    CrownOutlined,
    RobotOutlined,
    SmileOutlined,
    StockOutlined
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import Meta from "antd/es/card/Meta";
import ChartStyles from "@global/ChartStyles";
import ClientChat from "@holistico/proposal/view/ai/ClientChat";
import HSpinner from "@holistico/HSpinner";
import ConfigurationForm from "@holistico/proposal/view/ai/ConfigurationForm";



const prepareKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
}

const AiConfiguration = () => {
    const {id, aiSettings, modifyAiSettings} = useHolisticoProposalContext()
    // const [form] = Form.useForm();

    if (aiSettings == null) {
        return null
    }
    return <div className={'page-content'}>
        <Card>
            <Meta
                avatar={<Avatar size={100} src="/img/cyber-master-of-coin.jpg"/>}
                title="Master of coin"
                description="As the master of coin, I'm a wizard with numbers and finances. I have a knack for making sense of complex financial data, making it as comprehensible as a children's book."
            />
            <Divider dashed={true}/>
            <Row gutter={24}>
                <Col span={12}>
                    <ConfigurationForm aiSettings={aiSettings} modifyAiSettings={modifyAiSettings}/>
                </Col>
            </Row>
        </Card>


    </div>
}

export default AiConfiguration;
