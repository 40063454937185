import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

/* all securities with weights and performances by model */
const sendChatMessage = (proposal_id, content, role, callback) => {
    let url = `/ai-conversation/${proposal_id}`
    const params = {
        content: content,
        role: role
    }
    axiosInstance.post(url, params)
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
};

export {
    sendChatMessage,
    cancel
}
