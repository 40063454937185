import React from "react";
import { Typography, Empty } from "antd";
import SizePie from "@components/chart/SizePie";
import SectorBar from "@components/chart/SectorBar";
import SizeStyleHeat from "@components/chart/SizeStyleHeat";

const {Title} = Typography;

const EquityDistribution = ({fullAsset}) => {
    return (
        <>
            <Title level={4}>Size</Title>
            {Object.keys(fullAsset.size_alloc_dist).length > 0 ?
            <SizePie loading={false} allocation={fullAsset.size_alloc_dist}/>
            :
            <Empty description={'Size distribution is not available'}/>
            }
            <br/>
            <Title level={4}>Sector</Title>
            {Object.keys(fullAsset.sector_alloc_dist).length > 0 ?
            <SectorBar loading={false} allocation={fullAsset.sector_alloc_dist} legend={false} height={"auto"}/>
            :
            <Empty description={'Sector distribution is not available'}/>
            }
            <br/>
            <Title level={4}>Size Style</Title>
            {Object.keys(fullAsset.size_style_alloc_dist).length > 0 ?
            <SizeStyleHeat loading={false} allocation={fullAsset.size_style_alloc_dist}/>
            :
            <Empty description={'Size Style distribution is not available'}/>
            }
        </>
    )
}

export default EquityDistribution