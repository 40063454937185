import React from "react";
import {Flex, Form, Radio, Space} from 'antd';
import Localizator from "@global/Localizator";


const ReturnInput = ({allReturns, hidden=false}) => {
    return <Form.Item
        name={"return_factor"}
        label={<b>Rebalance</b>}
        hidden={hidden}
    >
        <Radio.Group size={'large'}>
            <Flex vertical>
                {[].concat(allReturns).reverse().map(return_factor => {
                    return <Radio value={return_factor} key={return_factor}>
                        {Localizator.t(`backtest_v2.${return_factor}`)}
                    </Radio>
                })}
            </Flex>
        </Radio.Group>
    </Form.Item>
}

export default ReturnInput
