import React, {useContext} from "react";
import {Col, Row, Flex, Divider} from "antd";
import SectorInput from "@components/backtest/inputs/common/SectorInput";
import {BacktestContext} from "@components/backtest/BacktestContext";
import SizeInput from "@components/backtest/inputs/common/SizeInput";
import StyleInput from "@components/backtest/inputs/common/StyleInput";
import GroupingInput from "@components/backtest/inputs/common/GroupingInput";
import ReturnInput from "@components/backtest/inputs/common/ReturnInput";
import DateInput from "@components/backtest/inputs/common/DateInput";
import WeightingSchemaInput
    from "@components/backtest/inputs/common/WeightingSchemaInput";


const CommonInputs = ({}) => {
    const {
        sizes,
        sectors,
        vbg_styles,
        groupings,
        returns
    } = useContext(BacktestContext);

    return <Flex vertical gap={"middle"}>
        <DateInput/>
        <Row gutter={[24, 24]}>
            <Col span={14}>
                <Flex vertical gap={"middle"}>
                    <GroupingInput allGroupings={groupings}/>
                    <WeightingSchemaInput/>
                </Flex>
            </Col>
            <Col span={10}>
                <ReturnInput allReturns={returns}/>
            </Col>
        </Row>
        <Divider style={{margin: "4px 0"}}/>
        <Row gutter={[24, 24]}>
            <Col span={14}>
                <SectorInput allSectors={sectors}/>
            </Col>
            <Col span={10}>
                <Flex vertical gap={"middle"}>
                    <SizeInput allSizes={sizes}/>
                    <StyleInput allStyles={vbg_styles}/>
                </Flex>
            </Col>
        </Row>
    </Flex>
}

export default CommonInputs
