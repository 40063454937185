/* eslint-disable react/prop-types */
import React from 'react';
import {Badge, Col, Row, Typography} from "antd";
import {ClockCircleOutlined, CloudFilled, UserSwitchOutlined} from '@ant-design/icons';

import RiskIndicator from "@root/components/portfolio/RiskIndicator";
import ValueFormatter from "@global/ValueFormatter";
import ClientRowMenu from "./ClientRowMenu";

import './ClientsList.less';
import {useAuth} from "@hooks/AuthHook";

// const {Paragraph, Text, Title} = Typography;
// const {Meta} = Card;
const {Text} = Typography;

const ClientListItem = ({client, select, edit, secureMode}) => {
    const current_calculations = client['p_bucket_cur']['calculated_values'];
    const auth = useAuth();
    const owner = client['owner']
    // const proposed_calculations = client['p_bucket_prop']['calculated_values']

    const ClientRiskStatus = () => {
        if (client._id) {
            let risker = <RiskIndicator riskValue={current_calculations?.risk?.risk_score}/>
            return risker
        } else {
            return <div className={"risk-indicator-low"} style={{height: 50, width: 50}}>
                <CloudFilled style={{fontSize: '16px', color: '#08c'}}/>
            </div>
        }
    }
    
    const ClientFullName = () => {
        if (owner == auth.user['email'] || !client._id) {
            return <Text className="portfolio-title-name" >{client.full_name}</Text>
        } else {
            return <Text copyable={{
                icon: [<UserSwitchOutlined style={{color: '#E0AD60', opacity: 0.5}}/>,<UserSwitchOutlined style={{color: '#E0AD60'}}/>],
                text: owner,
                tooltips: ['Owned by ' + owner, 'Owner name was copied to clipboard']
                
            }} className="portfolio-title-name" >{client.full_name}</Text>
        }
    }

    return (

        <Row className={'portfolio-row-item'} style={{marginBottom: 13, marginLeft: 0, marginRight: 0, marginTop: 0}}
             type="flex" justify="start" align="middle" key={client._id} gutter={[8, 0]}
             onClick={() => select(client)}>
            <Col md={2} lg={2} sm={5} xs={5} style={{textAlign: 'right'}}>
                <div className={secureMode ? "secured-client" : ""}>

                    <ClientRiskStatus/>
                </div>
            </Col>
            <Col md={14} lg={14} sm={0} xs={0} style={{textAlign: 'left'}}>
                <div className={secureMode ? "secured-client" : ""}>
                    <ClientFullName/>
                    {client.questionnaire?.risk ?

                        <Text className="portfolio-title-name additional-info">, target risk
                            score: {client.questionnaire?.risk}</Text> :
                        <Text className="portfolio-title-name additional-info">, no questionnaire filled</Text>}
                </div>
            </Col>
            {/* <Col md={0} lg={0} sm={14} xs={14} style={{textAlign: 'left'}}>
                <Text className="portfolio-title-name">{client.full_name}</Text>
            </Col> */}
            <Col md={6} lg={6} sm={0} xs={0} style={{textAlign: 'right'}}>
                <div className={secureMode ? "secured-client" : ""}>
                    {ValueFormatter.currency(current_calculations?.market_value)}
                </div>
            </Col>
            <Col md={2} lg={2} sm={0} xs={0} style={{textAlign: 'center'}}>
                <ClientRowMenu edit={() => edit(client._id)}
                               id={client._id}/>
            </Col>
        </Row>
    )
}

export default ClientListItem;
