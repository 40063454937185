/* eslint-disable react/prop-types */
import React from 'react';
import {Flex, Alert, Space} from 'antd';
import {grey} from '@ant-design/colors';


const DescriptiveInfo = ({title1, title2, title3, alerts, style}) => {
    return <Flex vertical style={style}>
        <span style={{fontSize: 14, fontWeight: 500}}>
            {title1}
        </span>
        <span style={{fontSize: 16, lineHeight: 1.2, marginTop: 12}}>
            {title2}
        </span>
        <span style={{fontSize: 14, lineHeight: 1.2, marginTop: 4, color: grey[4]}}>
            {title3}
        </span>
        {alerts && alerts.length > 0
            &&
            <Space style={{width: '100%'}}>
                {alerts.map(alert => {
                    return <Alert
                        key={alert}
                        message={alert}
                        type="warning"
                        showIcon
                        style={{display: "inline-flex", fontSize: 14, marginTop: 8}}
                    />
                })}
            </Space>
        }
    </Flex>
}

export default DescriptiveInfo;
