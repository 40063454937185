import {Button, Badge, Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";
import React from "react";
import "./MinimizedButtonsPanel.less"


const MinimizedButtonsPanel = ({
                                   aiMinimized,
                                   notesMinimized,
                                   hideAi,
                                   hideNotes,
                                   onAiClick,
                                   onNotesClick
                               }) => {
    return <>
        {((aiMinimized && !hideAi) || (notesMinimized && !hideNotes))
             &&
            <div className={"minimized-buttons-panel"}>
                {aiMinimized && !hideAi
                    &&
                    <Badge dot color={"red"}>
                        <Tooltip title={"Open AI chat"} placement="left">
                            <Button onClick={onAiClick}>
                                AI
                            </Button>
                        </Tooltip>
                    </Badge>
                }
                {notesMinimized && !hideNotes
                    &&
                    <Badge dot color={"red"}>
                        <Tooltip title={"Open notes"} placement="left">
                            <Button onClick={onNotesClick}>
                                <EditOutlined/>
                            </Button>
                        </Tooltip>
                    </Badge>
                }
            </div>
        }
    </>
}


export default MinimizedButtonsPanel;
