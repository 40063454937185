import React from "react";
import {
    Col,
    Form,
    Row,
    Input,
    Flex,
    Card,
    theme,
} from "antd";
import PortfolioInput from "@components/backtest/inputs/portfolio/PortfolioInput";
import CommonInputs from "@components/backtest/inputs/common/CommonInputs";


const {useToken} = theme;


const BacktestConfigForm = ({}) => {
    const {token} = useToken();

    return <>
        <Form.Item name={'name'} hidden={true}><Input/></Form.Item>
        <Form.Item name={'id'} hidden={true}><Input/></Form.Item>
        <Row gutter={[token.padding, token.padding]} align={"stretch"}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <Card hoverable style={{height: "100%"}}>
                    <CommonInputs/>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Flex vertical gap={token.padding} style={{height: "100%"}}>
                    <Card hoverable style={{flexGrow: 1}}>
                        <PortfolioInput field={'portfolio_config'}/>
                    </Card>
                    <Card hoverable style={{flexGrow: 1}}>
                        <PortfolioInput field={'benchmark_config'}/>
                    </Card>
                </Flex>
            </Col>
        </Row>
    </>
}

export default BacktestConfigForm
