import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Checkbox, Col, Empty, Input, message, Modal, Row, Select, Skeleton, Space, Typography} from "antd";
import {FileExcelOutlined, SearchOutlined} from "@ant-design/icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {HexColorPicker} from "react-colorful";
import {cancel, createExportConfig, getExportConfig, getFactorsRules, updateExportConfig} from "@API/data_export";
import FactorSelector from "@components/portfolio/selector/FactorSelector";
import { debounce } from "@global/Utils";

import "./DataExport.less";


const DEFAULT_COLOR = "#ffffff";

const EditExportConfig = ({config, close, fromEmpty}) => {
    const [configProxy, setConfigProxy] = useState(null);
    const [activeFactor, setActiveFactor] = useState(null);
    const [defaultConfigValues, setDefaultConfigValues] = useState(null);
    const [loading, setLoading] = useState(false);
    const [seachedFactor, setSearchedFactor] = useState("");
    const [filtredFactors, setFiltredFactors] = useState([]);

    useEffect(() => {
        if (!config) {
            return
        }
        setLoading(true);
        getFactorsRules((rules, error) => {
            if (error) {
                message.error("Error while loading rules");
                return
            }

            setDefaultConfigValues(rules);
            if (config.id) {
                getExportConfig(config.id, (data, error) => {
                    if (error) {
                        message.error("Error while loading the config");
                        return
                    }
                    setConfigProxy(data)
                    setLoading(false)
                });
            } else {
                let proxy = config;
                if (fromEmpty) {
                    proxy.config = {ticker: rules['factors']["ticker"]};
                } else {
                    proxy.config = rules['factors'];
                }
                setConfigProxy(proxy);
                setLoading(false);
            }

        })


        return () => {
            cancel && cancel();
        }
    }, [config, fromEmpty]);

    const factorsOrderChange = (result) => {
        if (!result.destination) return;

        const items = Array.from(Object.keys(configProxy.config));
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let reorderedConfig = {};
        items.map(factor => {
            reorderedConfig[factor] = configProxy.config[factor];
        })
        setConfigProxy({...configProxy, config: reorderedConfig});
    }

    const setRule = useCallback(debounce((enable, rule, value, factor) => {
            let rules;
            console.log(rule)
            if (enable) {
                rules = configProxy.config[factor].rules ? {
                    ...configProxy.config[factor].rules,
                    [rule]: value
                } : {[rule]: value};
            } else {
                if (configProxy.config[factor].rules) {
                    let newRules = Object.assign({}, configProxy.config[factor].rules);
                    delete newRules[rule];
                    if (Object.keys(newRules).length === 0) {
                        rules = null;
                    } else {
                        rules = newRules;
                    }
                } else {
                    rules = null;
                }
            }
            setConfigProxy({
                ...configProxy,
                config: {
                    ...configProxy.config,
                    [factor]: {
                        ...configProxy.config[factor],
                        rules: rules
                    }
                }
            })
        }, 200), [configProxy, setConfigProxy]);

    const setRuleValue = useCallback(debounce((rule, value, factor) => {
        console.log(rule, value, factor)
        setConfigProxy({
            ...configProxy,
            config: {
                ...configProxy.config,
                [factor]: {
                    ...configProxy.config[factor],
                    rules: configProxy.config[factor].rules !== null ? {
                        ...configProxy.config[factor].rules,
                        [rule]: value
                    } : {[rule]: value}
                }
            }
        })
    }, 350), 
    [configProxy, setConfigProxy]);

    const DraggableFactorsList = useMemo(() => {
        if (!configProxy) {
            return
        }
        return (
            <DragDropContext onDragEnd={factorsOrderChange}>
                <Droppable droppableId="factors">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Space style={{maxHeight: "600px", padding: "5px 0", overflowY: "scroll"}} direction="vertical">
                                {Object.keys(configProxy.config).map((factor, inx) => {
                                    return (
                                        <Draggable key={factor} draggableId={factor} index={inx}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {/* {(factor == activeFactor) ?
                                                        <a style={{textDecoration: 'underline'}}>
                                                            {configProxy.config[factor].full_name}
                                                        </a> : <a onClick={() => setActiveFactor(factor)} href={'#'}>
                                                            {configProxy.config[factor].full_name}
                                                        </a>} */}
                                                        <span className={factor === activeFactor ? "export-active-factor" : "export-inactive-factor"}
                                                            onClick={() => setActiveFactor(factor)}
                                                        >
                                                            {configProxy.config[factor].full_name}
                                                        </span>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </Space>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }, [configProxy, activeFactor, setActiveFactor]);

    const updateConfigFactors = useCallback((factors) => {
        let newConfig = {};
        console.log(factors)
        factors.map(factor => {
            console.log(factor)
            newConfig[factor.column_name] = {
                column_name: factor.column_name,
                full_name: factor.full_name,
                description: factor.description,
                source: factor.source,
                rules: configProxy.config[factor.column_name]?.rules ? 
                    configProxy.config[factor.column_name]?.rules : null
            };
        });
        setConfigProxy({
            ...configProxy,
            config: newConfig
        });
        setActiveFactor(null);
    }, [configProxy, setConfigProxy, setActiveFactor])

    const memoFactorSelector = useMemo(() => {
        if (!configProxy) {
            return
        }
        return (
            <FactorSelector done={updateConfigFactors}
            selectedColumnNames={
                Object.keys(configProxy.config)
            }
            ligthVersion={true}
            />
        )
    }, [configProxy])

    const FactorDescriptionRules = useMemo(() => {
        if (configProxy && activeFactor) {
            const factor = configProxy.config[activeFactor];
            const columnName = activeFactor;
            return (
                <Space direction="vertical" style={{width: "100%"}}>
                    <Typography.Title level={5}>{factor.full_name}</Typography.Title>
                    <Typography.Text>Original name: <b>{factor.column_name}</b></Typography.Text>
                    <Typography.Text>Data source: <b>{factor.source}</b></Typography.Text>
                    <Typography.Text>{factor.description}</Typography.Text>
                    <Typography.Title level={5} style={{color: "green", marginTop: "20px"}}><FileExcelOutlined/> Excel
                        rules</Typography.Title>
                    {Object.keys(defaultConfigValues.rules).map(rule => {
                        let defaultValue;

                        if (rule === "color") {
                            defaultValue = factor.rules !== null && factor.rules.hasOwnProperty(rule) ? factor.rules[rule] : DEFAULT_COLOR;
                        } else {
                            defaultValue = factor.rules !== null && factor.rules.hasOwnProperty(rule) ? factor.rules[rule] : defaultConfigValues.rules[rule][0];
                        }

                        return (
                            <Row key={rule} style={{borderTop: "1px solid #f0f0f0"}} align="middle">
                                <Col span={12}>
                                    <Checkbox key={rule}
                                              onChange={e => setRule(e.target.checked, rule, defaultValue, columnName)}
                                              checked={factor?.rules !== null && factor.rules.hasOwnProperty(rule) ? true : false}
                                    >
                                        {rule}
                                    </Checkbox>
                                </Col>
                                {factor?.rules !== null && factor.rules.hasOwnProperty(rule) ?
                                    <Col span={12} style={{paddingTop: "10px"}}>
                                        {rule !== "color" ?
                                            <Select style={{width: "100%"}}
                                                    value={defaultValue}
                                                    onChange={value => setRuleValue(rule, value, columnName)}>
                                                {defaultConfigValues.rules[rule].map(value => {
                                                    return (
                                                        <Select.Option key={value} value={value}>{value}</Select.Option>
                                                    )
                                                })}

                                            </Select>
                                            :
                                            <Space direction="vertical">
                                                <HexColorPicker
                                                    style={{height: "120px"}}
                                                    color={defaultValue}
                                                    onChange={color => factor.rules !== null && factor.rules.hasOwnProperty(rule) ? setRuleValue(rule, color, columnName) : {}}
                                                />
                                                <Input value={defaultValue}
                                                onChange={e => factor.rules !== null && factor.rules.hasOwnProperty(rule) ? setRuleValue(rule, e.target.value, columnName) : {}}
                                                placeholder="Color"/>
                                            </Space>
                                        }
                                    </Col>
                                    :
                                    <Col span={12} style={{paddingTop: "10px"}}>
                                        <Typography.Text>Activate rule for view values</Typography.Text>
                                    </Col>
                                }
                            </Row>
                        )
                    })}
                </Space>
            )
        } else {
            return <Empty description={'Select factor to define rules'}/>
        }
    }, [configProxy, activeFactor, defaultConfigValues, setRuleValue, setRule]);

    const resetConfig = (closed) => {
        setFiltredFactors([]);
        setSearchedFactor("");
        setConfigProxy(null);
        setActiveFactor(null);
        close(closed);
    }

    const updateConfig = () => {
        setFiltredFactors([]);
        setSearchedFactor("");
        if (configProxy.id) {
            updateExportConfig(configProxy.id, configProxy, (response, error) => {
                if (error) {
                    message.error("Error updating the config!");
                    return
                }
                resetConfig(true);
            });
        } else {
            createExportConfig(configProxy, (response, error) => {
                if (error) {
                    message.error("Error updating the config!");
                    return
                }
                resetConfig(true);
            });
        }
    }

    const factorsFilter = useCallback((searchString) => {
        if (searchString === "") {
            setFiltredFactors([]);
        } else {
            setFiltredFactors(Object.keys(configProxy.config).filter(factor =>
                configProxy.config[factor].full_name.toLowerCase().includes(searchString.toLowerCase())
            ));
        }
        setSearchedFactor(searchString);
    }, [configProxy, filtredFactors, setFiltredFactors, seachedFactor, setSearchedFactor])

    const SearchedFactorsList = useMemo(() => {
        if (!configProxy && seachedFactor === "") {
            return
        }
        if (filtredFactors.length === 0) {
            return (
                <Empty style={{
                    height: "70%",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center"
                }} description={'Not found such factors'}/>
            )
        }
        return (
            <Space style={{maxHeight: "600px", padding: "5px 0", overflowY: "scroll"}} direction="vertical">
                {filtredFactors.map((factor, inx) => {
                    return (
                        <span key={inx} className={factor === activeFactor ? "export-active-factor" : "export-inactive-factor"}
                            onClick={() => setActiveFactor(factor)}
                        >
                            {configProxy.config[factor].full_name}
                        </span>
                    )
                })}
            </Space>
        )
    }, [configProxy, activeFactor, filtredFactors, setActiveFactor]);

    return (
        (<Modal
            title="Excel configuration editor"
            open={config != null}
            onCancel={() => resetConfig(false)}
            onOk={() => updateConfig()}
            width={750}
        >
            <Skeleton loading={loading} active>
                <Row style={{marginBottom: "20px", fontSize: "1.1em"}}>
                    <Typography.Text style={{marginBottom: "5px"}}>Title</Typography.Text>
                    <Input value={configProxy?.name}
                           onChange={e => setConfigProxy({...configProxy, name: e.target.value})}
                           placeholder="Input title"/>
                </Row>
                <Row style={{fontSize: "1.1em"}}>
                    <Col span={9} style={{borderRight: "1px solid #f0f0f0"}}>
                        {memoFactorSelector}
                        <Input style={{marginBottom: "5px", width: "90%"}} value={seachedFactor}
                        onChange={e => factorsFilter(e.target.value)}
                        placeholder="Search factor" prefix={<SearchOutlined />} />
                        {seachedFactor !== "" 
                        ?
                        SearchedFactorsList
                        :
                        DraggableFactorsList
                        }
                    </Col>
                    <Col span={15} style={{paddingLeft: "20px"}}>
                        {FactorDescriptionRules}
                    </Col>
                </Row>
            </Skeleton>
        </Modal>)
    );
}

export default EditExportConfig
