import React from "react";
import {Col, Empty, Progress, Row} from "antd";
import {FileDoneOutlined, FileUnknownOutlined, StopOutlined} from "@ant-design/icons";
import {useStorage} from "@hooks/GlobalStorage";
import {capitilize} from "@global/Localizator";

const progressBarStatus = status => {
    switch (status) {
        case "waiting":
            return "normal"
        case "canceled":
            return "exception"
        case "done":
            return "success"
        default:
            return "normal"
    }
}

const getStatusColor = status => {
    switch (status) {
        case "waiting":
            return "#828282"
        case "canceled":
            return "#FF4D4F"
        case "writing":
            return "#1890FF"
        case "done":
            return "#52C41A"
        case "validation":
            return "#FFC72B"
        default:
            return "black"
    }
}

const TAG = "weekly";

const ImportedFilesTable = ({notShow = [], size = "default", renderOnlyStatus = []}) => {
    const {storage} = useStorage();
    console.log(storage)
    return (
        <div>
            {storage.hasOwnProperty(TAG) && storage[TAG].length !== 0 ?
                storage[TAG].map((file, inx) => {
                    if (renderOnlyStatus.length === 0 || renderOnlyStatus.includes(file.status)) {
                        return (
                            <Row key={inx} justify="space-between"
                                 style={{textAlign: "left", fontSize: size === "small" ? "14px" : "20px"}}>
                                {!notShow.includes("label") && <Col span={size === "small" ? null : 1}>
                                    {file.status === "canceled" ?
                                        <StopOutlined style={{color: "red"}}/>
                                        :
                                        file.type ? <FileDoneOutlined style={{color: "green"}}/> :
                                            <FileUnknownOutlined/>}
                                </Col>}
                                {!notShow.includes("name") && <Col span={size === "small" ? null : 7}>{file.name}</Col>}
                                {!notShow.includes("progress") && <Col span={7}>
                                    <Progress percent={(file.progress * 100).toFixed(2)}
                                              status={progressBarStatus(file.status)}/>
                                </Col>}
                                {!notShow.includes("type") &&
                                <Col span={size === "small" ? null : 6} style={{textAlign: 'right'}}>
                                    {file.type ? file.type.replaceAll("_", " ").toUpperCase() : "UNKNOWN TYPE"}
                                </Col>}
                                {!notShow.includes("status") &&
                                <Col span={size === "small" ? null : 2} style={{color: getStatusColor(file.status)}}>
                                    <b>{capitilize(file.status)}</b>
                                </Col>}
                            </Row>
                        )
                    }
                })
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
        </div>
    )
}

export default ImportedFilesTable
