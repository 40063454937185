import React from "react";
import {Form, Segmented} from 'antd';


const options = [
    {
        label: "Companies sample",
        value: "companies",
    },
    {
        label: "Asset or model portfolio",
        value: "any",
    },
];


const PortfolioTypeInput = ({field}) => {
    return <Form.Item name={[field, "type"]}>
        <Segmented options={options}/>
    </Form.Item>
}

export default PortfolioTypeInput;
