import dayjs from "dayjs";


const PORTFOLIO_CONFIG_DEFAULTS = {
    type: "companies",
    asset: null,
    sql_condition: null
}


export const prepareConfigForUI = (config) => {
    let _config = structuredClone(config);
    if (!_config.dates_range || _config.dates_range.length === 0) {
        _config.dates_range = [
            dayjs("2007-04-30"),
            dayjs().subtract(1, "month").endOf("month")
        ];
    } else {
        _config.dates_range = [
            dayjs(_config.dates_range[0]).endOf("month"),
            dayjs(_config.dates_range[1]).endOf("month"),
        ];
    }
    _config.portfolio_config = {...PORTFOLIO_CONFIG_DEFAULTS, ..._config?.portfolio_config};
    _config.benchmark_config = {...PORTFOLIO_CONFIG_DEFAULTS, ..._config?.benchmark_config};
    if (!_config.weight_model) {
        _config.weight_model = 0;
    }

    return _config;
}


export const prepareForServer = (config) => {
    let _config = structuredClone(config);
    _config.dates_range = [
        dayjs(config.dates_range[0]).format("YYYY-MM-DD"),
        dayjs(config.dates_range[1]).format("YYYY-MM-DD"),
    ];

    return _config;
}
