import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Badge, Button, Col, Descriptions, message, Popconfirm, Progress, Row, Spin, Tabs, Tag, Typography} from "antd";
import {cancel, deleteJob, deleteSchedulerObject, getSchedulerObject, runSchedulerObject} from "@API/scheduler";
import {getSchedulerDetails} from "@API/logs";
import {
    DeleteOutlined,
    EditOutlined,
    HistoryOutlined,
    PlayCircleOutlined,
    PoweroffOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import TaskQueueEditor from "./TaskQueueEditor";
import Editor from "@monaco-editor/react";
import SchedulerLogs from "../SchedulerLogs";
import TasksOrQueuesTable from "./TasksOrQueuesTable";
import JobEditor from "../job/JobEditor";
import {dddMMMDDHHmm} from "@global/DateFormatter";

const TaskQueueFullInfo = ({schedulerEntity}) => {
    const {schedulerObjectId} = useParams();
    const navigate = useNavigate();
    const [schedulerObject, setSchedulerObject] = useState(null);
    const [schedulerObjectDetails, setSchedulerObjectDetails] = useState(null);
    const [schedulerObjectTimer, setSchedulerObjectTimer] = useState(null);
    const [schedulerObjectTimerInterval, setSchedulerObjectTimerInterval] = useState(null);
    const [loading, setLoading] = useState(false);
    const [schedulerObjectsEditorValue, setSchedulerObjectsEditorValue] = useState(false);
    const [jobEditor, setJobEditor] = useState(false);
    const abortControllerRef = useRef(new AbortController());

    const getSchedulerObjectProxy = useCallback(() => {
        setLoading(true);
        Promise.all([
            new Promise((resolve) => getSchedulerObject(schedulerEntity, schedulerObjectId, (data, error) => {
                // setLoading(false);
                resolve();
                if (error) {
                    message.error(`Error while retrieving ${schedulerEntity}`);
                    return
                }
                console.log("schedulerObject", data)
                setSchedulerObject(data);
            }, abortControllerRef.current.signal)),
            new Promise((resolve) => getSchedulerDetails(schedulerEntity, schedulerObjectId, (data, error) => {
                // setLoading(false);
                resolve();
                if (error) {
                    message.error(`Error while retrieving ${schedulerEntity} details`);
                    return
                }
                console.log("details", data)
                setSchedulerObjectDetails(data);
            }, abortControllerRef.current.signal)),
        ]).then(() => {
            setLoading(false);
            console.log("promise done")
        })

    }, [schedulerObjectId, setLoading, setSchedulerObject, setSchedulerObjectDetails, schedulerEntity])

    const deleteJobProxy = useCallback((id) => {
        setLoading(true);
        deleteJob(id, (_, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while deleting job");
                return
            }
            message.success("Scheduler job was deleted successfully");
            getSchedulerObjectProxy();
        }, abortControllerRef.current.signal)
    }, [getSchedulerObjectProxy, setLoading])

    const runSchedulerObjectProxy = useCallback(() => {
        if (schedulerObject.state.is_running) {
            message.warning(`You can not delete running ${schedulerEntity}`);
            return
        }
        setLoading(true);
        runSchedulerObject(schedulerEntity, schedulerObjectId, (_, error) => {
            if (error) {
                setLoading(false);
                message.error(`Error while attempt to run ${schedulerEntity}`);
                return
            }
            setTimeout(() => {
                setLoading(false);
                getSchedulerObjectProxy();
                message.success(`Scheduler ${schedulerEntity} was ran`);
            }, [700])
        })
    }, [schedulerObjectId, getSchedulerObjectProxy, setLoading, schedulerObject, schedulerEntity])

    const deleteSchedulerObjectProxy = useCallback(() => {
        setLoading(true);
        deleteSchedulerObject(schedulerEntity, schedulerObjectId, (resp_message, error) => {
            if (error) {
                message.error(resp_message);
                return
            }
            message.success(`Scheduler ${schedulerEntity} was deleted successfully`);
            navigate("administration/scheduler/tasks");
        })
    }, [schedulerObjectId, setLoading, getSchedulerObjectProxy, schedulerEntity])

    // const refreshTask = () => {
    //     getSchedulerObjectProxy();
    // }

    const schedulerObjectProgress = useMemo(() => {
        if (schedulerObjectTimer === null || !schedulerObjectDetails) {
            return null
        }
        const realTaskTimeSeconds = schedulerObjectTimer / 1000;
        const percent = realTaskTimeSeconds / schedulerObjectDetails.mean_runtime * 100;
        if (schedulerObjectDetails.mean_runtime) {
            return <>
                <Progress percent={percent}
                          status="active" style={{width: "50%"}}
                          format={() => `${realTaskTimeSeconds / 60 >> 0} minutes and ${realTaskTimeSeconds % 60 >> 0} seconds `}/>
                <br/>
                <span>
                    out of {`${schedulerObjectDetails.mean_runtime / 60 >> 0} minutes and ${schedulerObjectDetails.mean_runtime % 60 >> 0} seconds`}
                </span>
            </>
        } else {
            return <span>
            {`${realTaskTimeSeconds / 60 >> 0} minutes and ${realTaskTimeSeconds % 60 >> 0} seconds have passed`}
        </span>
        }

    }, [schedulerObjectTimer, schedulerObjectDetails, schedulerObjectTimerInterval])

    const schedulerLogs = useMemo(() => (
        <SchedulerLogs schedulerObject={schedulerObject} schedulerEntity={schedulerEntity}/>
    ), [schedulerObject, schedulerEntity])

    useEffect(() => {
        let timerInteral = null;
        if (schedulerObject && schedulerObject.state?.is_running) {
            setSchedulerObjectTimer(new Date() - new Date(schedulerObject.state.start + "Z"));
            timerInteral = setInterval(() => {
                setSchedulerObjectTimer(new Date() - new Date(schedulerObject.state.start + "Z"));
            }, [3000]);
            setSchedulerObjectTimerInterval(timerInteral);
        }

        return () => {
            clearInterval(timerInteral);
        }
    }, [schedulerObject, setSchedulerObjectTimer])

    useEffect(() => {
        if (schedulerObjectTimer !== null && schedulerObjectDetails && schedulerObjectDetails?.mean_runtime) {
            const percentTaskDone = schedulerObjectTimer / 1000 / schedulerObjectDetails.mean_runtime * 100;
            if (percentTaskDone >= 100) {
                message.success(`Scheduler ${schedulerEntity} was done based on runtime statistic (refresh it)`);
                clearInterval(schedulerObjectTimerInterval);
                setSchedulerObjectTimer(null);
            }
        }
    }, [schedulerObjectTimer, schedulerObjectDetails, schedulerObjectTimerInterval, setSchedulerObjectTimer, schedulerEntity])

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
            cancel && cancel();
        }
    }, [])

    useEffect(() => {
        getSchedulerObjectProxy();
    }, [schedulerEntity, schedulerObjectId])

    return (
        <Spin size={"large"} spinning={loading} tip="Waiting..."
              style={!schedulerObject || !schedulerObjectDetails ? {height: "200px"} : {}}>
            {schedulerObject && schedulerObjectDetails && <div style={{marginTop: "20px"}}>
                <Row>
                    <Button type="primary"
                            style={schedulerObject.state?.is_running ? {} : {
                                backgroundColor: "green",
                                borderColor: "green"
                            }}
                            onClick={() => {
                                if (schedulerObject.state?.is_running) {
                                    message.warning(`You can not re-run still running ${schedulerEntity}`);
                                } else {
                                    runSchedulerObjectProxy();
                                }
                            }}
                            disabled={schedulerObject.state?.is_running}
                    >
                        <PlayCircleOutlined/> Run {schedulerEntity}
                    </Button>
                    <Button type="default" onClick={() => setSchedulerObjectsEditorValue(schedulerObjectId)}
                            style={{marginLeft: "20px"}}
                    >
                        <EditOutlined/> Edit {schedulerEntity}
                    </Button>
                    <Popconfirm
                        title={`Sure to delete ${schedulerEntity}?`}
                        onConfirm={deleteSchedulerObjectProxy}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button style={{marginLeft: "20px"}} type="default"><DeleteOutlined/> Delete {schedulerEntity}
                        </Button>
                    </Popconfirm>
                    <Button type="dashed" onClick={() => getSchedulerObjectProxy()}
                            style={{marginLeft: "20px"}}
                    >
                        <ReloadOutlined/> Refresh {schedulerEntity} state
                    </Button>
                    <Button type={schedulerObject?.job ? "default" : "primary"} onClick={() => setJobEditor(true)}
                            style={{marginLeft: "auto"}}
                    >
                        {schedulerObject?.job ? <><HistoryOutlined/> Reschedule</>
                            : <><HistoryOutlined/> Schedule</>}
                    </Button>
                    {schedulerObject?.job && <Button type="default" style={{marginLeft: "20px"}}
                                                     onClick={() => deleteJobProxy(schedulerObject.job.id)}
                    >
                        <PoweroffOutlined/> Cancel
                    </Button>}
                </Row>

                <Row>
                    <Typography.Paragraph type={'secondary'}
                                          style={{paddingTop: 24}}>Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </Typography.Paragraph>
                    <Descriptions title={<Typography.Title level={4} className="spas" style={{marginTop: 12}}>
                        {schedulerObject.title} {schedulerEntity}
                    </Typography.Title>}
                                  bordered
                                  style={{width: "100%"}}
                                  labelStyle={{width: "17%"}}
                                  column={2}
                    >
                        <Descriptions.Item label="ID">
                            {schedulerObject._id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            {schedulerObject.state?.is_running ?
                                <Badge color="green" status="processing" text="Running"/>
                                : <Badge color="gray" status="default" text="Idle"/>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Description" span={2}>
                            {schedulerObject?.description}
                        </Descriptions.Item>

                        {schedulerObjectDetails.mean_runtime !== null &&
                            <Descriptions.Item label="Mean execution time" span={2}>
                                {`${schedulerObjectDetails.mean_runtime / 60 >> 0} minutes and ${schedulerObjectDetails.mean_runtime % 60 >> 0} seconds`}
                            </Descriptions.Item>}
                        {schedulerObjectProgress !== null && <Descriptions.Item label="Estimated run time" span={3}>
                            {schedulerObjectProgress}
                        </Descriptions.Item>}
                        <Descriptions.Item label="Last run" span={2}>
                            {dddMMMDDHHmm(schedulerObject.state?.start)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last finish" span={2}>
                            {dddMMMDDHHmm(schedulerObject.state?.finish)}
                        </Descriptions.Item>
                        {schedulerObject.state.result && <Descriptions.Item label="Last status" span={3}>
                            <Tag color={schedulerObject.state.result === "error" ? "red" : "green"}
                                 style={{fontSize: "1.1em"}}>
                                {schedulerObject.state.result}
                            </Tag>
                        </Descriptions.Item>}
                        {schedulerObject.state?.done_tasks && schedulerObject.state.done_tasks.length > 0 &&
                            <Descriptions.Item label="Done tasks" span={3}>
                                {schedulerObject.state.done_tasks.map((task, inx) => {
                                    return <Row key={inx}>
                                        <Col span={1}>
                                            {inx + 1}.
                                        </Col>
                                        <Col span={8}>
                                            <Link to={`administration/scheduler/tasks/${task.task_id}`}>
                                                Task ID: {task.task_id}
                                            </Link>
                                        </Col>
                                        <Col span={2}>
                                            <Tag color={task.status === "error" ? "red" : "green"}
                                                 style={{fontSize: "1.1em"}}>
                                                {task.status}
                                            </Tag>
                                        </Col>
                                    </Row>
                                })}

                            </Descriptions.Item>}
                        <Descriptions.Item label="Next run" span={2}>
                            {dddMMMDDHHmm(schedulerObject.job?.next_run_time)}
                        </Descriptions.Item>
                    </Descriptions>
                </Row>
                <Tabs defaultActiveKey="1" style={{marginTop: "15px"}}>
                    <Tabs.TabPane tab={schedulerEntity === "task" ? "Task code" : "Queue tasks"} key="1">
                        {schedulerEntity === "task" ? <Editor
                                height="400px"
                                defaultLanguage="python"
                                value={schedulerObject.formula}
                                theme="vs-light"
                                options={{readOnly: true}}
                            />
                            : <TasksOrQueuesTable schedulerEntity="task" mode="notfull"
                                                  task_ids={schedulerObject.tasks}/>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Execution details" key="2">
                        <Descriptions title="Last execution status" bordered
                                      style={{width: "100%"}}
                                      labelStyle={{width: "12%"}}
                        >
                            <Descriptions.Item label="Status" span={3}>
                                {schedulerObject?.state?.result ?
                                    <Tag color={schedulerObject.state.result === "error" ? "red" : "green"}
                                         style={{fontSize: "1.1em"}}>
                                        {schedulerObject.state.result}
                                    </Tag>
                                    :
                                    <Tag color="gray" style={{fontSize: "1.1em"}}>
                                        Was never run before
                                    </Tag>
                                }
                            </Descriptions.Item>
                            {schedulerObject?.state?.error && <Descriptions.Item label="Error" span={3}>
                                <span style={{color: "rgb(217, 63, 63)"}}>{schedulerObject.state.error}</span>
                            </Descriptions.Item>}
                            {schedulerObject.state.traceback && <Descriptions.Item label="Traceback" span={3}>
                                <div style={{maxHeight: "300px", overflow: "auto"}}>
                                    <pre style={{whiteSpace: "pre-wrap"}}>
                                        {schedulerObject.state.traceback}
                                    </pre>
                                </div>
                            </Descriptions.Item>}
                        </Descriptions>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Logs" key="3">
                        {schedulerLogs}
                    </Tabs.TabPane>
                </Tabs>
                <TaskQueueEditor schedulerEntity={schedulerEntity} addModalOption={schedulerObjectsEditorValue}
                                 setAddModal={setSchedulerObjectsEditorValue}
                                 afterFinish={() => getSchedulerObjectProxy()} fullSchedulerObject={schedulerObject}/>
                <JobEditor addModalOption={jobEditor}
                           setAddModal={() => {
                               setJobEditor(false);
                           }}
                           afterFinish={() => {
                               getSchedulerObjectProxy();
                           }}
                           schedulerObjectBase={{
                               name: schedulerObject.title,
                               _id: schedulerObject._id,
                               entity: schedulerEntity
                           }}
                           jobSettings={schedulerObject?.job}
                />
            </div>}
        </Spin>
    )
}

export default TaskQueueFullInfo