import axios from "axios";
import {message} from 'antd';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SCHEDULER_ROOT,
    timeout: 300000,
    withCredentials: true
});

axiosInstance.interceptors.request.use(config => {
    config.headers["X-Referer"] = window.location.href;
    return config
})

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.code === "ERR_CANCELED") {
            return Promise.reject({cancel: true})
        }
        if (error.response.status > 400) {
            if (error.response.status === 401) {
                //document.location.href = document.location.href
                //return
            }
            // message.error("Server internal error: " + data.message);
        }
        return Promise.reject(error)
    }
);


export default axiosInstance;
