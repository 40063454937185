import {Card, Col, Flex, Row, Space, theme, Typography} from "antd";
import ProposalsCard from "@holistico/welcome/items/ProposalsCard";
import SectorCard from "@holistico/welcome/items/SectorCard";
import React from "react";
import FactorPerformanceCard
    from "@holistico/welcome/items/FactorPerformanceCard";
import MacroStatsCard from "@holistico/welcome/items/MacroStatsCard";
import MacroChartCard from "@holistico/welcome/items/MacroChartCard";
import MacroOverview from "@holistico/welcome/items/MacroOverview";
import ScreenerCard from "@holistico/welcome/items/ScreenerCard";


const {useToken} = theme;


const AdvisorIndex = () => {
    const {token} = useToken();

    return <Row gutter={[token.paddingSM, token.paddingSM]} align={"stretch"}>
        <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            <Row
                gutter={[token.paddingSM, token.paddingSM]}
                // style={{height: "100%"}}
            >
                <Col xs={24} sm={24} md={10} lg={24} xl={24}>
                    <ProposalsCard/>
                </Col>
                <Col xs={24} sm={24} md={14} lg={24} xl={24}>
                    <ScreenerCard/>
                </Col>
            </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={18}>
            <Flex vertical gap={token.paddingSM}>
                <MacroStatsCard/>
                <Row gutter={[token.paddingSM, token.paddingSM]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                        <MacroChartCard
                            title={null}
                            fromDate={"1980-01-31"}
                            columns={[
                                "vg_spread_1yr",
                                "sl_spread_1yr",
                                "equal_vs_cap_weighted_spread_1yr",
                                "valuation_spread_sp500_sp400",
                                "yield_curve_slope_10yr_vs_3m",
                            ]}
                            defaultColumn={"vg_spread_1yr"}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <SectorCard/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <MacroOverview/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                         <FactorPerformanceCard/>
                    </Col>
                </Row>
            </Flex>
        </Col>
    </Row>
}

export default AdvisorIndex
