/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Collapse} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {FILTER_DISPLAY_SETTINGS} from "@components/screener/settings";
import NumericFilter from "@components/screener/params/NumericFilter";
import AllocationFilter from "@components/screener/params/AllocationFilter";
import ChoiceFilter from "@components/screener/params/ChoiceFilter";
import CollectionFilter from "@components/screener/params/CollectionFilter";
import Localizator from "@global/Localizator";


const FiltersPanelItem = ({item}) => {
    const name = item.name
    const type = item.type

    return <>
        {type === 'choice' && name === 'collection'
            &&
            <CollectionFilter item={item}/>
        }
        {type === 'choice' && name !== 'collection'
            &&
            <ChoiceFilter item={item} showMax={item.showMax}/>
        }
        {type === 'numeric'
            &&
            <NumericFilter item={item}/>
        }
        {type === 'allocation'
            &&
            <AllocationFilter item={item}/>
        }
    </>
}


const FiltersPanel = ({}) => {
    const {entityGroup, settings} = useScreenerContext()
    const displaySettings = FILTER_DISPLAY_SETTINGS[entityGroup]
    const expanded = Object.keys(settings.filters)
        .filter(key => (displaySettings[key]?.expanded))

    return <Card style={{borderColor: "#e3e3e3"}}>
        <Collapse
            defaultActiveKey={expanded}
            ghost
            items={
                Object.keys(displaySettings).map(key => {
                    return {
                        key: key,
                        label: Localizator.t(`screener.${entityGroup}.${key}`),
                        children: <FiltersPanelItem
                            item={{
                                ...settings.filters[key],
                                ...displaySettings[key]
                            }}
                        />
                    }
                })
            }
        />
    </Card>
}


export default FiltersPanel;
