/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {Card, Col, Divider, Row, Statistic, Typography} from "antd";
import './Portfolio.less';
import ValueFormatter from "@global/ValueFormatter";

const {Paragraph, Text, Title} = Typography;

const {Meta} = Card;
/**
 *
 * @param rId id for the chart
 * @param link route to when clicked
 * @param calculated_values riskPlannedClient.assetDetails or riskPlannedClient.proposedAssetDetails
 * @param title
 * @param loading if is loading currently
 * @returns {JSX.Element} Card containing basic portfolio summary info
 * @constructor
 */
const PortfolioStatsMini = ({calculated_values = {}, loading}) => {
    useEffect(() => {


    }, []);

    return (
        <div className={"portfolio-stats"}>
            <Divider/>
            <Row style={{padding: 16}}>
                <Col span={6}>
                    <Statistic title="# of positions" value={calculated_values['unique_pos_count']}
                               precision={0}/>
                </Col>
                <Col span={6}>
                    <Statistic title={"Distr. rate"}
                               value={ValueFormatter.percent(calculated_values['annual_dividend'] / 100)}/>
                </Col>
                <Col span={6}>
                    <Statistic title="Expense ratio"
                               value={ValueFormatter.percent(calculated_values['expense_ratio'] / 100)}/>
                </Col>
                <Col span={6}>
                    <Statistic title="Proj. return"
                               value={ValueFormatter.percent(calculated_values?.risk?.projected_return_1yr / 100)}/>
                </Col>
            </Row>
            <Divider/>
        </div>
    );
}

export default PortfolioStatsMini;
