import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const auth = (setUser, callback) => {
    axiosInstance.get("/auth")
        .then(({data}) => {
            if (data.authorize_url) {
                window.location.href = data.authorize_url;
            } else {
                setUser(data);
                callback();
            }
        })
        .catch((response) => {
            console.log(response);
            callback();
        })
};

const logout = () => {
    axiosInstance.get("/auth/logout",
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            if (data.logout_url) {
                window.location.href = data.logout_url;
            }
            console.log(data);
        })
        .catch(({response}) => {
            console.log(response);
        })
};

export {auth, logout, cancel}
