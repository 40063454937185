import dayjs from "dayjs";

let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const getDatesForInterval = (start, stop) => {
    let now = start.clone().endOf('month'), dates = [];
    while (now.isSameOrBefore(stop)) {
        dates.push(now.format('YYYY-MM-DD'));
        now = now.add(1, 'months').endOf('month');
    }
    return dates
}

const filterArrayWithSkipper = (array, skipper) => {
    if (skipper === 0) {
        return array
    }
    let filteredArray = []
    let iter = 0
    for (let valueNumber in array) {
        if (iter % skipper !== 0) {
            iter++;
            continue;
        }
        filteredArray.push(array[valueNumber])
        iter++
    }
    return filteredArray

}


const getPerformanceMonthSkip = (performanceFactor) => {
    switch (performanceFactor) {
        case 'future_total_return_1m':
            return 0
        case 'future_total_return_1q':
            return 3
        case 'future_total_return_2q':
            return 6
        case 'future_total_return_1yr':
            return 12
        default:
            return 0
    }
}

const toCronString = (cronJson) => {
    let cronString = ""
    const cronFields = ['day_of_week', 'month', 'day', 'hour', 'minute'].reverse()
    for (let field of cronFields) {
        let fieldValue = cronJson[field] ? cronJson[field] : "*"
        cronString += fieldValue + " "
    }
    return cronString.trim()
}

const fromCronString = (cronString) => {
    let cronJson = {}
    const cronFields = ['day_of_week', 'month', 'day', 'hour', 'minute'].reverse()
    const cronValues = cronString.split(" ")
    if (cronValues.length != cronFields.length) {
        throw new Error("Wrong string given")
    }

    for (let i = 0; i < cronFields.length; i++) {
        let field = cronFields[i]
        let value = cronValues[i]
        cronJson[field] = value
    }

    return cronJson
}

export {filterArrayWithSkipper, getDatesForInterval, getPerformanceMonthSkip, toCronString, fromCronString}
