import {formatterMapper} from "@components/supervision/report_constructor/tree_utils/utils";

const flagRow = ({red_flag_name, metric_value, metric_limit, metric_units, metric_decimals_precision}) => {
    const formatter = formatterMapper(metric_units);
    return `    - "${red_flag_name}" problem. 
Client value is ${formatter(metric_value, metric_decimals_precision)}, 
but the allowed limit is set to ${formatter(metric_limit, metric_decimals_precision)}.`;
}

const mailtoSupervision = (clientInfo, represetativeInfo, flagInfo) => {
    const br = "%0D%0A";
    const synopsis = `please, pay attention to ${clientInfo.client_name.replaceAll("&", "and")}.`;

    let flagsBody = "";
    if (flagInfo instanceof Array) {
        flagInfo.map(flag => {
            if (!flag.red_flag_raised) {
                return
            }
            flagsBody += flagRow({
                red_flag_name: flag.red_flag_name, 
                metric_value: flag.metric.value, 
                metric_limit: flag.limit.type === "global" ? flag.limit.global : flag.limit.client,
                metric_units: flag.metric.units,
                metric_decimals_precision: flag.metric.decimals_precision
        }) + br;
        })
    } else if (flagInfo instanceof Object) {
        flagsBody = flagRow(flagInfo) + br;
    }

    const end = `Please, get back to me when you figure this out.`;
    const body = `Hi ${represetativeInfo.name},${br}${synopsis}${br}${br}${flagsBody}${br}${end}`;
    return `mailto:${represetativeInfo.email}?subject=SPAS Supervision warning&body=` + body;
}

export {mailtoSupervision}
