/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import ValueFormatter from "@global/ValueFormatter";
import {Pie} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
// import Localizator from "@global/Localizator";
import {Spin, Empty} from "antd";
import { normalizeWeight } from './utils';

const FISectorPie = ({allocation, loading = false}) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    useEffect(() => {
        if (!allocation) {
            setData([])
            return
        }
        const normilizedAlloc = normalizeWeight(allocation);
        let chartData = Object.keys(normilizedAlloc).map(key => {
            return {
                label: key,
                value: normilizedAlloc[key]
            }
        })

        chartData.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });
        setData(chartData)
        // const values = Object.values(allocation)
        // const labels = Object.keys(allocation)

        // const max_value = Math.max(...values)
        // const max_label = labels[values.indexOf(max_value)]

        // const statistics = {
        //     title: {
        //         formatter: () => ValueFormatter.percent(max_value)
        //     },
        //     content: {
        //         formatter: () => Localizator.t('stock.' + max_label),
        //     }
        // }

        // const b_statistics = {
        //     title: 'Sector',
        //     content: false
        // }
        console.debug(`allocation is ${JSON.stringify(chartData)}`)


        const chartConfig = {
            title: 'Sector Distribution',
            angleField: 'value',
            data: chartData,
            colorField: 'label',
            color: (labelObj) => {
                let color = ChartStyles.fiSectorColor(labelObj.label)
                return color;
            },
            pieStyle: (labelObj) => {
                return {
                    stroke: allocation[labelObj.label] >= 0 ? "white" : "red",
                    lineWidth: 2
                }
            },
            legend: false,
            label: {
                type: 'outer',
                fontFamily: 'Red Hat Display',
                content: (object, value, smth) => {
                    return `${object.label} ${ValueFormatter.percent(allocation[object.label])}`
                },
            },
            meta: {
                percent: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.percent(v)
                    }
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.label, value: ValueFormatter.percent(allocation[datum.label])};
                },
            },
            responsive: true,
            radius: 0.8,
            innerRadius: 0.7,
            statistic: {
                title: false,
                content: {formatter: () => 'Sector'}
            }
        }

        setConfig(chartConfig)


    }, [allocation])

    return <Spin size="large" spinning={loading}>
        {data.length > 0
        ? 
        <Pie {...config}/> 
        : 
        <Empty style={{margin: "15px 0"}} description="No super sector information"/>
        }
    </Spin>
}

export default FISectorPie;
