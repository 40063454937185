/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Spin, Empty} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import {Pie} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import { normalizeWeight } from './utils';

const AssetClassPie = ({allocation, loading}) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    useEffect(() => {
        if (!allocation) {
            return
        }

        const normilizedAlloc = normalizeWeight(allocation);
        let chartData = Object.keys(normilizedAlloc).map(key => {
            return {
                label: key,
                value: normilizedAlloc[key]
            }
        })

        chartData.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });
        setData(chartData)
        const values = Object.values(allocation)
        const labels = Object.keys(allocation)

        const max_value = Math.max(...values)
        const max_label = labels[values.indexOf(max_value)]

        const statistics = {
            title: {
                // formatter: () => ValueFormatter.softPercent(Math.round(max_value)),
                formatter: () => ValueFormatter.softPercent(max_value),
                offsetY: -6
            },
            content: {
                formatter: () => max_label,
                offsetY: -6
            },
        }
        console.debug(`normilizedAlloc is ${JSON.stringify(chartData)}`)


        const chartConfig = {
            title: 'Asset Class Distribution',
            angleField: 'value',
            data: chartData,
            colorField: 'label',
            color: (labelObj) => {
                let color = ChartStyles.securityTypeColor(labelObj.label)
                return color;
            },
            pieStyle: (labelObj) => {
                return {
                    stroke: allocation[labelObj.label] >= 0 ? "white" : "red",
                    lineWidth: 2
                }
            },
            label: {
                //type: 'spider',
                style: {
                    fontSize: 13,
                    fontFamily: 'Red Hat Display'
                },
                content: (valueObeject, b, index) => {
                    return `${valueObeject.label}, ${ValueFormatter.percent(allocation[valueObeject.label])}`
                }
            },
            legend: false,
            responsive: true,
            radius: 0.8,
            innerRadius: 0.7,
            statistic: statistics,
            meta: {
                percent: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.percent(v)
                    }
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.label, value: ValueFormatter.percent(allocation[datum.label])};
                },
            },
            // annotation: [
            //     {
            //         type: 'centralText',
            //         onActive: false,
            //     },
            // ]
        }

        // let workingConfig = {
        //     title: 'Asset Class Distribution',
        //     angleField: 'value',
        //     data: chartData,
        //     colorField: 'label',
        //     legend: false,
        //     responsive: true,
        //     radius: 0.8,
        //     innerRadius: 0.7,
        // }

        setConfig(chartConfig)


    }, [allocation])

    return <Spin size="large" spinning={loading}>
        {data.length > 0
        ? 
        <Pie {...config}/> 
        : 
        <Empty style={{margin: "15px 0"}} description="No asset class information"/>
        }
    </Spin>
}

export default AssetClassPie;
