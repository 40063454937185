import {Badge, List, Table, Typography} from "antd";
import React, {useEffect, useState} from "react";
import ValueFormatter from "@global/ValueFormatter";
import {getTipString} from "@components/supervision/client/ClientRecommendations";

const CustomTable = ({data}) => {
    if (!data || data.length == 0) {
        return null
    }
    let keys = Object.keys(data[0])
    return <Table size={"small"} pagination={false}
                  columns={keys.map(key => {
                      return {
                          title: key,
                          key: key,
                          dataIndex: key,
                      }
                  })} dataSource={data}/>
}
const PositionsTable = ({positions}) => {
    return <Table size={"small"} pagination={false}
                  columns={[
                      {
                          title: 'Ticker',
                          dataIndex: 'ticker',
                          key: 'ticker',
                      },
                      {
                          title: 'Name',
                          dataIndex: 'name',
                          key: 'name',
                      }, {
                          title: 'Weight',
                          dataIndex: 'weight',
                          key: 'weight',
                          render: (value => ValueFormatter.percent(value))
                      }, {
                          title: 'Mk. Value',
                          dataIndex: 'market_value',
                          key: 'market_value',
                          render: (value => ValueFormatter.compactCurrency(value))
                      }
                  ]} dataSource={positions}/>
}

const ExtendedPositionsTable = ({positions, additional_columns = []}) => {
    let columns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (value => ValueFormatter.percent(value))
        }, {
            title: 'Mk. Value',
            dataIndex: 'market_value',
            key: 'market_value',
            render: (value => ValueFormatter.compactCurrency(value))
        }
    ]

    for (let column of additional_columns) {
        columns.push({
            title: column.label,
            dataIndex: column.name,
            key: column.name,
            render: (value) => {
                if (column.units == '%') {
                    return ValueFormatter.int_percent(value)
                } else if (column.type == 'numeric') {
                    return ValueFormatter.number(value)
                } else {
                    return value
                }
            }
        })
    }

    return <Table size={"small"} pagination={false}
                  columns={columns} dataSource={positions}/>
}


const MetricsResultDetails = ({details = null, tips = null}) => {
    const [stringTips, setStringTips] = useState([])
    if (!details) return null

    useEffect(() => {
        let premTips = []

        if (tips != null) {
            for (let tip of tips) {
                premTips.push(getTipString(tip))
            }
        }
        setStringTips(premTips)
    }, [tips])

    return <div>
        <List
            dataSource={stringTips}
            renderItem={(item => {
                return <List.Item>
                    <Badge color={'red'} text={item}/>
                </List.Item>
            })}/>
        <Typography.Text className={'text'}>{details.name}</Typography.Text>
        {details.type == 'positions' && <PositionsTable positions={details.value}/>}
        {details.type == 'positions_extended' &&
            <ExtendedPositionsTable positions={details.value} additional_columns={details.additional_columns}/>}
        {details.type == 'table' && <CustomTable data={details.value}/>}


    </div>
}

export default MetricsResultDetails
