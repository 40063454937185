/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {Col, Input, InputNumber, Row, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";

const {TextArea} = Input;
const {Paragraph, Title} = Typography;

const QStepTwo = ({financialParams = {}, financialParamsChanged}) => {
    useEffect(() => {
        console.log(financialParams)
    }, [financialParams]);

    return (
        <div>
            <Title level={3}>What is your financial status</Title>
            <Paragraph style={{fontSize: 18}}>The answers to the following questions will help us establish criteria for
                an appropriate
                portfolio and define factors that may impact your investment strategy.</Paragraph>
            <Row gutter={[16, 16]} className={'retirement-options'} style={{paddingTop: 24}}>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Primary investment amount</label>
                    <InputNumber
                        placeholder="Investment amount" formatter={ValueFormatter.currency}
                        name={"investment_amount"}
                        step={1000}
                        value={financialParams['investment_amount']}
                        onChange={value => financialParamsChanged('investment_amount', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Year of birth</label>
                    <InputNumber
                        placeholder="Birth year"
                        name={"birth_year"}
                        value={financialParams['birth_year']}
                        onChange={value => financialParamsChanged('birth_year', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Retirement year</label>
                    <InputNumber
                        placeholder="Retirement year" name={"retirement_year"}
                        value={financialParams['retirement_year']}
                        onChange={value => financialParamsChanged('retirement_year', value)}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className={'retirement-options'} style={{paddingTop: 24}}>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Martial Status</label>
                    <Input
                        placeholder="Martial status"
                        name={"mstatus"}
                        value={financialParams['mstatus']}
                        onChange={e => financialParamsChanged('mstatus', e.target.value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Working status</label>
                    <Input
                        placeholder="Working status"
                        name={"wstatus"}
                        value={financialParams['wstatus']}
                        onChange={e => financialParamsChanged('wstatus', e.target.value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Health status</label>
                    <Input
                        placeholder="Health status" name={"hstatus"}
                        value={financialParams['hstatus']}
                        onChange={e => financialParamsChanged('hstatus', e.target.value)}
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]} className={'retirement-options'} style={{paddingTop: 24}}>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Life expectancy</label>
                    <InputNumber
                        placeholder="Life expectancy" name={"life_expectancy"}
                        value={financialParams['life_expectancy']}
                        onChange={value => financialParamsChanged('life_expectancy', value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Mandated restrictions</label>
                    <Input
                        placeholder="Mandated restrictions"
                        name={"restrictions"}
                        value={financialParams['restrictions']}
                        onChange={e => financialParamsChanged('restrictions', e.target.value)}
                    />
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <label>Current fee, %</label>
                    <InputNumber
                        placeholder="Current fee" name={"current_fee"}
                        value={financialParams['current_fee']}
                        step={0.1}
                        onChange={value => financialParamsChanged('current_fee', value)}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className={'retirement-options'} style={{paddingTop: 24}}>
                <Col span={24}>
                    <TextArea value={financialParams['additional']} autoFocus={true} rows={3} showCount={true}
                              maxLength={300}
                              onChange={e => financialParamsChanged('additional', e.target.value)}
                              placeholder={"Additional info about employment, major expenses etc"}/>
                </Col>
            </Row>
        </div>

    )
}

export default QStepTwo;
