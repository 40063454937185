/* eslint-disable react/prop-types */
import React, {useContext, useState} from 'react';
import {ApiTwoTone, DeleteOutlined, ExclamationCircleTwoTone} from '@ant-design/icons';
import {Col, message, Modal, Popconfirm, Row, Tooltip} from 'antd';
import ValueFormatter from '@global/ValueFormatter';
import MarketValueInput from '@root/components/portfolio/table/MarketValueInput';
import {PortfolioEntryContext} from "@root/components/portfolio/contexts/PortfolioEntryContext";
import PortfolioAssetSearch from './new/PortfolioAssetSearch';
import SecurityForm from "./SecurityForm";
import '@root/components/portfolio/Portfolio.less';


const PortfolioPositionsTable = ({expand, positions, hideAdd}) => {
    const {addPosition, removePosition, updatePosition} = useContext(PortfolioEntryContext);
    const [editedStock, setEditedStock] = useState(null);

    const deleteStock = (stockIndex) => {
        const removedPosition = positions[stockIndex]
        removePosition(removedPosition)
    }

    const updateStock = (updated) => {
        setEditedStock(null)
        if (updated == null) {
            return
        }
        console.log(updated)
        //just closed
        updatePosition(updated)
    }

    const changeDeposit = (newDeposit, index) => {
        console.log('deposit', newDeposit, index)
        if (newDeposit !== 0 && isFinite(newDeposit)) {
            let currentStock = positions[index];
            if (newDeposit < 100) {
                message.error(`$100 is minimum amount allowed`);
                return
            }
            currentStock.market_value = newDeposit
            updatePosition(currentStock)
        } else {
            message.error("Bad value");
        }
    }

    const getStocksClass = (stock) => {
        if (stock.riskScore > 60) {
            return 'score-risk-negative'
        } else {
            return 'score-risk-positive'
        }
    }

    const getAdditionalIcon = (stock) => {
        if (stock.proxy) {
            return <ApiTwoTone onClick={() => setEditedStock(stock)}/>
        } else if (stock.status === 'not found' || (stock.status === 'user-defined' && !stock.proxy)) {
            return <ExclamationCircleTwoTone twoToneColor="#fa541c" onClick={() => setEditedStock(stock)}/>
        } else {
            return null
        }
    }

    const getName = (asset) => {
        if (asset.proxy && !asset.name || asset.name == '') {
            return asset.proxy.label
        } else {
            return asset.name
        }
    }

    const getTooltip = (stock) => {
        if (stock.proxy) {
            return `We use ${stock.proxy.label} as a proxy for this asset.`
        } else {
            return null
        }
    }

    const fixRiskScore = (stock) => {
        console.log('click')
    }

    return expand
        ?
        <>
            {!hideAdd
                &&
                <PortfolioAssetSearch
                    onFinish={(a) => {
                        console.log("addPosition", a);
                        addPosition(a);
                    }}
                    tableView={true}
                    allowCreate={true}
                    placeholder={"Search asset to add..."}
                />
            }
            {positions.map((stock, index) => {
                return (
                    <Row
                        key={index}
                        type="flex"
                        gutter={[8, 0]}
                        justify="start"
                        align="middle"
                        className="portfolio-position-row"
                    >
                        <Col md={1} lg={1} sm={0} xs={0} className="remove-icon">
                            <Popconfirm
                                title="Sure to remove the position?"
                                onConfirm={() => deleteStock(index)}
                                onVisibleChange={() => console.log('visible change')}
                            >
                                <DeleteOutlined style={{cursor: 'pointer'}}/>
                            </Popconfirm>
                        </Col>
                        <Col md={4} lg={4} sm={8} xs={8}
                             className={'ticker ' + getStocksClass(stock)}
                             onClick={fixRiskScore}
                        >
                            {stock.ticker}
                        </Col>
                        <Col md={11} lg={11} sm={0} xs={0}>
                            {getName(stock)}
                            <Tooltip title={getTooltip(stock)}>
                                {getAdditionalIcon(stock)}
                            </Tooltip>
                        </Col>
                        <Col md={5} lg={5} sm={7} xs={7} style={{textAlign: 'right'}}>
                            <MarketValueInput
                                cellIndex={index}
                                market_value={stock.market_value}
                                applyNewValue={!stock.multilot ? changeDeposit : null}
                            />
                        </Col>
                        <Col span={3}>
                            <div style={{textAlign: 'right', paddingRight: 3}}>
                                {ValueFormatter.percent(stock.weight)}
                            </div>
                        </Col>
                    </Row>
                );
            })}
            <Modal
                title="Edit security"
                open={editedStock != null}
                width={750}
                // closable={false}
                maskClosable={true}
                onCancel={v => {
                    console.log("CANCEL")
                    setEditedStock(null)
                }}
                footer={[]}
                className={'portfolio-select-modal'}
            >
                <SecurityForm
                    handleSubmit={updateStock}
                    asset={editedStock}
                />
            </Modal>
        </>
        :
        <></>;
}

export default PortfolioPositionsTable;
