import React, {useEffect, useRef, useState} from "react";
import {Col, message, Row, Select, Tooltip, Typography} from "antd";
import {getGlobalConfigs, getGlobalConfigsDetails} from "@API/supervision";
import { defaultConfigKey } from "./GlobalConfigs";
import "./GlobalConfigs.less";

const LimitsTooltip = ({limits, details}) => (
    <>
        <Row><b>Config limits</b></Row>
        {Object.keys(details).map((flagKey, inx) => {
            return <Row key={inx}>
                <Col>{details[flagKey].name} </Col>
                <Col style={{marginLeft: "auto"}}>
                    {details[flagKey].limit.type === "choice" ? 
                    details[flagKey].limit.options.filter(option => option.key === limits[flagKey])[0].name :
                    limits[flagKey]}
                    {details[flagKey].limit.units === "%" && details[flagKey].limit.type !== "choice" && "%"}
                </Col>
            </Row>
        })}
    </>
)

const ConfigSelector = ({setSelectedConfig}) => {
    const [configsLoading, setConfigsLoading] = useState(false);
    const [configsList, setConfigsList] = useState(null);
    const [configsDetails, setConfigsDetails] = useState(null);
    const [selectedConfigId, setSelectedConfigId] = useState(null);
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        setConfigsLoading(true);
        getGlobalConfigsDetails((data, error) => {
            if (error) {
                message.error("Error while retrieving configs details")
                return
            }
            console.log(data)
            setConfigsDetails(data)
        }, abortControllerRef.current.signal);
        getGlobalConfigs((data, error) => {
            setConfigsLoading(false);
            if (error) {
                message.error("Error while retrieving configs");
                return
            }
            setConfigsList(data);
            console.log(data)
            const configIdFromUrl = new URLSearchParams(window.location.search).get("configId");
            if (configIdFromUrl) {
                const activeConfig = data.find(config => 
                    (config.id === configIdFromUrl && !config.out_of_date)
                );
                if (activeConfig) {
                    setSelectedConfigId(activeConfig.id);
                    setSelectedConfig(activeConfig);
                    return
                }
            }
            const defaultConfigId = localStorage.getItem(defaultConfigKey);
            if (defaultConfigId) {
                const activeConfig = data.find(config => 
                    (config.id === defaultConfigId && !config.out_of_date)
                );
                if (activeConfig) {
                    setSelectedConfigId(activeConfig.id);
                    setSelectedConfig(activeConfig);
                    return
                }
            }
            const activeConfig = data.find(config => !config.out_of_date);
            if (activeConfig) {
                setSelectedConfigId(activeConfig.id);
                setSelectedConfig(activeConfig);
            } else {
                message.error("Fix out of date configs");
            }
        }, abortControllerRef.current.signal);

        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    return (
        <Select
            style={{width: "100%"}}
            loading={configsLoading}
            placeholder="Select config"
            value={selectedConfigId}
            onChange={(value) => {
                setSelectedConfig(configsList.find(config => config.id === value));
                localStorage.setItem(defaultConfigKey, value);
                setSelectedConfigId(value);
            }}
            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {configsList && configsDetails && configsList.map((config, inx) => {
                return <Select.Option key={inx} value={config.id} disabled={config.out_of_date}>
                        <Typography.Text style={{fontSize: ''}}>{config.name} {config.out_of_date && <i>(Out of date)</i>}
                            <Tooltip overlayInnerStyle={{minWidth: "260px"}}
                                     title={<LimitsTooltip details={configsDetails} limits={config.limits}/>}>
                                <sup style={{color: '#E0AD60'}}>#</sup>
                            </Tooltip>
                        </Typography.Text>
                </Select.Option>
            })}
        </Select>
    )
}

export default ConfigSelector
