/* eslint-disable react/prop-types */
import {Button, Checkbox, Col, DatePicker, Form, InputNumber, Row} from 'antd';
import React, {useEffect, useRef} from "react";
import ValueFormatter from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";


const FormItemLabel = ({text, hint}) => {
    return <>
        <span
            style={{
                // fontWeight: 600,
                // color: "#93C2F0"
        }}
        >
            {text}
        </span>
        {hint
            && <span style={{
                color: "#a2a2a2",
                marginLeft: "8px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}>
                {"(" + hint + ")"}
            </span>
        }
    </>
}


const columnSpan = {xl: 8, lg: 12, md: 12, sm: 24, xs: 24}


const RetirementOptions = ({setCharToShow}) => {
    const [form] = Form.useForm();
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext()
    const currentYear = new Date().getFullYear();
    const initialValues = {
        life_expectancy: 95,
        monthly_savings: 0,
        monthly_withdrawals: 0,
        inflation_rate: 3.0,
        current_fee: 0.0,
        proposed_fee: 0.0,
    }

    useEffect(() => {
        form.setFieldsValue(proposal?.questionnaire);
    }, [proposal])

    const onFinish = (values) => {
        updateQuestionnaire(values, false)
    };

    return <Form
        form={form}
        layout="vertical"
        style={{margin: "0 12px"}}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
    >
        <Row gutter={24}>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text="Birth year"/>}
                    name="birth_year"
                    rules={[{ required: true, message: 'Please enter birth year!' }]}
                >
                   <InputNumber
                        placeholder="Birth year"
                        style={{width: "100%"}}
                    />
                </Form.Item>
             </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text="Retirement year"/>}
                    name="retirement_year"
                    rules={[
                        { required: true, message: 'Please enter retirement year!' }
                    ]}
                >
                    <InputNumber
                        placeholder="Retirement year"
                        style={{width: "100%"}}
                    />
                </Form.Item>
            </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text="Life expectancy"/>}
                    name="life_expectancy"
                     rules={[
                         { required: true, message: 'Please enter life expectancy!' },
                         ({ getFieldValue }) => ({
                             validator(_, value) {
                                 let birth_year = getFieldValue('birth_year')
                                 if (!value || !birth_year || value + birth_year > currentYear) {
                                     return Promise.resolve();
                                 }
                                 return Promise.reject(new Error('Life expectancy must exceed the current year!'));
                             },
                         }),
                     ]}
                >
                    <InputNumber
                        placeholder="Life expectancy"
                        style={{width: "100%"}}
                    />
                </Form.Item>
            </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text={"Savings"} hint={"monthly, before retirement"}/>}
                    name="monthly_savings"
                    rules={[{ required: true, message: 'Please enter savings!' }]}
                >
                    <InputNumber
                        step={1000}
                        placeholder="Savings"
                        formatter={ValueFormatter.currency}
                        style={{width: "100%"}}
                    />
                </Form.Item>
             </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text={"Withdrawals"} hint={"monthly, after retirement"}/>}
                    name="monthly_withdrawals"
                    rules={[{ required: true, message: 'Please enter withdrawals!' }]}
                >
                    <InputNumber
                        step={1000}
                        placeholder="Withdrawals"
                        formatter={ValueFormatter.currency}
                        style={{width: "100%"}}
                    />
                </Form.Item>
            </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text={"Inflation rate"} hint={"annual, %"}/>}
                    name="inflation_rate"
                    rules={[{ required: true, message: 'Please enter inflation rate!' }]}
                >
                    <InputNumber
                        step={0.1}
                        placeholder="Inflation"
                        style={{width: "100%"}}
                    />
                </Form.Item>
            </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text="Management fee, current" hint={"%"}/>}
                    name="current_fee"
                    rules={[{ required: true, message: 'Please enter current management fee!' }]}
                >
                    <InputNumber
                        step={0.1}
                        placeholder="Management fee"
                        style={{width: "100%"}}
                    />
                </Form.Item>
             </Col>
            <Col {...columnSpan}>
                <Form.Item
                    label={<FormItemLabel text="Management fee, proposed" hint={"%"}/>}
                    name="proposed_fee"
                    rules={[{ required: true, message: 'Please enter proposed management fee!' }]}
                >
                    <InputNumber
                        step={0.1}
                        placeholder="Management fee"
                        style={{width: "100%"}}
                    />
                </Form.Item>
            </Col>
        </Row>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
            <Button
                type="primary"
                htmlType="submit"
                size="large"
            >
                Refresh charts
            </Button>
            <Checkbox.Group
                options={[
                    { label: 'Current', value: 'original' },
                    { label: 'Proposed', value: 'proposed' },
                ]}
                defaultValue={['original', 'proposed']}
                onChange={(val)=>{setCharToShow(val)}}
            />
        </div>
    </Form>
}

export default RetirementOptions
