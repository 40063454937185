import axiosInstance from "./APIClient";


const calculateRetirement = (retirementData, successCallback, errorCallback) => {
    let url = `/retirement`

    axiosInstance.post(url, retirementData)
        .then((response) => {
            successCallback(response.data);
        })
        .catch(({response}) => {
            console.log(response)
            errorCallback(response);
        })
};

export {
    calculateRetirement
}
