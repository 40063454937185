/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Spin, Empty} from "antd";
import {Bar} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";


const SectorBar = ({allocation, proposalAllocation = null, loading = false, legend = true}) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    const heightCalculator = () => {
        let height;
        let _allocation;
        let _proposalAllocation;
        if (Object.keys(allocation).length == 0 && proposalAllocation && Object.keys(proposalAllocation).length > 0) {
            _allocation = proposalAllocation;
            _proposalAllocation = null;
        } else {
            _allocation = allocation;
            _proposalAllocation = proposalAllocation;
        }
        if (_proposalAllocation && Object.keys(_proposalAllocation).length > 0) {
            if (Math.max(Object.keys(_allocation).length, Object.keys(_proposalAllocation).length) > 2) {
                height = Math.max(Object.keys(_allocation).length, Object.keys(_proposalAllocation).length) * 60;
                return `${height}px`
            } else {
                height = (Object.keys(_allocation).length + Object.keys(_proposalAllocation).length) * 70;
                return `${height}px`
            }
        }
        height = Object.keys(_allocation).length * 65;
        return `${height}px`
    }

    useEffect(() => {
        if (!allocation) {
            return
        }
        let sectorMaxLabelLen = 0;
        let maxValue = 0;
        let chartData = Object.keys(allocation).map(key => {
            if (sectorMaxLabelLen < key.length) {
                sectorMaxLabelLen = key.length;
            }
            if (allocation[key] > maxValue) {
                maxValue = allocation[key];
            }
            return {
                label: key,
                value: allocation[key],
                type: 'Current Portfolio'
            }
        })

        if (chartData == data) {
            return
        }

        if (proposalAllocation) {
            Object.keys(proposalAllocation).forEach(key => {
                if (sectorMaxLabelLen < key.length) {
                    sectorMaxLabelLen = key.length;
                }
                if (proposalAllocation[key] > maxValue) {
                    maxValue = proposalAllocation[key]
                }
                const allocValue = {
                    label: key,
                    value: proposalAllocation[key],
                    type: 'Benchmark'
                }
                chartData.push(allocValue)
            })
        }

        if (maxValue < 0.9) {
            maxValue += 0.1
        }

        chartData.sort((a, b) => {
            return a?.label.localeCompare(b?.label)
        });

        setData(chartData)
        const chartConfig = {
            title: 'Sector Distribution',
            data: chartData,
            isGroup: true,
            color: ['#E0AD60', '#3196B2'],
            maxBarWidth: 25,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            // padding: 'auto',
            renderer: 'svg',
            legend: legend ? {position: 'top'} : false,
            padding: [25, 55, 23, Math.max(6.7 * sectorMaxLabelLen, 50)],
            label: {
                position: 'right',
                offsetX: -5,
                // layout: [
                //     // {
                //     //     type: 'interval-adjust-position',
                //     // },
                //     {
                //         type: 'adjust-color',
                //     },
                // ],
            },
            marginRatio: 0,
            meta: {
                value: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.percent(v)
                    }
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.type, value: ValueFormatter.percent(datum.value)};
                },
            },
        }

        setConfig(chartConfig)


    }, [allocation, proposalAllocation])

    return <Spin size="large" spinning={loading}>
        {data.length > 0
        ? 
        <div style={{height: heightCalculator()}}>
            <Bar {...config}/>
        </div>
        : 
        <Empty style={{margin: "15px 0"}} description="No sector information"/>
        }
    </Spin>
}

export default SectorBar;
