/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import ValueFormatter from "@global/ValueFormatter";
import {Heatmap} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import {Empty, List} from "antd";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const SizeStyleHeat = ({allocation}) => {
    const [data, setData] = useState([])
    const [config, setConfig] = useState({
        data: [],
        xField: 'style',
        yField: 'size',
        render: 'svg',
    });

    useEffect(() => {
        if (!allocation) {
            return
        }
        const defaults = ChartStyles.size_style_defaults();
        let allocProxy = {...defaults, ...allocation}

        console.debug(allocProxy)
        let _data = Object.keys(allocProxy).map(key => {
            let size = key.split(" ")[0]
            let style = key.split(" ")[1]
            return {
                size: size,
                style: style,
                label: key,
                value: allocProxy[key]
            }
        })
        setData(_data)
        console.debug(`allocation is ${JSON.stringify(_data)}`)

        const _config = {
            data: _data,
            xField: 'style',
            yField: 'size',
            render: 'svg',
            colorField: 'value',
            color: [
                "#eaf5f7",
                "#d6eaf0",
                "#c1e0e8",
                "#add5e0",
                "#98cbd9",
                "#83c0d1",
                "#6fb6c9",
                "#5aabc1",
                "#46a1ba",
                "#3196B2"
            ],
            meta: {
                'style': {
                    values: ['Value', 'Blend', 'Growth']
                },
                'size': {
                    values: ['Small', 'Medium', 'Large']
                },
            },
            xAxis: {
                position: 'top'
            },
            padding: [55, 12, 55, 55],
            label: {
                style: {
                    fill: '#000',
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                },
                content: (valueObeject, b, index) => {
                    return `${ValueFormatter.oneDigitPercent(valueObeject.value)}`
                }
            },
            tooltip: {
                showMarkers: false,
                formatter: (datum) => {
                    return {name: datum.size + " " + datum.style, value: ValueFormatter.oneDigitPercent(datum.value)};
                },
            }
        }

        setConfig(_config)

    }, [allocation])


    return <div className={"fade-in-200"}>
        {allocation && Object.keys(allocation).length > 0
            ?
            <>
                <Heatmap {...config}/>
            </>
            :
            <NoDataPlaceholder description={"No size-style information"}/>
        }
    </div>
}

export default SizeStyleHeat;
