/* eslint-disable react/prop-types */
import React, {useRef, useState} from 'react';
import {Form, Input} from "antd";
import {grey} from '@ant-design/colors';
import {SearchOutlined} from "@ant-design/icons";
import {useScreenerContext} from "@hooks/ScreenerContext";
import useFormInstance from "antd/es/form/hooks/useFormInstance";


const SearchFilter = ({}) => {
    const {setSearchParams} = useScreenerContext()
    const form = useFormInstance()
    const value = Form.useWatch('search', form);
    const inputRef = useRef()

    const onSubmit = (e) => {
        setSearchParams(prev => {
            prev.delete("search")
            if (e.target.value && e.target.value.trim()) {
                prev.append("search-pinned", e.target.value.trim())
            }
            return prev;
        })
        form.setFieldValue(['search'], null)
    }

    return <>
        <Form.Item
            name="search"
            colon={true}
            style={{width: "300px", marginBottom: 8}}
        >
            <Input
                placeholder={"Search by ticker or name"}
                suffix={<SearchOutlined/>}
                onPressEnter={onSubmit}
                ref={inputRef}
            />
        </Form.Item>
        {value && value[0].trim()
            &&
            <div style={{
                height: 20,
                marginTop: -8,
                marginBottom: -12,
                color: grey[1]
            }}>
                Press Enter to pin search
            </div>
        }
    </>
}


export default SearchFilter;
