import axiosInstance from "../APIClient";

const getProposal = async (id) => {
    let url = `/portfolio/model?full=false`
    return await axiosInstance.get(`client/${id}`)
};

const getAiThoughts = async (clientId, type) => {
    if (type == 'json') {
        return await getAiJson(clientId)
    }
    let url = `/client/ai/${type}/${clientId}`
    return await axiosInstance.get(url)
}

const getAiJson = async (id) => {
    let url = `/ai-conversation/structured_comments/${id}`
    return await axiosInstance.get(url)
}

export {getProposal, getAiJson, getAiThoughts}
