/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Card, Input, Select, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import StaticInfo from "@global/StaticInfo";

const {TextArea} = Input;
const {Paragraph, Text, Title} = Typography;
const {Meta} = Card;
const {Option} = Select;

const FineTuneOption = ({title, additionalClass = 'default', riskScore, investment_amount, selected, hheight}) => {
    const [maxPercent, setMaxPercent] = useState(0)
    const [minPercent, setMinPercent] = useState(0)
    const className = `risk-improve-range ${additionalClass}`
    const outerClassName = `improve-div ${additionalClass}`

    useEffect(() => {
        let percentData = StaticInfo.riskReturns[riskScore]
        setMinPercent(percentData['Lower Bound'])
        setMaxPercent(percentData['Upper Bound'])
    }, [riskScore]);

    return (
        <div className={outerClassName} onClick={() => selected(riskScore)}>
            <Title>{title}</Title>
            <Text
                className={"positive"}>{'+' + ValueFormatter.percent(maxPercent)} / {'+' + ValueFormatter.compactCurrency(maxPercent * investment_amount)}</Text>
            <div className={className}/>
            <Text
                className={"negative"}>{ValueFormatter.percent(minPercent)} / {'-' + ValueFormatter.compactCurrency(minPercent * investment_amount * -1)}</Text>
        </div>
    )
}

export default FineTuneOption;
