import {Col, Row, Affix, Drawer} from "antd";
import React, {useState} from "react";
import AiChat from "@holistico/ai/chat/AiChat";
import Notes from "@holistico/ai/notes/Notes";
import MinimizedButtonsPanel from "@holistico/ai/sidebar/MinimizedButtonsPanel";
import "./AiSidebarContainer.less"
import {AiChatContextContextProvider} from "@hooks/AiChatContext";


export const AiSidebarContainer = ({
                                       proposalId = null,
                                       chatType = "proposal",
                                       autoMessage=false,
                                       minimizeAi=false,
                                       minimizeNotes=false,
                                       hideAi=false,
                                       hideNotes=false,
                                       marginTop=38,
                                       children
                                   }) => {
    const [aiMinimized, setAiMinimized] = useState(minimizeAi)
    const [notesMinimized, setNotesMinimized] = useState(minimizeNotes)
    const [aiExpand, setAiExpand] = useState(false)
    const [notesExpand, setNotesExpand] = useState(false)
    const showSideBar = (!aiMinimized && !hideAi) || (!notesMinimized && !hideNotes)

    return (
        <AiChatContextContextProvider
            proposalId={proposalId}
            type={chatType}
            autoMessage={autoMessage}
        >
            <Row gutter={[24, 24]}>
                <Col span={showSideBar ? 16 : 24}>
                    {children}
                </Col>
                <Col span={showSideBar ? 8 : 0}>
                    <div style={{height: marginTop}}/>
                    <Affix offsetTop={16}>
                        <div className={"ai-sidebar"}>
                            {!aiMinimized && !hideAi
                                &&
                                <AiChat
                                    height={notesMinimized ? "calc(100vh - 500px)" : "calc(50vh - 150px)"}
                                    setAiMinimized={setAiMinimized}
                                    setAiExpand={setAiExpand}
                                />
                            }
                            {!notesMinimized && !hideNotes
                                &&
                                <Notes
                                    rows={aiMinimized ? 18 : 12}
                                    setNotesMinimized={setNotesMinimized}
                                    setNotesExpand={setNotesExpand}
                                />
                            }
                        </div>
                    </Affix>
                </Col>
            </Row>
            <MinimizedButtonsPanel
                aiMinimized={aiMinimized}
                notesMinimized={notesMinimized}
                hideAi={hideAi}
                hideNotes={hideNotes}
                onAiClick={() => {
                    setAiMinimized(false)
                }}
                onNotesClick={() => {
                    setNotesMinimized(false)
                }}
            />
            {aiExpand
                &&
                <Drawer
                    onClose={() => setAiExpand(false)}
                    open={aiExpand}
                    width={640}
                    rootClassName={"holistico ai-sidebar"}
                    title="AI Chat"
                >
                    <AiChat
                        autoMessage={autoMessage}
                        height={"calc(100vh - 155px)"}
                    />
                </Drawer>

            }
            {notesExpand
                &&
                <Drawer
                    onClose={() => setNotesExpand(false)}
                    open={notesExpand}
                    width={640}
                    rootClassName={"holistico ai-sidebar"}
                    title={"Notes"}
                >
                    <Notes/>
                </Drawer>
            }
        </AiChatContextContextProvider>
    );
}


export default AiSidebarContainer;
