/* eslint-disable react/prop-types */
import {Button, Card, Col, InputNumber, List, message, Modal, Row, Select, Spin, Typography} from 'antd';
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import './MarketAssumptions.less';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import {getMarketAssumptions} from "@API/configuration";
import ValueFormatter from "@global/ValueFormatter";
import {saveAssumptions} from "@API/clients";

const {Meta} = Card;

const {Option} = Select;

const {Paragraph, Text, Title} = Typography;

/*
    {
        "assumptions" : {
            "rf": 0.0375,
            "stocks_return": 0.0
            "bonds_return": 0.0
        }
    }
 */
// eslint-disable-next-line react/prop-types
const MarketAssumptions = ({
                               visible = false,
                               disabled = false,
                               title = "Market Assumptions"
                           }) => {

    const {market_assumptions, client, loadData} = useContext(SpasClientContext);
    const [assumptionsProxy, setAssumptionsProxy] = useState({})
    const [modalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [availableAssumptions, setAvailableAssumptions] = useState([])

    useEffect(() => {
        getMarketAssumptions((ass, error) => {
            if (!error) {
                console.debug(ass)
                //ass.push(assumptionsProxy)
                setAvailableAssumptions(ass)
                setAssumptionsProxy(market_assumptions)
            } else {
                message.error('Failed to load default assumptions')
            }
            console.log(market_assumptions)
        })
    }, [market_assumptions]);

    const handleProxyChange = (name, value) => {
        setAssumptionsProxy({...assumptionsProxy, [name]: value})
    }

    const useBlock = (assumption) => {
        setAssumptionsProxy(assumption)
        console.log('now assumptionsProxy is ' + JSON.stringify(assumptionsProxy))
    }

    const proxyDone = () => {
        Modal.confirm({
            title: 'Save new assumptions',
            content: 'This will recalculate all the data (client and proposed portfolios) using the values set and can take about a minute.',
            onOk: () => {
                saveClientAssumptions()
            }
        });
    }

    const saveClientAssumptions = () => {
        if (loading) {
            return
        }
        setLoading(true)
        saveAssumptions(client._id, assumptionsProxy, (response, error) => {
            console.log('loading is ' + loading)
            if (!error) {
                message.success("The assumptions were changed!", 15)
            } else {
                message.error("Failed to change market assumptions!", 15)
            }
            loadData()
            hide()
            setLoading(false)
        })
    }

    const getBlockClass = (blockAssumptions) => {
        if (blockAssumptions?.rf == assumptionsProxy?.rf) {
            if (blockAssumptions?.stocks_return == assumptionsProxy?.stocks_return) {
                if (blockAssumptions?.bonds_return == assumptionsProxy?.bonds_return) {
                    return 'selected-block'
                }
            }
        }
        return 'selectable-block'
    }

    const tick = () => {
        if (modalVisible) {
            hide()
        } else {
            show()
        }
    }

    const hide = () => {
        setAssumptionsProxy(market_assumptions)
        setModalVisible(false)
    }

    const show = () => {
        setModalVisible(true)
    }

    return (<>
        <Button type={'dashed'} onClick={tick} disabled={disabled} loading={loading}>{title}</Button>
        <Modal
            title={<Typography.Title style={{marginBottom: 0, paddingBottom: 0}} className={'spas'} level={3}>Market
                Assumptions, 1 year</Typography.Title>}
            subtitle={client?.full_name}
            closable={false}
            width={800}
            open={modalVisible}
            onOk={() => proxyDone()}
            onCancel={() => hide()}
        >
            <Spin size={"large"} spinning={loading} tip="Applying the new assumptions...">
                <Title level={4} className={'spas'}>Current Values</Title>
                <Row gutter={[16, 16]} className={'market-assumptions'}>
                    <Col span={8}>
                        <label>Stocks Return, %</label>
                        <InputNumber
                            placeholder="Stocks Return, %"
                            name={"stocks_return"}
                            step={0.1}
                            value={assumptionsProxy?.stocks_return}
                            onChange={value => handleProxyChange('stocks_return', value)}
                        />
                    </Col>
                    <Col span={8}>
                        <label>Bonds Return, %</label>
                        <InputNumber
                            placeholder="Bonds return, %"
                            name={"bonds_return"}
                            step={0.1}
                            value={assumptionsProxy?.bonds_return}
                            onChange={value => handleProxyChange('bonds_return', value)}
                        />
                    </Col>
                    <Col span={8}>
                        <label>Risk-free return, %</label>
                        <InputNumber
                            placeholder="Risk-free return, %"
                            name={"rf"}
                            step={0.01}
                            value={assumptionsProxy?.rf}
                            onChange={value => handleProxyChange('rf', value)}
                        />
                    </Col>
                </Row>
                <Title level={4} className={'spas'}>Available assumptions</Title>
                <Row gutter={[16, 16]} className={'market-assumptions'}>
                    {availableAssumptions.map(assumption => {
                        return (<Col key={assumption.label} span={8}>
                            <Card hoverable bordered={true} onClick={() => {
                                useBlock(assumption)
                            }} className={getBlockClass(assumption)}
                                  title={<Title level={5}
                                                style={{
                                                    marginBottom: 0,
                                                    paddingBottom: 0
                                                }}>{assumption.label}</Title>}>
                                <List>
                                    <List.Item key={'stocks'}>
                                        <List.Item.Meta
                                            title={'Stocks return'}
                                            description={ValueFormatter.percent(assumption.stocks_return / 100)}
                                        />
                                    </List.Item>

                                    <List.Item key={'bonds'}>
                                        <List.Item.Meta
                                            title={'Bonds return'}
                                            description={ValueFormatter.percent(assumption.bonds_return / 100)}
                                        />
                                    </List.Item>

                                    <List.Item key={'rf'}>
                                        <List.Item.Meta
                                            title={'Risk-free return'}
                                            description={ValueFormatter.percent(assumption.rf / 100)}
                                        />
                                    </List.Item>
                                </List>
                            </Card></Col>)
                    })}
                </Row>
            </Spin>
        </Modal>
    </>);
}

export default MarketAssumptions
