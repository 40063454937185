const TutorialVideo = ({}) => {
    return <iframe width="560"
                   height="315"
                   src="https://www.youtube.com/embed/E5whOOjbKbQ"
                   title="Wealth monitor"
                   frameborder="0"
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                   allowFullScreen>

    </iframe>
}

export default TutorialVideo