import React, {useCallback, useEffect, useState} from "react";
import {cancel, deleteUser, editUser, getUser} from "@API/users";
import {useNavigate} from 'react-router-dom';
import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, KeyOutlined} from '@ant-design/icons';

import "./Users.less";
import ChangePasswordModal from "@components/admin_panel/users/ChangePasswordModal";
import {getAuthConfiguration, getAuthConfigurationAsync} from "@API/configuration";
import {useRequest, useUpdateEffect} from "ahooks";
import {useAuth} from "@hooks/AuthHook";


const {Option} = Select;
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 12},
};

const ProfileDetails = ({user, getUser}) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [form] = Form.useForm();
    const {data: configuration, loading: configLoading} = useRequest(() => getAuthConfigurationAsync())

    useUpdateEffect(() => {
        form.setFieldsValue(user)
    }, [user])
    
    const retrieveUserDetails = useCallback(() => {
        getUser();
        setTimeout(() => setLoading(false), 500)
    }, [getUser])

    const userFormSave = useCallback((values) => {
        console.log(values)
        setLoading(true);
        let editedUser = Object.assign(user, values);
        editUser(editedUser, (resp, error) => {
            if (error) {
                setLoading(false);
                message.error(resp);
                return
            }
            message.success(`${user.email} was updated successfully`);
            retrieveUserDetails();
        })
    }, [user])

    const removeUser = useCallback(() => {
        deleteUser(user.id, (resp, error) => {
            if (error) {
                message.error(resp);
                return
            }

            message.success(`${user.email} was deleted successfully`);
            if (user.id == auth.user.id) {
                console.warn("Deleted muself!")
                auth.logout()
            } else {
                navigate("/organization");
            }
        })
    }, [user])

    const StaticDetails = () => {
        return <Descriptions layout={"horizontal"} column={1}>
            <Descriptions.Item
                label="Last seen"
                key={"last_activity"}>{new Date(user.last_activity).toLocaleString("en-US")}</Descriptions.Item>
            <Descriptions.Item
                label="Last edit"
                key={"last_modification"}>{new Date(user.last_modification).toLocaleString("en-US")}</Descriptions.Item>
        </Descriptions>
    }

    return (
        <Spin size="large" spinning={loading}>
            {user ?
                <div style={{width: '100%'}}>
                    <Card loading={loading}>
                        <Row>
                            <Col span={4}>
                                <Avatar size={160} src={user.avatar}
                                        style={{backgroundColor: '#E0AD60', fontSize: 50}}>U</Avatar>
                                <div
                                    style={{width: 160, height: 160, borderRadius: "50%"}} className="ant-image-mask"
                                    onClick={() => console.log("kek")}
                                >&nbsp;</div>
                            </Col>
                            <Col span={12}>
                                <Typography.Title level={3} copyable={true
                                }>{user.email}</Typography.Title>
                                <StaticDetails/>
                            </Col>
                        </Row>
                        <Form {...layout}
                              initialValues={user}
                              form={form}
                              name="user-details"
                              onFinish={userFormSave}
                              labelAlign={"left"}
                              size={"large"}
                              requiredMark={false}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item name={['details', 'meta', 'full_name']} label="Full Name"
                                               rules={[{required: true}]}>
                                        <Input/>

                                    </Form.Item>
                                    {auth.isHead() && <Form.Item name={'associates'} label={"Associates"}
                                                                 tooltip={"The user has access to this colleagues data"}>
                                        <Select mode="tags" maxTagCount={'responsive'} disabled={!auth.isHead()}>
                                            {configuration?.data?.available_associates.map(user => {
                                                return <Option value={user} key={user}/>
                                            })}
                                        </Select>
                                    </Form.Item>}
                                    {auth.isHead() && <Form.Item
                                        name={['details', 'roles']}
                                        label={`Roles`}
                                        rules={[{required: true}]}
                                    >
                                        <Select mode="tags" maxTagCount={'responsive'}>
                                            {configuration?.data?.available_roles.map(role => {
                                                return <Option value={role} key={role}/>
                                            })}
                                        </Select>
                                    </Form.Item>}
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>

                    {auth.authType() !== 'auth0' && <Row style={{marginTop: "20px"}}>
                        <Alert
                            message="Change password"
                            style={{width: "100%", fontSize: "1.2em"}}
                            showIcon
                            icon={<KeyOutlined/>}
                            type="error"
                            action={
                                <Button danger onClick={() => setPasswordModal(true)} style={{width: 100}}>
                                    Change
                                </Button>
                            }
                        />
                    </Row>}
                    <Alert
                        message="Delete user"
                        style={{width: "100%", fontSize: "1.2em", marginTop: "20px"}}
                        showIcon
                        icon={<DeleteOutlined/>}
                        type="error"
                        action={
                            <Popconfirm
                                title="Sure to remove the user?"
                                onConfirm={removeUser}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{width: 100}}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        }
                    />
                    <ChangePasswordModal user={user} hide={() => setPasswordModal(false)} open={passwordModal}/>
                </div>
                :
                <div style={{height: "400px"}}></div>
            }
        </Spin>
    )
}

export default ProfileDetails
