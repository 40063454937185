import {createContext} from 'react';

export const PortfolioEntryContext = createContext({
    rowPortfolio: null,
    marketValue: null,
    calculated_values: {},
    destroy: null,
    changeMarketValue: null,
    refreshFromOrion: null,
    changeTitle: null,
    addPosition: null,
    updatePosition: null,
    removePosition: null,
});
