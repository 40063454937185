import {Typography} from "antd";
import {useAuth} from "@hooks/AuthHook";

const highlight = (lastMessageContent) => {
    const auth = useAuth();
    let phrasesToHighlight = auth.globalValues['available_sectors'].concat(auth.globalValues['available_styles']);
    phrasesToHighlight = phrasesToHighlight.concat(['concentration', 'Risk', 'Stress tests', 'Fixed Income'])

    let replacedHtml = lastMessageContent
    for (let pth of phrasesToHighlight) {
        replacedHtml = replacedHtml.replaceAll(pth, "<mark>" + pth + "</mark>")
    }

    console.debug(replacedHtml)

    return <span
        dangerouslySetInnerHTML={{
            __html: replacedHtml
        }}/>
}

const getTextByKey = (textKey) => {
    switch (textKey) {
        case "points":
            return "Give me 5 points to discuss with the client"
        case "prosandcons":
            return "Write 5 pros and 5 cons for the client portfolio"
        case "impression":
            return "What's your overall client impression?"
        case "suggestions":
            return "What changes can enhance the portfolio?"
        case "vs_macro":
            return "What you can say about the client's portfolio in the context of the current economic situation?"
    }
}

export {highlight, getTextByKey}
