import React, {useEffect, useState} from "react";
import {Alert, Button, Col, message, Row, Spin, Switch} from "antd";
import {CloudDownloadOutlined} from '@ant-design/icons';

import {createReport, getReportBasics} from "@API/report";
import ReportPresetList from "./ReportPresetList";
import ReportSections from "./ReportSections";
import {downloadFile} from "@global/FileDownloader";

import "./PortfolioReport.less"


const ReportBuilder = ({client, reportClass, getMainPortfolios, getBenchmarkPortfolios}) => {
    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState({title: 'None', sections: [], 'key': null});
    const [availableSections, setAvailableSections] = useState([]);
    const [presetSections, setPresetSections] = useState([]);
    const [reportOptions, setReportOptions] = useState({show_volatility: true})
    const [reportCreationLoading, setReportCreationLoading] = useState(false);

    const loadReportBasics = () => {
        getReportBasics(reportClass, basicData => {
            let existingPresets = basicData.presets;
            let sectionKeys = []
            let allSectionObjects = basicData.available_sections.map(section => {
                sectionKeys.push(section.key)
                return {
                    ...section,
                    checked: false
                };
            })


            existingPresets.push({
                title: 'Custom',
                key: 'custom',
                description: "You can create your own template and customize sections",
                sections: sectionKeys
            })
            setPresets(existingPresets)
            setAvailableSections(allSectionObjects);
            setSelectedPreset(basicData.presets[0]);
        });
    }

    useEffect(() => {
        loadReportBasics()
    }, [])

    useEffect(() => {
        let sections = selectedPreset.sections.map(section_name => {
            return {
                ...availableSections.find(({key}) => key === section_name),
                checked: true,
                disabled: false
            }
        });
        sections.push(...availableSections.filter(section => !selectedPreset.sections.includes(section.key)))
        setPresetSections(sections);
    }, [selectedPreset])

    const selectPreset = (value) => {
        let preset = presets.filter(preset => preset.key === value)[0];
        setSelectedPreset(preset);
    }


    const createReportOnClick = () => {
        // if (!portfolioIds) {
        //     message.error("You must select or create portfolios to be able to generate the report");
        //     return
        // }
        setReportCreationLoading(true);
        let [main_ids, main_calculated_values] = [null, null];
        let [benchmark_ids, benchmark_calculated_values] = [null, null];
        const mainPortfolios = getMainPortfolios();
        const benchmarkPortfolios = getBenchmarkPortfolios();
        if (mainPortfolios !== null) {
            main_ids = mainPortfolios.main_ids;
            main_calculated_values = mainPortfolios.main_calculated_values;
        } else {
            message.warning("Note: you have not chosen a client portfolio");
        }
        if (benchmarkPortfolios !== null) {
            benchmark_ids = benchmarkPortfolios.benchmark_ids;
            benchmark_calculated_values = benchmarkPortfolios.benchmark_calculated_values;
        }
        createReport({
                main_ids: main_ids,
                benchmark_ids: benchmark_ids,
                main_calculated_values: main_ids !== null && main_ids.length > 1 ? main_calculated_values : null,
                benchmark_calculated_values: benchmark_ids !== null && benchmark_ids.length > 1 ? benchmark_calculated_values : null,
                client_id: client ? client._id : null,
                sections: presetSections.filter(section => section.checked).map(section => section.key),
                show_volatility: reportOptions.show_volatility
            }, (response, error) => {
                if (error) {
                    message.error("Error while creating the report!");
                    console.error(error);
                    setReportCreationLoading(false);
                    return
                }
                let file_type = response.headers['content-type'];
                let file_name;
                if (client) {
                    file_name = `${client.full_name} (${new Date().toLocaleString()}).pdf`;
                } else {
                    if (mainPortfolios && benchmarkPortfolios) {
                        file_name = `Playground Report ${mainPortfolios.name} vs ${benchmarkPortfolios.name} (${new Date().toLocaleString()}).pdf`;
                    } else if (mainPortfolios) {
                        file_name = `Playground Report ${mainPortfolios.name} (${new Date().toLocaleString()}).pdf`;
                    } else if (benchmarkPortfolios) {
                        file_name = `Playground Report ${benchmarkPortfolios.name} (${new Date().toLocaleString()}).pdf`;
                    } else {
                        file_name = `Playground Report (${new Date().toLocaleString()}).pdf`;
                    }

                }
                downloadFile(response.data, file_name, file_type, () => {
                    setReportCreationLoading(false);
                })
            }
        )
    }
    return (
        <Spin style={{height: 800}} spinning={reportCreationLoading} tip="Report generation in progress..."
              size={"large"}>
            {(client && Object.keys(client.questionnaire).length <= 1) &&
                <Row style={{marginBottom: "10px"}}>
                    <Col span={24}>
                        <Alert
                            message="No questionnaire filled"
                            description="If you want to use all report sections related to client you need to fill in the questionnaire"
                            type="warning"
                            showIcon
                        />
                    </Col>
                </Row>}
            <Switch title={"Show volatility info?"} checked={reportOptions.show_volatility}
                    checkedChildren="Volatility info" unCheckedChildren="Volatility info skipped"
                    onChange={(checked) => {
                        setReportOptions({...reportOptions, show_volatility: checked})
                    }}
            />
            <Row gutter={12}>
                <Col span={12}>
                    <ReportPresetList
                        presets={presets}
                        selected={selectedPreset}
                        selectPreset={selectPreset}
                        reloadPresets={loadReportBasics}
                    />
                    <Button type="primary" style={{marginTop: 12}}
                            onClick={createReportOnClick}><CloudDownloadOutlined/> Generate report</Button>
                </Col>
                <Col span={12}>
                    <ReportSections
                        reportClass={reportClass}
                        sections={presetSections}
                        updateSections={setPresetSections}
                        refreshData={loadReportBasics}
                    />
                </Col>
            </Row>
        </Spin>
    )
}

export default ReportBuilder
