/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Empty, Row, Spin, Tabs, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import AssetClassPie from "@components/chart/AssetClassPie";
import GeneralInfo from './GeneralInfo';
import EquityDistribution from './EquityDistribution';
import FixedIncomeDistribution from './FixedIncomeDistribution';
import parse from 'html-react-parser';

const {Title} = Typography;
const {TabPane} = Tabs;

const generalInfo = {
    "ticker": {
        title: "Ticker"
    },
    "security_type": {
        title: "Security type"
    },
    "global_category": {
        title: "Global category"
    },
    "fi_credit_rating_alloc": {
        title: "Credit rating"
    },
    "fi_super_sector_alloc": {
        title: "Fixed income sector"
    },
    "fi_effective_maturity_alloc": {
        title: "Effective maturity"
    },
    "mkval_avg": {
        title: "Average market cap.",
        formatter: (v) => ValueFormatter.compactCurrency(v * 10 ** 6)
    },
    "stocks_weight": {
        title: "Stocks weight",
        formatter: (v) => ValueFormatter.percent(v, 0)
    },
    "bonds_weight": {
        title: "Bonds weight",
        formatter: (v) => ValueFormatter.percent(v, 0)
    }
}

const IndexInfo = ({fullAsset, loadOpenApiData}) => {
    const [aiText, setAiText] = useState("");
    const [loading, setLoading] = useState(false)
    const run = () => {
        setLoading(true)
        loadOpenApiData((text) => {
            setAiText(text)
            setLoading(false)
        })
    }

    useEffect(() => {
        setAiText("")
    }, [fullAsset])

    const memoGeneralInfo = useMemo(() => {
        return <GeneralInfo fullAsset={fullAsset} generalInfo={generalInfo}/>
    }, [fullAsset])

    const memoFixedIncomeDistribution = useMemo(() => {
        return <FixedIncomeDistribution fullAsset={fullAsset}/>
    }, [fullAsset])

    const memoEquityDistribution = useMemo(() => {
        return <EquityDistribution fullAsset={fullAsset} generalInfo={generalInfo}/>
    }, [fullAsset])

    if (fullAsset) {
        return (
            <Card loading={loading}>
                {memoGeneralInfo}
                {Object.keys(fullAsset.asset_class_alloc_dist).length > 0 &&
                    <>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Title level={4}>Asset class</Title>
                                <AssetClassPie allocation={fullAsset.asset_class_alloc_dist} loading={false}/>
                            </Col>
                        </Row>
                    </>
                }
                <br/>
                <div>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Equity dist." key="1">
                            {memoEquityDistribution}
                        </TabPane>
                        <TabPane tab="Fixed income dist." key="2">
                            {memoFixedIncomeDistribution}
                        </TabPane>
                        <TabPane tab={<>&nbsp;AI<sup style={{color: 'red'}}>*</sup></>} key={"3"}>
                            <Button onClick={run} disabled={loading}>Get AI impression</Button>
                            <Spin spinning={loading} tip={"Talking to AI"}>
                                <Typography.Paragraph>
                                    {aiText.split("\n\n").map(par => {
                                        return <Typography.Paragraph>
                                            {parse(par)}
                                        </Typography.Paragraph>
                                    })
                                    }
                                </Typography.Paragraph>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
        )
    } else {
        return (
            loading ?
                <Card loading={loading}></Card>
                :
                <Empty description={'No asset selected'}/>
        )
    }
}

export default IndexInfo;
