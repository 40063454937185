import axiosInstance from "./APIClient";

const getUserLogs = (email, skip, handler, abortSignal) => {
    let url = `/logs/user/${email}`

    axiosInstance.get(url,
    {   
        params: {skip: skip},
        // cancelToken: new axios.CancelToken(function executor(c) {
        //     cancel = c;
        // })
        signal: abortSignal
    }).then(({data}) => {
        handler(data);
    })
    .catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
};

const getUserStatistics = (email, handler, abortSignal) => {
    let url = `/logs/user/${email}/statistics`

    axiosInstance.get(url,
    {   
        // cancelToken: new axios.CancelToken(function executor(c) {
        //     cancel = c;
        // })
        signal: abortSignal
    }).then(({data}) => {
        handler(data);
    })
    .catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
};

const getSchedulerLogs = (entity, id, skip, sort, filters, handler, abortSignal) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/logs/scheduler/${id}`

    const params = {skip: skip, sort: sort, entity: entity};
    if (filters) {
        params.filters = filters;
    }
    axiosInstance.get(url,
    {   
        params: params,
        // cancelToken: new axios.CancelToken(function executor(c) {
        //     cancel = c;
        // })
        signal: abortSignal
    }).then(({data}) => {
        handler(data);
    })
    .catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
};

const getSchedulerDetails = (entity, id, handler, abortSignal) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/logs/scheduler/${id}/details`

    axiosInstance.get(url,
    {   
        params: {entity: entity},
        // cancelToken: new axios.CancelToken(function executor(c) {
        //     cancel = c;
        // })
        signal: abortSignal
    }).then(({data}) => {
        handler(data);
    })
    .catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
};

const getSchedulerDistincts = (entity, id, handler, abortSignal) => {
    if (entity !== "task" && entity !== "queue") {
        throw new Error("Argument entity must be only [task | queue]")
    }

    let url = `/logs/scheduler/${id}/distincts`

    axiosInstance.get(url,
    {   
        params: {entity: entity},
        // cancelToken: new axios.CancelToken(function executor(c) {
        //     cancel = c;
        // })
        signal: abortSignal
    }).then(({data}) => {
        handler(data);
    })
    .catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
};

export {
    getUserLogs,
    getUserStatistics,
    getSchedulerLogs,
    getSchedulerDetails,
    getSchedulerDistincts
}