/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import ValueFormatter from "@global/ValueFormatter";
import {Heatmap} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import {Empty, Spin} from "antd";

const SizeStyleHeat = ({allocation, loading}) => {
    const [config, setConfig] = useState({
        data: [],
        xField: 'style',
        yField: 'size',
        render: 'svg',
    });

    useEffect(() => {
        if (!allocation) {
            return
        }
        const defaults = ChartStyles.size_style_defaults();
        let allocProxy = {...defaults, ...allocation}

        console.debug(allocProxy)
        let chartData = Object.keys(allocProxy).map(key => {
            let size = key.split(" ")[0]
            let style = key.split(" ")[1]
            return {
                size: size,
                style: style,
                label: key,
                value: allocProxy[key]
            }
        })

        console.debug(`allocation is ${JSON.stringify(chartData)}`)

        const chartConfig = {
            data: chartData,
            xField: 'style',
            yField: 'size',
            render: 'svg',
            colorField: 'value',
            color: [
                "#eaf5f7",
                "#d6eaf0",
                "#c1e0e8",
                "#add5e0",
                "#98cbd9",
                "#83c0d1",
                "#6fb6c9",
                "#5aabc1",
                "#46a1ba",
                "#3196B2"
            ],
            meta: {
                'style': {
                    values: ['Value', 'Blend', 'Growth']
                },
                'size': {
                    values: ['Small', 'Medium', 'Large']
                },
            },
            xAxis: {
                position: 'top'
            },
            padding: 55,
            sizeRatio: 0.5,
            label: {
                style: {
                    fill: '#000',
                    fontFamily: 'Red Hat Display'
                },
                content: (valueObeject, b, index) => {
                    return `${ValueFormatter.percent(valueObeject.value)}`
                }
            },
            tooltip: {
                showMarkers: false,
                formatter: (datum) => {
                    return {name: datum.size + " " + datum.style, value: ValueFormatter.percent(datum.value)};
                },
            }
        }
        setConfig(chartConfig)
    }, [allocation])
    return <Spin size="large" spinning={loading}>
        {allocation && Object.keys(allocation).length > 0 
        ?
        <Heatmap {...config}/> 
        : 
        <Empty style={{margin: "15px 0"}} description="No style size information"/>
        }
        </Spin>
}

export default SizeStyleHeat;
