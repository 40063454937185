import {AudioMutedOutlined, AudioOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import React, {useContext, useState} from "react";
import MuteClientModal from "@components/supervision/actions/form/MuteClientModal";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";

const ActionMuteClient = ({client, noticeDefaultVisible = false}) => {
    const {updateData} = useContext(SupervisionContext);
    const [modalOpen, setModalOpen] = useState(false);
    let mute = true

    if (client.hasOwnProperty('client_mute')) {
        mute = !client?.client_mute.muted
    } else if (client.hasOwnProperty('client_muted')) {
        mute = !client?.client_muted
    }
    return <>
        {mute ?
            <Tooltip title={"Fully mute client"}>
                <AudioMutedOutlined onClick={() => setModalOpen(true)}/>
            </Tooltip> :
            <Tooltip 
                title={"The client is muted. It does not take part in any reports by default. Push to unmute."}
                color={'gold'}
                defaultVisible={noticeDefaultVisible}
                placement={'bottom'}
            >
                <AudioOutlined style={{color: '#ccc'}}
                                                                 onClick={() => setModalOpen(true)}/>
            </Tooltip>}
        <MuteClientModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            clientId={client.client_id}
            clientName={client.client_name}
            mute={mute}
            finishAction={() => {
                //message.success("Client was muted. The changes will be seen upon next report loading.")
                updateData()
            }}
        />
    </>
}

export default ActionMuteClient
