import {Col, Row} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import React from "react";
import HStatistic from "@holistico/aux/HStatistic";
import RiskIndicator from "@components/portfolio/RiskIndicator";
import {showAi} from "@holistico/proposal/view/ai/AiSideBar";
import {NavLink} from "react-router-dom";


export const getToleranceLevelByRiskScore = (total) => {
    switch (true) {
        case ( total <= 27 ):
            return "Low"
        case ( total > 27 && total <= 41 ):
            return "Below-average"
        case ( total > 41 && total <= 55 ):
            return "Moderate"
        case ( total > 55 && total <= 60 ):
            return "Above-average"
        case ( total > 60):
            return "High"
        default:
            return "Not defined"
    }
}


const columnSpan = () => {
    if (showAi()) {
        return {xl: 4, lg: 8, md: 8, sm: 12, xs: 12}
    } else {
        return {xl: 4, lg: 4, md: 8, sm: 12, xs: 12}
    }
}
const columnStyle = null
const riskIndicatorWidth = 40


const RiskStatisticPanel = ({active=[], ...props}) => {
    const {proposal} = useHolisticoProposalContext()

    const current = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score
    const ability = proposal?.questionnaire?.risk_profile?.ability
    const behavioral = proposal?.questionnaire?.risk_profile?.behavioral
    const willingness = proposal?.questionnaire?.risk_profile?.willingness
    const proposed = proposal?.p_bucket_prop?.calculated_values?.risk?.risk_score

    return <div
        style={{
            padding: "8px 28px 16px 28px",
            borderBottom: "1px #F0F0F0 solid",
            backgroundImage: "linear-gradient(to bottom, white, white, rgba(241, 239, 236, 0.5)",
            minHeight: "94px",
        }}
        {...props}
    >
        <Row gutter={[12, 12]}>
            {proposal &&
                <>
                    <Col style={columnStyle} {...columnSpan()}>
                        <HStatistic
                            title="Current"
                            info={{
                                title: "Risk score of the client's current portfolio",
                                content: <>
                                    <a
                                        href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                                        target={"_blank"}
                                    >
                                        Read more about risk scores...
                                    </a>
                                </>
                            }}
                        >
                            <RiskIndicator
                                width={riskIndicatorWidth}
                                riskValue={current}
                                ghost={!active.includes("current")}
                            />
                        </HStatistic>
                    </Col>
                    <Col style={columnStyle} {...columnSpan()}>
                        <HStatistic
                            title="Ability"
                            info={{
                                title: "Risk score for the ability to take risk",
                                content: <div>
                                    Calculated on the questionnaire data.
                                    <br/>
                                    This risk score reflects client's financial ability
                                    <br/>
                                    to withstand declines in portfolio values.
                                    <br/><br/>
                                    <a
                                        href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                                        target={"_blank"}
                                    >
                                        Read more about risk scores...
                                    </a>
                                </div>
                            }}
                        >
                            <RiskIndicator
                                width={riskIndicatorWidth}
                                riskValue={ability}
                                ghost={!active.includes("ability")}
                            />
                        </HStatistic>
                    </Col>
                    <Col style={columnStyle} {...columnSpan()}>
                        <HStatistic
                            title="Behavioral"
                            info={{
                                title: "Risk score for the client's behavioral tolerance to risk",
                                content: <div>
                                    Based on the behavioral quiz results.
                                    <br/>
                                    Behavioral risk score measures client's
                                    <br/>
                                    subconsciousness tolerance to risk.
                                    <br/><br/>
                                    <a
                                        href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                                        target={"_blank"}
                                    >
                                        Read more about risk scores...
                                    </a>
                                </div>
                            }}
                        >
                            <RiskIndicator
                                width={riskIndicatorWidth}
                                riskValue={behavioral}
                                ghost={!active.includes("behavioral")}
                            />
                        </HStatistic>
                    </Col>
                    <Col style={columnStyle} {...columnSpan()}>
                        <HStatistic
                            title="Willingness"
                            info={{
                                title: "Risk score for the willingness to take risk",
                                content: <div>
                                    Based on the possible losses
                                    <br/>
                                    that the client agrees to accept.
                                    <br/><br/>
                                    <a
                                        href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                                        target={"_blank"}
                                    >
                                        Read more about risk scores...
                                    </a>
                                </div>
                            }}
                        >
                            <RiskIndicator
                                width={riskIndicatorWidth}
                                riskValue={willingness}
                                ghost={!active.includes("willingness")}
                            />
                        </HStatistic>
                    </Col>
                    <Col style={columnStyle} {...columnSpan()}>
                        <HStatistic
                            title="Proposed"
                            info={{
                                title: "Risk score of the proposed portfolio",
                                content: <>
                                    <a
                                        href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                                        target={"_blank"}
                                    >
                                        Read more about risk scores...
                                    </a>
                                </>
                            }}
                        >
                            <RiskIndicator
                                width={riskIndicatorWidth}
                                riskValue={proposed}
                                ghost={!active.includes("proposed")}
                            />
                        </HStatistic>
                    </Col>
                </>
            }
        </Row>
    </div>
}

export default RiskStatisticPanel;
