/* eslint-disable react/prop-types */
import React, {useContext, useMemo, useState} from 'react';
import {DatabaseOutlined} from '@ant-design/icons';
import {Button, Col, message, Modal, Row, Spin, Typography} from 'antd';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import ExpandablePortfolioRow from "@components/portfolio/table/ExpandablePortfolioRow";
import PortfolioCreator from "@components/portfolio/new/PortfolioCreator";
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import {
    clientPortfolioClone,
    clientPortfolioFromOrion,
    clientProposalsReallocate,
    createEmptyPortfolio,
    createFromFile,
    deletePortfolio
} from "@API/clientPortfolio";
import {orionRefreshMany} from "@API/portfolio";
import PortfolioStats from "@components/portfolio/PortfolioStats";
import MarketAssumptions from "@components/assumptions/MarketAssumptions";
import PortfolioWeighter from "@components/portfolio/weighter/PortfolioWeighter";

/**
 *
 * Represents Portfolios tab in menu
 *
 * @param type the type of the portfolios
 * @returns {*}
 * @constructor
 */
const ClientPortfolios = ({type}) => {
    const {client, loadData, calculated_values, proposed_calculated_values} = useContext(SpasClientContext);
    const [loadingPortfolios, setLoadingPortfolios] = useState(false);
    const [showAddPortfolio, setShowAddPortfolio] = useState(false);
    const [portfolioWeightsVisible, setPortfolioWeightsVisible] = useState(false);
    const [lockButton, setLockButton] = useState(false);

    const hideAddPortfolio = () => {
        setShowAddPortfolio(false);
    }

    const {portfoliosToUse, calculations} = useMemo(() => {
        let returner = {

        }
        if (type === "prop") {
            returner = {
                portfoliosToUse: client.proposed_portfolios,
                calculations: proposed_calculated_values
            }
        } else {
            returner = {
                portfoliosToUse: client.current_portfolios,
                calculations: calculated_values
            }
        }

        let cmv = calculated_values['market_value']
        if (cmv > 0) {
            for (let ptu of returner.portfoliosToUse) {
                ptu.weight = ptu['calculated_values']['market_value'] / cmv
            }
        }
        return returner
    }, [client, calculated_values, proposed_calculated_values, type])

    const togglePortfolioWeights = () => {
        setPortfolioWeightsVisible(!portfolioWeightsVisible)
    }

    const reallocateCapital = (save, newValues) => {
        if (!save) {
            togglePortfolioWeights()
        } else {
            console.info(newValues)
            setLoadingPortfolios(true);
            setLockButton(true);
            clientProposalsReallocate(client._id, newValues, (result, error) => {
                if (!error) {
                    console.log(result)
                    refreshClient();
                } else {
                    result && message.error('Error occurred while reallocating!');
                }
                setLoadingPortfolios(false);
                setLockButton(false);
            })
            togglePortfolioWeights()

        }
    }

    const preupdateAllOrionPortfolios = () => {
        Modal.confirm({
            title: 'Refresh ',
            content: 'This will reload the portfolios from Orion and can take about a minute.',
            onOk: () => {
                updateAllOrionPortfolios()
            }
        });
    }

    const updateAllOrionPortfolios = () => {
        const orionPortfolios = portfoliosToUse.filter(portfolio => portfolio.external_id !== null).map(portfolio => {
            return {portfolio_id: portfolio._id, orion_id: portfolio.external_id}
        })
        if (orionPortfolios.length > 0) {
            setLoadingPortfolios(true);
            setLockButton(true);
            orionRefreshMany(orionPortfolios, (result, error) => {
                if (!error) {
                    refreshClient();
                } else {
                    result && message.error('Error occurred while updating the portfolios!');
                }
                setLoadingPortfolios(false);
                setLockButton(false);
            })
        } else {
            message.warning("No portfolios to update!")
        }
    }

    const toggleAddPortfolio = (e) => {
        setShowAddPortfolio(!showAddPortfolio)
    }

    const refreshClient = () => {
        console.log('refresh client called from portfolio list!')
        loadData()
        // setLoadingPortfolios(false)
    }

    const addFromFile = (formData, callback) => {
        setLockButton(true);
        createFromFile(client._id, formData, (result, error) => {
            if (!error) {
                refreshClient();
                setShowAddPortfolio(false);
            } else {
                // setLoadingPortfolios(false);
                result && message.error(result.data.message);
            }

            if (callback) {
                callback()
            }
            setLockButton(false);
        })
    }

    const addEmpty = (postData, callback) => {
        createEmptyPortfolio(client._id, postData, (result, error) => {
            if (!error) {
                refreshClient();
                setShowAddPortfolio(false);
            } else {
                result && message.error('Portfolio creation failed!');
            }
            if (callback) {
                callback();
            }
            setLockButton(false);
            // setLoadingPortfolios(false);
        })
    }


    const addFromOrion = (orion_id, callback) => {
        // hideAddPortfolio()
        // setLoadingPortfolios(true)
        setLockButton(true);
        clientPortfolioFromOrion(client._id, orion_id, type, (result, error) => {
            if (!error) {
                refreshClient()
                setShowAddPortfolio(false);
            } else {
                // setLoadingPortfolios(false)
                result && message.error(result.data.message);
            }
            if (callback) {
                callback();
            }
            setLockButton(false);
        })
    }

    //weight is weight from total client value
    const addFromOther = (id, expand_portfolio_positions = false, weight = null, callback = () => {
    }) => {
        setLockButton(true);
        let cloneMarketValue = null
        if (weight) {
            if (calculated_values?.market_value) {
                cloneMarketValue = calculated_values['market_value'] * (weight/100)
            }
        }
        clientPortfolioClone(client._id, id, type, expand_portfolio_positions, cloneMarketValue, (result, error) => {
            if (!error) {
                refreshClient()
                setShowAddPortfolio(false);
            } else {
                // setLoadingPortfolios(false)
                result && message.error(result.data.message);
            }
            if (callback) {
                callback();
            }
            setLockButton(false);
        })
    }

    const deleteExisting = (id, callback) => {
        // setLoadingPortfolios(true)
        deletePortfolio(client._id, type, id, (result, error) => {
            if (!error) {
                message.success("Portfolio was deleted successfully");
                refreshClient();
            } else {
                // setLoadingPortfolios(false)
                console.log(result)
                result && message.error(result.data.message);
                callback();
            }
        })
    }

    return (
        <div>
            <PortfolioWeighter
                visible={portfolioWeightsVisible}
                clientTotal={calculated_values?.market_value}
                newPortfolio={null}
                availablePortfolios={portfoliosToUse}
                existingPortfolios={portfoliosToUse}
                doneCallback={reallocateCapital}
            />
            <PortfolioListContext.Provider value={{
                addFromOther: addFromOther,
                addFromOrion: addFromOrion,
                reloadContext: () => refreshClient(),
                addEmpty: addEmpty,
                addFromFile: addFromFile,
                removeOne: deleteExisting
            }}>

                <Row gutter={16}>
                    <Col md={24} lg={24} sm={24} xs={24}>
                        <Button type="primary" onClick={toggleAddPortfolio} style={{marginRight: 13, width: 200}}
                                disabled={lockButton}
                        >
                            Add portfolio
                        </Button>
                        {type == 'prop' && <Button onClick={togglePortfolioWeights}
                                disabled={lockButton}
                                loading={lockButton}
                                style={{marginRight: 13, width: 200}}
                        >
                            Reallocate capital
                        </Button>}
                        <Button onClick={preupdateAllOrionPortfolios}
                                disabled={lockButton}
                                style={{marginRight: 13, width: 200}}
                        >
                            Refresh Orion portfolios
                        </Button>
                        <MarketAssumptions title={"Change market assumptions"} disabled={lockButton}/>
                        <Modal
                            title={<Typography.Title>Add portfolio</Typography.Title>}
                            className={'portfolio-select-modal'}
                            open={showAddPortfolio}
                            width={750}
                            closable={false}
                            maskClosable={true}
                            onCancel={v => setShowAddPortfolio(false)}
                            footer={[]}
                        >
                            <PortfolioCreator hideForm={hideAddPortfolio} type={type}/>
                        </Modal>
                        {loadingPortfolios ?
                            <div style={{
                                display: "flex",
                                marginTop: "25vh",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Spin size="large" tip="Updating the portfolios..."></Spin>
                            </div>
                            :
                            <div className="portfolio-container">
                                <PortfolioStats calculated_values={calculations}/>
                                {portfoliosToUse.length > 0 ? portfoliosToUse.map((portfolio, index) => {
                                    return (<ExpandablePortfolioRow key={portfolio._id} portfolioInfo={portfolio}
                                                                    type={type}/>)
                                }) : <div className="noDataBox">
                                    <DatabaseOutlined style={{fontSize: '2em'}}/>
                                    <div>No portfolios added</div>
                                </div>}

                            </div>}
                    </Col>
                    {/*<Col md={7} lg={7} sm={24} xs={24} style={{alignItems: 'center'}}>
                        <PortfolioCardVertical calculated_values={calculated_values}/>
                    </Col>*/}
                </Row>
            </PortfolioListContext.Provider>
        </div>
    );
}

export default ClientPortfolios;
