import {Checkbox, Input, Tooltip} from "antd";
import {
    FileTextOutlined,
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {
    useDebounce,
    useUpdateEffect,
} from "ahooks";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {setRelatedInfo} from "@API/clients";
import AiSideBarItem from "@holistico/ai/sidebar/AiSideBarItem";
import "./Notes.less"


const Notes = ({rows, setNotesMinimized, setNotesExpand}) => {
    const {id, notes, setNotes, notesInReport, setNotesInReport} = useHolisticoProposalContext()
    const [currentMessage, setCurrentMessage] = useState(notes)
    const debouncedContent = useDebounce(currentMessage, {wait: 1000});

    const updateNotesInDb = (value) => {
        if (notes === value) {
            return
        }
        if (!id) {
            return
        }
        setRelatedInfo(id, "notes", value, (result, error) => {
            if (error) {
                console.error("notes not updated")
            } else {
                console.log("notes updated")
            }
            setNotes(currentMessage)
        })
    }

    useUpdateEffect(() => {
        updateNotesInDb(debouncedContent)
    }, [debouncedContent])

     useUpdateEffect(() => {
         let merged = (notes ?? "") + "\n" + (currentMessage ?? "")
         if (merged === "\n") {
             merged = null
         }
         setCurrentMessage(merged)
         updateNotesInDb(merged)
    }, [id]);

    useEffect(() => {
        if (!currentMessage) {
            setCurrentMessage(notes)
        }
    }, [notes]);

    return <AiSideBarItem
        title={"Notes"}
        icon={<FileTextOutlined style={{
            color: "#e0ad60",
            fontSize: 20,
        }}/>}
        onMinimize={() => setNotesMinimized(true)}
        onExpand={() => setNotesExpand(true)}
    >
        <div className={"notes"}>
            <Input.TextArea
                rows={rows}
                value={currentMessage}
                onChange={(e) => setCurrentMessage(e.target.value)}
                placeholder={"Notes about portfolios, client's employment, major expenses, etc."}
                bordered={false}
            />
            <div className={"notes-bottom-panel"}>
                <Tooltip
                    title='Include notes in the PDF report as "Client description" section'>
                    <Checkbox
                        checked={notesInReport}
                        onChange={(e) => setNotesInReport(e.target.checked)}
                    >
                        Include in report
                    </Checkbox>
                </Tooltip>
            </div>
        </div>
    </AiSideBarItem>
}


export default Notes;
