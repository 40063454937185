/* eslint-disable react/prop-types */
import React from 'react';
import ChartStyles from "@global/ChartStyles";
import BarChart from "@components/screener/chart/BarChart";


const SectorBar = ({allocation}) => {
    let skip = false
    if (allocation) {
        const nonZeroKeys = Object.keys(allocation).filter(key => allocation[key])
        skip = nonZeroKeys.length === 0
    }


    return <BarChart
        allocation={skip ? null : allocation}
        color={ChartStyles.colors[0]}
        noDataText={"No equity sector information"}
    />
}

export default SectorBar;
