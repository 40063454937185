/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Typography} from "antd";
import './Portfolio.less';

const {Paragraph, Text, Title} = Typography;

const {Meta} = Card;

const RiskIndicator = ({riskValue = '?', width = 50, float = null, ghost = false, ...props}) => {
    let className = "risk-indicator"
    if (riskValue < 30 || riskValue === '?') {
        className += " risk-indicator-low"
    } else if (riskValue > 70) {
        className += " risk-indicator-high"
    } else {
        className += " risk-indicator-medium"
    }

    if (ghost) {
        className += " ghost"
    }

    let style = props?.style ?? {}
    style["width"] = width
    style["height"] = width
    style["minWidth"] = width
    style["minHeight"] = width
    style["fontFeatureSettings"] = '"tnum", "tnum"'

    if (float) {
        style['float'] = float
    }
    return <div className={className} {...props} style={style}>
        <div className={"risk-indicator-inner"}>
            <Text strong={true} style={{fontSize: width / 2}}>{riskValue}</Text>
        </div>
    </div>
}

export default RiskIndicator;
