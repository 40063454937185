import React from "react";
import { Typography, Empty } from "antd";
import CreditRatingChart from "@components/chart/CreditRatingChart";
import MaturityDateBar from "@components/chart/MaturityDateBar";
import FISectorPie from "@components/chart/FISectorPie";

const {Title} = Typography;

const FixedIncomeDistribution = ({fullAsset}) => {
    return (
        <>
            <Title level={4}>Credit rating</Title>
            {Object.keys(fullAsset.fi_credit_rating_alloc_dist).length > 0 ?
            <CreditRatingChart allocation={fullAsset.fi_credit_rating_alloc_dist}/>
            :
            <Empty description={'Credit rating is not available'}/>
            }
            <br/>
            <Title level={4}>Effective maturity</Title>
            {Object.keys(fullAsset.fi_effective_maturity_alloc_dist).length > 0 ?
            <MaturityDateBar allocation={fullAsset.fi_effective_maturity_alloc_dist} />
            :
            <Empty description={'Effective maturity distribution is not available'}/>
            }
            <br/>
            <Title level={4}>Super sector</Title>
            {Object.keys(fullAsset.fi_super_sector_alloc_dist).length > 0 ?
            <FISectorPie allocation={fullAsset.fi_super_sector_alloc_dist} />
            :
            <Empty description={'Super sector distribution is not available'}/>
            }
        </>
    )
}

export default FixedIncomeDistribution