import React, {useRef, useState} from "react";
import {Card, Flex, Typography, theme, Button, Space} from "antd";
import {
    DownOutlined, FundFilled, FundOutlined,
    StarFilled,
    StockOutlined,
    UpOutlined,
} from "@ant-design/icons";
import Localizator from "@global/Localizator";
import ValueFomatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsInline from "@components/retail/cards/StatsInline";
import NestedCard from "@components/retail/cards/NestedCard";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import CardHeader from "@components/retail/cards/CardHeader";
import {useSize} from "ahooks";

const {useToken} = theme;


const SCENARIOS = [
    "interest rate hike 2022",
    // "bear market 2022",
    "bull market 2021",
    "2020 stock market crash",
    // "interest rate hike",
    "flat market 2015",
    "2013 bull market",
    "financial crisis",
    // "2008 bear market",
];


export const prepareScenarios = (scenarios) => {
    const preparedScenarios = [];
    if (!scenarios) {
        return preparedScenarios
    }
    for (let periodLabel of SCENARIOS) {
        const preparedScenario = {};
        for (let scenario of scenarios) {
            if (scenario.period_label === periodLabel) {
                preparedScenario["label"] = scenario.period_label;
                preparedScenario["start"] = scenario.start;
                preparedScenario["end"] = scenario.end;
                preparedScenario["portfolio_return"] = scenario.portfolio_return;
            }
            if (scenario.period_label === periodLabel && scenario.benchmark_label === "iShares Russell 3000 ETF (IWV)") {
                preparedScenario["stock_market_return"] = scenario.benchmark_return;
            }
            if (scenario.period_label === periodLabel && scenario.benchmark_label === "iShares US Aggregate Bond ETF (AGG)") {
                preparedScenario["bond_market_return"] = scenario.benchmark_return;
            }
        }
        if (!is_null(preparedScenario.bond_market_return) && !is_null(preparedScenario.stock_market_return)) {
            const r = [
                preparedScenario.portfolio_return,
                preparedScenario.stock_market_return,
                preparedScenario.bond_market_return
            ];
            const rMax = Math.max(...r);
            const rMin = Math.min(...r);
            const delta = rMax - rMin;
            const step = delta / 4;
            preparedScenario.portfolio_score = 1 + Math.floor((preparedScenario.portfolio_return - rMin) / step);
            preparedScenario.stock_market_score = 1 + Math.floor((preparedScenario.stock_market_return - rMin) / step);
            preparedScenario.bond_market_score = 1 + Math.floor((preparedScenario.bond_market_return - rMin) / step);
            preparedScenarios.push(preparedScenario);
        }
    }
    return preparedScenarios;
}


const PerformanceStats = ({
                              label,
                              portfolioReturn,
                              marketValue,
                              portfolioScore,
                              background = "none",
                              style = {}
                          }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();
    const pVal = (portfolioReturn > 0 ? "+" : "")
        + ValueFomatter.oneDigitPercent(portfolioReturn / 100);
    const dVal = (portfolioReturn > 0 ? "+" : "")
        + ValueFomatter.compactCurrency(marketValue * portfolioReturn / 100);

    return <Flex
        gap={"small"}
        justify={"space-between"}
        align={"flex-start"}
        wrap={"wrap"}
        style={{
            minWidth: 120,
            padding: "16px 24px",
            borderRadius: token.borderRadiusLG,
            // border: "1px solid rgb(230, 230, 230)",
            background: hover ? token.colorBgHoverBlue : background,
            border: "1px solid rgb(220, 220, 220)",
            // background: hover ? token.colorBgHoverBlue : "white",
            flexGrow: 1,
            ...style
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Flex vertical gap={4}>
            <span style={{
                fontSize: token.fontSizeSM,
                fontWeight: token.fontWeightStrong,
                lineHeight: 1.1,
            }}>
                {label}
            </span>
            <Flex gap={2}>
                {Array.from(Array(5)).map((_, i) => {
                    return <StarFilled key={i} style={{
                        color: i < portfolioScore ? "#eeba6c" : "rgba(0,0,0,0.1)",
                        fontSize: 10
                    }}/>
                })}
            </Flex>
        </Flex>
        <Flex vertical gap={4}>
             <span style={{
                 fontSize: token.fontSizeHeading4,
                 lineHeight: 1,
                 color: portfolioReturn >= 0 ? "rgb(73,170,25)" : "rgb(245,34,45)"
             }}>
                {pVal}
            </span>
            <span style={{color: token.colorTextDescription}}>
                {dVal}
            </span>
        </Flex>
    </Flex>
}


const StressTestTip = ({item}) => {
    return <Typography.Paragraph style={{maxWidth: 500, marginBottom: 12}}>
        In the case of
        {" " + Localizator.t("stress." + item.label).toLowerCase() + " "}
        scenario, my portfolio would probably
        {item.portfolio_return >= item.stock_market_return
            && item.portfolio_return >= item.bond_market_return
            && " beat both the stock and bond markets."
        }
        {item.portfolio_return >= item.stock_market_return
            && item.portfolio_return < item.bond_market_return
            && " beat the stock market, but lose to the bond market."
        }
        {item.portfolio_return < item.stock_market_return
            && item.portfolio_return >= item.bond_market_return
            && " lose to the stock market, but beat the bond market."
        }
        {item.portfolio_return < item.stock_market_return
            && item.portfolio_return < item.bond_market_return
            && " loose to both the stock and bond markets."
        }
    </Typography.Paragraph>
}


const StressTestItem = ({item, marketValue, vertical, performanceCardBackground}) => {
    const {token} = useToken();

    return <Flex vertical align={"flex-start"}>
        <Typography.Title
            level={5}
            style={{
                margin: 0,
                textTransform: "uppercase",
                letterSpacing: 1,
                fontWeight: 350
            }}
        >
            {Localizator.t("stress." + item.label)}
        </Typography.Title>
        <Typography.Text
            type={"secondary"}
            style={{
                fontSize: token.fontSizeSM,
                marginBottom: token.paddingSM,
            }}
        >
            {item.start + ' — ' + item.end}
        </Typography.Text>
        <StressTestTip item={item}/>
        <Flex vertical={vertical} gap={"small"} style={{width: "100%", paddingBottom: 16}}>
            <PerformanceStats
                label={<>My<br/>portfolio</>}
                portfolioReturn={item.portfolio_return}
                marketValue={marketValue}
                portfolioScore={item.portfolio_score}
                background={performanceCardBackground}
            />
            <PerformanceStats
                label={<>Stock<br/>market</>}
                portfolioReturn={item.stock_market_return}
                marketValue={marketValue}
                portfolioScore={item.stock_market_score}
                background={performanceCardBackground}
            />
            <PerformanceStats
                label={<>Bond<br/>market</>}
                portfolioReturn={item.bond_market_return}
                marketValue={marketValue}
                portfolioScore={item.bond_market_score}
                background={performanceCardBackground}
            />
        </Flex>
    </Flex>
}


const StressTestItemRecursive = ({idx, scenarios, marketValue, vertical, children}) => {
    const {token} = useToken();

    return <>{scenarios.length === 0
        ? children
        :
        <StressTestItemRecursive
            idx={idx + 1}
            scenarios={scenarios.filter((_, i) => i > 0)}
            marketValue={marketValue}
            vertical={vertical}
        >
            <NestedCard
                background={idx % 2 ? "white" : token.colorBgGrey}>
                {children}
                <StressTestItem
                    item={scenarios[0]}
                    marketValue={marketValue}
                    performanceCardBackground={idx % 2 ? token.colorBgGrey : "white"}
                    vertical={vertical}
                />
            </NestedCard>
        </StressTestItemRecursive>
    }</>
}


const StressTestCard = ({collapsible=true}) => {
    const {proposal} = useHolisticoProposalContext();
    const [expanded, setExpanded] = useState(false);
    const {token} = useToken();
    const ref = useRef(null);
    const size = useSize(ref);
    const vertical = size?.width < 400;

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const scenarios = prepareScenarios(cv.risk?.stress_test);
    const score = scenarios?.reduce((s, a) => s + a.portfolio_score, 0);
    const scoreMax = scenarios?.length * 5;
    let scenariosToShow = scenarios;
    if (collapsible && scenarios && !expanded) {
        scenariosToShow = scenarios.filter((_, i) => i < 2);
    }

    return <Card
        hoverable
        ref={ref}
        style={{
            borderColor: "rgb(220, 220, 220)",
            background: scenariosToShow.length % 2 ? "white" : token.colorBgGrey,
            cursor: "default"
        }}
    >
        {scenariosToShow && scenariosToShow.length > 0
            ?
            <StressTestItemRecursive
                idx={0}
                scenarios={scenariosToShow}
                marketValue={cv.market_value}
                vertical={vertical}
            >
                <NestedCard background={"white"}>
                    <CardHeader title="Stress tests" icon={<FundFilled/>}/>
                    <StatsInline title={"points"} value={score + " / " + scoreMax}/>
                </NestedCard>
            </StressTestItemRecursive>
            :
            <>
                <NestedCard background={"white"}>
                    <Typography.Title level={4} style={{margin: "0 0 12px 0"}}>
                        Stress tests
                    </Typography.Title>
                </NestedCard>
                <NoDataPlaceholder/>
            </>

        }
        {collapsible && scenarios && scenarios.length > 2
            &&
            <Button
                onClick={() => setExpanded(prev => !prev)}
                type={"link"}
                size={"small"}
                icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                style={{paddingLeft: 0}}
            >
                {expanded ? "Show less scenarios" : `Show more scenarios`}
            </Button>
        }
    </Card>
}


export default StressTestCard;
