/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {InputNumber, message, Tooltip, Typography} from 'antd';
import ValueFormatter from '@global/ValueFormatter';
import '../Portfolio.less';


const MarketValueInput = ({applyNewValue, market_value, cellIndex}) => {
    const [input, setInput] = useState(market_value);
    const [showDepositInput, setShowDepositInput] = useState(false);

    useEffect(() => {
        setInput(market_value)
    }, [market_value])


    const handleKeyDown = (event) => {
        if (event.keyCode === 27) {
            setInput(market_value)
            setShowDepositInput(false)
        } else if (event.keyCode === 13) {
            setShowDepositInput(false)
            applyNewValue(input, cellIndex)
        }
    }

    const handleBlur = (event) => {
        setInput(market_value)
        setShowDepositInput(false)
    }

    const handleSingle = (e) => {
        console.log(e)
        e.preventDefault();
    }

    const handleDbl = (e) => {
        e.preventDefault();
        if (applyNewValue) {
            setShowDepositInput(true)
        } else {
            message.warning("Can not update market value for a multilot position!");
        }
    }

    return (
        showDepositInput ?
            <InputNumber
                style={{minWidth: 100}}
                formatter={ValueFormatter.currency}
                autoFocus
                value={input}
                onChange={number => setInput(number)}
                onKeyDown={event => handleKeyDown(event)}
                onBlur={e => handleBlur(e)}
                step={50000}
                min={0}
                controls={false}
            />
            :
            <Tooltip title={"Double click to change market value"}>
                <Typography.Text
                    onClick={(event) => handleSingle(event)}
                    onDoubleClick={(event) => handleDbl(event)}
                    style={{whiteSpace: "nowrap"}}
                >
                    {ValueFormatter.currency(input)}
                </Typography.Text>
            </Tooltip>
    )
}

export default MarketValueInput;
