// noinspection JSCheckFunctionSignatures

import React, {useContext, useState} from 'react';
import {FileExcelOutlined, RightOutlined} from '@ant-design/icons';
import {
    Col,
    DatePicker,
    Flex,
    Form,
    message,
    Modal,
    Row,
    Spin,
    Upload
} from 'antd';
import PortfolioRowMenu from './PortfolioRowMenu';
import PortfolioTitleInput from './PortfolioTitleInput';
import MarketValueInput from './MarketValueInput';
import {PortfolioEntryContext} from "../contexts/PortfolioEntryContext";
import RiskIndicator from "../RiskIndicator";
import moment from "moment";

import '../Portfolio.less';
import ValueFormatter from "@global/ValueFormatter";
import dayjs from "dayjs";


const {Dragger} = Upload;

/* eslint-disable react/prop-types */
const PortfolioHeaderRow = ({expandProps, type, loading, showRisk=true}) => {
    const {
        rowPortfolio,
        changeMarketValue,
        changeTitle,
        destroy,
        refreshFromOrion,
        calculated_values,
        portfolioExcelDownload,
        updatePortfolioFromFile,
        addHistoricalComposition
    } = useContext(PortfolioEntryContext);
    const {expand, setExpand} = expandProps;
    const [editTitle, setEditTitle] = useState(false);
    const [clickTimeout, setClickTimeout] = useState(null);
    const [updatingModal, setUpdatingModal] = useState(false);
    const [historicalDate, setHistoricalDate] = useState(dayjs());

    const setMarketValue = marketValue => {
        marketValue = Number.parseInt(marketValue);
        if (isFinite(marketValue)) {
            // setEditMarketValue(false);
            changeMarketValue(marketValue);
        } else {
            message.error("Value can not be zero");
        }
    }

    const setTitle = newTitle => {
        setEditTitle(false);
        changeTitle(newTitle)
    }

    const doEditTitle = () => {
        setEditTitle(true)
    }

    const doEditMarketValue = () => {
        console.log('no edit from the outside')
    }

    const cleanTimeout = () => {
        clearTimeout(clickTimeout)
        setClickTimeout(null)
    }

    //preventing open/close on double click inside the row
    const onRowClick = (event) => {
        if (clickTimeout !== null) {
            cleanTimeout()
        } else {
            setClickTimeout(setTimeout(() => {
                setExpand(!expand)
                cleanTimeout()
            }, 200))
        }

    }

    const getClass = (p_type) => {
        let className = 'portfolio-row-item'
        if (p_type == 'benchmark') {
            className += ' benchmark'
        }
        return className
    }

    const draggerProps = {
        multiple: false,
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        showUploadList: false,
        customRequest: ({file}) => {
            addHistoricalComposition(file, historicalDate.toISOString())
            setUpdatingModal(false);
        }
    };

    return (<>
        <Flex
            key={rowPortfolio._id}
            onClick={(e) => onRowClick(e)}
            justify={"space-between"}
            align={"center"}
            wrap={"nowrap"}
            className={getClass(type)}
        >
            <Flex
                justify={"flex-start"}
                align={"center"}
                wrap={"nowrap"}
                style={{maxWidth: rowPortfolio.weight ? "calc(100% - 220px)" : "calc(100% - 185px)"}}

            >
                <Flex justify={"center"} align={"center"} style={{width: 30, marginRight: 8}}>
                    <RightOutlined rotate={expand ? 90 : 0}/>
                </Flex>
                {showRisk
                    &&
                    <div style={{marginRight: 16}}>
                        <RiskIndicator riskValue={calculated_values?.risk?.risk_score}/>
                    </div>
                }
                <div style={{maxWidth: showRisk ? "calc(100% - 80px)" : "calc(100% - 30px)"}}>
                    <PortfolioTitleInput
                        viewTitleInputProps={{viewTitleInput: editTitle, setViewTitleInput: setEditTitle}}
                        applyNewValue={title => setTitle(title)}
                        portfolioTitle={rowPortfolio.name}
                        ticker={rowPortfolio.ticker}
                        external_id={rowPortfolio.external_id}
                        auxiliary_data={rowPortfolio.auxiliary_data}
                        log_data={rowPortfolio.last_log}
                        type={rowPortfolio.type}
                    />
                </div>
            </Flex>
            <Flex
                justify={"flex-end"}
                align={"center"}
                wrap={"nowrap"}
            >
                {rowPortfolio.weight
                    &&
                    <div className={'additional-info'}>
                        {ValueFormatter.softPercent(rowPortfolio.weight)}
                    </div>
                }
                <div style={{marginLeft: "24px"}}>
                    <MarketValueInput
                        market_value={calculated_values['market_value']}
                        applyNewValue={setMarketValue}
                    />
                </div>
                <Flex justify={"center"} align={"center"} style={{
                    width: 60,
                    borderLeft: "1px #cfcfcf solid",
                    marginLeft: 24,
                    paddingLeft: 8
                }}>
                    {loading
                        ?
                        <Spin style={{marginRight: 20}}/>
                        :
                        <PortfolioRowMenu
                            type={rowPortfolio.type}
                            editTitle={() => doEditTitle()}
                            editMarketValue={() => doEditMarketValue()}
                            portfolioId={rowPortfolio.id}
                            exportPortfolio={() => exportPortfolio()}
                            deletePortfolio={destroy}
                            refreshPortfolio={() => refreshFromOrion()}
                            setUpdatingModal={setUpdatingModal}
                            portfolioExcelDownload={portfolioExcelDownload}
                            orionId={rowPortfolio.external_id}
                        />
                    }
                </Flex>
            </Flex>
        </Flex>
        <Modal
            title={"Updating " + rowPortfolio.name + " from file"}
            open={updatingModal}
            width={750}
            closable={true}
            maskClosable={true}
            onCancel={() => setUpdatingModal(false)}
            footer={null}
        >
            <Form.Item label="Composition date">
                <DatePicker value={historicalDate} onChange={(date, dateString) => setHistoricalDate(date)}/>
            </Form.Item>
            <Row>
                <div className={'ant-upload ant-upload-drag'}>
                    <Dragger {...draggerProps} style={{border: 0}}>
                        <p className="ant-upload-drag-icon">
                            <FileExcelOutlined style={{color: "green"}}/>
                        </p>
                        <p className="ant-upload-text">Click or drag to upload</p>
                        <p className="ant-upload-hint">
                            Upload an Excel file with positions info
                        </p>
                    </Dragger>
                </div>
            </Row>

        </Modal>
    </>);
}

export default PortfolioHeaderRow;
