/* eslint-disable react/prop-types */
import React, {useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Flex,
    Form,
    Input,
    Popconfirm,
    Space,
    message, Spin, Tooltip,
} from "antd";
import {DeleteOutlined, EditOutlined, HeartFilled} from "@ant-design/icons";
import dayjs from "dayjs";
import {
    createCollection,
    deleteCollection,
    renameCollection
} from "@API/screener";
import {useScreenerContext} from "@hooks/ScreenerContext";


const CollectionFilterItem = ({formItemName, collectionItem}) => {
    const {
        entityGroup,
        setLoadingCollections,
        updateCollectionOptions,
        setSearchParams
    } = useScreenerContext()
    const [editMode, setEditMode] = useState(false)
    const inputRef = useRef(null);
    const hiddenInputStyle = {
        border: "none",
        maxWidth: 0,
        padding: 0
    }

    const onRenameCollection = (new_name) => {
        setLoadingCollections(true)
        renameCollection(entityGroup, collectionItem.value, new_name, (data, error) => {
            if (!error) {
                updateCollectionOptions(() => {
                    setEditMode(false)
                    setLoadingCollections(false)
                })
            } else {
                message.error(`Something wrong happened while renaming "${collectionItem.label}" collection!`);
                setEditMode(false)
                setLoadingCollections(false)
            }
        })
    }

    const onDeleteCollection = () => {
        setLoadingCollections(true)
        deleteCollection(entityGroup, collectionItem.value, (data, error) => {
            if (!error) {
                setSearchParams(prev => {
                    prev.delete(formItemName, collectionItem.value)
                    return prev
                })
                updateCollectionOptions(() => {
                    setLoadingCollections(false)
                })
            } else {
                message.error(`Something wrong happened while deleting "${collectionItem.label}" collection!`);
                setLoadingCollections(false)
            }
        })
    }

    return <Flex
        justify={"space-between"}
        align={"center"}
        style={{width: "100%", height: 28}}
    >
        <Checkbox value={collectionItem.value} style={{flexGrow: 1}}>
            {!editMode && collectionItem.label}
            <Input
                defaultValue={collectionItem.label}
                onPressEnter={(e) => {
                    e.target.blur()
                }}
                onBlur={(e) => {
                    onRenameCollection(e.target.value)
                }}
                size={"small"}
                ref={inputRef}
                style={editMode ? {} : hiddenInputStyle}
            />
        </Checkbox>
        {!collectionItem.default
            &&
            <Space>
                <Tooltip
                    title={"Rename collection"}
                    mouseEnterDelay={0.5}
                >
                    <Button
                        onClick={() => {
                            setEditMode(true)
                            inputRef.current.focus({
                                cursor: 'end',
                            });
                        }}
                        size={"small"}
                        icon={<EditOutlined/>}
                        style={{border: "none"}}
                        className={'auxiliary-button'}
                    />
                </Tooltip>
                <Popconfirm
                    title="Delete collection"
                    description={`Are you sure to delete "${collectionItem.label}" collection?`}
                    onConfirm={onDeleteCollection}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                >
                    <Tooltip
                        title={"Delete collection"}
                        mouseEnterDelay={0.5}
                    >
                        <Button
                            size={"small"}
                            icon={<DeleteOutlined/>}
                            style={{border: "none"}}
                            className={'auxiliary-button'}
                        />
                    </Tooltip>
                </Popconfirm>
            </Space>
        }
    </Flex>
}


const CollectionFilter = ({item}) => {
    const {
        entityGroup,
        updateCollectionOptions,
        loadingCollections,
        setLoadingCollections
    } = useScreenerContext()

    const onCreateCollection = () => {
        setLoadingCollections(true)
        const _name = dayjs().format("MMMM DD, YYYY")
        createCollection(entityGroup, _name, (data, error) => {
            if (!error) {
                updateCollectionOptions(() => {
                    setLoadingCollections(false)
                })
            } else {
                message.error("Something wrong happened while creating new collection!");
                setLoadingCollections(false)
            }
        })
    }

    return <Spin spinning={loadingCollections} tip="Loading...">
        <Form.Item name={item.name}>
            <Checkbox.Group
                style={{
                    display: "flex",
                    flexDirection: item.flexDirection
                }}
            >
                {item.options.map(collectionItem => {
                    return <CollectionFilterItem
                        key={collectionItem.value}
                        formItemName={item.name}
                        collectionItem={collectionItem}
                    />
                })}
            </Checkbox.Group>
        </Form.Item>
        <Button
            onClick={onCreateCollection}
            style={{marginTop: 8}}
            className={'auxiliary-button'}
        >
            Create collection
        </Button>
    </Spin>
}


export default CollectionFilter;
