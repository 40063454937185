import {Anchor, Typography} from "antd";
import TutorialVideo from "@components/tutorial/TutorialVideo";

const {Link} = Anchor;

const TableOfContentsLink = ({item}) => {
    return <Link href={item.href} title={item.title}>
        {item?.children && item.children.map(child => {
            return <TableOfContentsLink item={child} key={child.key}/>
        })}
    </Link>
}

const TutorialArticle = ({item, child = false}) => {
    let correctId = item.href.replace('#', '')
    let style = {}

    let title = <Typography.Title level={4} id={correctId} className={'spas'}>{item.title}</Typography.Title>
    if (child) {
        style = {padding: 8}
        title = <Typography.Title level={4} id={correctId}>{item.title}</Typography.Title>
    }
    let paragraphs = [item.text]
    if (typeof item.text === 'string') {
        paragraphs = item.text.split('\n')
    }

    return <div style={style}>
        {title}
        {paragraphs.map((p, index) => {
                return <Typography.Paragraph key={index}>
                    {p}
                </Typography.Paragraph>
            }
        )}

        {item?.children && item.children.map(child => {
            return <TutorialArticle item={child} key={child.key} child={true}/>
        })}
    </div>
}

const SPASTutorial = ({title, items}) => {
    return <div style={{background: '#ffffff', padding: 12}}>
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Title level={4} className={'spas'}>Video</Typography.Title>
        <TutorialVideo/>
        <br/>
        <br/>
        <Typography.Title level={4} className={'spas'}>Table of contents</Typography.Title>
        <Anchor affix={false} style={{maxHeight: '300vh'}}>
            {items.map(item => {
                return <TableOfContentsLink item={item} key={item.key}/>
            })}
        </Anchor>
        {items.map(item => {
            return <TutorialArticle item={item} key={item.key}/>
        })}

    </div>
}

export default SPASTutorial;
