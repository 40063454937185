import {
    Button,
    Tooltip,
    Typography
} from "antd";
import React from "react";
import {
    useHolisticoProposalContext
} from "@hooks/ProposalContext";
import {useSearchParams} from "react-router-dom";
import {MinusOutlined} from "@ant-design/icons";
import AiConversation from "@holistico/proposal/ai/AiConversation";
import NotesForm from "@holistico/proposal/new/form/NotesForm";


export const showAi = () => {
    const [searchParams, _] = useSearchParams()
    return searchParams.get("show-ai") !== "false"
}


export const HideAiButton = () => {
    const [_, setSearchParams] = useSearchParams()

    const handleButtonClick = () => {
        setSearchParams(prev => {
            prev.set("show-ai", "false");
            return prev;
        })
    }

    return <Tooltip title="Hide Ai Assistant">
        <Button
            type="text"
            onClick={handleButtonClick}
            style={{marginLeft: "auto", fontSize: "20px"}}
        >
            <MinusOutlined/>
        </Button>
    </Tooltip>
}


export const ShowAiButton = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const _showAi = searchParams.get("show-ai") !== "false";

    const handleButtonClick = () => {
        setSearchParams(prev => {
            prev.set("show-ai", "true");
            return prev;
        })
    }

    return <>
        {!_showAi
            &&
            <Tooltip title="Show Ai Assistant">
                <Button
                    type="text"
                    onClick={handleButtonClick}
                    style={{marginLeft: "auto", fontSize: "20px"}}
                >
                    Ai >
                </Button>
            </Tooltip>
        }
    </>
}

const AiSideBar = () => {
    const {id, conversation, notes, setNotes} = useHolisticoProposalContext()

    return <>
        <Typography.Title level={3}>
            Ai Assistant
        </Typography.Title>
        <HideAiButton/>
        <div className={"page-content"}
             style={{padding: "24px 16px", minHeight: "auto"}}
        >
            <AiConversation
                client_id={id}
                chatHistory={conversation}
                height={250}
                autoMessage={false}
            />
        </div>
        {id
            && <>
                <div className={"page-content"}
                     style={{padding: "24px 16px", minHeight: "auto"}}
                >
                    <NotesForm
                        height={300}
                    />
                </div>
            </>
        }
    </>
}

export default AiSideBar
