/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Radar} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
// import {Typography} from "antd";
import {DatabaseOutlined} from "@ant-design/icons";

// const {Paragraph, Text, Title} = Typography;
const ScoreMarkersRadar = ({score_markers, loading, title}) => {
    const [config, setConfig] = useState({
        data: [],
        xField: 'label',
        yField: 'value'
    });

    useEffect(() => {
        const chartConfig = {
            title: 'Score Markers',
            autoFit: true,
            width: 350,
            data: score_markers,
            xField: 'label',
            yField: 'value',
            padding: 12,
            appendPadding: 12,
            meta: {
                value: {
                    alias: 'Portfolio Score',
                    min: -30,
                    max: 140
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: [4, 4],
                        },
                    },
                },
                label: {
                    style: {
                        //fill: 'black'
                    },
                    offset: 0
                }
            },
            yAxis: {
                line: null,
                tickLine: null,
                label: null,
                grid: {
                    line: {
                        style: (x, y) => {
                            if (y < 6) {
                                return {
                                    fill: "r(0.5,0.5,0.2) 0:rgb(85, 157, 180)",
                                    fillOpacity: 0.1,
                                    opacity: 0
                                };
                            } else {
                                return {}
                            }

                        }
                    }
                },
            },
            point: {
                style: (datum) => {
                    let status = ChartStyles.get_status(datum.value)
                    let color = ChartStyles.get_status_color(status)

                    return {
                        lineWidth: 0,
                        fill: color,
                        r: 15,
                        opacity: 0.5
                    }
                }
            },
            lineStyle: {
                lineDash: [2, 2],
                stroke: ChartStyles.colors[1]
            },
            tooltip: {
                showMarkers: true,
                marker: {
                    lineWidth: 5,
                    fillOpacity: 0,
                    r: 15,
                    opacity: 1
                }
            }
        }

        setConfig(chartConfig)


    }, [score_markers])

    return loading ? null : <div style={{padding: 12}}>
        {score_markers && score_markers.length ? <Radar {...config}/> :
            <div className="noDataBox">
                <DatabaseOutlined style={{fontSize: '2em'}}/>
                <div>No scores data</div>
            </div>}
    </div>;
}

export default ScoreMarkersRadar;
