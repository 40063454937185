import React, {useContext} from 'react';


import {Col, Row} from 'antd';

import PortfolioCard from "@root/components/portfolio/PortfolioCard";
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";

const ClientOverview = () => {
    const {client} = useContext(SpasClientContext);

    const current_calculations = client['p_bucket_cur']['calculated_values']
    const proposed_calculations = client['p_bucket_prop']['calculated_values']

    return (<div>
            <Row id="portfolio-charts" gutter={8}>
                <Col sm={24} md={12} xs={24} lg={12}>
                    <PortfolioCard calculated_values={current_calculations} rId={"client"}
                                   title={"Current Portfolio"}
                                   loading={false}
                                   link={"../current"}/>
                </Col>
                <Col sm={24} md={12} xs={24} lg={12}>
                    <PortfolioCard calculated_values={proposed_calculations}
                                   rId={"proposed"}
                                   title={"Proposed Portfolio"}
                                   loading={false}
                                   link={"../proposed"}/>
                </Col>
            </Row>
        </div>
    )
}

export default ClientOverview;
