import React, {useEffect, useState} from "react";
import {Progress, Spin, Typography} from "antd";


const HSpinner = ({children, loading, tip}) => {
    const [loadedPercent, setLoadedPercent] = useState(0);
    const [progressInterval, setProgressInterval] = useState(null);

    const progressIntervalCaller = () => {
        return setInterval(() => {
            setLoadedPercent((current) => current <= 100 ? current + 1 : current);
        }, 700)
    }


    useEffect(() => {
        if (loading) {
            let pi = progressIntervalCaller();
            setProgressInterval(progressInterval)
        } else {
            clearInterval(progressInterval)
        }
    }, [loading])

    return <Spin
        spinning={loading}
        tip={tip && <div style={{marginTop: -150}}>
            <Progress percent={loadedPercent}
                      status="active"
                      strokeWidth={1}
                      strokeColor='#3196B2'
                      showInfo={false}/>
            <Typography.Text>{tip}</Typography.Text>
        </div>}
        indicator={
            <img
                src={"/img/spin/spinner-atom.svg"}
                style={{width: '200px', height: '200px', marginLeft: -100, marginTop: -100}}
            />
        }
        size={"large"}
    >
        {children}
    </Spin>
}

export default HSpinner
