import React from "react";
import {Descriptions, Space, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import Localizator from "@global/Localizator";
import moment from "moment";
import {WarningOutlined} from "@ant-design/icons";

import "./SupervisionClientReport.less";

const EmptyWarning = () => {
    return <Space direction={'horizontal'}>
        <WarningOutlined/> Not set
    </Space>
}
let fields = [
    {
        label: 'representative',
        formatter: (client) => {
            return client['representative'] ? client['representative'] : <EmptyWarning/>
        }
    }, {
        label: 'state',
        formatter: (client) => {
            return client['state'] ? client['state'] : <EmptyWarning/>
        }
    }, {
        label: 'start_date',
        formatter: (client) => {
            if (client['start_date']) {
                let curMom = moment(client['start_date'])
                let formattedValue = curMom.format("MMM DD, YYYY")
                return client['start_date'] ? formattedValue : <EmptyWarning/>
            } else {
                return 'No info'
            }
        }
    }, {
        label: 'market_value',
        formatter: (client) => {
            return client['market_value'] ? ValueFormatter.compactCurrency(client['market_value']) : <EmptyWarning/>
        }
    }, {
        label: 'email',
        formatter: (client) => {
            return client['email'] ? client['email'] : <EmptyWarning/>
        }
    }, {
        label: 'birth_date',
        formatter: (client) => {
            if (client['birth_date']) {
                let curMom = moment(client['birth_date'])
                let formattedValue = curMom.format("MMM DD, YYYY")
                return client['birth_date'] ? formattedValue : <EmptyWarning/>
            } else {
                return 'No info'
            }
        }
    }, {
        label: 'source',
        formatter: (client) => {
            return client['source'] ? client['source'] : 'No info'
        }
    }, {
        label: 'external_id',
        formatter: (client) => {
            return client['external_id']
        }
    }]

const ClientInfo = ({client, extra=null}) => {
    const InfoTitle = () => {
        let title = client.name
        if (client.birth_date) {
            let clientAge = ValueFormatter.dateToAge(client.birth_date)
            title += ', ' + clientAge + ' y.o.'
        }
        return <Typography.Title level={4} className={'spas'}>{title}{extra}</Typography.Title>
    }

    return <div style={{marginBottom: 12}}>
        <InfoTitle/>
        <Descriptions bordered size={'small'}>
            {fields.map((field, index) => {
                let label = Localizator.t(`supervision.client.${field.label}`)
                return <Descriptions.Item key={label} label={label}>{field.formatter(client)}</Descriptions.Item>
            })}
        </Descriptions>
    </div>

}

export default ClientInfo
