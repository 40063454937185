import React, {useEffect, useState} from 'react';
import {
    Col, Flex,
    InputNumber,
    List,
    Popconfirm,
    Row,
    theme,
    Tooltip, Typography
} from "antd";
import {
    DeleteOutlined, ExclamationCircleFilled,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import '@root/components/portfolio/Portfolio.less';
import ValueFormatter from "@global/ValueFormatter";


const {useToken} = theme;


const COL_SPAN = {
    ticker: {lg: 5,md: 5, sm: 5, xs: 5},
    name: {lg: 13, md: 13, sm: 9, xs: 9},
    marketValue: {lg: 5, md: 5, sm: 8, xs: 8},
    removeIcon: {lg: 1, md: 1, sm: 2, xs: 2},
}


const ELLIPSIS_STYLE = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}



const EditableField = ({value, submit, tooltip=null, formatter, inputOptions={}}) => {
    const [input, setInput] = useState(value);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setInput(value)
    }, [value])

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            setEditing(false);
            submit(input);
        }
        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            setInput(value);
            setEditing(false);
        }
    }

    const onBlur = (e) => {
         setInput(value);
         setEditing(false);
    }

    return (
        editing
            ?
            <InputNumber
                value={input}
                onChange={v => setInput(v)}
                formatter={formatter}
                autoFocus
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                controls={false}
                // variant={"borderless"}
                {...inputOptions}
            />
            :
            <Tooltip title={tooltip} mouseEnterDelay={0.5}>
                <Typography.Text
                    onClick={(event) => setEditing(true)}
                    onDoubleClick={(event) => setEditing(true)}
                    style={{whiteSpace: "nowrap"}}
                >
                    {formatter(input)}
                </Typography.Text>
            </Tooltip>
    )
}


const PositionsTable = ({positions, changePositionMarketValue, deletePosition, rowHeight=45}) => {
    const {token} = useToken();

    return <>
         <Flex
             gap={"small"}
             align={"center"}
             justify={"flex-end"}
             style={{
                 color: token.colorTextDescription,
                 fontSize: token.fontSizeSM,
                 lineHeight: 1.2,
                 // margin: "12px 0 4px 0"
            }}
         >
            <ExclamationCircleFilled style={{color: "rgba(0,0,0,0.2)"}}/>
            Click market value to edit
        </Flex>
        <Row
            type="flex"
            gutter={[8, 0]}
            justify="start"
            align="middle"
            style={{
                minHeight: rowHeight,
                fontWeight: token.fontWeightStrong,
                // borderTop: `1px solid ${token.colorBorder}`
            }}
        >
            <Col {...COL_SPAN['removeIcon']}/>
            <Col {...COL_SPAN['ticker']} style={{...ELLIPSIS_STYLE}}>
                Ticker
            </Col>
            <Col{...COL_SPAN['name']} style={{...ELLIPSIS_STYLE}}>
                Name
            </Col>
            <Col {...COL_SPAN['marketValue']} style={{...ELLIPSIS_STYLE, textAlign: 'right'}}>
                Market value
            </Col>
        </Row>
        <List
            dataSource={positions}
            style={{background: "none"}}
            renderItem={(position, index) => {
                return <Row
                    key={index}
                    type="flex"
                    gutter={[8, 0]}
                    justify="start"
                    align="middle"
                    className="portfolio-position-row"
                    style={{
                        minHeight: rowHeight,
                        borderTop: `1px solid ${token.colorBorder}`
                    }}
                >
                    <Col {...COL_SPAN['removeIcon']}>
                        {!position.prohibitDeletion
                            &&
                            <Popconfirm
                                title={`Are you sure to delete ${position.ticker || "this"} position?`}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deletePosition(index)}
                            >
                                <DeleteOutlined style={{
                                    cursor: 'pointer',
                                    color: "rgba(0,0,0,0.4)"
                                }}/>
                            </Popconfirm>
                        }
                    </Col>
                    <Col {...COL_SPAN['ticker']}
                         style={{...ELLIPSIS_STYLE}}>
                        {position.ticker}
                    </Col>
                    <Col {...COL_SPAN['name']} style={{...ELLIPSIS_STYLE}}>
                        {position.name || "-"}
                    </Col>
                    <Col {...COL_SPAN['marketValue']}
                         style={{...ELLIPSIS_STYLE, textAlign: 'right'}}>
                        <EditableField
                            value={position.market_value}
                            submit={(value => changePositionMarketValue(value ?? 0, index))}
                            formatter={ValueFormatter.currency}
                            inputOptions={{
                                step: 1000,
                                style: {width: "100%", minWidth: 100},
                                align: "right"
                            }}
                        />
                    </Col>
                </Row>
            }}
        />
    </>
}

export default PositionsTable;
