/* eslint-disable react/prop-types */
import React from 'react';
import {Slider} from "antd";
import ValueFormatter from "@global/ValueFormatter";


const PrognosisSlider = ({risk_calculations, currentValue, vertical, title, height = 50}) => {

    if (!risk_calculations) {
        return null
    }
    const minPercent = risk_calculations['lower_bound_6m'] / 100
    const maxPercent = risk_calculations['upper_bound_6m'] / 100
    // const maxValue = (maxPercent * currentValue)
    // const minValue = (minPercent * currentValue)

    //console.log(maxPercent, minPercent)

    const tipFormatter = () => {
        return ValueFormatter.currency(currentValue)
    }

    let style = {};

    if (vertical) {
        style.color = '#ad2102'
    }

    let marks = {}
    marks[15] = {
        style: {
            color: '#ad2102',
        },
        label: ValueFormatter.percent(minPercent)
    }

    marks[50] = {
        style: {
            fontSize: 13
        },
        active: true,
        label: ValueFormatter.currency(currentValue)
    }

    marks[85] = {
        style: {
            color: '#5b8c00',
        },
        label: '+' + ValueFormatter.percent(maxPercent),
    }

    return (
        (<div style={{marginTop: 10, height: height, width: '100%'}}>
            <Slider
                className={"range-slider"}
                included={false}
                vertical={vertical}
                marks={marks}
                style={{fontSize: '13px'}}
                disabled={true}
                style={{cursor: "default"}}
                tooltip={{
                    formatter: tipFormatter,
                    placement: "right",
                    open: false
                }} />
        </div>)
    );
}

export default PrognosisSlider;
