import {Result} from "antd";
import React from "react";

const UnderMaintenance = () => {
     return <Result
       status="500"
       title="Under Maintenance"
       subTitle="This section is under maintenance and will be available soon"
     />
}

export default UnderMaintenance
