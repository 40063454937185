import {Button, Input, Popover} from "antd";
import {SendOutlined} from "@ant-design/icons";
import React, {useRef, useState} from "react";
import TimedLoader from "@holistico/TimedLoader";
import {useAiChatContext} from "@hooks/AiChatContext";
import "./AiChatInput.less"


const AiChatInput = ({}) => {
    const {
        sendMessage,
        waitingForResponse,
        predefinedMessages,
        getPredefinedMessageByKey
    } = useAiChatContext()
    const [currentMessage, setCurrentMessage] = useState('');
    const searchInputRef = useRef(null);

    const loadPredefinedMessage = (key) => {
        let msg = getPredefinedMessageByKey(key)
        setCurrentMessage(msg)
        searchInputRef.current.focus();
        return msg
    }

    return <div className={"ai-chat-input-container"}>
        <TimedLoader
            secondsToWait={waitingForResponse ? 30 : 0}
            stepsCount={null}
            strokeColor={null}
            size={"small"}
        />
        <div className={"predefined-questions-panel"}>
            {predefinedMessages.slice(0, 2).map((obj => {
                return <Button
                    key={obj.key}
                    className="predefined-questions-button"
                    onClick={() => loadPredefinedMessage(obj.key)}
                >
                    {obj.label}
                </Button>
            }))}
            <Popover
                content={predefinedMessages.slice(2).map((obj) => {
                    return <Button
                        key={obj.key}
                        className="predefined-questions-button"
                        onClick={() => loadPredefinedMessage(obj.key)}
                    >
                        {obj.label}
                    </Button>
                })}
                placement="topRight"
                overlayClassName={"ai-chat-predefined-questions-popover"}
            >
                <Button className="predefined-questions-button">
                    more...
                </Button>
            </Popover>
        </div>
        <div className="text-area-search">
            <Input.TextArea
                placeholder={'Write a message'}
                value={currentMessage}
                bordered={false}
                autoSize={{minRows: 1}}
                autoFocus={true}
                ref={searchInputRef}
                disabled={waitingForResponse}
                onChange={(e) => setCurrentMessage(e.target.value)}
                onPressEnter={(e) => {
                    e.preventDefault()
                    sendMessage(e.target.value)
                    setCurrentMessage("")
                }}
            />
            <Button
                type={"primary"}
                icon={<SendOutlined/>}
                onClick={() => {
                    sendMessage(currentMessage)
                    setCurrentMessage("")
                }}
                disabled={waitingForResponse}
                loading={waitingForResponse}
            />
        </div>
    </div>
}


export default AiChatInput
