import React, {useEffect, useState} from "react";
import {Button, DatePicker, message, Spin, Typography} from 'antd';
import {useRequest} from "ahooks";
import {getDashboardData} from "@API/scheduler";
import {formChartData, TaskTimeLine} from "@components/admin_panel/scheduler/TaskTimeLine";

import "./Scheduler.less";
import dayjs from "dayjs";

const DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_START = dayjs().subtract(1, 'day').startOf('day')
const DEFAULT_END = dayjs().add(1, 'day')

const {RangePicker} = DatePicker;

/*
we trick ant charts a bit: we need additional data for coloring etc. so we use 3rd element in range array.
 */

const SchedulerDashboard = () => {
    const [dates, setDates] = useState([DEFAULT_START, DEFAULT_END])
    const {
        data,
        run,
        loading,
        cancel,
        error
    } = useRequest(() => getDashboardData(dates[0].format(DATE_FORMAT), dates[1].format(DATE_FORMAT)), {
        refreshDeps: [dates],
    });
    const [schedulerData, setSchedulerData] = useState([])
    const [taskNames, setTaskNames] = useState([])
    const [queueNames, setQueueNames] = useState([])
    const [orderedEventTitles, setOrderedEventTitles] = useState([])

    useEffect(() => {
        if (!data) return
        let mappedData = formChartData(data?.data['event_list'])
        setSchedulerData(mappedData)
        setTaskNames(data?.data['task_names'])
        setQueueNames(data?.data['queue_names'])
        setOrderedEventTitles(data?.data['ordered_titles'])
    }, [data])

    const onDatesChange = (dates, datesString, info) => {
        //run({start_date: datesString[0], end_date: datesString[1]})
        setDates(dates)
    }

    if (error) {
        message.error("Failed to load scheduler state data!")
    }

    return (
        <Spin size={"large"} spinning={loading} tip="Loading data...">
            <div className={'scheduler-container'}>
                <div style={{paddingBottom: 12}}>
                    <RangePicker
                        value={dates}
                        format={DATE_FORMAT}
                        onCalendarChange={onDatesChange}
                    />
                    <Button type="primary"
                            onClick={() => run()}
                    >
                        Refresh
                    </Button>
                </div>
                <Typography.Title level={4} className={'spas'}>Scheduler</Typography.Title>
                {/*TODO: data loading should be moved into the component to prevent shaking*/}
                <TaskTimeLine task_data={schedulerData}
                              interval_dates={[dates[0].startOf('day'), dates[1]]}
                              task_names={taskNames}
                              queue_names={queueNames}
                              ordered_titles={orderedEventTitles}
                />
            </div>
        </Spin>
    )
}

export default SchedulerDashboard
