/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Spin, Empty} from "antd";
import {Bar} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";


const MaturityDateBar = ({allocation, proposalAllocation = null, loading = false, legend = true}) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    const heightCalculator = () => {
        let height;
        let _allocation;
        let _proposalAllocation;
        if (Object.keys(allocation).length == 0 && proposalAllocation && Object.keys(proposalAllocation).length > 0) {
            _allocation = proposalAllocation;
            _proposalAllocation = null;
        } else {
            _allocation = allocation;
            _proposalAllocation = proposalAllocation;
        }
        if (_proposalAllocation && Object.keys(_proposalAllocation).length > 0) {
            height = Math.max(Object.keys(_allocation).length, Object.keys(_proposalAllocation).length) * 70;
            return `${height}px`
        }
        height = Object.keys(_allocation).length * 50;
        return `${height}px`
    }

    useEffect(() => {

        if (!allocation) {
            return
        }
        let maxValue = 0;
        let chartData = Object.keys(allocation).map(key => {
            if (allocation[key] > maxValue) {
                maxValue = allocation[key]
            }
            return {
                label: key,
                value: allocation[key],
                type: 'Current Portfolio'
            }
        })

        if (chartData == data) {
            return
        }

        if (proposalAllocation) {
            Object.keys(proposalAllocation).forEach(key => {
                if (proposalAllocation[key] > maxValue) {
                    maxValue = proposalAllocation[key]
                }
                const allocValue = {
                    label: key,
                    value: proposalAllocation[key],
                    type: 'Benchmark'
                }
                chartData.push(allocValue)
            })
        }
        chartData.sort((a, b) => {
            let a_key = a?.label
            let b_key = b?.label
            if (!a_key) return -1
            if (!b_key) return 1

            a_key = parseInt(a_key.split('-')[0])
            b_key = parseInt(b_key.split('-')[0])
            if (a_key > b_key) return 1;
            if (a_key < b_key) return -1;
            return 0;
        });
        setData(chartData)
        const chartConfig = {
            title: 'Maturity date distribution',
            data: chartData,
            isGroup: true,
            color: [ChartStyles.colors[3], ChartStyles.colors[5]],
            maxBarWidth: 25,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            // padding: 'auto',
            renderer: 'svg',
            legend: legend ? {position: 'top'} : false,
            marginRatio: 0,
            padding: [20, 55, 23, 62],
            meta: {
                value: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.percent(v)
                    }
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.type, value: ValueFormatter.percent(datum.value)};
                },
            },
            label: {
                position: 'right',
                offsetX: -5,
                // layout: [
                //     // {
                //     //     type: 'interval-adjust-position',
                //     // },
                //     {
                //         type: 'adjust-color',
                //     },
                // ],
            },
        }

        setConfig(chartConfig)


    }, [allocation, proposalAllocation])

    return <Spin size="large" spinning={loading}>
        {data.length > 0
        ? 
        <div style={{
            paddingBottom: 12,
            height: heightCalculator()
        }}>
            <Bar {...config}/>
        </div>
        : 
        <Empty style={{margin: "15px 0"}} description="No maturity date information"/>
        }
    </Spin>
}

export default MaturityDateBar;
