import React, {useContext} from "react";
import {Col, Row, Tag, Tooltip, Typography} from "antd";
import {Link} from "react-router-dom";
import {TeamOutlined} from "@ant-design/icons";
import ValueFormatter from "@global/ValueFormatter";
import {formatterMapper} from "@components/supervision/report_constructor/tree_utils/utils";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";
import ClientActions from "@components/supervision/actions/ClientActions";

import "../RedFlagReport.less";

const ClientTreeTitle = ({client, metricFlagData, representativeInfo}) => {
    const {metric_units, metric_decimals_precision} = metricFlagData;
    const metricFormatter = formatterMapper(metric_units);

    const {configState} = useContext(SupervisionContext);

    const AdditionalData = () => {
        if (client.age) {
            return <Typography.Text type={'secondary'}>{ValueFormatter.currency(client.market_value)}, {client.age} y.o.</Typography.Text>
        } else {
            return <Typography.Text>{ValueFormatter.currency(client.market_value)}</Typography.Text>
        }

    }

    return (
        <div className="supervision-client-inner">
            <Row>
                <Col>
                    <Link to={"../client/" + client.client_id + `?${new URLSearchParams(configState).toString()}`}
                          target="_blank"
                          relative="path"
                    >
                        <TeamOutlined/> {client.client_name}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col style={{fontSize: 16}}><AdditionalData/></Col>
            </Row>
            <Row>
                <Col>
                    <Tooltip title="Metric value">
                        <Tag color="red" style={{fontSize: "0.9em"}}>
                            {metricFormatter(client.metric_value, metric_decimals_precision)}
                        </Tag>
                    </Tooltip>
                </Col>
                <Col>vs</Col>
                <Col style={{marginLeft: "10px"}}>
                    <Tooltip title={`Limit (${client.limit_type})`}>
                        <Tag style={{fontSize: "0.9em"}}>
                            {metricFormatter(client.metric_limit, metric_decimals_precision)}
                        </Tag>
                    </Tooltip>
                </Col>
            </Row>
            <ClientActions
                size={'medium'}
                showTitle={false}
                actions={['notify', 'mute_flag', 'analyze', 'proposal']}
                client={client} representativeInfo={{
                name: representativeInfo.representative_name,
                email: representativeInfo.representative_email
            }} metricFlagData={{
                ...metricFlagData,
                metric_value: client.metric_value,
                metric_limit: client.metric_limit
            }}
            />
        </div>
    )
}

export default ClientTreeTitle
