import {createContext} from 'react';

export const PortfolioListContext = createContext({
    addFromFile: null,
    addFromOrion: null,
    addFromOther: null,
    addEmpty: null,
    removeOne: null,
    reloadContext: () => {},
    lockActions: false
});
