/* eslint-disable react/prop-types */
import React from 'react';
import PortfolioComposition
    from "@holistico/proposal/view/portfolios/PortfolioComposition";
import {Alert, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {NavLink} from "react-router-dom";
import {
    HideAiButton,
    ShowAiButton
} from "@holistico/proposal/view/ai/AiSideBar";


const ProposedPortfolioComposition = () => {
    const {proposal} = useHolisticoProposalContext()
    const ability = proposal?.questionnaire?.risk_profile?.ability
    const behavioral = proposal?.questionnaire?.risk_profile?.behavioral

    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Proposed portfolio
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"}>
            {ability || behavioral
                ?
                <PortfolioComposition bucketType={"prop"}/>
                :
                <Alert
                    message="Questionnaire not filled"
                    description={
                        <div>
                            {"This section will become available after filling the "}
                            <NavLink
                                to={`/ria/proposals/${proposal?._id}/client/general-info`}>
                                questionnaire
                            </NavLink>
                            {" or passing the "}
                            <NavLink
                                to={`/ria/proposals/${proposal?._id}/client/behavioral-quiz`}>
                                behavioral quiz
                            </NavLink>
                        </div>
                    }
                    type="warning"
                    showIcon
                />
            }
        </div>
    </>
}

export default ProposedPortfolioComposition;
