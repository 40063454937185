import {Card, Button, Space, Tag, Typography, Tooltip, Badge} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";
import ValueFormatter from "@global/ValueFormatter";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import {getSScore, getAssetAllocation, getAssetAllocationLabel} from "@holistico/proposal/new/preview/StatsList";
import "./ModelCard.less"


const { Meta } = Card;


const ModelCardHeader = ({model, onAdd, onRemove, idInProposal}) => {
    return <Meta
        title={<div style={{display: "flex"}}>
            <div style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}>
                {model.name}
            </div>
            {model.ticker
                && <div style={{marginLeft: "8px"}}>
                    ({model.ticker})
                </div>

            }
        </div>}
        description={<>
            {idInProposal.length > 0
                ?
                <Button
                    type={"link"}
                    style={{marginLeft: "-7px"}}
                    onClick={() => {
                        onRemove(idInProposal.slice(-1))
                    }}
                    size="small"
                >
                    <DeleteOutlined/>
                    Remove from proposal
                </Button>
                :
                <Button
                    type={"link"}
                    style={{marginLeft: "-7px"}}
                    onClick={() => {
                        onAdd(model._id)
                    }}
                    size="small"
                >
                    <PlusOutlined/>
                    Add to proposal
                </Button>
            }
        </>}
    />
}


const ModelCard = ({model, onAdd, onRemove, idInProposal=[]}) => {
    const assetAllocation = getAssetAllocation(model.calculated_values)
    let allocationTag = null
    if (assetAllocation[0] > 0.9) {
        allocationTag = "Stocks"
    } else if (assetAllocation[1] > 0.9) {
        allocationTag = "Bonds"
    }

    return <Card className={"model-card"}>
        {/*{idInProposal.length > 0*/}
        {/*    && <Tooltip title={"Model is in proposal"}>*/}
        {/*        <div className="in-proposal-tag">*/}
        {/*            P*/}
        {/*        </div>*/}
        {/*    </Tooltip>*/}
        {/*}*/}
        {idInProposal.length > 0
            ?
            <Badge.Ribbon text="In proposal" size="small" color="#008000">
                <ModelCardHeader
                    model={model}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    idInProposal={idInProposal}
                />
            </Badge.Ribbon>
            :
            <ModelCardHeader
                model={model}
                onAdd={onAdd}
                onRemove={onRemove}
                idInProposal={idInProposal}
            />
        }
        <div className="tag-container">
            <Space size={[0, 8]} wrap>
                {model.calculated_values.annual_dividend >= 2.0
                    && <Tooltip title={"High dividends or coupons"}>
                        <Tag color="#2db7f5">
                            Dividends
                        </Tag>
                    </Tooltip>
                }
                {model.calculated_values.expense_ratio >= 0.01
                    && <Tooltip title={"Contains ETFs or mutual funds"}>
                        <Tag color="#f50">
                            ETFs
                        </Tag>
                    </Tooltip>
                }
                {allocationTag === "Stocks"
                    && <Tooltip title={"High share of equities"}>
                        <Tag color="#3b5999">
                            Stocks
                        </Tag>
                    </Tooltip>
                }
                {allocationTag === "Bonds"
                    && <Tooltip title={"High share of fixed-income assets"}>
                        <Tag color="#bd893a">
                            Bonds
                        </Tag>
                    </Tooltip>
                }
                {/*{idInProposal.length > 0*/}
                {/*    && <Tag color="#86bd91">*/}
                {/*        In proposal*/}
                {/*    </Tag>*/}
                {/*}*/}
            </Space>
        </div>
        <div className={"statistic-container"}>
            <CustomLegendItem
                label={'Risk score'}
                value={model.calculated_values?.risk?.risk_score}
            />
            <CustomLegendItem
                label={'Composite rating'}
                value={getSScore(model.calculated_values)}
            />
            <CustomLegendItem
                label={'Stocks / Bonds'}
                value={getAssetAllocationLabel(model.calculated_values)}
            />
            <CustomLegendItem
                label={'Distribution rate'}
                value={ValueFormatter.percent(model.calculated_values['annual_dividend'] / 100)}
            />
            <CustomLegendItem
                label={'Expense Ratio'}
                value={ValueFormatter.percent(model.calculated_values['expense_ratio'] / 100)}
            />
        </div>
    </Card>
}

export default ModelCard;
