/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {Card, Statistic} from 'antd';
import './StressInfo.less';
import React from "react";
import ValueFomatter from "@global/ValueFormatter";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

const {Meta} = Card;

const StressInfo = ({
                        title,
                        returnPercent,
                        dollarPortfolioReturn,
                        risk
                    }) => {

    let formattedDollars = ValueFomatter.compactCurrency(Math.abs(dollarPortfolioReturn))
    let formattedPercent = ValueFomatter.percent(Math.abs(returnPercent), 0)

    let colorClass = 'stress-positive'
    let icon = <ArrowUpOutlined/>

    if (dollarPortfolioReturn > 0) {

    } else {
        colorClass = 'stress-negative'
        icon = <ArrowDownOutlined/>
    }

    const amountToShow = formattedDollars + ' (' + formattedPercent + ')'

    return (
        <Statistic className={colorClass} title={title}
                   value={formattedPercent}
                   prefix={icon}
                   suffix={" / " + formattedDollars}
        />
    )
}

export default StressInfo
