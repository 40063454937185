/* eslint-disable react/prop-types */
import React, {useContext, useEffect, useState} from 'react';
import {Badge, Card, Col, Modal, Row, Tag, Typography} from 'antd';
import './ClientInfo.less';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import ClientQuestionnaireView from "@components/questionnaire/ClientQuestionnaireView";

const {Paragraph} = Typography;
const ClientInfo = ({loading}) => {
    const {client, calculated_values} = useContext(SpasClientContext);
    const [assetEntries, setAssetEntries] = useState([])
    const {Meta} = Card;
    const [fullInfoVisible, setFullInfoVisible] = useState(false)

    const showFullInfo = ({}) => {
        setFullInfoVisible(true)
    }

    useEffect(() => {
        if (!calculated_values) {
            return
        }
        if (Object.keys(calculated_values).length === 0) {
            return
        }
        let asset_classes = calculated_values['asset_class']
        let entries = Object.entries(asset_classes)
        entries.sort(function (a, b) {
            return b[1] - a[1]

        })
        setAssetEntries(entries)
    }, [calculated_values])

    const RiskLine = () => {
        if (client?.questionnaire?.risk) {
            return <Tag color="#E0AD60"style={{cursor: 'pointer'}} onClick={() => showFullInfo(client)}>risk: {calculated_values?.risk?.risk_score}τ,
                desired risk: {client?.questionnaire?.risk}τ</Tag>
        } else {
            return <Tag color="#E0AD60">risk: {calculated_values?.risk?.risk_score}τ,
                desired risk not set.</Tag>
        }
    }

    return (client ? <Row style={{background: '#ffffff', height: 170}}>
        <Col span={21}>
            <div className={"client-info-container"}>
                <Card loading={loading} style={{height: 160}}>
                    <Meta
                        title={client.full_name}
                        description={client.email}
                    /><RiskLine/>
                </Card>
            </div>
        </Col>
        <Col span={3}>
            {assetEntries.map((entry, index) => {
                if (index < 4) {
                    return (<div key={index}>
                        <Badge.Ribbon
                            text={ValueFormatter.percent(entry[1]) + " " + entry[0]}
                            placement={'end'} color={ChartStyles.securityTypeColor(entry[0])}>
                            <Paragraph>&nbsp;</Paragraph>
                        </Badge.Ribbon>
                    </div>)
                }
            })}
        </Col>
        <Modal
            open={fullInfoVisible} title={null} footer={null}
            closable={true}
            destroyOnClose={true}
            width={600}
            onCancel={() => {
                setFullInfoVisible(false)
            }}>
            <ClientQuestionnaireView questionnaire={client?.questionnaire}/>
        </Modal>
    </Row> : null);

}

export default ClientInfo;
