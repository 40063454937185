const normalizeWeight = (source) => {
    let newWeights = {};
    let weightsSum = Object.keys(source).reduce((sum, field) => sum + Math.abs(source[field]), 0)
    for (let field in source) {
        newWeights[field] = Math.abs(source[field]) / weightsSum;
    }

    return newWeights
}

export {normalizeWeight}