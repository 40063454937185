import React, {useContext, useState} from 'react';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import ClientPortfolioSelector from "@components/client/ClientPortfolioSelector";
import {Spin} from "antd";
import ReportBuilder from "@components/report/ReportBuilder";

const ClientReport = () => {
    const {client} = useContext(SpasClientContext);
    // const [loading, setLoading] = useState(false)
    const [selectedPortfolio, setSelectedPortfolio] = useState({
        name: 'Unknown', calculated_values: {}
    })
    const [selectedBenchmark, setSelectedBenchmark] = useState({
        name: 'Unknown', calculated_values: {}
    })

    const getMainPortfolios = () => {
        if (selectedPortfolio._id) {
            return {main_ids: selectedPortfolio._id.split(','), main_calculated_values: selectedPortfolio.calculated_values}
        } else {
            return null
        }
    }

    const getBenchmarkPortfolios = () => {
        if (selectedBenchmark._id) {
            return {benchmark_ids: selectedBenchmark._id.split(','), benchmark_calculated_values: selectedBenchmark.calculated_values}
        } else {
            return null
        }
    }

    return (
        // <Spin style={{height: 800}} spinning={loading} tip="Loading..." size={"large"}>
        <>
            <ClientPortfolioSelector setActual={setSelectedPortfolio} setBenchmark={setSelectedBenchmark}/>
            <ReportBuilder client={client} reportClass="client" getMainPortfolios={getMainPortfolios} getBenchmarkPortfolios={getBenchmarkPortfolios}/>
        </>
        // </Spin>
    )
}

export default ClientReport;
