import React from 'react';
import {Progress} from 'antd';

const HoldSign = ({value}) => {
    return <Progress percent={value} steps={10} size="small" strokeColor={'#a6a6a6'}
                     showInfo={true} status="active" format={(percent) => {
        return "Hold"
    }}/>
}

export default HoldSign;
