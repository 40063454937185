import React, {useContext, useEffect, useState} from 'react';


import {Alert, Button, Card, Col, Empty, Form, message, Progress, Row, Space, Tabs, Typography} from 'antd';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import {useRequest} from "ahooks";
import {getAiThoughts} from "@API/ahooks/proposal";
import PortfolioStats from "@components/portfolio/PortfolioStats";
import parse from 'html-react-parser';
import Markdown from "react-markdown";

const ClientAI = () => {
    const {client, calculated_values, loadData} = useContext(SpasClientContext);
    const [loadedPercent, setLoadedPercent] = useState(0);
    const [impression, setImpression] = useState(client?.related_info?.ai_opinion);
    const [talkingPoints, setTalkingPoints] = useState(client?.related_info?.talking_points);
    const [activeKey, setActiveKey] = useState('impression')

    useEffect(() => {
        setImpression(client?.related_info?.ai_opinion)
        setTalkingPoints(client?.related_info?.talking_points)
    }, [client])

    const {
        data,
        runAsync,
        loading,
        cancel,
        error
    } = useRequest(getAiThoughts, {
        manual: true,
        onError: (error) => {
            message.error("Failed to load AI client advice!")
        }
    });

    const askAI = (type = 'impression') => {
        setLoadedPercent(0);
        let progressInterval = progressIntervalCaller();
        setActiveKey(type)
        runAsync(client._id, type).then((data) => {

            if (data) {
                /*let text = data.data.replaceAll('\n\n', '\n')
                if (type == 'impression') {
                    setImpression(text)

                } else {
                    setTalkingPoints(text)
                }
                setAiText(data.data.replaceAll('\n\n', '\n'))*/
            }
            loadData()
        }).catch((error) => {
            console.error(error);
            message.error("Failed to load AI client advice!")
        }).finally(() => {
            clearInterval(progressInterval)
        })
    }

    const progressIntervalCaller = () => {
        //setAiText(null)
        return setInterval(() => {
            setLoadedPercent((current) => current <= 100 ? current + 1 : current);
        }, 700)
    }

    if (error) {
        message.error("Failed to load AI client advice!")
    }

    return (<div>
            <Space direction={'horizontal'}>
                <Form.Item>
                    <Button type="primary" style={{marginTop: 12, marginBottom: 12}} onClick={() => askAI('impression')}
                            disabled={loading}>Get AI impression</Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" style={{marginTop: 12, marginBottom: 12}}
                            onClick={() => askAI('talking_points')} disabled={loading}>Get talking points</Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" style={{marginTop: 12, marginBottom: 12}}
                            onClick={() => askAI('json')} disabled={loading}>Get json</Button>
                </Form.Item>
            </Space>


            <PortfolioStats calculated_values={calculated_values}/>
            {(client && Object.keys(client.questionnaire).length <= 1) &&
                <Row style={{marginBottom: 12, marginTop: 12}}>
                    <Col span={24}>
                        <Alert
                            message="No questionnaire filled"
                            description="Filling in the questionnaire will give additional data to the AI"
                            type="warning"
                            showIcon
                        />
                    </Col>
                </Row>}
            <Card style={{marginTop: 12}}>
                {loading ? <>
                    <Typography.Paragraph mark={true}>Convincing the computer to serve us...</Typography.Paragraph>
                    <Progress percent={loadedPercent} status="active" strokeColor='#3196B2'/>
                </> : null}

                <Tabs activeKey={activeKey} onChange={(key) => {
                    setActiveKey(key)
                }}>
                    <Tabs.TabPane tab="Impression" key="impression">

                        <Typography.Title level={4}>Holistic Vision<sup
                            style={{color: 'red'}}>Beta</sup></Typography.Title>
                        <Typography.Paragraph>
                            {impression ? <Markdown>{impression}</Markdown>
                                : <Empty description={'No AI impression data yet.'}/>}
                        </Typography.Paragraph>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Points" key="talking_points">
                        <Typography.Title level={4}>Talking points<sup
                            style={{color: 'red'}}>Beta</sup></Typography.Title>
                        <Typography.Paragraph>
                            {talkingPoints ? talkingPoints.split("\n").map((par, index) => {
                                return <Typography.Paragraph key={index}>
                                    {par}
                                </Typography.Paragraph>
                            }) : <Empty description={'No AI impression data yet.'}/>}
                        </Typography.Paragraph>
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div>
    )
}

export default ClientAI;
