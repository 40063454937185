/* eslint-disable react/prop-types */
import React from 'react';
import {List} from "antd";
import "./CustomLegend.less"


const CustomLegendItem = ({label, value, valueMax=null, color=null, customMarker=null, highlight=false}) => {
    return <List.Item title={label} className={"custom-legend-item" + (highlight ? " highlight" : "")}>
        {color && <div className={"marker"} style={{color: color}}>{"\u25CF"}</div>}
        {customMarker && <div className={"marker"}>{customMarker}</div>}
        <span className={"label"}>{label}</span>
        <span className={"value"}>{value}</span>
        {valueMax && <span className={"value-max"}>{valueMax}</span>}
    </List.Item>
}


export default CustomLegendItem;
