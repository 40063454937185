/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {App, ConfigProvider, Typography} from "antd";
import {AssetScreener} from "@components/screener/AssetScreener";


export const ScreenerSpasStyle = () => {
    return <ConfigProvider
        theme={{
            token: {
                colorPrimary: '#3F8DFA',
                colorWarning: '#e0ad60',
                fontSizeHeading1: 48,
                fontFamily: 'Red Hat Display W',
                fontSize: 16,
                fontWeightStrong: 450,
                colorBgBase: '#ffffff',
                colorBgLayout: 'transparent',
                headerBg: 'white',
            }
        }}>
        <App className={'holistico'}>
            <Typography.Title level={3}>Screener</Typography.Title>
            <AssetScreener/>
        </App>
    </ConfigProvider>
}

const Screener = () => {
    return (
        <>
            <Typography.Title level={3}>Screener</Typography.Title>
            <AssetScreener/>
        </>
    );
}

export default Screener;
