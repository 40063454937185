/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {Col, Row, Typography, Empty} from "antd";
import '@root/components/portfolio/Portfolio.less';
import ScoreMarkersStats from "@components/chart/ScoreMarkersStats";
import {EyeInvisibleTwoTone} from "@ant-design/icons";
import SizePie from "@components/chart/SizePie";
import SizeStyleHeat from "@components/chart/SizeStyleHeat";
import SectorBar from "@components/chart/SectorBar";

const {Title, Paragraph} = Typography;
const PlaygroundStockAnalysis = ({selectedPortfolio, selectedBenchmark, loading}) => {

    return (<><Title level={3}>Equities</Title>
        {selectedPortfolio?.calculated_values?.score_markers ?
            <Row gutter={12}>
                <Col span={24}>
                    <Title level={4} className={"spas"}>Score attribution</Title>
                    <Paragraph>Score ratings attribution shows how healthy a portfolio is in terms of fundamental factors
                        exposure. To simplify the analysis, we present portfolio scores, each of which aggregates
                        multiple factors. For example, SScore+ takes into account more than 35 fundamental
                        factors and ratings such as Sales Growth, Momentum, Zacks Rank, etc. Score ratings are
                        normalized to take values from 1 to 100. For each individual stock, the interpretation of
                        score values is following: 70 and above — "Buy", 20 and below — "Sell", between 20 and 70 —
                        "Hold". Portfolio scores are calculated as a weighted average of its holdings scores. You
                        should strive to achieve portfolio scores above 50. Notice that score attribution relates
                        only to the equity part of a portfolio.</Paragraph>
                </Col>
                <Col span={12}>
                    <ScoreMarkersStats
                        score_markers={selectedPortfolio?.calculated_values['score_markers']}
                        title={selectedPortfolio.name} loading={loading}/>
                </Col>
                <Col span={12}>
                    <ScoreMarkersStats
                        score_markers={selectedBenchmark?.calculated_values['score_markers']}
                        title={selectedBenchmark.name} loading={loading}/>
                </Col>
            </Row>
            :
            <Empty style={{margin: "15px 0"}} description="No SScores information"/>
            }
        <Row>
            <Col span={24}>
                <Title level={4} className={"spas"}>Size</Title>
                <Paragraph>Historically, over the long run, Small-Caps have out- performed Large-Cap stocks. However,
                    periodically Large Cap lead the way for periods of 3-6 years. The rotation from Small to Large and
                    back to Small dominance regimes can be swift. A well balanced portfolio will have representation
                    from large, Medium and Small companies in order to provide a smoother long-term ride.</Paragraph>
            </Col>
            <Col span={12}>
                <SizePie loading={loading} allocation={selectedPortfolio.calculated_values['size']}/>
            </Col>
            <Col span={12}>
                <SizePie loading={loading} allocation={selectedBenchmark?.calculated_values?.size}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Title level={4} className={"spas"}>Sector</Title>
                <Paragraph>To provide our investors with adequate diversification, we include most major economic
                    sectors in our portfolios. During the distinct stages of an economic cycle sectors behave
                    differently. During the recovery and expansion cyclical sectors like Consumer Discretionary,
                    Financials, Industrials, Information Technology and Real Estate tend to do well. During the slowdown
                    Consumer Staples, Energy and Materials dominate. Going into recession defensive sectors like
                    Consumer Staples, Healthcare, Telecom and Utilities tend to outperform. It is very difficult to
                    predict the exact timing of a regime change and shift from one cycle stage to another. That is why
                    it is important to have a diversified portfolio where major sectors are represented.</Paragraph>
            </Col>
            {/*<Col span={12}>
                <SectorPie loading={false} allocation={selectedPortfolio.calculated_values['sector']}/>
            </Col>
            <Col span={12}>
                <SectorPie loading={false} allocation={selectedBenchmark?.calculated_values?.sector}/>
            </Col>*/}
            <Col span={24}>
                {<SectorBar loading={loading} allocation={selectedPortfolio.calculated_values['sector']}
                            proposalAllocation={selectedBenchmark?.calculated_values?.sector}/>}
            </Col>
            <Col style={{marginTop: "20px"}} span={24}>
                <Title level={4} className={"spas"}>Size Style</Title>
                <Paragraph>To provide our investors with adequate diversification, we include most major economic
                    sectors in our portfolios. During the distinct stages of an economic cycle sectors behave
                    differently. During the recovery and expansion cyclical sectors like Consumer Discretionary,
                    Financials, Industrials, Information Technology and Real Estate tend to do well. During the slowdown
                    Consumer Staples, Energy and Materials dominate. Going into recession defensive sectors like
                    Consumer Staples, Healthcare, Telecom and Utilities tend to outperform. It is very difficult to
                    predict the exact timing of a regime change and shift from one cycle stage to another. That is why
                    it is important to have a diversified portfolio where major sectors are represented.</Paragraph>
            </Col>
            <Col span={12}>
                <SizeStyleHeat loading={loading}
                               allocation={selectedPortfolio.calculated_values['size_style']}/>
            </Col>
            <Col span={12}>
                <SizeStyleHeat loading={loading}
                               allocation={selectedBenchmark.calculated_values['size_style']}/>
            </Col>
        </Row></>)
}

export default PlaygroundStockAnalysis;
