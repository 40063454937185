import React, {useRef, useState} from "react";
import {Button, Form, Image, Input, message, Result, Space, Spin, Steps, Tooltip} from "antd";
import InvestmentsInfoForm from "@holistico/proposal/new/form/InvestmentsInfoForm";
import GeneralInfoForm from "@holistico/proposal/new/form/GeneralInfoForm";
import {submitExtendedClient, updateExtendedClient} from "@API/clients";
import ReportDownloadButton from "@holistico/proposal/view/report/ReportDownloadButton";
import {useNavigate} from 'react-router-dom';

const initialValues = {
    "id": null,
    "full_name": "",
    "questionnaire": {
        life_expectancy: 95,
        hstatus: 5
    },
    "initial_investments": {
        "uploaded_files": [],
        "cash": 0
    },
    selected_model: null,
    loading: false,
    client: null
}
const NewInvestorForm = ({
                             setClientId = () => {
                             }
                         }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const idValue = Form.useWatch("id", form);
    const loading = Form.useWatch("loading", form);
    const [currentStep, setCurrentStep] = useState(0)

    const silentlyUpdateClient = (id, updatedClientData) => {
        updateExtendedClient(id, updatedClientData, (result, error) => {
            if (error) {
                console.error(result)
            } else {
                console.debug(result)
            }
        })
    }

    const submitForm = (values, final) => {
        let formValues = {...values}
        form.setFieldValue('loading', true)
        submitExtendedClient(formValues, (result, error) => {
            if (error) {
                console.error(result)
                message.error(result);
            } else {
                form.setFieldValue('id', result._id)
                setClientId(result._id)
                form.setFieldValue('client', result)
            }
            form.setFieldValue('loading', false)
            if (final) {
                navigate(`../proposals/${result._id}/client/general-info`)
            }
        })
    }

    const valuesChanged = (changedValues, allValues) => {
        console.debug('Top component on change called')
        console.debug(changedValues, allValues);

        let valuesToSet = {...allValues};

        //submit only if investments details changed
        if (Object.keys(changedValues).includes("initial_investments")) {
            if (Object.keys(changedValues['initial_investments']).includes("uploaded_files")) {
                setCurrentStep(1)
                console.debug('Uploaded files changed')
                let uploadedFiles = changedValues.initial_investments.uploaded_files
                let filesReady = true
                for (let file of uploadedFiles) {
                    if (!['done', 'error'].includes(file.status)) {
                        filesReady = false
                    }
                }
                const lastFile = uploadedFiles[uploadedFiles.length - 1];

                if (!uploadedFiles.length || filesReady) {
                    form.submit()
                    return;
                } else {
                    console.info('Waiting for files to be uploaded')
                }
                console.debug('submitting client')
            } else {
                setCurrentStep(3)
            }
        }
        if (!valuesToSet.id) {
            return
        }
        console.debug('Client values changed, updating the client silently')
        silentlyUpdateClient(allValues.id, valuesToSet)
    }

    return <div>
        <Space style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 12px 12px 12px"
        }}>
            <Tooltip title={!idValue
                ?
                "Proceed with empty proposal form and fill in the data later"
                :
                "Create proposal based on the uploaded portfolio"
            }>
                <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        console.info('Submitting new client')
                        let vals = form.getFieldsValue()
                        submitForm(vals, true)
                    }}
                >
                    {idValue ? "Formulate proposal" : "Fill in later"}
                </Button>
            </Tooltip>
            <ReportDownloadButton
                disabled={!idValue}
                size={"medium"}
                strictPresets={['full_with_fixed_income', 'single']}
                clientId={idValue}
                reportClass="enrollment"
                tooltip={!idValue
                    ?
                    "Submit the client first to be able to get PDF report"
                    :
                    'Download PDF report for the uploaded portfolio. You can choose options by clicking "..."'
                }
            />
        </Space>
        <Form
            initialValues={initialValues}
            layout={'vertical'}
            onValuesChange={valuesChanged}
            form={form}
            onFinish={submitForm}
            name={"proposal-preview"}
            style={{margin: "0 12px"}}
        >
            <Form.Item name={"id"} hidden={true}>
                <Input/>
            </Form.Item>
            <Form.Item name={"client"} hidden={true}>
                <Input/>
            </Form.Item>
            <Form.Item name={"loading"} hidden={true}>
                <Input/>
            </Form.Item>
            <GeneralInfoForm saveData={() => form.submit()}/>
            <InvestmentsInfoForm/>
        </Form>
    </div>

}
export default NewInvestorForm;
