import React, {useRef, useState, useEffect, useMemo, useCallback} from "react";
import {message, Table, Tag, Modal, Descriptions} from "antd";
import { getSchedulerLogs, getSchedulerDistincts } from "@API/logs";

import "./Scheduler.less";

const SchedulerLogs = ({schedulerObject, schedulerEntity}) => {
    const [logs, setLogs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [distincts, setDistincts] = useState(null);
    const abortControllerRef = useRef(new AbortController());

    const logsColumns = useMemo(() => {
        if (!logs || !distincts) {
            return null
        }

        return [
        {
            key: 'timestamp',
            title: "Log time",
            dataIndex: "timestamp",
            render: timestamp => new Date(timestamp + "Z").toLocaleString("en-GB"),
            sorter: true
        },
        {
            key: 'related_info.type',
            title: "Type",
            dataIndex: ["related_info", "type"],
            filters: distincts?.type ? distincts.type.map(v => {return {text: v, value: v}}) : null
        },
        {
            key: "time_status",
            title: "Time status",
            dataIndex: "time_status",
            filters: distincts?.time_status ? distincts.time_status.map(v => {return {text: v, value: v}}) : null
        },
        {
            key: "related_info.result",
            title: "Execution result",
            dataIndex: ["related_info", "result"],
            filters: distincts?.result ? distincts.result.map(v => {return {text: v, value: v}}) : null,
            render: (_, {related_info}) => {
                if (!related_info?.result) {
                    return null
                }
                return <Tag color={related_info?.result === "error" ? "red" : "green"}
                    style={{fontSize: "1.1em"}}>
                        {related_info?.result}
                </Tag>
            }
        },
        {
            key: "message",
            title: "Message",
            dataIndex: "message"
        },
        {
            key: "user",
            title: "User",
            dataIndex: "user",
            filters: distincts.user ? distincts.user.map(v => {return {text: v, value: v}}) : null
        },
    ]}, [logs, distincts])

    const retrieveLogs = (skip, sort, filters) => {
        setLoading(true);
        getSchedulerLogs(schedulerEntity, schedulerObject._id, skip, sort, filters, (data, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while retrieving logs");
                return
            }
            console.log("logs", data)
            setLogs(data);
        }, abortControllerRef.current.signal)
    }

    useEffect(() => {
        retrieveLogs(0, "timestamp,-1", null);
        getSchedulerDistincts(schedulerEntity, schedulerObject._id, (data, error) => {
            if (error) {
                message.error("Error while retrieving distincts");
                return
            }

            setDistincts(data);
        }, abortControllerRef.current.signal)
    }, [schedulerObject])

    useEffect(() => {
        return () => abortControllerRef.current.abort()
    }, [])

    const updateLogsList = (pagination, filters, sorter) => {
        filters = Object.fromEntries(Object.entries(filters).filter(([key, val]) => val !== null));
        console.log(filters)
        const {current, pageSize} = pagination;
        const {field, order} = sorter;
        const sort = order !== undefined ? (order === "ascend" ? `${field},1` : `${field},-1`) : "timestamp,-1";
        retrieveLogs((current - 1) * pageSize, sort, filters);
    }

    const prepareLogDescription = useCallback((logPart) => {
        const titleFormatter = (key) => {
            key = key.split("_");
            key[0] = key[0][0].toUpperCase() + key[0].slice(1)
            return key.join(" ")
        }
        return <Descriptions bordered style={{width: "100%"}}
            labelStyle={{width: "15%"}}>
            {Object.keys(logPart).map((key, inx) => {
                if (typeof logPart[key] === "string") {
                    return <Descriptions.Item key={inx} label={titleFormatter(key)} span={3}>
                            <div style={{maxHeight: "300px", overflow: "auto"}}>
                                <pre style={{whiteSpace: "pre-wrap", marginBottom: 0}}>
                                    {logPart[key]}
                                </pre>
                            </div>
                    </Descriptions.Item>
                }
                if (logPart[key] instanceof Array) {
                    return <Descriptions.Item key={inx} label={titleFormatter(key)} span={3}>
                            <div style={{maxHeight: "300px", overflow: "auto"}}>
                                {logPart[key].map((item, inx) => {
                                    return <pre key={inx} style={{whiteSpace: "pre-wrap", marginBottom: 0}}>
                                        {item}
                                    </pre>
                                })}
                            </div>
                    </Descriptions.Item>
                }
                if (logPart[key] instanceof Object) {
                    return <Descriptions.Item key={inx} label={titleFormatter(key)} span={3}>
                        {prepareLogDescription(logPart[key])}
                    </Descriptions.Item>
                }
            })}
        </Descriptions>
    }, [])

    const logView = useMemo(() => {
        if (!selectedLog) {
            return null
        }
        return prepareLogDescription(selectedLog)
    }, [selectedLog, prepareLogDescription])

    return (<>
        <Table
            loading={loading} style={{width: "100%"}} columns={logsColumns} dataSource={logs?.logs}
            onChange={updateLogsList}
            pagination={{
                pageSize: 10,
                total: logs?.logs_count,
                showSizeChanger: false
            }}
            rowClassName="scheduler_logs-table-row"
            onRow={(record, rowIndex) => {
                return {
                onClick: () => setSelectedLog(record)
                };
            }}
        />
        <Modal
            width={1100}
            open={selectedLog !== null}
            title="Log"
            footer={null}
            onCancel={() => setSelectedLog(null)}
        >
            {logView}
        </Modal>
    </>);
}

export default SchedulerLogs