import {Col, Empty, Row} from 'antd'
import React from "react";
import FISectorPie from "@holistico/chart/FISectorPie";

const FISectorCompareRow = ({original_values, recommended_values}) => {
    return <Row style={{width: '100%'}} gutter={[24,24]}>
        <Col span={12}>
            <FISectorPie allocation={original_values?.fi_super_sector} showChart={true} showLegend={false}/>
        </Col>
        <Col span={12}>
            <FISectorPie allocation={recommended_values?.fi_super_sector} showChart={true} showLegend={false}/>
        </Col>
    </Row>
}

export default FISectorCompareRow
