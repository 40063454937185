import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Flex,
    Form,
    Input,
    InputNumber,
    Row,
    Space,
    Tooltip,
} from 'antd';
import ValueFormatter from '@global/ValueFormatter';
import {QuestionCircleOutlined} from "@ant-design/icons";
import PositionProxy from "@components/portfolio/position/PositionProxy";
import {useUpdateEffect} from "ahooks";


const emptyAsset = {
    ticker: null,
    name: null,
    market_value: null,
    proxy: null
}


const SecurityForm = ({handleSubmit, asset, trigger}) => {
    const [form] = Form.useForm();
    const tickerRef = useRef(null);
    const nameRef = useRef(null);
    const marketValueRef = useRef(null);
    const proxyRef = useRef(null);
    const saveRef = useRef(null);

    useEffect(() => {
        console.log(asset)
        form.setFieldsValue({...emptyAsset, ...asset});
    }, [asset]);

    useEffect(() => {
        // Doesn't always work without timeout
        setTimeout(() => tickerRef.current.focus(), 100);
    }, [trigger]);

    const onFinish = (values) => {
        let toSubmitValues = {...asset, ...values}
        console.info(toSubmitValues)
        handleSubmit(toSubmitValues);
    }

    return <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
    >
        <Row gutter={[16, 0]}>
            <Col md={6} lg={6} sm={24} xs={24}>
                <label>Ticker/CUSIP</label>
                <Form.Item
                    name="ticker"
                    rules={[{
                        required: true,
                        message: 'Please enter asset ticker!'
                    }]}
                >
                    <Input
                        ref={tickerRef}
                        autoFocus
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                nameRef.current.focus();
                            }
                        }}
                    />
                </Form.Item>
            </Col>
            <Col md={13} lg={13} sm={24} xs={24}>
                <label>Name</label>
                <Form.Item
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please enter asset name!'
                    }]}
                >
                    <Input
                        ref={nameRef}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                marketValueRef.current.focus();
                            }
                        }}
                    />
                </Form.Item>
            </Col>
            <Col md={5} lg={5} sm={24} xs={24}>
                <label>Market value</label>
                <Form.Item
                    name="market_value"
                    rules={[{
                        required: true,
                        message: 'Please enter market value!'
                    }]}
                >
                    <InputNumber
                        formatter={(x) => ValueFormatter.currencyCustom(x, "$")}
                        min={1}
                        step={1000}
                        style={{width: "100%"}}
                        ref={marketValueRef}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                proxyRef.current.focus();
                            }
                        }}
                    />
                </Form.Item>
            </Col>
            <Col md={19} lg={19} sm={24} xs={24}>
                <Tooltip title={
                    "For the unknown asset, proxy is used to approximate " +
                    " this asset return, risk and other characteristics." +
                    " Fot the better result, proxy must behave " +
                    " similarly to this asset."
                }>
                    <Space>
                        <label>Proxy asset</label>
                        <QuestionCircleOutlined style={{color: "rgb(140, 140, 140)"}}/>
                    </Space>
                </Tooltip>
                <Form.Item
                    name="proxy"
                    rules={[{
                        required: true,
                        message: 'Please choose proxy!'
                    }]}
                >
                    <PositionProxy
                        thisRef={proxyRef}
                        nextRef={saveRef}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Flex gap={"small"} justify={"flex-end"} style={{marginTop: 16}}>
            <Button
                type="default"
                htmlType="button"
                onClick={() => handleSubmit(null)}
                style={{width: 120}}
            >
                Cancel
            </Button>
            <Button
                type="primary"
                htmlType="submit"
                style={{width: 120}}
                ref={saveRef}
            >
                Save
            </Button>
        </Flex>
    </Form>
}

export default SecurityForm;
