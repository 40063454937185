const roles = [{
    label: 'charismatic',
    max_tokens: 800,
    name: 'Charismatic',
    model: 'gpt-4',
    character_params: {
        charm: 10,
        humor: 8,
        mood: 7,
        intelligence: 9,
        assertiveness: 6,
    }
}, {
    label: 'pusher',
    name: 'Pusher',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 3,
        humor: 8,
        mood: 4,
        intelligence: 8,
        assertiveness: 10,
    }
},{
    label: 'cynic',
    name: 'Cynic',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 0,
        humor: 10,
        mood: 0,
        intelligence: 5,
        assertiveness: 10,
    }
}, {
    label: 'intelligent',
    name: 'Intelligent',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 4,
        humor: 2,
        mood: 3,
        intelligence: 10,
        assertiveness: 6,
    }
}, {
    label: 'buffet',
    name: 'Warrent Buffet',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 2,
        humor: 1,
        mood: 6,
        intelligence: 7,
        assertiveness: 5,
    }
}, {
    label: 'blakfein',
    name: 'Lloyd Blankfein',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 8,
        humor: 5,
        mood: 6,
        intelligence: 9,
        assertiveness: 8,
    }
}, {
    label: 'gthumberg',
    name: 'Gretta Thumberg',
    max_tokens: 800,
    model: 'gpt-4',
    character_params: {
        charm: 10,
        humor: 0,
        mood: 1,
        intelligence: 1,
        assertiveness: 10,
    }
}]

export {roles}
