/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Empty, Spin, Tabs, Typography} from 'antd';
import ValueFormatter from "@global/ValueFormatter";
import Neighbours from "@components/asset/Neighbours";
import GeneralInfo from './GeneralInfo';
import ScoreAttribution from './ScoreAttribution';
import Localizator from "@global/Localizator";
import parse from "html-react-parser";


const {TabPane} = Tabs;
const generalInfo = {
    "ticker": {
        title: "Ticker"
    },
    "security_type": {
        title: "Security type"
    },
    "sector": {
        title: "Sector",
        formatter: (v) => Localizator.t(`stock.${v}`)
    },
    "size": {
        title: "Size"
    },
    "vbg_style": {
        title: "Style"
    },
    "sscore_plus": {
        title: "SScore+",
        formatter: (v) => ValueFormatter.round(v, 0)
    },
    "mkval": {
        title: "Market capitalization",
        formatter: (v) => ValueFormatter.compactCurrency(v * 10 ** 6)
    },
}

const StockInfo = ({fullAsset, otherSelected, loadOpenApiData}) => {
    const [aiText, setAiText] = useState("");
    const [loading, setLoading] = useState(false)
    const run = () => {
        setLoading(true)
        loadOpenApiData((text) => {
            setAiText(text)
            setLoading(false)
        })
    }
    useEffect(() => {
        setAiText("")
    }, [fullAsset])

    const memoScoreAttribution = useMemo(() => {
        return <ScoreAttribution fullAsset={fullAsset}/>
    }, [fullAsset])

    const memoGeneralInfo = useMemo(() => {
        return <GeneralInfo fullAsset={fullAsset} generalInfo={generalInfo}/>
    }, [fullAsset])

    if (fullAsset) {
        return (
            <Card>
                {memoGeneralInfo}
                <div style={{marginTop: "12px"}}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Ratings" key="1">
                            {memoScoreAttribution}
                        </TabPane>
                        <TabPane tab="Peers" key="2">
                            <Neighbours
                                neighbours={fullAsset['k_neighbours']}
                                neighborClicked={otherSelected}
                            />
                        </TabPane>
                        <TabPane tab={<>&nbsp;AI<sup style={{color: 'red'}}>*</sup></>} key={"3"}>
                            <Button onClick={run} disabled={loading}>Get AI impression</Button>
                            <Spin spinning={loading} tip={"Talking to AI"}>
                                <Typography.Paragraph>
                                    {aiText.split("\n\n").map(par => {
                                        return <Typography.Paragraph>
                                            {parse(par)}
                                        </Typography.Paragraph>
                                    })
                                    }
                                </Typography.Paragraph>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
        )
    } else {
        return (
            <Empty description={'No asset selected'}/>
        )
    }
}

export default StockInfo;
