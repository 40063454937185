/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Empty, List} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import {Pie} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import {normalizeWeight} from './utils';
import CustomLegendItem from "@holistico/chart/CustomLegend";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


export const assetClassSortOrder = {
    "US Equity": 1,
    "Non-US Equity": 2,
    "US Bond": 3,
    "Non-US Bond": 4,
    "Other": 5,
    "Cash": 6
}


export const calcData = (allocation) => {
    const normilizedAlloc = normalizeWeight(allocation);
    let _data = Object.keys(normilizedAlloc).map(key => {
        return {
            label: key,
            value: normilizedAlloc[key]
        }
    }).filter(item => item.value !== 0);

    let _pure_data = Object.keys(allocation).map(key => {
        return {
            label: key,
            value: allocation[key]
        }
    });
    _data.sort(function (a, b) {
        return (assetClassSortOrder[a.label] - assetClassSortOrder[b.label])
            || a.label.localeCompare(b.label);
    });

    _pure_data.sort(function (a, b) {
        return (assetClassSortOrder[a.label] - assetClassSortOrder[b.label])
            || a.label.localeCompare(b.label);
    });

    return [_data, _pure_data]
}


const AssetClassPie = ({allocation, showChart = true, showLegend = true}) => {
    const [data, setData] = useState([]);
    const [pureData, setPureData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    useEffect(() => {
        if (!allocation) {
            return
        }

        const [_data, _pure_data] = calcData(allocation)
        setData(_data)
        setPureData(_pure_data)
        console.debug(`normilizedAlloc is ${JSON.stringify(_data)}`)

        const values = Object.values(allocation);
        const labels = Object.keys(allocation);

        const max_value = Math.max(...values);
        const max_label = labels[values.indexOf(max_value)];

        const _config = {
            title: 'Asset Class Distribution',
            angleField: 'value',
            data: _data,
            colorField: 'label',
            legend: false,
            color: (item) => {
                return ChartStyles.securityTypeColor(item.label);
            },
            pieStyle: (item) => {
                return {
                    stroke: allocation[item.label] >= 0 ? "white" : "red",
                    lineWidth: 2
                }
            },
            label: {
                style: {
                    fontSize: 12,
                    fontFamily: 'Red Hat Display W',
                    fontWeight: 350,
                },
                text: (item) => {
                    return item.label
                },
                content: (item) => {
                    return item.label
                }
            },
            responsive: false,
            radius: 0.7,
            innerRadius: 0.8,
            statistic: {
                title: {
                    formatter: () => ValueFormatter.softPercent(max_value),
                    style: {
                        fontSize: 16,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 350,
                    },
                    offsetY: -6,

                },
                content: {
                    formatter: () => max_label,
                    style: {
                        fontSize: 22,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 600,
                        height: "1.2em"
                    },
                    offsetY: -6,
                },
            },
            meta: {
                percent: {
                    formatter: (v) => {
                        return ValueFormatter.oneDigitPercent(v)
                    }
                }
            },
            tooltip: {
                formatter: (item) => {
                    let value = allocation[item.label];
                    if (!value) {
                        value = allocation[item.label.split(" ")[0]]
                    }

                    console.log(value)
                    return {
                        name: item.label,
                        value: ValueFormatter.oneDigitPercent(value),
                    };
                },
            },
        };
        setConfig(_config)
    }, [allocation])

    return <div className={"fade-in-500"}>
        {data && data.length > 0
            ?
            <>
                {showChart && <Pie {...config}/>}
                {showLegend && <List
                    className={"custom-legend"}
                    dataSource={pureData}
                    renderItem={
                        item => (
                            <CustomLegendItem
                                label={item.label}
                                value={ValueFormatter.oneDigitPercent(item.value)}
                                color={ChartStyles.securityTypeColor(item.label)}
                            />
                        )
                    }
                />}
            </>
            :
            <NoDataPlaceholder description={"No asset class information"}/>
        }
    </div>
}


export default AssetClassPie;
