import React from "react";
import {Col, Row} from "antd";
import RepresentativeProblemClients from "@components/supervision/indicators/RepresentativeProblemClients";

const RepresentativeTreeTitle = ({representative, flagTitle}) => {
    return (
        <Row style={{cursor: "default", width: "500px"}}>
            <Col span={10}>{representative.representative_name}</Col>
            <Col span={8} align={'right'}>
                <RepresentativeProblemClients
                    flagTitle={flagTitle}    
                    allClientsCount={representative.total_counters.clients} 
                    badClientsCount={representative.problem_counters.clients}
                />
            </Col>
        </Row>
    )
}

export default RepresentativeTreeTitle
