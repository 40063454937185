import React from "react";
import {Flex, theme} from "antd";
// import "./NestedCard.less"


const {useToken} = theme;


const NestedCard = ({background, horizontal=false, style={}, children}) => {
    const {token} = useToken();

    return <Flex
        vertical={!horizontal}
        style={{
            margin: horizontal
                ? `${-token.paddingLG}px ${token.paddingLG}px ${-token.paddingLG}px ${-token.paddingLG}px`
                : `${-token.paddingLG}px ${-token.paddingLG}px ${token.paddingLG}px ${-token.paddingLG}px`,
            padding: token.paddingLG,
            background: background,
            borderRadius: token.borderRadiusLG - 1,
            ...style
        }}
        className={"nested-card"}
    >
        {children}
    </Flex>
}

export default NestedCard;
