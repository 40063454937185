/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {Card, Col, Row, Statistic, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import './Portfolio.less';
import {showAi} from "@holistico/proposal/view/ai/AiSideBar";

const {Paragraph, Text, Title} = Typography;

const {Meta} = Card;


const columnSpan = () => {
    if(showAi()) {
        return {xl: 4, lg: 8, md: 12, sm: 12, xs:12}
    } else {
        return {xl: 4, lg: 4, md: 8, sm: 12, xs:12}
    }
}
/**
 *
 * @param rId id for the chart
 * @param link route to when clicked
 * @param calculated_values riskPlannedClient.assetDetails or riskPlannedClient.proposedAssetDetails
 * @param title
 * @param loading if is loading currently
 * @returns {JSX.Element} Card containing basic portfolio summary info
 * @constructor
 */
const PortfolioStats = ({calculated_values = {}, loading}) => {
    useEffect(() => {


    }, []);

    return (
        <div className={"portfolio-stats"}>
            <Row gutter={[24, 24]}>
                <Col {...columnSpan()}>
                    <Statistic title="Risk Score"
                               value={calculated_values?.risk?.risk_score} float={'center'} precision={0}/>

                </Col>
                <Col  {...columnSpan()}>
                    <Statistic title="Market value" value={ValueFormatter.currency(calculated_values['market_value'])}
                               precision={0}/>
                </Col>
                <Col l {...columnSpan()}>
                    <Statistic title="# of positions" value={calculated_values['unique_pos_count']}
                               precision={0}/>
                </Col>
                <Col {...columnSpan()}>
                    <Statistic title="Distribution rate"
                               value={ValueFormatter.percent(calculated_values['annual_dividend'] / 100)}/>
                </Col>
                <Col  {...columnSpan()}>
                    <Statistic title="Expense ratio"
                               value={ValueFormatter.percent(calculated_values['expense_ratio'] / 100)}/>
                </Col>
                <Col  {...columnSpan()}>
                    <Statistic title="Projected return"
                               value={ValueFormatter.percent(calculated_values?.risk?.projected_return_1yr / 100)}/>
                </Col>
            </Row>
        </div>
    );
}

export default PortfolioStats;
