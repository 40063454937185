import React from "react";
import {Checkbox, Flex, Form, Space} from 'antd';
import Localizator from "@global/Localizator";


const GroupingInput = ({allGroupings, plusDate=false}) => {
    return <Form.Item
        name={"groupings"}
        label={<b>Group by</b>}
        tooltip={'Defines stocks groups. "Groups weights" option below defines ' +
            'the weights of these groups in the portfolio or/and in the benchmark.'}
        className={'groupings'}
    >
        <Checkbox.Group>
            <Flex vertical>
                {allGroupings.map(gr => {
                    return <Checkbox
                        disabled={gr === "date"}
                        key={gr}
                        value={gr}
                    >
                        {Localizator.t(`factor.${gr}`)}
                    </Checkbox>
                })}
            </Flex>
        </Checkbox.Group>
    </Form.Item>
}

export default GroupingInput
