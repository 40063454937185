import React, {useState} from "react";
import {Form, Input, message, Modal, Select, Spin} from "antd";
import {createUser} from "@API/users";
import {useNavigate} from "react-router-dom";


const AddUser = ({addModal, setAddModal, reloadUsers}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCancel = () => {
        form.resetFields();
        setAddModal(false);
    }

    const onFinish = values => {
        setLoading(true);
        createUser(values, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error(resp);
                return
            }
            message.success("New user was created successfully");
            handleCancel();
            navigate(`${resp}`)
        })
    }

    return (
        (<Modal
            open={addModal}
            title="User creation"
            onOk={form.submit}
            onCancel={handleCancel}
            okText="Add"
        >
            <Spin size={"large"} spinning={loading} tip="Client is created...">
                <Form form={form} name="user_creation" onFinish={onFinish} autoComplete="off" scrollToFirstError layout="vertical">
                    <Form.Item
                        name="email"
                        label="Email"
                        requiredMark={false}
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                            },
                        {
                            required: true,
                            message: 'Missing email',
                        },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={['details', 'meta', 'full_name']}
                        label="Full name"
                        rules={[
                        {
                            required: true,
                            message: 'Missing name',
                        },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                        {
                            required: true,
                            message: 'Missing password',
                        },
                        {
                            min: 7,
                            message: 'Password requires 7 or more symbols',
                        }
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        name="confirmed_password"
                        label="Confirm password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('The two passwords that you entered do not match'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        name={['details', 'roles']}
                        label={`Roles`}
                        rules={[{required: true, message: "At least one role needed"}]}
                    >
                        <Select mode="tags" maxTagCount={'responsive'}>
                            <Select.Option value="admin"/>
                            <Select.Option value="head"/>
                            <Select.Option value="sales"/>
                            <Select.Option value="dash_admin"/>
                            <Select.Option value="client"/>
                        </Select>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>)
    );
}

export default AddUser
