import {Button, Typography} from "antd";
import React, {useState} from "react";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ActionInfo from "@holistico/aux/ActionInfo";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import RiskStatisticPanel, {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/RiskStatisticPanel";
import ModelsCarousel
    from "@holistico/proposal/view/client/ModelsCarousel";
import BehavioralQuizForm
    from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuizForm";
import "./BehavioralQuiz.less"
import {
    HideAiButton,
    ShowAiButton
} from "@holistico/proposal/view/ai/AiSideBar";
import {NavLink} from "react-router-dom";


export const getQuizTotal = (answers) => {
    let total = 0
    for (let i in answers) {
        total += answers[i]
    }
    return total
}


export const getRiskScoreByQuizScore = (total) => {
    if ( total < 25 ) {
         return Math.round(total + 25 - 2.5*(25 - total))
    } else if ( total >= 25 ) {
         return Math.round(total + 25 + 0.4*(total - 25))
    } else {
        return null
    }
}


const CfaQuote = () => {
    return <Typography.Paragraph style={{
        fontSize: "20px",
        color: "#a2a2a2",
        maxWidth: "600px"
    }}>
        <blockquote>
            <div style={{
                fontStyle: "italic",
                marginBottom: "16px"
            }}>
                An investor’s behavioral loss tolerance can upset
                the most carefully devised quantitative portfolio
                strategy. Best practice is to use psychometric
                tools (often questionnaires) that have
                demonstrated reliability and validity in predicting
                an investor’s emotional and behavioral tendencies
                around loss of portfolio value and investing discipline.
            </div>
            CFA Institute, Investment Risk Profiling, 2020
        </blockquote>
    </Typography.Paragraph>
}


const BehavioralQuiz = ({}) => {
    const {proposal, updateQuestionnaire, loading} = useHolisticoProposalContext()
    const [openQuiz, setOpenQuiz] = useState(false)
    const quiz = proposal?.questionnaire?.behavioral_quiz
    const behavioralScore = proposal?.questionnaire?.risk_profile?.behavioral
    const behavioralLevel = getToleranceLevelByRiskScore(behavioralScore)

    const submitQuizResults = (values) => {
        if (!values) {
            return
        }
        const total = getQuizTotal(values["answers"])
        const riskScore = getRiskScoreByQuizScore(total)
        const questionnaire = {"behavioral_quiz": {...values, total: total}}
        if (riskScore) {
            questionnaire["risk_profile"] = {"behavioral": riskScore}
        }

        updateQuestionnaire(questionnaire, true, () => {
            setOpenQuiz(false)
        })
    }


    return <>
        <div className={'proposal-item-header'}>
            <Typography.Title level={3}>
                Behavioral quiz
            </Typography.Title>
            <ShowAiButton/>
        </div>
        <div className={"page-content"} style={{padding: "0"}}>
            {openQuiz
                && <div style={{padding: "24px 28px"}}>
                    <BehavioralQuizForm
                        initialValues={quiz}
                        onFinish={submitQuizResults}
                    />
                </div>
            }
            {!openQuiz && !behavioralScore
                && <>
                    <RiskStatisticPanel active="behavioral"/>
                    <div style={{padding: "32px 28px 72px 28px"}}>
                        <ActionInfo>
                            The quiz estimates client's subconscious willingness
                            to take risk
                        </ActionInfo>
                        <div className={"hint"} style={{marginLeft: "32px", marginTop: "8px", whiteSpace: "pre-line"}}>
                            {"Alternatively, you can fill the "}
                            <NavLink
                                to={`/ria/proposals/${proposal?._id}/client/general-info`}>
                                Questionnaire
                            </NavLink>
                            {" to estimate client's ability to take risk.\n"}
                            {"To estimate client's willingness to take risk, use "}
                             <NavLink
                                to={`/ria/proposals/${proposal?._id}/client/risk-tolerance`}>
                                Risk tolerance
                            </NavLink>
                            {" section."}
                        </div>
                        {/*<CfaQuote/>*/}
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => setOpenQuiz(true)}
                            style={{width: "200px", marginTop: "32px"}}
                        >
                            Start the quiz
                        </Button>
                    </div>
                </>
            }
            {!openQuiz && behavioralScore
                && <>
                    <RiskStatisticPanel active="behavioral"/>
                    <div style={{ padding: "48px 28px 72px 28px"}}>
                        <RiskResult
                            title={`Behavioral risk score: ${behavioralScore}`}
                            description={`${behavioralLevel} risk tolerance`}
                            success={true}
                        >
                            <Button
                                // type="secondary"
                                key="console"
                                onClick={() => setOpenQuiz(true)}
                                style={{width: "200px"}}
                            >
                                Open the quiz
                            </Button>
                        </RiskResult>
                    </div>
                </>
            }
            {!loading && !openQuiz
                && <ModelsCarousel
                    riskScore={behavioralScore}
                    active={!openQuiz && !!behavioralScore}
                    inactiveMessage={"Models will become available after passing the quiz"}
                />
            }
        </div>
    </>
}

export default BehavioralQuiz;
