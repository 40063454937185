import React from "react";
import {Col, Row, Spin, Typography} from 'antd';
import ExportConfigsList from "./ExportConfigsList";


const AssetDataExport = () => {
    // const [weeklyLoading, setWeeklyLoading] = useState(false);
    // const [selectedConfig, setSelectedConfig] = useState(null);

    return (
        <Spin style={{height: 300}} spinning={false} tip="Weekly creation in progress..."
              size={"large"}>
            <div className={"data-export"}>
                <Typography.Title level={4} className={"spas"}>Download</Typography.Title>
                <Row>
                    <Col span={24}>
                        <ExportConfigsList/>
                    </Col>
                </Row>
                {/*<Row justify="start" gutter={[16, 0]}>
                    <Col span={12}>
                        <Button type="primary" onClick={prepareDownloadWeekly}>
                            <CloudDownloadOutlined/> Download Weekly
                        </Button>
                    </Col>

                    <Col span={12}>
                        <ExportOptionsList setConfig={setSelectedConfig} selectedConfig={selectedConfig}/>
                    </Col>
                </Row>*/}
            </div>

        </Spin>
    )
}

export default AssetDataExport
