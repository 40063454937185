/* eslint-disable react/prop-types */
import React, {useContext} from 'react';
import {Col, Divider, Row} from 'antd';

import OrionForm from "./OrionForm";
import CloneForm from "./CloneForm";
import EmptyForm from "./EmptyForm";

import '../Portfolio.less';
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import FileForm from "@components/portfolio/new/FileForm";
import {useAuth} from "@hooks/AuthHook";

const PortfolioCreator = ({hideForm, type}) => {
    // const [loading, setLoading] = useState(false);
    const auth = useAuth()

    const {addEmpty, addFromFile, addFromOrion, addFromOther} = useContext(PortfolioListContext);
    // const [isLoadingData, setIsLoadingData] = useState(false);


    // if (isLoadingData) {
    //     return <div className="loadingBox" style={{height: '150px'}}><Spin size="large"></Spin></div>
    // }

    return <div style={{background: '#ffffff', padding: 20}}>
        <Row>
            <Col span={24}>
                <Divider plain>Clone Existing / Copy Model</Divider>
                <CloneForm clonePortfolio={addFromOther} type={type}/>
            </Col>

        </Row>
        {auth.orionEnabled() && <Row>
            <Col span={11}>
                <Divider plain>Export from Orion</Divider>
                <OrionForm addFromOrion={addFromOrion}/>
            </Col>
            <Col span={11} style={{marginLeft: "auto"}}>
                <Divider plain>Create Empty Portfolio</Divider>
                <EmptyForm createEmpty={addEmpty} portfolioType={type}/>
            </Col>
        </Row>}
        <Row>
            <Col span={24}>
                <Divider plain>Upload a file</Divider>
                <FileForm uploadFile={addFromFile} portfolioType={type}/>
            </Col>
        </Row>

    </div>
}

export default PortfolioCreator;
