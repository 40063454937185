/* eslint-disable react/prop-types */
// import React, {useEffect, useState} from 'react';
import React from 'react';
import {Divider} from 'antd';
import './PortfolioList.less';
import PortfolioList from './PortfolioList';

// const {Paragraph} = Typography;
const portfolioTypes = {
    client: "client",
    proposed: "proposed"
};
/**
 * @param setSelection called when user selected needed portfolios
 * @returns {JSX.Element} Multiselect list of separated proposed and client portfolios
 * @constructor
 */
const StaticTypifiedPortfolioList = ({proposedPortfolios, clientPortfolios, setSelection, showEmpty = true, showMarketValue = true}) => {

    // const [portfolioPool, setPortfolioPool] = useState([]);
    // const [selectedPortfolio, setSelectedPortfolio] = useState([]);
    // const [proposedPortfoliosProxy, setProposedPortfoliosProxy] = useState(proposedPortfolios)
    // const [clientPortfoliosProxy, setClientPortfoliosProxy] = useState(clientPortfolios)

    const selectPortfolio = (portfolio, toAdd) => {
        // setSelectedPortfolio(portfolio)
        setSelection(portfolio)
    }

    return (
        <div>
            {/* <Paragraph style={{fontSize: 'x-large', marginTop: '0.4em', marginBottom: '0.3em'}}></Paragraph> */}
            <PortfolioList portfolios={clientPortfolios}
                           itemSelected={selectPortfolio} multi={false}
                           portfolioType={portfolioTypes.client}
                           showEmpty={showEmpty}
                           showMarketValue={showMarketValue}/>
            <Divider />
            {/* <Paragraph style={{fontSize: 'x-large', marginTop: '0.4em', marginBottom: '0.3em'}}></Paragraph> */}
            <PortfolioList portfolios={proposedPortfolios}
                           itemSelected={selectPortfolio} multi={false} portfolioType={portfolioTypes.proposed} showEmpty={showEmpty} showMarketValue={showMarketValue}/>
        </div>
    )
}

export default StaticTypifiedPortfolioList;
