import {Link} from "react-router-dom";
import {ExperimentOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import React from "react";

const ActionToPlayground = ({client}) => {
    return <Tooltip title={"Analyze in SPAS playground"}>
        <Link className={'supervision-client-additional_link'}
              to={"/playground?main_supervision_id=" + client.client_id}
              relative="path" target={'_blank'}><ExperimentOutlined/></Link>
    </Tooltip>
}

export default ActionToPlayground
