import React from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams
} from "react-router-dom";
import {
    HolisticoProposalContextProvider
} from "@hooks/ProposalContext";
import ProposalHeader from "@holistico/proposal/view/ProposalHeader";
import ProposalMegaMenu from "@holistico/proposal/view/ProposalMegaMenu";
import Fundamentals from "@holistico/proposal/view/fundamentals/Fundamentals";

import "./HolisticoProposal.less"
import StressTests from "@holistico/proposal/view/stress_tests/StressTests";
import RetirementMap
    from "@holistico/proposal/view/retirement_map/RetirementMap";
import ClientPortfolioComposition
    from "@holistico/proposal/view/portfolios/ClientPortfolioComposition";
import ProposedPortfolioComposition
    from "@holistico/proposal/view/portfolios/ProposedPortfolioComposition";
import ClientChat from "@holistico/proposal/view/ai/ClientChat";
import AiConfiguration from "@holistico/proposal/view/ai/AiConfiguration";
import ConfiguredChat from "@holistico/proposal/view/ai/ConfiguredChat";
import RiskTolerance from "@holistico/proposal/view/client/risk_tolerance/RiskTolerance";
import BehavioralQuiz
    from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuiz";
import Questionnaire
    from "@holistico/proposal/view/client/questionnaire/Questionnaire";
import AiSidebarContainer from "@holistico/ai/sidebar/AiSidebarContainer";
import MyPortfolio from "@components/retail/portfolio/MyPortfolio";


const aiPath = () => {
    const path = useLocation().pathname
    const aiPaths = [
        "ai/conversation",
        "ai/configuration",
        "ai/assistant",
    ]
    for (let aiPath of aiPaths) {
        if (path.includes(aiPath)) {
            return true
        }
    }
    return false
}


const HolisticoProposal = () => {
    const {id} = useParams();

    return <HolisticoProposalContextProvider proposal_id={id}>
        <div className={"proposal-view"}>
            <ProposalHeader/>
            <ProposalMegaMenu proposalId={id}/>
            <AiSidebarContainer proposalId={id} chatType={"proposal"} hideAi={aiPath()} hideNotes={aiPath()}>
                <Routes>
                    <Route path="" element={<Navigate to="client/general-info" replace/>}/>
                    <Route path="portfolios/client-portfolio" element={<ClientPortfolioComposition/>}/>
                    <Route path="portfolios/proposed-portfolios" element={<ProposedPortfolioComposition/>}/>
                    <Route path="client/general-info" element={<Questionnaire/>}/>
                    <Route path="client/behavioral-quiz" element={<BehavioralQuiz/>}/>
                    <Route path="client/risk-tolerance" element={<RiskTolerance/>}/>
                    <Route path="ai/conversation" element={<ClientChat/>}/>
                    <Route path="ai/configuration" element={<AiConfiguration/>}/>
                    <Route path="ai/assistant" element={<ConfiguredChat/>}/>
                    <Route path="fundamentals/*" element={<Fundamentals/>}/>
                    <Route path="other/retirement-map" element={<RetirementMap/>}/>
                    <Route path="other/stress-tests" element={<StressTests/>}/>
                </Routes>
            </AiSidebarContainer>
        </div>
    </HolisticoProposalContextProvider>
}


export default HolisticoProposal
