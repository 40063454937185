import {UserAddOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import React, {useState} from "react";
import OrionProposalCreatorModal from "@components/clients/OrionProposalCreatorModal";

const ActionAddProposal = ({client}) => {
    const [orionId, setOrionId] = useState(null);
    return <><Tooltip title={"Add new client proposal"}>
        <UserAddOutlined onClick={() => setOrionId(client.external_id)}/>
    </Tooltip>
        <OrionProposalCreatorModal
            orionId={orionId}
            cancelAction={() => setOrionId(null)}
        />
    </>
}

export default ActionAddProposal
