import React from "react";
import {Col, Progress, Row, Select} from "antd";
import {DeleteOutlined, FileDoneOutlined, FileUnknownOutlined} from "@ant-design/icons";

const {Option} = Select;

const SelectType = ({fileType, types, setType}) => {
    return (
        <Select value={fileType} style={{width: 200}} onChange={setType}>
            {types.map(type => {
                return <Option key={type} value={type}>{type.replaceAll("_", " ").toUpperCase()}</Option>
            })}
        </Select>
    )
}

const ImportFileRow = ({index, file, changeFile, deleteFile, types}) => {
    const setType = value => {
        changeFile(index, {
            ...file,
            type: value
        })
    }

    return (
        <Row style={{textAlign: "left", fontSize: "22px"}}>
            <Col span={1}>
                {file.type ? <FileDoneOutlined style={{color: "green"}}/> : <FileUnknownOutlined/>}
            </Col>
            <Col span={8}>{file.file.name}</Col>
            <Col span={8}><Progress percent={file.progress * 100}
                                    status={file.status === "preparation" && "normal"}/></Col>
            <Col span={4}>
                {file.status === "waiting" ?
                    <SelectType fileType={file.type} types={types} setType={setType}/>
                    :
                    file.type ? file.type.replaceAll("_", " ").toUpperCase() : "UNKNOWN TYPE"
                }

            </Col>
            <Col span={1} onClick={() => deleteFile(index)}>
                <DeleteOutlined style={{cursor: "pointer"}}/>
            </Col>
        </Row>
    )
}

export default ImportFileRow
