import React, {useCallback, useEffect, useState} from "react";
import {cancel, deleteUser, editOrganization, editUser, getOrgAsync, getUser, getUserAsync} from "@API/users";
import {useNavigate} from 'react-router-dom';
import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, KeyOutlined} from '@ant-design/icons';

import "./Users.less";
import ChangePasswordModal from "@components/admin_panel/users/ChangePasswordModal";
import {getAuthConfiguration} from "@API/configuration";
import UsersList from "@components/admin_panel/users/UsersList";
import {useRequest, useUpdateEffect} from "ahooks";


const {Option} = Select;
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 12},
};

const organization = {
    id: "382838238823",
    full_name: 'Signet',
    picture: 'https://signetfm.com/wp-content/themes/wp-signet/img/logo.png'
}

const OrganizationDetails = ({id}) => {
    const [loading, setLoading] = useState(false);
    const {
        data: organization,
        loading: loadingOrganization,
        refresh,
        error
    } = useRequest(() => getOrgAsync(id), {
        refreshDeps: [id]
    })

    const [orgForm] = Form.useForm();

    useUpdateEffect(() => {
        console.log(organization)
        orgForm.setFieldsValue(organization.data)
    }, [organization])

    const orgFormSave = useCallback((values) => {
        console.log(values)
        setLoading(true);
        editOrganization(id, values, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error(resp);
            } else {
                refresh()
            }

        })
    }, [id])

    return (
        <Spin size="large" spinning={loading}>
            {organization ?
                <div style={{width: '100%'}}>
                    <Card loading={loading}>
                        <Row>
                            <Col span={4}>
                                <Avatar size={160} src={organization.picture}
                                        style={{backgroundColor: '#E0AD60', fontSize: 50}}>U</Avatar>
                                <div
                                    style={{width: 160, height: 160, borderRadius: "50%"}} className="ant-image-mask"
                                    onClick={() => console.log("org-profile-click")}
                                >&nbsp;</div>
                            </Col>
                            <Col span={20}>
                                <Form {...layout}
                                      initialValues={organization}
                                      form={orgForm}
                                      name="user-details"
                                      onFinish={orgFormSave}
                                      labelAlign={"left"}
                                      size={"large"}
                                      requiredMark={false}
                                >
                                    <Form.Item name={'name'} label="Full Name"
                                               rules={[{required: true}]}>
                                        <Input/>

                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <UsersList holistico={true}/>
                            </Col>
                        </Row>
                    </Card>
                </div>
                :
                <div style={{height: "400px"}}></div>
            }
        </Spin>
    )
}

export default OrganizationDetails
