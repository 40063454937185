import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;
const getPlaygroundPresets = (templatesHandler) => {
    let url = `/playground_presets/`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
        templatesHandler(data);
    })
        .catch(({response}) => {
            console.log(response);
        })
};

const savePlayroundPreset = (userTemplate, creationCallback) => {
    let url = `/playground_presets`

    axiosInstance.post(url, userTemplate)
        .then((result) => {
            creationCallback(null, result);
        })
        .catch(({response}) => {
            console.log(response);
            creationCallback(response, null);
        })
};

const deletePlaygroundPreset = (_id, name, deletionCallback) => {
    let url = `/playground_presets/${_id}?name=${name}`

    axiosInstance.delete(url)
        .then(() => {
            deletionCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};

export {
    getPlaygroundPresets,
    savePlayroundPreset,
    deletePlaygroundPreset
}
