import ValueFormatter from "@global/ValueFormatter";
import React, {useEffect, useState} from "react";
import {DecompositionTreeGraph} from "@ant-design/charts";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Select, Space, theme} from "antd";

const formatLog = (log_data) => {
    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    const updatedAgo = dayjs(log_data['timestamp']).fromNow()
    return `Updated ${updatedAgo}`
}
const mapComposition = (composition) => {
    let lastLog = formatLog(composition.last_log)
    let children = []

    let root = {
        id: composition._id,
        value: {
            title: composition.name,
            items: [{"text": lastLog}]
        }
    }

    for (let portfolioPosition of composition.positions) {
        let position = {
            id: `${composition._id} ${portfolioPosition.definition_id}`,
            value: {
                title: portfolioPosition.ticker,
                items: [{"text": portfolioPosition.name,}, {
                    text: 'Weight',
                    value: ValueFormatter.percent(portfolioPosition.weight)
                }]
            }
        }
        children.push(position)
    }

    root.children = children
    return root
}

const getOptions = (portfolios) => {
    return portfolios.map(d => <Option
        key={d._id}>{d.name} ({d.calculated_values?.risk?.risk_score})</Option>);
}
const mapData = (compositions) => {
    let mappedData = []
    for (let comp of compositions) {
        let compData = mapComposition(comp)
        mappedData.push(compData)
        console.log(compData)
        return compData
    }

    const root = {
        id: '__root',
        value: {
            title: 'Singet FM',
            items: [{"text": 'Model Structure'}]
        },
        children: mappedData
    }
    console.info(root)
    return root

}
const {useToken} = theme;
const ModelGraph = ({compositions = []}) => {
    const [graphData, setGraphData] = useState({})
    const {token} = useToken()


    useEffect(() => {
        const data = mapData(compositions);
        setGraphData(data);
    }, [compositions]);

    const config = {
        data: graphData,
        width: 700,
        markerCfg: (cfg) => {
            const {children} = cfg;
            return {
                show: children?.length,
            };
        },
        nodeCfg: {
            size: [240, 49],
            items: {
                style: (cfg, group, type) => {
                    const styles = {
                        value: {
                            fill: token.colorWarning,
                            fontSize: 13,
                            fontWeight: 'bold'
                        },
                        text: {
                            fill: token.colorPrimary,
                            fontSize: 12,
                        },
                    };
                    return styles[type];
                },
            },
            title: {
                style: {
                    fontSize: 13,
                    fontWeight: 'bold'
                },
            },
        },
        layout: {
            type: 'indented',
            direction: 'LR',
            dropCap: false,
            indent: 350,
            getHeight: () => {
                return 60;
            },
            getWidth: () => {
                return 100;
            },
        },
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    };

    const handleChange = (value) => {
        if (!value) {
            return
        }
        let comp = compositions.find(c => c._id == value)
        setGraphData(mapComposition(comp))
        console.log(`selected ${value}`);
    }

    return <Space direction={"vertical"} style={{width: '100%'}}>
        <Select
            placeholder={'select the portfolio'}
            onChange={handleChange}
            style={{width: '100%', marginRight: 24}}>
            {getOptions(compositions)}
        </Select>
        <DecompositionTreeGraph {...config} />
    </Space>;
}

export default ModelGraph
