/*
    In general performs more complicated search by anything, not only by clients. By design exists on /clients page
 */
import React, {useEffect, useRef} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {Input, Row} from 'antd';
import './MainSearch.less';
import { debounce } from '@global/Utils';

// eslint-disable-next-line react/prop-types
const MainSearch = ({fetchData, secureMode, setSecureMode, placeholder, bordered=false, autoFocus=false}) => {
    //omg
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    let className = isSafari ? 'big-search-safari' : 'big-search'

    const inputRef = useRef(null);

    const handleChange = secureMode ? (v) => {} : debounce((value) => {
        fetchData(value)
    }, 400)

    const directSearch = (value) => {
        fetchData(value, false);
    }

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current]);

    return (
        <div>
            <Row className={bordered ? 'big-search-container bordered' : 'big-search-container' }>
                <Input onChange={e => handleChange(e.target.value)} className={className} size={"large"}
                       placeholder={placeholder}
                       suffix={<SearchOutlined className='certain-category-icon'/>}
                       onPressEnter={e => directSearch(e.target.value)}
                       ref={inputRef}
                />
            </Row>
        </div>
    );
}

export default MainSearch;
