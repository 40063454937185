import {AudioMutedOutlined, AudioOutlined} from "@ant-design/icons";
import {message, Tooltip} from "antd";
import React, {useState} from "react";
import MuteModal from "@components/supervision/actions/form/MuteModal";

const ActionMuteFlag = ({client, metricFlagData}) => {
    const [modalOpen, setModalOpen] = useState(false);
    //actually always true, unmuted clients wont get to the report
    const mute = !client?.flag_muted
    return <>
        {mute ? <Tooltip title={"Mute flag for client"}><AudioMutedOutlined onClick={() => setModalOpen(true)}/></Tooltip> :
            <Tooltip title={"Unmute flag for client"}><AudioOutlined onClick={() => setModalOpen(true)}/></Tooltip>}
        <MuteModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            clientId={client.client_id}
            clientName={client.client_name}
            mute={mute}
            flagKey={metricFlagData.red_flag_key}
            flagName={metricFlagData.red_flag_name}
            finishAction={() => {
                message.success("Client flag was muted. The changes will take effect upon next report loading.")
            }}
        />
    </>
}

export default ActionMuteFlag
