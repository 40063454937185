import React, {useEffect, useState} from "react";
import {
    Empty,
    Flex,
    Segmented,
} from "antd";
import moment from "moment";
import ValueFormatter from "@global/ValueFormatter";
import {Area, Column} from "@ant-design/charts";


const prepareAlpha= (rawData, monthly=true) => {
    const data = [];
    rawData.forEach(function (value, i) {
        data.push({
            type: "Alpha",
            date: moment(value['date'])
                .format(monthly ? "MMM YYYY" : "YYYY"),
            value: value['return_portfolio'] - value['return_benchmark']
        })
    });

    return data;
}

const prepareReturns= (rawData, monthly=true) => {
    const data = [];
    rawData.forEach(function (value, i) {
        data.push({
            type: "Portfolio return",
            date: moment(value['date'])
                // .add(monthly ? 1 : 12, 'months')
                .format(monthly ? "MMM YYYY" : "YYYY"),
            value: value['return_portfolio']
        })
        data.push({
            type: "Benchmark return",
            date: moment(value['date'])
                .format(monthly ? "MMM YYYY" : "YYYY"),
            value: value['return_benchmark']
        })
    });

    return data;
}


const convertToCumulative= (rawData) => {
    const data = [];
    rawData.forEach(function (value, i) {
        const doc = {...value};
        if (i > 0) {
            doc.return_portfolio = (
                (1 + data[i - 1].return_portfolio / 100)
                * (1 + value.return_portfolio / 100) - 1) * 100;
            doc.return_benchmark = (
                (1 + data[i - 1].return_benchmark / 100)
                * (1 + value.return_benchmark / 100) - 1) * 100;
            doc.alpha = doc.return_portfolio - doc.return_benchmark;
        }
        data.push(doc)
    });

    return data;
}


const Chart = ({data, line, chartRef}) => {
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        setChartData(data);
    }, [data])

    const config = {
        data: chartData,
        appendPadding: 24,
        isGroup: true,
        isStack: false,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        height: 350,
        theme: {
            styleSheet: {
                backgroundColor: "white"
            }
        },
        yAxis: {
            label: {
                formatter: (v) => ValueFormatter.softPercent(v / 100),
            },
        },
        legend: {
          layout: 'horizontal',
          position: 'bottom'
        },
        smooth: false,
        color: ['#E0AD60', '#3196B2'],
        animation: false,
        tooltip: {
            formatter: (tooltipOptions) => {
                return {
                    value: ValueFormatter.percent(tooltipOptions.value / 100),
                    name: tooltipOptions.type
                }
            }
        },
        onReady: (plot) => {
          chartRef.current = plot;
        }
    };

    if (line) {
        return <Area {...config}/>
    } else {
        return <Column {...config}/>;
    }
}


const PerformanceChart = ({statistics, chartRef}) => {
    const [monthly, setMonthly] = useState(true);
    const [alpha, setAlpha] = useState(false);
    const [cumulative, setCumulative] = useState(true);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (statistics) {
            let rawData = monthly ? statistics.monthly_data : statistics.annual_data;
            if (cumulative) {
                rawData = convertToCumulative(rawData);
            }
            if (alpha) {
                setChartData(prepareAlpha(rawData, monthly));
            } else {
                 setChartData(prepareReturns(rawData, monthly));
            }
        } else {
            setChartData([])
        }
    }, [statistics, monthly, alpha, cumulative])


    return <Flex vertical gap={"middle"}>
        <Flex gap={"large"} justify={"space-between"}>
            <Flex gap={"middle"} wrap={"wrap"}>
                <Segmented
                    options={["Alpha", "Return"]}
                    value={alpha ? "Alpha" : "Return"}
                    onChange={value => setAlpha(value === "Alpha")}
                />
                <Segmented
                    options={["Monthly", "Annual"]}
                    value={monthly ? "Monthly" : "Annual"}
                    onChange={value => setMonthly(value === "Monthly")}
                />
                <Segmented
                    options={["Cumulative", "Simple"]}
                    value={cumulative ? "Cumulative" : "Simple"}
                    onChange={value => setCumulative(value === "Cumulative")}
                />

            </Flex>
        </Flex>
        {chartData?.length > 0
            ? <Chart data={chartData} line={monthly} chartRef={chartRef}/>
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data"/>
        }
    </Flex>
}


export default PerformanceChart;
