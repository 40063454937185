import React from 'react';
import {Progress} from 'antd';
import ChartStyles from "@global/ChartStyles";

const StatusProgress = ({value, status}) => {

    return (
        <Progress percent={value} steps={10} size="small" strokeColor={ChartStyles.get_status_color(status)}
                  showInfo={false} status={status}/>
    );
}

export default StatusProgress;
