import React, {useEffect, useState} from "react";
import {Card, ConfigProvider, Form, message, Spin, theme} from 'antd';
import {getConfig} from "@API/backtest";
import {
    prepareConfigForUI,
    prepareForServer
} from "@components/backtest/BacktestTransformator";
import BacktestConfigForm from "@components/backtest/BacktestConfigForm";
import BacktestHeader from "@components/backtest/BacktestHeader";


const {useToken} = theme;


const BacktestConfiguration = ({run, clearResults}) => {
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(null);
    const [form] = Form.useForm();
    const {token} = useToken();

    useEffect(() => {
        loadConfig(null);
    }, [])

    useEffect(() => {
        console.log("Config form set", config);
        form.setFieldsValue(config);
    }, [config])

    const loadConfig = (idToLoad) => {
        setLoading(true);
        getConfig(idToLoad, (result, error) => {
            console.info('Loading config with id: ' + idToLoad);
            if (error) {
                message.error(error);
            } else {
                const _config = prepareConfigForUI(result);
                setConfig(_config);
                console.log('Config loaded', _config);
                clearResults();
            }
            setLoading(false)
        })
    }

    const startTesting = (config) => {
        setConfig(config);
        const _config = prepareForServer(config);
        console.debug('Config to send to server', _config);
        run(_config);
    }

    return <Spin size={"large"}
                 spinning={loading}
                 tip={"Loading configuration..."}
    >
        {config
            &&
            <Form
                form={form}
                name="backtest_params"
                onFinish={startTesting}
                layout={"vertical"}
                requiredMark={false}
                validateTrigger="onSubmit"
            >
                <Card hoverable style={{marginBottom: token.padding}}>
                    <BacktestHeader
                        config={config}
                        loadConfig={loadConfig}
                    />
                </Card>

                <ConfigProvider theme={{
                    token: {
                        fontSize: 14,
                    },
                    components: {
                        Form: {itemMarginBottom: 0},
                        Mentions: {colorText: token.colorPrimary},
                    }
                }}>
                    <BacktestConfigForm
                        config={config}
                        run={(config) => startTesting(config)}
                        load={(id) => loadConfig(id)}
                        setConfig={setConfig}
                    />
                </ConfigProvider>
            </Form>
        }
    </Spin>
}

export default BacktestConfiguration;
