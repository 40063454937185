/* eslint-disable react/prop-types */
import React from 'react';
import {Form, Select, Flex} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";


const SortSelect = ({}) => {
    const {settings} = useScreenerContext()

    return <Flex align={"center"}>
        <span style={{
            marginRight: "10px",
            fontWeight: 500,
            whiteSpace: "nowrap",
            fontSize: 18
        }}>
            Sort by:
        </span>
        <Form.Item
            name="order_by"
            colon={true}
            style={{width: "240px"}}
        >
            <Select options={settings.order_by.options.filter(option => !option.additional).map(item => {
                return {value: item.value, label: item.label}
            })}/>
        </Form.Item>
    </Flex>
}


export default SortSelect;
